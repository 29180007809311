import React, { FunctionComponent } from 'react';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { Negative, Positive } from '../../../components/General/NegativePositive';
import { Input, SIZE } from 'baseui/input';


interface ListPieceOrTimingProps {
  value: boolean;
  register: any;
  errors: any;
}
const PieceOrTiming: FunctionComponent<ListPieceOrTimingProps> = ({
  value,
  register,
  errors,
}) => {
  return (
      <FormControl
        // label={
        //   value ? (
        //     <FormattedMessage id="measurement_rule.timingPiece" />
        //   ) : (
        //     <FormattedMessage id="measurement_rule.timingTime" />
        //   )
        // }
        error={errors?.timingPieceTime ? 'Please input a valid  Piece' : null}
      >
        <Input
          type="number"
          size={SIZE.compact}
          name="timingPieceTime"
          inputRef={register({
            required: true,
          })}
          endEnhancer={value ? 'Adet' : 'Dakika'}
          error={errors.timingPieceTime}
          overrides={errors.timingPieceTime ? { After: Negative } : { After: Positive }}
        />
      </FormControl>
  );
};

export default PieceOrTiming;
