import React, { FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { Users } from '../../types/User';

const GET_USERS = loader('../../graphql/user/GET_USERS.graphql');
interface ListProps {
  errors?: any;
  control: any;
  name?: string;
  onChange?: Function;
}
const SelectUser: FunctionComponent<ListProps> = ({
  errors,
  control,
  name,
  onChange,
}) => {
  const { data } = useQuery<Users>(GET_USERS);

  return (
    <FormControl
      label={<FormattedMessage id={'select.' + (name || 'user')} />}
      error={errors && errors?.userId ? 'Please Select a valid User' : null}
    >
      <Select
        data={data && data.users}
        controller={{
          name: 'userId',
          type: TYPE.search,
          control,
          creatable: false,
          clearable: false,
          rules: { required: errors ? true : false },
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            onChange && onChange(selected.option ? selected.value : undefined);
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectUser;
