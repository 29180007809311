import { useMutation } from '@apollo/react-hooks';
import { faCalculator } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, SIZE } from 'baseui/input';
import { loader } from 'graphql.macro';
import React, { Fragment, FunctionComponent, useEffect, useMemo,  useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  Row,
  RowCustom,
} from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { useToaster } from '../../../../context/toaster-provider';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';
import DateCustom from '../../../DateCustom/DateCustom';
import { handleCeil, handleDate, inputDecimal } from '../../../Functions/Utilities';
import RawMaterialCalculation from '../../../Offers/AddPurchaseOffer/PurchaseMaterialOffer/RawMaterialCalculate';
import SelectInventory from '../../../SelectCustom/SelectInventory';
import SelectInventoryType from '../../../SelectCustom/SelectInventoryType';
import SelectUnit from '../../../SelectCustom/SelectUnit';
import useBillOfMaterialItemUnitPrice from './useBillOfMaterialItemUnitPrice';
const ADD_PURCHASE_OFFER = loader(
  '../../../../graphql/purchaseOffer/ADD_PURCHASE_OFFER.graphql'
);
const ADD_PURCHASE_MATERIAL_OFFER = loader(
  '../../../../graphql/purchaseOfferMaterial/ADD_PURCHASE_MATERIAL_OFFER.graphql'
);
interface PurchaseMaterialOfferFormProps {
  purchaseOfferId: string;
  offerId: string;
  orderQty: number;
  setClose?: Function;
  inventory: [{ id: string; label: string }];
  item: BillOfMaterialItem,
}
const PurchaseMaterialOfferForm: FunctionComponent<PurchaseMaterialOfferFormProps> = ({
  purchaseOfferId,
  offerId,
  orderQty,
  setClose,
  inventory,
  item,
}) => {
  const { showToaster } = useToaster();
  const [oneUnitWeight, setOneUnitWeight] = useState(0);
  const [qtyCalculate, setQtyCalculate] = useState(false);
  const [rawMaterialId, setRawMaterialId] = useState(null);
  const [materialGeometryId, setMaterialGeometryId] = useState(null);
  const { control, errors, setValue, register, handleSubmit } = useForm({
    defaultValues: {
      estimatedDate: [new Date()],
    },
  });
  const { select, unit, rawMaterialGeometry, inventoryType } = SelectInventory({
    name: 'inventoryId',
    defaultValue: inventory,
    control,
    errors,
    required: true,
    creatable: true,
    disabled: false,
  });
  const billOfMaterialItemInventoryTypeRawMaterial = useMemo(() => {
    return (item.inventory?.inventoryType?.id === 'd5b8e917-9bc5-42e6-a23a-b721e72972a1') ||
      (item?.inventoryType?.id === 'd5b8e917-9bc5-42e6-a23a-b721e72972a1')
  }, [item])
  useEffect(() => {
    if (!billOfMaterialItemInventoryTypeRawMaterial) {
      setValue("qty", handleCeil(orderQty * (item?.quantity || 1)))
    }
  }, [item, billOfMaterialItemInventoryTypeRawMaterial])
  useEffect(() => {
    !unit &&
      qtyCalculate &&
      setValue('unitsId', [
        { id: 'c932822a-e35e-4ace-83e0-14b11932a6a2', label: 'Kilo' },
      ]);
  }, [unit, qtyCalculate]);
  useEffect(() => {
    setValue('unitsId', unit);
    setValue('inventoryTypeId', inventoryType);
  }, [setValue, unit, inventoryType]);
  useEffect(() => {
    setValue('inventoryId', inventory);
  }, [inventory]);
  const [addItem] = useMutation(ADD_PURCHASE_MATERIAL_OFFER);
  const [add] = useMutation(ADD_PURCHASE_OFFER);
  const { oneUnitWeightUpdate } = useBillOfMaterialItemUnitPrice()
  const onSubmit = ({
    qty,
    inventoryId,
    inventoryTypeId,
    unitsId,
    estimatedDate,
  }) => {
    if (purchaseOfferId) {
      addItem({
        variables: {
          purchaseOfferId: purchaseOfferId,
          ...handleNameOrInventoryIdAdd({
            inventoryId,
            inventoryTypeId,
            unitsId,
            rawMaterialId,
            materialGeometryId,
          }),
          estimatedDate: handleDate(estimatedDate[0]).toISOString(),
          rawMaterialId: rawMaterialId,
          materialGeometryId: materialGeometryId,
          qty: +qty,
        },
      }).then(() => {
        showToaster('created', 'positive');
        billOfMaterialItemInventoryTypeRawMaterial && oneUnitWeightUpdate({
          item,
          oneUnitWeight: oneUnitWeight
        })
        handleResetForm();
      });
    } else {
      add({
        variables: {
          approved: false,
          offerId,
          purchaseMaterialOffer: [
            {
              ...handleNameOrInventoryIdAdd({
                inventoryId,
                inventoryTypeId,
                unitsId,
                rawMaterialId,
                materialGeometryId,
              }),
              estimatedDate: handleDate(estimatedDate[0]).toISOString(),
              rawMaterialId: rawMaterialId,
              materialGeometryId: materialGeometryId,
              qty: +qty,
            },
          ],
        },
      }).then(() => {
        showToaster('created', 'positive');
        billOfMaterialItemInventoryTypeRawMaterial && oneUnitWeightUpdate({
          item,
          oneUnitWeight: oneUnitWeight
        })
        handleResetForm();
      });
    }
  };
  const handleResetForm = () => {
    setClose({
      inventory: null
    });
    // setValue('inventoryId', undefined);
    // setValue('inventoryTypeId', undefined);
    // setValue('unitsId', undefined);
    // setValue('estimatedDate', [new Date()]);
    // setValue('qty', 0);
  };
  const handleCalculateResult = ({
    rawMaterialId,
    materialGeometryId,
    result,
    oneUnitWeight
  }) => {
    setValue('qty', result);
    setRawMaterialId(rawMaterialId);
    setMaterialGeometryId(materialGeometryId);
    setOneUnitWeight(oneUnitWeight)
  };
  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RowCustom bottom="xs">
          <Col xs={12} md={5} lg={5}>
            {select}
          </Col>
          <Col xs={12} md={4} lg={4}>
            <SelectInventoryType control={control} disabled={!!unit} />
          </Col>
          <Col xs={12} md={3} lg={3}>
            <DateCustom
              errors={errors}
              control={control}
              name="estimatedDate"
            />
          </Col>
          <Col xs={7} sm={4} md={4} lg={4}>
            <Row style={{ margin: 0, padding: 0 }}>
              <Col style={{ margin: 0, padding: 0 }} xs={9}>
                <FormControl
                  label={<FormattedMessage id="purchaseOffer.qty" />}
                >
                  <Input
                    type="text"
                    size={SIZE.compact}
                    name="qty"
                    inputRef={register({
                      required: false,
                    })}
                    onChange={(event: any) => {
                      setValue('qty', inputDecimal({ event }));
                    }}
                    clearOnEscape
                  />
                </FormControl>
              </Col>
              <Col style={{ margin: 0, padding: 0 }} xs={3}>
                <Button
                  style={{ marginTop: '8px' }}
                  type="button"
                  onClick={() => setQtyCalculate(true)}
                >
                  <FontAwesomeIcon icon={faCalculator} />
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={5} sm={4} md={3} lg={4}>
            <SelectUnit control={control} disabled={!!unit} />
          </Col>
          <ColButton xs>
            <Button
              style={{
                marginBottom: '16px',
              }}
              type="button"
              onClick={handleResetForm}
            >
              <FormattedMessage id={'button.close'} />
            </Button>
            <Button
              style={{
                marginBottom: '16px',
              }}
              type="submit"
            >
              <FormattedMessage id={'button.add'} />
            </Button>
          </ColButton>
        </RowCustom>
      </Form>
      {qtyCalculate && (
        <RawMaterialCalculation
        setQtyCalculate={setQtyCalculate}
        handleResult={({ rawMaterialId, materialGeometryId, result, oneUnitWeight }) =>
        handleCalculateResult({ rawMaterialId, materialGeometryId, result, oneUnitWeight })
      }
      rawMaterialGeometry={
        rawMaterialGeometry ? rawMaterialGeometry : undefined
      }
      orderQty={orderQty}
      />
      )}
    </Fragment>
  );
};

export default PurchaseMaterialOfferForm;

const handleNameOrInventoryIdAdd = ({
  inventoryId,
  inventoryTypeId,
  unitsId,
  rawMaterialId,
  materialGeometryId,
}) => {
  const nameOrInventoryId =
    inventoryId[0].id !== inventoryId[0].label
      ? { inventoryId: inventoryId[0].id }
      : {
        name: inventoryId[0].id,
        inventoryTypeId: inventoryTypeId ? inventoryTypeId[0].id : null,
        unitsId: unitsId ? unitsId[0].id : null,
        rawMaterialId: rawMaterialId,
        materialGeometryId: materialGeometryId,
      };
  return nameOrInventoryId;
};
