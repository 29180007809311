import React from 'react';
import NotificationCard from './NotificationCard';
import { FormattedMessage } from 'react-intl';
import {
  Body,
  Header,
  Heading,
  ClearAll,
  Footer,
  FeedsButton,
} from './Notification.style';

export default function Notification({
  data = [],
  onClear,
  feedBtnClick,
}: any) {
  return (
    <div>
      <Header>
        <Heading>
          <FormattedMessage id="topbar.notifications" />
        </Heading>
        <ClearAll onClick={onClear}>
          <FormattedMessage id="topbar.clear_all" />
        </ClearAll>
      </Header>
      <Body>
        {data &&
          data.length > 0 &&
          data?.map((item, index) => <NotificationCard key={index} {...item} />)}
      </Body>
      <Footer>
        <FeedsButton onClick={feedBtnClick}>
          <FormattedMessage id="topbar.check_all_notifications" />
        </FeedsButton>
      </Footer>
    </div>
  );
}
