import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { useForm, Controller } from 'react-hook-form';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { Datepicker } from 'baseui/datepicker';
import tr from 'date-fns/locale/tr';
import { SIZE } from 'baseui/input';
import { handleDate } from '../Functions/Utilities';
import { handleAddShiftDetail, handleUpdateShiftDetail } from './GraphqlFuntion';

const ADD_SHIFT_DETAIL = loader('../../graphql/shift/ADD_SHIFT_DETAIL.graphql');
const UPDATE_SHIFT_DETAIL = loader('../../graphql/shift/UPDATE_SHIFT_DETAIL.graphql');
const GET_DAYS = loader('../../graphql/shift/GET_DAYS.graphql');
const GET_TIME_GROUPS = loader('../../graphql/shift/GET_TIME_GROUPS.graphql');

const ShiftForm: FunctionComponent = () => {
  const { handleSubmit, setValue,  control, errors } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  console.log(dataDispatch)
  const [defaultDate, setDefaultDate] = useState(()=>dataDispatch?.specificDate ?
    [new Date(dataDispatch.specificDate)] : [new Date(dataDispatch.startDate)]);
  const { data: dataDay } = useQuery(GET_DAYS);
  const { data: dataGroup } = useQuery(GET_TIME_GROUPS);
  const closeDrawer = useCallback(
    () => {
         dispatch({ type: 'CLOSE_DRAWER' });
    },
    [dispatch]
  );

  useEffect(() => {
    if (transactionsReasonDispatch === 'update') {
      dataDispatch.days ? setValue('daysId', [{ id: dataDispatch.days.id, label: dataDispatch.days.name }]):
      setValue('specificDate', [new Date(dataDispatch.specificDate)]);
      setValue('timeGroupId', [
        { id: dataDispatch.timeGroup.id, label: dataDispatch.timeGroup.name },
      ]);
    }else{
      setValue('specificDate', [new Date(dataDispatch.startDate)]);  
    }
  }, [dataDispatch, setValue,transactionsReasonDispatch]);
  const onSubmit = ({ daysId, timeGroupId,specificDate }) => {
    if (dataDispatch?.id) {
      updateShift({
        variables: {
          id: dataDispatch.id,
          shiftId: dataDispatch.shiftId,
          daysId: daysId ? daysId[0].id : null,
          timeGroupId: timeGroupId[0].id,
          specificDate: specificDate ?  handleDate(specificDate[0]) :null
        },
      });
    } else {
      addShift({
        variables: {
          shiftId: dataDispatch?.shiftId,
          daysId: daysId ? daysId[0].id : null,
          timeGroupId: timeGroupId[0].id,
          specificDate: specificDate ?  handleDate(specificDate[0]) :null
        },
      });
    }
  };
  const [addShift] = useMutation(ADD_SHIFT_DETAIL, {
    update(cache, { data: { createShiftDetail } }) {
      handleAddShiftDetail(cache,dataDispatch?.shiftId,createShiftDetail)
      closeDrawer();
    },
  });
  const [updateShift] = useMutation(UPDATE_SHIFT_DETAIL, {
    update(cache,{data:{updateShiftDetail}}) {
      handleUpdateShiftDetail(cache,dataDispatch?.shiftId,updateShiftDetail)
      closeDrawer();
    },
  });

  return (
    <Card
      style={{ padding: 0 }}
      title={
        <FormattedMessage id={transactionsReasonDispatch === 'update' ? 'shift.update' : 'shift.create'} />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <Row style={{ width: '100%' }}>
          <Col sm={12} md={5} lg={5}>
            {
              (dataDispatch?.endDate && dataDispatch?.startDate) ?

                <FormControl
                  label={<FormattedMessage id="shift.specificDate" />}
                  error={
                    errors.specificDate ? 'Please select a valid Date Start and End' : null
                  }
                >
                  <Controller
                    as={
                      <Datepicker
                        locale={tr}
                        mask={null}
                        value={defaultDate}
                        formatString="d/MM/yyyy"
                        minDate={new Date(dataDispatch.startDate)}
                        maxDate={new Date(dataDispatch.endDate)}
                      />
                    }
                    size={SIZE.compact}
                    name="specificDate"
                    rules={{ required: true }}
                    control={control}
                    valueName="selected"
                    onChange={([selected]) => {
                      setDefaultDate(selected.date);
                      return selected ? [selected.date] : undefined;
                    }}
                    error={errors.specificDate}
                  />
                </FormControl> :
                <FormControl
                  label={<FormattedMessage id="shift.days" />}
                >
                  <Select
                    data={dataDay && dataDay.days}
                    controller={{
                      name: 'daysId',
                      control: control,
                      rules: { required: false },
                      onChange: ([selected]) => {
                        return selected.option ? selected.value : undefined;
                      },
                    }}
                  />
                </FormControl>
            }
          </Col>
          <Col sm={12} md={7} lg={7}>
            <FormControl
              label={<FormattedMessage id="shift.timeGroup" />}

            >
              <Select
                data={dataGroup && dataGroup.timeGroups}
                controller={{
                  name: 'timeGroupId',
                  control: control,
                  rules: { required: false },
                  onChange: ([selected]) => {
                    return selected.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage
                id={transactionsReasonDispatch === 'update' ? 'button.update' : 'button.create'}
              />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default ShiftForm;
