import React, { useCallback, useEffect, FunctionComponent, useState } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { ipcRenderer } from '../../appRuntime';
import { shopFloorOrderWip } from '../../barcode/Barcode';
import { useToaster } from '../../context/toaster-provider';
import { CardBody, CardTitle, Card } from '../../components/FormWithElemets/Card.style';
import { handleDateLocal } from '../Functions/Utilities';
import { ShopFloorOrderNextOperations } from '../../types/ShopFloorOrderNextOperation';
import SelectShift from '../SelectCustom/SelectShift';
import { Datepicker } from 'baseui/datepicker';
import tr from 'date-fns/locale/tr';

const ADD_PRODUCT_RECORD = loader(
  '../../graphql/productRecord/ADD_PRODUCT_RECORD.graphql'
);
const GET_SHOP_FLOOR_ORDER_NEXT_OPERATION = loader(
  '../../graphql/shopFloorOrder/GET_SHOP_FLOOR_ORDER_NEXT_OPERATION.graphql'
);

const WipProductRecordForm: FunctionComponent = () => {
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  console.log('dataDispatch', dataDispatch);
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  return (
    <AddWipProductRecordForm
      dispatch={dispatch}
      dataDispatch={dataDispatch}
      transactionsReasonDispatch={transactionsReasonDispatch}
    />
  );
};
interface ListAddWipProps {
  dispatch: any;
  dataDispatch: any;
  transactionsReasonDispatch: any;
}
export const AddWipProductRecordForm: FunctionComponent<ListAddWipProps> = ({
  dispatch,
  dataDispatch,
  transactionsReasonDispatch,
}) => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [specificDate, setSpecificDate] = useState([new Date()]);
  const { data } = useQuery<ShopFloorOrderNextOperations>(
    GET_SHOP_FLOOR_ORDER_NEXT_OPERATION,
    {
      variables: {
        shopFloorOrderId: dataDispatch.shopFloorOrderId,
      },
    }
  );
  const [printerData, setPrinterData] = useState({
    wipBarcode: '',
    improperBarcode: '',
  });
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const onSubmit = ({ wip }) => {
    addProductRecord({
      variables: {
        wip: wip ? Number(wip) : 0,
        improper: 0,
        shopFloorOrderId: dataDispatch.shopFloorOrderId,
        productRecordReason: undefined,
      },
    })
      .then(() => showToaster('created', 'positive'))
      .catch((error) => {
        console.log('error', error); // <-- OK
        showToaster(error, 'warning', false);
      });
  };
  const [addProductRecord] = useMutation(ADD_PRODUCT_RECORD, {
    update(cache, { data: { createProductRecord } }) {
      // handleAddProductRecord(
      //   cache,
      //   dataDispatch.shopFloorId,
      //   dataDispatch.shopFloorOrderId,
      //   dataDispatch.machineryAndEquipmentId,
      //   createProductRecord
      // );
      ipcRenderer &&
        setPrinterData(() => {
          return {
            improperBarcode: '',
            wipBarcode: shopFloorOrderWip({
              nextOperation: data?.shopFloorOrderNextOperation?.name || '-',
              productBarcodeNo: createProductRecord?.barcode?.toUpperCase(),
              product: dataDispatch.product,
              qtyOrders: dataDispatch.shopFloorOrderQty,
              qtyProduction: createProductRecord?.wip || 0,
              user:
                createProductRecord?.user?.name +
                ' ' +
                createProductRecord?.user?.surname,
              shift: createProductRecord?.shift?.name || '',
              processingTime: handleDateLocal(createProductRecord?.insertedAt),
              companyShortName:
                createProductRecord?.shopFloorOrder?.orderDetail?.order?.company
                  ?.companyShortName || '',
              productionOrderNo: createProductRecord?.shopFloorOrder?.serial || '',
              orderNo:
                createProductRecord?.shopFloorOrder?.orderDetail?.order?.serial || '',
              completeOperation:
                createProductRecord?.productOperation?.operation?.name || '--',
            }),
          };
        });
      transactionsReasonDispatch === 'shopFloorOrderCompleted'
        ? setValue('wip', '')
        : closeDrawer();
    },
  });
  useEffect(() => {
    if (ipcRenderer) {
      printerData.wipBarcode !== '' &&
        ipcRenderer.send('print-barcode', {
          data: printerData.wipBarcode,
          printer: localStorage.getItem('barcode'),
        });
    }
  }, [printerData, data]);
  return (
    <Card>
      <CardTitle
        title={<FormattedMessage id={'product_record_wip.create'} />}
      ></CardTitle>
      <CardBody style={{ padding: '5px' }}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            margin: 0,
            padding: 0,
            height: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <Row style={{ margin: 0, padding: 0 }}>
            <Col sm={12}>
              <FormControl
                label={<FormattedMessage id="product_record.wip" />}
                error={errors.wip ? 'Please input a valid Wip' : null}
              >
                <Input
                  size={SIZE.compact}
                  name="wip"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.wip}
                  overrides={errors.wip ? { After: Negative } : { After: Positive }}
                />
              </FormControl>
            </Col>
            {transactionsReasonDispatch === 'planingManagement' &&
            <>
              <Col sm={12}>
                <SelectShift errors={errors} control={control} />
              </Col>
              <Col sm={12}>
              <FormControl
                label={<FormattedMessage id="product_record.specificDate" />}
                error={
                  errors.specificDate ? 'Please input a valid Specific Date' : null
                }
              >
                <Controller
                  as={
                    <Datepicker
                      size={SIZE.compact}
                      locale={tr}
                      mask={null}
                      value={specificDate}
                      formatString="d/MM/yyyy"
                    />
                  }
                  control={control}
                  name="specificDate"
                  rules={{ required: true }}
                  valueName="selected"
                  onChange={([selected]) => {
                    setSpecificDate(selected.date);
                    return selected ? [selected.date] : undefined;
                  }}
                  error={errors.specificDate}
                  overrides={
                    errors.specificDate ? { After: Negative } : { After: Positive }
                  }
                />
              </FormControl>
            </Col>
            </>
            }
            <Col md={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                {!(transactionsReasonDispatch === 'shopFloorOrderCompleted') && (
                  <Button type="button" onClick={closeDrawer}>
                    <FormattedMessage id="button.close" />
                  </Button>
                )}
                <Button type="submit">
                  <FormattedMessage id={'button.save'} />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};
export default WipProductRecordForm;
