import React, {
  useState,
  FunctionComponent,
  useEffect,
  useContext,
} from 'react';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Negative,
  Positive,
} from '../../../components/General/NegativePositive';
import { Select } from '../../../components/FormWithElemets/Select/Select';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import Button from '../../../components/FormWithElemets/Button/Button';
import { useToaster } from '../../../context/toaster-provider';
import { handleUpdateOffer } from '../WasteGraphqlFunction';
import { OfferStatus, OfferStatuses } from '../../../types/OfferStatus';
import { Form } from '../../../components/FormWithElemets/Form.style';
import { ComponentStatusContext } from '../Offer';
const GET_STATUSES = loader('../../../graphql/offer/GET_STATUSES.graphql');
const UPDATE_OFFER = loader('../../../graphql/offer/UPDATE_OFFER.graphql');

type ListProps = {
  offerStatus: Status;
  offerId: string;
};
const UpdateOfferStatus: FunctionComponent<ListProps> = ({
  offerStatus,
  offerId,
}) => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const { showToaster } = useToaster();
  const { control, errors, handleSubmit } = useForm<UseForm>({
    defaultValues: {
      status: [{ id: offerStatus.id, label: offerStatus.name }],
    },
  });
  const [offerStage, setOfferStage] = useState({
    id: '',
    name: '',
  });
  const { data: dataStatuses } = useQuery<OfferStatuses>(GET_STATUSES);
  useEffect(() => {
    dataStatuses &&
      dataStatuses.offerStatuses.forEach((item: OfferStatus) => {
        if (offerStatus.id === item.id) {
          setOfferStage({
            id: item?.offerStage?.id,
            name: item?.offerStage?.name,
          });
        }
      });
  }, [dataStatuses, offerStatus.id]);
  const handleChangeStatuses = (selected: any) => {
    dataStatuses.offerStatuses.forEach((item) => {
      if (selected[0].id === item.id) {
        setOfferStage({
          id: item?.offerStage?.id,
          name: item?.offerStage?.name,
        });
      }
    });
  };
  const onSubmit = ({ status }) => {
    updateOffer({
      variables: {
        id: offerId,
        offerStageId: offerStage?.id,
        offerStatusId: status[0]?.id,
      },
    }).then(() => {
      dispatchComponentStatus({
        type: 'LIST',
      });
      showToaster('updated', 'positive');
    });
  };
  const [updateOffer] = useMutation(UPDATE_OFFER, {
    update(cache, { data: { updateOffer } }) {
      handleUpdateOffer(cache, updateOffer);
    },
  });
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{ padding: 0, margin: 0, height: 'auto' }}
    >
      <RowCustom>
        <Col sm={9}>
          <FormControl
            label={<FormattedMessage id="offer.status" />}
            error={errors.status ? 'Please select a valid Status' : null}
          >
            <Select
              data={dataStatuses && dataStatuses.offerStatuses}
              controller={{
                name: 'status',
                control,
                creatable: false,
                error: errors.status,
                overrides: errors.status
                  ? { After: Negative }
                  : { After: Positive },
                onChange: ([selected]) => {
                  handleChangeStatuses(selected?.value);
                  return selected.option ? selected.value : undefined;
                },
              }}
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button type="submit" style={{ marginTop: '16px' }}>
            <FormattedMessage id={'button.update'} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default UpdateOfferStatus;
type UseForm = {
  status: [{ id: string; label: string }];
};
type Status = {
  id: string;
  name: string;
};
