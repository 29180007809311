import { useQuery } from '@apollo/react-hooks';
import { faGenderless, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { COLOR } from '../../../components/FormWithElemets/Button/Button';
import { Card, CardBody } from '../../../components/FormWithElemets/Card.style';
import NoResult from '../../../components/NoResult/NoResult';
import {
  ProductRecordFromShopFloorOrder,
  ProductRecordFromShopFloorOrders,
} from '../../../types/ProductRecordShopFloorOrder';
import {  handleDateLocal } from '../../Functions/Utilities';
const GET_PRODUCT_RECORD_SHOP_FLOOR_ORDER = loader(
  '../../../graphql/productRecord/GET_PRODUCT_RECORD_SHOP_FLOOR_ORDER.graphql'
);
const S_GET_PRODUCT_RECORD_SHOP_FLOOR_ORDER = loader(
  '../../../graphql/productRecord/S_GET_PRODUCT_RECORD_SHOP_FLOOR_ORDER.graphql'
);
interface ListProps {
  shopFloorOrderId: string;
  setProductRecordEmpty?: Function;
}
const ProductRecordShopFloorOrderHistory: FunctionComponent<ListProps> = ({
  shopFloorOrderId,
  setProductRecordEmpty,
}) => {
  const intl= useIntl();
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<ProductRecordFromShopFloorOrders>(GET_PRODUCT_RECORD_SHOP_FLOOR_ORDER, {
    variables: {
      id: shopFloorOrderId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_PRODUCT_RECORD_SHOP_FLOOR_ORDER,
      variables: {
        id: shopFloorOrderId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.productRecordFromShopFloorOrder;
        return Object.assign({}, prev, {
          productRecordFromShopFloorOrder: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, shopFloorOrderId]);
  useEffect(() => {
    data &&
      setProductRecordEmpty &&
      data.productRecordFromShopFloorOrder.length > 0 &&
      setProductRecordEmpty(false);
  }, [data, setProductRecordEmpty]);
  console.log("---->data",data)
  return (
    <Card>
      <CardBody>
        {loading ? (
          <StyledSpinnerNext />
        ) : error ? (
          <NoResult />
        ) : data && data.productRecordFromShopFloorOrder.length > 0 ? (
          data.productRecordFromShopFloorOrder.map(
            (item: ProductRecordFromShopFloorOrder) => {
              return (
                <div key={item?.id} className="timeline timeline-6 mt-3">
                  <div className="timeline-item align-items-start">
                    <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                      <div style={{ width: '100%', textAlign: 'center' }}>
                        {handleDateLocal(item.insertedAt)}
                      </div>
                    </div>
                    <div className="timeline-badge">
                      <FontAwesomeIcon
                        icon={faGenderless}
                        color={item.wip !== 0 ? COLOR.green : COLOR.red}
                        size="2x"
                      />
                    </div>
                    <div
                      className="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
                      style={{ marginLeft: '15px' }}
                    >
                      {item.wip > 0 ? (
                        <span>
                          {' '}
                          {item.wip +
                            ' '} <FormattedMessage id="product_record.wip" />{' '}
                        </span>
                      ) : null}
                      {item.improper > 0 ? (
                        <span>
                          {' '}
                          {item.improper + ' '}{' '}
                          <FormattedMessage id="product_record.improper" />{' '}
                        </span>
                      ) : null}
                      <span
                        style={{
                          marginLeft: '20px',
                          marginRight: '5px',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faUser}
                          size="1x"
                          color={item.wip !== 0 ? COLOR.green : COLOR.red}
                        />
                      </span>
                      {'  ' + item.user.name + '  ' + item.user.surname + '  '}
                      <span
                        style={{
                          marginLeft: '20px',
                          marginRight: '5px',
                        }}
                      >
                        {handleDateLocal(item.updatedAt) + " (" + intl.formatMessage({id:"updated"}) + ")"}
                      </span>
                      <span
                        style={{
                          marginLeft: '20px',
                          marginRight: '5px',
                        }}
                      >
                        {item?.specificDate
                          ? handleDateLocal(item?.specificDate).slice(0, 10)
                          : '...'}
                      </span>
                    </div>
                  </div>
                  <div
                    style={{
                      marginLeft: '165px',
                      marginTop: '5px',
                      marginBottom: '5px',
                      display: 'block',
                    }}
                  >
                    {item.productRecordsReasonPivot.map((pivot) => {
                      return (
                        <span
                          key={pivot?.id}
                          style={{
                            backgroundColor: COLOR.yellow,
                            padding: '5px',
                            borderRadius: '5px',
                            marginLeft: '10px',
                            marginBottom: '5px',
                          }}
                        >
                          {pivot.productRecordsReason?.name}
                        </span>
                      );
                    })}
                  </div>
                </div>
              );
            }
          )
        ) : (
          <div style={{ paddingLeft: '20px' }}>
            <FormattedMessage id="no_product_record" />
          </div>
        )}
      </CardBody>
    </Card>
  );
};
export default ProductRecordShopFloorOrderHistory;
