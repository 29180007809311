import { Input, SIZE } from "baseui/input";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { Col, RowCustom } from "../../../components/FlexBox/FlexBox";
import FormControl from "../../../components/FormWithElemets/FormControl";
import { Negative, Positive } from "../../../components/General/NegativePositive";
import SelectCostCenter from "../../SelectCustom/SelectCostCenter";
import SelectInventoryCategory from "../../SelectCustom/SelectInventoryCategory";
import SelectInventoryType from "../../SelectCustom/SelectInventoryType";
import SelectUnit from "../../SelectCustom/SelectUnit";
interface CommonFormProps{
    errors:any;
    register:any;
    control:any;
}
const CommonForm:FunctionComponent<CommonFormProps>=({errors,register,control})=>{
    return          <RowCustom>
    <Col lg={8}>
      <FormControl
        label={<FormattedMessage id="inventory.name" />}
        error={errors.name ? 'Please input a valid Name' : null}
      >
        <Input
          size={SIZE.compact} 
          name="name"
          inputRef={register({
            required: true,
          })}
          error={errors.name}
          overrides={
            errors.name ? { After: Negative } : { After: Positive }
          }
        />
      </FormControl>
    </Col>
    <Col lg={4}>
      <SelectUnit control={control} errors={errors} />
    </Col>
    <Col lg={4}>
      <SelectInventoryCategory control={control} errors={errors} />
    </Col> 
    <Col lg={4}>
      <SelectInventoryType control={control} errors={errors} />
    </Col>
    <Col lg={4}>
      <SelectCostCenter control={control} errors={errors} />
    </Col>
  </RowCustom>
}
export default CommonForm