import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
const ImgTopIcon: FunctionComponent<Props & ImgType> = ({ children,...props }) => {
  return (
    <span className="inline-block relative">
      <img {...props} className="h-16 w-16 rounded-md shadow cursor-pointer"/>
      <span className="flex items-center justify-center absolute top-0 right-0 block h-4 w-4 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-gray-400">
        {children}
      </span>
    </span>
  );
};
export default ImgTopIcon;
export type ImgType = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
>;