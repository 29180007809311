import React, { useEffect, useState, FunctionComponent } from 'react';
import { SIZE } from 'baseui/input';
import { Controller } from 'react-hook-form';
import { Select as SelectBaseUI } from 'baseui/select';
import _ from 'lodash';

interface SelectProps {
  data: any;
  controller: any;
  labelValue?: string;
  onfocus?: any;
  productCustom?: boolean | undefined;
  selectGrouped?: boolean | undefined;
  selectJoin?: string | undefined;
}
export const Select: FunctionComponent<SelectProps> = ({
  data,
  controller,
  labelValue,
  productCustom = false,
  selectGrouped = false,
  selectJoin,
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (data) {
      setOptions([]);
      if (selectGrouped) {
        setOptions(() =>
          data
            .filter((selectGroup:any) => _.get(selectGroup, labelValue).length > 0)
            .map((item:any) => {
              const rObj = {};
              rObj[item.name] = _.get(item, labelValue).map((child:any) => {
                return {
                  id: child.id,
                  label: child.name,
                };
              });
              return rObj;
            })
            .reduce((obj:any, value:any) => {
              return { ...obj, ...value };
            }, {})
        );
      } else {
        productCustom
          ? data.map(async (item) => {
              setOptions((options) => [
                ...options,
                {
                  id: item.id.toString(),
                  label:
                    (item.productRevision
                      .map((revisionNo) => {
                        if (revisionNo?.active) {
                          return revisionNo.no;
                        } else {
                          return null;
                        }
                      })
                      .filter((itm) => itm !== null)[0] || '-') +
                    ' - ' +
                    item.name,
                },
              ]);
            })
          : selectJoin
          ? data.map(async (item) => {
              setOptions((options) => [
                ...options,
                {
                  id: item.id.toString(),
                  label:
                    typeof labelValue === 'undefined'
                      ? (selectJoin ? _.get(item, selectJoin) : '') + ' - ' + item.name
                      : _.get(item, labelValue),
                },
              ]);
            })
          : data.map(async (item) => {
              setOptions((options) => [
                ...options,
                {
                  id: item.id.toString(),
                  label:
                    typeof labelValue === 'undefined'
                      ? item.name
                      : _.get(item, labelValue),
                },
              ]);
            });
      }
    }
  }, [data, labelValue, productCustom, selectGrouped, selectJoin]);
  return (
    <Controller
      as={<SelectBaseUI size={SIZE.compact} />}
      rules={{ required: true }}
      options={options}
      {...controller}
      overrides={{
        Root: {
          style: () => {
            return {
              position: 'relative',
            };
          },
        },
        Dropdown: {
          style: () => {
            return {
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              maxHeight: '200px',
            };
          },
        },
      }}
    />
  );
};
