import { useMutation } from '@apollo/react-hooks';
import { faLongArrowAltRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, LABEL_PLACEMENT, STYLE_TYPE } from 'baseui/checkbox';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useToaster } from '../../../../context/toaster-provider';
const UPDATE_OFFER_TRANSPORT_INCLUDED = loader(
  '../../../../graphql/offer/transport/UPDATE_OFFER_TRANSPORT_INCLUDED.graphql'
);
interface IncludeTransportOfferProps {
  transportIncluded: boolean;
  offerId: string;
  handleTransportInclude?: Function;
}
const IncludeTransportOffer: FunctionComponent<IncludeTransportOfferProps> = ({
  transportIncluded,
  offerId,
  handleTransportInclude,
}) => {
  const [updateOfferTransportIncluded] = useMutation(UPDATE_OFFER_TRANSPORT_INCLUDED);
  const { showToaster } = useToaster();
  const [checkedValue, setCheckedValue] = useState(() => transportIncluded || false);
  useEffect(() => {
    checkedValue !== transportIncluded && setCheckedValue(transportIncluded);
  }, [transportIncluded]);

  return (
    <div className="flex items-center">
      <Checkbox
        checked={checkedValue}
        checkmarkType={STYLE_TYPE.toggle_round}
        labelPlacement={LABEL_PLACEMENT.left}
        onChange={(e) => {
          handleTransportInclude && handleTransportInclude();
          setCheckedValue(e.currentTarget.checked);
          updateOfferTransportIncluded({
            variables: {
              id: offerId,
              transportIncluded: e.currentTarget.checked,
            },
          }).then(() => showToaster('updated', 'positive'));
        }}
      >
        <FontAwesomeIcon icon={faLongArrowAltRight} />{' '}
        <FormattedMessage id="offer.transports" />
      </Checkbox>
      <span className="text-xs font-normal">
        <FormattedMessage id="offer.transportsDescription" />
      </span>
    </div>
  );
};
export default IncludeTransportOffer;
