import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { CardBody } from '../../../../../components/FormWithElemets/Card.style';
import { Label, LabelCol } from '../../../../../components/FormWithElemets/Label.style';
import { FormattedMessage } from 'react-intl';
import Button, { COLOR } from '../../../../../components/FormWithElemets/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowToBottom,
  faArrowToTop,
  faEdit,
} from '@fortawesome/pro-regular-svg-icons';
import { Col, ColButton, RowCustom } from '../../../../../components/FlexBox/FlexBox';
import { SubcontractSalesOfferPrice as SubcontractSalesOfferPriceType } from '../../../../../types/SubcontractSalesOfferPrice';
import ItemDetailOfferForm from './ItemDetailOfferForm';
import { handleCeil } from '../../../../Functions/Utilities';

interface ItemDetailOfferProps {
  subcontractSalesOfferPrice: SubcontractSalesOfferPriceType;
}
const ItemDetailOffer: FunctionComponent<ItemDetailOfferProps> = ({
  subcontractSalesOfferPrice,
}) => {
  const [detailShow, setDetailShow] = useState(true);
  const [itemAdd, setItemAdd] = useState(null);
  return (
    <CardBody style={{ marginLeft: '20px', backgroundColor: '#dedede' }}>
      <Label
        style={{ borderBottom: '1px solid ' + COLOR.grey }}
        title={<FormattedMessage id="offerPrice.item" />}
      >
        <Button disabled={!itemAdd} onClick={() => setItemAdd(null)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Button onClick={() => setDetailShow(!detailShow)}>
          <FontAwesomeIcon icon={detailShow ? faArrowToTop : faArrowToBottom} />
        </Button>
      </Label>
      <div style={detailShow ? {} : { display: 'none' }}>
        {itemAdd !== null && (
          <ItemDetailOfferForm itemAdd={itemAdd} setItemAdd={setItemAdd} />
        )}
        {itemAdd === null && (
          <RowCustom>
            <SubcontractSalesOfferPrice
              subcontractSalesOfferPrice={subcontractSalesOfferPrice}
            />
            <ColButton xs>
              <Button
                onClick={() => setItemAdd(subcontractSalesOfferPrice)}
                color={COLOR.green}
              >
                <FontAwesomeIcon icon={faEdit} />
              </Button>
            </ColButton>
          </RowCustom>
        )}
      </div>
    </CardBody>
  );
};
export default ItemDetailOffer;
interface SubcontractSalesOfferMaterialPriceProps {
  subcontractSalesOfferPrice: SubcontractSalesOfferPriceType;
}
export const SubcontractSalesOfferPrice: FunctionComponent<SubcontractSalesOfferMaterialPriceProps> = ({
  subcontractSalesOfferPrice,
}) => {
  const [profitAmount, setProfitAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  useEffect(() => {
    if (subcontractSalesOfferPrice) {
      const { amount, profitRate, discountRate, taxRate } = subcontractSalesOfferPrice;
      const _profitAmount = handleCeil((+amount * +profitRate) / 100);
      setProfitAmount(_profitAmount);
      const _discountAmount = handleCeil(
        ((+amount + +_profitAmount) * +discountRate) / 100
      );
      setDiscountAmount(_discountAmount);
      const _taxAmount = handleCeil(
        ((+amount + +_profitAmount - +_discountAmount) * +taxRate) / 100
      );
      setTaxAmount(_taxAmount);
    }
  }, [subcontractSalesOfferPrice, taxAmount, profitAmount, discountAmount]);
  return (
    <Fragment>
      {/* <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="offerPrice.amountPrice" />}>
          {subcontractSalesOfferPrice?.amount || '0'}
        </LabelCol>
      </Col> 
       <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.profitRate" />
              {' % ' + (subcontractSalesOfferPrice?.profitRate || 0)}
            </>
          }
        >
          {profitAmount || '-'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.discountRate" />
              {' % ' + (subcontractSalesOfferPrice?.discountRate || 0)}
            </>
          }
        >
          {discountAmount || '-'}
        </LabelCol>
      </Col>
     */}
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.taxRate" />
              {' % ' + (subcontractSalesOfferPrice?.taxRate || 0)}
            </>
          }
        >
          {taxAmount || '-'}
        </LabelCol>
      </Col>
    </Fragment>
  );
};
