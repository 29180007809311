import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import FormControl from '../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../components/Input/Input';
import { useToaster } from '../../../context/toaster-provider';
import { ExecutionPlanStepInput } from '../../../types/ExecutionPlan';
import SelectBillOfMaterialInventories from '../../SelectCustom/SelectBillOfMaterialInventories';
import { ComponentStatusContext } from '../ExecutionPlanning/ExecutionPlanning';
const UPDATE_EXECUTION_PLANNING_STEP_INPUT = loader(
  '../../../graphql/executionPlanningStepInput/UPDATE_EXECUTION_PLANNING_STEP_INPUT.graphql'
);
const ADD_EXECUTION_PLANNING_STEP_INPUT = loader(
  '../../../graphql/executionPlanningStepInput/ADD_EXECUTION_PLANNING_STEP_INPUT.graphql'
);
interface ItemFormProps {
  item?: ExecutionPlanStepInput;
  setItemAddShow?: Function;
  setItemId?: Function;
}
const ItemForm: FunctionComponent<ItemFormProps> = ({
  item,
  setItemId,
  setItemAddShow,
  
}) => {
  const { 
    componentStatus,billOfMaterialId,inputMaterialIds
    } = useContext(ComponentStatusContext);
  const { control, errors, handleSubmit, setValue, register } = useForm({
    defaultValues: item
      ? {
          name: item.name,
          inventoryId: [{ id: item.inventory.id, label: item.inventory.name }],
        }
      : {},
  });
  const { showToaster } = useToaster();
  const { select,inventoryTypeId } = SelectBillOfMaterialInventories({
    billOfMaterialId:billOfMaterialId,
    inputMaterialIds,
    name: 'inventoryId',
    control,
    errors,
    required: true,
    creatable: false,
    disabled: false,
  });
  const [update] = useMutation(UPDATE_EXECUTION_PLANNING_STEP_INPUT);
  const [add] = useMutation(ADD_EXECUTION_PLANNING_STEP_INPUT);
  const onSubmit = ({ name, inventoryId }) => {
    if (item) {
      update({
        variables: {
          id: item.id,
          name: name,
          inventoryId: inventoryId[0].id,
          inventoryTypeId: inventoryTypeId || item?.inventoryType?.id || '',
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
    } else {
      add({
        variables: {
          executionPlanStepId: componentStatus.executionPlanningStepId,
          name: name,
          inventoryId: inventoryId[0].id,
          inventoryTypeId: inventoryTypeId,
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleResetForm();
      });
    }
  };
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('name', '');
      setValue('inventoryId', undefined);
      setItemAddShow(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom>
        <Col xs={12} md={6} lg={4}>
          <FormControl label={<FormattedMessage id="executionPlanningStep.inputName" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <Col xs={12} md={6} lg={4}>
          {select}
        </Col>
        <ColButton xs>
          <Button
            style={{
              marginTop: '30px',
            }}
            type="button"
            onClick={() => handleResetForm()}
          >
            <FormattedMessage id="button.close" />
          </Button>
          <Button
            style={{
              marginTop: '30px',
            }}
            type="submit"
          >
            <FormattedMessage id={item ? 'button.update' : 'button.add'} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};

export default ItemForm;
