import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useToaster } from '../../../../context/toaster-provider';
import {  OperationSalesOfferIDs } from '../../../../types/OperationSalesOffer';
import ListItemOperationSalesItem from '../../OperationSales/ListItemOperationSalesItem';
import ItemHeaders from '../../OperationSales/ItemHeaders';
import { Item as ItemType } from '../../OperationSales/OperationSalesOfferAdd';
import UpdateAddOperationSalesItems from './UpdateAddOperationSalesItems';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
const DELETE_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/DELETE_OPERATION_SALES_OFFER.graphql'
);
const GET_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/GET_OPERATION_SALES_OFFER.graphql'
);
const S_GET_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/S_GET_OPERATION_SALES_OFFER.graphql'
);
interface ListOperationSalesItemProps {
  //itemTemps: OperationSalesOffer[];
  offerId: string;
}
const ListOperationSalesItem: FunctionComponent<ListOperationSalesItemProps> = ({
  //itemTemps,
  offerId,
}) => {
  const { showToaster } = useToaster();
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<OperationSalesOfferIDs>(GET_OPERATION_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_OPERATION_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.operationSalesOffer;
        console.log('---------->subcontractSales', newFeedItem);
        return Object.assign({}, prev, {
          operationSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const [itemIndex, setItemIndex] = useState(null);
  const newItemsLength = useMemo(() => {
    return data?.operationSalesOffer?.operationSalesOffer?.length || 0;
  }, [data]);
  const [itemAddShow, setItemAddShow] = useState(false);
  useEffect(()=>{
    setItemAddShow(()=>newItemsLength > 0 ? false : true)
  },[newItemsLength])
  const [items, setItems] = useState<ItemType[]>();
  useEffect(() => {
    data && setItems(() => itemsEdit(data?.operationSalesOffer?.operationSalesOffer));
  }, [data]);
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
  }, []);
  const [remove] = useMutation(DELETE_OPERATION_SALES_OFFER);
  const handleItemDelete = useCallback(
    (index: number) => {
      if (window.confirm('Are you sure !')) {
        remove({
          variables: { id: items[index].id },
        }).then(() => {
          showToaster('deleted', 'positive');
        });
      }
    },
    [items, remove, showToaster]
  );
  return (
    loading ? <StyledSpinnerNext /> : error ? <NoResult /> : data &&
      <Fragment>
        <ItemHeaders
          itemsLenght={newItemsLength}
          setItemAddShow={setItemAddShow}
          itemAddShow={itemAddShow}
        />
        {itemAddShow && (
          <UpdateAddOperationSalesItems
            parentId={offerId}
            itemIndex={itemIndex}
            setClose={setItemAddShow}
          />
        )}
        {items &&
          items.map((item: ItemType, index: number) => {
            return index !== itemIndex ? (
              <ListItemOperationSalesItem
                key={index}
                item={item}
                index={index}
                handleItemDelete={handleItemDelete}
                handleItemEdit={handleItemEdit}
              />
            ) : (
                <UpdateAddOperationSalesItems
                  key={item.id}
                  item={item}
                  itemIndex={itemIndex}
                  setClose={setItemIndex}
                />
              );
          })}
      </Fragment>
  );
};
const itemsEdit = (itemTemps: any) => {
  return itemTemps.map((item: any) => {
    return {
      id: item.id,
      operationId: [{ id: item.operation.id, label: item.operation.name }],
      unitsId: [{ id: item.units.id, label: item.units.name }],
      estimatedDeliveryDate: item.estimatedDeliveryDate,
      qty: item.qty,
      unitPrice: item.unitPrice,
    };
  });
}; 
export default ListOperationSalesItem;
