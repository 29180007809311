import { loader } from 'graphql.macro';

const GET_ORDERS = loader('../../graphql/order/GET_ORDERS.graphql');
const GET_APPROVED_OFFERS = loader('../../graphql/offer/GET_APPROVED_OFFERS.graphql');

export const handleCacheAddOrder = (cache: any, createOrder: any) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  const { approvedOffers } = cache.readQuery({
    query: GET_APPROVED_OFFERS,
  });
  const newApprovedOffers = approvedOffers.filter(
    (item) => item.id !== createOrder?.offer.id
  );
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: orders.concat([createOrder]) },
  });
  cache.writeQuery({
    query: GET_APPROVED_OFFERS,
    data: { approvedOffers: newApprovedOffers },
  });
};

export const handleCacheAddOrderFromOffer = (cache: any, createOrderFromOffer: any) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  const { approvedOffers } = cache.readQuery({
    query: GET_APPROVED_OFFERS,
  });
  const newApprovedOffers = approvedOffers.filter(
    (item) => item.id !== createOrderFromOffer?.offer.id
  );
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: orders.concat([createOrderFromOffer]) },
  });
  cache.writeQuery({
    query: GET_APPROVED_OFFERS,
    data: { approvedOffers: newApprovedOffers },
  });
};

type Item = {
  productId: { id: string }[];
  estimatedDeliveryDateItem: string | Date;
  qty: number;
  id: string | undefined;
};
type NewItem = {
  id: string | undefined;
  productId: string;
  estimatedFinishDate: string | Date;
  qty: number;
};
export const editItems = (items: Item[]) => {
  const newItems: NewItem[] = items.map((item) => {
    let productIdTemp = item.productId && item.productId[0]?.id;
    return {
      id: item.id,
      productId: productIdTemp,
      qty: Number(item.qty),
      estimatedFinishDate: item.estimatedDeliveryDateItem,
    };
  });
  return newItems;
};
export const handleAddOrder = (cache, createOrder) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: orders.concat([createOrder]) },
  });
};
export const handleUpdateOrder = (cache, updateOrder) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  const newOrders = orders.map((item) => {
    if (item.id === updateOrder.id) {
      return updateOrder;
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: newOrders },
  });
};
export const handleDeleteOrderDetail = (cache, deleteOrderDetail) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  const newOrders = orders.map((item) => {
    if (item.id === deleteOrderDetail.order.id) {
      return {
        ...item,
        orderDetail: item.orderDetail.map((detail) => detail.id !== deleteOrderDetail.id),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: newOrders },
  });
};
export const handleAddShopFloorOrder = (
  cache,
  orderId,
  orderDetailId,
  createShopFloorOrder
) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  const newOrders = orders.map((item) => {
    if (item.id === orderId) {
      return {
        ...item,
        orderDetail: item.orderDetail.map((detail) => {
          if (detail.id === orderDetailId) {
            return {
              ...detail,
              shopFloorOrder: detail.shopFloorOrder.concat(createShopFloorOrder),
            };
          } else {
            return detail;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: newOrders },
  });
};

export const handleUpdateShopFloorOrder = (
  cache,
  orderId,
  orderDetailId,
  updateShopFloorOrder
) => {
  const { orders } = cache.readQuery({
    query: GET_ORDERS,
  });
  const newOrders = orders.map((item) => {
    if (item.id === orderId) {
      return {
        ...item,
        orderDetail: item.orderDetail.map((detail) => {
          if (detail.id === orderDetailId) {
            return {
              ...detail,
              shopFloorOrder: detail.shopFloorOrder.map((shpFlrOrder) => {
                if (shpFlrOrder.id === updateShopFloorOrder.id) {
                  return updateShopFloorOrder;
                } else {
                  return shpFlrOrder;
                }
              }),
            };
          } else {
            return detail;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_ORDERS,
    data: { orders: newOrders },
  });
};
