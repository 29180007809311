import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMutation } from '@apollo/react-hooks';
import { faArrowLeft, faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { DivButton } from '../../../components/FlexBox/FlexBox';
import { CardBody } from '../../../components/FormWithElemets/Card.style';
import { useToaster } from '../../../context/toaster-provider';
import ItemForm from './ItemForm';
import ListItem from './ListItem';
import Button from '../../../components/FormWithElemets/Button/Button';
import { ComponentStatusContext } from '../ExecutionPlanning/ExecutionPlanning';
import BreadcrumbsCustom from '../ExecutionPlanning/BreadcrumbsCustom';
import { Label } from '../../../components/FormWithElemets/Label.style';

const DELETE_EXECUTION_PLANNING_STEP_OUTPUT = loader(
  '../../../graphql/executionPlanningStepOutput/DELETE_EXECUTION_PLANNING_STEP_OUTPUT.graphql'
);

const ListOutput: FunctionComponent = () => {
  const { dispatchComponentStatus, componentStatus } = useContext(ComponentStatusContext);
  const [items, setItems] = useState(() => componentStatus?.data);
  useEffect(() => {
    setItems(() => componentStatus?.data);
  }, [componentStatus]);
  const { showToaster } = useToaster();
  const [itemId, setItemId] = useState(null);
  const [itemAddShow, setItemAddShow] = useState(() =>
    componentStatus?.data.length > 0 ? false : true
  );
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
    setItemAddShow(false);
  }, []);
  const [remove] = useMutation(DELETE_EXECUTION_PLANNING_STEP_OUTPUT);
  const handleItemDelete = useCallback((id: number) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: id },
      }).then(() => {
        showToaster('deleted', 'positive');
      });
    }
  }, [showToaster, remove]);
  return (
    <CardBody>
      <Label title={<BreadcrumbsCustom inputOrOutput={componentStatus?.component} dispatch={dispatchComponentStatus} />}>
        <Button
          type="button"
          onClick={() => {
            dispatchComponentStatus({
              type: 'STEP',
              data: null,
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Button
          type="button"
          onClick={() => {
            setItemAddShow(!itemAddShow);
          }}
        >
          <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
        </Button>
      </Label>
      {itemAddShow && <ItemForm setItemAddShow={setItemAddShow} />}
      {items &&
        items.map((item: any) => {
          return item.id !== itemId ? (
            <ListItem
              key={item.id}
              item={item}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
            />
          ) : (
              <ItemForm key={item.id} item={item} setItemId={setItemId} />
            );
        })}
    </CardBody>
  );
};
export default ListOutput;
