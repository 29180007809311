import React, { Component,  ReactNode } from 'react';
interface CardProps {
  children: ReactNode;
  ref?: any;
  className?:string
}
class Card extends Component<CardProps> {
  render() {
    const { ref, children,className } = this.props;
    return (
      <div
        ref={ref}
        className={"rounded-md mb-20 pb-1 print:relative print:h-screen bg-white " + className}
      >
        {children}
      </div>
    );
  }
}
export default Card;
