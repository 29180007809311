import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  DivButtonTitle,
  RowTitle,
} from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';

interface ItemHeaderProps {
  itemsLenght: number;
  setItemAddShow?: Function;
  itemAddShow?: boolean;
}
const BillOfMaterialItemHeaders: FunctionComponent<ItemHeaderProps> = memo(
  ({ itemsLenght, setItemAddShow, itemAddShow }) => {
    return (
      <Fragment>
        <RowTitle style={{ marginTop: '20px' }}>
          {itemsLenght > 0 && (
            <Fragment>
              <Col xs={12} md={7} lg={4}>
                <FormattedMessage id="inventory.materialItemInventoryId" />
              </Col>
              <Col xs={12} md={5} lg={3}>
                <FormattedMessage id="select.inventoryType" />
              </Col>
              <Col sm={6} md={4} lg={2}>
                <FormattedMessage id="materialItem.partNumber" />
              </Col>
              <Col  sm={6} md={4} lg={2}>
                <FormattedMessage id="materialItem.quantity" />
              </Col>
              {/* <Col sm={9} lg={3}>
                <FormattedMessage id="company.vendor" />
              </Col> */}
            </Fragment>
          )} 
          <ColButton xs>
            <Button
              type="button"
              onClick={() => {
                setItemAddShow(!itemAddShow);
              }}
            >
              <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
            </Button>
          </ColButton>
        </RowTitle>
        <DivButtonTitle>
          <Button
            type="button"
            onClick={() => {
              setItemAddShow(!itemAddShow);
            }}
          >
            <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
          </Button>
        </DivButtonTitle>
      </Fragment>
    );
  }
);
export default BillOfMaterialItemHeaders;
