import { useQuery } from '@apollo/react-hooks';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import React, {
    Fragment,
    FunctionComponent,
    memo,
    useEffect,
    useState,
  } from 'react';
import { CardBody } from '../../../components/FormWithElemets/Card.style';
import NoResult from '../../../components/NoResult/NoResult';
import { OfferTransport } from '../../../types/OfferAndDetailsWithOperation';
import { ProductSalesOffer, ProductSalesOfferIDs } from '../../../types/ProductSalesOffer';
import ListProductSalesPreview from '../InOfferStage/ProductSales/Preview/ListProductSalesPreview';
import ProductSalesResult from '../InOfferStage/ProductSales/ProductSalesResult';

  const GET_PRODUCT_SALES_OFFER = loader(
    '../../../graphql/offer/productSales/GET_PRODUCT_SALES_OFFER.graphql'
  );
  const S_GET_PRODUCT_SALES_OFFER = loader(
    '../../../graphql/offer/productSales/S_GET_PRODUCT_SALES_OFFER.graphql'
  );
  interface ProductSalesProps {
    offerId: string;
  }
  const ProductSales: FunctionComponent<ProductSalesProps> = ({
    offerId,
  }) => {
    const {
      subscribeToMore,
      data,
      loading,
      error,
    } = useQuery<ProductSalesOfferIDs>(GET_PRODUCT_SALES_OFFER, {
      variables: {
        id: offerId,
      },
    });
    useEffect(() => {
      subscribeToMore({
        document: S_GET_PRODUCT_SALES_OFFER,
        variables: {
          id: offerId,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.productSalesOffer;
          return Object.assign({}, prev, {
            productSalesOffer: newFeedItem,
          });
        },
      });
    }, [subscribeToMore, offerId]);
    const [transportUnitPrice, setTransportUnitPrice] = useState(0);
    useEffect(() => {
      if (data) {
        const {
          productSalesOffer: { productSalesOffer },
        } = data;
        setTransportUnitPrice(
          handleTransportUnitPrice(
            data.productSalesOffer.transportIncluded,
            data.productSalesOffer.offerTransport,
            productSalesOffer
          )
        );
      }
    }, [data]);
    return (
      <Fragment>
        {loading ? (
          <StyledSpinnerNext />
        ) : error ? (
          <NoResult />
        ) : (
              data && (
                <Fragment>
                  <CardBody>
                    <ListProductSalesPreview items={data?.productSalesOffer.productSalesOffer} transportUnitPrice={transportUnitPrice} />
                  </CardBody>
                  <ProductSalesResult
                    offerTransport={data.productSalesOffer.offerTransport}
                    items={data?.productSalesOffer?.productSalesOffer}
                    transportIncluded={data.productSalesOffer.transportIncluded}
                  />
                </Fragment>
              )
            )}
      </Fragment>
    );
  };
  
  export default memo(ProductSales);
  const handleTransportUnitPrice = (
    transportIncluded: boolean,
    offerTransport: OfferTransport[],
    items: ProductSalesOffer[]
  ) => {
    const offerTransportTotalPrice = transportIncluded
      ? offerTransport.reduce(
        (acc: number, item: OfferTransport) =>
          item.approved === true ? acc + item.price : 0,
        0
      )
      : 0;
    const orderQty =
      items.reduce(
        (acc: number, item: ProductSalesOffer) => acc + (item?.qty || 0),
        0
      ) || 1;
    const transportUnitPrice = offerTransportTotalPrice / orderQty;
    return transportUnitPrice;
  };
  