import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import FormControl from '../../components/FormWithElemets/FormControl';
import { FormattedMessage } from 'react-intl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { TYPE } from 'baseui/select';
import { SearchProduct } from '../../types/Product';
import { debounce } from '../Functions/Utilities';
const SEARCH_PRODUCTS = loader('../../graphql/product/SEARCH_PRODUCTS.graphql');
interface ListProps {
  control: any;
  errors: any;
  required: boolean;
  creatable: boolean;
  disabled?: boolean | undefined;
}
const SelectProduct = ({ control, errors, required, creatable, disabled }: ListProps) => {
  const [loadOptions, { data: dataSearchProduct, loading }] = useLazyQuery<SearchProduct>(
    SEARCH_PRODUCTS
  );
  useEffect(() => {
    loadOptions({
      variables: {
        full: false,
        name: null,
      },
    });
  }, [loadOptions]);
  const [unit, setUnit] = useState<{id:string,label:string}[]>(undefined);
  const [productRevison, setProductRevison] = useState('');
  const handleChangeInventory = ({ value }) => {
    if (dataSearchProduct) {
      const productItem = dataSearchProduct.searchProduct.find(
        (item) => item.id === value[0]?.id
      );
      if (productItem) {
        setUnit([
          {
            id: productItem?.inventory?.units?.id,
            label: productItem?.inventory?.units?.name,
          },
        ]);
        setProductRevison(
          productItem.productRevision.find((revision) => revision.active === true).id
        );
      }
    }
  };
  const handleInputChange = debounce(function (term: string) {
    setUnit(undefined);
    loadOptions({
      variables: {
        full: false,
        name: term ? term : null,
      },
    });
  });
  return {
    select: (
      <FormControl
        label={<FormattedMessage id="offer.item" />}
        error={errors.productId ? 'Please input a valid Product' : null}
      >
        <Select
          data={dataSearchProduct && dataSearchProduct.searchProduct}
          productCustom={true}
          controller={{
            name: 'productId',
            type: TYPE.search,
            control,
            isLoading: loading,
            rules: { required: required },
            creatable: creatable,
            searchable: true,
            filterOutSelected: false,
            disabled: disabled,
            onChange: ([selected]) => {
              handleChangeInventory(selected);
              return selected?.option ? selected.value : undefined;
            },
            onInputChange: (event: React.SyntheticEvent) => {
              const target = event.target as HTMLInputElement;
              handleInputChange(target.value);
            },
          }}
        />
      </FormControl>
    ),
    productRevison: productRevison,
    unit: unit,
  };
};
export default SelectProduct;
