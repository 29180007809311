import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
//import { useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import { FileUploader } from 'baseui/file-uploader';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { BlockProps } from 'baseui/block';
import { useDrawerDispatch } from '../../context/DrawerContext';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../components/NoResult/NoResult';
import { ProductImage, ProductImages } from '../../types/ProductImage';
import Button, { COLOR } from '../../components/FormWithElemets/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTimesOctagon } from '@fortawesome/pro-regular-svg-icons';
import { styled } from 'styletron-react';
import { useToaster } from '../../context/toaster-provider';

const UPLOAD_PRODUCT_IMAGE = loader(
  '../../graphql/product/upload/UPLOAD_PRODUCT_IMAGE.graphql'
);
const S_PRODUCT_IMAGES = loader('../../graphql/product/upload/S_PRODUCT_IMAGES.graphql');
const PRODUCT_IMAGES = loader('../../graphql/product/upload/PRODUCT_IMAGES.graphql');
const DELETE_IMAGE = loader('../../graphql/product/upload/DELETE_IMAGE.graphql');

interface ListProps {
  productId: string;
}

const TechnicalImages: FunctionComponent<ListProps> = ({ productId }) => {
  const { subscribeToMore, data: dataImages, loading, error } = useQuery<ProductImages>(
    PRODUCT_IMAGES,
    {
      variables: {
        id: productId,
      },
    }
  );
  const { showToaster } = useToaster();

  const [dataTechnicalImages, setDataTechnicalImages] = useState(null);
  useEffect(() => {
    dataImages && setDataTechnicalImages(dataImages);
  }, [dataImages]);
  const dispatch = useDrawerDispatch();
  const [uploading, setUploading] = useState(false);
  const [data, setData] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [uploadFileMutation] = useMutation(UPLOAD_PRODUCT_IMAGE);
  useEffect(() => {
    subscribeToMore({
      document: S_PRODUCT_IMAGES,
      variables: {
        id: productId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        setDataTechnicalImages(subscriptionData.data);
      },
    });
  }, [subscribeToMore, productId]);
  const handleUpload = async (uploadFile: any) => {
    console.log('uploadFile', uploadFile);
    setUploading(true);
    const options = {
      variables: {
        productId: productId,
        image: uploadFile[0],
      },
    };
    try {
      const response = await uploadFileMutation(options);
      if (!(response && response.data)) {
        return;
      }
      const parsedResponse = JSON.parse(response.data.uploadFile);
      setUploading(false);
      setData(parsedResponse);
    } catch (err) {
      setUploading(false);
      setData([]);
    }
  };
  const openDrawer = useCallback(
    ({ item }) => {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'TECHICAL_IMAGES_PREVIEW',
        data: { url: item.url, fileType: item.fileType },
        widthComponent: '80%',
      });
    },
    [dispatch]
  );
  // const onChange = ({
  //   target: {
  //     validity,
  //     files: [file],
  //   },
  // }) => {
  //   if (validity.valid) {
  //     uploadFileMutation({
  //       variables: {
  //         productId: productId,
  //         image: file,
  //       },
  //     });
  //   }
  // };
  const [remove] = useMutation(DELETE_IMAGE);
  const handleRemove = (Id) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
      }).then(() => showToaster('deleted', 'positive'));;
    }
  };
  const itemProps: BlockProps = {
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <CardBody style={{ padding: '10px' }}>
      <CardBody style={{ marginBottom: '5px' }}>
        {loading ? (
          <StyledSpinnerNext />
        ) : error ? (
          <NoResult />
        ) : (
          <FlexGrid
            flexGridColumnCount={[1, 4, 8]}
            flexGridColumnGap="scale800"
            flexGridRowGap="scale800"
          >
            {dataTechnicalImages &&
              dataTechnicalImages?.productImages.map(
                (item: ProductImage, index: number) => (
                  <FlexGridItem key={item.id} {...itemProps} $style={{}}>
                    <DivTechnicalImages>
                      <div
                        style={{
                          flexGrow: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                        }}
                      >
                        {item.fileType === 'pdf' ? (
                          <div style={{ height: '85%' }}>
                            <Button
                              type="button"
                              onClick={() => openDrawer({ item })}
                              color={COLOR.transparent}
                              style={{ margin: 0, padding: 0 }}
                            >
                              <FontAwesomeIcon
                                icon={faFilePdf}
                                size="3x"
                                color={COLOR.grey}
                              />
                            </Button>
                          </div>
                        ) : (
                          <img
                            src={item.url}
                            onClick={() => openDrawer({ item })}
                            width="100%"
                            height="85%"
                            alt="technical"
                            style={{
                              cursor: 'pointer',
                              border: '1px solid rgba(215,218,215,1)',
                            }}
                          />
                        )}
                      </div>
                      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Button
                          type="button"
                          onClick={() => handleRemove(item.id)}
                          color={COLOR.transparent}
                          style={{ margin: 0, padding: 0 }}
                        >
                          <FontAwesomeIcon
                            icon={faTimesOctagon}
                            size="1x"
                            color={COLOR.grey}
                          />
                        </Button>
                      </div>
                    </DivTechnicalImages>
                  </FlexGridItem>
                )
              )}
          </FlexGrid>
        )}
      </CardBody>
      <FileUploader
        errorMessage={errorMessage}
        onDrop={(acceptedFiles, rejectedFiles) => {
          console.log(acceptedFiles, rejectedFiles);
          handleUpload(acceptedFiles).then(() => console.log('upload bitti'));
        }}
      />
    </CardBody>
  );
};
export default TechnicalImages;
export const DivTechnicalImages = styled('div', () => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'flex-start',
  backgroundColor: 'rgb(247, 247, 247)',
  width: '100%',
  height: '100%',
  padding: '2px',
  borderRadius: '3px',
  ':hover': {
    width: '108%',
    height: '108%',
    boxShadow: '0px 0px 0.3px 0.3px rgba(215,218,215,1)',
    backgroundColor: '#ffffff',
  },
  overflow: 'hidden',
}));
