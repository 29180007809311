import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from '../../../../../components/FlexBox/FlexBox';
import { LabelCol } from '../../../../../components/FormWithElemets/Label.style';
import { SubcontractSalesOfferOperationPrice } from '../../../../../types/SubcontractSalesOfferOperationPrice';
import { ContextSubcontractSalesOfferId } from '../ListItemSuncontractSales';

interface SubcontractSalesOfferOperationPriceProps {
  itemPrice: SubcontractSalesOfferOperationPrice;
  duration: number;
  external: boolean;
  executionPlanStepId: string;
  subcontractSalesOfferPriceId: string;
  operationId: string;
  priceUpdate: Function;
  purchaseOfferRecord: {
    qty: number;
    unitPrice: number; 
    currency: string;
  };
}
const ItemDateilOperationPrice: FunctionComponent<SubcontractSalesOfferOperationPriceProps> = ({
  itemPrice,
  duration,
  purchaseOfferRecord,
  operationId,
  subcontractSalesOfferPriceId,
  executionPlanStepId,
  priceUpdate,
  external,
}) => {
  const { subcontractSalesOfferId } = useContext(
    ContextSubcontractSalesOfferId
  );
  const [profitAmount, setProfitAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  useEffect(() => {
    if (itemPrice) {
      const { amount, profitRate, discountRate } = itemPrice;
      const _profitAmount =
        Math.ceil(100 * ((amount * profitRate) / 100)) / 100;
      setProfitAmount(_profitAmount);
      const _discountAmount =
        Math.ceil((100 * ((+amount + +_profitAmount) * discountRate)) / 100) /
        100;
      setDiscountAmount(_discountAmount);
    }
  }, [itemPrice, profitAmount, discountAmount]);
  const { unitPrice, currency } = purchaseOfferRecord;
  useEffect(() => {
    if (external && unitPrice) {
      if (unitPrice !== itemPrice?.amount) {
        priceUpdate({
          executionPlanStepId,
          itemPrice,
          operationId,
          unitPrice: unitPrice,
          subcontractSalesOfferId,
          subcontractSalesOfferPriceId,
        });
      }
    }
  }, [itemPrice, unitPrice]);
  return (
    <Fragment>
      <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="operationPrice.duration" />}>
          {external ? '-' : duration || '-'}{' '}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="operationPrice.unitPrice" />}>
          {external ? '-' : (itemPrice?.unitPrice || 0) + ' ' + currency || '-'}{' '}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="operationPrice.amountPrice" />}>
          {(itemPrice?.amount || '0') + ' ' + currency}{' '}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="operationPrice.profitRate" />{' '}
              {' % ' + (itemPrice?.profitRate || 0)}
            </>
          }
        >
          {profitAmount || '-'}{' '}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="operationPrice.discountRate" />
              {' % ' + (itemPrice?.discountRate || 0)}
            </>
          }
        >
          {discountAmount || '-'}{' '}
        </LabelCol>
      </Col>
    </Fragment>
  );
};
export default ItemDateilOperationPrice;
