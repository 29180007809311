import React, { FunctionComponent, ReactNode } from 'react';
import { AnchorType } from './Breadcrumbs';

interface Props {
  children: ReactNode;
}
const Breadcrumb: FunctionComponent<Props & AnchorType> = ({ children,...props }) => {
  return (
    <li>
      <div className="flex items-center">
        <svg
          className="flex-shrink-0 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fill-rule="evenodd"
            d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
            clip-rule="evenodd"
          />
        </svg>
        <a
          aria-current="page"
          className="cursor-pointer ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
          {...props}
        >
          {children}
        </a>
      </div>
    </li>
  );
};
export default Breadcrumb;