import React, { FunctionComponent } from 'react';

interface FormProps {
  className?: string;
}
const Form: FunctionComponent<FormProps & typeForm> = ({
  children,
  className = '',
  ...props
}) => {
  return (
    <form {...props} className={'space-y-8 divide-y divide-gray-200 ' + className}>
      <div className="shadow  sm:rounded-md">
        <div className="px-4 py-5 bg-white sm:p-6">
          {children}
        </div>
      </div>
    </form>
  );
};
type typeForm = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;
export default Form;
