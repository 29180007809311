import React, { useCallback, useEffect, FunctionComponent, useState } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { ipcRenderer } from '../../appRuntime';
import { handleAddProductRecord } from '../ShopFloor/GraphqlFunction';
import { shopFloorOrderImproper, shopFloorOrderWip } from '../../barcode/Barcode';
import { useToaster } from '../../context/toaster-provider';
import SelectShift from '../SelectCustom/SelectShift';
import { ProductRecord } from '../../types/ProductRecord';
import SelectProductRecordReason from '../SelectCustom/SelectProductRecordReason';

const GET_PRODUCT_RECORDS = loader(
  '../../graphql/productRecord/GET_PRODUCT_RECORDS.graphql'
);
const ADD_PRODUCT_RECORD = loader(
  '../../graphql/productRecord/ADD_PRODUCT_RECORD.graphql'
);
const UPDATE_PRODUCT_RECORD = loader(
  '../../graphql/productRecord/UPDATE_PRODUCT_RECORD.graphql'
);

const ProductRecordForm: FunctionComponent = () => {
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  return (
    <Card
      style={{
        padding: '30px 5px',
      }}
      title={
        <FormattedMessage
          id={
            transactionsReasonDispatch === 'productRecordUpdate'
              ? 'product_record.update'
              : transactionsReasonDispatch === 'productRecordAddImproper'
                ? 'product_record_improper.add'
                : transactionsReasonDispatch === 'productRecordAddWip' &&
                'product_record_wip.create'
          }
        />
      }
    >
      <ProductRecordFormTemp
        dispatch={dispatch}
        transactionsReasonDispatch={transactionsReasonDispatch}
        dataDispatch={dataDispatch}
      />
    </Card>
  );
};
export default ProductRecordForm;

interface ListProductRecordFormPropsTemp {
  dispatch: any;
  transactionsReasonDispatch: any;
  dataDispatch: any;
}
export const ProductRecordFormTemp: FunctionComponent<ListProductRecordFormPropsTemp> = ({
  dispatch,
  dataDispatch,
  transactionsReasonDispatch,
}) => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [printerData, setPrinterData] = useState({
    wipBarcode: '',
    improperBarcode: '',
  });
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (transactionsReasonDispatch === 'productRecordUpdate') {
      setValue('wip', dataDispatch.wip);
      setValue('improper', dataDispatch.improper);
      const _shiftId = dataDispatch?.shift ? [{ id: dataDispatch?.shift.id, label: dataDispatch?.shift.label }] : undefined;
      setValue('shiftId', _shiftId)
    }
  }, [dataDispatch, setValue, transactionsReasonDispatch]);
  const onSubmit = ({ wip, improper, productRecordReason, shiftId }) => {
    transactionsReasonDispatch === 'productRecordUpdate'
      ? updateProductRecord({
        variables: {
          id: dataDispatch.id,
          wip: Number(wip),
          improper: Number(improper),
          shiftId: shiftId[0].id,
        },
      })
        .then(() => showToaster('updated', 'positive'))
        .catch((error) => {
          console.log('error', error); // <-- OK
          showToaster(error, 'positive');
        })
      : addProductRecord({
        variables: {
          wip: wip ? Number(wip) : 0,
          improper: improper ? Number(improper) : 0,
          shopFloorOrderId: dataDispatch.shopFloorOrderId,
          productRecordReason: productRecordReason?.map((item: any) => {
            return {
              id: item.id,
            };
          }),
        },
      })
        .then(() => showToaster('created', 'positive'))
        .catch((error) => {
          console.log('error', error); // <-- OK
          showToaster(error, 'positive');
        });
  };
  const [addProductRecord] = useMutation(ADD_PRODUCT_RECORD, {
    update(cache, { data: { createProductRecord } }) {
      handleAddProductRecord(
        cache,
        dataDispatch.shopFloorId,
        dataDispatch.shopFloorOrderId,
        dataDispatch.machineryAndEquipmentId,
        createProductRecord
      );
      ipcRenderer &&
        setPrinterData(() => {
          return {
            wipBarcode:
              createProductRecord.wip > 0
                ? shopFloorOrderWip({
                  productRecordId: createProductRecord?.id?.toUpperCase(),
                  product: dataDispatch.product,
                  qtyOrders: dataDispatch.shopFloorOrderQty,
                  qtyProduction: createProductRecord?.wip || 0,
                  user:
                    createProductRecord?.user?.name +
                    ' ' +
                    createProductRecord?.user?.surname,
                  shift: createProductRecord?.shift?.name || '',
                  processingTime: createProductRecord?.insertedAt || '',
                })
                : '',
            improperBarcode:
              createProductRecord.improper > 0
                ? shopFloorOrderImproper({
                  productRecordId: createProductRecord?.id?.toUpperCase(),
                  product: dataDispatch.product,
                  qtyOrders: dataDispatch.shopFloorOrderQty,
                  qtyImproper: createProductRecord?.improper || 0,
                  user:
                    createProductRecord?.user?.name +
                    ' ' +
                    createProductRecord?.user?.surname,
                  shift: createProductRecord?.shift?.name || '',
                  processingTime: createProductRecord?.insertedAt || '',
                })
                : '',
          };
        });
      closeDrawer();
    },
  });
  useEffect(() => {
    if (ipcRenderer) {
      printerData.wipBarcode !== '' &&
        ipcRenderer.send('print-barcode', {
          data: printerData.wipBarcode,
          printer: localStorage.getItem('barcode'),
        });
      printerData.improperBarcode !== '' &&
        ipcRenderer.send('print-barcode', {
          data: printerData.improperBarcode,
          printer: localStorage.getItem('barcode'),
        });
    }
  }, [printerData]);
  const [updateProductRecord] = useMutation(UPDATE_PRODUCT_RECORD, {
    update(cache, { data: { updateProductRecord } }) {
      const { productRecords } = cache.readQuery({
        query: GET_PRODUCT_RECORDS,
      });
      const newProductRecords = productRecords.map((item: ProductRecord) => {
        if (dataDispatch.id === item.id) {
          return updateProductRecord;
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_PRODUCT_RECORDS,
        data: { productRecords: newProductRecords },
      });
      closeDrawer();
    },
  });
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Row style={{ margin: 0, padding: 0 }}>
        {transactionsReasonDispatch === 'productRecordUpdate' ? (
          <UpdateProductRecordForm
            control={control}
            register={register}
            errors={errors}
          />
        ) : transactionsReasonDispatch === 'productRecordAddWip' ? (
          <AddWipProductRecordForm register={register} errors={errors} />
        ) : (
              transactionsReasonDispatch === 'productRecordAddImproper' && (
                <AddImproperProductRecordForm
                  register={register}
                  errors={errors}
                  control={control}
                />
              )
            )}
        <Col md={12}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            {/* {ipcRenderer && (
                <Button
                  type="button"
                  onClick={() => {
                    printerData.wipBarcode !== '' &&
                      ipcRenderer.send('print-barcode', {
                        data: printerData.wipBarcode,
                        printer: localStorage.getItem('barcode'),
                      });
                    printerData.improperBarcode !== '' &&
                      ipcRenderer.send('print-barcode', {
                        data: printerData.improperBarcode,
                        printer: localStorage.getItem('barcode'),
                      });
                  }}
                  disabled={!(printerData.wipBarcode || printerData.improperBarcode)}
                >
                  <FormattedMessage id="button.printer_for" />
                </Button>
              )} */}
            <Button type="button" onClick={closeDrawer}>
              <FormattedMessage id="button.close" />
            </Button>{' '}
            <Button type="submit">
              <FormattedMessage
                id={
                  transactionsReasonDispatch === 'productRecordUpdate'
                    ? 'button.update'
                    : 'button.save'
                }
              />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

interface ListUpdateProps {
  errors: any;
  register: any;
  control: any;
}
export const UpdateProductRecordForm: FunctionComponent<ListUpdateProps> = ({
  errors,
  register,
  control,
}) => {
  return (
    <>
      <Col sm={6}>
        <FormControl
          label={<FormattedMessage id="product_record.wip" />}
          error={errors.wip ? 'Please input a valid Wip' : null}
        >
          <Input
            size={SIZE.compact}
            name="wip"
            inputRef={register({
              required: true,
            })}
            error={errors.wip}
            overrides={errors.wip ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
      <Col sm={6}>
        <FormControl
          label={<FormattedMessage id="product_record.improper" />}
          error={errors.improper ? 'Please input a valid Improper' : null}
        >
          <Input
            size={SIZE.compact}
            name="improper"
            inputRef={register({
              required: true,
            })}
            error={errors.improper}
            overrides={errors.improper ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
      <Col md={12}>
        <SelectShift control={control} />
      </Col>
    </>
  );
};
interface ListAddWipProps {
  errors: any;
  register: any;
}
export const AddWipProductRecordForm: FunctionComponent<ListAddWipProps> = ({
  errors,
  register,
}) => {
  return (
    <Col sm={6}>
      <FormControl
        label={<FormattedMessage id="product_record.wip" />}
        error={errors.wip ? 'Please input a valid Wip' : null}
      >
        <Input
          size={SIZE.compact}
          name="wip"
          inputRef={register({
            required: true,
          })}
          error={errors.wip}
          overrides={errors.wip ? { After: Negative } : { After: Positive }}
        />
      </FormControl>
    </Col>
  );
};
interface ListAddImproperProps {
  errors: any;
  register: any;
  control: any;
}
export const AddImproperProductRecordForm: FunctionComponent<ListAddImproperProps> = ({
  errors,
  register,
  control,
}) => {
  return (
    <>
      <Col sm={12}>
        <FormControl
          label={<FormattedMessage id="product_record.wip" />}
          error={errors.wip ? 'Please input a valid Wip' : null}
        >
          <Input
            size={SIZE.compact}
            name="wip"
            inputRef={register({
              required: true,
            })}
            error={errors.wip}
            overrides={errors.wip ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
      <Col sm={12}>
        <SelectProductRecordReason errors={errors} control={control} />
      </Col>
    </>
  );
};
