import React, { FunctionComponent, useState } from 'react';
import { Datepicker } from 'baseui/datepicker';
import { SIZE } from 'baseui/input';
import { Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import FormControl from '../../components/FormWithElemets/FormControl';
import tr from 'date-fns/locale/tr';
import _ from 'lodash';
import { Negative, Positive } from '../../components/General/NegativePositive';

interface DateCustomProps {
  errors: any;
  control: any;
  name: string;
  formLabel?: boolean;
  dateValue?: string;
  clearable?:boolean;
}
const DateCustom: FunctionComponent<DateCustomProps> = ({
  errors,
  control,
  formLabel=true,
  name,
  dateValue,
  clearable=false
}) => {
  const [specificDate, setSpecificDate] = useState(dateValue ? [new Date(dateValue)]: undefined);
  return (
    <FormControl
      label={formLabel ? <FormattedMessage id={"date."+name} /> : null}
      error={_.get(errors, name) ? 'Please Select a valid' : null}
    >
      <Controller
        as={
          <Datepicker
            size={SIZE.compact}
            locale={tr}
            mask={null}
            value={specificDate}
            formatString="d/MM/yyyy"
          />
        }
        control={control}
        name={name}
        clearable={clearable}
        rules={{ required: true }}
        valueName="selected"
        onChange={([selected]) => {
          setSpecificDate(selected?.date);
          return selected?.date ? [selected?.date] : undefined;
        }}
        error={_.get(errors, name)}
        overrides={_.get(errors, name) ? { After: Negative } : { After: Positive }}
      />
    </FormControl>
  );
};
export default DateCustom;
