import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  BillOfMaterialType,
} from '../../../../types/BillOfMaterial';
import UpdateBillOfMaterialForm from '../../UpdateBillOfMaterialForm';
import ListItemBillOfMaterialRow from '../../ListItemBillOfMaterialRow';
import { useIntl } from 'react-intl';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import ExecutionPlanning from '../../../ExecutionPlanning/ExecutionPlanning/ExecutionPlanning';
import ListPurchaseOffer from '../ListPurhaseOffer';
import { OfferPurchaseOffer } from '../../../../types/Offer';
import { loader } from 'graphql.macro';
import ListAndAddTechicalImagesCustom from '../../../TechicalImages/ListAndAddTechicalImagesCustom';
import BillOfMaterialTechicalImages from './BillOfMaterialTechicalImages';
const DELETE_BILL_OF_MATERIAL_ATTACHMENT = loader(
  '../../../../graphql/uploadBillOfMaterial/DELETE_BILL_OF_MATERIAL_ATTACHMENT.graphql'
);
const UPLOAD_BILL_OF_MATERIAL_ATTACHMENT = loader(
  '../../../../graphql/uploadBillOfMaterial/UPLOAD_BILL_OF_MATERIAL_ATTACHMENT.graphql'
);
interface ListProps {
  item: BillOfMaterialType;
  offerPurchaseOffer: OfferPurchaseOffer;
  offerId: string;
  componentStatus: string;
  setComponentStatus: Function;
  orderQty:number;
}
const ItemBillOfMaterial: FunctionComponent<ListProps> = ({
  item,
  offerPurchaseOffer,
  offerId,
  componentStatus,
  setComponentStatus,
  orderQty,
}) => {
  const intl = useIntl();
  //const pageEndRef = useRef(null);
  const [itemId, setItemId] = useState(null);
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
  }, []);
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    },
    {
      label: intl.formatMessage({ id: 'billOfMaterial.purhaseOffer' }),
      function: () => {
        setComponentStatus('purhaseOffer');
      },
      check_type: '',
    },
    {
      label: intl.formatMessage({ id: 'billOfMaterial.executionPlanning' }),
      function: () => {
        setComponentStatus('executionPlanning');
      },
      check_type: '',
    },
    {
      label: intl.formatMessage({ id: 'billOfMaterial.images' }),
      function: () => {
        setComponentStatus('images');
      },
      check_type: 'IMAGES',
    },
  ];

  return (
    <Fragment>
      {itemId !== item.id ? (
        <ListItemBillOfMaterialRow item={item} buttons={buttons} />
      ) : (
        <UpdateBillOfMaterialForm item={item} setItemId={setItemId} />
      )}
      <CardBody style={{ margin: '5px', padding: 0 }}>
        {componentStatus === 'images' ? (
          <ListAndAddTechicalImagesCustom
            imajes={item?.billOfMaterialAttachment}
            parentVariables={{ billOfMaterialId: item.id }} // örenek { billOfMaterialId:"kefnklmsmfs de"}
            addMutation={UPLOAD_BILL_OF_MATERIAL_ATTACHMENT}
            deleteMutation={DELETE_BILL_OF_MATERIAL_ATTACHMENT}
          />
        ) : componentStatus === 'executionPlanning' ? (
          <Fragment>
            <BillOfMaterialTechicalImages
              images={item.billOfMaterialAttachment}
              deleteMutation={DELETE_BILL_OF_MATERIAL_ATTACHMENT}
            />
            <ExecutionPlanning
              executionPlanningId={item?.executionPlan?.id}
              billOfMaterialId={item?.id}
              purchaseOffer={offerPurchaseOffer?.purchaseOffer}
              offerId={offerId}
              orderQty={orderQty}
            />
          </Fragment>
        ) : (
          componentStatus === 'purhaseOffer' && (
            <ListPurchaseOffer items={offerPurchaseOffer?.purchaseOffer} />
          )
        )}
      </CardBody>
    </Fragment>
  );
};
export default ItemBillOfMaterial;

