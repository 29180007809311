import React, { useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';

import { loader } from 'graphql.macro';

const GET_EXTERNAL_OPERATION_TYPES = loader(
  '../../graphql/externalOperationType/GET_EXTERNAL_OPERATION_TYPES.graphql'
);
const UPDATE_EXTERNAL_OPERATION_TYPE = loader(
  '../../graphql/externalOperationType/UPDATE_EXTERNAL_OPERATION_TYPE.graphql'
);
const ADD_EXTERNAL_OPERATION_TYPE = loader(
  '../../graphql/externalOperationType/ADD_EXTERNAL_OPERATION_TYPE.graphql'
);

const ExternalOperationTypeForm: React.FC = () => {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    dataDispatch && setValue('operationType', dataDispatch.name);
  }, [dataDispatch, setValue]);
  const onSubmit = ({ operationType }) => {
    dataDispatch
      ? updateOperationType({
          variables: {
            id: dataDispatch.id,
            name: operationType,
          },
        })
      : addOperationType({ variables: { name: operationType } });
  };
  const [addOperationType] = useMutation(ADD_EXTERNAL_OPERATION_TYPE, {
    update(cache, { data: { createExternalOperation } }) {
      const { externalOperations } = cache.readQuery({
        query: GET_EXTERNAL_OPERATION_TYPES,
      });
      cache.writeQuery({
        query: GET_EXTERNAL_OPERATION_TYPES,
        data: {
          externalOperations: externalOperations.concat(createExternalOperation),
        },
      });
      closeDrawer();
    },
  });
  const [updateOperationType] = useMutation(UPDATE_EXTERNAL_OPERATION_TYPE, {
    update(cache) {
      const { externalOperations } = cache.readQuery({
        query: GET_EXTERNAL_OPERATION_TYPES,
      });
      const newOperationTypes = externalOperations.map((newOperationType) => {
        if (dataDispatch.id === newOperationType.id) {
          return {
            ...newOperationType,
            name: getValues().operationType,
          };
        } else {
          return newOperationType;
        }
      });
      cache.writeQuery({
        query: GET_EXTERNAL_OPERATION_TYPES,
        data: { externalOperations: newOperationTypes },
      });
      closeDrawer();
    },
  });
  return (
    <Card
      title={
        <FormattedMessage
          id={
            dataDispatch
              ? 'external_operation_type.update'
              : 'external_operation_type.create'
          }
        />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="external_operation_type.name" />}
          error={
            errors.operationType ? 'Please input a valid External Operation Type' : null
          }
        >
          <Input
            size={SIZE.compact}
            name="operationType"
            inputRef={register({
              required: true,
            })}
            error={errors.operationType}
            overrides={errors.operationType ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default ExternalOperationTypeForm;
