import React, {FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery} from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import {  Operations } from '../../types/Operations';


const GET_OPERATIONS = loader(
  '../../graphql/operation/GET_OPERATIONS.graphql'
);
interface ListProps {
    errors?: any;
    control: any;
    creatable?:boolean;
    disabled?:boolean;
  }
const SelectOperation: FunctionComponent<ListProps> = ({
  errors,
  control,
  creatable = false,
  disabled = false
}) => {
  const { data } = useQuery<Operations>(GET_OPERATIONS);

  return (
    <FormControl 
      label={<FormattedMessage id="select.operation" />}
      error={
        errors && errors?.operationId ? 'Please Select a valid Operation' : null
      }
    >
      <Select
        data={data && data.operations}
        controller={{
          name: 'operationId',
          type: TYPE.search,
          control,
          disabled:disabled,
          creatable: creatable,
          rules: { required: errors ? true : false },
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectOperation