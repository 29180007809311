import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import { Label } from '../../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';
import { InputType } from '../Step/Step';

interface ListItemProps {
  item: InputType;
  handleItemDelete: Function;
  handleItemEdit: Function;
  index:number
}
const ListInputItem: FunctionComponent<ListItemProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
  index
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(index);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(index);
      },
      check_type: 'UPDATE',
    },
  ];
  return (
    <RowCustom>
      <Col xs={12} md={6} lg={4}>
        <Label title={intl.formatMessage({ id: 'executionPlanningStep.inputName' })}>
          {item.name || 'No Item'}
        </Label>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Label title={intl.formatMessage({ id: 'inventory.inventoryId' })}>
          {item.inventoryId[0]?.label || 'No Item'}
        </Label>
      </Col>
      <ColButton xs>
        <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
      </ColButton>
    </RowCustom>
  );
};
export default ListInputItem;
