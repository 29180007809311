import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../../context/toaster-provider';
import { handleCeil } from '../../../Functions/Utilities';
const UPDATE_BILL_OF_MATERIAL_ITEM = loader(
    '../../../../graphql/billOfMaterialItem/UPDATE_BILL_OF_MATERIAL_ITEM.graphql'
);
const useBillOfMaterialItemUnitPrice = () => {
    const { showToaster } = useToaster();
    const oneUnitWeightUpdate = ({
        item,
        oneUnitWeight,
    }) => {
        console.log("------>",item)
        console.log("------>",oneUnitWeight)
        update({
            variables: {
                id: item?.id,
                ...item,
                quantity: handleCeil(oneUnitWeight),
            },
        }).then(() => {
            showToaster('updated', 'positive');
        })
    };
    const [update] = useMutation(UPDATE_BILL_OF_MATERIAL_ITEM);
    return {
        oneUnitWeightUpdate,
    };
};
export default useBillOfMaterialItemUnitPrice;

