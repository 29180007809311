// @ts-nocheck
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { Negative, Positive } from '../../../components/General/NegativePositive';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Select } from '../../../components/FormWithElemets/Select/Select';
import { useToaster } from '../../../context/toaster-provider';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import { ShopFloors } from '../../../types/ShopFloor';
import { Card, CardTitle } from '../../../components/FormWithElemets/Card.style';
import { useDrawerState, useDrawerDispatch } from '../../../context/DrawerContext';
import { handleAddShopFloorOrder, handleUpdateShopFloorOrder } from '../GraphqlFunction';

interface ListProps {
  dataShoopFloor: ShopFloors;
}
const GET_SHOP_FLOORS = loader('../../../graphql/shopFloorOrder/GET_SHOP_FLOORS.graphql');
const ADD_SHOP_FLOOR_ORDER = loader(
  '../../../graphql/shopFloorOrder/ADD_SHOP_FLOOR_ORDER.graphql'
);
const UPDATE_SHOP_FLOOR_ORDER = loader(
  '../../../graphql/shopFloorOrder/UPDATE_SHOP_FLOOR_ORDER.graphql'
);
const ShopFloorOrderForm: FunctionComponent<ListProps> = () => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, setValue, control } = useForm({
    defaultValues: {},
  });
  const { data } = useQuery<ShopFloors>(GET_SHOP_FLOORS);
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const dispatch = useDrawerDispatch();
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (transactionsReasonDispatch === 'Update') {
      setValue('machineryAndEquipmentId', [
        {
          id: dataDispatch.machineryAndEquipment.id,
          label: dataDispatch.machineryAndEquipment.name,
        },
      ]);
      setValue('qty', dataDispatch.qty);
    }
  }, [dataDispatch, setValue, transactionsReasonDispatch]);
  const onSubmit = ({ machineryAndEquipmentId, qty }) => {
    if (dataDispatch?.id) {
      update({
        variables: {
          id: dataDispatch.id,
          machineryAndEquipmentId: machineryAndEquipmentId[0].id,
          qty: Number(qty),
        },
      }).then(() => {closeDrawer();showToaster('updated', 'positive')});
    } else {
      add({
        variables: {
          machineryAndEquipmentId: machineryAndEquipmentId[0].id,
          productOperationId: dataDispatch.productOperationId,
          orderDetailId: dataDispatch.orderDetailId,
          productId: dataDispatch.productId,
          qty: Number(qty),
          shopFloorId: data.shopFloors.filter((item) =>
            item.machineryAndEquipment.filter(
              (itm) => itm.id === machineryAndEquipmentId[0].id
            )
          )[0].id,
        },
      }).then(() =>{closeDrawer(); showToaster('created', 'positive')});
    }
  };
  const [add] = useMutation(ADD_SHOP_FLOOR_ORDER, {
    update(cache, { data: { createShopFloorOrder } }) {
      handleAddShopFloorOrder(
        cache,
        dataDispatch.orderId,
        dataDispatch.orderDetailId,
        createShopFloorOrder
      );
    },
  });
  const [update] = useMutation(UPDATE_SHOP_FLOOR_ORDER, {
    update(cache, { data: { updateShopFloorOrder } }) {
      handleUpdateShopFloorOrder(
        cache,
        dataDispatch.orderId,
        dataDispatch.orderDetailId,
        updateShopFloorOrder
      );
    },
  });
  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id={
              transactionsReasonDispatch === 'Update'
                ? 'product.update'
                : 'product.create'
            }
          />
        }
      ></CardTitle>
      <Form
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row style={{ margin: 0, padding: 0 }}>
          <Col md={8}>
            <FormControl
              label={<FormattedMessage id="shop_floor_order.machineryAndEquipment" />}
              error={
                errors.machineryAndEquipmentId
                  ? 'Please select a valid Machinery And Equipment'
                  : null
              }
            >
              <Select
                data={data && data.shopFloors}
                labelValue={"machineryAndEquipment"}
                selectGrouped={true}
                controller={{
                  name: 'machineryAndEquipmentId',
                  control,
                  creatable: false,
                  searchable: true,
                  error: errors.machineryAndEquipmentId,
                  overrides: errors.machineryAndEquipmentId
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    setValue(
                      'description',
                      selected.option ? selected.value[0].label : ''
                    );
                    return selected.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
          <Col md={4}>
            <FormControl
              label={<FormattedMessage id="shop_floor_order.qty" />}
              error={errors?.qty ? 'Please input a valid Qty' : null}
            >
              <Input
                type="number"
                size={SIZE.compact}
                name="qty"
                inputRef={register({
                  required: true,
                  pattern: /^[0-9]*\.?[0-9]*$/,
                })}
                error={errors.qty}
                overrides={errors.qty ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
          <Col md={12}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
                flexWrap: 'wrap',
              }}
            >
              <Button
                type="button"
                style={{ marginBottom: '16px' }}
                onClick={() => {
                  closeDrawer();
                }}
              >
                <FormattedMessage id={'button.close'} />
              </Button>
              <Button type="submit" style={{ marginBottom: '16px' }}>
                <FormattedMessage id="button.ok" />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default ShopFloorOrderForm;
