import React, { FunctionComponent } from 'react';
import List from './List';
import { WarehouseSection as WarehouseSectionType } from '../../types/WarehouseSection';

// const GET_WAREHOUSE_SECTIONS = loader(
//   '../../graphql/warehouseSection/GET_WAREHOUSE_SECTIONS.graphql'
// );
interface ListProps {
  data: WarehouseSectionType[];
  warehouseId:string;
}
const WarehouseSection: FunctionComponent<ListProps> = ({ data,warehouseId }) => {
  // const { data, error, loading } = useQuery<WarehouseSections>(GET_WAREHOUSE_SECTIONS);
  return data && <List data={data}  warehouseId={warehouseId} />;
};

export default WarehouseSection;
