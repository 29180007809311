import React, { FunctionComponent,  useContext } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import {  useMutation } from '@apollo/react-hooks';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { WarehouseSection } from '../../types/WarehouseSection';
import { handleAddWareHouseSection, handleUpdateWareHouseSection } from '../WareHouse/GraphqlFunction';
import { ListContextType, WarehouseContext } from '../WarehouseLevel/List';

const ADD_WAREHOUSE_SECTION = loader(
  '../../graphql/warehouseSection/ADD_WAREHOUSE_SECTION.graphql'
);
const UPDATE_WAREHOUSE_SECTION = loader(
  '../../graphql/warehouseSection/UPDATE_WAREHOUSE_SECTION.graphql'
);
interface ListProps {
  data?: WarehouseSection;
  setItemId?: Function;
  warehouseId:string;
}
const WarehouseSectionForm: FunctionComponent<ListProps> = ({ data, setItemId,warehouseId }) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const { warehouse } = useContext<ListContextType>(WarehouseContext);
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: data ? data.name : '',
    },
  });
  const onSubmit = ({ name }) => {
    data
      ? updateWarehouseSection({
          variables: {
            id: data.id,
            name: name,
          },
        }).then(() => {
          showToaster('updated', 'positive');
          setItemId(null);
        })
      : addWarehouseSection({
          variables: {
            name: name,
            warehouseLevelId:warehouse.warehouseLevelId
          },
        }).then(() => {
          showToaster('created', 'positive');
          setValue('name', '');
        });
  };
  const [addWarehouseSection] = useMutation(ADD_WAREHOUSE_SECTION, {
    update(cache, { data: { createWarehouseSection } }) {
      handleAddWareHouseSection (
        cache,
        createWarehouseSection,
        warehouse.warehouseLevelId,
        warehouseId
      )
    },
  });
  const [updateWarehouseSection] = useMutation(UPDATE_WAREHOUSE_SECTION, {
    update(cache, { data: { updateWarehouseSection } }) {
      handleUpdateWareHouseSection (
        cache,
        updateWarehouseSection,
        warehouse.warehouseLevelId,
        warehouseId
      )
    },
  });
  return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <Row style={{ margin: 0, padding: 0 }}>
          <Col lg={9}>
            <FormControl
        //      label={<FormattedMessage id="warehouse_section.name" />}
              error={errors.name ? 'Please input a valid Name' : null}
            >
              <Input
                size={SIZE.compact}
                name="name"
                inputRef={register({
                  required: true,
                })}
              placeholder={intl.formatMessage({ id: "warehouse_section.name" })}
                error={!!errors.name}
                overrides={errors.name ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
            </Col>
          <Col md={3}>
            <div
              style={{
                height: '100%',
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-start',
                flexWrap: 'wrap',
              }}
            >
              <Button onClick={() => (data ? setItemId(null) : setValue('name', ''))}>
                <FormattedMessage id={data ? 'button.close' : 'button.clean'} />
              </Button>
              <Button type="submit">
                <FormattedMessage id={data ? 'button.update' : 'button.create'} />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
  );
};
export default WarehouseSectionForm;
