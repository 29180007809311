import { Breadcrumbs } from 'baseui/breadcrumbs';
import { StyledLink } from 'baseui/link';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

interface BreadcrumbsCustomProps {
  componentStatus: string;
}
const BreadcrumbsCustom: FunctionComponent<BreadcrumbsCustomProps> = ({
  componentStatus,
}) => {
  return (
    <Breadcrumbs>
      <StyledLink>
        <FormattedMessage id="menu.billOfMaterial" />
      </StyledLink>
      {componentStatus === 'purhaseOffer' ? (
        <StyledLink>
          <FormattedMessage id="billOfMaterial.purhaseOffer" />
        </StyledLink>
      ) : componentStatus === 'executionPlanning' ? (
        <StyledLink>
          <FormattedMessage id="billOfMaterial.executionPlanning" />
        </StyledLink>
      ) : (
        componentStatus === 'images' && (
          <StyledLink>
            <FormattedMessage id="billOfMaterial.images" />
          </StyledLink>
        )
      )}
    </Breadcrumbs>
  );
};

export default BreadcrumbsCustom;
