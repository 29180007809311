import React, { FunctionComponent } from 'react';
import List from './List';
import { WarehouseRow as WarehouseRowType } from '../../types/WarehouseRow';

// const GET_WAREHOUSE_LEVELS = loader(
//   '../../graphql/warehouseLevel/GET_WAREHOUSE_LEVELS.graphql'
// );
interface ListProps {
  data: WarehouseRowType[];
  warehouseId:string;
}
const WarehouseRow: FunctionComponent<ListProps> = ({ data,warehouseId }) => {
  //const { data, error, loading } = useQuery<WarehouseLevels>(GET_WAREHOUSE_LEVELS);
  return data && <List data={data} warehouseId={warehouseId}/>;
};
export default WarehouseRow;
