import React from 'react';
import { SubcontractSalesOfferOperationPrice } from '../../../../../../types/SubcontractSalesOfferOperationPrice';
import { SubcontractSalesOfferPrice } from '../../../../../../types/SubcontractSalesOfferPrice';
import { handleCeil } from '../../../../../Functions/Utilities';
import GridCol from '../../../../../TailWinduiHTML/GridCol';
import { OperationIds } from '../AddCol/AddOperationCol';
import ColTitle from './ColTitle';
import OperationDetail from './OperationDetail';

const useOperationCol = (
  item: SubcontractSalesOfferPrice,
  cols: OperationIds,
  setColOperations: Function
) => {
  const operationCol = () => {
    const { subcontractSalesOfferOperationPrice } = item;
    let unitPriceOperation = 0;
    const operationCol = cols.map((col, index) => {
      let discountAmount: number = 0;
      const operationDetail = col.operationIds.map((item) => {
        const _colOperationPrice = colOperationPrice(
          subcontractSalesOfferOperationPrice,
          item.id
        );
        discountAmount += _colOperationPrice?.discountAmount || 0;
        return {
          ..._colOperationPrice,
          operationName: item.name,
        };
      });
      unitPriceOperation += handleCeil(discountAmount);
      return {
        colTitle: (
          <ColTitle
            key={index}
            colName={col.colName}
            index={index}
            setColOperations={setColOperations}
          />
        ),
        colItem: (
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-1">
            <GridCol title={col.colName} fontSize="xs">{handleCeil(discountAmount) + ' ₺'}</GridCol>
          </div>
        ),
        operationDetail: col?.detail ? (
          <OperationDetail
            key={index}
            operationDetail={operationDetail}
            colName={col.colName}
          />
        ) : null,
      };
    });
    return {
      unitPriceOperation: unitPriceOperation,
      operationCol,
    };
  };
  return {
    operationCol,
  };
};
export default useOperationCol;

const _calculation = ({
  amount,
  profitRate,
  discountRate,
  //_profitRate,
  //_discountRate,
}) => {
  const profitAmount = handleCeil(amount + (amount * profitRate) / 100);
  const discountAmount = handleCeil(profitAmount - (profitAmount * discountRate) / 100);
  // const _profitAmount = discountAmount + (discountAmount * _profitRate) / 100;
  // const _discountAmount = _profitAmount + (_profitAmount * _discountRate) / 100;
  return {
    profitRate,
    profitAmount,
    discountRate,
    discountAmount,
    amount,
  };
};
const colOperationPrice = (
  subcontractSalesOfferOperationPrice: SubcontractSalesOfferOperationPrice[],
  _executionPlanStepId: string
) =>
  subcontractSalesOfferOperationPrice
    .map((item) => {
      const { amount, profitRate, discountRate, executionPlanStepId } = item;
      if (_executionPlanStepId === executionPlanStepId) {
        return _calculation({
          amount,
          profitRate,
          discountRate,
        });
      } else {
        return null;
      }
    })
    .filter((i) => i !== null)[0];
