// @ts-nocheck
import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  OFFERS,
  COMPANY,
  DASHBOARD,
  SETTINGS,
  STUFF_MEMBERS,
  SITE_SETTINGS,
  INVOICE_SALES,
  INVOICE_PURCHASE,
  WAYBILL_SALES,
  WAYBILL_PURCHASE,
  BANKS,
  EXTERNAL_OPERATION_TYPES,
  INTERNAL_OPERATION_TYPES,
  MACHINERY_AND_EQUIPMENT,
  MACHINERY_AND_EQUIPMENT_STATUS,
  MATERIAL_TYPE,
  STATUS,
  UNITS,
  USERS,
  WAREHOUSE,
  WAREHOUSE_SECTION,
  COST_CENTER,
  INVENTORY_CATEGORY,
  INVENTORY,
  WAREHOUSE_LEVEL,
  CASH_BOOK,
  SHIFT,
  PRODUCT,
  PRODUCT_PRICE,
  PRODUCT_RECORD,
  MBOM,
  INVOICE_SALES_WITH_ID,
  INVOICE_PURCHASE_WITH_ID,
  WAYBILL_SALES_WITH_ID,
  WAYBILL_PURCHASE_WITH_ID,
  ORDER,
  OPERATION,
  PLANNING,
  QUALITY_CONTROL,
  SHOP_FLOOR,
  AQL_SAMPLE_RULE,
  QUALITY_CONTROL_REASON,
  PERMISSION,
  SHOP_FLOOR_ORDER,
  MEASUREMENT_RULE_UNIT,
  SHOP_FLOOR_ORDER_STOP_REASON,
  SHOP_FLOOR_ORDER_CANCEL_REASON,
  SELECT_PRINTER,
  PRODUCT_RECORD_ID,
  CANCELED_OR_DECLINED_REASON,
  MATERIAL,
  PRODUCT_RECORD_REASON,
  OFFER_TYPE,
  BILL_OF_MATERIAL,
  PURCHASE_OFFER,
  CALCULATOR,
  MANUFACTURER,
  DEPARTMENT,
  QUALITY_DOCUMENT_TYPE,
  QUALITY_ACTION,
  QUALITY_IMPROVEMENT_ACTION_PLAN,
  QUALITY_OBJECTIVE,
  QUALITY_OBJECTIVE_RESPONSIBLE,
  QUALITY_DOCUMENT,
  QUALITY_OBJECTIVE_RESOURCE,
  QUALITY_DOCUMENT_MASTER,
} from './settings/constants';
import AuthProvider, { AuthContext } from './context/auth';
import { InLineLoader } from './components/InlineLoader/InlineLoader';
import Offer from './containers/Offers/Offer';
import QualityControlReason from './containers/QualityControlReason/QualityControlReason';

const InvoiceSales = lazy(() => import('./containers/Invoice/Invoice'));
const InvoicePurchase = lazy(() => import('./containers/Invoice/Invoice'));
const WaybillSales = lazy(() => import('./containers/Waybill/Waybill'));
const WaybillPurchase = lazy(() => import('./containers/Waybill/Waybill'));
const Banks = lazy(() => import('./containers/Bank/Bank'));
const ExternalOperationTypes = lazy(
  () => import('./containers/ExternalOperationType/ExternalOperationType')
);
const InternalOperationTypes = lazy(
  () => import('./containers/InternalOperationType/InternalOperationType')
);
const MachineryAndEquipment = lazy(
  () => import('./containers/MachineryAndEquipment/MachineryAndEquipment')
);
const MaterialType = lazy(() => import('./containers/MaterialType/MaterialType'));
const Status = lazy(() => import('./containers/Status/Status'));
const Unit = lazy(() => import('./containers/Unit/Unit'));
const User = lazy(() => import('./containers/User/User'));
const WareHouse = lazy(() => import('./containers/WareHouse/WareHouse'));
const WareHouseSection = lazy(
  () => import('./containers/WarehouseSection/WarehouseSection')
);
const WareHouseLevel = lazy(() => import('./containers/WarehouseLevel/WarehouseLevel'));
const CostCenter = lazy(() => import('./containers/CostCenter/CostCenter'));
const InventoryCategory = lazy(
  () => import('./containers/InventoryCategory/InventoryCategory')
);
const Inventory = lazy(() => import('./containers/Inventory/Inventory'));
const CashBook = lazy(() => import('./containers/CashBook/CashBook'));
const Shift = lazy(() => import('./containers/Shift/Shift'));
const Product = lazy(() => import('./containers/Product/Product'));
const ProductPrice = lazy(() => import('./containers/PriceLists/Price'));
const ProductRecord = lazy(() => import('./containers/ProductRecord/ProductRecord'));
const Operation = lazy(() => import('./containers/Operation/Operation'));
const QualityControl = lazy(() => import('./containers/QualityControl/QualityControl'));

const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Dashboard = lazy(() => import('./containers/Dashboard/Dashboard'));
const Company = lazy(() => import('./containers/Company/Company'));
const Settings = lazy(() => import('./containers/Settings/Settings'));
const SiteSettingForm = lazy(
  () => import('./containers/SiteSettingForm/SiteSettingForm')
);
const StaffMembers = lazy(() => import('./containers/StaffMembers/StaffMembers'));

const Login = lazy(() => import('./containers/Login/Login'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));
const Planning = lazy(() => import('./containers/Planning/Planning'));
const ShopFloorOrder = lazy(
  () => import('./containers/ShopFloor/ShopFloorOrder/ShopFloorOrder')
);
const ShopFloor = lazy(() => import('./containers/ShopFloor/ShopFloor/ShopFloor'));
const ShopFloorOrderStopReason = lazy(
  () => import('./containers/ShopFloorOrderStopReason/ShopFloorOrderStopReason')
);
const ShopFloorOrderCancelReason = lazy(
  () => import('./containers/ShopFloorOrderCancelReason/ShopFloorOrderCancelReason')
);
const ManufacturingBillOfMaterials = lazy(
  () => import('./containers/Production/ManufacturingBillOfMaterials')
);

const Order = lazy(() => import('./containers/Order/Order'));
const AqlSampleRule = lazy(() => import('./containers/AqlSampleRule/AqlSampleRule'));
const Permission = lazy(() => import('./containers/Permission/Permission'));
const MeasurementRuleUnit = lazy(
  () => import('./containers/MeasurementRuleUnit/MeasurementRuleUnit')
);
const SelectPrinter = lazy(() => import('./containers/Printer/SelectPrinter'));
const CanceledOrDeclinedReason = lazy(
  () => import('./containers/CanceledOrDeclinedReason/CanceledOrDeclinedReason')
);
const Material = lazy(() => import('./containers/Material/Material'));
const ProductRecordReason = lazy(
  () => import('./containers/ProductRecordReason/ProductRecordReason')
);
const OfferType = lazy(() => import('./containers/OfferType/OfferType'));
const BillOfMaterial = lazy(() => import('./containers/BillOfMaterial/BillOfMaterials'));
const PurchaseOffer = lazy(() => import('./containers/Purchase/Purchases'));
const RawMaterialCalculation = lazy(
  () => import('./containers/Calculation/RawMaterialCalculation')
);
const Manufacturer = lazy(() => import('./containers/Manufacturer/Manufacturer'));
const MachineryAndEquipmentStatus = lazy(
  () => import('./containers/MachineryAndEquipmentStatus/MachineryAndEquipmentStatus')
);
const Department = lazy(() => import('./containers/Department/Department'));
const QualityDocumentType = lazy(
  () => import('./containers/QualityDocumentType/QualityDocumentType')
);
const QualityAction = lazy(() => import('./containers/QualityAction/QualityAction'));
const QualityImprovementActionPlan = lazy(
  () => import('./containers/QualityImprovementActionPlan/QualityImprovementActionPlan')
);
const QualityObjective = lazy(
  () => import('./containers/QualityObjective/QualityObjective')
);
const QualityObjectiveResponsible = lazy(
  () => import('./containers/QualityObjectiveResponsible/QualityObjectiveResponsible')
);
const QualityDocument = lazy(
  () => import('./containers/QualityDocument/QualityDocument')
);
const QualityObjectiveResource = lazy(
  () => import('./containers/QualityObjectiveResource/QualityObjectiveResource')
);
const QualityDocumentMaster = lazy(
  () => import('./containers/QualityDocumentMaster/QualityDocumentMaster')
);

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact path={DASHBOARD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Dashboard />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICE_SALES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InvoiceSales type="SALES" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICE_PURCHASE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InvoicePurchase type="PURCHASE" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAYBILL_SALES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WaybillSales type="SALES" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAYBILL_PURCHASE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WaybillPurchase type="PURCHASE" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PLANNING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Planning />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SHOP_FLOOR_ORDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ShopFloorOrder />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SHOP_FLOOR_ORDER_STOP_REASON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ShopFloorOrderStopReason />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SHOP_FLOOR_ORDER_CANCEL_REASON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ShopFloorOrderCancelReason />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SHOP_FLOOR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ShopFloor />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICE_SALES_WITH_ID}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InvoiceSales type="SALES" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVOICE_PURCHASE_WITH_ID}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InvoicePurchase type="PURCHASE" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAYBILL_SALES_WITH_ID}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WaybillSales type="SALES" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAYBILL_PURCHASE_WITH_ID}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WaybillPurchase type="PURCHASE" />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_RECORD_ID}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductRecord />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_CONTROL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityControl />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_CONTROL_REASON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityControlReason />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={AQL_SAMPLE_RULE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <AqlSampleRule />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={OFFERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Offer />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PURCHASE_OFFER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <PurchaseOffer />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COMPANY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Company />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={ORDER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Order />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Settings />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STUFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SITE_SETTINGS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SiteSettingForm />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BANKS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Banks />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EXTERNAL_OPERATION_TYPES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ExternalOperationTypes />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INTERNAL_OPERATION_TYPES}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InternalOperationTypes />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MACHINERY_AND_EQUIPMENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MachineryAndEquipment />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MATERIAL_TYPE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MaterialType />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STATUS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Status />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={USERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <User />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={UNITS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Unit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAREHOUSE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WareHouse />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAREHOUSE_SECTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WareHouseSection />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={WAREHOUSE_LEVEL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <WareHouseLevel />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={COST_CENTER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CostCenter />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVENTORY_CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <InventoryCategory />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={INVENTORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Inventory />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CASH_BOOK}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CashBook />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SHIFT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Shift />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Product />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MATERIAL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Material />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_PRICE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductPrice />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_RECORD}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductRecord />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MBOM}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ManufacturingBillOfMaterials />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={OPERATION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Operation />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PERMISSION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Permission />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MEASUREMENT_RULE_UNIT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MeasurementRuleUnit />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MANUFACTURER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Manufacturer />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={SELECT_PRINTER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <SelectPrinter />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CANCELED_OR_DECLINED_REASON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <CanceledOrDeclinedReason />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCT_RECORD_REASON}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <ProductRecordReason />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={OFFER_TYPE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <OfferType />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={BILL_OF_MATERIAL}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <BillOfMaterial />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={DEPARTMENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Department />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_DOCUMENT_TYPE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityDocumentType />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_DOCUMENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityDocument />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_ACTION}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityAction />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_IMPROVEMENT_ACTION_PLAN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityImprovementActionPlan />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_OBJECTIVE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityObjective />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_OBJECTIVE_RESPONSIBLE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityObjectiveResponsible />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_OBJECTIVE_RESOURCE}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityObjectiveResource />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={QUALITY_DOCUMENT_MASTER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <QualityDocumentMaster />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CALCULATOR}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <RawMaterialCalculation />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MACHINERY_AND_EQUIPMENT_STATUS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <MachineryAndEquipmentStatus />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
