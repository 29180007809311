import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  title: ReactNode;
}
const Breadcrumbs: FunctionComponent<Props & AnchorType> = ({ children, title, ...props }) => {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div className="flex items-center">
            <a
              aria-current="page"
              className="cursor-pointer ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              {...props}
            >
              {title}
            </a>
          </div>
        </li>
        {children}
      </ol>
    </nav>
  );
};
export default Breadcrumbs;
export type AnchorType = React.DetailedHTMLProps<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
>;