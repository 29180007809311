import React, { Fragment, FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import { OperationSalesOffer } from "../../types/OperationSalesOffer";
import { NewCol, ProductHeadLineForCard, ProductHeadLineText, RowSpecial,Text } from "./RequestBox.style";

interface OperationSalesOfferProps {
    data: OperationSalesOffer[];
  }
  const OperationSales: FunctionComponent<OperationSalesOfferProps> = ({ data }) => {
    return (
      <Fragment>
        {data.map((item, index) => {
          return (
            <RowSpecial middle="xs" between="xs" key={index}>
              <NewCol md={7} lg={7}>
                <ProductHeadLineForCard>
                  <ProductHeadLineText>{item.operation?.name}</ProductHeadLineText>
                </ProductHeadLineForCard>
              </NewCol>
              <NewCol xs>
                <Text>
                  <b>
                    <FormattedMessage id="offer.qty" />:
                  </b>{' '}
                  {item.qty} {item.units?.name}
                </Text>
              </NewCol>
            </RowSpecial>
          );
        })}
      </Fragment>
    );
  };
export default OperationSales