import React, { useCallback, useEffect, FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import SelectShift from '../SelectCustom/SelectShift';
import { ProductRecord } from '../../types/ProductRecord';
import { CardBody, CardTitle, Card } from '../../components/FormWithElemets/Card.style';

const GET_PRODUCT_RECORDS = loader(
  '../../graphql/productRecord/GET_PRODUCT_RECORDS.graphql'
);
const UPDATE_PRODUCT_RECORD = loader(
  '../../graphql/productRecord/UPDATE_PRODUCT_RECORD.graphql'
);

const UpdateProductRecordForm: FunctionComponent = () => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  //   const transactionsReasonDispatch = useDrawerState('transactionsReason');
  //   const [printerData, setPrinterData] = useState({
  //     wipBarcode: '',
  //     improperBarcode: '',
  //   });
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  console.log("------>shift",dataDispatch)
  useEffect(() => {
    setValue('wip', dataDispatch.wip);
    setValue('improper', dataDispatch.improper);
    const _shiftId = dataDispatch?.shift ? [{ id: dataDispatch?.shift?.id, label: dataDispatch.shift?.name }] : undefined;
    setValue('shiftId', _shiftId)
  }, [dataDispatch, setValue]);
  const onSubmit = ({ wip, improper, shiftId }) => {
    updateProductRecord({
      variables: {
        id: dataDispatch.id,
        wip: Number(wip),
        improper: Number(improper),
        shiftId: shiftId[0].id,
      },
    })
      .then(() => showToaster('updated', 'positive'))
      .catch((error) => {
        console.log('error', error); // <-- OK
        showToaster(error, 'warning', false);
      });
  };
  //    useEffect(() => {
  //     if (ipcRenderer) {
  //       printerData.wipBarcode !== '' &&
  //         ipcRenderer.send('print-barcode', {
  //           data: printerData.wipBarcode,
  //           printer: localStorage.getItem('barcode'),
  //         });
  //       printerData.improperBarcode !== '' &&
  //         ipcRenderer.send('print-barcode', {
  //           data: printerData.improperBarcode,
  //           printer: localStorage.getItem('barcode'),
  //         });
  //     }
  //   }, [printerData]);
  const [updateProductRecord] = useMutation(UPDATE_PRODUCT_RECORD, {
    update(cache, { data: { updateProductRecord } }) {
      const { productRecords } = cache.readQuery({
        query: GET_PRODUCT_RECORDS,
      });
      const newProductRecords = productRecords.map((item: ProductRecord) => {
        if (dataDispatch.id === item.id) {
          return updateProductRecord;
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_PRODUCT_RECORDS,
        data: { productRecords: newProductRecords },
      });
      closeDrawer();
    },
  });
  return (
    <Card>
      <CardTitle title={<FormattedMessage id={'productRecordUpdate'} />}></CardTitle>
      <CardBody style={{ padding: '5px' }}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            margin: 0,
            padding: 0,
            height: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <Row style={{ margin: 0, padding: 0 }}>
            <UpdateProductRecordFormInput
              control={control}
              register={register}
              errors={errors}
            />
            <Col md={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                <Button type="button" onClick={closeDrawer}>
                  <FormattedMessage id="button.close" />
                </Button>{' '}
                <Button type="submit">
                  <FormattedMessage id={'button.update'} />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};
export default UpdateProductRecordForm;
interface ListUpdateProps {
  errors: any;
  register: any;
  control: any;
}
const UpdateProductRecordFormInput: FunctionComponent<ListUpdateProps> = ({
  errors,
  register,
  control,
}) => {
  return (
    <>
      <Col sm={6}>
        <FormControl
          label={<FormattedMessage id="product_record.wip" />}
          error={errors.wip ? 'Please input a valid Wip' : null}
        >
          <Input
            size={SIZE.compact}
            name="wip"
            inputRef={register({
              required: true,
            })}
            error={errors.wip}
            overrides={errors.wip ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
      <Col sm={6}>
        <FormControl
          label={<FormattedMessage id="product_record.improper" />}
          error={errors.improper ? 'Please input a valid Improper' : null}
        >
          <Input
            size={SIZE.compact}
            name="improper"
            inputRef={register({
              required: true,
            })}
            error={errors.improper}
            overrides={errors.improper ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
      <Col md={12}>
        <SelectShift control={control} />
      </Col>
    </>
  );
};
