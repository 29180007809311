import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { SubcontractSalesOffer } from '../../../../../types/SubcontractSalesOffer';
import { handleCeil, handleLocalDateString } from '../../../../Functions/Utilities';
import GridCol from '../../../../TailWinduiHTML/GridCol';
import { MaterialIds } from './AddCol/AddMaterialCol';
import { OperationIds } from './AddCol/AddOperationCol';
import useMaterialCol from './MaterialCol/useMaterialCol';
import useOperationCol from './OperationCol/useOperationCol';

interface ListItemSubcontractSalesPreviewProps {
  item: SubcontractSalesOffer;
  setColOperationTitle: Function;
  setColMaterialTitle: Function;
  colOperations: OperationIds;
  colMaterials: MaterialIds;
  setColOperations: Function;
  setColMaterials: Function;
}
const ListIteSubcontractSalesPreview: FunctionComponent<ListItemSubcontractSalesPreviewProps> = ({
  item,
  setColOperationTitle,
  setColMaterialTitle,
  colOperations,
  colMaterials,
  setColOperations,
  setColMaterials,
}) => {
  console.log("---->item.subcontractSalesOfferPrice",item.subcontractSalesOfferPrice)
  const [operationCol, setOperationCol] = useState(null);
  const [materialCol, setMaterialCol] = useState(null);
  const { operationCol: _operationCol } = useOperationCol(
    item.subcontractSalesOfferPrice,
    colOperations,
    setColOperations
  );
  const { materialCol: _materialCol } = useMaterialCol(
    item?.subcontractSalesOfferPrice?.subcontractSalesOfferMaterialPrice,
    colMaterials,
    setColMaterials
  );
  useEffect(() => {
    item?.subcontractSalesOfferPrice && setOperationCol(_operationCol());
  }, [colOperations, item]);
  useEffect(() => {
    item?.subcontractSalesOfferPrice && setMaterialCol(_materialCol());
  }, [colMaterials, item]);
  useEffect(() => {
    setColOperationTitle(
      operationCol?.operationCol?.map((item: any) => item?.colTitle) || []
    );
  }, [operationCol]);
  useEffect(() => {
    setColMaterialTitle(
      materialCol?.materialCol?.map((item: any) => item?.colTitle) || []
    );
  }, [materialCol]);
  const colLength =
    6 +
    (materialCol?.materialCol?.length || 0) +
    (operationCol?.operationCol?.length || 0);
  return (
    <Fragment>
      <div className="pt-3 grid print:grid-cols-12 lg:divide-x print:divide-x  grid-cols-12 border-b hover:bg-gray-100 cursor-pointer">
        <div className="col-span-12 md:col-span-6 lg:col-span-1 print:col-span-1 h-full">
          <div className="grid grid-cols-3 lg:divide-x print:divide-x h-full">
            <div className="lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.no'} fontSize="xs">
                {'1'}
              </GridCol>
            </div>
            <div className="lg:col-span-2 print:col-span-2">
              <GridCol title={'offer.itemCode'} fontSize="xs">
                {'Code'}
              </GridCol>
            </div>
          </div>
        </div>
        <div className="col-span-12 md:col-span-6 lg:col-span-2 print:col-span-2">
          <GridCol title={'offer.item'} fontSize="xs">
            {item?.billOfMaterial?.inventory?.name ||
              item?.billOfMaterial?.name ||
              'No Item...'}
          </GridCol>
        </div>
        <div className="col-span-12 lg:col-span-9 print:col-span-9 h-full">
          <div
            className={`grid grid-cols-1 lg:divide-x print:divide-x h-full print:grid-cols-${colLength} sm:grid-cols-12 md:grid-cols-12 lg:grid-cols-${colLength}`}
          >
            <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.revisionNo'} fontSize="xs">
                {item?.billOfMaterial?.revisionNo || 'No Rev.'}
              </GridCol>
            </div>
            <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.estimatedDeliveryDate'} fontSize="xs">
                {handleLocalDateString(item.estimatedDeliveryDate)}
              </GridCol>
            </div>
            <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.qty'} fontSize="xs">
                {item?.qty +
                  ' ' +
                  (item.billOfMaterial?.inventory?.units?.name ||
                    item?.units?.name ||
                    ' No Units')}
              </GridCol>
            </div>
            <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.materialAndOperation'} fontSize="xs">
                {handleCeil(
                  item.subcontractSalesOfferPrice?.unitPrice -
                    ((operationCol?.unitPriceOperation || 0) +
                      (materialCol?.unitPriceMaterial || 0))
                ) + ' ₺'}
              </GridCol>
            </div>
            {operationCol?.operationCol?.length > 0 &&
              operationCol?.operationCol?.map((item: any) => item?.colItem)}
            {materialCol?.materialCol?.length > 0 &&
              materialCol?.materialCol?.map((item: any) => item?.colItem)}
            <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.unitPrice'} fontSize="xs">
                {handleCeil(item.subcontractSalesOfferPrice?.unitPrice) + ' ₺'}
              </GridCol>
            </div>
            <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
              <GridCol title={'offer.amount'} fontSize="xs">
                {item.subcontractSalesOfferPrice?.amount + ' ₺'}
              </GridCol>
            </div>
          </div>
        </div>
        <div className="col-span-12">
          {operationCol?.operationCol?.length > 0 &&
            operationCol.operationCol.map((item: any) => item?.operationDetail)}
        </div>
        <div className="col-span-12">
          {materialCol?.materialCol?.length > 0 &&
            materialCol.materialCol.map((item: any) => item?.materialDetail)}
        </div>
      </div>
    </Fragment>
  );
};
export default ListIteSubcontractSalesPreview;

/*
   <RowCustom style={{ cursor: 'pointer' }}>
        <Col sm={12} md={6} lg={4}>
          <LabelCustom title={<FormattedMessage id="offer.item" />}>
            {item?.billOfMaterial?.inventory?.name ||
              item?.billOfMaterial?.name ||
              'No Item...'}
          </LabelCustom>
        </Col>
        <Col xs>
           <Col sm={6} md={6} lg={2}> 
          <LabelCustom title={<FormattedMessage id="offer.estimatedDeliveryDate" />}>
            {handleLocalDateString(item.estimatedDeliveryDate)}
          </LabelCustom>
        </Col>
        <Col xs>
           <Col sm={6} md={4} lg={2}> 
          <LabelCustom title={<FormattedMessage id="offer.qty" />}>
            {item?.qty} &nbsp;{' '}
            {item.billOfMaterial?.inventory?.units?.name ||
              item?.units?.name ||
              'No Units'}
          </LabelCustom>
        </Col>
         <Col sm={6} md={3} lg={2}>
        <Col xs>
          <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
            {handleCeil(
              item.subcontractSalesOfferPrice.unitPrice -
                (operationCol?.unitPriceOperation || 0)
            ) + ' ₺'}
          </LabelCustom>
        </Col>
         <Col sm={6} md={3} lg={2}> 
        {operationCol?.operationCol?.length > 0 &&
          operationCol?.operationCol?.map((item: any) => item?.colItem)}
        {materialCol?.materialCol?.length > 0 &&
          materialCol?.materialCol?.map((item: any) => item?.colItem)}
        <Col xs>
          <LabelCustom title={<FormattedMessage id="offer.amount" />}>
            {item.subcontractSalesOfferPrice.amount + ' ₺'}
          </LabelCustom>
        </Col>
        
        <Col xs={12}>
          {operationCol?.operationCol?.length > 0 &&
            operationCol.operationCol.map((item: any) => item?.operationDetail)}
        </Col>
        <Col xs={12}>
          {materialCol?.materialCol?.length > 0 &&
            materialCol.materialCol.map((item: any) => item?.materialDetail)}
        </Col>
      </RowCustom>

*/

//const operationIds = cols.map((item) => item.operationIds.map((itm) => itm.id)).flat(1);
// const unitPriceOperation = subcontractSalesOfferOperationPrice.reduce((acc, item) => {
//   const { amount, profitRate, discountRate, executionPlanStepId } = item;
//   if (operationIds.includes(executionPlanStepId)) {
//     return (
//       acc +
//       calculation({ amount, profitRate, discountRate, _discountRate, _profitRate })
//     );
//   } else return acc + 0;
// }, 0);
// const calculation = ({
//   amount,
//   profitRate,
//   discountRate,
//   _profitRate,
//   _discountRate,
// }) => {
//   const profitAmount = amount + (amount * profitRate) / 100;
//   const discountAmount = profitAmount - (profitAmount * discountRate) / 100;
//   const _profitAmount = discountAmount + (discountAmount * _profitRate) / 100;
//   const _discountAmount = _profitAmount + (_profitAmount * _discountRate) / 100;
//   return handleCeil(_discountAmount);
// };
// const price = col.operationIds.reduce((acc, item) => {
//   const _price = _colOperationPrice(item.id).discountAmount;
//   console.log('------->', _colOperationPrice(item.id));
//   return acc + _price;
// }, 0);
// const colOperationPrice = (operationId: string) =>
//   subcontractSalesOfferOperationPrice.reduce((acc, item) => {
//     const { amount, profitRate, discountRate, executionPlanStepId } = item;
//     if (operationId === executionPlanStepId) {
//       return (
//         acc +
//         calculation({ amount, profitRate, discountRate, _discountRate, _profitRate })
//       );
//     } else {
//       return acc;
//     }
//   }, 0);
