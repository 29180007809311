import { useMutation } from '@apollo/react-hooks';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, SIZE } from 'baseui/input';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import Button from '../../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../../components/FormWithElemets/FormControl';
import { useToaster } from '../../../../../context/toaster-provider';
import { ProductSalesOffer } from '../../../../../types/ProductSalesOffer';
import { handleCeil, inputDecimal } from '../../../../Functions/Utilities';

const UPDATE_PRODUCT_SALES_OFFER_PRICE = loader(
  '../../../../../graphql/productSalesOfferPrice/UPDATE_PRODUCT_SALES_OFFER_PRICE.graphql'
);
const ADD_PRODUCT_SALES_OFFER_PRICE = loader(
  '../../../../../graphql/productSalesOfferPrice/ADD_PRODUCT_SALES_OFFER_PRICE.graphql'
);
interface ProductSalesPriceFormProps {
  item: ProductSalesOffer;
  transportUnitPrice: number;
  setItemAdd: Function;
}
const ProductSalesPriceForm: FunctionComponent<ProductSalesPriceFormProps> = ({
  item,
  transportUnitPrice,
  setItemAdd,
}) => {
  const { id, qty, unitPrice, productSalesOfferPrice } = item;
  const { showToaster } = useToaster();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      amount: productSalesOfferPrice?.amount | 0,
      discountRate: productSalesOfferPrice?.discountRate | 0,
      profitRate: productSalesOfferPrice?.profitRate | 0,
      taxRate: productSalesOfferPrice?.taxRate | 0,
    },
  });
  const { amount, profitRate, discountRate, taxRate } = watch();
  useEffect(() => {
    const _amount = handleCeil(qty * (unitPrice + transportUnitPrice))
    setValue('amount', _amount);
  }, [transportUnitPrice, qty, unitPrice]);
  useEffect(() => {
    const _profitAmount = handleCeil((amount * profitRate) / 100)
    setProfitAmount(_profitAmount);
    const _discountAmount = handleCeil(((amount + _profitAmount) * discountRate) / 100);
    setDiscountAmount(_discountAmount);
    const _taxAmount = handleCeil((amount + _profitAmount - _discountAmount) * discountRate) /
      100
      ;
    setTaxAmount(_taxAmount);
  }, [amount, discountRate, profitRate, taxRate, transportUnitPrice]);
  const onSubmit = ({  discountRate, profitRate, taxRate }) => {
    productSalesOfferPrice?.id
      ? update({
        variables: {
          id: productSalesOfferPrice?.id,
          qty: productSalesOfferPrice?.qty || 0,
          unitPrice: productSalesOfferPrice?.unitPrice || 0,
          amount: handleCeil(qty * unitPrice) ||0,
          discountRate: +discountRate || 0,
          profitRate: +profitRate || 0,
          taxRate: +taxRate || 0,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        setItemAdd(null);
      })
      : add({
        variables: {
          productSalesOfferId: id,
          amount: handleCeil(qty * unitPrice) ||0,
          discountRate: +discountRate || 0,
          profitRate: +profitRate || 0,
          taxRate: +taxRate || 0,
          qty: qty,
          unitPrice: unitPrice,
        },
      }).then(() => {
        showToaster('created', 'positive');
        setItemAdd(null);
      });
  };
  const [add] = useMutation(ADD_PRODUCT_SALES_OFFER_PRICE);
  const [update] = useMutation(UPDATE_PRODUCT_SALES_OFFER_PRICE);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom middle="xs">
        <Col sm={4} md={4} lg={2} style={{display:"none"}}>
          <FormControl label={<FormattedMessage id="offerPrice.amountPrice" />}>
            <Input
              type="text"
              name={'amount'}
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('amount', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl label={<FormattedMessage id="offerPrice.profitRate" />}>
            <Input
              type="text"
              name={'profitRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('profitRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={profitAmount + '₺'}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl
            label={<FormattedMessage id="offerPrice.discountRate" />}
          >
            <Input
              type="text"
              name={'discountRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('discountRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={discountAmount + '₺'}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl label={<FormattedMessage id="offerPrice.taxRate" />}>
            <Input
              type="text"
              name={'taxRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('taxRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={taxAmount + '₺'}
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button
            style={{ marginTop: '16px' }}
            type="button"
            onClick={() => setItemAdd(null)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button style={{ marginTop: '16px' }} type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default ProductSalesPriceForm;
