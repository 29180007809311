import React, { FunctionComponent, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Card } from '../../components/FormWithElemets/Card.style';
import Offers from './Offers';
import { OfferWithStages } from '../../types/OfferWithStages';
import { Can } from '../Layout/Layout';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../components/NoResult/NoResult';
import SubContractSales from './SubContractSales/SubContractSales';
import OfferAddTypeSelect from './OfferAddTypeSelect';
import OperationSalesOfferAdd from './OperationSales/OperationSalesOfferAdd';
import ToAssesment from './ToAssesment/ToAssesment';
import InReview from './InReview/InReview';
import ProductSalesOfferAdd from './ProductSales/ProductSalesOfferAdd';
import InOfferStage from './InOfferStage/InOfferStage';
import Done from './Done/Done';
const GET_OFFER_WITH_STAGES = loader(
  '../../graphql/offer/GET_OFFER_WITH_STAGES.graphql'
);
const S_GET_OFFER_WITH_STAGES = loader(
  '../../graphql/offer/S_GET_OFFER_WITH_STAGES.graphql'
);

export const ComponentStatusContext = React.createContext(null);
const initialState = { component: 'LIST', offerType: null };
type State = { component: 'LIST' | 'ADD'; offerType: string | null };
type Action =
  | { type: 'LIST' }
  | { type: 'ADD'; offerType: string }
  | { type: 'TO_ASSESSMENT'; offerType: string; data: any }
  | { type: 'IN_REVIEW'; offerType: string; data: any }
  | { type: 'IN_OFFER_STAGE'; offerType: string; data: any }
  | { type: 'DONE'; offerType: string; data: any };
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'LIST':
      return {
        component: 'LIST', 
        offerType: null,
      };
    case 'ADD':
      return {
        component: 'ADD',
        offerType: action.offerType,
      };
    case 'TO_ASSESSMENT':
      return {
        component: 'TO_ASSESSMENT',
        offerType: action.offerType,
        data: action.data,
      };
    case 'IN_REVIEW':
      return {
        component: 'IN_REVIEW',
        offerType: action.offerType,
        data: action.data,
      };
    case 'IN_OFFER_STAGE':
      return {
        component: 'IN_OFFER_STAGE',
        offerType: action.offerType,
        data: action.data,
      };
    case 'DONE':
      return {
        component: 'DONE',
        offerType: action.offerType,
        data: action.data,
      };
    default:
      return state;
  }
};
const Offer: FunctionComponent = () => {
  const [componentStatus, dispatchComponentStatus] = useReducer(
    reducer,
    initialState
  );
  const { subscribeToMore, data, loading, error } = useQuery<OfferWithStages>(
    GET_OFFER_WITH_STAGES,
    { notifyOnNetworkStatusChange: true }
  );
  useEffect(() => {
    subscribeToMore({
      document: S_GET_OFFER_WITH_STAGES,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.offerWithStages;
        return Object.assign({}, prev, {
          offerWithStages: newFeedItem,
        });
      },
    });
  }, [subscribeToMore]);
  useEffect(() => {
    if (componentStatus.component === 'IN_OFFER_STAGE' && data) {
      const _data = data.offerWithStages
        .find((item) => item.name === 'IN_OFFER_STAGE')
        .offer.find((itm) => itm.id === componentStatus.data.id);
      _data &&
        dispatchComponentStatus({
          type: 'IN_OFFER_STAGE',
          offerType: '',
          data: _data,
        });
    } else if (componentStatus.component === 'IN_REVIEW' && data) {
      const _data = data.offerWithStages
        .find((item) => item.name === 'IN_REVIEW')
        .offer.find((itm) => itm.id === componentStatus.data.id);
      _data &&
        dispatchComponentStatus({
          type: 'IN_REVIEW',
          offerType: '',
          data: _data,
        });
    }
    // } else if (componentStatus.component === 'TO_ASSESSMENT' && data) {
    //   const _data = data.offerWithStages
    //     .find((item) => item.name === 'TO_ASSESSMENT')
    //     .offer.find((itm) => itm.id === componentStatus.data.id);
    //   _data &&
    //     dispatchComponentStatus({
    //       type: 'TO_ASSESSMENT',
    //       offerType: '',
    //       data: _data,
    //     });
    // }
  }, [data]);
  return (
    <Can I="LIST" a="OFFERS">
      <ComponentStatusContext.Provider
        value={{ componentStatus, dispatchComponentStatus }}
      >
        {componentStatus.component === 'LIST' && <OfferAddTypeSelect />}
        <div
          style={
            componentStatus.component === 'LIST'
              ? { display: 'inline' }
              : { display: 'none' }
          }
        >
          {loading ? (
            <StyledSpinnerNext />
          ) : error ? (
            <NoResult />
          ) : (
            data && <Offers offerWithStagesData={data.offerWithStages} />
          )}
        </div>
        {componentStatus.component === 'ADD' && (
          <Card>
            {componentStatus?.offerType === 'Operation' ? (
              <OperationSalesOfferAdd />
            ) : componentStatus?.offerType === 'Subcontract' ? (
              <SubContractSales /> 
            ) : (
              <ProductSalesOfferAdd />
            )}
          </Card>
        )}
        {componentStatus.component === 'TO_ASSESSMENT' && <ToAssesment />}
        {componentStatus.component === 'IN_REVIEW' && <InReview />}
        {componentStatus.component === 'IN_OFFER_STAGE' && <InOfferStage />}
        {componentStatus.component === 'DONE' && <Done />}
      </ComponentStatusContext.Provider>
    </Can>
  );
};
export default Offer;
