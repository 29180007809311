// @ts-nocheck
import React, { useCallback, useEffect, useState, FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import {
//handleAddProduct,
  handleUpdateProduct,
  handleAddProductRevision,
} from './GraphqlFunction';
import { Card, CardTitle } from '../../components/FormWithElemets/Card.style';
import { useToaster } from '../../context/toaster-provider';
import ProductCategoryAndSelect from './ProductCategoryAndAqlSelect';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowToBottom, faArrowToTop } from '@fortawesome/pro-regular-svg-icons';
//const ADD_PRODUCT = loader('../../graphql/product/ADD_PRODUCT.graphql');
const ADD_REVISION = loader('../../graphql/product/ADD_REVISION.graphql');
const UPDATE_PRODUCT = loader('../../graphql/product/UPDATE_PRODUCT.graphql');

export const ProductForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const { showToaster } = useToaster();
  const [createType, setCreateType] = useState(false);
  const dispatch = useDrawerDispatch();
  const { item: dataDispatch, cacheSearch } = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  console.log("dataDispatch",dataDispatch)
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
      setValue('fullControl', dataDispatch.fullControl);
      setValue(
        'aql',
        dataDispatch.aql?.id
          ? [{ id: dataDispatch.aql.id, label: dataDispatch.aql.code }]
          : undefined
      );
      setValue('productCategory', [
        {
          id: dataDispatch.productCategory.id,
          label: dataDispatch.productCategory.name,
        },
      ]);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name, productCategory, aql, fullControl }) => {
      updateProduct({
          variables: {
            id: dataDispatch.id,
            name: name,
            aqlId: aql ? aql[0]?.id : null,
            fullControl: fullControl,
            productCategory: createType ? productCategory[0].id : null,
            productCategoryId: !createType ? productCategory[0].id : null,
          },
        })
      // : addProduct({
      //     variables: {
      //       name: name,
      //       aqlId: aql ? aql[0]?.id : null,
      //       productCategory: createType ? productCategory[0].id : null,
      //       productCategoryId: !createType ? productCategory[0].id : null,
      //     },
      //   });
  };
  // const [addProduct] = useMutation(ADD_PRODUCT, {
  //   update(cache, { data: { createProduct } }) {
  //     handleAddProduct(cache, createProduct, createType, cacheSearch);
  //     showToaster('created', 'positive');
  //     closeDrawer();
  //   },
  // });
  const [updateProduct] = useMutation(UPDATE_PRODUCT, {
    update(cache, { data: { updateProduct } }) {
      handleUpdateProduct(cache, updateProduct, cacheSearch);
      showToaster('updated', 'positive');
      closeDrawer();
    },
  });
  return (
    <>
      <Card>
        <CardTitle
          title={
            <FormattedMessage id={dataDispatch ? 'product.update' : 'product.create'} />
          }
        />
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <FormControl
            label={<FormattedMessage id="product.name" />}
            error={errors.name ? 'Please input a valid Name' : null}
          >
            <Input
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              error={errors.name}
              overrides={errors.name ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
          <ProductCategoryAndSelect
            control={control}
            errors={errors}
            col={12}
            setCreateType={setCreateType}
          />
          <Controller
            as={
              <Checkbox
                name="full_control"
                overrides={{
                  Label: {
                    style: () => {
                      return {
                        marginTop: '40px',
                        marginBottom: '5px',
                        color: '#454b68',
                      };
                    },
                  },
                  Checkmark: {
                    style: () => {
                      return {
                        marginTop: '40px',
                        marginBottom: '5px',
                      };
                    },
                  },
                }}
              >
                <FormattedMessage id="product.fullControl" />
              </Checkbox>
            }
            name="fullControl"
            type="checkbox"
            control={control}
            labelPlacement={LABEL_PLACEMENT.right}
            defaultValue={false}
          />
          <Row
            end="md"
            style={{
              width: '100%',
              marginTop: '50px',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
              padding: 0,
            }}
          >
            <Col md={6}>
              <Button onClick={closeDrawer} style={{ width: '100%' }}>
                <FormattedMessage id="button.close" />
              </Button>
            </Col>
            <Col md={6}>
              <Button type="submit" style={{ width: '100%' }}>
                <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
              </Button>
            </Col>
          </Row>
        </Form>
      </Card>
      <ProductRevisionForm
        productId={dataDispatch.id}
        productRevisionNo={
          dataDispatch.productRevision.find((item) => item.active === true)?.no
        }
        cacheSearch={cacheSearch}
      />
    </>
  );
};

interface ListPropsRevision {
  productId: string;
  productRevisionNo: string;
  cacheSearch: {
    productCategoryId: string;
    name: string;
  };
}
const ProductRevisionForm: FunctionComponent<ListPropsRevision> = ({
  productId,
  productRevisionNo,
  cacheSearch,
}) => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [expand, setExpend] = useState(false);
  const [revisionNo, setRevisionNo] = useState(() => productRevisionNo);
  const { showToaster } = useToaster();
  const onSubmit = ({ no }) => {
    add({
      variables: {
        no: no,
        active: true,
        productId: productId,
      },
    }).then(({ data }) => {
      setValue('no', '');
      setRevisionNo(data.createProductRevision.no);
      showToaster('created', 'positive');
      setExpend(false);
    });
  };
  const [add] = useMutation(ADD_REVISION, {
    update(cache, { data: { createProductRevision } }) {
      handleAddProductRevision(cache, productId, createProductRevision, cacheSearch);
    },
  });
  return (
    <Card>
      <CardTitle title={<FormattedMessage id={'product_revision.create'} />}>
        <FormattedMessage id="product_revision.no" /> <span style={{marginRight:'10px'}}>{' : ' + revisionNo}</span>
        <Button onClick={() => setExpend(!expand)}>
          <FontAwesomeIcon icon={expand ? faArrowToTop : faArrowToBottom} />
        </Button>
      </CardTitle>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={
          expand
            ? {
                height: '100%',
                backgroundColor: 'transparent',
              }
            : { display: 'none' }
        }
      >
        <FormControl
          label={<FormattedMessage id="product_revision.no" />}
          error={errors.no ? 'Please input a valid Name' : null}
        >
          <Input
            size={SIZE.compact}
            name="no"
            inputRef={register({
              required: true,
            })}
            error={errors.no}
            overrides={errors.no ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="xs"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ProductForm;
