import React, { FunctionComponent, useCallback, useEffect, useReducer } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage, useIntl } from 'react-intl';
import { Textarea } from '../../components/Textarea/Textarea';
import { Row, Col, RowCustom } from '../../components/FlexBox/FlexBox';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import Button from '../../components/FormWithElemets/Button/Button';
import { Form, ButtonGroup, Hr, Label } from './Company.style';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { handleAddCompany, handleUpdateCompany } from './GraphqlFunction';
import { TYPE } from 'baseui/select';
import DataTableCustom, { ColumnContents } from '../../components/Table/DataTableCustom';
import { CardBody, Card } from '../../components/FormWithElemets/Card.style';

const ADD_COMPANY = loader('../../graphql/company/ADD_COMPANY.graphql');
const UPDATE_COMPANY = loader('../../graphql/company/UPDATE_COMPANY.graphql');
interface StateAddress {
  id: number;
  address: string; 
  city: string;
  country: string;
  county: string;
} 
type ActionAddress =
  | {
      type: 'ADD';
      item: StateAddress;
    }
  | { type: 'DELETE'; index: number }
  | {
      type: 'UPDATE';
      item: StateAddress;
      index: number;
    }
  | {
      type: 'RESET';
    };
const reducerAddress = (state: StateAddress[], action: ActionAddress) => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.item];
    case 'UPDATE':
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...action.item,
          };
        }
        return item;
      });
    case 'DELETE':
      return state
        .filter((item, index) => index !== action.index)
        .map((item, index) => {
          return {
            ...item,
            id: index + 1,
          };
        });
    case 'RESET':
      return [];
    default:
      return state;
  }
};
interface StateTelephone {
  id: number;
  areaCode: string;
  telephone: string;
}
type ActionTelephone =
  | {
      type: 'ADD';
      item: StateTelephone;
    }
  | { type: 'DELETE'; index: number }
  | {
      type: 'UPDATE';
      item: StateTelephone;
      index: number;
    }
  | {
      type: 'RESET';
    };
const reducerTelephone = (state: StateTelephone[], action: ActionTelephone) => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.item];
    case 'UPDATE':
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...action.item,
          };
        }
        return item;
      });
    case 'DELETE':
      return state
        .filter((item, index) => index !== action.index)
        .map((item, index) => {
          return {
            ...item,
            id: index + 1,
          };
        });
    case 'RESET':
      return [];
    default:
      return state;
  }
};
const optionsType = [
  { id: 'BOTH', label: 'BOTH' },
  { id: 'CUSTOMER', label: 'CUSTOMER' },
  { id: 'SUPPLIER', label: 'SUPPLIER' },
];
const CompanyForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue, getValues, control } = useForm();
  const [addressItems, dispatchAddressItems] = useReducer(reducerAddress, []);
  const [telephoneItems, dispatchTelephoneItems] = useReducer(reducerTelephone, []);
  const { showToaster } = useToaster();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const closeDrawer = useCallback(
    (sendData?) => {
      transactionsReasonDispatch === 'anotherComponent'
        ? dispatch({
            type: 'CLOSE_DRAWER',
            data: { companyName: sendData } || undefined,
            transactionsReason: 'companyForm',
          })
        : dispatch({
            type: 'CLOSE_DRAWER',
          });
    },
    [dispatch, transactionsReasonDispatch]
  );
  useEffect(() => {
    if (transactionsReasonDispatch === 'anotherComponent') {
      setValue('companyName', dataDispatch?.name);
    } else if (dataDispatch) {
      setValue('companyName', dataDispatch?.companyName);
      setValue('email', dataDispatch?.email);
      setValue('companyShortName', dataDispatch?.companyShortName);
      setValue('category', dataDispatch?.category);
      setValue('companyType', [
        { id: dataDispatch?.companyType, label: dataDispatch?.companyType },
      ]);
      setValue('taxNumber', dataDispatch?.taxNumber);
      setValue('taxOffice', dataDispatch?.taxOffice);
    }
  }, [dataDispatch, setValue, transactionsReasonDispatch]);
  const [addCompany] = useMutation(ADD_COMPANY, {
    update(cache, { data: { createCompany } }) {
      handleAddCompany(cache, createCompany);
    },
  });
  const [updateCompany] = useMutation(UPDATE_COMPANY, {
    update(cache, { data: { updateCompany } }) {
      handleUpdateCompany(cache, updateCompany);
    },
  });
  const onSubmit = ({
    category,
    currency,
    companyType,
    companyShortName,
    companyName,
    taxNumber,
    taxOffice,
    email
  }) => {
    if (transactionsReasonDispatch === 'update') {
      updateCompany({
        variables: {
          id: dataDispatch.id,
          category: category,
          companyName: companyName,
          companyShortName: companyShortName,
          companyType: companyType[0].id,
          taxNumber: taxNumber,
          taxOffice: taxOffice,
          email:email
        },
      }).then(({ data }) => {
        closeDrawer();
        showToaster('updated', 'positive');
      });
    } else {
      addCompany({
        variables: {
          category: category,
          companyName: companyName,
          companyShortName: companyShortName,
          companyType: companyType[0].id,
          taxNumber: taxNumber,
          taxOffice: taxOffice,
          email:email,
          currency: currency || 'TRY',
          companyAddress: addressItems.map((item) => {
            return {
              address: item.address,
              city: item.city,
              country: item.country,
              county: item.county,
            };
          }),
          companyTelephones: telephoneItems.map((item) => {
            return {
              areaCode: item.areaCode,
              telephone: item.telephone,
            };
          }),
        },
      }).then(({ data: { createCompany } }) => {
        transactionsReasonDispatch === 'anotherComponent'
          ? closeDrawer([{ id: createCompany.id, label: createCompany.companyName }])
          : closeDrawer();
        showToaster('created', 'positive');
      });
    }
  };

  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Scrollbars
        autoHide
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
        )}
        renderTrackHorizontal={(props) => (
          <div {...props} style={{ display: 'none' }} className="track-horizontal" />
        )}
      >
        <Card>
          <CardBody style={{ padding: '5px' }}>
            <Row style={{ margin: 0, padding: 0 }}>
              <Col sm md>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col md={12}>
                    <FormControl
                      label={<FormattedMessage id="company.companyName" />}
                      error={
                        errors.companyName ? 'Please input a valid Company Name' : null
                      }
                    >
                      <Input
                        size={SIZE.compact}
                        name="companyName"
                        inputRef={register({
                          required: true,
                        })}
                        error={errors.companyName}
                        overrides={
                          errors.companyName ? { After: Negative } : { After: Positive }
                        }
                      />
                    </FormControl>
                  </Col>
                </Row>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col sm={8} md={8}>
                    <FormControl
                      label={<FormattedMessage id="company.companyShortName" />}
                      error={
                        errors.companyShortName
                          ? 'Please input a valid Company Short Name'
                          : null
                      }
                    >
                      <Input
                        size={SIZE.compact}
                        name="companyShortName"
                        inputRef={register({
                          required: true,
                        })}
                        error={errors.companyShortName}
                        overrides={
                          errors.companyShortName
                            ? { After: Negative }
                            : { After: Positive }
                        }
                      />
                    </FormControl>
                  </Col>
                  <Col sm={4} md={4}>
                    <FormControl
                      label={<FormattedMessage id="company.companyType" />}
                      error={
                        errors.companyType ? 'Please select a valid Company Type' : null
                      }
                    >
                      <Select
                        data={optionsType}
                        labelValue="label"
                        controller={{
                          name: 'companyType',
                          type: TYPE.search,
                          control,
                          rules: { required: true },
                          creatable: false,
                          searchable: false,
                          defaultValue: [{ id: 'BOTH', label: 'BOTH' }],
                          onChange: ([selected]) => {
                            return selected.value;
                          },
                        }}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} >
                    <FormControl label={<FormattedMessage id="company.email" />}>
                      <Input
                        size={SIZE.compact}
                        name="email"
                        inputRef={register({
                          required: false,
                        })}
                        error={errors.email}
                        overrides={
                          errors.email ? { After: Negative } : { After: Positive }
                        }
                      />
                    </FormControl>
                  </Col>
                </Row>
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col sm={4} md={4}>
                    <FormControl label={<FormattedMessage id="company.category" />}>
                      <Input
                        size={SIZE.compact}
                        name="category"
                        inputRef={register({
                          required: false,
                        })}
                        error={errors.category}
                        overrides={
                          errors.category ? { After: Negative } : { After: Positive }
                        }
                      />
                    </FormControl>
                  </Col>
                  <Col sm={4} md={4}>
                    <FormControl
                      label={<FormattedMessage id="company.taxNumber" />}
                      error={errors.taxNumber ? 'Please input a valid Tax Number' : null}
                    >
                      <Input
                        size={SIZE.compact}
                        name="taxNumber"
                        inputRef={register({
                          required: true,
                        })}
                        error={errors.taxNumber}
                        overrides={
                          errors.taxNumber ? { After: Negative } : { After: Positive }
                        }
                      />
                    </FormControl>
                  </Col>
                  <Col sm={4} md={4}>
                    <FormControl
                      label={<FormattedMessage id="company.taxOffice" />}
                      error={errors.taxOffice ? 'Please input a valid Tax Office' : null}
                    >
                      <Input
                        size={SIZE.compact}
                        name="taxOffice"
                        inputRef={register({
                          required: true,
                        })}
                        error={errors.taxOffice}
                        overrides={
                          errors.taxOffice ? { After: Negative } : { After: Positive }
                        }
                      />
                    </FormControl>
                  </Col>
                </Row>
              </Col>
              {!(
                transactionsReasonDispatch === 'update' ||
                transactionsReasonDispatch === 'anotherComponent'
              ) && (
                <Col
                  md={3}
                  sm={12}
                  style={{
                    textAlign: 'center',
                    boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <Label>
                    <FormattedMessage id="cash_book.currency" />
                  </Label>
                  <Row>
                    <Col xs={6} style={{ textAlign: 'center' }}>
                      <Controller
                        as={
                          <RadioGroup align={ALIGN.vertical}>
                            <Radio value="TRY">TRY</Radio>
                            <Radio value="EUR">EUR</Radio>
                            <Radio value="USD">USD</Radio>
                          </RadioGroup>
                        }
                        defaultValue={'TRY'}
                        name={'currency'}
                        control={control}
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
            {!(
              transactionsReasonDispatch === 'anotherComponent' ||
              transactionsReasonDispatch === 'update'
            ) && (
              <>
                <Hr />
                <CompanyAddress
                  control={control}
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  data={addressItems}
                  dispatchAddressItems={dispatchAddressItems}
                />
                <Hr />
                <CompanyTelephone
                  getValues={getValues}
                  setValue={setValue}
                  register={register}
                  data={telephoneItems}
                  dispatchTelephoneItems={dispatchTelephoneItems}
                />
              </>
            )}
            <ButtonGroup style={{ textAlign: 'center' }}>
              <Row style={{ margin: 0, padding: 0, width: '100%' }}>
                <Col md={6}>
                  <Button onClick={() => closeDrawer(null)} style={{ width: '100%' }}>
                    <FormattedMessage id="button.close" />
                  </Button>
                </Col>
                <Col md={6}>
                  <Button type="submit" style={{ width: '100%' }}>
                    <FormattedMessage
                      id={
                        transactionsReasonDispatch === 'update'
                          ? 'button.update'
                          : 'button.create'
                      }
                    />
                  </Button>
                </Col>
              </Row>
            </ButtonGroup>
          </CardBody>
        </Card>
      </Scrollbars>
    </Form>
  );
};
export default CompanyForm;
const columnAddressHeaders = [
  { name: 'company.address.city', col: { md: 4 } },
  { name: 'company.address.country', col: { md: 4 } },
  { name: 'company.address.county', col: { md: 4 } },
];
interface ListCompanyAddressProps {
  data: {
    id: number;
    address: string;
    city: string;
    country: string;
    county: string;
  }[];
  control: any;
  register: any;
  setValue: any;
  dispatchAddressItems: any;
  getValues: any;
}
const CompanyAddress: FunctionComponent<ListCompanyAddressProps> = ({
  data,
  control,
  register,
  setValue,
  dispatchAddressItems,
  getValues,
}) => {
  const intl = useIntl();
  const columnContents: ColumnContents[] = [
    {
      name: 'country',
      title: { name: 'company.address.country', custom: true },
      type: 'text',
      col: { md: 4 },
    },
    {
      name: 'city',
      title: { name: 'company.address.city', custom: true },
      type: 'text',
      col: { md: 4 },
    },
    {
      name: 'county',
      title: { name: 'company.address.county', custom: true },
      type: 'text',
      col: { md: 4 },
    },
    {
      name: 'address',
      title: { name: 'company.address', custom: false },
      type: 'text',
      col: { md: 9 },
    },
    {
      name: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleDeleteAddressItem(id);
          },
        },
      ],
      title: { name: 'transactions', custom: true },
      type: 'buttons',
      col: { md: 3 },
    },
  ];
  const handleDeleteAddressItem = (idx) => {
    if (window.confirm('Are you sure !')) {
      dispatchAddressItems({
        type: 'DELETE',
        index: idx - 1,
      });
    }
  };

  return (
    <Card>
      <CardBody>
        <DataTableCustom
          threeDot
          paginationInclude={false}
          data={data}
          columnHeaders={columnAddressHeaders}
          columnContents={columnContents}
          formAdd={() => (
            <CompanyAddressForm
              index={data.length + 1}
              setValue={setValue}
              control={control}
              register={register}
              getValues={getValues}
              dispatchAddressItems={dispatchAddressItems}
            />
          )}
          formUpdate={({ item, setItemId }) =>
            item && (
              <CompanyAddressForm
                data={item}
                setItemId={setItemId}
                control={control}
                setValue={setValue}
                register={register}
                getValues={getValues}
                dispatchAddressItems={dispatchAddressItems}
              />
            )
          }
        />
      </CardBody>
    </Card>
  );
};
interface ListCompanyAddressFormProps {
  data?: {
    id: number;
    address: string;
    city: string;
    country: string;
    county: string;
  };
  index?: number;
  setItemId?: Function;
  control: any;
  register: any;
  setValue: any;
  dispatchAddressItems: any;
  getValues;
}
const CompanyAddressForm: FunctionComponent<ListCompanyAddressFormProps> = ({
  data,
  setItemId,
  index,
  control,
  register,
  setValue,
  dispatchAddressItems,
  getValues,
}) => {
  const { showToaster } = useToaster();
  useEffect(() => {
    if (data) {
      setValue('address', data?.address);
      setValue('city', data?.city);
      setValue('country', data?.country);
      setValue('county', data?.county);
    }
  }, [data,setValue]);
  const handleResetAddress = () => {
    setValue('address', '');
    setValue('city', '');
    setValue('country', '');
    setValue('county', '');
  };
  const handleAddOrUpdateAddressItem = async () => {
    const { address, city, country, county } = getValues();
    if (!data) {
      dispatchAddressItems({
        type: 'ADD',
        item: {
          id: index,
          address,
          city,
          country,
          county,
        },
      });
      showToaster('created', 'positive');
    } else {
      dispatchAddressItems({
        type: 'UPDATE',
        item: {
          id: data.id,
          address,
          city,
          country,
          county,
        },
        index: data.id - 1,
      });
      showToaster('updated', 'positive');
    }
    handleResetAddress();
  };
  return (
    <RowCustom bottom="md" style={{ padding: '5px' }}>
      <Col md={4}>
        <FormControl>
          <Input size={SIZE.compact} name={`country`} inputRef={register} />
        </FormControl>
      </Col>
      <Col md={4}>
        <FormControl>
          <Input size={SIZE.compact} name={`city`} inputRef={register} />
        </FormControl>
      </Col>
      <Col md={4}>
        <FormControl>
          <Input size={SIZE.compact} name={`county`} inputRef={register} />
        </FormControl>
      </Col>
      <Col md={10}>
        <Controller
          as={<Textarea size={SIZE.mini} />}
          name={`address`}
          control={control}
          inputRef={register}
          placeholder="Address...."
        />
      </Col>
      <Col md={2}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            style={{}}
            onClick={() => {
              handleAddOrUpdateAddressItem();
              data && setItemId(null);
            }}
          >
            <FormattedMessage id={data ? 'button.update' : 'button.add'} />
          </Button>
        </div>
      </Col>
    </RowCustom>
  );
};
const columnTelephoneHeaders = [
  { name: 'company.areaCode', col: { md: 3 } },
  { name: 'company.telephone', col: { md: 5 } },
  { name: 'transactions', col: { md: 3 } },
];
interface ListCompanyTelephoneProps {
  data: {
    id: number;
    telephone: string;
    areaCode: string;
  }[];
  register: any;
  setValue: any;
  dispatchTelephoneItems: any;
  getValues: any;
}
const CompanyTelephone: FunctionComponent<ListCompanyTelephoneProps> = ({
  data,
  register,
  setValue,
  dispatchTelephoneItems,
  getValues,
}) => {
  const intl = useIntl();
  const columnContents: ColumnContents[] = [
    {
      name: 'areaCode',
      title: { name: 'company.areaCode', custom: true },
      type: 'text',
      col: { md: 4 },
    },
    {
      name: 'telephone',
      title: { name: 'company.telephone', custom: true },
      type: 'text',
      col: { md: 4 },
    },
    {
      name: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleDeleteTelephoneItem(id);
          },
        },
      ],
      title: { name: 'transactions', custom: true },
      type: 'buttons',
      col: { md: 3 },
    },
  ];
  const handleDeleteTelephoneItem = (idx) => {
    if (window.confirm('Are you sure !')) {
      dispatchTelephoneItems({
        type: 'DELETE',
        index: idx - 1,
      });
    }
  };
  return (
    <Card style={{ marginBottom: '30px' }}>
      <CardBody>
        <DataTableCustom
          threeDot
          paginationInclude={false}
          data={data}
          columnHeaders={columnTelephoneHeaders}
          columnContents={columnContents}
          formAdd={() => (
            <CompanyTelephoneForm
              index={data.length + 1}
              setValue={setValue}
              register={register}
              getValues={getValues}
              dispatchTelephoneItems={dispatchTelephoneItems}
            />
          )}
          formUpdate={({ item, setItemId }) =>
            item && (
              <CompanyTelephoneForm
                data={item}
                setItemId={setItemId}
                setValue={setValue}
                register={register}
                getValues={getValues}
                dispatchTelephoneItems={dispatchTelephoneItems}
              />
            )
          }
        />
      </CardBody>
    </Card>
  );
};
interface ListCompanyTelephoneFormProps {
  data?: {
    id: number;
    telephone: string;
    areaCode: string;
  };
  index?: number;
  setItemId?: Function;
  register: any;
  setValue: any;
  dispatchTelephoneItems: any;
  getValues:any;
}
const CompanyTelephoneForm: FunctionComponent<ListCompanyTelephoneFormProps> = ({
  data,
  setItemId,
  index,
  register,
  setValue,
  dispatchTelephoneItems,
  getValues,
}) => {
  const { showToaster } = useToaster();
  useEffect(() => {
    if (data) {
      setValue('telephone', data?.telephone);
      setValue('areaCode', data?.areaCode);
    }
  }, [data,setValue]);
  const handleResetTelephone = () => {
    setValue('telephone', '');
    setValue('areaCode', '');
  };
  const handleAddOrUpdateTelephoneItem = async () => {
    const { telephone, areaCode } = getValues();
    if (!data) {
      dispatchTelephoneItems({
        type: 'ADD',
        item: {
          id: index,
          telephone,
          areaCode,
        },
      });
      showToaster('created', 'positive');
    } else {
      dispatchTelephoneItems({
        type: 'UPDATE',
        item: {
          id: data.id,
          telephone,
          areaCode,
        },
        index: data.id - 1,
      });
      showToaster('updated', 'positive');
    }
    handleResetTelephone();
  };
  return (
    <RowCustom top="xs" style={{ padding: '5px' }}>
      <Col md={3}>
        <FormControl>
          <Input size={SIZE.compact} name={`areaCode`} inputRef={register} />
        </FormControl>
      </Col>
      <Col md={6}>
        <FormControl>
          <Input size={SIZE.compact} name={`telephone`} inputRef={register} />
        </FormControl>
      </Col>

      <Col md={3}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            type="button"
            style={{}}
            onClick={() => {
              handleAddOrUpdateTelephoneItem();
              data && setItemId(null);
            }}
          >
            <FormattedMessage id={data ? 'button.update' : 'button.add'} />
          </Button>
        </div>
      </Col>
    </RowCustom>
  );
};
