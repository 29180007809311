import React, { FunctionComponent } from 'react';

interface Props {
  register?: any;
  errors?: boolean;
  refInput?: React.LegacyRef<HTMLInputElement>;
  className?: string;
}
const Input: FunctionComponent<Props & InputType> = ({
  register,
  errors = false,
  refInput,
  className,
  ...props
}) => {
  const _errorsClassName = errors
    ? 'pr-10 border-red-300 text-red-900 placeholder-red-300 '
    : 'border-gray-300  placeholder-gray-400 ';
  return (
    <div className=" relative rounded-md shadow-sm">
      <input
        className={
          'appearance-none block w-full px-3 py-2 border  rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm ' +
          _errorsClassName +
          className
        }
        ref={register || refInput}
        {...props}
      />
      {errors && (
        <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
          <svg
            className="h-5 w-5 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      )}
    </div>
  );
};
export default Input;
export type InputType = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;
