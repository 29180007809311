import { TYPE } from "baseui/select";
import React, { FunctionComponent } from "react"
import { FormattedMessage } from "react-intl";
import FormControl from "../../../../../../components/FormWithElemets/FormControl";
import { Select } from "../../../../../../components/FormWithElemets/Select/Select";
interface CustomSelectMaterialProps {
    items: {id:string,name:string}[];
    errors: any;
    control: any;
}
const CustomSelectMaterial: FunctionComponent<CustomSelectMaterialProps> = ({ items, errors, control }) => {
    return (
        <FormControl
            label={<FormattedMessage id="select.material" />}
            error={
                errors?.materialIds ? 'Please Select a valid Material' : null
            }
        >
            <Select
                data={items}
                controller={{
                    name: 'materialIds',
                    type: TYPE.search,
                    multi: true,
                    control,
                    creatable: false,
                    rules: { required: errors ? true : false },
                    searchable: true,
                    filterOutSelected: false,
                    onChange: ([selected]) => {
                        return selected.option ? selected.value : undefined;
                    },
                }}
            />
        </FormControl>
    );
}
export default CustomSelectMaterial