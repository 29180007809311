import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

interface BreadcrumbsCustomProps {
    inputOrOutput: string;
    dispatch: Function;
}
const BreadcrumbsCustom: FunctionComponent<BreadcrumbsCustomProps> = ({
    inputOrOutput,
    dispatch
}) => {
    return (
        <Breadcrumbs>
            <StyledLink onClick={() => {
                dispatch({
                    type: 'STEP',
                    data: null,
                });
            }}>
                <FormattedMessage id="executionPlanning.steps" />
            </StyledLink>
            {inputOrOutput === 'STEP_INPUT' && (
                <StyledLink>
                    <FormattedMessage id="executionPlanningSteps.INPUT" />
                </StyledLink>
            )}
            {inputOrOutput === 'STEP_OUTPUT' && (
                <StyledLink>
                    <FormattedMessage id="executionPlanningSteps.OUTPUT" />
                </StyledLink>
            )}
        </Breadcrumbs>
    );
};

export default BreadcrumbsCustom