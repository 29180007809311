import React, { FunctionComponent } from 'react';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import { Col } from 'react-flexbox-grid';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { FormattedMessage } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import { Negative, Positive } from '../../../../components/General/NegativePositive';
import { Input, SIZE } from 'baseui/input';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Textarea } from 'baseui/textarea';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../../context/toaster-provider';
import { ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import { inputDecimal } from '../../../Functions/Utilities';
import CheckboxCustom from '../../../CheckboxCustom/CheckboxCustom';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import SelectCompany from '../../../SelectCustom/SelectCompany';

const ADD_OFFER_TRANSPORT = loader(
  '../../../../graphql/offer/transport/ADD_OFFER_TRANSPORT.graphql'
);
const UPDATE_OFFER_TRANSPORT = loader(
  '../../../../graphql/offer/transport/UPDATE_OFFER_TRANSPORT.graphql'
);

interface ListProps {
  item?: OfferTransport;
  setItemId?: Function;
  offerId: string;
  setClose?:Function
}
const TransportForm: FunctionComponent<ListProps> = ({
  item,
  setItemId,
  offerId,
  setClose
}) => {
  const { register, handleSubmit, errors, setValue, control } = useForm({
    defaultValues: {
      price: item?.price || '',
      approved: item?.approved || false,
      description: item?.description || '',
      companyName: item && item?.company
        ? [{ id: item?.company?.id, label: item?.company?.companyShortName }]
        : undefined,
    },
  });
  const { showToaster } = useToaster();
  const onSubmit = ({ price, approved, description, companyName }) => {
    if (item) {
      updateOfferTransport({
        variables: {
          id: item?.id,
          price: Number(price),
          companyId: companyName[0]?.id,
          approved: approved,
          description: description,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
    } else {
      addOfferTransport({
        variables: {
          offerId: offerId,
          price: Number(price),
          companyId: companyName[0]?.id,
          approved: approved,
          description: description,
        },
      }).then(() => {
        handleResetForm();
        showToaster('created', 'positive')
      });
    }
  };
  const [addOfferTransport] = useMutation(ADD_OFFER_TRANSPORT);
  const [updateOfferTransport] = useMutation(UPDATE_OFFER_TRANSPORT);
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('companyName', undefined);
      setValue('price', '');
      setValue('approved', false);
      setValue('description', '');
      setClose(false);
    }
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
    >
      <RowCustom>
        <Col md={5}>
          <SelectCompany control={control} errors={errors} />
        </Col>
        <Col md={3}>
          <FormControl
            label={<FormattedMessage id="offer_item_transport.price" />}
            error={errors?.price ? 'Please input a valid price' : null}
          >
            <Input
              type="text"
              size={SIZE.compact}
              name="price"
              inputRef={register({
                required:true
              })}
              error={!!errors.price}
              overrides={errors.price ? { After: Negative } : { After: Positive }}
              onChange={(event) => {
                setValue('price', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col md={2}>
          <CheckboxCustom  control={control} name="approved" style={{marginTop:'8px'}}/>
        </Col>
        <ColButton xs>
          <Button
            type="button"
            style={{ marginTop: '16px' }}
            onClick={() => {
              handleResetForm();
            }}
          >
            <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
          </Button>
          <Button
            type="submit"
            style={{ marginTop: '16px' }}
          >
            <FormattedMessage id="button.ok" />
          </Button>
        </ColButton>
        <Col md={12}>
          <FormControl label={<FormattedMessage id="offer_item_transport.description" />}>
            <Controller
              as={<Textarea />}
              clearable
              control={control}
              size={SIZE.compact}
              id="textarea-id"
              name="description"
            />
          </FormControl>
        </Col>
      </RowCustom>
    </Form>
  );
};
export default TransportForm;
