import { useMutation } from '@apollo/react-hooks';
import {
  faArrowAltDown,
  faArrowAltUp,
  faCheck,
  faEdit,
  faTimes,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import React, {
  createContext,
  Fragment,
  FunctionComponent,
  memo,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { useToaster } from '../../../../context/toaster-provider';
import { PurchaseMaterialOffer } from '../../../../types/PurchaseMaterialOffer';
import { PurchaseOperationOffer } from '../../../../types/PurchaseOperationOffer';
import { SubcontractSalesOffer } from '../../../../types/SubcontractSalesOffer';
import DateCustom from '../../../DateCustom/DateCustom';
import { handleLocalDateString } from '../../../Functions/Utilities';
import ItemDetail from './ItemDetailPrice/ItemDetail';
const UPDATE_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/UPDATE_SUBCONTRACT_SALES_OFFER.graphql'
);
export const ContextSubcontractSalesOfferId = createContext(null);
interface ItemProps {
  item: SubcontractSalesOffer;
  itemId: string;
  setItemId: Function;
  transportUnitPrice: number;
  purchaseOperationOffer: PurchaseOperationOffer[];
  purchaseMaterialOffer: PurchaseMaterialOffer[];
}
const ListItemSuncontractSales: FunctionComponent<ItemProps> = memo(
  ({
    item,
    setItemId,
    itemId,
    transportUnitPrice,
    purchaseOperationOffer,
    purchaseMaterialOffer,
  }) => {
    return (
      <Fragment>
        <RowCustom>
          <Col sm={12} md={6} lg={4}>
            <LabelCustom title={<FormattedMessage id="offer.item" />}>
              {item?.billOfMaterial?.inventory?.name ||
                item?.billOfMaterial?.name ||
                'No Item...'}
            </LabelCustom>
          </Col>
          <Col sm={6} md={6} lg={2}>
            <LabelCustom title={<FormattedMessage id="offer.estimatedDeliveryDate" />}>
              <DateEdit item={item} />
            </LabelCustom>
          </Col>
          <Col sm={6} md={4} lg={2}>
            <LabelCustom title={<FormattedMessage id="offer.qty" />}>
              {item?.qty} &nbsp;{' '}
              {item.billOfMaterial?.inventory?.units?.name ||
                item?.units?.name ||
                'No Units'}
            </LabelCustom>
          </Col>
          <Col sm={6} md={3} lg={2}>
            <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
              {(item.subcontractSalesOfferPrice?.unitPrice || 0) + ' ₺'}
            </LabelCustom>
          </Col>
          <Col sm={6} md={3} lg={2}>
            <LabelCustom title={<FormattedMessage id="offer.amount" />}>
              {(item.subcontractSalesOfferPrice?.amount || 0) + ' ₺'}
            </LabelCustom>
          </Col>
          <ColButton xs>
            <Button
              type="button"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
              onClick={() => {
                itemId ? setItemId(null) : setItemId(item.id);
              }}
            >
              <FontAwesomeIcon icon={itemId ? faArrowAltUp : faArrowAltDown} size="1x" />
            </Button>
          </ColButton>
        </RowCustom>
        <CardBody style={item.id === itemId ? {} : { display: 'none' }}>
          <ContextSubcontractSalesOfferId.Provider
            value={{ subcontractSalesOfferId: item.id }}
          >
            <ItemDetail
              subcontractSalesOfferPrice={item.subcontractSalesOfferPrice}
              billOfMaterial={item.billOfMaterial}
              transportUnitPrice={transportUnitPrice}
              purchaseOperationOffer={purchaseOperationOffer}
              purchaseMaterialOffer={purchaseMaterialOffer}
              orderQty={item?.qty}
            />
          </ContextSubcontractSalesOfferId.Provider>
        </CardBody>
      </Fragment>
    );
  }
);
export default ListItemSuncontractSales;
interface DateEditProps {
  item: SubcontractSalesOffer;
}
const DateEdit: FunctionComponent<DateEditProps> = ({ item }) => {
  const { showToaster } = useToaster();
  const { control, errors, handleSubmit, setValue } = useForm();
  const [dateEdit, setDateEdit] = useState(false);
  const [updateInlineSubContract] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER);
  const onSubmit = ({ estimatedDeliveryDate }) => {
    updateInlineSubContract({
      variables: {
        id: item.id,
        subcontractSalesOffer: {
          estimatedDeliveryDate: estimatedDeliveryDate ? estimatedDeliveryDate[0] : '',
          qty: item.qty,
          billOfMaterialId: item?.billOfMaterial?.id,
          unitsId: item?.units.id,
        },
      },
    }).then(() => {
      showToaster('updated', 'positive');
    });
  };
  useEffect(() => {
    console.log('---->', item?.estimatedDeliveryDate);
    setValue(
      'estimatedDeliveryDate',
      item?.estimatedDeliveryDate ? [new Date(item?.estimatedDeliveryDate)] : undefined
    );
  }, [dateEdit, setValue, item]);
  return (
    <Fragment>
      {!dateEdit ? (
        <div className="flex justify-between">
          <span className="inline-block">
            {item?.estimatedDeliveryDate
              ? handleLocalDateString(item?.estimatedDeliveryDate)
              : ''}
          </span>
          <span className="inline-block">
            <Button
              type="button"
              onClick={() => {
                setDateEdit(true);
              }}
            >
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Button>
          </span>
        </div>
      ) : (
        <Form onSubmit={handleSubmit(onSubmit)} style={{ padding: 0, margin: 0 }}>
          <div className="shadow p-0.5">
            <DateCustom
              control={control}
              errors={errors}
              name="estimatedDeliveryDate"
              dateValue={item?.estimatedDeliveryDate}
              formLabel={false}
            />
            <div className="flex justify-end">
              <Button
                type="button"
                onClick={() => {
                  setDateEdit(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} size="1x" />
              </Button>
              <Button type="submit">
                <FontAwesomeIcon icon={faCheck} size="1x" />
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Fragment>
  );
};
