import React, { Fragment, FunctionComponent, memo, useMemo, useState } from 'react';
import { SubcontractSalesOffer } from '../../../../../types/SubcontractSalesOffer';
import AddMaterialCol, { MaterialIds } from './AddCol/AddMaterialCol';
import AddOperationCol, { OperationIds } from './AddCol/AddOperationCol';
import ListIteSubcontractSalesPreview from './ListItemSubcontractSalesPreview';
import SubcontractSalesHeaders from './SubcontractSalesHeaders';

interface ListSubcontractSalesPreviewProps {
  items: SubcontractSalesOffer[];
}
const ListSubcontractSalesPreview: FunctionComponent<ListSubcontractSalesPreviewProps> = ({
  items,
}) => {
  const [{ addOperatinCol, addMaterialCol }, setAddCol] = useState<AddColType>({
    addOperatinCol: false,
    addMaterialCol: false,
  });
  const [colOperationTitle, setColOperationTitle] = useState(null);
  const [colMaterialTitle, setColMaterialTitle] = useState(null);
  const _colOperationTitle = useMemo(() => colOperationTitle, [colOperationTitle]);
  const _colMaterialTitle = useMemo(() => colMaterialTitle, [colMaterialTitle]);
  const [colOperations, setColOperations] = useState<OperationIds>([]);
  const [colMaterials, setColMaterials] = useState<MaterialIds>([]);
  const operationItems = () => _operations(items, colOperations);
  const materialItems = () => _materials(items, colMaterials);
  return (
    <Fragment>
      {addOperatinCol && (
        <AddOperationCol
          items={operationItems()}
          setCols={setColOperations}
          setClose={setAddCol}
        />
      )}
      {addMaterialCol && (
        <AddMaterialCol
          items={materialItems()}
          setCols={setColMaterials}
          setClose={setAddCol}
        />
      )}
      <SubcontractSalesHeaders
        colMaterialTitle={_colMaterialTitle}
        colOperationTitle={_colOperationTitle}
        setAddCol={setAddCol}
      />
      {items.map((item: SubcontractSalesOffer) => {
        return (
          <ListIteSubcontractSalesPreview
            key={item?.id}
            item={item}
            setColOperationTitle={setColOperationTitle}
            setColMaterialTitle={setColMaterialTitle}
            colOperations={colOperations}
            colMaterials={colMaterials}
            setColOperations={setColOperations}
            setColMaterials={setColMaterials}
          />
        );
      })}
    </Fragment>
  );
};
export default memo(ListSubcontractSalesPreview);

export type AddColType = { addOperatinCol: boolean; addMaterialCol: boolean };

const _operations = (items: SubcontractSalesOffer[], cols: OperationIds) => {
  const operationIds = cols.map((item) => item.operationIds.map((itm) => itm.id)).flat(1);
  return items
    .map((item) =>
      item.billOfMaterial.executionPlan.executionPlanStep.map((itm) =>
        operationIds.includes(itm.id)
          ? null
          : { id: itm.id, name: itm.operation.name, operationId: itm.operation.id }
      )
    )
    .flat(1)
    .filter((_null) => _null !== null)
    .filter(
      (value, index, self) =>
        self.findIndex((i) => i.operationId === value.operationId) === index
    );
};
const _materials = (items: SubcontractSalesOffer[], cols: MaterialIds) => {
  const materialIds = cols.map((item) => item.materialIds.map((itm) => itm.id)).flat(1);
  return items
    .map((item) =>
      item.billOfMaterial.billOfMaterialItem.map((itm) =>
        materialIds.includes(itm.id) ? null : { id: itm.id, name: itm.inventory.name,inventoryId:itm.inventory.id }
      )
    )
    .flat(1)
    .filter((_null) => _null !== null).filter(
      (value, index, self) =>
        self.findIndex((i) => i.inventoryId === value.inventoryId) === index
    );;
};
