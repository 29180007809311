import React, { FunctionComponent, useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import {
  Card,
  CardBody,
  CardTitle,
} from '../../../components/FormWithElemets/Card.style';
import CompanyInformation from '../../Functions/CompanyInformation';
import { ComponentStatusContext } from '../Offer';
import ListOperationSales from './OperationSales/ListOperationSales';
import ListProductSales from './ProductSales/ListProductSales';
import ListSubcontractSalesItem from './SubcontractSales/ListSubcontractSales';

const InReview: FunctionComponent = () => {
  const { componentStatus, dispatchComponentStatus } = useContext(ComponentStatusContext);
  const {
    id,
    subcontractSalesOffer,
    productSalesOffer,
    operationSalesOffer,
  } = componentStatus?.data;
  const currentSalesOffer = subcontractSalesOffer?.length
    ? 'update_subContract'
    : productSalesOffer?.length
      ? 'update_product'
      : operationSalesOffer?.length && 'update_operation';
  return (
    <Card>
      <CardTitle title={<FormattedMessage id={`offer.${currentSalesOffer}`} />}>
        <Button
          onClick={() => {
            dispatchComponentStatus({ type: 'LIST' });
          }}
        >
          <FormattedMessage id="button.back" />
        </Button>
      </CardTitle>
      <CardBody>
        <Row>
          <Col>
            <CompanyInformation
              companyId={componentStatus?.data?.company?.id}
              companyName={componentStatus?.data?.company?.companyName}
            />
          </Col>
        </Row>
      </CardBody>
      <CardBody>
        <div style={{ marginTop: '50px' }} />
        {currentSalesOffer === 'update_subContract' && (
          <ListSubcontractSalesItem
            offerId={id}
          />
        )}
        {currentSalesOffer === 'update_product' && (
          <ListProductSales
            offerId={id}
          />
        )}
        {currentSalesOffer === 'update_operation' && (
          <ListOperationSales
            offerId={id}
          />
        )}
      </CardBody>
    </Card>
  );
};
export default InReview;
