import React, { Fragment, FunctionComponent, useContext, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { OfferStatuses } from '../../../../types/OfferStatus';
import SelectCanceledOrDeclinedReason from '../../../TailWinduiComponent/Select/SelectCanceledOrDeclinedReason';
import Button from '../../../TailWinduiHTML/Form/Button';
import Form from '../../../TailWinduiHTML/Form/Form';
import ErrorLabel from '../../../TailWinduiHTML/Form/Input/ErrorLabel';
import Label from '../../../TailWinduiHTML/Form/Input/Label';
import Modal from '../../../TailWinduiHTML/Modal/Modal';
import ModalBody from '../../../TailWinduiHTML/Modal/ModalBody';
import ModalFooter from '../../../TailWinduiHTML/Modal/ModalFooter';
import { ComponentStatusContext } from '../../Offer';
import useOfferStatusChange from './useOfferStatusChange';
const GET_STATUSES = loader('../../../../graphql/offer/GET_STATUSES.graphql');

interface OfferStatusChangeProps {
  offerId: string;
}
const OfferStatusChange: FunctionComponent<OfferStatusChangeProps> = ({ offerId }) => {
  const intl = useIntl();
  const { data, loading, error } = useQuery<OfferStatuses>(GET_STATUSES);
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const { control, handleSubmit, errors } = useForm();
  const [{ offerStageId, offerStatusId, modalOpen }, setState] = useState({
    offerStageId: null,
    offerStatusId: null,
    modalOpen: false,
  });
  const { onSubmit: _onSubmit } = useOfferStatusChange();
  const onSubmit = ({ canceledOrDeclinedReason }) => {
    _onSubmit({
      offerId: offerId,
      offerStageId: offerStageId,
      offerStatusId: offerStatusId,
      canceledOrDeclinedReasonId: canceledOrDeclinedReason
        ? canceledOrDeclinedReason[0].value
        : null,
      offerApproved: false,
    });
    dispatchComponentStatus({
      type: 'LIST',
    });
  };

  return (
    <Fragment>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <span>Error...</span>
      ) : (
        data &&
        data.offerStatuses.map((item) => {
          return (
            <Fragment key={item?.id}>
              {item.code === 'OFFER_CANCELED' && (
                <Button
                  color="red"
                  onClick={() =>
                    setState({
                      offerStageId: item?.offerStage?.id,
                      offerStatusId: item?.id,
                      modalOpen: true,
                    })
                  }
                >
                  {intl.formatMessage({ id: 'offer.OFFER_CANCELED' })}
                </Button>
              )}
              {item.code === 'OFFER_DECLINED' && (
                <Button
                  color="red"
                  onClick={() =>
                    setState({
                      offerStageId: item?.offerStage?.id,
                      offerStatusId: item?.id,
                      modalOpen: true,
                    })
                  }
                >
                  {intl.formatMessage({ id: 'offer.OFFER_DECLINED' })}
                </Button>
              )}
              {item.code === 'OFFER_APPROVED' && (
                <Button
                  color="green"
                  onClick={() => {
                    _onSubmit({
                      offerId: offerId,
                      offerStageId: item?.offerStage?.id,
                      offerStatusId: null,
                      canceledOrDeclinedReasonId: null,
                      offerApproved: true,
                    });
                    dispatchComponentStatus({
                      type: 'LIST',
                    });
                  }}
                >
                  {intl.formatMessage({ id: 'offer.OFFER_APPROVED' })}
                </Button>
              )}
            </Fragment>
          );
        })
      )}
      <Modal hidden={!modalOpen}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ModalBody>
            <Label name="canceledOrDeclinedReason">
              {intl.formatMessage({ id: 'select.canceledordeclinedreason' })}
            </Label>
            <SelectCanceledOrDeclinedReason control={control} required={true} />
            <ErrorLabel>
              {errors?.canceledOrDeclinedReason
                ? 'Please select a valid Canceled Or Declined Reason'
                : null}
            </ErrorLabel>
          </ModalBody>
          <ModalFooter>
            <Button
              type="button"
              onClick={() =>
                setState({
                  offerStageId: null,
                  offerStatusId: null,
                  modalOpen: false,
                })
              }
            >
              {intl.formatMessage({ id: 'button.close' })}
            </Button>
            <Button type="submit">{intl.formatMessage({ id: 'button.save' })}</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </Fragment>
  );
};
export default OfferStatusChange;
