import React, { Component } from 'react';
import { OfferTransport } from '../../../../../types/OfferAndDetailsWithOperation';
import { ReportStyle } from '../../../../Waybill/Waybill.style';
import ListSuncontractSales from '../ListSuncontractSales';

type PreviewShowProps = {
  offerId: string;
  offerTransport: OfferTransport[];
  transportIncluded: boolean;
  preview: boolean;
};
class PreviewShowPrint extends Component<PreviewShowProps, {}> {
  render() {
    const { offerId, offerTransport, transportIncluded, preview } = this.props;
    return (
      <>
        <ReportStyle />
        <ListSuncontractSales
            offerId={offerId}
            offerTransport={offerTransport}
            transportIncluded={transportIncluded}
            preview={preview}
          />
      </>
    );
  }
}
export default PreviewShowPrint;
