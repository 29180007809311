// @ts-nocheck
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { styled } from 'baseui';
import { Col as Cols } from 'react-flexbox-grid';
import Button from '../../components/FormWithElemets/Button/Button';

export const CreateButton = styled(Button, () => ({
  float: 'right',
}));

export const RowTitle = styled('div', () => ({
  marginRight: 0,
  marginLeft: 0,
  marginBottom: '3px',
  padding: 0,
  '@media all and (max-width: 768px)': {
    display: 'none',
  },
}));
export const Box = styled('div', () => ({
  margin: 0,
  padding: 0,
  overflow: 'hidden',
  transition: 'max-height .8s ease-out',
}));

export const Card = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
  marginBottom: '10px',
  padding: '30px',
  '@media all and (max-width: 800px)': {
    padding: '5px',
  },
}));

//****************-Yeni-******************//
type LabelWithContentProps = {
  children: React.ReactNode;
  style?: any;
  title?: any;
};
const TitleC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  '@media all and (min-width: 769px)': {
    display: 'none !important',
  },
}));
const TContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  textAlign: 'right',
  margin: 0,
  padding: '1px',
  flex: '1',
  '@media all and (min-width: 769px)': {
    ...$theme.typography.font18,
    color: $theme.colors.textDark,
    margin: '0 !important',
    padding: '1px !important',
    textAlign: 'left !important',
    flex: '1',
  },
}));
export const TitleContent: React.FC<LabelWithContentProps> = ({
  children,
  title,
  style,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <TitleC>{title}</TitleC>
      <TContent>{children}</TContent>
    </div>
  );
};
const TitleCC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'inline-block',
  margin: 0,
  width: '100%',
  padding: '1px',
  textAlign: 'center',
  '@media all and (min-width: 769px)': {
    display: 'none !important',
  },
}));

const TTContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  display: 'inline-block',
  width: '100%',
  margin: 0,
  padding: '1px',
  textAlign: 'center',
  '@media all and (min-width: 769px)': {
    ...$theme.typography.font18,
    color: $theme.colors.textDark,
    margin: '0 !important',
    padding: '1px !important',
    textAlign: 'left !important',
    flex: '1',
  },
}));
export const TitleCOntent: React.FC<LabelWithContentProps> = ({
  children,
  title,
  style,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <TitleCC>{title}</TitleCC>
      <TTContent>{children}</TTContent>
    </div>
  );
};
///*********************************///
const LabelC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
}));

export const LContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  textAlign: 'right',
  margin: 0,
  padding: '1px',
  flex: '1',
}));
// export const LabelContent: React.FC<LabelWithContentProps> = ({
//   children,
//   title,
// }) => {
//   return (
//     <div
//       style={{
//         width: '100%',
//         display: 'flex',
//         alignItems: 'center',
//         flexWrap: 'wrap',
//         justifyContent: 'center',
//         marginBottom: '6px',
//         marginTop: '6px',
//       }}
//     >
//       <LabelC>{title}</LabelC>
//       <LContent>{children}</LContent>
//     </div>
//   );
// };
export const LabelContent: React.FC<LabelWithContentProps> = ({ children, title }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '6px',
        marginTop: '6px',
      }}
    >
      <LabelC style={{ flex: '0 0 130px', paddingTop: '5px' }}>{title}</LabelC>
      <LContent style={{ flex: 1 }}>{children}</LContent>
    </div>
  );
};

export const ReportStyle = createGlobalStyle`
  @page {
    font-family: Arial,serif;
    font-size: 10px;
    size: A4;
    margin: 150pt 34pt 34pt 34pt;
    }
    @media screen
    {
        #printOnly{display:none;}
    }
    
    @media print
    {
        #printOnly{}
    }
`;
export const InvoiceLeftCol = styled(Cols, () => ({
  fontFamily: 'Arial',
  fontSize: '14px',
  padding: '0 30px',
  marginTop: '45px',
}));

export const P = styled(Cols, () => ({
  fontFamily: 'Arial',
  fontSize: '14px',
  marginBlockStart: '0.5em !important',
  marginBlockEnd: '0.5em !important',
}));

export const InvoiceRightCol = styled(Cols, () => ({
  fontFamily: 'Arial',
  fontSize: '12px',
  paddingLeft: '50px !important',
}));
export const TaxOfficeAndNumber = styled('p', () => ({
  fontFamily: 'Arial',
  fontSize: '14px',
  marginLeft: '100px',
  marginTop: '30px',
}));
export const DataP = styled('p', () => ({
  fontFamily: 'Arial',
  fontSize: '14px',
  marginBlockStart: '0.5em',
  marginBlockEnd: '0.5em',
}));
export const BlockWithMargin = styled('div', () => ({
  marginTop: '50px',
}));
export const GlobalStyle = styled('div', () => ({
  '@font-face': {
    fontWeight: 'normal',
    fontStyle: 'normal',
    fontDisplay: 'fallback',
  },
}));

export const SectionWrapper = styled('section', () => ({
  '@media print': {
    pageBreakAfter: 'always',
  },
}));

//
// export const TopEmptyBox = styled('div', ({ $theme }) => ({
//   ...$theme.typography.font18,
//   color: $theme.colors.textDark,
//   display: 'inline-block',
//   width: '100%',
//   margin: 0,
//   padding: '1px',
//   textAlign: 'center',
//   '@media all and (min-width: 769px)': {
//     ...$theme.typography.font18,
//     color: $theme.colors.textDark,
//     margin: '0 !important',
//     padding: '1px !important',
//     textAlign: 'left !important',
//     flex: '1',
//   },
// }));
