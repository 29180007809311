import React, {  FunctionComponent } from 'react';
import { ColumnButton } from './DataList';
import  { KIND, SIZE } from '../FormWithElemets/Button/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisHAlt } from '@fortawesome/pro-regular-svg-icons';
import Button from '../FormWithElemets/Button/Button';
import { DropdownButtonNew } from '../FormWithElemets/Button/DropdownButtonNew';

interface ListProps {
  buttons: ColumnButton[];
  threeDot: boolean;
  item: any;
}
export const ColumnButtons: FunctionComponent<ListProps> = ({
  threeDot,
  item,
  buttons,
}) => {
  const buttonTemps = buttons.map((button: ColumnButton) => {
    return Object.assign(button, { id: item.id });
  });
  return (
    <>
      {threeDot ? (
        <DropdownButtonNew
          items={buttonTemps}
          kind={KIND.secondary}
          size={SIZE.mini}
          data={item}
        >
          <FontAwesomeIcon icon={faEllipsisHAlt} size="1x" />
        </DropdownButtonNew>
      ) : (
        buttons.map((button: ColumnButton, key: any) => {
          return (
            <Button
              type="button"
              key={key}
              onClick={() => {
                button.check_type === 'UPDATE'
                  ? button.function(item)
                  : button.function(item?.id);
                if (button.close) {
                  button.close();
                }
              }}
            >
              {button.label}
            </Button>
          );
        })
      )}
    </>
  );
};
