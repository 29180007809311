import { useQuery } from '@apollo/react-hooks';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useState,
} from 'react';
import { CardBody } from '../../../components/FormWithElemets/Card.style';
import NoResult from '../../../components/NoResult/NoResult';
import { OfferTransport } from '../../../types/OfferAndDetailsWithOperation';
import { OperationSalesOffer, OperationSalesOfferIDs } from '../../../types/OperationSalesOffer';
import OperationSalesResult from '../InOfferStage/OperationSales/OperationSalesResult';
import ListOperationSalesPreview from '../InOfferStage/OperationSales/Preview/ListOperationSalesPreview';

const GET_OPERATION_SALES_OFFER = loader(
  '../../../graphql/offer/operationSales/GET_OPERATION_SALES_OFFER.graphql'
);
const S_GET_OPERATION_SALES_OFFER = loader(
  '../../../graphql/offer/operationSales/S_GET_OPERATION_SALES_OFFER.graphql'
);

interface OperationSalesProps {
  offerId: string;
}
const OperationSales: FunctionComponent<OperationSalesProps> = ({
  offerId,
}) => {
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<OperationSalesOfferIDs>(GET_OPERATION_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_OPERATION_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.operationSalesOffer;
        return Object.assign({}, prev, {
          operationSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const [transportUnitPrice, setTransportUnitPrice] = useState(0);
  useEffect(() => {
    if (data) {
      const {
        operationSalesOffer: { operationSalesOffer },
      } = data;
      const _transportUnitPrice = handleTransportUnitPrice(
        data.operationSalesOffer.transportIncluded,
        data.operationSalesOffer.offerTransport,
        operationSalesOffer
      );
      setTransportUnitPrice(_transportUnitPrice);
    }
  }, [data]);
  return (
    <Fragment>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
            data && (
              <Fragment>
                <CardBody>
                  <ListOperationSalesPreview items={data?.operationSalesOffer?.operationSalesOffer} transportUnitPrice={transportUnitPrice} />
                </CardBody>
                <OperationSalesResult
                  offerTransport={data.operationSalesOffer?.offerTransport}
                  items={data?.operationSalesOffer?.operationSalesOffer}
                  transportIncluded={data.operationSalesOffer?.transportIncluded}
                />
              </Fragment>
            )
          )}
    </Fragment>
  );
};

export default OperationSales;
const handleTransportUnitPrice = (
  transportIncluded: boolean,
  offerTransport: OfferTransport[],
  items: OperationSalesOffer[]
) => {
  const offerTransportTotalPrice = transportIncluded
    ? offerTransport.reduce(
      (acc: number, item: OfferTransport) =>
        item.approved === true ? acc + item.price : 0,
      0
    )
    : 0;
  const orderQty =
    items.reduce(
      (acc: number, item: OperationSalesOffer) => acc + (item?.qty || 0),
      0
    ) || 1;
  const transportUnitPrice =
    Math.ceil(100 * (offerTransportTotalPrice / orderQty)) / 100;
  return transportUnitPrice;
};
