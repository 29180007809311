import React, { Component } from 'react';
import { Company } from '../../../../../types/Company';
import { PurchaseMaterialOffer } from '../../../../../types/PurchaseMaterialOffer';
import { PurchaseOperationOffer } from '../../../../../types/PurchaseOperationOffer';
import ListPurchaseOffer from './ListPurchaseOffer';
interface ListPurhaseOfferProps {
    itemMaterials: PurchaseMaterialOffer[];
    itemOperations: PurchaseOperationOffer[];
    company: Company;
    print:boolean;
    setPrint:Function
    componentRef:any
  }
class ListPurchaseOfferClass extends Component<ListPurhaseOfferProps> {
    render() {
      const {company,itemOperations,itemMaterials,setPrint,print,componentRef} = this.props
    return (
        <ListPurchaseOffer componentRef={componentRef} company={company} itemMaterials={itemMaterials} itemOperations={itemOperations} setPrint={setPrint} print={print}/>
    );
  }
}
export default ListPurchaseOfferClass;
