import React, { FunctionComponent, useEffect, useState } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { SelectBase } from '../../components/FormWithElemets/Select/SelectBase';
import { BillOfMaterials } from '../../types/BillOfMaterial';

const GET_BILL_OF_MATERIALS = loader(
  '../../graphql/billOfMaterial/GET_BILL_OF_MATERIALS.graphql'
);
const S_GET_BILL_OF_MATERIALS = loader(
  '../../graphql/billOfMaterial/S_GET_BILL_OF_MATERIALS.graphql'
);
interface ListProps {
  errors?: any;
  control: any;
  creatable?: boolean;
  setSubcontractSalesStatus: Function;
  setUnit: Function;
}
const SelectBillOfMaterial: FunctionComponent<ListProps> = ({
  errors,
  control,
  creatable = false,
  setSubcontractSalesStatus,
  setUnit,
}) => {
  const { subscribeToMore, data } = useQuery<BillOfMaterials>(GET_BILL_OF_MATERIALS);
  const [dataNew, setDataNew] = useState([]);
  useEffect(() => {
    subscribeToMore({
      document: S_GET_BILL_OF_MATERIALS,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.billOfMaterials;
        return Object.assign({}, prev, {
          billOfMaterials: newFeedItem,
        });
      },
    });
  }, [subscribeToMore]);
  useEffect(() => {
    setDataNew(
      () =>
        (data &&
          data?.billOfMaterials.map((item) => {
            const labelTemp = item?.inventory?.name || item?.name || 'No Item';
            return {
              id: item.id,
              label: labelTemp,
            };
          })) ||
        []
    );
  }, [data]);
  const handleChange = ({ option, value }) => {
    if (option?.isCreatable) {
      setSubcontractSalesStatus({
        status: 'AddBillOfMaterial',
        billOfMaterialName: option?.label,
        billOfMaterialId: '',
        executionPlanningId: '',
      });
    } else if (data) {
      const billOfMaterialItem = data.billOfMaterials.find(
        (item) => item.id === value[0]?.id
      );
      if (billOfMaterialItem?.inventory) {
        setUnit([
          {
            id: billOfMaterialItem.inventory?.units?.id,
            label: billOfMaterialItem.inventory?.units?.name,
          },
        ]);
      } else {
        setUnit(undefined);
      }
    }
  };
  return (
    <FormControl
      label={<FormattedMessage id="select.billOfMaterial" />}
      error={
        errors && errors?.billOfMaterialId ? 'Please Select a valid Operation' : null
      }
    >
      <SelectBase
        data={dataNew}
        controller={{
          name: 'billOfMaterialId',
          type: TYPE.search,
          control,
          creatable: creatable,
          rules: { required: errors ? true : false },
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            handleChange(selected);
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectBillOfMaterial;
