import React, { useCallback, useEffect, FunctionComponent, useState } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { ipcRenderer } from '../../appRuntime';
import { shopFloorOrderImproper } from '../../barcode/Barcode';
import { useToaster } from '../../context/toaster-provider';
import SelectProductRecordReason from '../SelectCustom/SelectProductRecordReason';
import { CardTitle, Card, CardBody } from '../../components/FormWithElemets/Card.style';
import { handleDate, handleDateLocal } from '../Functions/Utilities';
import { ShopFloorOrderNextOperations } from '../../types/ShopFloorOrderNextOperation';
import SelectShift from '../SelectCustom/SelectShift';
import { Datepicker } from 'baseui/datepicker';
import tr from 'date-fns/locale/tr';

const ADD_PRODUCT_RECORD = loader(
  '../../graphql/productRecord/ADD_PRODUCT_RECORD.graphql'
);
const GET_SHOP_FLOOR_ORDER_NEXT_OPERATION = loader(
  '../../graphql/shopFloorOrder/GET_SHOP_FLOOR_ORDER_NEXT_OPERATION.graphql'
);
const ImproperProductRecordForm: FunctionComponent = () => {
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  return (
    <AddImproperProductRecordForm
      dispatch={dispatch}
      dataDispatch={dataDispatch}
      transactionsReasonDispatch={transactionsReasonDispatch}
    />
  );
};
export default ImproperProductRecordForm;

interface ListAddImproperProps {
  dispatch: any;
  dataDispatch: any;
  transactionsReasonDispatch: any;
}
export const AddImproperProductRecordForm: FunctionComponent<ListAddImproperProps> = ({
  dispatch,
  dataDispatch,
  transactionsReasonDispatch,
}) => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, control, setValue } = useForm();
  const [printerData, setPrinterData] = useState({
    wipBarcode: '',
    improperBarcode: '',
  });
  const [specificDate, setSpecificDate] = useState([new Date()]);
  const { data } = useQuery<ShopFloorOrderNextOperations>(
    GET_SHOP_FLOOR_ORDER_NEXT_OPERATION,
    {
      variables: {
        shopFloorOrderId: dataDispatch.shopFloorOrderId,
      },
    }
  );
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const onSubmit = ({ improper, productRecordReason, shiftId, specificDate }) => {
    addProductRecord({
      variables: {
        wip: 0,
        shiftId: shiftId && shiftId[0] ? shiftId[0]?.id : null,
        specificDate: specificDate ? handleDate(specificDate[0]) : '',
        improper: improper ? Number(improper) : 0,
        shopFloorOrderId: dataDispatch.shopFloorOrderId,
        productRecordReason: productRecordReason?.map((item: any) => {
          return {
            id: item.id,
          };
        }),
      },
    })
      .then(() => showToaster('created', 'positive'))
      .catch((error) => {
        console.log('error', error); // <-- OK
        showToaster(error, 'warning', false);
      });
  };
  const [addProductRecord] = useMutation(ADD_PRODUCT_RECORD, {
    update(cache, { data: { createProductRecord } }) {
      // handleAddProductRecord(
      //   cache,
      //   dataDispatch.shopFloorId,
      //   dataDispatch.shopFloorOrderId,
      //   dataDispatch.machineryAndEquipmentId,
      //   createProductRecord
      // );
      ipcRenderer &&
        setPrinterData(() => {
          return {
            wipBarcode: '',
            improperBarcode:
              createProductRecord.improper > 0
                ? shopFloorOrderImproper({
                    nextOperation: data?.shopFloorOrderNextOperation?.name || '-',
                    productBarcodeNo: createProductRecord?.barcode?.toUpperCase(),
                    product: dataDispatch.product,
                    qtyOrders: dataDispatch.shopFloorOrderQty,
                    qtyImproper: createProductRecord?.improper || 0,
                    user:
                      createProductRecord?.user?.name +
                      ' ' +
                      createProductRecord?.user?.surname,
                    shift: createProductRecord?.shift?.name || '',
                    processingTime: handleDateLocal(createProductRecord?.insertedAt),
                    companyShortName:
                      createProductRecord?.shopFloorOrder?.orderDetail?.order?.company
                        ?.companyShortName || '',
                    productionOrderNo: createProductRecord?.shopFloorOrder?.serial || '',
                    orderNo:
                      createProductRecord?.shopFloorOrder?.orderDetail?.order?.serial ||
                      '',
                    completeOperation:
                      createProductRecord?.productOperation?.operation?.name || '--',
                    productRecordsReason:
                      createProductRecord?.productRecordsReasonPivot?.reduce(
                        (acc: string, item: any) =>
                          acc + ' * ' + item?.productRecordsReason?.name,
                        ' '
                      ) || '...',
                  })
                : '',
          };
        });
      transactionsReasonDispatch === 'shopFloorOrderCompleted'
        ? handleFormReset()
        : closeDrawer();
    },
  });
  const handleFormReset = () => {
    setValue('improper', '');
    setValue('productRecordReason', undefined);
  };
  useEffect(() => {
    if (ipcRenderer) {
      printerData.improperBarcode !== '' &&
        ipcRenderer.send('print-barcode', {
          data: printerData.improperBarcode,
          printer: localStorage.getItem('barcode'),
        });
    }
  }, [printerData]);
  return (
    <Card>
      <CardTitle
        title={<FormattedMessage id={'product_record_improper.add'} />}
      ></CardTitle>
      <CardBody style={{ padding: '5px' }}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            margin: 0,
            padding: 0,
            height: '100%',
            backgroundColor: 'transparent',
          }}
        >
          <Row style={{ margin: 0, padding: 0 }}>
            <Col sm={12}>
              <FormControl
                label={<FormattedMessage id="product_record.improper" />}
                error={errors.improper ? 'Please input a valid Improper' : null}
              >
                <Input
                  size={SIZE.compact}
                  name="improper"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.improper}
                  overrides={errors.improper ? { After: Negative } : { After: Positive }}
                />
              </FormControl>
            </Col>
            <Col sm={12}>
              <SelectProductRecordReason errors={errors} control={control} />
            </Col>
            {transactionsReasonDispatch === 'planingManagement' && (
              <>
                <Col sm={12}>
                  <SelectShift errors={errors} control={control} />
                </Col>
                <Col sm={12}>
                  <FormControl
                    label={<FormattedMessage id="product_record.specificDate" />}
                    error={
                      errors.specificDate ? 'Please input a valid Specific Date' : null
                    }
                  >
                    <Controller
                      as={
                        <Datepicker
                          size={SIZE.compact}
                          locale={tr}
                          mask={null}
                          value={specificDate}
                          formatString="d/MM/yyyy"
                        />
                      }
                      control={control}
                      name="specificDate"
                      rules={{ required: true }}
                      valueName="selected"
                      onChange={([selected]) => {
                        setSpecificDate(selected.date);
                        return selected ? [selected.date] : undefined;
                      }}
                      error={errors.specificDate}
                      overrides={
                        errors.specificDate ? { After: Negative } : { After: Positive }
                      }
                    />
                  </FormControl>
                </Col>
              </>
            )}
            <Col md={12}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'flex-end',
                }}
              >
                {!(transactionsReasonDispatch === 'shopFloorOrderCompleted') && (
                  <Button type="button" onClick={closeDrawer}>
                    <FormattedMessage id="button.close" />
                  </Button>
                )}
                <Button type="submit">
                  <FormattedMessage id={'button.save'} />
                </Button>
              </div>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};
