// @ts-nocheck
import React, { createContext, useState, useContext } from 'react';
import useComponentSize from '../../settings/useComponentSize';
import Topbar from './Topbar/Topbar';
import TopBarNew from './Topbar/TopBarNew';
import DrawerItems from '../DrawerItems/DrawerItems';
import { DrawerProvider } from '../../context/DrawerContext';
import { LayoutWrapper, ContentWrapper, ContentInnerWrapper } from './Layout.style';
import { NewSidebar } from './NewSidebar/NewSidebar';
import { createContextualCan } from '@casl/react';
import { Ability } from '@casl/ability';
import { AuthContext } from '../../context/auth';

export const AbilityContext = createContext<Ability>();
export const Can = createContextualCan(AbilityContext.Consumer);

const AdminLayout = ({ children }: never) => {
  const { user, permissions } = useContext(AuthContext);
  const [topbarRef, { height }] = useComponentSize();
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const userCache = JSON.parse(localStorage.getItem('user'));
  const ability = new Ability(
    permissions === [] && permissions.length > 0
      ? permissions
      : JSON.parse(localStorage.getItem('user_roles'))
  );
  const handleCollapsedChange = (checked: any) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value: any) => {
    setToggled(value);
  };
  return permissions ? (
    <AbilityContext.Provider value={ability}>
      <DrawerProvider>
        <Topbar
          refs={topbarRef}
          toggled={toggled}
          handleToggleSidebar={handleToggleSidebar}
          profileData={user ? user : userCache}
        />
        {/*<TopBarNew />*/}
        <LayoutWrapper
          style={{
            height: `calc(100vh - ${height}px)`,
          }}
        >
          {
            <>
              <NewSidebar
                collapsed={collapsed}
                toggled={toggled}
                handleToggleSidebar={handleToggleSidebar}
                handleCollapsedChange={handleCollapsedChange}
              />
              <ContentWrapper>
                <ContentInnerWrapper>{children}</ContentInnerWrapper>
              </ContentWrapper>
            </>
          }
        </LayoutWrapper>
        <DrawerItems />
      </DrawerProvider>
    </AbilityContext.Provider>
  ) : null;
};

export default AdminLayout;
