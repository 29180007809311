import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, memo, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import Button, {
  COLOR,
} from '../../../../../components/FormWithElemets/Button/Button';
import { LabelCol } from '../../../../../components/FormWithElemets/Label.style';
import { ProductSalesOffer } from '../../../../../types/ProductSalesOffer';
import { handleCeil } from '../../../../Functions/Utilities';

interface ProductSalesOfferPriceItemProps {
  item: ProductSalesOffer;
  transportUnitPrice: number;
  setItemAdd: Function;
}
const ProductSalesOfferPriceItem: FunctionComponent<ProductSalesOfferPriceItemProps> = ({
  item,
  transportUnitPrice,
  setItemAdd,
}) => {
  const { qty, unitPrice, productSalesOfferPrice } = item;
  const [{ profitAmount, discountAmount, taxAmount }, setState] = useState({
    profitAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
  });
  useEffect(() => {
    if (productSalesOfferPrice) {
      const { profitRate, discountRate, taxRate } = productSalesOfferPrice;
      const _amount = handleCeil(qty * (unitPrice + transportUnitPrice))
      const _profitAmount = handleCeil((_amount * profitRate) / 100);
      const _discountAmount = handleCeil(((_amount + _profitAmount) * discountRate) /
        100);
      const _taxAmount = handleCeil(((_amount + _profitAmount - _discountAmount) * taxRate) /
        100);
      setState({
        profitAmount: _profitAmount,
        discountAmount: _discountAmount,
        taxAmount: _taxAmount,
      });
    }
  }, [
    productSalesOfferPrice,
    unitPrice,
    qty,
    transportUnitPrice,
  ]);
  return (
    <RowCustom>
      <Col sm={6} md={4} lg={2} style={{display:"none"}}>
        <LabelCol title={<FormattedMessage id="offerPrice.amountPrice" />}>
          {handleCeil(qty * (unitPrice + transportUnitPrice)) || '0'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.profitRate" />
              {' % ' + (productSalesOfferPrice?.profitRate || 0)}
            </>
          }
        >
          {profitAmount || '-'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.discountRate" />
              {' % ' + (productSalesOfferPrice?.discountRate || 0)}
            </>
          }
        >
          {discountAmount || '-'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.taxRate" />
              {' % ' + (productSalesOfferPrice?.taxRate || 0)}
            </>
          }
        >
          {taxAmount || '-'}
        </LabelCol>
      </Col>
      <ColButton xs>
        <Button onClick={() => setItemAdd(item)} color={COLOR.green}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};
export default memo(ProductSalesOfferPriceItem);
