import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, DivButtonTitle, RowTitle } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';

interface ItemHeaderProps {
  itemsLenght: number;
  setItemAddShow?: Function;
  itemAddShow?: boolean;
}
const ItemHeaders: FunctionComponent<ItemHeaderProps> = memo(
  ({ itemsLenght, setItemAddShow, itemAddShow }) => (
    <Fragment>
      <RowTitle>
        {itemsLenght > 0 && (
          <Fragment>
            <Col xs={4}>
              <FormattedMessage id="offer.item" />
            </Col>
            <Col xs={2}>
              <FormattedMessage id="offer.estimatedDeliveryDate" />
            </Col>
            <Col xs={4}>
              <FormattedMessage id="offer.qty" />
            </Col>
          </Fragment>
        )}
        {/* <Col xs={2}>{<FormattedMessage id="offer.unitPrice" />}</Col> */}
        {setItemAddShow && (
          <ColButton xs>
            <Button
              type="button"
              onClick={() => {
                setItemAddShow(!itemAddShow);
              }}
            >
              <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
            </Button>
          </ColButton>
        )}
      </RowTitle>
      {setItemAddShow && (
        <DivButtonTitle>
          <Button
            type="button"
            onClick={() => {
              setItemAddShow(!itemAddShow);
            }}
          >
            <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
          </Button>
        </DivButtonTitle>
      )}
    </Fragment>
  )
);
export default ItemHeaders;
