import { Breadcrumbs } from "baseui/breadcrumbs";
import { StyledLink } from "baseui/link";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";

interface BreadcrumbsCustomProps {
    componentStatus: string;
    serial?: number;
}
const BreadcrumbsCustom: FunctionComponent<BreadcrumbsCustomProps> = ({
    componentStatus,
    serial,
}) => {
    return (
        <Breadcrumbs>
            <StyledLink>
                <FormattedMessage id="menu.billOfMaterial" />
            </StyledLink>
            {componentStatus === 'ExecutionPlanning' ? (
                <StyledLink>
                    {'EX-' + (serial || '')}
                    <span
                        style={{
                            display: 'inline-block',
                            marginRight: '10px',
                        }}
                    />
                    <FormattedMessage id="billOfMaterial.executionPlanning" />
                </StyledLink>
            ) : componentStatus === 'BillOfMaterialItem' ? (
                <StyledLink>
                    <FormattedMessage id="billOfMaterial.item" />
                </StyledLink>
            ) : componentStatus === 'Images' && (
                <StyledLink>
                    <FormattedMessage id="billOfMaterial.images" />
                </StyledLink>
            )}
        </Breadcrumbs>
    );
};

export default BreadcrumbsCustom