import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, RowTitle } from '../../../../components/FlexBox/FlexBox';

interface HeadersPurchaseMaterialOfferProps {
  itemLength: number;
}
const HeadersPurchaseMaterialOffer: FunctionComponent<HeadersPurchaseMaterialOfferProps> = ({
  itemLength,
}) => {
  const intl = useIntl();

  return (
    itemLength && (
      <RowTitle>
        <Col xs={12} md={5} lg={4}>
          {intl.formatMessage({ id: 'inventory.inventoryId' })}
        </Col>
        <Col xs={6} md={3} lg={2}>
          {intl.formatMessage({ id: 'select.inventoryType' })}
        </Col>
        <Col xs={6} md={3} lg={2}>
          {intl.formatMessage({ id: 'date.estimatedDate' })}
        </Col>
        <Col xs={6} md={3} lg={2}>
          {intl.formatMessage({ id: 'purchaseOffer.qty' })}
        </Col>
      </RowTitle>
    )
  );
};
export default HeadersPurchaseMaterialOffer;
