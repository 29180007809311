import React, { FunctionComponent } from 'react';
interface ListProps {
  innerShape?: string | undefined;
}
export const Rectangle: FunctionComponent<ListProps> = ({
  innerShape = 'inner_full',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="75"
      height="50"
      viewBox="0 0 150 100"
    >
      <g id="Group_21" data-name="Group 21" transform="translate(-360)">
        <g
          id="Rectangle_16"
          data-name="Rectangle 16"
          transform="translate(360)"
          fill="none"
          stroke="#707070"
          strokeWidth="3"
        >
          <rect width="150" height="100" stroke="none" />
          <rect x="1.5" y="1.5" width="147" height="97" fill="none" />
        </g>
        {innerShape === 'inner_hexagonal' ? (
          <g id="Hexagonal_6" data-name="Hexagonal_6">
            <line
              id="Line_19"
              data-name="Line 19"
              x1="34.214"
              y2="20.168"
              transform="translate(398 9.407)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_20"
              data-name="Line 20"
              x1="34.214"
              y1="20.168"
              transform="translate(432.214 9.407)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_21"
              data-name="Line 21"
              y2="39.256"
              transform="translate(398 29.575)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_22"
              data-name="Line 22"
              x1="34.214"
              y1="20.168"
              transform="translate(398 68.832)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_23"
              data-name="Line 23"
              x1="34.214"
              y2="20.168"
              transform="translate(432.214 68.832)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_24"
              data-name="Line 24"
              x1="0.36"
              y2="39.256"
              transform="translate(466.068 29.575)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
          </g>
        ) : innerShape === 'inner_round' ? (
          <g id="Group_16" data-name="Group 16" transform="translate(282 -101)">
            <g
              id="Ellipse_2"
              data-name="Ellipse 2"
              transform="translate(110 110)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <circle cx="40" cy="40" r="40" stroke="none" />
              <circle cx="40" cy="40" r="38.5" fill="none" />
            </g>
          </g>
        ) : innerShape === 'inner_square' ? (
          <g
            id="Rectangle_19"
            data-name="Rectangle 19"
            transform="translate(402 20)"
            fill="none"
            stroke="#707070"
            strokeWidth="3"
          >
            <rect width="58" height="58" stroke="none" />
            <rect x="1.5" y="1.5" width="55" height="55" fill="none" />
          </g>
        ) : (
          innerShape === 'inner_rectangle' && (
            <g
              id="Rectangle_20"
              data-name="Rectangle 20"
              transform="translate(373 10)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <rect width="120" height="75" stroke="none" />
              <rect x="1.5" y="1.5" width="120" height="75" fill="none" />
            </g>
          )
        )}
      </g>
    </svg>
  );
};
