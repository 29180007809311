import React, {FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery} from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { Units } from '../../types/Units';


const GET_UNITS = loader(
  '../../graphql/unit/GET_UNITS.graphql'
);
interface ListProps {
    errors?: any;
    control: any;
    disabled?:boolean;
  }
const SelectUnit: FunctionComponent<ListProps> = ({
  errors,
  control,
  disabled=false
}) => {
  const { data } = useQuery<Units>(GET_UNITS);
  return (
    <FormControl
      label={<FormattedMessage id="select.unit" />}
      error={
        errors && errors?.unitsId ? 'Please Select a valid Unit' : null
      }
    >
      <Select
        data={data && data.units}
        controller={{
          name: 'unitsId',
          type: TYPE.search,
          disabled:disabled,
          control,
          rules: { required: errors ? true : false },
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectUnit