import React, { Fragment, FunctionComponent, useContext, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { CardTitle, CardBody } from '../../../components/FormWithElemets/Card.style';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Col, DivSubmit, Row } from '../../../components/FlexBox/FlexBox';
import { reducerFunction } from '../../Functions/Utilities';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../context/toaster-provider';
import { useCompanyAdd } from '../../Functions/useCompanyAdd';
import { Form } from '../../../components/FormWithElemets/Form.style';
import ListProductSalesItem from './ListProductSalesItem';
import { ComponentStatusContext } from '../Offer';
import SelectUser from '../../SelectCustom/SelectUser';
const ADD_PRODUCT_SALES_OFFER = loader(
  '../../../graphql/offer/productSales/ADD_PRODUCT_SALES_OFFER.graphql'
);
export const UseFormContext = React.createContext(null);
export const ItemsContext = React.createContext(null);
export const BillOfMaterialCreateContext = React.createContext(null);
type Product = {
  id: string;
  label: string;
};
type Unit = {
  id: string;
  label: string;
};
export type Item = {
  id: string | undefined;
  productId: Product[];
  unitsId: Unit[] | undefined;
  productRevisionId: string;
  estimatedDeliveryDate: string;
  qty: number;
  unitPrice: number;
};
type Action =
  | {
    type: 'ADD';
    item: Item;
  }
  | { type: 'DELETE'; index: number }
  | {
    type: 'UPDATE';
    item: Item;
    index: number;
  }
  | {
    type: 'RESET';
  };
const reducer = (state: Item[], action: Action) => {
  return reducerFunction(state, action);
};
const ProductSalesOfferAdd: FunctionComponent = () => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const { showToaster } = useToaster();
  const initialState = [];
  const [items, dispatchItems] = useReducer(reducer, initialState);
  const {
    register,
    setValue,
    control,
    errors,
    getValues,
    clearError,
    watch,
    handleSubmit,
  } = useForm();
  const { selectCompany } = useCompanyAdd({
    control,
    errors,
    setValue,
    dispatchItems,
    items,
    update: false,
    creatable: true,
  });
  const onSubmit = ({ companyName, productId, qty, userId }) => {
    if (items.length > 0) {
      if (productId === undefined && qty === '') {
        addProduct({
          variables: {
            companyId: companyName[0].id,
            assignedTo: userId ? userId[0]?.id : null,
            approved: false,
            transportIncluded: false,
            productSalesOffer: handleNewProductItems(items),
          },
        }).then(() => {
          showToaster('created', 'positive');
          dispatchComponentStatus({ type: 'LIST' });
        });
      } else {
        showToaster('item_did_not_add_or_remove', 'warning');
      }
    } else {
      showToaster('items_is_empty', 'warning');
    }
  };
  const [addProduct] = useMutation(ADD_PRODUCT_SALES_OFFER);
  console.log("companyName", watch()?.companyName)
  return (
    <Fragment>
      <CardTitle title={<FormattedMessage id={'productSalesOffer.add'} />}>
        <Button
          onClick={() => {
            dispatchComponentStatus({ type: 'LIST' });
          }}
        >
          <FormattedMessage id="button.back" />
        </Button>
      </CardTitle>
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <UseFormContext.Provider
            value={{ register, setValue, control, errors, getValues, clearError, watch }}
          >
            <Row style={{ marginBottom: '45px' }}>
              <Col md={7} lg={6} xs={12}>
                <ItemsContext.Provider value={{ items, dispatchItems }}>
                  {selectCompany}
                </ItemsContext.Provider>
              </Col>
              <Col md={5} lg={5} xs={12}>
                <SelectUser errors={errors} control={control} name="assignOfferToPerson" />
              </Col>
            </Row>
            <ItemsContext.Provider value={{ items, dispatchItems }}>
              <ListProductSalesItem />
            </ItemsContext.Provider>
          </UseFormContext.Provider>
          <DivSubmit style={{ margin: '40px' }}>
            <Button type="button" onClick={() => {
              dispatchComponentStatus({ type: 'LIST' });
            }}>
              <FormattedMessage id="button.close" />
            </Button>
            <Button type="submit">
              <FormattedMessage id={'button.create'} />
            </Button>
          </DivSubmit>
        </Form>
      </CardBody>
    </Fragment>
  );
};
export default ProductSalesOfferAdd;

const handleNewProductItems = (items: Item[]) => {
  return items.map((item) => {
    return {
      estimatedDeliveryDate: item.estimatedDeliveryDate,
      productId: item.productId[0].id,
      productRevisionId: item.productRevisionId,
      qty: +item.qty,
      unitPrice: +item.unitPrice,
    };
  });
};
