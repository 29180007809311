import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Select from '../../TailWinduiHTML/Form/Select';
import { useForm } from 'react-hook-form';
import { CanceledOrDeclinedReasons } from '../../../types/CanceledOrDeclinedReason';

const GET_CANCELED_OR_DECLINED_REASONS = loader(
    '../../../graphql/canceledOrDeclinedReason/GET_CANCELED_OR_DECLINED_REASONS.graphql'
  );
interface Props {
  control: ReturnType<typeof useForm>['control'];
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  required?:boolean;
}
const SelectCanceledOrDeclinedReason: FunctionComponent<Props> = ({
  control,
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
  isSearchable = false,
  required=false
}) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const { data } = useQuery<CanceledOrDeclinedReasons>(GET_CANCELED_OR_DECLINED_REASONS);
  useEffect(() => {
    if (data) {
      const _options = data?.canceledOrDeclinedReasons.map((item) => {
        return { value: item?.id, label: item?.name };
      });
      setOptions(_options);
    }
  }, [data]);
  const handleChange = ([selected]) => {
    return selected ? [selected] :undefined; 
  };
  const handleInputChange = (inputValue: any, actionMeta: any) => {
    //console.group('Input Changed');
    //console.log(inputValue);
    //console.log(`action: ${actionMeta?.action}`);
    //console.groupEnd();
  };
  return (
    <Select
      control={control}
      name={'canceledOrDeclinedReason'}
      options={options}
      required= {required }
      isDisabled={isDisabled}
      isLoading={isLoading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};
export default SelectCanceledOrDeclinedReason;
