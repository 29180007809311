import React, { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select/creatable';
interface CreatableSelectProps {
  options?: { value: string; label: string }[];
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  required?:boolean;
  className?:string;
  name:string
  control?: ReturnType<typeof useForm>['control'];
  handleChange?: (e: any) => void;
  handleInputChange?: (inputValue: any, actionMeta: any) => void;
}
const CreatableSelect: FunctionComponent<CreatableSelectProps> = ({
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
  isSearchable = false,
  options=[],
  required=false,
  className="",
  name,
  control,
  handleChange,
  handleInputChange,
}) => {
  return (
    <div
      className={
        'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md ' +
        className
      }
    >
      <Controller
        as={<Select />}
        control={control}
        name={name}
        options={options}
        rules={{required:required}}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        onChange={handleChange}
        onInputChange={handleInputChange}
      />
    </div>
  );
};
export default CreatableSelect;
