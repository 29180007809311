import { Input, SIZE } from 'baseui/input';
import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from '../../../../components/FlexBox/FlexBox';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { Negative, Positive } from '../../../../components/General/NegativePositive';
import { RawMaterialInventory } from '../../../../types/Inventory';
import { MaterialGeometry } from '../../../../types/MaterialGeometries';
import { inputDecimal } from '../../../Functions/Utilities';

interface SelectionMaterialGeometryTypeProps {
  materialGeometry: MaterialGeometry;
  errors: any;
  register: any;
  setValue: any;
  rawMaterialGeometry?: RawMaterialInventory;
}
const SelectionMaterialGeometryType: FunctionComponent<SelectionMaterialGeometryTypeProps> = ({
  materialGeometry,
  errors,
  register,
  setValue,
  rawMaterialGeometry,
}) => {
  const materialOuterInput = () => {
    const materialOuterShape = materialGeometry.materialOuterShape.name;
    return (
      <Fragment>
        <Col sm={6} md={4} lg={3}>
          <FormControl
            label={<FormattedMessage id={`calc_material.${materialOuterShape}_A`} />}
            error={
              errors.outerMeasurementA ? 'Please input a valid Outer Measurement 1' : null
            }
          >
            <Input
              type="text"
              size={SIZE.compact}
              name="outerMeasurementA"
              inputRef={register({
                required: true,
              })}
              disabled={!!rawMaterialGeometry?.outerMeasurementA}
              error={!!errors.outerMeasurementA}
              overrides={
                errors.outerMeasurementA ? { After: Negative } : { After: Positive }
              }
              onChange={(event) => {
                setValue('outerMeasurementA', inputDecimal({ event }));
              }}
              clearOnEscape
              endEnhancer="mm"
            />
          </FormControl>
        </Col>
        {(materialOuterShape === 'outer_rectangle' ||
          materialOuterShape === 'outer_sheet') && (
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id={`calc_material.${materialOuterShape}_B`} />}
              error={
                errors.outerMeasurementB
                  ? 'Please input a valid Outer Measurement 1'
                  : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="outerMeasurementB"
                inputRef={register({
                  required: true,
                })}
                disabled={!!rawMaterialGeometry?.outerMeasurementB}
                error={!!errors.outerMeasurementB}
                overrides={
                  errors.outerMeasurementB ? { After: Negative } : { After: Positive }
                }
                onChange={(event) => {
                  setValue('outerMeasurementB', inputDecimal({ event }));
                }}
                clearOnEscape
                endEnhancer="mm"
              />
            </FormControl>
          </Col>
        )}
      </Fragment>
    );
  };
  const materialInnerInput = () => {
    const materialInnerShape = materialGeometry.materialInnerShape.name;
    return (
      materialInnerShape !== 'inner_full' && (
        <Fragment>
          <Col sm={6} md={4} lg={3}>
            <FormControl
              label={<FormattedMessage id={`calc_material.${materialInnerShape}_A`} />}
              error={
                errors.innerMeasurementA
                  ? 'Please input a valid Inner Measurement 1'
                  : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="innerMeasurementA"
                inputRef={register({
                  required: true,
                })}
                disabled={!!rawMaterialGeometry?.innerMeasurementA}
                error={!!errors.innerMeasurementA}
                overrides={
                  errors.innerMeasurementA ? { After: Negative } : { After: Positive }
                }
                onChange={(event) => {
                  setValue('innerMeasurementA', inputDecimal({ event }));
                }}
                clearOnEscape
                endEnhancer="mm"
              />
            </FormControl>
          </Col>
          {materialInnerShape === 'inner_rectangle' && (
            <Col sm={6} md={4} lg={3}>
              {/* + */}
              <FormControl
                label={<FormattedMessage id={`calc_material.${materialInnerShape}_B`} />}
                error={
                  errors.innerMeasurementB
                    ? 'Please input a valid Inner Measurement 1'
                    : null
                }
              >
                <Input
                  type="text"
                  size={SIZE.compact}
                  name="innerMeasurementB"
                  inputRef={register({
                    required: true,
                  })}
                  disabled={!!rawMaterialGeometry?.innerMeasurementB}
                  error={!!errors.innerMeasurementB}
                  overrides={
                    errors.innerMeasurementB ? { After: Negative } : { After: Positive }
                  }
                  onChange={(event) => {
                    setValue('innerMeasurementB', inputDecimal({ event }));
                  }}
                  clearOnEscape
                  endEnhancer="mm"
                />
              </FormControl>
            </Col>
          )}
        </Fragment>
      )
    );
  };
  return (
    <Fragment>
      {materialOuterInput()}
      {materialInnerInput()}
    </Fragment>
  );
};
export default SelectionMaterialGeometryType;
