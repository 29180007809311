import { loader } from 'graphql.macro';
const SEARCH_INVENTORIES = loader(
  '../../graphql/inventory/SEARCH_INVENTORIES.graphql'
);
const SEARCH_PRODUCTS = loader('../../graphql/product/SEARCH_PRODUCTS.graphql');
const GET_PRODUCT_CATEGORIES = loader(
  '../../graphql/product/GET_PRODUCT_CATEGORIES.graphql'
);
const GET_INVENTORY_TYPES = loader('../../graphql/inventory/GET_INVENTORY_TYPES.graphql');
const GET_INVENTORY_CATEGORIES = loader(
  '../../graphql/inventory/GET_INVENTORY_CATEGORIES.graphql'
);
export const handleAddInventory = (
  cache: any,
  createInventory: any,
  productCategory: string | undefined
) => {
  if (productCategory) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full: true,
        name: null,
      },
    });
    const newCreateInventory = {
      ...createInventory.product[0],
      inventory: {
        id: createInventory.id,
        units: createInventory.units,
      },
      productOperation: [],
      productMaterial: [],
      productRawMaterial: [],
    };
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full: true,
        name: null,
      },
      data: { searchProduct: searchProduct.concat(newCreateInventory) },
    });

    const { productCategories } = cache.readQuery({
      query: GET_PRODUCT_CATEGORIES,
    });
    cache.writeQuery({
      query: GET_PRODUCT_CATEGORIES,
      data: {
        productCategories: productCategories.concat([
          createInventory.product[0]?.productCategory,
        ]),
      },
    });
  }
  const { searchInventory } = cache.readQuery({
    query: SEARCH_INVENTORIES,
    variables: {
      full: true,
      name: null,
    },
  });
  cache.writeQuery({
    query: SEARCH_INVENTORIES,
    variables: {
      full: true,
      name: null,
    },
    data: {
      searchInventory: searchInventory.concat([createInventory]),
    },
  });
};
export const handleUpdateInventory = (cache: any, updateInventory: any) => {
  const { searchInventory } = cache.readQuery({
    query: SEARCH_INVENTORIES,
    variables: {
      full: true,
      name: null,
    },
  });
  const newsearchInventory = searchInventory.map((item) => {
    if (updateInventory.id === item.id) {
      return updateInventory;
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_INVENTORIES,
    variables: {
      full: true,
      name: null,
    },
    data: { searchInventory: newsearchInventory },
  });
};
export const handleDeleteInventory = (cache: any, deleteInventory: any) => {
  const { searchInventory } = cache.readQuery({
    query: SEARCH_INVENTORIES,
    variables: {
      full: true,
      name: null,
    },
  });
  const newsearchInventory = searchInventory.filter(
    (item) => item.id !== deleteInventory.id
  );
  cache.writeQuery({
    query: SEARCH_INVENTORIES,
    variables: {
      full: true,
      name: null,
    },
    data: { searchInventory: newsearchInventory },
  });
};
export const handleAddInventoryCategory = (cache, createInventoryCategory) => {
  const { inventoryCategories } = cache.readQuery({
    query: GET_INVENTORY_CATEGORIES,
  });
  cache.writeQuery({
    query: GET_INVENTORY_CATEGORIES,
    data: {
      inventoryCategories: inventoryCategories.concat([createInventoryCategory]),
    },
  });
};
export const handleUpdateInventoryCategory = (cache, updateInventoryCategory) => {
  const { inventoryCategories } = cache.readQuery({
    query: GET_INVENTORY_CATEGORIES,
  });
  const newInventoryCategories = inventoryCategories.map((item) => {
    if (updateInventoryCategory.id === item.id) {
      return {
        ...item,
        name: updateInventoryCategory.name,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_INVENTORY_CATEGORIES,
    data: { inventoryCategories: newInventoryCategories },
  });
};
export const handleDeleteInventoryCategory = (cache, deleteInventoryCategory) => {
  const { inventoryCategories } = cache.readQuery({
    query: GET_INVENTORY_CATEGORIES,
  });
  const newInventoryCategories = inventoryCategories.filter(
    (item) => item.id !== deleteInventoryCategory.id
  );
  cache.writeQuery({
    query: GET_INVENTORY_CATEGORIES,
    data: { inventoryCategories: newInventoryCategories },
  });
};
export const handleAddInventoryType = (cache, createInventoryType) => {
  const { inventoryTypes } = cache.readQuery({
    query: GET_INVENTORY_TYPES,
  });
  cache.writeQuery({
    query: GET_INVENTORY_TYPES,
    data: {
      inventoryTypes: inventoryTypes.concat(createInventoryType),
    },
  });
};
export const handleUpdateInventoryType = (cache, updateInventoryType) => {
  const { inventoryTypes } = cache.readQuery({
    query: GET_INVENTORY_TYPES,
  });
  const newinventoryTypes = inventoryTypes.map((item) => {
    if (updateInventoryType.id === item.id) {
      return {
        ...item,
        name: updateInventoryType.name,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_INVENTORY_TYPES,
    data: { inventoryTypes: newinventoryTypes },
  });
};
export const handleDeleteInventoryType = (cache, deleteInventoryType) => {
  const { inventoryTypes } = cache.readQuery({
    query: GET_INVENTORY_TYPES,
  });
  const newInventoryTypes = inventoryTypes.filter(
    (item) => item.id !== deleteInventoryType.id
  );
  cache.writeQuery({
    query: GET_INVENTORY_TYPES,
    data: { inventoryTypes: newInventoryTypes },
  });
};
