import { faEye, faEyeSlash, faPrint } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent,Fragment } from 'react';
import { FormattedMessage } from 'react-intl';
import { COLOR } from '../../../../components/FormWithElemets/Button/Button';
import Button from '../../../TailWinduiHTML/Form/Button';
import OfferStatusChange from '../SubcontractSales/OfferStatusChange';

interface ButtonsProps{
    setPreview:Function;
    offerId:string;
    preview:boolean;
    handlePrint:Function;
    dispatchComponentStatus:Function
}
const Buttons: FunctionComponent<ButtonsProps> = ({offerId,setPreview,preview,handlePrint,dispatchComponentStatus}) => {
  return (
    <Fragment>
      <OfferStatusChange offerId={offerId} />
      <Button
        color="green"
        onClick={() => {
          setPreview((prev: boolean) => !prev);
        }}
      >
        <FontAwesomeIcon
          icon={preview ? faEyeSlash : faEye}
          color={COLOR.grey}
          style={{ cursor: 'pointer' }}
        />
      </Button>
      {preview && (
        <Button
          color="green"
          onClick={() => {
            handlePrint();
          }}
        >
          <FontAwesomeIcon
            icon={faPrint}
            color={COLOR.grey}
            style={{ cursor: 'pointer' }}
          />
        </Button>
      )}
      <Button
        onClick={() => {
          dispatchComponentStatus({ type: 'LIST' });
        }}
      >
        <FormattedMessage id="button.back" />
      </Button>
    </Fragment>
  );
};
export default Buttons