import React, { FunctionComponent } from 'react';
import { Pagination as PaginationTemp } from 'baseui/pagination';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/FormWithElemets/Button/Button';
interface ListProps {
  currentPageInCount?:number
  setCurrentPage:any;
  dataLength:any;
  currentPage:any;
}
const Pagination: FunctionComponent<ListProps> = ({
  dataLength,
  currentPage,
  setCurrentPage,
  currentPageInCount=10
}) => {
  return (
    <PaginationTemp
      numPages={Math.ceil(dataLength / currentPageInCount)}
      currentPage={currentPage}
      overrides={{
        PrevButton: {
          component: ({ onClick }: any) => (
            <Button disabled={currentPage === 1} onClick={onClick}>
              <FormattedMessage id="button.previous" />
            </Button>
          ),
        },
        NextButton: {
          component: ({ onClick }: any) => (
            <Button
              disabled={currentPage === Math.ceil(dataLength / currentPageInCount)}
              onClick={onClick}
            >
              <FormattedMessage id="button.next" />
            </Button>
          ),
        },
      }}
      onPageChange={({ nextPage }) => {
        setCurrentPage(Math.min(Math.max(nextPage, 1), 60));
      }}
    />
  );
};
export default Pagination;