import React, { FunctionComponent } from 'react';

interface ErrorPros {
  className?: string;
}
const ErrorLabel: FunctionComponent<ErrorPros> = ({ children, className }) => {
  return (
    <p
      className={
        'mt-2 text-sm text-red-600 ' + className + (children === null ? ' hidden' : '')
      }
      id="email-error"
    >
      {children}
    </p>
  );
};
export default ErrorLabel;
