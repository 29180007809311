import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useToaster } from '../../../../context/toaster-provider';
import { handleDate } from '../../../Functions/Utilities';
import { Item } from '../../SubContractSales/AddSubContractSalesForm';
import InputForm from '../../SubContractSales/InputForm';
import { Status } from './ListSubcontractSalesItem';
const ADD_INLINE_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/ADD_INLINE_SUBCONTRACT_SALES_OFFER.graphql'
);
const UPDATE_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/UPDATE_SUBCONTRACT_SALES_OFFER.graphql'
);
interface ItemInputsProps {
  item?: Item;
  itemIndex?: number;
  setItemIndex?: Function;
  offerId?: string | undefined;
  setSubcontractSalesStatus: Function;
  subcontractSalesStatus: Status;
  setClose?: Function
}
const UpdateAddSubcontractSalesItem: FunctionComponent<ItemInputsProps> = ({
  item,
  itemIndex = null,
  setItemIndex,
  offerId = undefined,
  setSubcontractSalesStatus,
  subcontractSalesStatus,
  setClose
}) => {
  const { showToaster } = useToaster();
  const { register, setValue, control, getValues, watch, errors } = useForm();
  const [addInlineSubContract] = useMutation(ADD_INLINE_SUBCONTRACT_SALES_OFFER);
  const [updateInlineSubContract] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER);
  const handleAddOrUpdateItem = () => {
    const {
      qty,
      billOfMaterialId,
      estimatedDeliveryDate,
      unitsId,
    } = getValues();
    if (qty !== '' && billOfMaterialId) {
      if (itemIndex === null) {
        addInlineSubContract({
          variables: {
            id: offerId,
            subcontractSalesOffer: handleNewSubcontractItem({
              qty,
              billOfMaterialId,
              estimatedDeliveryDate,
              unitsId,
            }),
          },
        }).then(() => {
          showToaster('created', 'positive');
        });
      } else {
        updateInlineSubContract({
          variables: {
            id: item.id,
            subcontractSalesOffer: handleNewSubcontractItem({
              qty,
              billOfMaterialId,
              estimatedDeliveryDate,
              unitsId,
            }),
          },
        }).then(() => {
          showToaster('updated', 'positive');
        });
      }
      handleResetForm();
    }
  };
  useEffect(() => {
    console.log("--->1")
    const { status, billOfMaterialId, billOfMaterialName } = subcontractSalesStatus;
    if (status === 'AddSubcontractSalesOffer') {
      billOfMaterialId !== '' && billOfMaterialName !== ''
        ? setValue('billOfMaterialId', [
          { id: billOfMaterialId, label: billOfMaterialName },
        ])
        : setValue('billOfMaterialId', undefined);
    }
  }, [subcontractSalesStatus, setValue]);
  useEffect(() => {
    setValue('billOfMaterialId', item?.billOfMaterialId || undefined);
    setValue('unitsId', item?.unitsId || undefined);
    setValue('qty', item?.qty || '');
    setValue('estimatedDeliveryDate', [
      new Date(item?.estimatedDeliveryDate || new Date()),
    ]);
  }, [setValue, item]);
  const handleResetForm = () => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('billOfMaterialId', undefined);
      setValue('estimatedDeliveryDate', [new Date()]);
      setValue('qty', '');
      setValue('unitsId', undefined);
      setClose(false);
    }
  };
  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      estimatedDeliveryDate={item?.estimatedDeliveryDate}
      update={!!item}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      handleResetForm={handleResetForm}
      setSubcontractSalesStatus={setSubcontractSalesStatus}
    />
  );
};
export default UpdateAddSubcontractSalesItem;
const handleNewSubcontractItem = ({
  qty,
  billOfMaterialId,
  estimatedDeliveryDate,
  unitsId,
}) => {
  return {
    estimatedDeliveryDate: estimatedDeliveryDate ? handleDate(estimatedDeliveryDate[0])?.toISOString() : "",
    unitsId: unitsId[0]?.id,
    billOfMaterialId: billOfMaterialId[0]?.id,
    qty: +qty,
  };
};
