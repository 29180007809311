import { loader } from 'graphql.macro';
import { Company, CompanyAddress, CompanyTelephone } from '../../types/Company';

const SEARCH_COMPANY = loader('../../graphql/company/SEARCH_COMPANY.graphql');

export const handleUpdateCompany = (cache: any, updateCompany: Company) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (updateCompany.id === item.id) {
      return {
        ...updateCompany,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
export const handleAddCompany = (cache: any, createCompany: Company) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: searchCompany.concat(createCompany) },
  });
};
export const handleDeleteCompany = (cache: any, deleteCompany: any) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: {
      searchCompany: searchCompany.filter(
        (item: Company) => item.id !== deleteCompany.id
      ),
    },
  });
};
export const handleDeleteCompanyAddress = (
  cache: any,
  deleteCompanyAddress: any,
  companyId: string
) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (item.id === companyId) {
      return {
        ...item,
        companyAddress: item.companyAddress.filter(
          (itm) => itm.id !== deleteCompanyAddress.id
        ),
      };
    }
    return item;
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
export const handleAddCompanyAddress = (
  cache: any,
  createCompanyAddress: CompanyAddress,
  companyId: string
) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (item.id === companyId) {
      return {
        ...item,
        companyAddress: item.companyAddress.concat(createCompanyAddress),
      };
    }
    return item;
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
export const handleUpdateCompanyAddress = (
  cache: any,
  updateCompanyAddress: CompanyAddress,
  companyId: string
) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (item.id === companyId) {
      return {
        ...item,
        companyAddress: item.companyAddress.map((address) => {
          if (address.id === updateCompanyAddress.id) {
            return {
              ...updateCompanyAddress,
            };
          } else {
            return address;
          }
        }),
      };
    }
    return item;
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
export const handleDeleteCompanyTelephone = (
  cache: any,
  deleteCompanyTelephone: any,
  companyId: string
) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (item.id === companyId) {
      return {
        ...item,
        companyTelephones: item.companyTelephones.filter(
          (itm) => itm.id !== deleteCompanyTelephone.id
        ),
      };
    }
    return item;
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
export const handleAddCompanyTelephone = (
  cache: any,
  createCompanyTelephone: CompanyTelephone,
  companyId: string
) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (item.id === companyId) {
      return {
        ...item,
        companyTelephones: item.companyTelephones.concat(createCompanyTelephone),
      };
    }
    return item;
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
export const handleUpdateCompanyTelephone = (
  cache: any,
  updateCompanyTelephone: CompanyTelephone,
  companyId: string
) => {
  const { searchCompany } = cache.readQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
  });
  const newSearchCompany = searchCompany.map((item: Company) => {
    if (item.id === companyId) {
      return {
        ...item,
        companyTelephones: item.companyTelephones.map((telephone) => {
          if (telephone.id === updateCompanyTelephone.id) {
            return {
              ...updateCompanyTelephone,
            };
          } else {
            return telephone;
          }
        }),
      };
    }
    return item;
  });
  cache.writeQuery({
    query: SEARCH_COMPANY,
    variables: {
      full: true,
      name: null,
    },
    data: { searchCompany: newSearchCompany },
  });
};
