import React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

interface MaterialDetailProps {
  colName: string;
  materialDetail: {
    amount: number;
    profitRate: number;
    profitAmount: number;
    materialName: string;
  }[];
}
const MaterialDetail: FunctionComponent<MaterialDetailProps> = ({
    materialDetail,
    colName,
}) => {
  const intl = useIntl();
  return (
    <div className="overflow-hidden">
      {materialDetail.map((item) => {
        return (
          <ul key={item?.materialName}>
            <li>
              <a href="#">
                <div className="pl-4  sm:px-6">
                  <div className="mt-1  grid grid-cols-1  sm:grid-cols-2 lg:grid-cols-5 print:grid-cols-5">
                    <div className="col-span-1 sm:col-span-2 print:col-span-1">
                      <ColContent colName={colName} contentName={item.materialName} />
                    </div>
                    <div className="col-span-1 print:col-span-1">
                      <ColContent
                        colName={intl.formatMessage({ id: 'preview.unitPrice' })}
                        contentName={item.amount + ' ₺'}
                      />
                    </div>
                    <div className="col-span-1 print:col-span-1">
                       <ColContent
                          colName={
                            intl.formatMessage({ id: 'preview.profitAmount' }) +
                            ' % ' +
                            item.profitRate
                          }
                          contentName={item.profitAmount + ' ₺ '}
                        />
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        );
      })}
    </div>
  );
};
export default MaterialDetail;
interface ColContentProps {
  colName: string;
  contentName: string;
}
const ColContent: FunctionComponent<ColContentProps> = ({ colName, contentName }) => {
  return (
    <p className="flex items-center text-sm text-gray-500 mt-0">
      <span className="pr-3 font-medium text-xs">{colName}</span>
      <span className="text-xs">{contentName}</span>
    </p>
  );
};
