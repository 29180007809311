import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { Input, SIZE } from 'baseui/input';
import { loader } from 'graphql.macro';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, Row } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { Negative, Positive } from '../../../../components/General/NegativePositive';
import { useToaster } from '../../../../context/toaster-provider';
import { MaterialGeometry } from '../../../../types/MaterialGeometries';
import { useCalcOfferMaterialHexagonal } from '../../../Calc/Hexagonal/useCalcOfferMaterialHexagonal';
import { inputDecimal } from '../../../Functions/Utilities';
import MaterialGeometries from '../../../Product/MaterialGeomatries';
import { MaterialGeometry as MaterialGeometryShape } from '../../../../types/Calc';
import SelectRawMaterial from '../../../SelectCustom/SelectRawMaterial';
import { RawMaterials } from '../../../../types/Material';
import { useCalcOfferMaterialRectangle } from '../../../Calc/Rectangle/useCalcOfferMaterialRectangle';
import { useCalcOfferMaterialRound } from '../../../Calc/Round/useCalcOfferMaterialRound';
import { useCalcOfferMaterialSheet } from '../../../Calc/Sheet/useCalcOfferMaterialSheet';
import { useCalcOfferMaterialSquare } from '../../../Calc/Square/useCalcOfferMaterialSquare';
import SelectionMaterialGeometryType from '../../InReview/SubcontractSales/SelectionMaterialGeometryType';
import { RawMaterialInventory } from '../../../../types/Inventory';
const GET_RAW_MATERIAL = loader(
  '../../../../graphql/rawMaterial/GET_RAW_MATERIAL.graphql'
);
interface RawMaterialCalculationProps {
  setQtyCalculate?: Function;  
  handleResult: Function;
  rawMaterialGeometry: RawMaterialInventory;
  orderQty:number
}
const RawMaterialCalculation: FunctionComponent<RawMaterialCalculationProps> = ({
  setQtyCalculate,
  handleResult,
  rawMaterialGeometry,
  orderQty
}) => {
  const pageEndRef = useRef(null);
  useEffect(() => {
    if (pageEndRef.current) {
      pageEndRef?.current.scrollIntoView({ behavior: "smooth" })
    }
  },[pageEndRef])
  const { data } = useQuery<RawMaterials>(GET_RAW_MATERIAL);
  const {
    offerMaterialHexagonalResult,
    setState: setStateHexagonal,
  } = useCalcOfferMaterialHexagonal(handleHexagonal());
  /********/
  const {
    offerMaterialRectangleResult,
    setState: setStateRectangle,
  } = useCalcOfferMaterialRectangle(handleRectangle());
  /********/
  const { offerMaterialRoundResult, setState: setStateRound } = useCalcOfferMaterialRound(
    handleRound()
  );
  /********/
  const {
    offerMaterialSquareResult,
    setState: setStateSquare,
  } = useCalcOfferMaterialSquare(handleSquare());
  /********/
  const { offerMaterialSheetResult, setState: setStateSheet } = useCalcOfferMaterialSheet(
    handleSheet()
  );
  const { control, errors, register, setValue, getValues,watch } = useForm({
    defaultValues: handleDefaultValues({ rawMaterialGeometry,orderQty }),
  });
  const { rawMaterialId } = getValues();
  const { showToaster } = useToaster();
  const [materialGeometry, setMaterialGeometry] = useState<MaterialGeometry>(
    () => rawMaterialGeometry?.materialGeometry || null
  );
  const handleCalculate = () => {
    const {
      outerMeasurementA,
      outerMeasurementB,
      pieceLength,
      orderQty,
      unmachinablePartLength,
      cuttingToolDia,
      processingLength,
      unitPrice,
      rawMaterialId,
      innerMeasurementA,
      innerMeasurementB,
    } = getValues();
    if (materialGeometry.materialOuterShape.name === 'outer_hexagonal') {
      setStateHexagonal((prev) => ({
        ...prev,
        diameter: +outerMeasurementA,
        pieceLength: +pieceLength,
        qty: +orderQty,
        unmachinablePartLength: +unmachinablePartLength,
        cuttingToolDiam: +cuttingToolDia,
        unitPrice: +unitPrice,
        mass: data
          ? +data.rawMaterials.find((item) => item.id === rawMaterialId[0].id).mass
          : 0,
        processingLength: +processingLength,
        innerShape: materialGeometry.materialInnerShape.name,
        innerDiameterOrWidth: +innerMeasurementA,
        innerHeight: +innerMeasurementB,
      }));
    } else if (materialGeometry.materialOuterShape.name === 'outer_round') {
      setStateRound((prev) => ({
        ...prev,
        diameter: +outerMeasurementA,
        pieceLength: +pieceLength,
        qty: +orderQty,
        unmachinablePartLength: +unmachinablePartLength,
        cuttingToolDiam: +cuttingToolDia,
        unitPrice: +unitPrice,
        mass: data
          ? +data.rawMaterials.find((item) => item.id === rawMaterialId[0].id).mass
          : 0,
        processingLength: +processingLength,
        innerShape: materialGeometry.materialInnerShape.name,
        innerDiameterOrWidth: +innerMeasurementA,
        innerHeight: +innerMeasurementB,
      }));
    } else if (materialGeometry.materialOuterShape.name === 'outer_square') {
      setStateSquare((prev) => ({
        ...prev,
        width: +outerMeasurementA,
        pieceLength: +pieceLength,
        qty: +orderQty,
        unmachinablePartLength: +unmachinablePartLength,
        cuttingToolDiam: +cuttingToolDia,
        unitPrice: +unitPrice,
        mass: data
          ? +data.rawMaterials.find((item) => item.id === rawMaterialId[0].id).mass
          : 0,
        processingLength: +processingLength,
        innerShape: materialGeometry.materialInnerShape.name,
        innerDiameterOrWidth: +innerMeasurementA,
        innerHeight: +innerMeasurementB,
      }));
    } else if (materialGeometry.materialOuterShape.name === 'outer_rectangle') {
      setStateRectangle((prev) => ({
        ...prev,
        width: +outerMeasurementA,
        height: +outerMeasurementB,
        pieceLength: +pieceLength,
        qty: +orderQty,
        unmachinablePartLength: +unmachinablePartLength,
        cuttingToolDiam: +cuttingToolDia,
        unitPrice: +unitPrice,
        mass: data
          ? +data.rawMaterials.find((item) => item.id === rawMaterialId[0].id).mass
          : 0,
        processingLength: +processingLength,
        innerShape: materialGeometry.materialInnerShape.name,
        innerDiameterOrWidth: +innerMeasurementA,
        innerHeight: +innerMeasurementB,
      }));
    } else if (materialGeometry.materialOuterShape.name === 'outer_sheet') {
      setStateSheet((prev) => ({
        ...prev,
        thickness: +outerMeasurementA,
        height: +outerMeasurementB,
        pieceLength: +pieceLength,
        unmachinablePartLength: +unmachinablePartLength,
        cuttingToolDiam: +cuttingToolDia,
        unitPrice: +unitPrice,
        mass: data
          ? +data.rawMaterials.find((item) => item.id === rawMaterialId[0].id).mass
          : 0,
        processingLength: +processingLength,
        innerShape: materialGeometry.materialInnerShape.name,
      }));
    }
  };
  useEffect(() => {
    setValue('totalWeight', offerMaterialHexagonalResult?.materialWeight || 0);
    setValue('totalLength', offerMaterialHexagonalResult?.materialLength || 0);
    handleResult({
      rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
      materialGeometryId: materialGeometry?.id || null,
      result: offerMaterialHexagonalResult?.materialWeight || 0,
      oneUnitWeight:offerMaterialHexagonalResult?.oneUnitWeight || 0
    });
    if(offerMaterialHexagonalResult.materialWeight>0){
      showToaster(offerMaterialHexagonalResult.materialWeight + " Kilo","positive",false)
      setQtyCalculate(null)
    }
  }, [offerMaterialHexagonalResult,rawMaterialId,setValue,handleResult,materialGeometry]);
  useEffect(() => {
    setValue('totalWeight', offerMaterialRectangleResult?.materialWeight || 0);
    setValue('totalLength', offerMaterialRectangleResult?.materialLength || 0);
    handleResult({
      rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
      materialGeometryId: materialGeometry?.id || null,
      result: offerMaterialRectangleResult?.materialWeight || 0,
      oneUnitWeight:offerMaterialRectangleResult?.oneUnitWeight || 0
    });
    if(offerMaterialRectangleResult.materialWeight>0){
      showToaster(offerMaterialRectangleResult.materialWeight + " Kilo","positive",false)
      setQtyCalculate(null)
    }
  }, [offerMaterialRectangleResult,rawMaterialId,setValue,handleResult,materialGeometry]);
  useEffect(() => {
    setValue('totalWeight', offerMaterialRoundResult?.materialWeight || 0);
    setValue('totalLength', offerMaterialRoundResult?.materialLength || 0);
    handleResult({
      rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
      materialGeometryId: materialGeometry?.id || null,
      result: offerMaterialRoundResult?.materialWeight || 0,
      oneUnitWeight:offerMaterialRoundResult?.oneUnitWeight || 0
    });
    if(offerMaterialRoundResult.materialWeight>0){
      showToaster(offerMaterialRoundResult.materialWeight + " Kilo","positive",false)
      setQtyCalculate(null)
    }
  }, [offerMaterialRoundResult,rawMaterialId,setValue,handleResult,materialGeometry]);
  useEffect(() => {
    setValue('totalWeight', offerMaterialSheetResult?.materialWeight || 0);
    setValue('totalLength', offerMaterialSheetResult?.materialLength || 0);
    handleResult({
      rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
      materialGeometryId: materialGeometry?.id || null,
      result: offerMaterialSheetResult?.materialWeight || 0,
      oneUnitWeight:offerMaterialSheetResult?.oneUnitWeight || 0
    });
    if(offerMaterialSheetResult.materialWeight>0){
      showToaster(offerMaterialSheetResult.materialWeight + " Kilo","positive",false)
      setQtyCalculate(null)
    }
  }, [offerMaterialSheetResult,rawMaterialId,setValue,handleResult,materialGeometry]);
  useEffect(() => {
    setValue('totalWeight', offerMaterialSquareResult?.materialWeight || 0);
    setValue('totalLength', offerMaterialSquareResult?.materialLength || 0);
    handleResult({
      rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
      materialGeometryId: materialGeometry?.id || null,
      result: offerMaterialSquareResult?.materialWeight || 0,
      oneUnitWeight:offerMaterialSquareResult?.oneUnitWeight || 0
    });
    if(offerMaterialSquareResult.materialWeight>0){
      showToaster(offerMaterialSquareResult.materialWeight + " Kilo","positive",false)
      setQtyCalculate(null)
    }
  }, [offerMaterialSquareResult,rawMaterialId,setValue,handleResult,materialGeometry]);

  return (
    <CardBody style={{ padding: '10px' }}>
      <Form>
        <Row middle="xs">
          <Col xs={12}>
            <div>
              <FormattedMessage id="calc_material.material_geometry" />
            </div>
            <MaterialGeometries
              setMaterialGeometry={setMaterialGeometry}
              materialGeometry={materialGeometry}
              disabled={!!rawMaterialGeometry}
            />
          </Col>
          <Col sm={6} md={4} lg={3}>
            <ByPieceCheckbox control={control} />
          </Col>
          <Col sm={6} md={4} lg={3}>
            <SelectRawMaterial
              errors={errors}
              control={control}
              disabled={!!rawMaterialGeometry?.rawMaterial}
            />
          </Col>
          <Col sm={6} md={4} lg={3}>
            <FormControl
              label={<FormattedMessage id="calc_material.orderQty" />}
              error={
                errors.orderQty ? 'Please input a valid Cutting Material Length' : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="orderQty"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.orderQty}
                overrides={errors.orderQty ? { After: Negative } : { After: Positive }}
                onChange={(event) => {
                  setValue('orderQty', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            <FormControl
              label={<FormattedMessage id="calc_material.materialLength" />}
              error={
                errors.materialLength
                  ? 'Please input a valid Cutting Material Length'
                  : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="materialLength"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.materialLength}
                overrides={
                  errors.materialLength ? { After: Negative } : { After: Positive }
                }
                endEnhancer={"mm"}
                onChange={(event) => {
                  setValue('materialLength', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.cuttingToolDia" />}
              error={
                errors.cuttingToolDia
                  ? 'Please input a valid Cutting Tool Diamater'
                  : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="cuttingToolDia"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.cuttingToolDia}
                overrides={
                  errors.cuttingToolDia ? { After: Negative } : { After: Positive }
                }
                endEnhancer={"mm"}
                onChange={(event) => {
                  setValue('cuttingToolDia', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.pieceLength" />}
              error={errors.pieceLength ? 'Please input a valid Piece Length' : null}
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="pieceLength"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.pieceLength}
                endEnhancer={"mm"}
                overrides={errors.pieceLength ? { After: Negative } : { After: Positive }}
                onChange={(event) => {
                  setValue('pieceLength', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          {materialGeometry && (
            <SelectionMaterialGeometryType
              materialGeometry={materialGeometry}
              setValue={setValue}
              errors={errors}
              register={register}
              rawMaterialGeometry={rawMaterialGeometry}
            />
          )}
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.processingLength" />}
              error={
                errors.processingLength ? 'Please input a valid Processing Length' : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="processingLength"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.processingLength}
                endEnhancer={"mm"}
                overrides={
                  errors.processingLength ? { After: Negative } : { After: Positive }
                }
                onChange={(event) => {
                  setValue('processingLength', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.unmachinablePartLength" />}
              error={
                errors.unmachinablePartLength
                  ? 'Please input a valid Unmachinable Part Length Length'
                  : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="unmachinablePartLength"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.unmachinablePartLength}
                overrides={
                  errors.unmachinablePartLength
                    ? { After: Negative }
                    : { After: Positive }
                }
                endEnhancer={"mm"}
                onChange={(event) => {
                  setValue('unmachinablePartLength', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.shavingPrice" />}
              error={errors.shavingPrice ? 'Please input a valid Shaving Price' : null}
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="shavingPrice"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.shavingPrice}
                overrides={
                  errors.shavingPrice ? { After: Negative } : { After: Positive }
                }
                onChange={(event) => {
                  setValue('shavingPrice', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.shavingWeight" />}
              error={errors.shavingWeight ? 'Please input a valid Shaving Weight' : null}
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="shavingWeight"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.shavingWeight}
                overrides={
                  errors.shavingWeight ? { After: Negative } : { After: Positive }
                }
                onChange={(event) => {
                  setValue('shavingWeight', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl
              label={<FormattedMessage id="calc_material.unitPrice" />}
              error={errors.unitPrice ? 'Please input a valid Unit Price' : null}
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="unitPrice"
                inputRef={register({
                  required: true,
                })}
                error={!!errors.unitPrice}
                overrides={errors.unitPrice ? { After: Negative } : { After: Positive }}
                onChange={(event) => {
                  setValue('unitPrice', inputDecimal({ event }));
                }}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl label={<FormattedMessage id="calc_material.totalLength" />}>
              <Input
                type="text"
                size={SIZE.compact}
                name="totalLength"
                inputRef={register({
                  required: false,
                })}
                onChange={(event) => {
                  setValue('totalLength', inputDecimal({ event }));
                }}
                endEnhancer={"mm"}
                clearOnEscape
              />
            </FormControl>
          </Col>

          <Col sm={6} md={4} lg={3}>
            {/* + */}
            <FormControl label={<FormattedMessage id="calc_material.totalWeight" />}>
              <Input
                type="text"
                size={SIZE.compact}
                name="totalWeight"
                inputRef={register({
                  required: false,
                })}
                onChange={(event) => {
                  setValue('totalWeight', inputDecimal({ event }));
                }}
                endEnhancer="kg"
                clearOnEscape
              />
            </FormControl>
          </Col>
          <ColButton xs >
            <Button
              type="button"
              style={{
                marginTop: '30px',
                marginBottom: '15px',
              }}
              onClick={() => {
                setQtyCalculate(null);
              }}
            >
              <FormattedMessage id="button.close" />
            </Button>
            <Button
              type="button"
              onClick={() => {
                handleResetForm({ setValue });
              }}
              style={{
                marginTop: '30px',
                marginBottom: '15px',
              }}
            >
              <FormattedMessage id={'button.clean'} />
            </Button>
            <Button
              type="button"
              disabled={handleRawMaterialchose({
                rawMaterialId: watch().rawMaterialId,
              })}
              onClick={handleCalculate}
              style={{
                marginTop: '30px',
                marginBottom: '15px',
              }}
            >
              <FormattedMessage id="button.calculate" />
            </Button>
          </ColButton>
        </Row>
      </Form>
      <div ref={pageEndRef} />
    </CardBody>
  );
};
export default RawMaterialCalculation;
interface ByPieceCheckboxProps {
  control: any;
}
const ByPieceCheckbox: FunctionComponent<ByPieceCheckboxProps> = ({ control }) => {
  return (
    <div
      style={{ display: 'flex', marginTop: '20px', alignItems: 'center', height: '100%' }}
    >
      <Controller
        as={
          <Checkbox>
            <FormattedMessage id="calc_material.byPieces" />
          </Checkbox>
        }
        name="byPieces"
        type="checkbox"
        control={control}
        labelPlacement={LABEL_PLACEMENT.right}
        defaultValue={false}
      />
    </div>
  );
};
const handleResetForm = ({ setValue }) => {
  setValue('byPieces', false);
  setValue('cuttingToolDia', 0);
  setValue('pieceLength', 0);
  setValue('innerMeasurementA', 0);
  setValue('innerMeasurementB', 0);
  setValue('outerMeasurementA', 0);
  setValue('outerMeasurementB', 0);
  setValue('processingLength', 0);
  setValue('rawMaterialId', undefined);
  setValue('shavingPrice', 0);
  setValue('shavingWeight', 0);
  setValue('totalLength', 0);
  setValue('totalWeight', 0);
  setValue('unitPrice', 0);
  setValue('unmachinablePartLength', 0);
  setValue('withOffer', undefined);
};
const handleHexagonal = () => {
  return {
    diameter: 0,
    length: 3000,
    rawMaterialId: '0', //Kullanılmıyor
    rawMaterialName: 'Ad', //Kullanılmıyor
    byPieces: false,
    materialGeometry: MaterialGeometryShape.Hexagonal,
    qty: 0, //Default Atandı
    unitPrice: 0,
    pieceLength: 0,
    unmachinablePartLength: 0,
    cuttingToolDiam: 0,
    mass: 8.5,
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    processingLength: 0,
    innerShape: 'inner_full',
    innerDiameterOrWidth: 0,
    innerHeight: 0,
  };
};
const handleRectangle = () => {
  return {
    width: 0,
    height: 0,
    length: 3000,
    rawMaterialId: '0', //Kullanılmıyor
    rawMaterialName: 'Ad', //Kullanılmıyor
    byPieces: false,
    materialGeometry: MaterialGeometryShape.Rectangle,
    qty: 0, //Default Atandı
    unitPrice: 0,
    pieceLength: 0,
    unmachinablePartLength: 0,
    cuttingToolDiam: 0,
    mass: 8.5,
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    processingLength: 0,
    innerShape: 'inner_full',
    innerDiameterOrWidth: 0,
    innerHeight: 0,
  };
};
const handleRound = () => {
  return {
    diameter: 0,
    length: 3000,
    rawMaterialId: '0', //Kullanılmıyor
    rawMaterialName: 'Ad', //Kullanılmıyor
    byPieces: false,
    materialGeometry: MaterialGeometryShape.Round,
    qty: 0, //Default Atandı
    unitPrice: 0,
    pieceLength: 0,
    unmachinablePartLength: 0,
    cuttingToolDiam: 0,
    mass: 8.5,
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    processingLength: 0,
    innerShape: 'inner_full',
    innerDiameterOrWidth: 0,
    innerHeight: 0,
  };
};
const handleSquare = () => {
  return {
    width: 0,
    length: 3000,
    rawMaterialId: '0', //Kullanılmıyor
    rawMaterialName: 'Ad', //Kullanılmıyor
    byPieces: false,
    materialGeometry: MaterialGeometryShape.Round,
    qty: 0, //Default Atandı
    unitPrice: 0,
    pieceLength: 0,
    unmachinablePartLength: 0,
    cuttingToolDiam: 0,
    mass: 8.5,
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    processingLength: 0,
    innerShape: 'inner_full',
    innerDiameterOrWidth: 0,
    innerHeight: 0,
  };
};
const handleSheet = () => {
  return {
    thickness: 0,
    height: 0,
    length: 3000,
    rawMaterialId: '0', //Kullanılmıyor
    rawMaterialName: 'Ad', //Kullanılmıyor
    byPieces: false,
    materialGeometry: MaterialGeometryShape.Round,
    qty: 0, //Default Atandı
    unitPrice: 0,
    pieceLength: 0,
    unmachinablePartLength: 0,
    cuttingToolDiam: 0,
    mass: 8.5,
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    processingLength: 0,
    innerShape: 'inner_full',
  };
};
const handleDefaultValues = ({ rawMaterialGeometry,orderQty }) => {
  return {
    byPieces: false,
    cuttingToolDia: 0,
    materialLength: 3000,
    pieceLength: 0,
    orderQty: orderQty ||0,
    outerMeasurementA: rawMaterialGeometry?.outerMeasurementA || 0,
    outerMeasurementB: rawMaterialGeometry?.outerMeasurementB || 0,
    innerMeasurementA: rawMaterialGeometry?.innerMeasurementA || 0,
    innerMeasurementB: rawMaterialGeometry?.innerMeasurementB || 0,
    processingLength: 1000,
    rawMaterialId: rawMaterialGeometry && rawMaterialGeometry?.rawMaterial
      ? [
          {
            id: rawMaterialGeometry?.rawMaterial.id,
            label: rawMaterialGeometry.rawMaterial.name,
          },
        ]
      : undefined,
    shavingPrice: 0,
    totalLength: 0,
    shavingWeight: 0,
    totalWeight: 0,
    unitPrice: 0,
    unmachinablePartLength: 300,
    withOffer: false,
  };
};
const handleRawMaterialchose = ({ rawMaterialId }) => {
  return rawMaterialId?.length > 0 ? false : true;
};
