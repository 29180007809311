import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../../components/Input/Input';
import { useToaster } from '../../../../context/toaster-provider';
import SelectInventoryType from '../../../SelectCustom/SelectInventoryType';
import SelectUnit from '../../../SelectCustom/SelectUnit';
import { OutputType } from '../Step/Step';

interface ItemFormProps {
  item?: OutputType;
  itemIndex?: number;
  setItemAddShow?: Function;
  setItemIndex?: Function;
  dispatchItems: Function;
}
const ItemFormOutput: FunctionComponent<ItemFormProps> = ({
  item,
  setItemIndex,
  itemIndex = null,
  setItemAddShow,
  dispatchItems,
}) => {
  const { control, errors, setValue, register, getValues } = useForm({
    defaultValues: item
      ? {
          name: item?.name,
          inventoryTypeId: item?.inventoryTypeId,
          unitsId: item?.unitsId,
        }
      : {},
  });
  const { showToaster } = useToaster();
  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('name', '');
      setValue('inventoryTypeId', undefined);
      setValue('unitsId', undefined);
      setItemAddShow(false);
    }
  }, [item,setItemAddShow,setItemIndex,setValue]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { name, inventoryTypeId, unitsId } = getValues();
    if (true) {
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            name,
            inventoryTypeId,
            unitsId,
          },
        });
        showToaster('created', 'positive');
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            name,
            inventoryTypeId,
            unitsId,
          },
          index: itemIndex,
        });
        showToaster('updated', 'positive');
      }
      handleResetForm();
    }
  }, [dispatchItems, getValues, handleResetForm, itemIndex, showToaster]);

  return (
    <RowCustom bottom="xs">
      <Col xs={12} md={6} lg={4}>
        <FormControl label={<FormattedMessage id="executionPlanningStep.inputName" />}>
          <Input
            type="text"
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            clearOnEscape
          />
        </FormControl>
      </Col>
      <Col xs={5} md={6} lg={3}>
        <SelectInventoryType errors={errors} control={control} />
      </Col>
      <Col xs={5} md={6} lg={3}>
        <SelectUnit errors={errors} control={control} />
      </Col>
      <ColButton xs>
        <Button
          style={{
            marginBottom: '16px',
          }}
          type="button"
          onClick={handleResetForm}
        >
          <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
        </Button>
        <Button
          style={{
            marginBottom: '16px',
          }}
          onClick={handleAddOrUpdateItem}
          type="button"
        >
          <FormattedMessage id={item ? 'button.update' : 'button.add'} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};

export default ItemFormOutput;
