import { faArrowAltDown, faArrowAltUp, faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { OperationSalesOffer } from '../../../../types/OperationSalesOffer';
import { handleCeil, handleLocalDateString } from '../../../Functions/Utilities';
import OperationSalesPrice from './OperationSalesPrice/OperationSalesPrice';

interface ListItemOperationSalesProps {
  item: OperationSalesOffer;
  handleItemDelete: Function;
  handleItemEdit: Function;
  transportUnitPrice: number;
}
const ListItemOperationSales: FunctionComponent<ListItemOperationSalesProps> = memo(
  ({ item, handleItemDelete, handleItemEdit, transportUnitPrice }) => {
    const [operationSalesOfferId, setOperationSalesOfferId] = useState(null);
    return (
      <Fragment>
        <RowCustom>
          <Col xs={4}>
            <LabelCustom title={<FormattedMessage id="offer.item" />}>
              {item?.operation?.name || ''}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom
              title={<FormattedMessage id="offer.estimatedDeliveryDate" />}
            >
               {handleLocalDateString(item.estimatedDeliveryDate)}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.qty" />}>
              {item.qty} &nbsp; {item?.units?.name || 'No Unit'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
              {handleCeil((item?.unitPrice + transportUnitPrice || 0)) + ' ₺'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.amount" />}>
              {handleCeil((item?.unitPrice + transportUnitPrice) * item.qty) +
                ' ₺'}
            </LabelCustom>
          </Col>
          <ColButton xs>
            <Button
              type="button"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
              onClick={() => {
                handleItemDelete(item.id);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="1x" />
            </Button>
            <Button
              type="button"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
              onClick={() => handleItemEdit(item.id)}
            >
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Button>
            <Button
              type="button"
              onClick={() => setOperationSalesOfferId((prev: any) => prev === null ? item.id : null)}
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
            >
              <FontAwesomeIcon
                icon={operationSalesOfferId ? faArrowAltUp : faArrowAltDown}
                size="1x"
              />
            </Button>
          </ColButton>
        </RowCustom>
        <CardBody style={item.id === operationSalesOfferId ? {} : { display: 'none' }}>
          <OperationSalesPrice item={item} transportUnitPrice={transportUnitPrice} />
        </CardBody>
      </Fragment>
    );
  }
);
export default ListItemOperationSales;
