import React, { FunctionComponent, useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { ColumnContents } from '../../components/Table/DataTableCustom';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import {
  handleDeleteWareHouseShelf,
  handleUpdateWareHouseShelfOrder,
} from '../WareHouse/GraphqlFunction';
import DataTableCustomWithOrder from '../../components/Table/DataTableCustomWithOrder';
import { WarehouseContext } from '../WarehouseLevel/List';
import { WarehouseShelf } from '../../types/WarehouseShelf';
import WarehouseShelfForm from './WarehouseShelfForm';

const DELETE_WAREHOUSE_SHELF = loader(
  '../../graphql/warehouseShelf/DELETE_WAREHOUSE_SHELF.graphql'
);
const UPDATE_WAREHOUSE_SHELF_ORDER = loader(
  '../../graphql/warehouseShelf/UPDATE_WAREHOUSE_SHELF_ORDER.graphql'
);
interface ListContextType {
  warehouse: any;
  setWarehouse: Function;
}
const columnHeaders = [
  { name: 'warehouse_shelf.serial', col: { sm: 2 } },
  { name: 'warehouse_shelf.order', col: { sm: 2 } },
  { name: 'warehouse_shelf.name', col: { sm: 6 } },
  { name: 'transactions', col: { sm: 2 } },
];
interface ListProps {
  data: WarehouseShelf[];
  warehouseId: string;
}
const List: FunctionComponent<ListProps> = ({ data, warehouseId }) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const { warehouse } = useContext<ListContextType>(WarehouseContext);
  const [items, setItems] = useState(null);
  const columnContents: ColumnContents[] = [
    {
      name: 'serial',
      title: { name: 'warehouse_shelf.serial', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'order',
      title: { name: 'warehouse_shelf.order', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'name',
      title: { name: 'warehouse_shelf.name', custom: true },
      type: 'text',
      col: { sm: 6 },
    },

    {
      name: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleRemove(id);
          },
        },
      ],
      title: { name: 'transactions', custom: true },
      type: 'buttons',
      col: { sm: 2 },
    },
  ];
  const [remove] = useMutation(DELETE_WAREHOUSE_SHELF);
  const handleRemove = (Id) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
        update(cache, { data: { deleteWarehouseShelf } }) {
          handleDeleteWareHouseShelf(cache, deleteWarehouseShelf, warehouseId, warehouse);
        },
      }).then(() => showToaster('deleted', 'positive'));
    }
  };
  const handleOrder = (items) => {
    setItems(
      items.map((item, index) => {
        return {
          ...item,
          order: index,
        };
      })
    );
    const newItemsOrders = items.map((item, index) => {
      return {
        id: item.id,
        order: index,
      };
    });
    updateOrder({
      variables: {
        params: newItemsOrders,
      },
    }).then(() => showToaster('updated', 'positive'));
  };
  const [updateOrder] = useMutation(UPDATE_WAREHOUSE_SHELF_ORDER, {
    update(cache, { data: { updateWarehouseShelfOrder } }) {
      updateWarehouseShelfOrder?.status &&
        handleUpdateWareHouseShelfOrder(cache, items, warehouseId, warehouse);
    },
  });
  return (
    <CardBody style={{ padding: '5px' }}>
      <DataTableCustomWithOrder
        threeDot
        data={data}
        columnHeaders={columnHeaders}
        columnContents={columnContents}
        updateOrder={(items) => {
          handleOrder(items);
        }}
        form={({ item, setItemId }) => (
          <WarehouseShelfForm
            data={item}
            setItemId={setItemId}
            warehouseId={warehouseId}
          />
        )}
      />
    </CardBody>
  );
};
export default List;
