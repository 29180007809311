import React, { useEffect, useState, FunctionComponent } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { handleCompanyInformation } from './Utilities';
import { Label } from '../../components/FormWithElemets/Label.style';
import { Col, Row } from '../../components/FlexBox/FlexBox';
const GET_COMPANIES = loader('../../graphql/company/GET_COMPANIES.graphql');
type ListProps = {
  companyId: string;
  companyName: string;
  handleCurrency?:Function;
};
const CompanyInformation: FunctionComponent<ListProps> = ({ companyId, companyName,handleCurrency }) => {
  const [companyInformation, setCompanyInformation] = useState(null);
  const { data: dataCompany } =  useQuery(GET_COMPANIES);
  useEffect(() => {
    dataCompany &&
      setCompanyInformation(handleCompanyInformation(companyId, dataCompany.companies));
  }, [dataCompany,companyId]);
  useEffect(()=>{
    handleCurrency && handleCurrency(companyInformation?.currency)
  },[companyInformation,handleCurrency])
  return (
    <Row>
      <Col xs={12}>
        <Label title={<FormattedMessage id="waybill.company.companyName" />}>
          {companyName || '.........................................................'}
        </Label>
      </Col>
      <Col xs={12}>
        <Label style={{ justifyContent: 'flex-end' }}>
          {companyInformation?.address ||
            '.........................................................'}
        </Label>
        <Label style={{ justifyContent: 'flex-end' }}>
          {companyInformation?.county || '.......'}
          {' / '}
          {companyInformation?.city || '.......'}
        </Label>
        <Label style={{ justifyContent: 'flex-end' }}>
          {companyInformation?.taxOffice || '.......'}
          {' - '}
          {companyInformation?.taxNumber || '.......'}
        </Label>
      </Col>
    </Row>
  );
};
export default CompanyInformation;
