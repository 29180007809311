import React, { Fragment, FunctionComponent} from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import { COLOR } from '../../../../components/FormWithElemets/Button/Button';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';
import { PurchaseMaterialOffer } from '../../../../types/PurchaseMaterialOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';

interface ListItemProps {
  item: PurchaseMaterialOffer;
  handleItemDelete: Function;
  handleItemEdit: Function;
} 
const ListItemPurchaseMaterialOffer: FunctionComponent<ListItemProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(item.id);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    }
  ];
  return (
    <Fragment>
      <RowCustom>
        <Col xs={12} md={5} lg={4}>
          <LabelCustom title={intl.formatMessage({ id: 'inventory.inventoryId' })}>
            <span
              style={{
                fontSize: '20px',
                fontWeight: 'lighter',
                textDecoration: 'underline',
                color: COLOR.grey,
              }}
            >
              {item.inventory?.name || item?.name || 'No Item'}
            </span>
          </LabelCustom>
        </Col>
          <Fragment>
            <Col xs={12} md={4} lg={3}>
              <LabelCustom title={intl.formatMessage({ id: 'date.estimatedDate' })}>
                {handleLocalDateString(item.estimatedDate)}
              </LabelCustom>
            </Col>
            <Col xs={8} md={3} lg={3}>
              <LabelCustom title={intl.formatMessage({ id: 'purchaseOffer.qty' })}>
                {item.qty} &nbsp;{' '}
                {item.inventory?.units?.name || item?.units?.name || 'No Unit'}
              </LabelCustom>
            </Col>
            <ColButton xs>
              <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
            </ColButton>
          </Fragment>
      </RowCustom>
    </Fragment>
  );
};
export default ListItemPurchaseMaterialOffer;
