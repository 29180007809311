import React, { Fragment, FunctionComponent, memo } from "react";
import { OperationSalesOffer } from "../../../../../types/OperationSalesOffer";
import ListIteOperationSalesPreview from "./ListItemOperationSalesPreview";
import OperationSalesHeaders from "./OperationSalesHeaders";

interface ListSubcontractSalesPreviewProps {
    items: OperationSalesOffer[];
    transportUnitPrice: number;
}
const ListSubcontractSalesPreview: FunctionComponent<ListSubcontractSalesPreviewProps> = ({
    items,
    transportUnitPrice
}) => {
    return (
        <Fragment>
            <OperationSalesHeaders />
            {
                items.map((item: OperationSalesOffer) => {
                    return <ListIteOperationSalesPreview key={item?.id} item={item} transportUnitPrice={transportUnitPrice} />
                })
            }
        </Fragment>
    )
}
export default memo(ListSubcontractSalesPreview)