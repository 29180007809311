import React, { useCallback } from 'react';
import { FormControl } from 'baseui/form-control';
import { useForm, Controller } from 'react-hook-form';
import gql from 'graphql-tag';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import Button from '../../components/FormWithElemets/Button/Button';

const ADD_CASH_BOOK = gql`
  mutation createCashBook(
    $codeRef: CashBookCodeType
    $companyId: ID
    $currency: CurrenciesType
  ) {
    createCashBook(codeRef: $codeRef, companyId: $companyId, currency: $currency) {
      id
      codeRef
      codeCounter
      currency
      cashBookRecords {
        id
        balance
        credit
        date
        debit
        description
      }
    }
  }
`;

const GET_CASH_BOOKS = gql`
  query queryCashBook($id: ID!) {
    cashBookByCompanyId(id: $id) {
      id
      codeRef
      codeCounter
      currency
      cashBookRecords {
        id
        balance
        credit
        date
        debit
        description
      }
    }
  }
`;

export default function CashBookForm() {
  const { handleSubmit, errors, control } = useForm();
  const dispatch = useDrawerDispatch();
  const dataCompanyId = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const onSubmit = ({ currency }) => {
    console.log(currency);
    addCashBook({
      variables: {
        codeRef: 'CB',
        companyId: dataCompanyId.companyId,
        currency: currency,
      },
    });
  };
  const [addCashBook] = useMutation(ADD_CASH_BOOK, {
    update(cache, { data }) {
      const { cashBookByCompanyId } = cache.readQuery({
        query: GET_CASH_BOOKS,
        variables: { id: dataCompanyId.companyId },
      });
      cache.writeQuery({
        query: GET_CASH_BOOKS,
        variables: { id: dataCompanyId.companyId },
        data: {
          cashBookByCompanyId: cashBookByCompanyId.concat([data.createCashBook]),
        },
      });
      closeDrawer();
    },
  });
  return (
    <Card title={<FormattedMessage id={'unit.create'} />}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="cash_book.currency" />}
          error={errors.currency ? 'Please input a valid Currency' : null}
        >
          <Controller
            as={
              <RadioGroup align={ALIGN.vertical}>
                <Radio value="TRY">TRY</Radio>
                <Radio value="EUR">EUR</Radio>
                <Radio value="USD">USD</Radio>
              </RadioGroup>
            }
            rules={{ required: true }}
            defaultValue={'TRY'}
            name={'currency'}
            control={control}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
