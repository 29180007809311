import React, { Fragment, FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';
import { PurchaseOperationOffer } from '../../../../types/PurchaseOperationOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';

interface ListItemProps {
    item: PurchaseOperationOffer;
    handleItemDelete: Function;
    handleItemEdit: Function;
}
const ListItemPurchaseOperationOffer: FunctionComponent<ListItemProps> = ({
    item,
    handleItemEdit,
    handleItemDelete,
}) => {
    const intl = useIntl();
    const buttons = [
        {
            label: intl.formatMessage({ id: 'button.delete' }),
            function: () => {
                handleItemDelete(item.id);
            },
            check_type: 'DELETE',
        },
        {
            label: intl.formatMessage({ id: 'button.update' }),
            function: () => {
                handleItemEdit(item.id);
            },
            check_type: 'UPDATE',
        }
    ];
    return (
        <Fragment>
            <RowCustom>
                <Col xs={12} md={5} lg={4}>
                    <LabelCustom title={intl.formatMessage({ id: 'select.operation' })}>
                        {item?.operation?.name || 'No Operation'}
                    </LabelCustom>
                </Col>
                <Col xs={12} md={4} lg={3}>
                    <LabelCustom title={intl.formatMessage({ id: 'date.estimatedDate' })}>
                        {handleLocalDateString(item.estimatedDate)}
                    </LabelCustom>
                </Col>
                <Col xs={8} md={3} lg={3}>
                    <LabelCustom title={intl.formatMessage({ id: 'purchaseOffer.qty' })}>
                        {item?.qty}
                    </LabelCustom>
                </Col>
                <ColButton xs>
                    <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
                </ColButton>
            </RowCustom>
        </Fragment>
    );
};
export default ListItemPurchaseOperationOffer;
