import React, { Fragment, FunctionComponent, useEffect } from 'react';
import { Col as ColTemp, Row } from '../../components/FlexBox/FlexBox';
import { Card, CardBody } from '../../components/FormWithElemets/Card.style';
// import _hexagon from '../../image/materialPicture/hexagon.jpg';
// import _angle from '../../image/materialPicture/angle.jpg';
// import _flat from '../../image/materialPicture/flat.jpg';
// import _outerHexagonallnnerPipe from '../../image/materialPicture/outerHexagonallnnerPipe.jpg';
// import _plate from '../../image/materialPicture/plate.jpg';
// import _rectangulartube from '../../image/materialPicture/rectangulartube.jpg';
// import _round from '../../image/materialPicture/round.jpg';
// import _roundtubeext from '../../image/materialPicture/roundtubeext.jpg';
// import _square from '../../image/materialPicture/square.jpg';
// import _squaretube from '../../image/materialPicture/squaretube.jpg';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import {
  MaterialGeometries as MaterialGeometriesType,
  MaterialGeometry,
} from '../../types/MaterialGeometries';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../components/NoResult/NoResult';
import { styled } from 'styletron-react';
import { COLOR } from '../../components/FormWithElemets/Button/Button';
import { Hexagonal } from './MaterialGeomatriesSvg/Hexagonal';
import { Round } from './MaterialGeomatriesSvg/Round';
import { Square } from './MaterialGeomatriesSvg/Square';
import { Sheet } from './MaterialGeomatriesSvg/Sheet';
import { Rectangle } from './MaterialGeomatriesSvg/Rectangle';
import { useToaster } from '../../context/toaster-provider';
const GET_MATERIAL_GEOMETRIES = loader(
  '../../graphql/materialGeometries/GET_MATERIAL_GEOMETRIES.graphql'
);
interface ListProps {
  setMaterialGeometry: Function;
  materialGeometry: MaterialGeometry;
  disabled?: boolean;
}
const MaterialGeometries: FunctionComponent<ListProps> = ({
  setMaterialGeometry,
  materialGeometry,
  disabled = false,
}) => {
  const { showToaster } = useToaster();
  const { data, error, loading } = useQuery<MaterialGeometriesType>(
    GET_MATERIAL_GEOMETRIES
  );
  useEffect(() => {
    data && setMaterialGeometry(() => materialGeometry || data.materialGeometries[0]);
  }, [data,setMaterialGeometry,materialGeometry]);
  return (
    <>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
        data && (
          <Card>
            <CardBody>
              <Row style={{ margin: 0, padding: '3px' }}>
                {data.materialGeometries.map((geometry) => {
                  return (
                    <Fragment key={geometry.id}>
                      {geometry.materialOuterShape?.name === 'outer_hexagonal' && (
                        <Col
                          xs
                          style={
                            geometry.id === materialGeometry?.id
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                  paddingTop: '3px',
                                }
                              : { paddingTop: '3px' }
                          }
                          onClick={() =>
                            !disabled
                              ? setMaterialGeometry(geometry)
                              : showToaster('?', 'positive')
                          }
                        >
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <Hexagonal innerShape={geometry.materialInnerShape?.name} />
                          </Div>
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.materialOuterShape?.name === 'outer_round' && (
                        <Col
                          xs
                          style={
                            geometry.id === materialGeometry?.id
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                  paddingTop: '3px',
                                }
                              : { paddingTop: '3px' }
                          }
                          onClick={() =>
                            !disabled
                              ? setMaterialGeometry(geometry)
                              : showToaster('?', 'positive')
                          }
                        >
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <Round innerShape={geometry.materialInnerShape?.name} />
                          </Div>
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.materialOuterShape?.name === 'outer_square' && (
                        <Col
                          xs
                          style={
                            geometry.id === materialGeometry?.id
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                  paddingTop: '3px',
                                }
                              : { paddingTop: '3px' }
                          }
                          onClick={() =>
                            !disabled
                              ? setMaterialGeometry(geometry)
                              : showToaster('?', 'positive')
                          }
                        >
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <Square innerShape={geometry.materialInnerShape?.name} />
                          </Div>
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.materialOuterShape?.name === 'outer_sheet' && (
                        <Col
                          xs
                          style={
                            geometry.id === materialGeometry?.id
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                  paddingTop: '3px',
                                }
                              : { paddingTop: '3px' }
                          }
                          onClick={() =>
                            !disabled
                              ? setMaterialGeometry(geometry)
                              : showToaster('?', 'positive')
                          }
                        >
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <Sheet />
                          </Div>
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.materialOuterShape?.name === 'outer_rectangle' && (
                        <Col
                          xs
                          style={
                            geometry.id === materialGeometry?.id
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                  paddingTop: '3px',
                                }
                              : { paddingTop: '3px' }
                          }
                          onClick={() =>
                            !disabled
                              ? setMaterialGeometry(geometry)
                              : showToaster('?', 'positive')
                          }
                        >
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <Rectangle innerShape={geometry.materialInnerShape?.name} />
                          </Div>
                          <Div style={(disabled && geometry.id !== materialGeometry?.id) ?{opacity:0.3,cursor:"no-drop"}:{}}>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                    </Fragment>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
        )
      )}
    </>
  );
};
export default MaterialGeometries;
export const Col = styled(ColTemp, () => ({
  ':hover': {
    width: '108%',
    height: '108%',
    boxShadow: '0px 0px 0.3px 0.3px rgba(215,218,215,1)',
    cursor: 'pointer',
  },
}));
export const Div = styled('div', () => ({
  display:'flex',
  justifyContent:'center'
}));
/*
              <Fragment key={geometry.id}>
                      {geometry.name === 'Altıköşe Boru' && (
                        <Col
                          md={2}
                          style={
                            geometry.id === materialGeometry
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                }
                              : {}
                          }
                          onClick={() => setMaterialGeometry(geometry.id)}
                        >
                          <img src={_hexagon} alt="hexagon" width="100%" />

                          <Div>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.name === 'Kutu Profil' && (
                        <Col
                          md={2}
                          style={
                            geometry.id === materialGeometry
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                }
                              : {}
                          }
                          onClick={() => setMaterialGeometry(geometry.id)}
                        >
                          <img src={_squaretube} alt="hexagon" width="100%" />
                          <Div>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.name === 'Sac' && (
                        <Col
                          md={2}
                          style={
                            geometry.id === materialGeometry
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                }
                              : {}
                          }
                          onClick={() => setMaterialGeometry(geometry.id)}
                        >
                          <img src={_flat} alt="hexagon" width="100%" />
                          <Div>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.name === 'Mil' && (
                        <Col
                          md={2}
                          style={
                            geometry.id === materialGeometry
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                }
                              : {}
                          }
                          onClick={() => setMaterialGeometry(geometry.id)}
                        >
                          <img src={_roundtubeext} alt="hexagon" width="100%" />
                          <Div>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.name === 'Boru' && (
                        <Col
                          md={2}
                          style={
                            geometry.id === materialGeometry
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                }
                              : {}
                          }
                          onClick={() => setMaterialGeometry(geometry.id)}
                        >
                          <img src={_round} alt="hexagon" width="100%" />
                          <Div>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                      {geometry.name === 'Kare' && (
                        <Col
                          md={2}
                          style={
                            geometry.id === materialGeometry
                              ? {
                                  boxShadow: `0px 0px 3px 1px ${COLOR.grey}`,
                                  borderRadius: '3px',
                                }
                              : {}
                          }
                          onClick={() => setMaterialGeometry(geometry.id)}
                        >
                          <img src={_square} alt="hexagon" width="100%" />
                          <Div>
                            <p>{geometry.name}</p>
                          </Div>
                        </Col>
                      )}
                    </Fragment>
      
*/
