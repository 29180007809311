import React from 'react';
import { Col } from '../../../../../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../../../../../components/FormWithElemets/Label.style';
import { SubcontractSalesOfferMaterialPrice } from '../../../../../../types/SubcontractSalesOfferMaterialPrice';
import { handleCeil } from '../../../../../Functions/Utilities';
import GridCol from '../../../../../TailWinduiHTML/GridCol';
import { MaterialIds } from '../AddCol/AddMaterialCol';
import ColTitle from './ColTitle';
import MaterialDetail from './MaterialDetail';

const useMaterialCol = (
  subcontractSalesOfferMaterialPrice: SubcontractSalesOfferMaterialPrice[],
  cols: MaterialIds,
  setColMaterials: Function
) => {
  let unitPriceMaterial = 0;
  const materialCol = () => {
    const materialCol = cols.map((col, index) => {
      let profitAmount: number = 0;
      const materialDetail = col.materialIds.map((item) => {
        const _colMaterialPrice = colMaterialPrice(
          subcontractSalesOfferMaterialPrice,
          item.id
        );
        profitAmount += _colMaterialPrice.profitAmount;
        return {
          ..._colMaterialPrice,
          materialName: item.name,
        };
      });
      unitPriceMaterial += handleCeil(profitAmount);
      return {
        colTitle: (
          <ColTitle
            key={index}
            colName={col.colName}
            index={index}
            setColMaterials={setColMaterials}
          />
        ),
        colItem: (
          <div className="sm:col-span-6 md:col-span-4 lg:col-span-1 print:col-span-1">
            <GridCol title={col.colName} fontSize="xs">{handleCeil(profitAmount) + ' ₺'}</GridCol>
          </div>
        ),
        materialDetail: col?.detail ? (
          <MaterialDetail
            key={index}
            materialDetail={materialDetail}
            colName={col.colName}
          />
        ) : null,
      };
    });
    return {
      unitPriceMaterial: unitPriceMaterial,
      materialCol,
    };
  };
  return {
    materialCol,
  };
};
export default useMaterialCol;
const _calculation = ({ amount, profitRate }) => {
  const profitAmount = handleCeil(amount + (amount * profitRate) / 100);
  return {
    profitRate,
    profitAmount,
    amount,
  };
};
const colMaterialPrice = (
  subcontractSalesOfferMaterialPrice: SubcontractSalesOfferMaterialPrice[],
  _billOfMaterialItemId: string
) =>
  subcontractSalesOfferMaterialPrice
    .map((item) => {
      const { amount, profitRate, billOfMaterialItemId } = item;
      if (_billOfMaterialItemId === billOfMaterialItemId) {
        return _calculation({
          amount,
          profitRate,
        });
      } else {
        return null;
      }
    })
    .filter((i) => i !== null)[0];
