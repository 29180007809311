import { faCheck, faWindowClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, SIZE } from 'baseui/input';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../../../components/FlexBox/FlexBox';
import Button from '../../../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../../../components/FormWithElemets/FormControl';
import {
  Negative,
  Positive,
} from '../../../../../../components/General/NegativePositive';
import { AddColType } from '../ListSubcontractSalesPreview';
import CustomSelectOperation from './CustomSelectOperation';

interface AddOperationColProps {
  setClose?: Function;
  setCols: Function;
  items: { id: string; name: string }[];
}
const AddOperationCol: FunctionComponent<AddOperationColProps> = ({
  setClose,
  setCols,
  items,
}) => {
  const { handleSubmit, control, errors, register, setValue } = useForm();
  const onSubmit = ({ operationIds, colName }) => {
    const _operationIds = operationIds
      .map((item: { id: string; label: string }) => ({ id: item.id, name: item.label }))
      .flat(1);
    setCols((prev: OperationIds) => [
      ...prev,
      {
        operationIds: _operationIds,
        colName: colName,
        detail: false,
      },
    ]);
    handleRestForm();
  };
  const handleRestForm = () => {
    setValue('operationIds', undefined);
    setValue('colName', '');
    setClose((prev: AddColType) => ({
      ...prev,
      addOperatinCol: false,
    }));
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom>
        <Col xs={7}>
          <CustomSelectOperation control={control} errors={errors} items={items} />
        </Col>
        <Col xs={3}>
          <FormControl
            label={<FormattedMessage id="addCol.colName" />}
            error={errors.colName ? 'Please input a valid Col Name' : null}
          >
            <Input
              size={SIZE.compact}
              name="colName"
              inputRef={register({
                required: true,
              })}
              error={errors.colName ? true : false}
              overrides={errors.colName ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
        </Col>
        <ColButton xs style={{ marginTop: '15px' }}>
          <Button type="button" onClick={handleRestForm}>
            <FontAwesomeIcon icon={faWindowClose} />
          </Button>
          <Button type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default AddOperationCol;
export type OperationIds = {
  operationIds: [{ id: string; name: string }];
  colName: string;
  detail: boolean;
}[];
