import React, { FunctionComponent } from 'react';

interface LabelProps {
  name: string;
  className?: string;
  required?: boolean;
}
const Label: FunctionComponent<LabelProps> = ({
  children,
  name,
  className,
  required = false,
}) => {
  return (
    <div className="flex">
      <label
        htmlFor={name}
        className={'block text-sm font-medium text-gray-700' + className}
      >
        {children}
      </label>
      {required && (
        <span className="text-sm text-gray-500 pl-2 pr-1" id="email-optional">
          *
        </span>
      )}
    </div>
  );
};
export default Label;
