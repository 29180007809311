import React, { FunctionComponent, useContext } from 'react';
import {  useIntl } from 'react-intl';
import { ColButton, Row } from '../../components/FlexBox/FlexBox';
import { ColumnButtons } from '../../components/TailWinduiTable/ColumnButtons';
import { ComponentStatusContext } from './Offer';

const OfferAddTypeSelect: FunctionComponent = () => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'offer.subContract' }),
      function: () => {
        dispatchComponentStatus({ type: 'ADD', offerType: 'Subcontract' });
      },
      check_type: 'ADD',
    },
    {
      label: intl.formatMessage({ id: 'offer.product' }),
      function: () => {
        dispatchComponentStatus({ type: 'ADD', offerType: 'Product' });
      },
      check_type: 'ADD',
    },
    {
      label: intl.formatMessage({ id: 'offer.operation' }),
      function: () => {
        dispatchComponentStatus({ type: 'ADD', offerType: 'Operation' });
      },
      check_type: 'ADD',
    },
  ];
  const item = [
    {
      id: 'ADD',
    },
  ];
  return (
    <Row>
      <ColButton xs={12}>
        <ColumnButtons threeDot={true} item={item} buttons={buttons} />
      </ColButton>
    </Row>
  );
};
export default OfferAddTypeSelect;
