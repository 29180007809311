export const CR = '\x0D';
interface ShopFloorOrder {
  productBarcodeNo: string;
  product: string;
  qtyOrders: number;
  shift: string;
  user: string;
  processingTime: string;
  companyShortName: string;
  orderNo: string;
  productionOrderNo: string;
  completeOperation: string;
  nextOperation: string;
}
interface ShopFloorOrderWip extends ShopFloorOrder {
  qtyProduction: number;
}
interface ShopFloorOrderImproper extends ShopFloorOrder {
  qtyImproper: number;
  productRecordsReason: string;
}
export const shopFloorOrderWip: Function = ({
  productBarcodeNo,
  product,
  qtyOrders,
  qtyProduction,
  shift,
  user,
  processingTime,
  companyShortName,
  orderNo,
  productionOrderNo,
  completeOperation,
  nextOperation,
}: ShopFloorOrderWip) => {
  let cmds = '^Q70,3' + CR;
  cmds += '^W100' + CR;
  cmds += '^H5' + CR;
  cmds += '^P1' + CR;
  cmds += '^S2' + CR;
  cmds += '^AD' + CR;
  cmds += '^C1' + CR;
  cmds += '^R16' + CR;
  cmds += '~Q-16' + CR;
  cmds += '^O0' + CR;
  cmds += '^D0' + CR;
  cmds += '^E12' + CR;
  cmds += '~R255' + CR;
  cmds += '^L' + CR;
  cmds += 'Dy2-me-dd' + CR;
  cmds += 'Th:m:s' + CR;
  cmds += 'Y29,20,Image2-50' + CR;
  cmds += 'R22,388,747,492,2,2' + CR;
  cmds += 'Lo,22,422,747,423' + CR;
  cmds += 'Lo,22,458,747,459' + CR;
  cmds += 'Lo,260,388,261,492' + CR;
  cmds += 'R20,256,747,370,2,2' + CR;
  cmds += 'Lo,20,293,747,294' + CR;
  cmds += 'Lo,20,331,747,332' + CR;
  cmds += 'Lo,157,256,158,370' + CR;
  cmds += 'Lo,381,256,382,370' + CR;
  cmds += 'Lo,552,256,553,370' + CR;
  cmds += 'R21,160,747,239,2,2' + CR;
  cmds += 'Lo,21,200,747,201' + CR;
  cmds += 'Lo,145,160,146,239' + CR;
  cmds += 'BQ,199,24,2,5,94,0,3,' + productBarcodeNo + CR; //barcode No
  cmds += 'AB,34,207,1,1,0,0E,Ürün' + CR;
  cmds += 'AB,29,340,1,1,0,0E,Vardiya' + CR;
  cmds += 'AB,35,465,1,1,0,0E,İşlem Yapan' + CR;
  cmds += 'AB,390,340,1,1,0,0E,İşlem Zamanı' + CR;
  cmds += 'AB,158,209,1,1,0,0E,' + product + CR;
  cmds += 'AB,174,304,1,1,0,0E,' + qtyOrders + CR;
  cmds += 'AB,569,304,1,1,0,0E,' + qtyProduction + CR;
  cmds += 'AB,174,339,1,1,0,0E,' + shift + CR;
  cmds += 'AB,271,466,1,1,0,0E,' + user + CR;
  cmds += 'AB,569,339,1,1,0,0E,' + processingTime + CR;
  cmds += 'AB,29,264,1,1,0,0E,Sipariş No' + CR;
  cmds += 'AB,34,168,1,1,0,0E,Müşteri' + CR;
  cmds += 'AB,390,264,1,1,0,0E,Üretim Emri No' + CR;
  cmds += 'AB,32,395,1,1,0,0E,Tamamlanan İşlem' + CR;
  cmds += 'AB,35,429,1,1,0,0E,Sıradaki İşlem' + CR;
  cmds += 'AB,160,170,1,1,0,0E,' + companyShortName + CR; //compnay
  cmds += 'XRB629,31,5,0,19' + CR;
  cmds += productBarcodeNo + CR; //barcode No
  cmds += 'AB,271,397,1,1,0,0E,' + completeOperation + CR;
  cmds += 'AB,271,432,1,1,0,0E,' + nextOperation + CR;
  cmds += 'AB,390,302,1,1,0,0E,Üretim Adeti' + CR;
  cmds += 'AB,29,302,1,1,0,0E,Sipariş Adeti' + CR;
  cmds += 'AB,174,265,1,1,0,0E,' + orderNo + CR;
  cmds += 'AB,568,268,1,1,0,0E,' + productionOrderNo + CR;
  cmds += 'E' + CR;
  return cmds;
};

export const shopFloorOrderImproper: Function = ({
  productBarcodeNo,
  product,
  qtyOrders,
  qtyImproper,
  shift,
  user,
  processingTime,
  companyShortName,
  orderNo,
  productionOrderNo,
  completeOperation,
  productRecordsReason,
  nextOperation,
}: ShopFloorOrderImproper) => {
  let cmds = '^Q70,3' + CR;
  cmds += '^W100' + CR;
  cmds += '^H5' + CR;
  cmds += '^P1' + CR;
  cmds += '^S2' + CR;
  cmds += '^AD' + CR;
  cmds += '^C1' + CR;
  cmds += '^R16' + CR;
  cmds += '~Q-16' + CR;
  cmds += '^O0' + CR;
  cmds += '^D0' + CR;
  cmds += '^E12' + CR;
  cmds += '~R255' + CR;
  cmds += '^L' + CR;
  cmds += 'Dy2-me-dd' + CR;
  cmds += 'Th:m:s' + CR;
  cmds += 'Y16,21,Image2-50' + CR;
  cmds += 'R21,417,747,496,2,2' + CR;
  cmds += 'Lo,21,456,747,457' + CR;
  cmds += 'Lo,222,417,222,496' + CR;

  cmds += 'R21,226,747,340,2,2' + CR;
  cmds += 'Lo,21,263,747,264' + CR;
  cmds += 'Lo,21,302,747,303' + CR;
  cmds += 'Lo,158,226,159,340' + CR;
  cmds += 'Lo,382,226,383,340' + CR;
  cmds += 'Lo,553,226,554,340' + CR;

  cmds += 'R21,148,747,227,2,2' + CR;
  cmds += 'Lo,21,188,747,189' + CR;
  cmds += 'Lo,158,148,159,227' + CR;

  cmds += 'BQ,208,10,2,5,94,0,3,' + productBarcodeNo + CR;
  cmds += 'AB,34,195,1,1,0,0E,Ürün' + CR;
  cmds += 'AB,30,311,1,1,0,0E,Vardiya' + CR;
  cmds += 'AB,391,311,1,1,0,0E,İşlemi Yapan' + CR;
  cmds += 'AB,169,197,1,1,0,0E,' + product + CR;
  cmds += 'AB,175,275,1,1,0,0E,' + qtyOrders + CR;
  cmds += 'AB,568,274,1,1,0,0E,' + qtyImproper + CR;
  cmds += 'AB,175,310,1,1,0,0E,' + shift + CR;
  cmds += 'AB,565,309,1,1,0,0E,' + user + CR;
  cmds += 'AB,580,120,1,1,0,0E,' + processingTime + CR;
  cmds += 'AB,30,234,1,1,0,0E,Sipariş No' + CR;
  cmds += 'AB,34,156,1,1,0,0E,Müşteri' + CR;
  cmds += 'AB,391,235,1,1,0,0E,Üretim Emri No' + CR;
  cmds += 'AB,32,427,1,1,0,0E,Tamamlanan İşlem' + CR;
  cmds += 'AB,33,465,1,1,0,0E,Sıradaki İşlem' + CR;
  cmds += 'AB,171,158,1,1,0,0E,' + companyShortName + CR;
  cmds += 'XRB635,29,4,0,20' + CR;
  cmds += productBarcodeNo + CR;
  cmds += 'AB,232,465,1,1,0,0E,' + nextOperation + CR;
  cmds += 'AB,391,273,1,1,0,0E,Uygunsuz Adeti' + CR;
  cmds += 'AB,30,273,1,1,0,0E,Sipariş Adeti' + CR;
  cmds += 'AB,175,236,1,1,0,0E,' + orderNo + CR;
  cmds += 'AB,568,236,1,1,0,0E,' + productionOrderNo + CR;
  cmds += 'AB,232,426,1,1,0,0E,' + completeOperation + CR;
  cmds += 'R21,339,747,418,2,2' + CR;

  cmds +=
    'AB,28,347,1,1,0,0E,Uygunsuzluk Nedenleri :' + productRecordsReason.slice(0, 50) + CR;
  cmds += 'AB,28,375,1,1,0,0E,' + productRecordsReason.slice(50) + CR;
  cmds += 'E' + CR;
  return cmds;
};
/*
 let cmds = '^Q70,3' + CR;
  cmds += '^W100' + CR;
  cmds += '^H5' + CR;
  cmds += '^P1' + CR;
  cmds += '^S2' + CR;
  cmds += '^AD' + CR;
  cmds += '^C1' + CR;
  cmds += '^R16' + CR;
  cmds += '~Q-16' + CR;
  cmds += '^O0' + CR;
  cmds += '^D0' + CR;
  cmds += '^E12' + CR;
  cmds += '~R255' + CR;
  cmds += '^L' + CR;
  cmds += 'Dy2-me-dd' + CR;
  cmds += 'Th:m:s' + CR;
  cmds += 'Y29,20,Image0-29' + CR;
  cmds += 'R22,388,747,492,2,2' + CR;
  cmds += 'Lo,22,422,747,423' + CR;
  cmds += 'Lo,22,458,747,459' + CR;
  cmds += 'Lo,260,388,261,492' + CR;
  cmds += 'R20,256,747,370,2,2' + CR;
  cmds += 'Lo,20,293,747,294' + CR;
  cmds += 'Lo,20,331,747,332' + CR;
  cmds += 'Lo,157,256,158,370' + CR;
  cmds += 'Lo,381,256,382,370' + CR;
  cmds += 'Lo,552,256,553,370' + CR;
  cmds += 'R21,160,747,239,2,2' + CR;
  cmds += 'Lo,21,200,747,201' + CR;
  cmds += 'Lo,145,160,146,239' + CR;
  cmds += 'BQ,199,24,2,5,94,0,3,' + productBarcodeNo + CR; //barcode No
  cmds += 'AB,34,207,1,1,0,0E,Ürün' + CR;
  cmds += 'AB,29,340,1,1,0,0E,Vardiya' + CR;
  cmds += 'AB,35,465,1,1,0,0E,İşlem Yapan' + CR;
  cmds += 'AB,390,340,1,1,0,0E,İşlem Zamanı' + CR;
  cmds += 'AB,158,209,1,1,0,0E,' + product + CR;
  cmds += 'AB,174,304,1,1,0,0E,' + qtyOrders + CR;
  cmds += 'AB,569,304,1,1,0,0E,' + qtyImproper + CR;
  cmds += 'AB,174,339,1,1,0,0E,' + shift + CR;
  cmds += 'AB,271,466,1,1,0,0E,' + user + CR;
  cmds += 'AB,569,339,1,1,0,0E,' + processingTime + CR;
  cmds += 'AB,29,264,1,1,0,0E,Sipariş No' + CR;
  cmds += 'AB,34,168,1,1,0,0E,Müşteri' + CR;
  cmds += 'AB,390,264,1,1,0,0E,Üretim Emri No' + CR;
  cmds += 'AB,32,395,1,1,0,0E,Tamamlanan İşlem' + CR;
  cmds += 'AB,35,429,1,1,0,0E,Sıradaki İşlem' + CR;
  cmds += 'AB,160,170,1,1,0,0E,' + companyShortName + CR; //compnay
  cmds += 'XRB635,34,4,0S018018,20' + CR;
  cmds += productBarcodeNo + CR; //barcode No
  cmds += 'AB,271,397,1,1,0,0E,' + completeOperation + CR;
  cmds += 'AB,271,432,1,1,0,0E,' + CR;
  cmds += 'AB,390,302,1,1,0,0E,Uygunsuz Adeti' + CR;
  cmds += 'AB,29,302,1,1,0,0E,Sipariş Adeti' + CR;
  cmds += 'AB,174,265,1,1,0,0E,' + orderNo + CR;
  cmds += 'AB,568,268,1,1,0,0E,' + productionOrderNo + CR;
  cmds += 'E' + CR;
  return cmds;
*/
///////////////////
/*
export const shopFloorOrderWip: Function = ({
  productRecordId,
  product,
  qtyOrders,
  qtyProduction,
  shift,
  user,
  processingTime,
}) => {
  let cmds = '^Q70,3' + CR;
  cmds += '^W100' + CR;
  cmds += '^H5' + CR;
  cmds += '^P1' + CR;
  cmds += '^S2' + CR;
  cmds += '^AD' + CR;
  cmds += '^C1' + CR;
  cmds += '^R16' + CR;
  cmds += '~Q-16' + CR;
  cmds += '^O0' + CR;
  cmds += '^D0' + CR;
  cmds += '^E12' + CR;
  cmds += '~R255' + CR;
  cmds += '^L' + CR;
  cmds += 'Dy2-me-dd' + CR;
  cmds += 'Th:m:s' + CR;
  cmds += 'R9,7,750,371,2,2' + CR;
  cmds += 'Lo,9,66,750,67' + CR;
  cmds += 'Lo,9,126,750,127' + CR;
  cmds += 'Lo,9,186,750,187' + CR;
  cmds += 'Lo,9,246,750,247' + CR;
  cmds += 'Lo,9,306,750,307' + CR;
  cmds += 'Lo,230,7,231,371' + CR;

  cmds += 'AC,15,28,1,1,0,0E,Ürün :' + CR;
  cmds += 'AC,15,85,1,1,0,0E,Sipariş Adeti :' + CR;
  cmds += 'AC,15,146,1,1,0,0E,Üretim Adeti :' + CR;
  cmds += 'AC,15,204,1,1,0,0E,Vardiya :' + CR;
  cmds += 'AC,15,264,1,1,0,0E,İşlem Yapan :' + CR;
  cmds += 'AC,15,326,1,1,0,0E,İşlem Zamanı :' + CR;
  cmds += 'AA,250,29,1,1,0,0E,' + product + CR;
  cmds += 'AA,250,91,1,1,0,0E,' + qtyOrders + CR;
  cmds += 'AA,250,148,1,1,0,0E,' + qtyProduction + CR;
  cmds += 'AA,250,205,1,1,0,0E,' + shift + CR;
  cmds += 'AA,250,270,1,1,0,0E,' + user + CR;
  cmds += 'AA,250,332,1,1,0,0E,' + processingTime + CR;
  cmds += 'BP,21,405,2,6,80,0,3,' + productRecordId + CR;

  cmds += 'E' + CR;
  return cmds;
};

export const shopFloorOrderImproper: Function = ({
  productRecordId,
  product,
  qtyOrders,
  qtyImproper,
  shift,
  user,
  processingTime,
}) => {
  let cmds = '^Q70,3' + CR;
  cmds += '^W100' + CR;
  cmds += '^H5' + CR;
  cmds += '^P1' + CR;
  cmds += '^S2' + CR;
  cmds += '^AD' + CR;
  cmds += '^C1' + CR;
  cmds += '^R16' + CR;
  cmds += '~Q-16' + CR;
  cmds += '^O0' + CR;
  cmds += '^D0' + CR;
  cmds += '^E12' + CR;
  cmds += '~R255' + CR;
  cmds += '^L' + CR;
  cmds += 'Dy2-me-dd' + CR;
  cmds += 'Th:m:s' + CR;
  cmds += 'R9,7,750,371,2,2' + CR;
  cmds += 'Lo,9,66,750,67' + CR;
  cmds += 'Lo,9,126,750,127' + CR;
  cmds += 'Lo,9,186,750,187' + CR;
  cmds += 'Lo,9,246,750,247' + CR;
  cmds += 'Lo,9,306,750,307' + CR;
  cmds += 'Lo,230,7,231,371' + CR;

  cmds += 'AC,15,28,1,1,0,0E,Ürün :' + CR;
  cmds += 'AC,15,85,1,1,0,0E,Sipariş Adeti :' + CR;
  cmds += 'AC,15,146,1,1,0,0E,Uygunsuz Adeti :' + CR;
  cmds += 'AC,15,204,1,1,0,0E,Vardiya :' + CR;
  cmds += 'AC,15,264,1,1,0,0E,İşlem Yapan :' + CR;
  cmds += 'AC,15,326,1,1,0,0E,İşlem Zamanı :' + CR;
  cmds += 'AA,250,29,1,1,0,0E,' + product + CR;
  cmds += 'AA,250,91,1,1,0,0E,' + qtyOrders + CR;
  cmds += 'AA,250,148,1,1,0,0E,' + qtyImproper + CR;
  cmds += 'AA,250,205,1,1,0,0E,' + shift + CR;
  cmds += 'AA,250,270,1,1,0,0E,' + user + CR;
  cmds += 'AA,250,332,1,1,0,0E,' + processingTime + CR;
  cmds += 'BP,21,405,2,6,80,0,3,' + productRecordId + CR;

  cmds += 'E' + CR;
  return cmds;
};
*/
