// @ts-nocheck
import { styled } from 'baseui';
import React, { FunctionComponent } from 'react';
import { COLOR } from './Button/Button';

type ListProps = {
  children: React.ReactNode;
  title?: React.ReactNode;
  style?: object;
  styleTitle?: object;
  styleContent?: object;
};
const Title = styled('span', ({ $theme }) => ({
  ...$theme.typography.font350,
  color: COLOR.grey,
  margin: 0,
  paddingBottom: '1px',
  paddingTop: '1px',
  paddingRight: '1px',
  paddingLeft: '3px',
}));
const TitleMinWidth = styled('span', ({ $theme }) => ({
  ...$theme.typography.font350,
  color: COLOR.grey,
  margin: 0,
  paddingBottom: '1px',
  paddingTop: '1px',
  paddingRight: '1px',
  paddingLeft: '3px',
  verticalAlign: 'text-bottom',
  '@media all and (min-width: 1001px)': {
    display: 'none !important',
  },
}));

export const Content = styled('span', ({ $theme }) => ({
  color: $theme.colors.textDark,
  textAlign: 'left',
  margin: 0,
  paddingBottom: '5px',
  paddingTop: '1px',
  paddingLeft: '1px',
  paddingRight: '3px',
}));
export const Label: FunctionComponent<ListProps> = ({
  style,
  children,
  title,
  styleTitle,
  styleContent,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        marginBottom: '6px',
        alignItems: 'flex-end',
        marginTop: '6px',
        flexWrap: 'wrap',
        ...style,
      }}
    >
      {title && <Title style={{ flex: '1 2 auto', ...styleTitle }}>{title}</Title>}
      {children && <Content style={{ ...styleContent }}>{children}</Content>}
    </div>
  );
};
export const LabelCol: FunctionComponent<ListProps> = ({
  style,
  children,
  title,
  styleTitle,
  styleContent,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        marginBottom: '6px',
        alignItems: 'flex-end',
        marginTop: '6px',
        flexWrap: 'wrap',
        ...style,
      }}
    >
      {title && <Title style={{ paddingRight: '5px', ...styleTitle }}>{title}</Title>}
      <Content> : </Content>
      {children && (
        <Content style={{ flex: '1 2 auto', ...styleContent }}>{children}</Content>
      )}
    </div>
  );
};

export const LabelCustom: FunctionComponent<ListProps> = ({
  style,
  children,
  title,
  styleTitle,
  styleContent,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        marginBottom: '6px',
        alignItems: 'flex-end',
        marginTop: '6px',
        flexWrap: 'wrap',
        ...style,
      }}
    >
      {title && (
        <TitleMinWidth style={{ flex: '1 0 0', ...styleTitle }}>{title}</TitleMinWidth>
      )}
      {children && <Content style={{ ...styleContent }}>{children}</Content>}
    </div>
  );
};
