import { useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import React, { FunctionComponent } from "react";
import { useForm } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { Col, DivSubmit, Row } from "../../components/FlexBox/FlexBox";
import Button from "../../components/FormWithElemets/Button/Button";
import { Form } from "../../components/FormWithElemets/Form.style";
import { useToaster } from "../../context/toaster-provider";
import { BillOfMaterialType } from "../../types/BillOfMaterial";
import SelectInventory from "../SelectCustom/SelectInventory";
import InputForm from "./InputForm";
const UPDATE_BILL_OF_MATERIAL = loader(
    '../../graphql/billOfMaterial/UPDATE_BILL_OF_MATERIAL.graphql'
  );
interface ItemInputsUpdateProps {
    item?: BillOfMaterialType;
    setItemId?: Function;
  }
  const UpdateBillOfMaterialForm: FunctionComponent<ItemInputsUpdateProps> = ({
    item,
    setItemId,
  }) => {
    const { control, errors, handleSubmit,register } = useForm({
      defaultValues: {
        billOfMaterialId: [
          item?.name
            ? { id: item.name, label: item.name }
            : { id: item.inventory.id, label: item.inventory.name },
        ],
        revisionNo:item?.revisionNo||'',
        artNo: item?.artNo || ''
      },
    });
    const { showToaster } = useToaster();
    // const { select } = SelectInventory({
    //   name: 'billOfMaterialId',
    //   control,
    //   errors,
    //   required: true,
    //   creatable: true,
    //   disabled: false,
    // });
    const [update] = useMutation(UPDATE_BILL_OF_MATERIAL);
    const onSubmit = ({ billOfMaterialId,revisionNo,artNo }) => {
      const nameOrInventoryId =
        billOfMaterialId[0].id === billOfMaterialId[0].label
          ? { name: billOfMaterialId[0].id }
          : { inventoryId: billOfMaterialId[0].id };
  
      update({
        variables: {
          id: item.id,
          ...nameOrInventoryId,
          draft: true,
          revisionNo:revisionNo,
          artNo:artNo,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        setItemId(null);
      });
    };
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Row middle="xs">
         <InputForm control={control} errors={errors} register={register}/>
          <Col xs>
            <DivSubmit>
              <Button style={{marginTop: '30px'}} type="button" onClick={() => setItemId(null)}>
                <FormattedMessage id="button.close" />
              </Button>
              <Button style={{marginTop: '30px'}} type="submit">
                <FormattedMessage id="button.update" />
              </Button>
            </DivSubmit>
          </Col>
        </Row>
      </Form>
    );
  };
  
  export default UpdateBillOfMaterialForm