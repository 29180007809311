import React, { FunctionComponent } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { Form } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { Warehouse } from '../../types/WareHouse';

const ADD_WAREHOUSE = loader('../../graphql/warehouse/ADD_WAREHOUSE.graphql');
const GET_WAREHOUSES = loader('../../graphql/warehouse/GET_WAREHOUSES.graphql');
const UPDATE_WAREHOUSE = loader('../../graphql/warehouse/UPDATE_WAREHOUSE.graphql');
interface ListProps {
  data?: Warehouse;
  setItemId?: Function;
}
const WareHouseForm: FunctionComponent<ListProps> = ({ data, setItemId }) => {
  const { showToaster } = useToaster();
  const intl = useIntl();
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: data ? data.name : '',
    },
  });
  const onSubmit = ({ name }) => {
    data
      ? update({
          variables: {
            id: data.id,
            name: name,
          },
        }).then(() => {
          showToaster('updated', 'positive');
          setItemId(null);
        })
      : add({
          variables: { name: name },
        }).then(() => {
          showToaster('created', 'positive');
          setValue('name', '');
        });
  };
  const [add] = useMutation(ADD_WAREHOUSE, {
    update(cache, { data: { createWarehouse } }) {
      const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
      cache.writeQuery({
        query: GET_WAREHOUSES,
        data: { warehouses: warehouses.concat(createWarehouse) },
      });
    },
  });
  const [update] = useMutation(UPDATE_WAREHOUSE, {
    update(cache, { data: { updateWarehouse } }) {
      const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
      const newWarehouses = warehouses.map((item) => {
        if (updateWarehouse.id === item.id) {
          return {
            ...updateWarehouse,
          };
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_WAREHOUSES,
        data: { warehouses: newWarehouses },
      });
    },
  });
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Row style={{ margin: 0, padding: 0 }}>
        <Col md={9}>
          <FormControl error={errors.name ? 'Please input a valid Warehouse' : null}>
            <Input
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              placeholder={intl.formatMessage({ id: "warehouse.name" })}
              error={errors.name ? true : false}
              overrides={errors.name ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
        </Col>
        <Col md={3}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => (data ? setItemId(null) : setValue('name', ''))}
            >
              <FormattedMessage id={data ? 'button.close' : 'button.clean'} />
            </Button>
            <Button type="submit" >
              <FormattedMessage id={data ? 'button.update' : 'button.create'} />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default WareHouseForm;
