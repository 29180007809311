import React, { FunctionComponent, useCallback, useEffect } from 'react';
import  FormControl  from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { CardTitle, Card } from '../../components/FormWithElemets/Card.style';
import { handleAddProductCategory, handleUpdateProductCategory } from './GraphqlFunction';

const ADD_PRODUCT_CATEGORY = loader('../../graphql/product/ADD_PRODUCT_CATEGORY.graphql');
const UPDATE_PRODUCT_CATEGORY = loader(
  '../../graphql/product/UPDATE_PRODUCT_CATEGORY.graphql'
);
const ProductCategoryForm:FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name }) => {
    dataDispatch
      ? updateProductCategory({
          variables: {
            id: dataDispatch.id,
            name: name,
          },
        })
      : addProductCategory({
          variables: {
            name: name,
          },
        });
  };
  const [addProductCategory] = useMutation(ADD_PRODUCT_CATEGORY, {
    update(cache, { data: { createProductCategory } }) {
      handleAddProductCategory(cache,createProductCategory)
      closeDrawer();
    },
  });
  const [updateProductCategory] = useMutation(UPDATE_PRODUCT_CATEGORY, {
    update(cache,{data:{updateProductCategory}}) {
      handleUpdateProductCategory(cache,updateProductCategory)
      closeDrawer();
    },
  });
  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id={dataDispatch ? 'product_category.update' : 'product_category.create'}
          />
        }
      ></CardTitle>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="product_category.name" />}
          error={errors.name ? 'Please input a valid Category' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default ProductCategoryForm;
