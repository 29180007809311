import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { Shifts } from '../../types/Shift';
const GET_SHIFTS = loader('../../graphql/shift/GET_SHIFTS.graphql');
interface ListProps {
  control: any;
  errors?:any;
  required?: boolean;
}
const SelectShift: FunctionComponent<ListProps> = ({ control, required = true,errors }) => {
  const { data } = useQuery<Shifts>(GET_SHIFTS);
  return (
    <FormControl label={<FormattedMessage id="shift.name" />}  
    error={
      errors && errors?.shiftId ? 'Please Select a valid Shift' : null
    }
    >
      <Select
        data={data && data.shifts}
        controller={{
          name: 'shiftId',
          rules: { required: required },
          openOnClick: false,
          control,
          creatable: false,
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectShift;
