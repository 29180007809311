import React, { FunctionComponent, useEffect, useState } from 'react';

interface HeadersProps {
  formNameTR: string;
  formNameEN: string;
}
const Headers: FunctionComponent<HeadersProps> = ({ formNameEN, formNameTR }) => {
  const subdomain = window.location.host.split('.')[0];
  const [companyName, setCompanyName] = useState('');
  useEffect(() => {
    const _companyName =
      subdomain === 'app' || subdomain === 'localhost:3000'
        ? ms
        : subdomain
        ? ceren
        : 'No Company';
    setCompanyName(_companyName);
  }, [subdomain]);
  return (
    <div className="grid grid-cols-12 print:grid-cols-12 border-b divide-x border-gray-200">
      <div className="lg:col-span-3 md:col-span-4 col-span-12 flex  print:col-span-3">
        <p className="text-center self-center">{companyName}</p>
      </div>
      <div className="lg:col-span-7 md:col-span-6 col-span-12 print:col-span-7  flex flex-wrap content-center">
        <p className="text-2xl  text-center font-bold w-full">{formNameTR}</p>
        <p className="text-2xl  text-center font-bold w-full">{formNameEN}</p>
      </div>
      <div className="lg:col-span-2 md:col-span-2 col-span-12 print:col-span-2  text-xs">
        <div className="flex border-b">
          <div className="flex-1 border-r">1</div>
          <div className="flex-1  text-center">1</div>
        </div>
        <div className="flex border-b">
          <div className="flex-1 border-r">1</div>
          <div className="flex-1 text-center">1</div>
        </div>
        <div className="flex border-b">
          <div className="flex-1 border-r">1</div>
          <div className="flex-1 text-center">1</div>
        </div>
        <div className="flex border-b">
          <div className="flex-1 border-r">1</div>
          <div className="flex-1 text-center">1</div>
        </div>
        <div className="flex">
          <div className="flex-1 border-r">1</div>
          <div className="flex-1 text-center">1</div>
        </div>
      </div>
    </div>
  );
};
export default Headers;
export const ceren = 'CEREN OTOMAT METAL İşleme San. ve Tic. Ltd. Şti'; 
export const ms = 'MS MEKANİK SANATLAR San. ve Tic. Ltd. Şti';
