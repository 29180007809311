import React, { FunctionComponent, useCallback, useState } from 'react';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { Negative, Positive } from '../../../components/General/NegativePositive';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Row, Col } from '../../../components/FlexBox/FlexBox';
import { useDrawerDispatch, useDrawerState } from '../../../context/DrawerContext';
import { useToaster } from '../../../context/toaster-provider';
import { RadioGroup, ALIGN, Radio } from 'baseui/radio';
import {
  Card,
  CardTitle,
  CardBody,
} from '../../../components/FormWithElemets/Card.style';
import {
  handleAddMeasurementRule,
  handleUpdateMeasurementRule,
} from '../GraphqlFunction';
import SelectMeasurementType from '../../SelectCustom/SelectMeasurementType';
import PieceOrTiming from './PieceOrTiming';
import BooleanRule from './BooleanRule';
import RangeRule from './RangeRule';
import NumericRule from './NumericRule';

const GET_MEASUREMENT_UNITS = loader(
  '../../../graphql/product/measurementRule/GET_MEASUREMENT_UNITS.graphql'
);

const ADD_MEASUREMENT_RULE = loader(
  '../../../graphql/product/measurementRule/ADD_MEASUREMENT_RULE.graphql'
);
const UPDATE_MEASUREMENT_RULE = loader(
  '../../../graphql/product/measurementRule/UPDATE_MEASUREMENT_RULE.graphql'
);
const MeasurementRuleForm: FunctionComponent = () => {
  const { data: dataMeasurementRuleUnit } = useQuery(GET_MEASUREMENT_UNITS);
  const { showToaster } = useToaster();
  const dispatch = useDrawerDispatch();
  const { dataItem: dataDispatch, measurementRule, cahceSearch } = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const [
    controlBooleanOrNumericOrRange,
    setControlBooleanOrNumericOrRange,
  ] = useState(() => (dataDispatch ? dataDispatch.measurementRuleType : 'BOOLEAN'));
  const [controlPieceOrTiming, setControlPieceOrTiming] = useState(() =>
    dataDispatch?.timingPiece ? 'timingPiece' : 'timingTime'
  );
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues:
      dataDispatch && dataDispatch
        ? {
            machineryAndEquipmentId: dataDispatch.machineryAndEquipment?.id
              ? [
                  {
                    id: dataDispatch.machineryAndEquipment.id,
                    label: dataDispatch.machineryAndEquipment.name,
                  },
                ]
              : undefined,
            measurementTypeId: [
              {
                id: dataDispatch.measurementType.id,
                label: dataDispatch.measurementType.name,
              },
            ],
            name: dataDispatch?.name || '',
            timingPieceTime: dataDispatch?.timingPiece || dataDispatch?.timingTime || '',
            description: dataDispatch?.description || '',
            measurementRuleType: dataDispatch?.measurementRuleType || '',
            tolerance: dataDispatch?.data?.tolerance || '',
            minimumTolerance: dataDispatch?.data?.minimumTolerance || '',
            maximumTolerance: dataDispatch?.data?.maximumTolerance || '',
            trueLabel: dataDispatch?.data?.trueLabel || '',
            falseLabel: dataDispatch?.data?.falseLabel || '',
            measurementRuleUnitId: dataDispatch?.measurementRuleUnit?.id
              ? [
                  {
                    id: dataDispatch?.measurementRuleUnit?.id,
                    label: dataDispatch?.measurementRuleUnit?.name,
                  },
                ]
              : undefined,
            minimumToleranceSign: dataDispatch.data?.minimumToleranceSign,
            maximumToleranceSign: dataDispatch.data?.maximumToleranceSign,
          }
        : {},
  });
  const onSubmit = ({
    machineryAndEquipmentId,
    measurementTypeId,
    measurementRuleUnitId,
    name,
    timingPieceTime,
    description,
    trueLabel,
    falseLabel,
    minimumToleranceSign,
    minimumTolerance,
    maximumToleranceSign,
    maximumTolerance,
    tolerance,
  }) => {
    if (transactionsReasonDispatch === 'Add') {
      add({
        variables: {
          productId: measurementRule.productId,
          productOperationId: measurementRule.productOperationId,
          machineryAndEquipmentId: machineryAndEquipmentId
            ? machineryAndEquipmentId[0]?.id || null
            : null,
          measurementRuleType: controlBooleanOrNumericOrRange,
          measurementTypeId: measurementTypeId[0].id,
          measurementRuleUnitId: measurementRuleUnitId
            ? measurementRuleUnitId[0].id
            : null,
          name: name,
          timingPiece:
            controlPieceOrTiming === 'timingPiece' ? Number(timingPieceTime) : null,
          timingTime:
            controlPieceOrTiming === 'timingTime' ? Number(timingPieceTime) : null,
          description: description,
          data:
            controlBooleanOrNumericOrRange === 'NUMERIC'
              ? // prettier-ignore
                JSON.stringify({
              "tolerance": Number(tolerance),
              "maximumTolerance": Number(maximumTolerance),
              "minimumTolerance": Number(minimumTolerance),
              "minimumToleranceSign": minimumToleranceSign,
              "maximumToleranceSign": maximumToleranceSign,
            })
              : controlBooleanOrNumericOrRange === 'RANGE'
              ? // prettier-ignore
                JSON.stringify({
              "maximumTolerance": Number(maximumTolerance),
              "minimumTolerance": Number(minimumTolerance),
            })
              : controlBooleanOrNumericOrRange === 'BOOLEAN' &&
                // prettier-ignore
                JSON.stringify({
              "trueLabel": trueLabel,
              "falseLabel": falseLabel,
            }),
        },
      }).then(() => showToaster('created', 'positive'));
    } else if (transactionsReasonDispatch === 'Update') {
      update({
        variables: {
          id: dataDispatch?.id,
          machineryAndEquipmentId: machineryAndEquipmentId
            ? machineryAndEquipmentId[0]?.id || null
            : null,
          measurementTypeId: measurementTypeId[0].id,
          measurementRuleUnitId: measurementRuleUnitId
            ? measurementRuleUnitId[0].id
            : null,
          name: name,
          timingPiece:
            controlPieceOrTiming === 'timingPiece' ? Number(timingPieceTime) : null,
          timingTime:
            controlPieceOrTiming === 'timingTime' ? Number(timingPieceTime) : null,
          description: description,
          data:
            controlBooleanOrNumericOrRange === 'NUMERIC'
              ? // prettier-ignore
                JSON.stringify({
                  "tolerance": Number(tolerance),
                  "maximumTolerance": Number(maximumTolerance),
                  "minimumTolerance": Number(minimumTolerance),
                  "minimumToleranceSign": minimumToleranceSign,
                  "maximumToleranceSign": maximumToleranceSign,
                })
              : controlBooleanOrNumericOrRange === 'RANGE'
              ? // prettier-ignore
                JSON.stringify({
                  "maximumTolerance": Number(maximumTolerance),
                  "minimumTolerance": Number(minimumTolerance),
                })
              : controlBooleanOrNumericOrRange === 'BOOLEAN' &&
                // prettier-ignore
                JSON.stringify({
                  "trueLabel": trueLabel,
                  "falseLabel": falseLabel,
                }),
        },
      }).then(() => {
        showToaster('updated', 'positive');
      });
    }
  };
  const [add] = useMutation(ADD_MEASUREMENT_RULE, {
    update(cache, { data: { createMeasurementRule } }) {
      handleAddMeasurementRule(
        cache,
        measurementRule.productId,
        measurementRule.productOperationId,
        createMeasurementRule,
        cahceSearch
      );
      closeDrawer();
    },
  });
  const [update] = useMutation(UPDATE_MEASUREMENT_RULE, {
    update(cache, { data: { updateMeasurementRule } }) {
      handleUpdateMeasurementRule(
        cache,
        measurementRule.productId,
        measurementRule.productOperationId,
        updateMeasurementRule,
        cahceSearch
      );
      closeDrawer();
    },
  });
  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id={dataDispatch ? 'measurement_rule.update' : 'measurement_rule.create'}
          />
        }
      />
      <CardBody style={{ margin: 0 }}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            backgroundColor: 'transparent',
            width: '100%',
            margin: 0,
            padding: 0,
          }}
        >
          <Row bottom="xs" style={{ margin: 0, padding: 0 }}>
            <Col md={5}>
              <FormControl
                label={<FormattedMessage id="measurement_rule.name" />}
                error={errors?.name ? 'Please input a valid Name' : null}
              >
                <Input
                  type="text"
                  size={SIZE.compact}
                  name="name"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.name}
                  overrides={errors.name ? { After: Negative } : { After: Positive }}
                />
              </FormControl>
            </Col>
            <Col md={7}>
              <FormControl
                label={<FormattedMessage id="measurement_rule.description" />}
                error={errors?.description ? 'Please input a valid Name' : null}
              >
                <Input
                  type="text"
                  size={SIZE.compact}
                  name="description"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.description}
                  overrides={
                    errors.description ? { After: Negative } : { After: Positive }
                  }
                />
              </FormControl>
            </Col>
            <SelectMeasurementType
              errors={errors}
              control={control}
              setValue={setValue}
            />
            <Col md={4} style={{ display: 'flex', alignItems: 'center' }}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignContent: 'center',
                }}
              >
                <RadioGroup
                  value={controlPieceOrTiming}
                  onChange={(e) => {
                    setControlPieceOrTiming(e.target.value);
                    setValue('timingPieceTime', '');
                  }}
                  name="pieceOrTime"
                  align={ALIGN.horizontal}
                >
                  <Radio value="timingPiece">
                    <FormattedMessage id="measurement_rule.timingPiece" />
                  </Radio>
                  <Radio value="timingTime">
                    <FormattedMessage id="measurement_rule.timingTime" />
                  </Radio>
                </RadioGroup>
                <PieceOrTiming
                  value={controlPieceOrTiming === 'timingPiece'}
                  register={register}
                  errors={errors}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col md={12} style={{ display: 'flex', justifyContent: 'center' }}>
              <RadioGroup
                value={controlBooleanOrNumericOrRange}
                onChange={(e) => {
                  setControlBooleanOrNumericOrRange(e.target.value);
                }}
                name="booleanOrNumericOrRange"
                align={ALIGN.horizontal}
                disabled={!!dataDispatch}
              >
                <Radio value="BOOLEAN">
                  <FormattedMessage id="measurement_rule.boolean" />
                </Radio>
                <Radio value="NUMERIC">
                  <FormattedMessage id="measurement_rule.numeric" />
                </Radio>
                <Radio value="RANGE">
                  <FormattedMessage id="measurement_rule.range" />
                </Radio>
              </RadioGroup>
            </Col>
          </Row>
          {controlBooleanOrNumericOrRange === 'BOOLEAN' ? (
            <BooleanRule errors={errors} register={register} />
          ) : controlBooleanOrNumericOrRange === 'NUMERIC' ? (
            <NumericRule
              dataMeasurementRuleUnit={dataMeasurementRuleUnit}
              errors={errors}
              register={register}
              control={control}
            />
          ) : (
            controlBooleanOrNumericOrRange === 'RANGE' && (
              <RangeRule
                errors={errors}
                register={register}
                control={control}
                dataMeasurementRuleUnit={dataMeasurementRuleUnit}
              />
            )
          )}
          <Row end="xs" style={{ margin: 0, padding: 0 }}>
            <Col
              md={4}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'flex-end',
              }}
            >
              <Button
                type="button"
                onClick={closeDrawer}
                style={{ marginBottom: '16px' }}
              >
                <FormattedMessage id="button.close" />
              </Button>

              <Button type="submit" style={{ marginBottom: '16px' }}>
                <FormattedMessage id="button.ok" />
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default MeasurementRuleForm;
// interface ListMachineryAndEquipmentOrTypeProps {
//   value: boolean;
//   dataMachineryAndEquipment: MachineryAndEquipments;
//   dataMachineryAndEquipmentType: MachineryAndEquipmentTypes;
//   errors;
//   control;
// }
// export const MachineryAndEquipmentOrType: FunctionComponent<ListMachineryAndEquipmentOrTypeProps> = ({
//   value,
//   dataMachineryAndEquipment,
//   dataMachineryAndEquipmentType,
//   errors,
//   control,
// }) => {
//   return value ? (
//     <Col md={5}>
//       <FormControl
//         label={<FormattedMessage id="measurement_rule.machinery_and_equipment" />}
//         error={
//           errors?.machineryAndEquipmentId
//             ? 'Please input a valid Machinery And Equipment'
//             : null
//         }
//       >
//         <Select
//           data={
//             dataMachineryAndEquipment && dataMachineryAndEquipment.machineryAndEquipments
//           }
//           controller={{
//             name: 'machineryAndEquipmentId',
//             type: TYPE.search,
//             control,
//             rules: { required: true },
//             creatable: false,
//             searchable: true,
//             filterOutSelected: false,
//             error: errors.machineryAndEquipmentId,
//             overrides: errors.machineryAndEquipmentId
//               ? { After: Negative }
//               : { After: Positive },
//             onChange: ([selected]) => {
//               return selected?.option ? selected.value : undefined;
//             },
//           }}
//         />
//       </FormControl>
//     </Col>
//   ) : (
//     <Col md={5}>
//       <FormControl
//         label={<FormattedMessage id="measurement_rule.machinery_and_equipment_type" />}
//         error={
//           errors?.machineryAndEquipmentTypeId
//             ? 'Please input a valid Machinery And Equipment Type'
//             : null
//         }
//       >
//         <Select
//           data={
//             dataMachineryAndEquipmentType &&
//             dataMachineryAndEquipmentType.machineryAndEquipmentTypes
//           }
//           controller={{
//             name: 'machineryAndEquipmentTypeId',
//             type: TYPE.search,
//             control,
//             rules: { required: true },
//             creatable: false,
//             searchable: true,
//             filterOutSelected: false,
//             error: errors.machineryAndEquipmentTypeId,
//             overrides: errors.machineryAndEquipmentTypeId
//               ? { After: Negative }
//               : { After: Positive },
//             onChange: ([selected]) => {
//               return selected?.option ? selected.value : undefined;
//             },
//           }}
//         />
//       </FormControl>
//     </Col>
//   );
// };
