import { useEffect, useState } from 'react';
import {
  OfferMaterialSquare,
  Width,
  Length,
  RawMaterialId,
  RawMaterialName,
  ByPieces,
  PieceLength,
  UnmachinablePartLength,
  CuttingToolDiam,
  Qty,
  UnitPrice,
  Mass,
  ProcessingLength,
  fromOfferResult,
  MaterialLength,
  MaterialWeight,
  MaterialPrice,
} from '../../../types/Calc';
import {
  cm3ToMm3,
  getMaterialLength,
  getMaterialPrice,
  getPerUnit,
  getUnitWeighSquare,
  mm2ToCm2,
} from '../Common';

export const useCalcOfferMaterialSquare = (initial: OfferMaterialSquare) => {
  const [offerMaterialSquare, setState] = useState<OfferMaterialSquare>(initial);
  const [offerMaterialSquareResult, setStateResult] = useState<fromOfferResult>({
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    oneUnitWeight:0
  });
  function setWidth(width: Width) {
    setState((prev) => {
      return {
        ...prev,
        width: width,
      };
    });
    console.log('Wall Thickness Setted');
  }

  function setLength(length: Length) {
    setState((prev) => {
      return {
        ...prev,
        length: length,
      };
    });
    console.log('Wall Thickness Setted');
  }

  function setMaterial(rawMaterialId: RawMaterialId, rawMaterialName: RawMaterialName) {
    setState((prev) => {
      return {
        ...prev,
        rawMaterialId: rawMaterialId,
        rawMaterialName: rawMaterialName,
      };
    });
    console.log('Material Setted');
  }

  function setProductionStyle(byPieces: ByPieces) {
    setState((prev) => {
      return {
        ...prev,
        byPieces: byPieces,
      };
    });
    console.log('Production Style Setted');
  }
  function setPieceLength(pieceLength: PieceLength) {
    setState((prev) => {
      return {
        ...prev,
        pieceLength: pieceLength,
      };
    });
    console.log('Piece Length Setted');
  }
  function setUnmachinablePartLength(unmachinablePartLength: UnmachinablePartLength) {
    setState((prev) => {
      return {
        ...prev,
        unmachinablePartLength: unmachinablePartLength,
      };
    });
    console.log('Unmachinable Part Length Setted');
  }

  function setCuttingToolDiam(cuttingToolDiam: CuttingToolDiam) {
    setState((prev) => {
      return {
        ...prev,
        cuttingToolDiam: cuttingToolDiam,
      };
    });
    console.log('Cutting Tool Diam Setted');
  }

  function setQty(qty: Qty) {
    setState((prev) => {
      return {
        ...prev,
        qty: qty,
      };
    });
    console.log('Cutting Tool Diam Setted');
  }

  function setUnitPrice(unitPrice: UnitPrice) {
    setState((prev) => {
      return {
        ...prev,
        unitPrice: unitPrice,
      };
    });
    console.log('Cutting Tool Diam Setted');
  }

  function setMass(mass: Mass) {
    setState((prev) => {
      return {
        ...prev,
        mass: mass,
      };
    });
    console.log('Cutting Tool Diam Setted');
  }

  function setProcessingLength(processingLength: ProcessingLength) {
    setState((prev) => {
      return {
        ...prev,
        processingLength: processingLength,
      };
    });
    console.log('Processing Length Setted');
  }
  useEffect(() => {
    const perUnit: number = getPerUnit(
      offerMaterialSquare.processingLength,
      offerMaterialSquare.unmachinablePartLength,
      offerMaterialSquare.pieceLength,
      offerMaterialSquare.cuttingToolDiam
    );
    const materialLength: MaterialLength = getMaterialLength(
      offerMaterialSquare.qty,
      perUnit,
      offerMaterialSquare.processingLength
    );
    const unitWeight: number = getUnitWeighSquare(
      offerMaterialSquare.mass,
      offerMaterialSquare.width,
      offerMaterialSquare.innerShape,
      offerMaterialSquare.innerDiameterOrWidth,
      offerMaterialSquare.innerHeight,
      materialLength
    );
    const oneUnitWeight:number = getUnitWeighSquare(
      offerMaterialSquare.mass,
      offerMaterialSquare.width,
      offerMaterialSquare.innerShape,
      offerMaterialSquare.innerDiameterOrWidth,
      offerMaterialSquare.innerHeight,
      offerMaterialSquare.pieceLength
    )
    const materialWeight: MaterialWeight = mm2ToCm2(unitWeight);

    const materialPrice: MaterialPrice = getMaterialPrice(
      materialWeight,
      offerMaterialSquare.unitPrice
    );
    setStateResult(() => {
      return {
        materialWeight: +materialWeight.toFixed(3),
        materialPrice: +materialPrice.toFixed(3),
        materialLength: +materialLength.toFixed(3),
        oneUnitWeight: mm2ToCm2(oneUnitWeight)
      };
    });
  }, [offerMaterialSquare]);
  return {
    offerMaterialSquare,
    offerMaterialSquareResult,
    setWidth,
    setLength,
    setMaterial,
    setProductionStyle,
    setPieceLength,
    setUnmachinablePartLength,
    setCuttingToolDiam,
    setQty,
    setUnitPrice,
    setMass,
    setProcessingLength,
    setState,
  };
};
