import React, { ReactNode } from "react";
import { FunctionComponent } from "react";

interface CardBodyProps{
    children:ReactNode
}
const CardBody: FunctionComponent<CardBodyProps> = ({children}) => {
  return <div className="bg-white px-2 py-1">
      {children}
  </div>;
};
export default CardBody