import React, { FunctionComponent } from 'react';
import { FormControl as BaseFormControl } from 'baseui/form-control';

type ListProps = {
  label?: any;
  error?: string;
  children?: any;
  overrides?: any;
};
const FormControl: FunctionComponent<ListProps> = ({
  label,
  error,
  children,
  overrides,
}) => {
  return (
    <BaseFormControl
      label={label}
      error={error}
      overrides={{
        Label: {
          style: () => {
            return {
              color: '#454b68',
            };
          },
        },
        ...overrides,
      }}
    >
      {children}
    </BaseFormControl>
  );
};

export default FormControl;
