import React, { FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Col } from '../../components/FlexBox/FlexBox';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { Input, SIZE } from 'baseui/input';
const GET_PRODUCT_CATEGORIES = loader(
  '../../graphql/product/GET_PRODUCT_CATEGORIES.graphql'
);
const GET_AQL = loader('../../graphql/aql/GET_AQL.graphql');

interface ListProps {
  errors: any;
  control: any;
  register?:any;
  setCreateType: Function;
  col?: number;
}
const ProductCategoryAndSelect: FunctionComponent<ListProps> = ({
  errors,
  control,
  register,
  setCreateType,
  col,
}) => {
  const { data: dataProductCategory } = useQuery(GET_PRODUCT_CATEGORIES);
  return (
    <>
     {register && <Col md={col || 2}>
        <FormControl
          label={<FormattedMessage id="inventory.revisionNo" />}
          error={errors.revisionNo ? 'Please input a valid Revision No' : null}
        >
          <Input
            size={SIZE.compact}
            name="revisionNo"
            inputRef={register({ 
              required: true,
            })}
            error={errors.revisionNo}
            overrides={errors.revisionNo ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>}
      <Col md={col || 4}>
        <FormControl
          label={<FormattedMessage id="product_category.name" />}
          error={errors.productCategory ? 'Please select a valid Product Category' : null}
        >
          <Select
            data={dataProductCategory && dataProductCategory.productCategories}
            controller={{
              name: 'productCategory',
              type: TYPE.search,
              control,
              rules: { required: true },
              creatable: true,
              searchable: true,
              filterOutSelected: false,
              error: errors.productCategory,
              overrides: errors.productCategory
                ? { After: Negative }
                : { After: Positive },
              onChange: ([selected]) => {
                setCreateType(selected.option?.isCreatable || false);
                return selected.option ? selected.value : undefined;
              },
            }}
          />
        </FormControl>
      </Col>
      <Col md={col || 2}>
        <AqlSelect control={control} errors={errors} />
      </Col>
    </>
  );
};
export default ProductCategoryAndSelect;
interface ListAqlSelectProsp {
  control: any;
  errors: any;
}
export const AqlSelect: FunctionComponent<ListAqlSelectProsp> = ({ control, errors }) => {
  const { data: dataAql } = useQuery(GET_AQL);
  return (
    <FormControl label={<FormattedMessage id="product.aql" />}>
      <Select
        data={dataAql && dataAql.aqls}
        labelValue="code"
        controller={{
          name: 'aql',
          type: TYPE.search,
          control,
          rules: { required: false },
          creatable: false,
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
