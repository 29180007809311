import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { Col, RowCustom } from '../../../components/FlexBox/FlexBox';
import { Form } from '../../../components/FormWithElemets/Form.style';
import { useToaster } from '../../../context/toaster-provider';
import { User } from '../../../types/User';
import SelectUser from '../../SelectCustom/SelectUser';

const ASSIGN_OFFER_TO_USER = loader(
  '../../../graphql/offer/ASSIGN_OFFER_TO_USER.graphql'
);

interface AssignmentToPersonProps {
  item?: User;
  offerId: string;
}
const AssignOfferToPerson: FunctionComponent<AssignmentToPersonProps> = ({
  item,
  offerId,
}) => {
  const { showToaster } = useToaster();
  const { control, errors } = useForm({
    defaultValues: item
      ? {
        userId: [{ id: item.id, label: item.name }],
      }
      : {},
  });
  const [updateOffer] = useMutation(ASSIGN_OFFER_TO_USER);
  const onSubmit = (userId?: UserId[]) => {
    userId &&
      userId.length > 0 &&
      updateOffer({
        variables: {
          id: offerId,
          userId: userId[0].id,
        },
      }).then(() => showToaster('updated', 'positive'));
  };
  return (
    <Form style={{ padding: 0, margin: 0, height: 'auto' }}>
      <RowCustom>
        <Col sm={11}>
          <SelectUser
            onChange={(userId: UserId[]) => onSubmit(userId)}
            errors={errors}
            control={control}
            name="assignOfferToPerson"
          />
        </Col>
      </RowCustom>
    </Form>
  );
};
type UserId = {
  id: string;
  label: string;
};
export default AssignOfferToPerson;
