import React, { FunctionComponent, useContext } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { Form } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage, useIntl } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import {
    handleAddWareHouseRow, handleUpdateWareHouseRow,
} from '../WareHouse/GraphqlFunction';
import { ListContextType, WarehouseContext } from '../WarehouseLevel/List';
import { WarehouseRow } from '../../types/WarehouseRow';


const ADD_WAREHOUSE_ROW = loader(
  '../../graphql/warehouseRow/ADD_WAREHOUSE_ROW.graphql'
);
const UPDATE_WAREHOUSE_ROW = loader(
  '../../graphql/warehouseRow/UPDATE_WAREHOUSE_ROW.graphql'
);
interface ListProps {
  data?: WarehouseRow;
  warehouseId: string;
  setItemId?: Function;
}
const WarehouseLevelForm: FunctionComponent<ListProps> = ({
  data,
  setItemId,
  warehouseId,
}) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const { warehouse } = useContext<ListContextType>(WarehouseContext);
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: data ? data.name : '',
    },
  });
  const onSubmit = ({ name }) => {
    data
      ? updateWarehouseRow({
          variables: {
            id: data.id,
            name: name,
          },
        }).then(() => {
          showToaster('updated', 'positive');
          setItemId(null);
        })
      : addWarehouseRow({
          variables: { name: name, warehouseSectionId: warehouse.warehouseSectionId },
        }).then(() => {
          showToaster('created', 'positive');
          setValue('name', '');
        });
  };
  const [addWarehouseRow] = useMutation(ADD_WAREHOUSE_ROW, {
    update(cache, { data: { createWarehouseRow } }) {
      handleAddWareHouseRow(cache, createWarehouseRow, warehouseId,warehouse);
    },
  });
  const [updateWarehouseRow] = useMutation(UPDATE_WAREHOUSE_ROW, {
    update(cache, { data: { updateWarehouseRow } }) {
      handleUpdateWareHouseRow(cache, updateWarehouseRow, warehouseId,warehouse);
    },
  });
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      {' '}
      <Row>
        <Col sm={9}>
          <FormControl
            error={errors.name ? 'Please input a valid Warehouse Level' : null}
          >
            <Input
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              placeholder={intl.formatMessage({ id: "warehouse_row.name" })}
              error={!!errors.name}
              overrides={errors.name ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
        </Col>

        <Col md={3}>
          <div
            style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '16px' }}
          >
            <Button onClick={() => (data ? setItemId(null) : setValue('name', ''))}>
              <FormattedMessage id={data ? 'button.close' : 'button.clean'} />
            </Button>
            <Button type="submit">
              <FormattedMessage id={data ? 'button.update' : 'button.create'} />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default WarehouseLevelForm;
