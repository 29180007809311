import { Input, SIZE } from 'baseui/input';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { inputDecimal } from '../../Functions/Utilities';
import SelectCompany from '../../SelectCustom/SelectCompany';
import SelectInventory from '../../SelectCustom/SelectInventory';
import SelectInventoryType from '../../SelectCustom/SelectInventoryType';
import { MaterialItem } from './ListBillOfMaterialItem';

interface InputFormProps {
  item: MaterialItem;
  register: any;
  errors: any;
  setValue?: any;
  control: any;
  watch: any;
  handleAddOrUpdateItem?: Function | undefined;
  handleResetForm: Function;
}
const InputForm: FunctionComponent<InputFormProps> = ({
  item,
  control,
  errors,
  setValue,
  register,
  watch,
  handleResetForm,
  handleAddOrUpdateItem,
}) => {
  const { materialItemInventoryId,inventoryTypeId } = watch();
  const { select, inventoryType } = SelectInventory({
    name: 'materialItemInventoryId',
    defaultValue: materialItemInventoryId,
    control,
    errors,
    required: false,
    creatable: true,
    disabled: false,
  });
  const addButtonActive = useMemo(() => {
    const result =
      materialItemInventoryId !== undefined && inventoryTypeId !== undefined;
    return !result;
  }, [materialItemInventoryId, inventoryTypeId]);
  useEffect(() => {
    setValue("inventoryTypeId", inventoryType)
  }, [inventoryType])
  const inventoryTypeDisabled = useMemo(() => {
    if (materialItemInventoryId && materialItemInventoryId[0]) {
      const result = materialItemInventoryId[0].id === materialItemInventoryId[0].label
      return !result
    } else {
      return false
    }
  }, [materialItemInventoryId])
  const unitPriceDisabled = useMemo(() => {
    if (inventoryTypeId && inventoryTypeId[0]) {
      const result = "d5b8e917-9bc5-42e6-a23a-b721e72972a1" === inventoryTypeId[0].id ||
      "Hammadde" === inventoryTypeId[0].label
      return result
    } else {
      return false 
    }
  }, [inventoryTypeId])
  return (
    <RowCustom>
      <Col xs={12} md={7} lg={4}>
        {select}
      </Col>
      <Col xs={12} md={5} lg={4}>
        <SelectInventoryType control={control} errors={errors} disabled={inventoryTypeDisabled} />
      </Col>
      <Col sm={6} md={4} lg={2}>
        <FormControl label={<FormattedMessage id="materialItem.partNumber" />}>
          <Input
            type="text"
            size={SIZE.compact}
            name="partNumber"
            inputRef={register}
            clearOnEscape
          />
        </FormControl>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <FormControl label={<FormattedMessage id="materialItem.quantity" />}>
          <Input
            type="text"
            size={SIZE.compact}
            name="quantity"
            disabled={unitPriceDisabled}
            inputRef={register}
            onChange={(event) => {
              setValue('quantity', inputDecimal({ event }));
            }}
            clearOnEscape
          />
        </FormControl>
      </Col>
      {/* <Col  sm={9} md={5} lg={3}>
        <SelectCompany
          name={'vendor'}
          control={control}
          errors={errors}
          update={false}
          creatable={false}
        />
      </Col> */}
      <ColButton xs>
        <Button
          type="button"
          // disabled={cleanButtonActive}
          onClick={() => {
            handleResetForm();
          }}
          style={{
            marginTop: '30px',
            marginBottom: '15px',
          }}
        >
          <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
        </Button>
        <Button
          type={'button'}
          onClick={() => handleAddOrUpdateItem()}
          disabled={addButtonActive}
          style={{
            marginTop: '30px',
            marginBottom: '15px',
          }}
        >
          <FormattedMessage id={item ? 'button.update' : 'button.add'} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};
export default InputForm;
