import React, { FunctionComponent, useContext, useMemo, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Offer } from '../../../types/Offer';
import useCompanyInformation from '../../TailWinduiComponent/useCompanyInformation';
import { ComponentStatusContext } from '../Offer';
import ListOperationSales from './OperationSales/ListOperationSales';
import Buttons from './PreviewCommon/Buttons';
import Card from '../../TailWinduiHTML/Card/Card';
import CardBody from '../../TailWinduiHTML/Card/CardBody';
import CardFooter from './PreviewCommon/CardFooterCustom';
import CardTitle from '../../TailWinduiHTML/Card/CardTitle';
import CompanyInformation from '../../TailWinduiComponent/CompanyInformation';
import Headers from '../../TailWinduiComponent/Headers';
import ListProductSales from './ProductSales/ListProductSales';
import PreviewShowPrint from './SubcontractSales/Preview/PreviewShowPrint';

const InOfferStage: FunctionComponent = () => {
  const {
    componentStatus: { data },
    dispatchComponentStatus,
  } = useContext(ComponentStatusContext);
  const [preview, setPreview] = useState(false);
  const {
    id,
    company,
    serial,
    subcontractSalesOffer,
    productSalesOffer,
    operationSalesOffer,
    offerTransport,
    transportIncluded,
    assigned,
    note
  }: Offer = data;
  const { companyInformation, currency } = useCompanyInformation({
    companyId: company?.id || null,
  });
  const currentSalesOffer = useMemo(
    () =>
      subcontractSalesOffer?.length
        ? 'update_subContract'
        : productSalesOffer?.length
        ? 'update_product'
        : operationSalesOffer?.length && 'update_operation',
    [subcontractSalesOffer, productSalesOffer, operationSalesOffer]
  );
  const componentRef = useRef<HTMLDivElement|any>(null);
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <Card ref={componentRef}>
      <CardTitle title={'offer.' + currentSalesOffer}>
        <Buttons
          setPreview={setPreview}
          offerId={id}
          preview={preview}
          handlePrint={handlePrint}
          dispatchComponentStatus={dispatchComponentStatus}
        />
      </CardTitle>
      <CardBody>
        <Headers formNameEN={"BID PRICE"} formNameTR={"FİYAT TEKLİF FORMU"}/>
        <CompanyInformation company={company} assigned={assigned} no={serial}/>
        <div className="my-20" />
        {currentSalesOffer === 'update_subContract' && (
          <PreviewShowPrint
            offerId={id}
            offerTransport={offerTransport}
            transportIncluded={transportIncluded}
            preview={preview}
          />
        )}
        {currentSalesOffer === 'update_product' && (
          <ListProductSales
            offerId={id}
            offerTransport={offerTransport}
            transportIncluded={transportIncluded}
            preview={preview}
          />
        )}
        {currentSalesOffer === 'update_operation' && (
          <ListOperationSales
            offerId={id}
            offerTransport={offerTransport}
            transportIncluded={transportIncluded}
            preview={preview}
          />
        )}
      </CardBody>
      <CardFooter offerId={id}  note={note}/>
    </Card>
  );
};
export default InOfferStage;
