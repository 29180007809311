import { faArrowDown, faArrowUp } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {  FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import {  CardBody } from '../../../components/FormWithElemets/Card.style';
import { Label } from '../../../components/FormWithElemets/Label.style';
import { PurchaseOffer } from '../../../types/Purchase';
import ListPurchaseMaterialOffer from './PurchaseMaterialOffer/ListPurchaseMaterialOffer';
import ListPurchaseOperationOffer from './PurchaseOperationOffer/ListPurchaseOperationOffer';
interface ListPurchaseOfferProps {
    items: PurchaseOffer,
}
const ListPurchaseOffer: FunctionComponent<ListPurchaseOfferProps> = ({ items }) => {
    const [showComponent, setShowComponent] = useState(true);
    return (
        <CardBody style={{ marginLeft: '20px', width: 'auto' }}>
            <Label style={{ borderBottom: '1px solid' + COLOR.grey }} title={<FormattedMessage id={'billOfMaterial.purhaseOffer'} />}>
                <Button
                    onClick={() => {
                        setShowComponent(prev => !prev);
                    }}
                >
                    <FontAwesomeIcon icon={showComponent ? faArrowDown : faArrowUp} />
                </Button>
            </Label>
            {showComponent &&  
            <div style={{ marginLeft: '10px'}}>
                <ListPurchaseMaterialOffer
                    items={items?.purchaseMaterialOffer || []}
                />
                <ListPurchaseOperationOffer
                    items={items?.purchaseOperationOffer || []}
                />
            </div>}
        </CardBody>
    );
};
export default ListPurchaseOffer;
