import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../../components/Input/Input';
import { useToaster } from '../../../../context/toaster-provider';
import DateCustom from '../../../DateCustom/DateCustom';
import { handleDate, inputDecimal } from '../../../Functions/Utilities';
import SelectOperation from '../../../SelectCustom/SelectOperation';
import { ItemOperation } from '../AddPurchaseOfferForm';

interface ItemFormProps {
  item?: ItemOperation;
  itemIndex?: number;
  setItemAddShow?: Function;
  setItemIndex?: Function;
  dispatchItems: Function;
}
const PurchaseOperationForm: FunctionComponent<ItemFormProps> = ({
  item,
  setItemIndex,
  itemIndex = null,
  setItemAddShow,
  dispatchItems,
}) => {
  const { control, errors, setValue, register, getValues } = useForm({
    defaultValues: item
      ? {
          operationId: item.operationId,
          qty: item.qty,
          estimatedDate: [new Date(item?.estimatedDate || new Date())],
        }
      : {
          operationId: undefined,
          qty: 0,
          estimatedDate: [new Date(new Date())],
        },
  });
  const { showToaster } = useToaster();
  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('operationId', undefined);
      setValue('qty', 0);
      setValue('estimatedDate', [new Date()]);
      setItemAddShow(false);
    }
  }, [setItemAddShow, setItemIndex, setValue, item]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { operationId, estimatedDate, qty } = getValues();
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            operationId: operationId,
            estimatedDate: handleDate(estimatedDate[0]).toISOString(),
            qty: +qty,
          },
        });
        showToaster('created', 'positive');
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            operationId: operationId,
            estimatedDate: handleDate(estimatedDate[0]).toISOString(),
            qty: +qty,
          },
          index: itemIndex,
        });
        showToaster('updated', 'positive');
      }
      handleResetForm();
  }, [dispatchItems,itemIndex,handleResetForm,getValues,showToaster]);

  return (
    <RowCustom bottom="xs">
      <Col xs={4}>
        <SelectOperation control={control} />
      </Col>
      <Col xs={2}>
        <DateCustom errors={errors} control={control} name="estimatedDate" />
      </Col>
      <Col xs={12} md={6} lg={4}>
        <FormControl label={<FormattedMessage id="purchaseOffer.qty" />}>
          <Input
            type="text"
            size={SIZE.compact}
            name="qty"
            inputRef={register({
              required: false,
            })}
            onChange={(event: any) => {
              setValue('qty', inputDecimal({ event }));
            }}
            clearOnEscape
          />
        </FormControl>
      </Col>
      <ColButton xs>
        <Button
          style={{
            marginBottom: '16px',
          }}
          type="button"
          onClick={handleResetForm}
        >
          <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
        </Button>
        <Button
          style={{
            marginBottom: '16px',
          }}
          onClick={handleAddOrUpdateItem}
          type="button"
        >
          <FormattedMessage id={item ? 'button.update' : 'button.add'} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};

export default PurchaseOperationForm;
