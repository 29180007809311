import React, { FunctionComponent, useCallback, useState } from 'react';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../context/toaster-provider';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import {
  Card,
  CardBody,
  CardTitle,
} from '../../../components/FormWithElemets/Card.style';
import { useDrawerState, useDrawerDispatch } from '../../../context/DrawerContext';
import {
  handleAddMachineMeasurementRecord,
  handleUpdateMachineMeasurementRecord,
} from '../GraphqlFunction';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import { ShopFloorOrderStatuses } from '../../../types/ShopFloorOrderStatus';
import TechnicalImagesList from './TechnicalImagesList';
import { measurementTrueFalse } from '../Measurement/LastTenValues';
import { inputDecimal } from '../../Functions/Utilities';
const GET_SHOP_FLOOR_ORDER_STATUS = loader(
  '../../../graphql/shopFloorOrderStatus/GET_SHOP_FLOOR_ORDER_STATUS.graphql'
);
const CHANGE_SHOP_FLOOR_ORDER_STATUS = loader(
  '../../../graphql/shopFloorOrderStatus/CHANGE_SHOP_FLOOR_ORDER_STATUS.graphql'
);
const UPDATE_MACHINE_MEASUREMENT_RECORD = loader(
  '../../../graphql/machineMeasurementRecord/UPDATE_MACHINE_MEASUREMENT_RECORD.graphql'
);
const ADD_MACHINE_MEASUREMENT_RECORD = loader(
  '../../../graphql/machineMeasurementRecord/ADD_MACHINE_MEASUREMENT_RECORD.graphql'
);

const MachineMeasurementForm: FunctionComponent = () => {
  const { showToaster } = useToaster();
  const { data: dataShopFloorOrderStatus } = useQuery<ShopFloorOrderStatuses>(
    GET_SHOP_FLOOR_ORDER_STATUS
  );
  const [technicalImage, setTechnicalImage] = useState(null);
  const {
    data: dataDispatch,
    productId,
    shopFloorOrderId,
    machineryAndEquipmentId,
    shopFloorId,
  } = useDrawerState<any>('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const dispatch = useDrawerDispatch();

  const { register, handleSubmit, errors, control, setValue } = useForm({
    defaultValues: {
      measurement: dataDispatch?.data?.value || '',
    },
  });
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const onSubmit = ({ measurement }) => {
    if (transactionsReasonDispatch === 'machineMeasurementUpdate') {
      update({
        variables: {
          id: dataDispatch.id,
          // prettier-ignore
          data: JSON.stringify({ "value": dataDispatch?.measurementRuleType === 'BOOLEAN' ? Boolean(+measurement) : Number(measurement) }),
        },
      }).then(() => {
        showToaster('updated', 'positive');
      });
    } else {
      add({
        variables: {
          // prettier-ignore
          data: JSON.stringify({ "value": dataDispatch?.measurementRuleType === 'BOOLEAN' ? Boolean(+measurement) : Number(measurement) }),
          measurementRuleId: dataDispatch?.id,
          shopFloorOrderId: shopFloorOrderId,
        },
      }).then(({ data }) => {
        showToaster('created', 'positive');
        measurementTrueFalse({
          value: data.createMachineMeasurementRecord.data.value,
          ruleValue: dataDispatch?.rule,
          measurementRuleType: dataDispatch?.measurementRuleType,
        }) &&
          updateShoFloorOrderStatus({
            variables: {
              id: shopFloorOrderId,
              shopFloorOrderStatusId: dataShopFloorOrderStatus.shopFloorOrderStatuses.find(
                (item) => item.code === 'SHOP_FLOOR_ORDER_QUALITY_CONTROL_REQUIRED'
              ).id,
              shopFloorOrderStopReasonId: null,
            },
          }).then(
            ({
              data: {
                changeShopFloorOrderStatus: { shopFloorOrderStatus },
              },
            }) => {
              showToaster(shopFloorOrderStatus.name, 'positive', false);
            }
          );
      });
    }
  };
  const [updateShoFloorOrderStatus] = useMutation(CHANGE_SHOP_FLOOR_ORDER_STATUS);
  const [add] = useMutation(ADD_MACHINE_MEASUREMENT_RECORD, {
    update(cache, { data: { createMachineMeasurementRecord } }) {
      handleAddMachineMeasurementRecord(
        cache,
        shopFloorId,
        machineryAndEquipmentId,
        shopFloorOrderId,
        createMachineMeasurementRecord
      );
      closeDrawer();
    },
  });
  const [update] = useMutation(UPDATE_MACHINE_MEASUREMENT_RECORD, {
    update(cache, { data: { updateMachineMeasurementRecord } }) {
      handleUpdateMachineMeasurementRecord(
        cache,
        shopFloorId,
        machineryAndEquipmentId,
        shopFloorOrderId,
        updateMachineMeasurementRecord
      );
      closeDrawer();
    },
  });
  return (
    <Card style={technicalImage ? { height: '85%' }:{}}>
      <CardTitle
        title={
          <FormattedMessage
            id={
              transactionsReasonDispatch === 'machineMeasurementUpdate'
                ? 'measurement.update'
                : 'measurement.add'
            }
          />
        }
      >
        {technicalImage && (
          <Button onClick={() => setTechnicalImage(null)}>
            <FormattedMessage id="button.close" />
          </Button>
        )}
      </CardTitle>
      {!technicalImage ? (
        <CardBody>
          <Form
            style={{
              height: '100%',
              backgroundColor: 'transparent',
            }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <Card style={{ paddingRight: '8px',paddingLeft: '8px',paddingBottom: '8px',paddingTop: '8px', marginBottom: '10px' }}>
              <Measurement
                measurementRuleType={dataDispatch?.measurementRuleType}
                rule={dataDispatch?.rule}
                measurementRuleUnit={dataDispatch?.measurementRuleUnit}
                register={register}
                control={control}
                errors={errors}
                setValue={setValue}
              />
            </Card>
            <Row>
              <Col md={12}>
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    flexWrap: 'wrap',
                  }}
                >
                  <Button
                    type="button"
                    onClick={() => {
                      closeDrawer();
                    }}
                  >
                    <FormattedMessage id={'button.close'} />
                  </Button>
                  <Button type="submit">
                    <FormattedMessage id="button.ok" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
          <TechnicalImagesList
            productId={productId}
            setTechnicalImage={setTechnicalImage}
          />
        </CardBody>
      ) : (
        <CardBody style={{ padding: "5px", margin: 0, height: '100%',overflowX: "hidden" }}>
        {technicalImage.fileType === 'pdf' ? (
          <embed src={technicalImage.url} width="100%" height="85%" />
        ) : (
          <img src={technicalImage.url} width="100%" height="auto" alt="technicalImage" />
        )}
      </CardBody>
      )}
    </Card>
  );
};
interface ListMeasurementProsp {
  measurementRuleType: string;
  measurementRuleUnit: any;
  rule: any;
  errors: any;
  register: any;
  control: any;
  setValue: any;
}
const Measurement: FunctionComponent<ListMeasurementProsp> = ({
  measurementRuleType,
  measurementRuleUnit,
  rule,
  errors,
  register,
  control,
  setValue,
}) => {
  // const intl = useIntl();
  const __rule = () => {
    const mimTolerance =
      (rule?.minimumToleranceSign ? rule?.minimumToleranceSign[0].label : '') +
      ' ' +
      rule?.minimumTolerance +
      ' ' +
      measurementRuleUnit?.symbol;
    const maxTolerance =
      (rule?.minimumToleranceSign ? rule?.maximumToleranceSign[0].label : '') +
      ' ' +
      rule?.maximumTolerance +
      ' ' +
      measurementRuleUnit?.symbol;
    const tolerance = rule?.tolerance
      ? rule?.tolerance + ' ' + measurementRuleUnit?.symbol
      : '';
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}
      >
        <div>{mimTolerance}</div>
        <div>{tolerance || ''}</div>
        <div>{maxTolerance}</div>
      </div>
    );
  };
  const mimTolerance = () => {
    return (
      (measurementRuleType === 'NUMERIC'
        ? rule?.minimumToleranceSign[0].label === '-'
          ? (rule?.tolerance - rule?.minimumTolerance).toFixed(3)
          : rule?.minimumToleranceSign[0].label === '+' &&
            (rule?.tolerance + rule?.minimumTolerance).toFixed(3)
        : rule.minimumTolerance) +
      ' ' +
      measurementRuleUnit?.symbol
    );
  };
  const maxTolerance = () => {
    return (
      (measurementRuleType === 'NUMERIC'
        ? rule?.maximumToleranceSign[0].label === '-'
          ? (rule?.tolerance - rule?.maximumTolerance).toFixed(3)
          : rule?.maximumToleranceSign[0].label === '+' &&
            (rule?.tolerance + rule?.maximumTolerance).toFixed(3)
        : rule.maximumTolerance) +
      ' ' +
      measurementRuleUnit?.symbol
    );
  };
  return measurementRuleType === 'NUMERIC' || measurementRuleType === 'RANGE' ? (
    <FormControl
      label={<>{__rule()}</>}
      error={errors?.measurement ? 'Please input a valid Measurement' : null}
    >
      <Input
        type="text"
        size={SIZE.compact}
        name="measurement"
        inputRef={register({
          required: true,
        })}
        onChange={(event) => {
          setValue('measurement', inputDecimal({ event }));
        }}
        startEnhancer={mimTolerance()}
        endEnhancer={maxTolerance()}
        error={errors.measurement}
        overrides={{
          EndEnhancer: {
            style: () => {
              return {
                backgroundColor: '#c0c0c0',
                marginRight: 0,
                marginLeft: 0,
                marginTop: 0,
                marginBottom: 0,
                width: '30%',
              };
            },
          },
          Root: {
            style: () => {
              return {
                marginRight: 0,
                marginLeft: 0,
                marginTop: 0,
                marginBottom: 0,
                paddingRight: 0,
                paddingLeft: 0,
                paddingTop: 0,
                paddingBottom: 0,
              };
            },
          },
          StartEnhancer: {
            style: () => {
              return {
                backgroundColor: '#c0c0c0',
                marginRight: 0,
                marginLeft: 0,
                marginTop: 0,
                marginBottom: 0,
                width: '30%',
              };
            },
          },
        }}
      />
    </FormControl>
  ) : (
    measurementRuleType === 'BOOLEAN' && (
      <FormControl
        label={<FormattedMessage id="shop_floor.measurement" />}
        error={errors?.measurement ? 'Please input a valid Measurement' : null}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            flexWrap: 'wrap',
          }}
        >
          <Controller
            as={
              <RadioGroup align={ALIGN.horizontal}>
                <Radio value="1">{rule.trueLabel}</Radio>
                <Radio value="0">{rule.falseLabel}</Radio>
              </RadioGroup>
            }
            name="measurement"
            align={ALIGN.horizontal}
            control={control}
          />
        </div>
      </FormControl>
    )
  );
};
export default MachineMeasurementForm;

