// @ts-nocheck
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { StyledSpinnerNext } from 'baseui/spinner';
import { useIntl } from 'react-intl';
import { ColFull, GridFull, Heading, MainCard, RowFull } from './OfferRequest.style';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  ColumnContent,
  OfferWithStage,
  OfferWithStages,
} from '../../types/OfferWithStages';
import { RequestBox } from './RequestBox';
import { Offer } from '../../types/Offer';
const CHANGE_OFFER_STAGE_WITH_DEFAULT = loader(
  '../../graphql/offer/CHANGE_OFFER_STAGE_WITH_DEFAULT.graphql'
);

type ListProps = {
  offerWithStagesData: OfferWithStage[];
};
export const Offers: FunctionComponent<ListProps> = ({
  offerWithStagesData,
}) => {
  return offerWithStagesData ? (
    <OfferLayout result={offerWithStagesData}  />
  ) : (
    <StyledSpinnerNext />
  );
};

interface OfferLayoutData {
  result: OfferWithStages;
}

const OfferLayout: FunctionComponent<OfferLayoutData> = ({ result }) => {
  const intl = useIntl();
  const [data, setData] = useState(
    result.map((item:any) => {
      return {
        ...item,
        offer: item.offer.filter((offerItem:any) => {
          const orderLength = offerItem.order.length === 0;
          const updatedAtTemp = new Date().setDate(
            new Date(offerItem.updatedAt).getDate() + 1) > new Date().getTime()
          ;
          const canceledAndDeclined =
            offerItem.offerStatus.code !== 'OFFER_CANCELED' &&
            offerItem.offerStatus.code !== 'OFFER_DECLINED';
          return orderLength && (updatedAtTemp || canceledAndDeclined);
        }),
      };
    })
  );
  const [changeOfferStageWithDefault] = useMutation(CHANGE_OFFER_STAGE_WITH_DEFAULT);
  useEffect(() => {
    setData(
      result.map((item:any) => {
        return {
          ...item,
          offer: item.offer.filter((offerItem:any) => {
            const orderLength = offerItem.order.length === 0;
            const updatedAtTemp = new Date().setDate(
              new Date(offerItem.updatedAt).getDate() + 1) > new Date().getTime()
            ;
            const canceledAndDeclined =
              offerItem.offerStatus.code !== 'OFFER_CANCELED' &&
              offerItem.offerStatus.code !== 'OFFER_DECLINED';
            return orderLength && (updatedAtTemp || canceledAndDeclined);
          }),
        };
      })
    );
  }, [result]);
  return (
      <GridFull fluid={true}>
        <RowFull>
          <DragDropContext
            onDragEnd={(result:any) =>
              onDragEnd(result, data, setData, changeOfferStageWithDefault)
            }
          >
            {Object.entries(data).map(([columnId, column], index) => {
              return (
                <ColFull md={3} lg={3} key={columnId} index={index}>
                  <Heading>
                    {intl.formatMessage({ id: column.name })} ({column.offer.length})
                  </Heading>
                  <Droppable droppableId={columnId} key={columnId}>
                    {(provided:any) => {
                      return (
                        <MainCard {...provided.droppableProps} ref={provided.innerRef}>
                          {column.offer.map((offer: Offer, index: number) => {
                            return (
                              <Draggable
                                key={offer.id}
                                draggableId={offer.id}
                                index={index}
                              >
                                {(provided:any, snapshot:any) => {
                                  return (
                                    <RequestBox
                                      provided={provided}
                                      snapshot={snapshot}
                                      data={offer}
                                      columnId={columnId}
                                    />
                                  );
                                }}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </MainCard>
                      );
                    }}
                  </Droppable>
                </ColFull>
              );
            })}
          </DragDropContext>
        </RowFull>
      </GridFull>
  );
};

const onDragEnd = (
  result: DropResult,
  offerWithStages: OfferWithStage[],
  setOfferWithStages: React.Dispatch<React.SetStateAction<OfferWithStage[]>>,
  updateMutation: any
) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn: ColumnContent = offerWithStages[source.droppableId];
    const destColumn: ColumnContent = offerWithStages[destination.droppableId];
    const sourceItems: Offer[] = [...sourceColumn.offer];
    const destItems: Offer[] = [...destColumn.offer];
    const [removed]: Offer[] = sourceItems.splice(source.index, 1);

    destItems.splice(destination.index, 0, removed);
    setOfferWithStages({
      ...offerWithStages,
      [source.droppableId]: {
        ...sourceColumn,
        offer: sourceItems,
      },
      [destination.droppableId]: {
        ...destColumn,
        offer: destItems,
      },
    });

    updateMutation({
      variables: {
        id: removed.id,
        targetStage: destColumn['id'],
      },
    });
  } else {
    const groupedOffer = offerWithStages[source.droppableId];
    const copiedItems = [...groupedOffer.offer];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setOfferWithStages({
      ...offerWithStages,
      [source.droppableId]: {
        ...groupedOffer,
        offer: copiedItems,
      },
    });
  }
};

export default Offers;
