import React, { FunctionComponent, useCallback, useEffect } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { Controller, useForm } from 'react-hook-form';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { handleAddRemoteOperationAdd, handleAddRemoteOperationUpdate } from './GraphqlFunction';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import  {Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
const ADD_OPERATION = loader('../../graphql/operation/ADD_OPERATION.graphql');
const UPDATE_OPERATION = loader('../../graphql/operation/UPDATE_OPERATION.graphql');
const GET_OPERATIONS = loader('../../graphql/operation/GET_OPERATIONS.graphql');
const GET_OPERATION_GROUPS = loader(
  '../../graphql/operation/GET_OPERATION_GROUPS.graphql'
);

export const AddOperationForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const { data: dataOperation } = useQuery(GET_OPERATIONS);
  const { data: dataOperationGroup } = useQuery(GET_OPERATION_GROUPS);
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReason = useDrawerState('transactionsReason');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (transactionsReason === 'update') {
      if (dataOperation) {
        dataOperation.operations.forEach((item) => {
          if (item.id === dataDispatch) {
            setValue('external', item.external);
            setValue('name', item.name);
            setValue('operationGroupId', [
              {
                id: item.operationGroup.id,
                label: item.operationGroup.name,
              },
            ]);
          }
        });
      }
    }
  }, [transactionsReason, dataDispatch, setValue, dataOperation]);
  const onSubmit = ({ name, operationGroupId, external }) => {
    if (transactionsReason === 'update') {
      updateOperation({
        variables: {
          id: dataDispatch,
          name: name,
          external: external,
          operationGroupId: operationGroupId[0].id,
        },
      }).then(() => {
        console.log('the data updated');
        closeDrawer();
      });
    } else {
      addOperation({
        variables: {
          name: name,
          external: external,
          operationGroupId: operationGroupId[0].id,
        },
      }).then(() => {
        console.log('the data added');
        closeDrawer();
      });
    }
  };
  const [addOperation] = useMutation(ADD_OPERATION, {
    update(cache, { data: {createOperation} }) {
      handleAddRemoteOperationAdd(cache, createOperation);
    },
  });
  const [updateOperation] = useMutation(UPDATE_OPERATION,{
    update(cache, { data: {updateOperation} }) {
      handleAddRemoteOperationUpdate(cache, updateOperation);
    },
  });
  return (
    <Card>
      <CardBody style={{ paddingTop: 0, paddingLeft: '5px', paddingRight: '5px' }}>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: '100%',
            backgroundColor: 'transparent',
          }}
        >
          {' '}
          <FormControl
            label={<FormattedMessage id="operation.name" />}
            error={errors.name ? 'Please input a valid Name' : null}
          >
            <Input
              type="text"
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              error={errors.name}
              overrides={errors.name ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
          <FormControl
            label={<FormattedMessage id="operation.operationGroup" />}
            error={
              errors.operationGroupId ? 'Please select a valid Operation Group' : null
            }
          >
            <Select
              data={dataOperationGroup && dataOperationGroup.operationGroups}
              controller={{
                name: 'operationGroupId',
                control: control,
                error: errors.operationGroupId,
                overrides: errors.operationGroupId
                  ? { After: Negative }
                  : { After: Positive },
                onChange: ([selected]) => {
                  return selected.option ? selected.value : undefined;
                },
              }}
            />
          </FormControl>
          <Controller
            as={
              <Checkbox
                overrides={{
                  Label: {
                    style: () => {
                      return {
                        marginTop: '5px',
                        marginBottom: '5px',
                        color: '#454b68',
                      };
                    },
                  },
                  Checkmark: {
                    style: () => {
                      return {
                        marginTop: '5px',
                        marginBottom: '5px',
                      };
                    },
                  },
                }}
              >
                <FormattedMessage id="operation.external" />
              </Checkbox>
            }
            name="external"
            type="checkbox"
            control={control}
            labelPlacement={LABEL_PLACEMENT.right}
            defaultValue={false}
          />
          <Row
            end="md"
            style={{
              width: '100%',
              marginTop: '50px',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
              padding: 0,
            }}
          >
            <Col md={6}>
              <Button onClick={closeDrawer} style={{ width: '100%' }}>
                <FormattedMessage id="button.close" />
              </Button>
            </Col>
            <Col md={6}>
              <Button type="submit" style={{ width: '100%' }}>
                <FormattedMessage
                  id={transactionsReason === 'update' ? 'button.update' : 'button.create'}
                />
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};
export default AddOperationForm;
