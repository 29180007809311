import { Input, SIZE } from 'baseui/input';
import { TYPE } from 'baseui/select';
import React from 'react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';

import { Col, Row } from '../../../components/FlexBox/FlexBox';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { Select } from '../../../components/FormWithElemets/Select/Select';
import { Negative, Positive } from '../../../components/General/NegativePositive';
import { MeasurementRuleUnits } from '../../../types/MeasurementRuleUnit';

const dataToleranceSign = [
    { id: 'NEGATIVE', name: '+' },
    { id: 'POSITIVE', name: '-' },
  ];
interface ListProps {
    errors:any;
    control:any;
    register:any;
    dataMeasurementRuleUnit: MeasurementRuleUnits;
  }
   const NumericRule: FunctionComponent<ListProps> = ({
    errors,
    register,
    control,
    dataMeasurementRuleUnit,
  }) => {
    return (
      <>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col md={6}>
            <FormControl
              label={<FormattedMessage id="measurement_rule.tolerance" />}
              error={errors?.tolerance ? 'Please input a valid Tolerance' : null}
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="tolerance"
                inputRef={register({
                  required: true,
                  pattern: /^[0-9]*\.?[0-9]*$/,
                })}
                error={errors.tolerance}
                overrides={errors.tolerance ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
          <Col md={6}>
            <FormControl
              label={<FormattedMessage id="measurement_rule.units" />}
              error={errors?.measurementRuleUnitId ? 'Please input a valid Unit' : null}
            >
              <Select
                data={
                  dataMeasurementRuleUnit && dataMeasurementRuleUnit.measurementRuleUnits
                }
                controller={{
                  name: 'measurementRuleUnitId',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.measurementRuleUnitId,
                  overrides: errors.measurementRuleUnitId
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col md={2}>
            <FormControl
              label={<FormattedMessage id="measurement_rule.minimumToleranceSign" />}
              error={
                errors?.minimumToleranceSign
                  ? 'Please input a valid Minimum Tolerance Sign'
                  : null
              }
            >
              <Select
                data={dataToleranceSign}
                controller={{
                  name: 'minimumToleranceSign',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.minimumToleranceSign,
                  overrides: errors.minimumToleranceSign
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
          <Col md={4}>
            <FormControl
              label={<FormattedMessage id="measurement_rule.minimumTolerance" />}
              error={
                errors?.minimumTolerance ? 'Please input a valid Minimum Tolerance' : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="minimumTolerance"
                inputRef={register({
                  required: true,
                  pattern: /^[0-9]*\.?[0-9]*$/,
                })}
                error={errors.minimumTolerance}
                overrides={
                  errors.minimumTolerance ? { After: Negative } : { After: Positive }
                }
              />
            </FormControl>
          </Col>
          <Col md={2}>
            <FormControl
              label={<FormattedMessage id="measurement_rule.maximumToleranceSign" />}
              error={
                errors?.maximumToleranceSign
                  ? 'Please input a valid Maximum Tolerance Sign'
                  : null
              }
            >
              <Select
                data={dataToleranceSign}
                controller={{
                  name: 'maximumToleranceSign',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.maximumToleranceSign,
                  overrides: errors.maximumToleranceSign
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
          <Col md={4}>
            <FormControl
              label={<FormattedMessage id="measurement_rule.maximumTolerance" />}
              error={
                errors?.maximumTolerance ? 'Please input a valid Maximum Tolerance' : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="maximumTolerance"
                inputRef={register({
                  required: true,
                  pattern: /^[0-9]*\.?[0-9]*$/,
                })}
                error={errors.maximumTolerance}
                overrides={
                  errors.maximumTolerance ? { After: Negative } : { After: Positive }
                }
              />
            </FormControl>
          </Col>
        </Row>
      </>
    );
  };
  export default NumericRule;