import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import {  LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';
import { ItemMaterial } from '../AddPurchaseOfferForm';

interface ListItemProps {
  item: ItemMaterial;
  handleItemDelete: Function;
  handleItemEdit: Function;
  index: number;
}
const ListItemPurchaseMaterial: FunctionComponent<ListItemProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
  index,
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(index);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(index);
      },
      check_type: 'UPDATE',
    },
  ];
  return (
    <RowCustom>
      <Col xs={12} md={6} lg={4}>
        <LabelCustom title={intl.formatMessage({ id: 'inventory.inventoryId' })}>
          {item.inventoryId[0]?.label}
        </LabelCustom>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <LabelCustom title={intl.formatMessage({ id: 'date.estimatedDate' })}>
          {new Date(item.estimatedDate).toLocaleDateString()}
        </LabelCustom>
      </Col>
      <Col xs={4}>
        <LabelCustom title={intl.formatMessage({ id: 'purchaseOffer.qty' })}>
          {item.qty} &nbsp; {item.unitsId ? item.unitsId[0]?.label : ''}
        </LabelCustom>
      </Col>
      <ColButton xs>
        <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
      </ColButton>
    </RowCustom>
  );
};
export default ListItemPurchaseMaterial;
