import React, {
  Fragment,
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import {} from 'react-flexbox-grid';
import { FormattedMessage } from 'react-intl';
import { LabelCol } from '../../../../../components/FormWithElemets/Label.style';
import { ContextSubcontractSalesOfferId } from '../ListItemSuncontractSales';
import { SubcontractSalesOfferMaterialPrice } from '../../../../../types/SubcontractSalesOfferMaterialPrice';
import { Col } from '../../../../../components/FlexBox/FlexBox';

interface ItemDetailMaterialPriceProps {
  itemPrice: SubcontractSalesOfferMaterialPrice;
  purchaseOfferRecord: {
    qty: number;
    unitPrice: number;
    currency: string;
  };
  priceUpdate: Function;
  inventoryId: string;
  billOfMaterialItemId: string;
  subcontractSalesOfferPriceId: string;
  orderQty: number;
}
const ItemDetailMaterialPrice: FunctionComponent<ItemDetailMaterialPriceProps> = ({
  itemPrice,
  purchaseOfferRecord,
  priceUpdate,
  inventoryId,
  subcontractSalesOfferPriceId,
  billOfMaterialItemId,
  orderQty,
}) => {
  const { subcontractSalesOfferId } = useContext(
    ContextSubcontractSalesOfferId
  );
  const [profitAmount, setProfitAmount] = useState(0);
  useEffect(() => {
    if (itemPrice) {
      const { amount, profitRate } = itemPrice;
      const _profitAmount =
        Math.ceil(100 * ((amount * profitRate) / 100)) / 100;
      setProfitAmount(_profitAmount);
    }
  }, [itemPrice, itemPrice]);
  const { qty, unitPrice, currency } = purchaseOfferRecord;
  useEffect(() => {
    if (qty && unitPrice) {
      const _qty = Math.ceil((100 * qty) / orderQty) / 100;// Tane başı kaç gram malzeme
      if (_qty !== itemPrice?.qty || unitPrice !== itemPrice?.unitPrice) {
        priceUpdate({
          itemPrice,
          inventoryId,
          qty: _qty,
          unitPrice: unitPrice,
          billOfMaterialItemId,
          subcontractSalesOfferId,
          subcontractSalesOfferPriceId
        });
      }
    }
  }, [unitPrice, qty, itemPrice]);
  return (
    <Fragment>
      <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="materialPrice.qty" />}>
          {itemPrice?.qty || "0"}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="materialPrice.unitPrice" />}>
          {(itemPrice?.unitPrice || 0) + ' ' + currency}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCol title={<FormattedMessage id="materialPrice.amountPrice" />}>
          {(itemPrice?.amount || 0) + ' ' + currency}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="operationPrice.profitRate" />{' '}
              {' % ' + (itemPrice?.profitRate || 0)}
            </>
          }
        >
          {profitAmount || '-'}{' '}
        </LabelCol>
      </Col>
      {/* {itemPrice?.amount !==  && (
        <ColButton sm={6} md={4} lg={1}>
          <Button
            onClick={() =>
              priceUpdate({
                inventoryId,
                qty: 10 || itemPrice?.qty,
                unitPrice: itemPrice?.unitPrice,
                billOfMaterialItemId,
                offerMaterialPriceId: itemPrice?.id,
                subcontractSalesOfferId,
                subcontractSalesOfferPriceId,
              })
            }
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      )} */}
    </Fragment>
  );
};
export default ItemDetailMaterialPrice;
