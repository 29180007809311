import { faEdit } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import Button, {
  COLOR,
} from '../../../../../components/FormWithElemets/Button/Button';
import { LabelCol } from '../../../../../components/FormWithElemets/Label.style';
import { OperationSalesOffer } from '../../../../../types/OperationSalesOffer';

interface OperationSalesOfferPriceItemProps {
  item: OperationSalesOffer;
  setItemAdd: Function;
  transportUnitPrice:number
}
const OperationSalesOfferPriceItem: FunctionComponent<OperationSalesOfferPriceItemProps> = ({
  item,
  transportUnitPrice,
  setItemAdd,
}) => {
  const { qty, unitPrice, operationSalesOfferPrice } = item;
  const [{ profitAmount, discountAmount, taxAmount }, setState] = useState({
    profitAmount: 0,
    discountAmount: 0,
    taxAmount: 0,
  });
  useEffect(() => {
    if (operationSalesOfferPrice) {
      const { profitRate, discountRate, taxRate } = operationSalesOfferPrice;
      const amount = +(qty * (unitPrice + transportUnitPrice)).toFixed(2);
      const profitAmount = +((amount * profitRate) / 100).toFixed(2);
      const discountAmount = +(
        ((amount + profitAmount) * discountRate) /
        100
      ).toFixed(2);
      const taxAmount = +(
        ((amount + profitAmount - discountAmount) * taxRate) /
        100
      ).toFixed(2);
      setState({
        profitAmount: profitAmount,
        discountAmount: discountAmount,
        taxAmount: taxAmount,
      });
    }
  }, [
    operationSalesOfferPrice,
    unitPrice,
    qty,
    taxAmount,
    profitAmount,
    discountAmount,
    transportUnitPrice,
  ]);
  return (
    <RowCustom>
      <Col sm={6} md={4} lg={2} style={{display:'none'}}>
        <LabelCol title={<FormattedMessage id="offerPrice.amountPrice" />}>
        {(qty * (unitPrice + transportUnitPrice)).toFixed(2) || '0'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.profitRate" />
              {' % ' + (operationSalesOfferPrice?.profitRate || 0)}
            </>
          }
        >
          {profitAmount || '-'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.discountRate" />
              {' % ' + (operationSalesOfferPrice?.discountRate || 0)}
            </>
          }
        >
          {discountAmount || '-'}
        </LabelCol>
      </Col>
      <Col sm={6} md={4} lg={3}>
        <LabelCol
          title={
            <>
              <FormattedMessage id="offerPrice.taxRate" />
              {' % ' + (operationSalesOfferPrice?.taxRate || 0)}
            </>
          }
        >
          {taxAmount || '-'}
        </LabelCol>
      </Col>
      <ColButton xs>
        <Button onClick={() => setItemAdd(item)} color={COLOR.green}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};
export default OperationSalesOfferPriceItem;
