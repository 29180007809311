import {
  faArrowToBottom,
  faArrowToTop,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {  FunctionComponent, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Button, {
  COLOR,
} from '../../../../components/FormWithElemets/Button/Button';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { Label } from '../../../../components/FormWithElemets/Label.style';
import { BillOfMaterialAttachment } from '../../../../types/BillOfMaterial';
import ListTechicalImages from '../../../TechicalImages/ListTechicalImages';

interface BillOfMaterialTechicalImagesProps {
  images: BillOfMaterialAttachment[];
  deleteMutation: any;
}
const BillOfMaterialTechicalImages: FunctionComponent<BillOfMaterialTechicalImagesProps> = ({
  images,
  deleteMutation,
}) => {
  const [show, setShow] = useState(true);
  return (
    <CardBody style={{ marginLeft: '25px', marginBottom: 0, paddingBottom: 0 }}>
      <Label
        style={{ borderBottom: '1px solid' + COLOR.grey }}
        title={<FormattedMessage id="billOfMaterial.images" />}
      >
        <Button
          type="button"
          onClick={() => {
            setShow(!show);
          }}
        >
          <FontAwesomeIcon icon={show ? faArrowToBottom : faArrowToTop} />
        </Button>
      </Label>
      {show && (
        <ListTechicalImages imajes={images} deleteMutation={deleteMutation} />
      )}
    </CardBody>
  );
};

export default BillOfMaterialTechicalImages;
