import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { handleAddInventory, handleUpdateInventory } from './GraphqlFunction';
import { CardTitle, Card } from '../../components/FormWithElemets/Card.style';
import ProductCategoryAndSelect from '../Product/ProductCategoryAndAqlSelect';

const ADD_INVENTORY = loader('../../graphql/inventory/ADD_INVENTORY.graphql');
const UPDATE_INVENTORY = loader('../../graphql/inventory/UPDATE_INVENTORY.graphql');
const GET_COST_CENTERS = loader('../../graphql/inventory/GET_COST_CENTERS.graphql');
const GET_INVENTORY_CATEGORIES = loader(
  '../../graphql/inventory/GET_INVENTORY_CATEGORIES.graphql'
);
const SEARCH_PRODUCTS = loader('../../graphql/product/SEARCH_PRODUCTS.graphql');
const GET_INVENTORY_TYPE = loader('../../graphql/inventory/GET_INVENTORY_TYPES.graphql');
const GET_UNITS = loader('../../graphql/unit/GET_UNITS.graphql');

const InventoryForm: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
  } = useForm();
  const [createtype, setCreatetype] = useState(false);
  const { data: dataInventoryCategory } = useQuery(GET_INVENTORY_CATEGORIES);
  const { data: dataCostCenter } = useQuery(GET_COST_CENTERS);
  const { data: dataInventoryType } = useQuery(GET_INVENTORY_TYPE);
  const { data: dataUnit } = useQuery(GET_UNITS);
  const { loading } = useQuery(SEARCH_PRODUCTS, {
    variables: {
      full: true,
      name: null,
    },
  });
  console.log(loading);
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const closeDrawer = useCallback(
    (sendData?) => {
      sendData
        ? dispatch({
            type: 'CLOSE_DRAWER',
            data: sendData || undefined,
            transactionsReason: 'InventoryForm',
          })
        : dispatch({
            type: 'CLOSE_DRAWER',
            transactionsReason: 'InventoryForm',
          });
    },
    [dispatch]
  );
  useEffect(() => {
    if (transactionsReasonDispatch === 'anotherComponent' || transactionsReasonDispatch === 'material') {
      setValue('name', dataDispatch?.name || '');
    } else if (transactionsReasonDispatch === 'update') {
      setValue('name', dataDispatch.name);
      setValue(
        'aql',
        dataDispatch.aql?.id
          ? [{ id: dataDispatch.aql.id, label: dataDispatch.aql.code }]
          : undefined
      );
      setValue('unitsId', dataDispatch.units ? [
        {
          id: dataDispatch.units.id,
          label: dataDispatch.units.name,
        },
      ]:undefined);
      setValue('inventoryCategoryId', [
        {
          id: dataDispatch.inventoryCategory.id,
          label: dataDispatch.inventoryCategory.name,
        },
      ]);
      setValue('costCenterId', [
        {
          id: dataDispatch.costCenter.id,
          label: dataDispatch.inventoryCategory.name,
        },
      ]);
      setValue('inventoryTypeId', [
        {
          id: dataDispatch.inventoryType.id,
          label: dataDispatch.inventoryType.name,
        },
      ]);
    } else if (transactionsReasonDispatch === 'productAdd') {
      setValue('inventoryTypeId', [
        {
          id: '36fb058e-69bb-4bb8-94b7-642f6d5407da',
          label: 'Finished Goods',
        },
      ]);
    }
  }, [dataDispatch, transactionsReasonDispatch, setValue]);

  const onSubmit = ({
    name,
    unitsId,
    inventoryCategoryId,
    costCenterId,
    inventoryTypeId,
    productCategory,
    aql,
    fullControl,
    revisionNo
  }) => {
    transactionsReasonDispatch === 'update'
      ? updateInventory({
          variables: {
            id: dataDispatch.id,
            name: name,
            unitsId: unitsId[0].id,
            inventoryCategoryId: inventoryCategoryId[0].id,
            costCenterId: costCenterId[0].id,
            inventoryTypeId: inventoryTypeId[0].id,
          },
        })
      : addInventory({
          variables: {
            name: name,
            aqlId: aql ? aql[0]?.id : null,
            unitsId: unitsId[0].id,
            inventoryCategoryId: inventoryCategoryId[0].id,
            costCenterId: costCenterId[0].id,
            inventoryTypeId: inventoryTypeId[0].id,
            productCategory: createtype
              ? productCategory
                ? productCategory[0]?.id
                : null
              : null,
            productCategoryId: !createtype
              ? productCategory
                ? productCategory[0]?.id
                : null
              : null,
            fullControl: fullControl,
            revisionNo:revisionNo,
          },
        });
  };
  const [addInventory] = useMutation(ADD_INVENTORY, {
    update(cache, { data: { createInventory } }) {
      handleAddInventory(cache, createInventory, getValues().productCategory);
      transactionsReasonDispatch === 'anotherComponent' ||transactionsReasonDispatch === 'material'
        ? closeDrawer({
            inventoryId: [{ id: createInventory.id, label: createInventory.name }],
            unit: createInventory.units.name,
          })
        : closeDrawer();
    },
  });
  const [updateInventory] = useMutation(UPDATE_INVENTORY, {
    update(cache, { data: { updateInventory } }) {
      handleUpdateInventory(cache, updateInventory);
      closeDrawer();
    },
  });
  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id={
              transactionsReasonDispatch === 'productAdd'
                ? 'product.create'
                : transactionsReasonDispatch === 'update'
                ? 'inventory.update'
                : 'inventory.create'
            }
          />
        }
      />
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <Row style={{ margin: 0, padding: 0 }}>
          <Col lg={8}>
            <FormControl
              label={<FormattedMessage id="inventory.name" />}
              error={errors.name ? 'Please input a valid Name' : null}
            >
              <Input
                size={SIZE.compact}
                name="name"
                inputRef={register({
                  required: true,
                })}
                error={errors.name}
                overrides={errors.name ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
          <Col lg={4}>
            <FormControl
              label={<FormattedMessage id="inventory.unit" />}
              error={errors.unitsId ? 'Please input a valid Unit' : null}
            >
              <Select
                data={dataUnit && dataUnit.units}
                controller={{
                  name: 'unitsId',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.unitsId,
                  overrides: errors.unitsId ? { After: Negative } : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col lg={4}>
            <FormControl
              label={<FormattedMessage id="inventory.category" />}
              error={
                errors.inventoryCategoryId
                  ? 'Please select a valid inventory Category'
                  : null
              }
            >
              <Select
                data={dataInventoryCategory && dataInventoryCategory.inventoryCategories}
                controller={{
                  name: 'inventoryCategoryId',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.inventoryCategoryId,
                  overrides: errors.inventoryCategoryId
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
          <Col lg={4}>
            <FormControl
              label={<FormattedMessage id="inventory.inventoryType" />}
              error={
                errors.inventoryTypeId ? 'Please input a valid Inventory Type' : null
              }
            >
              <Select
                data={dataInventoryType && dataInventoryType.inventoryTypes}
                controller={{
                  name: 'inventoryTypeId',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.inventoryTypeId,
                  disabled: transactionsReasonDispatch === 'productAdd',
                  overrides: errors.inventoryTypeId
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
          <Col lg={4}>
            <FormControl
              label={<FormattedMessage id="inventory.costCenter" />}
              error={errors.costCenterId ? 'Please select a valid Cost Center' : null}
            >
              <Select
                data={dataCostCenter && dataCostCenter.costCenters}
                controller={{
                  name: 'costCenterId',
                  type: TYPE.search,
                  control,
                  rules: { required: true },
                  creatable: false,
                  searchable: true,
                  filterOutSelected: false,
                  error: errors.costCenterId,
                  overrides: errors.costCenterId
                    ? { After: Negative }
                    : { After: Positive },
                  onChange: ([selected]) => {
                    return selected?.option ? selected.value : undefined;
                  },
                }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row style={{ margin: 0, padding: 0 }}>
          {transactionsReasonDispatch !== 'update' && transactionsReasonDispatch !== 'material' && (
            <>
              <Col md={2}>
                <Controller
                  as={
                    <Checkbox
                      overrides={{
                        Label: {
                          style: () => {
                            return {
                              marginTop: '40px',
                              marginBottom: '5px',
                              color: '#454b68',
                            };
                          },
                        },
                        Checkmark: {
                          style: () => {
                            return {
                              marginTop: '40px',
                              marginBottom: '5px',
                            };
                          },
                        },
                      }}
                    >
                      <FormattedMessage id="inventory.product_add" />
                    </Checkbox>
                  }
                  name="product"
                  type="checkbox"
                  control={control}
                  disabled={transactionsReasonDispatch === 'productAdd' || transactionsReasonDispatch === 'anotherComponent'}
                  labelPlacement={LABEL_PLACEMENT.right}
                  defaultValue={transactionsReasonDispatch === 'productAdd' || transactionsReasonDispatch === 'anotherComponent'}
                />
              </Col>
              {watch().product && (
                <>
                  <ProductCategoryAndSelect
                    errors={errors}
                    control={control}
                    register={register}
                    setCreateType={setCreatetype}
                  />
                  <Col md={2}>
                    <Controller
                      as={
                        <Checkbox
                          overrides={{
                            Label: {
                              style: () => {
                                return {
                                  marginTop: '40px',
                                  marginBottom: '5px',
                                  color: '#454b68',
                                };
                              },
                            },
                            Checkmark: {
                              style: () => {
                                return {
                                  marginTop: '40px',
                                  marginBottom: '5px',
                                };
                              },
                            },
                          }}
                        >
                          <FormattedMessage id="inventory.fullControl" />
                        </Checkbox>
                      }
                      name="fullControl"
                      type="checkbox"
                      control={control}
                      labelPlacement={LABEL_PLACEMENT.right}
                      defaultValue={false}
                    />
                  </Col>
                </>
              )}
            </>
          )}
        </Row>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={() => closeDrawer(null)} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage
                id={
                  transactionsReasonDispatch !== 'update'
                    ? 'button.create'
                    : 'button.update'
                }
              />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default InventoryForm;
