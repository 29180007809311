import React, { FunctionComponent, useState } from 'react';
import { SubcontractSalesOfferMaterialPrice as SubcontractSalesOfferMaterialPriceType } from '../../../../../types/SubcontractSalesOfferMaterialPrice';
import ItemDetailMaterialForm from './ItemDetailMaterialForm';
import { CardBody } from '../../../../../components/FormWithElemets/Card.style';
import { Label } from '../../../../../components/FormWithElemets/Label.style';
import { FormattedMessage } from 'react-intl';
import Button, {
  COLOR,
} from '../../../../../components/FormWithElemets/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowToBottom,
  faArrowToTop,
  faEdit,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { BillOfMaterialItem } from '../../../../../types/BillOfMaterial';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import { PurchaseMaterialOffer } from '../../../../../types/PurchaseMaterialOffer';
import useMaterialPriceUpdate from './useMaterialPriceUpdate';
import ItemDetailMaterialPrice from './ItemDetailMaterailPrice';
import { handleCurrency } from '../../../../Functions/Utilities';

interface ItemDetailMaterialProps {
  billOfMaterialItem: BillOfMaterialItem[];
  subcontractSalesOfferMaterialPrice: SubcontractSalesOfferMaterialPriceType[];
  subcontractSalesOfferPriceId: string;
  purchaseMaterialOffer: PurchaseMaterialOffer[];
  orderQty: number
}
const ItemDetailMaterial: FunctionComponent<ItemDetailMaterialProps> = ({
  billOfMaterialItem,
  subcontractSalesOfferMaterialPrice,
  subcontractSalesOfferPriceId,
  purchaseMaterialOffer,
  orderQty = 0
}) => {
  const { priceUpdate } = useMaterialPriceUpdate();
  const [detailShow, setDetailShow] = useState(true);
  const [itemAdd, setItemAdd] = useState(null);
  return (
    <CardBody style={{ marginLeft: '20px', backgroundColor: '#dedede' }}>
      <Label
        style={{ borderBottom: '1px solid ' + COLOR.grey }}
        title={<FormattedMessage id="materialprice.materials" />}
      >
        <Button disabled={!itemAdd} onClick={() => setItemAdd(null)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Button onClick={() => setDetailShow(!detailShow)}>
          <FontAwesomeIcon icon={detailShow ? faArrowToTop : faArrowToBottom} />
        </Button>
      </Label>
      <div style={detailShow ? {} : { display: 'none' }}>
        {itemAdd !== null && (
          <ItemDetailMaterialForm
            itemAdd={itemAdd}
            setItemAdd={setItemAdd}
            subcontractSalesOfferPriceId={subcontractSalesOfferPriceId}
          />
        )}
        {itemAdd === null &&
          billOfMaterialItem.map((item) => {
            const itemPrice = subcontractSalesOfferMaterialPrice?.find(
              (price) => price.billOfMaterialItemId === item.id
            );
            return (
              <RowCustom key={item?.id}>
                <Col sm={12} md={8} lg={4}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      textDecoration: 'underline',
                    }}
                  >
                    {item?.inventory?.name || item?.name || 'No Inventory'}
                  </div>
                </Col>
                <ItemDetailMaterialPrice
                  itemPrice={itemPrice}
                  orderQty={orderQty}
                  billOfMaterialItemId={item.id}
                  subcontractSalesOfferPriceId={subcontractSalesOfferPriceId}
                  inventoryId={item?.inventory?.id}
                  priceUpdate={priceUpdate}
                  purchaseOfferRecord={handleChangeCheckPrice(
                    item?.inventory?.id,
                    purchaseMaterialOffer,
                  )}
                />
                <ColButton xs>
                  <Button
                    onClick={() =>
                      setItemAdd({
                        itemMaterial: item,
                        itemPrice,
                      })
                    }
                    color={COLOR.green}
                  >
                    <FontAwesomeIcon icon={itemPrice ? faEdit : faPlus} />
                  </Button>
                </ColButton>
              </RowCustom>
            );
          })}
      </div>
    </CardBody>
  );
};
export default ItemDetailMaterial;

const handleChangeCheckPrice = (
  inventoryId: string,
  purchaseMaterialOffer: PurchaseMaterialOffer[]
) => {
  const currency = purchaseMaterialOffer?.find(item => item.inventory?.id === inventoryId)?.purchaseOfferRecord.find(record => record.accepted == true)?.currency || '₺';
  const totalQty = purchaseMaterialOffer?.reduce((acc, item) => {
    return item.inventory?.id === inventoryId
      ? acc +
      item.purchaseOfferRecord.reduce(
        (accc, record) =>
          record.accepted === true ? (accc + record.qty) : (accc + 0),
        0
      )
      : acc + 0;
  }, 0);
  const unitPrice = totalQty ? purchaseMaterialOffer?.reduce((acc, item) => {
    return item.inventory?.id === inventoryId
      ? acc +
      item?.purchaseOfferRecord.reduce(
        (accc, record) =>
          record.accepted === true
            ? accc + (record.qty * record.unitPrice)
            : accc + 0,
        0
      )
      : acc + 0;
  }, 0) : 0;
  return {
    qty: Math.ceil(100 * totalQty) / 100,
    unitPrice: (Math.ceil(100 * unitPrice / totalQty) / 100) || 0,
    currency: handleCurrency(currency)
  };
};
