import React, { FunctionComponent } from 'react';
interface ListProps {
  innerShape?: string | undefined;
}
export const Square: FunctionComponent<ListProps> = ({ innerShape = 'inner_full' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 100 100"
    >
      <g id="Group_20" data-name="Group 20" transform="translate(-242)">
        <g
          id="Rectangle_15"
          data-name="Rectangle 15"
          transform="translate(242)"
          fill="none"
          stroke="#707070"
          strokeWidth="3"
        >
          <rect width="100" height="100" stroke="none" />
          <rect x="1.5" y="1.5" width="97" height="97" fill="none" />
        </g>
        {innerShape === 'inner_hexagonal' && (
          <g id="Hexagonal_6" data-name="Hexagonal_6">
            <g id="Rectangle_1" data-name="Rectangle 1">
              <line
                id="Line_13"
                data-name="Line 13"
                x1="34.214"
                y2="20.168"
                transform="translate(257 10.407)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_14"
                data-name="Line 14"
                x1="34.214"
                y1="20.168"
                transform="translate(291.214 10.407)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_15"
                data-name="Line 15"
                y2="39.256"
                transform="translate(257 30.575)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_16"
                data-name="Line 16"
                x1="34.214"
                y1="20.168"
                transform="translate(257 69.832)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_17"
                data-name="Line 17"
                x1="34.214"
                y2="20.168"
                transform="translate(291.214 69.832)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_18"
                data-name="Line 18"
                x1="0.36"
                y2="39.256"
                transform="translate(325.068 30.575)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
            </g>
          </g>
        )}
        {innerShape === 'inner_round' ? (
          <g id="Round_1" data-name="Round 1" transform="translate(141 -100)">
            <g
              id="Round_2"
              data-name="Round 2"
              transform="translate(110 110)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <circle cx="40" cy="40" r="40" stroke="none" />
              <circle cx="40" cy="40" r="38.5" fill="none" />
            </g>
          </g>
        ) : innerShape === 'inner_square' ? (
          <g
            id="Square_17"
            data-name="Square 17"
            transform="translate(261 21)"
            fill="none"
            stroke="#707070"
            strokeWidth="3"
          >
            <rect width="58" height="58" stroke="none" />
            <rect x="1.5" y="1.5" width="55" height="55" fill="none" />
          </g>
        ) : (
          innerShape === 'inner_rectangle' && (
            <g
              id="Rectangle_18"
              data-name="Rectangle 18"
              transform="translate(257 28)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <rect width="68" height="42" stroke="none" />
              <rect x="1.5" y="1.5" width="65" height="39" fill="none" />
            </g>
          )
        )}
      </g>
    </svg>
  );
};
