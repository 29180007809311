import React, { FunctionComponent, useReducer, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, ColButton, DivSubmit, Row } from '../../../components/FlexBox/FlexBox';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import { ColumnButtons } from '../../../components/TailWinduiTable/ColumnButtons';
import {
  Card,
  CardBody,
  CardTitle,
} from '../../../components/FormWithElemets/Card.style';
import ListPurchaseMaterial from './PurchaseMaterialOffer/ListPurchaseMaterial';
import ListPurchaseOperation from './PurchaseOperationOffer/ListPurchaseOperation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons';
import ContentInformation from '../../Functions/ContentInformation';
const ADD_PURCHASE_OFFER = loader(
  '../../../graphql/purchaseOffer/ADD_PURCHASE_OFFER.graphql'
);

type SelectType = {
  id: string;
  label: string;
};
export type ItemMaterial = {
  name: string;
  estimatedDate: string;
  inventoryId: SelectType[];
  inventoryTypeId: SelectType[];
  qty: number;
  unitsId: SelectType[];
  rawMaterialId:string;
  materialGeometryId:string;
};
export type ItemOperation = {
  estimatedDate: string;
  operationId: [SelectType];
  qty: number;
};
export type PurchaseOffer = {
  purchaseMaterialOffer: ItemMaterial[];
  purchaseOperationOffer: ItemOperation[];
};
type Action =
  | { type: 'MATERIAL'; item: ItemMaterial[] }
  | { type: 'OPERATION'; item: ItemOperation[] };
const reducer = (state: PurchaseOffer, action: Action) => {
  switch (action.type) {
    case 'MATERIAL':
      return {
        ...state,
        purchaseMaterialOffer: action.item,
      };
    case 'OPERATION':
      return {
        ...state,
        purchaseOperationOffer: action.item,
      };
    default:
      return state;
  }
};
interface AddPurchaseOfferProps {
  setAddPurchaseOffer?: Function;
}
const AddPurchaseOfferForm: FunctionComponent<AddPurchaseOfferProps> = ({
  setAddPurchaseOffer,
}) => {
  const intl = useIntl();
  const [items, dispatch] = useReducer(reducer, {
    purchaseMaterialOffer: [],
    purchaseOperationOffer: [],
  });
  const [addShow, setAddShow] = useState({
    purchaseMaterialOffer: false,
    purchaseOperationOffer: false,
  });
  console.log("items",items)
  const [add] = useMutation(ADD_PURCHASE_OFFER);
  const handleSubmit = () => {
    add({
      variables: {
        approved: false,
        purchaseMaterialOffer: handleDataItemMaterialNew(items.purchaseMaterialOffer),
        purchaseOperationOffer: handleDataItemOperationNew(items.purchaseOperationOffer),
      },
    }).then(() => {
      setAddPurchaseOffer(false);
    });
  };
  const buttons = [
    {
      label: intl.formatMessage({ id: 'purchaseOffer.operations' }),
      function: () => {
        setAddShow({
          purchaseMaterialOffer: false,
          purchaseOperationOffer: true,
        });
      },
      check_type: '',
    },
    {
      label: intl.formatMessage({ id: 'purchaseOffer.materials' }),
      function: () => {
        setAddShow({
          purchaseMaterialOffer: true,
          purchaseOperationOffer: false,
        });
      },
      check_type: '',
    },
  ];
  return (
    <Card>
      <CardTitle title={<FormattedMessage id={'purchaseOffer.add'} />}>
        <Button
          color={COLOR.green}
          onClick={() => {
            setAddPurchaseOffer(false);
          }}
        >
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
      </CardTitle>
      <CardBody>
        <Row middle="xs">
          <Col sm={5}>
            <ContentInformation
              legnth={items?.purchaseOperationOffer.length}
              icon={<FormattedMessage id="purchaseOffer.operations"/>}
            />
          </Col>
          <Col sm={5}>
          <ContentInformation
              legnth={items?.purchaseMaterialOffer.length}
              icon={<FormattedMessage id="purchaseOffer.materials"/>}
            />
          </Col>
          <ColButton xs>
              <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
          </ColButton>
        </Row>
        {addShow.purchaseMaterialOffer && (
          <ListPurchaseMaterial
            dispatch={dispatch}
            initialState={items.purchaseMaterialOffer}
            setAddShow={setAddShow}
          />
        )}
        {addShow.purchaseOperationOffer && (
          <ListPurchaseOperation
            dispatch={dispatch}
            initialState={items.purchaseOperationOffer}
            setAddShow={setAddShow}
          />
        )}
        <DivSubmit style={{ marginTop: '20px' }}>
          <Button type="button" onClick={handleSubmit}>
            <FormattedMessage id="button.save" />
          </Button>
        </DivSubmit>
      </CardBody>
    </Card>
  );
};
export default AddPurchaseOfferForm;
const handleDataItemMaterialNew = (items: ItemMaterial[]) => {
  return items.map((item) => {
    const materialItemNameOrInventoryId =
      item.inventoryId[0].id === item.inventoryId[0].label
        ? { name: item.inventoryId[0].id }
        : { inventoryId: item.inventoryId[0].id };
    return {
      ...materialItemNameOrInventoryId,
      inventoryTypeId: item?.inventoryTypeId ? item.inventoryTypeId[0].id : null,
      unitsId: item.unitsId[0].id,
      qty: +item.qty,
      estimatedDate: item.estimatedDate,
      rawMaterialId:item.rawMaterialId,
      materialGeometryId:item.materialGeometryId
    };
  });
};
const handleDataItemOperationNew = (items: ItemOperation[]) => {
  return items.map((item) => {
    return {
      operationId: item.operationId[0].id,
      estimatedDate: item.estimatedDate,
      qty: +item.qty,
    };
  });
};
