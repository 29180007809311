import { Input, SIZE } from 'baseui/input';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import FormControl from '../../../../../components/FormWithElemets/FormControl';
import { handleCeil, inputDecimal } from '../../../../Functions/Utilities';
import { loader } from 'graphql.macro';
import { SubcontractSalesOfferPrice } from '../../../../../types/SubcontractSalesOfferPrice';
import { useToaster } from '../../../../../context/toaster-provider';
import { useForm } from 'react-hook-form';
import { Form } from '../../../../../components/FormWithElemets/Form.style';
import { useMutation } from '@apollo/react-hooks';
import Button from '../../../../../components/FormWithElemets/Button/Button';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UPDATE_SUBCONTRACT_SALES_OFFER_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferPrice/UPDATE_SUBCONTRACT_SALES_OFFER_PRICE.graphql'
);
interface ItemDetailOperationFormProps {
  itemAdd: SubcontractSalesOfferPrice;
  setItemAdd: Function;
}
const ItemDetailOfferForm: FunctionComponent<ItemDetailOperationFormProps> = ({
  itemAdd,
  setItemAdd,
}) => {
  const { showToaster } = useToaster();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: itemAdd
      ? {
          amount: itemAdd?.amount || 0,
          discountRate: itemAdd?.discountRate || 0,
          profitRate: itemAdd?.profitRate || 0,
          taxRate: itemAdd?.taxRate || 0,
        }
      : { amount: 0, discountRate: 0, profitRate: 0, taxRate: 0 },
  });
  const { amount, profitRate, discountRate, taxRate } = watch();
  useEffect(() => {
    const _profitAmount = handleCeil((amount * profitRate) / 100)
    setProfitAmount(_profitAmount);
    const _discountAmount = handleCeil(((+amount + +_profitAmount) * +discountRate / 100));
    setDiscountAmount(_discountAmount);
    const _taxAmount = handleCeil((((+amount + +_profitAmount - +_discountAmount) * +taxRate) / 100));
    setTaxAmount(_taxAmount);
  }, [amount, discountRate, profitRate, taxRate]);
  const onSubmit = ({ amount, discountRate, profitRate, taxRate }) => {
    update({
      variables: {
        id: itemAdd?.id,
        amount: +amount || 0,
        discountRate: +discountRate || 0,
        profitRate: +profitRate || 0,
        taxRate: +taxRate || 0,
      },
    }).then(() => {
      showToaster('updated', 'positive');
      setItemAdd(null);
    });
  };
  const [update] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER_PRICE);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom middle="xs">
        <Col sm={4} md={4} lg={2}>
          <FormControl label={<FormattedMessage id="offerPrice.amountPrice" />}>
            <Input
              type="text"
              name={'amount'}
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('amount', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3} style={{display:'none'}}>
          <FormControl label={<FormattedMessage id="offerPrice.profitRate" />}>
            <Input
              type="text"
              name={'profitRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('profitRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={profitAmount + '₺'}
            />
          </FormControl>
        </Col>

        <Col sm={4} md={4} lg={3} style={{display:'none'}}>
          <FormControl
            label={<FormattedMessage id="offerPrice.discountRate" />}
          >
            <Input
              type="text"
              name={'discountRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('discountRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={discountAmount + '₺'}
            />
          </FormControl>
        </Col>
     
        <Col sm={4} md={4} lg={3}>
          <FormControl label={<FormattedMessage id="offerPrice.taxRate" />}>
            <Input
              type="text"
              name={'taxRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('taxRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={taxAmount + '₺'}
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button
            style={{ marginTop: '16px' }}
            type="button"
            onClick={() => setItemAdd(null)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button style={{ marginTop: '16px' }} type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default ItemDetailOfferForm;
