import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../../components/Input/Input';
import { useToaster } from '../../../../context/toaster-provider';
import { PurchaseOperationOffer } from '../../../../types/PurchaseOperationOffer';
import DateCustom from '../../../DateCustom/DateCustom';
import { handleDate, inputDecimal } from '../../../Functions/Utilities';
import SelectOperation from '../../../SelectCustom/SelectOperation';
const UPDATE_PURCHASE_OPERATION_OFFER = loader(
  '../../../../graphql/purchaseOfferOperation/UPDATE_PURCHASE_OPERATION_OFFER.graphql'
);
interface UpdatePurchaseOperationOfferFormProps {
  item?: PurchaseOperationOffer;
  setItemAddShow?: Function;
  setItemId?: Function;
}
const UpdatePurchaseOperationOfferForm: FunctionComponent<UpdatePurchaseOperationOfferFormProps> = ({
  item,
  setItemId,
  setItemAddShow,
}) => {
  const { control, errors, setValue, register, handleSubmit } = useForm({
    defaultValues: item
      ? {
          operationId: [{ id: item.operation.id, label: item.operation.name }],
          qty: item.qty,
          estimatedDate: [new Date(item?.estimatedDate || new Date())],
        }
      : {
          operationId: undefined,
          qty: 0,
          estimatedDate: [new Date(new Date())],
        },
  });
  const { showToaster } = useToaster();
  const [update] = useMutation(UPDATE_PURCHASE_OPERATION_OFFER);
  const onSubmit = ({ qty, operationId, estimatedDate }) => {
      update({
        variables: {
          id: item.id,
          operationId: operationId ? operationId[0].id : null,
          estimatedDate: handleDate(estimatedDate[0]).toISOString(),
          qty: +qty,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
  };
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('operationId', undefined);
      setValue('qty', 0);
      setValue('estimatedDate', [new Date()]);
      setItemAddShow(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom bottom="xs">
        <Col xs={4}>
          <SelectOperation control={control} />
        </Col>
        <Col xs={2}>
          <DateCustom errors={errors} control={control} name="estimatedDate" />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormControl label={<FormattedMessage id="purchaseOffer.qty" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="qty"
              inputRef={register({
                required: false,
              })}
              onChange={(event: any) => {
                setValue('qty', inputDecimal({ event }));
              }}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button
            style={{
              marginBottom: '16px',
            }}
            type="button"
            onClick={handleResetForm}
          >
            <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
          </Button>
          <Button
            style={{
              marginBottom: '16px',
            }}
            type="submit"
          >
            <FormattedMessage id={item ? 'button.update' : 'button.add'} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};

export default UpdatePurchaseOperationOfferForm;
