import React, { Fragment, FunctionComponent, memo } from "react";
import { ProductSalesOffer } from "../../../../../types/ProductSalesOffer";
import ListItemProductSalesPreview from "./ListItemProductSalesPreview";
import ProductSalesHeaders from "./ProductSalesHeaders";

interface ListSubcontractSalesPreviewProps {
    items: ProductSalesOffer[];
    transportUnitPrice:number;
}
const ListSubcontractSalesPreview: FunctionComponent<ListSubcontractSalesPreviewProps> = ({
    items,
    transportUnitPrice
}) => {
    return (
        <Fragment>
            <ProductSalesHeaders/>
            {
                items.map((item: ProductSalesOffer) => {
                    return <ListItemProductSalesPreview key={item?.id} item={item} transportUnitPrice={transportUnitPrice}/>
                })
            }
        </Fragment>
    )
}
export default memo(ListSubcontractSalesPreview)