import React, {FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery} from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { CostCenters } from '../../types/CostCenter';


const GET_COST_CENTERS = loader(
    '../../graphql/inventory/GET_COST_CENTERS.graphql'
  );
interface ListProps {
    errors?: any;
    control: any;
    creatable?:boolean
    disabled?:boolean
  }
const SelectCostCenter: FunctionComponent<ListProps> = ({
  errors,
  control,
  creatable = false,
  disabled= false
}) => {
  const { data } = useQuery<CostCenters>(GET_COST_CENTERS);

  return (
    <FormControl 
      label={<FormattedMessage id="select.costCenter" />}
      error={
        errors && errors?.costCenterId ? 'Please Select a valid Cost Center' : null
      }
    >
      <Select
        data={data && data.costCenters}
        controller={{
          name: 'costCenterId',
          type: TYPE.search,
          control,
          disabled:disabled,
          creatable: creatable,
          rules: { required: errors ? true : false },
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectCostCenter