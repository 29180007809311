import React, {
  Fragment,
  FunctionComponent,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ListItemProductSales from './ListItemProductSales';
import ItemHeaders from '../../SubContractSales/ItemHeaders';
import {
  ProductSalesOffer,
  ProductSalesOfferIDs,
} from '../../../../types/ProductSalesOffer';
import UpdateProductSalesForm from './UpdateProductSalesForm';
import { useToaster } from '../../../../context/toaster-provider';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Transport from '../Transport/Transport';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
import ProductSalesResult from './ProductSalesResult';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import ListProductSalesPreview from './Preview/ListProductSalesPreview';
const DELETE_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/DELETE_PRODUCT_SALES_OFFER.graphql'
);
const GET_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/GET_PRODUCT_SALES_OFFER.graphql'
);
const S_GET_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/S_GET_PRODUCT_SALES_OFFER.graphql'
);
interface ListProductSalesProps {
  offerId: string;
  offerTransport: OfferTransport[];
  transportIncluded: boolean;
  preview: boolean;
}
const ListProductSales: FunctionComponent<ListProductSalesProps> = ({
  offerId,
  offerTransport,
  transportIncluded,
  preview
}) => {
  const { showToaster } = useToaster();
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<ProductSalesOfferIDs>(GET_PRODUCT_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_PRODUCT_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.productSalesOffer;
        return Object.assign({}, prev, {
          productSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const [itemId, setItemId] = useState(null);
  const [transportUnitPrice, setTransportUnitPrice] = useState(0);
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
  }, []);
  const [remove] = useMutation(DELETE_PRODUCT_SALES_OFFER);
  const handleItemDelete = useCallback(
    (id: string) => {
      if (window.confirm('Are you sure !')) {
        remove({
          variables: { id: id },
        }).then(() => {
          showToaster('deleted', 'positive');
        });
      }
    },
    [showToaster, remove]
  );
  useEffect(() => {
    if (data) {
      const {
        productSalesOffer: { productSalesOffer },
      } = data;
      setTransportUnitPrice(
        handleTransportUnitPrice(
          transportIncluded,
          offerTransport,
          productSalesOffer
        )
      );
    }
  }, [offerTransport, data, transportIncluded]);
  return (
    <Fragment>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
            data && (
              <Fragment>
                <CardBody style={preview ? { display: 'none' } : {}}>
                  <ItemHeaders
                    itemsLenght={1}
                  />
                  {data.productSalesOffer?.productSalesOffer?.map(
                    (item: ProductSalesOffer, index: number) => {
                      return itemId === null && item.id !== itemId ? (
                        <ListItemProductSales
                          key={index}
                          item={item}
                          transportUnitPrice={transportUnitPrice}
                          handleItemEdit={handleItemEdit}
                          handleItemDelete={handleItemDelete}
                        />
                      ) : (
                          <UpdateProductSalesForm
                            key={item.id}
                            item={item}
                            setClose={setItemId}
                          />
                        );
                    }
                  )}
                  <Transport
                    offerId={offerId}
                    offerTransport={offerTransport}
                    transportIncluded={transportIncluded}
                  />
                </CardBody>
                <CardBody style={preview ? {} : { display: 'none' }}>
                  <ListProductSalesPreview items={data?.productSalesOffer.productSalesOffer} transportUnitPrice={transportUnitPrice} />
                </CardBody>
                <ProductSalesResult
                    preview={preview}
                  offerTransport={offerTransport}
                  items={data?.productSalesOffer?.productSalesOffer}
                  transportIncluded={transportIncluded}
                />
              </Fragment>
            )
          )}
    </Fragment>
  );
};

export default memo(ListProductSales);
const handleTransportUnitPrice = (
  transportIncluded: boolean,
  offerTransport: OfferTransport[],
  items: ProductSalesOffer[]
) => {
  const offerTransportTotalPrice = transportIncluded
    ? offerTransport.reduce(
      (acc: number, item: OfferTransport) =>
        item.approved === true ? acc + item.price : 0,
      0
    )
    : 0;
  const orderQty =
    items.reduce(
      (acc: number, item: ProductSalesOffer) => acc + (item?.qty || 0),
      0
    ) || 1;
  const transportUnitPrice = offerTransportTotalPrice / orderQty;
  return transportUnitPrice;
};
