import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, ReactNode } from 'react';

interface Props {
  children: ReactNode;
}
const ButtonTopIcon: FunctionComponent<Props & ImgType> = ({ children, ...props }) => {
  return (
    <span className="inline-block relative">
      <button {...props} className="h-16 w-16 rounded-md shadow cursor-pointer">
        <FontAwesomeIcon icon={faFilePdf} size="2x"/>
      </button>
      <span className="flex items-center justify-center absolute top-0 right-0 block h-4 w-4 transform -translate-y-1/2 translate-x-1/2 rounded-full ring-2 ring-white bg-gray-400">
        {children}
      </span>
    </span>
  );
};
export default ButtonTopIcon;
export type ImgType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;
