import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { WarehouseLevel } from '../../types/WarehouseLevel';
import { useToaster } from '../../context/toaster-provider';
import { ColumnContents } from '../../components/Table/DataTableCustom';
import { Card, CardBody, CardTitle } from '../../components/FormWithElemets/Card.style';
import WarehouseLevelForm from './WarehouseLevelForm';
import { handleDeleteWareHouseLevel, handleUpdateWareHouseLevelOrder } from '../WareHouse/GraphqlFunction';
import DataTableCustomWithOrder from '../../components/Table/DataTableCustomWithOrder';
import WarehouseSection from '../WarehouseSection/WarehouseSection';
import { Breadcrumbs } from 'baseui/breadcrumbs';
import { StyledLink } from 'baseui/link';
const DELETE_WAREHOUSE_LEVEL = loader(
  '../../graphql/warehouseLevel/DELETE_WAREHOUSE_LEVEL.graphql'
);
const UPDATE_WAREHOUSE_LEVEL_ORDER = loader(
  '../../graphql/warehouseLevel/UPDATE_WAREHOUSE_LEVEL_ORDER.graphql'
);
export interface ListContextType {
  warehouse: any;
  setWarehouse: Function;
}
export const WarehouseContext = React.createContext<ListContextType>(null);
const columnHeaders = [
  { name: 'warehouse_level.serial', col: { sm: 2 } },
  { name: 'warehouse_level.order', col: { sm: 2 } },
  { name: 'warehouse_level.name', col: { sm: 6 } },
  { name: 'transactions', col: { sm: 2 } },
];
interface ListProps {
  data: WarehouseLevel[];
  warehouseId: string;
}
const List: FunctionComponent<ListProps> = ({ data, warehouseId }) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const [items, setItems] = useState(null);
  const [warehouse, setWarehouse] = useState({
    warehouseLevelId: null,
    warehouseSectionId: null,
    warehouseRowId: null,
  });
  const columnContents: ColumnContents[] = [
    {
      name: 'serial',
      title: { name: 'warehouse_level.serial', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'order',
      title: { name: 'warehouse_level.order', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'name',
      title: { name: 'warehouse_level.name', custom: true },
      type: 'text',
      col: { sm: 6 },
    },
    {
      name: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleRemove(id);
          },
        },
        {
          label: intl.formatMessage({ id: 'button.warehouse_section' }),
          function: (id) => {
            setWarehouse({
              ...warehouse,
              warehouseLevelId: id,
            });
          },
        },
      ],
      title: { name: 'transactions', custom: true },
      type: 'buttons',
      col: { sm: 2 },
    },
  ];
  const [remove] = useMutation(DELETE_WAREHOUSE_LEVEL);
  const handleRemove = (Id) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
        update(cache, { data: { deleteWarehouseLevel } }) {
          handleDeleteWareHouseLevel(cache, deleteWarehouseLevel, warehouseId);
        },
      }).then(() => showToaster('deleted', 'positive'));
    }
  };
  const handleOrder = (items) => {
    setItems(items.map((item, index) => {
      return {
        ...item,
        order: index,
      };
    }));
    const newItemsOrders = items.map((item, index) => {
      return {
        id: item.id,
        order: index,
      };
    });
    updateOrder({
      variables: {
        params: newItemsOrders,
      },
    }).then(() => showToaster('updated', 'positive'));
  };
  const [updateOrder] = useMutation(UPDATE_WAREHOUSE_LEVEL_ORDER, {
    update(cache, { data: { updateWarehouseLevelOrder } }) {
      updateWarehouseLevelOrder?.status &&
      handleUpdateWareHouseLevelOrder(cache, items, warehouseId);
    },
  });
  return (
    <Card>
      <CardTitle title={<FormattedMessage id="menu.warehouse_level" />}></CardTitle>
      <CardBody style={{ padding: '5px' }}>
        <Breadcrumbs>
          {warehouse.warehouseLevelId && (
            <StyledLink
              key={warehouse.warehouseLevelId}
              style={{cursor: 'pointer'}}
              onClick={() =>
                setWarehouse({
                  warehouseLevelId: null,
                  warehouseSectionId: null,
                  warehouseRowId: null,
                })
              } 
            >
              {data.find((item) => item.id === warehouse.warehouseLevelId).name}
            </StyledLink>
          )}
          {warehouse.warehouseSectionId && (
            <StyledLink
              style={{cursor: 'pointer'}}
              key={warehouse.warehouseSectionId}
              onClick={() =>
                setWarehouse({
                  ...warehouse,
                  warehouseSectionId: null,
                  warehouseRowId: null,
                })
              }
            >
              {
                data
                  .find((level) => level.id === warehouse.warehouseLevelId)
                  .warehouseSection.find(
                    (section) => section.id === warehouse.warehouseSectionId
                  ).name
              }
            </StyledLink>
          )}
          {warehouse.warehouseRowId && (
            <StyledLink
              key={warehouse.warehouseSectionId}
              style={{cursor: 'pointer'}}
              onClick={() =>
                setWarehouse({
                  ...warehouse,
                  warehouseRowId: null,
                })
              }
            >
              {
                data
                  .find((level) => level.id === warehouse.warehouseLevelId)
                  .warehouseSection.find(
                    (section) => section.id === warehouse.warehouseSectionId
                  )
                  .warehouseRow.find((row) => row.id === warehouse.warehouseRowId).name
              }
            </StyledLink>
          )}
        </Breadcrumbs>
        {!warehouse.warehouseLevelId ? (
          <DataTableCustomWithOrder
            threeDot
            data={data}
            columnHeaders={columnHeaders}
            columnContents={columnContents}
            updateOrder={(items) => {
              handleOrder(items);
            }}
            form={({ item, setItemId }) => (
              <WarehouseLevelForm
                data={item}
                setItemId={setItemId}
                warehouseId={warehouseId}
              />
            )}
          />
        ) : (
          <WarehouseContext.Provider value={{ warehouse, setWarehouse }}>
            <WarehouseSection
              data={
                data.find((item) => item.id === warehouse.warehouseLevelId)
                  .warehouseSection
              }
              warehouseId={warehouseId}
            />
          </WarehouseContext.Provider>
        )}
      </CardBody>
    </Card>
  );
};
export default List;
