import React, { Fragment, FunctionComponent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGenderless } from '@fortawesome/pro-regular-svg-icons';
import { useDrawerState } from '../../context/DrawerContext';
import { ShopFloorOrderStatusLog } from '../../types/ShopFloorOrderStatusLog';
import { Card, CardBody, CardTitle } from '../../components/FormWithElemets/Card.style';
import { machineStatusColor } from '../ShopFloor/GraphqlFunction';
import { handleDate } from '../Functions/Utilities';
import { COLOR } from '../../components/FormWithElemets/Button/Button';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
interface ListType {
  shopFloorOrderStatusLog: ShopFloorOrderStatusLog[];
  machineryAndEquipment: string;
  product: string;
}
const ShopFloorOrderStatusHistory: FunctionComponent = () => {
  const { shopFloorOrderStatusLog, product }: ListType = useDrawerState('data');
  return (
    <Card>
      <CardTitle title={product}></CardTitle>
      <CardBody style={{ margin: '20px' }}>
        <div className="timeline timeline-6 mt-3">
          {shopFloorOrderStatusLog.map((item) => {
            return (
              <Fragment key={item.id}>
                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    {handleDate(new Date(item.insertedAt))
                      ?.toLocaleDateString()
                      .slice(0, 10) +
                      ' - ' +
                      handleDate(new Date(item.insertedAt))
                        ?.toLocaleTimeString()
                        .slice(0, 5)} 
                  </div>
                  <div className="timeline-badge">
                    <FontAwesomeIcon
                      icon={faGenderless}
                      color={machineStatusColor(item.shopFloorOrderStatus)}
                      size="2x"
                    />
                  </div>
                  <div
                    className="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
                    style={{ marginLeft: '15px' }}
                  >
                    {item.shopFloorOrderStatus.name}
                    <span
                      style={{
                        marginLeft: '20px',
                        marginRight: '5px',
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faUser}
                        size="1x"
                        color={machineStatusColor(item.shopFloorOrderStatus)}
                      />
                    </span>
                    {'  ' + item.user.name + '  ' + item.user.surname + '  '}
                  </div>
                </div>
                <div style={{ marginLeft: '165px', marginTop: '5px', display: 'block' }}>
                  {item.shopFloorOrderStopReason?.name && (
                    <span
                      style={{
                        backgroundColor: COLOR.yellow,
                        padding: '5px',
                        borderRadius: '5px',
                        marginLeft: '10px',
                      }}
                    >
                      {item.shopFloorOrderStopReason?.name}
                    </span>
                  )}
                  {item.shopFloorOrderCancelReason?.name && (
                    <span
                      style={{
                        backgroundColor: COLOR.yellow,
                        padding: '5px',
                        borderRadius: '5px',
                        marginLeft: '10px',
                      }}
                    >
                      {item.shopFloorOrderCancelReason?.name}
                    </span>
                  )}
                </div>
              </Fragment>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};
export default ShopFloorOrderStatusHistory;
