import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import FormControl from '../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../components/Input/Input';
import { useToaster } from '../../../context/toaster-provider';
import DateCustom from '../../DateCustom/DateCustom';
import { handleDate, inputDecimal } from '../../Functions/Utilities';
import SelectOperation from '../../SelectCustom/SelectOperation';
const ADD_PURCHASE_OFFER = loader(
  '../../../graphql/purchaseOffer/ADD_PURCHASE_OFFER.graphql'
);
const ADD_PURCHASE_OPERATION_OFFER = loader(
  '../../../graphql/purchaseOfferOperation/ADD_PURCHASE_OPERATION_OFFER.graphql'
);
interface PurchaseOperationOfferFormProps {
  purchaseOfferId?: string;
  offerId?:string;
  setClose?: Function;
  operation:[{id:string,label:string}];
  orderQty:number
}
const PurchaseOperationOfferForm: FunctionComponent<PurchaseOperationOfferFormProps> = ({
  purchaseOfferId,
  setClose,
  offerId,
  operation,
  orderQty
}) => {
  const { control, errors, setValue, register, handleSubmit } = useForm({
    defaultValues: {
      estimatedDate: [new Date()],
      operationId:operation,
      qty:orderQty||0
    }
  });
  const { showToaster } = useToaster();
  const [add] = useMutation(ADD_PURCHASE_OFFER);
  const [addItem] = useMutation(ADD_PURCHASE_OPERATION_OFFER);
  const onSubmit = ({ qty, operationId, estimatedDate }) => {
    if (purchaseOfferId) {
      addItem({
        variables: {
          purchaseOfferId: purchaseOfferId,
          operationId: operationId ? operationId[0].id : null,
          estimatedDate: handleDate(estimatedDate[0]).toISOString(),
          qty: +qty,
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleResetForm();
      });
    } else {
      add({
        variables: {
          approved: false,
          offerId:offerId,
          purchaseOperationOffer: [{
            operationId: operationId ? operationId[0].id : null,
            estimatedDate: handleDate(estimatedDate[0]).toISOString(),
            qty: +qty,
          }],
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleResetForm();
      });
    }
  };
  const handleResetForm = () => {
    setValue('operationId', undefined);
    setValue('qty', 0);
    setValue('estimatedDate', [new Date()]);
    setClose({operation:null});
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom bottom="xs">
        <Col xs={4}>
          <SelectOperation control={control} />
        </Col>
        <Col xs={2}>
          <DateCustom errors={errors} control={control} name="estimatedDate" />
        </Col>
        <Col xs={12} md={6} lg={4}>
          <FormControl label={<FormattedMessage id="purchaseOffer.qty" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="qty"
              inputRef={register({
                required: false,
              })}
              onChange={(event: any) => {
                setValue('qty', inputDecimal({ event }));
              }}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button
            style={{
              marginBottom: '16px',
            }}
            type="button"
            onClick={handleResetForm}
          >
            <FormattedMessage id={'button.close'} />
          </Button>
          <Button
            style={{
              marginBottom: '16px',
            }}
            type="submit"
          >
            <FormattedMessage id={'button.add'} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};

export default PurchaseOperationOfferForm;
