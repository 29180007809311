import React, { FunctionComponent, useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import CreatableSelect from '../../TailWinduiHTML/Form/CreatableSelect';
import { SearchInventory } from '../../../types/Inventory';
import { debounce } from '../../Functions/Utilities';

const SEARCH_INVENTORIES = loader(
  '../../../graphql/inventory/SEARCH_INVENTORIES.graphql'
);

interface Props {
  control: any;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  required?: boolean;
}
const CSelectInventory: FunctionComponent<Props> = ({
  control,
  isClearable = true,
  isDisabled = false,
  isRtl = false,
  isSearchable = true,
  required = false,
}) => {
  const [options, setOptions] = useState<{ value: string; label: string }[]>([]);
  const [loadOptionsEs, { data, loading }] = useLazyQuery<SearchInventory>(
    SEARCH_INVENTORIES
  );
  useEffect(() => {
    loadOptionsEs({
      variables: {
        full: true,
        name: null,
      },
    });
  }, [loadOptionsEs]);
  useEffect(() => {
    if (data) {
      const _options = data?.searchInventory.map((item) => {
        return { value: item?.id, label: item?.name };
      });
      setOptions(_options);
    }
  }, [data]);
  const handleChange = ([selected]) => {
    return selected ? [selected] : undefined;
  };
  const handleInputChange = debounce(function (term: string) {
    loadOptionsEs({
      variables: {
        full: true,
        name: term ? term : null,
      },
    });
  });
  return (
    <CreatableSelect
      options={options}
      name="inventory"
      control={control}
      required={required}
      isDisabled={isDisabled}
      isLoading={loading}
      isClearable={isClearable}
      isRtl={isRtl}
      isSearchable={isSearchable}
      handleChange={handleChange}
      handleInputChange={handleInputChange}
    />
  );
};
export default CSelectInventory;
