import React, { FunctionComponent, useCallback } from 'react';
import { useMutation } from '@apollo/react-hooks';
//import { useIntl } from 'react-intl';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { BlockProps } from 'baseui/block';
import { useDrawerDispatch } from '../../context/DrawerContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faTimes, faTimesOctagon } from '@fortawesome/pro-regular-svg-icons';
import { styled } from 'styletron-react';
import { useToaster } from '../../context/toaster-provider';
import { BillOfMaterialAttachment } from '../../types/BillOfMaterial';
import ImgTopIcon from '../TailWinduiHTML/Imgs/ImgTopIcon';
import Button from '../TailWinduiHTML/Form/Button';
import ButtonTopIcon from '../TailWinduiComponent/ButtonTopIcon';

interface ListTechicalImagesProps {
  imajes: BillOfMaterialAttachment[];
  deleteMutation: any;
}

const ListTechicalImages: FunctionComponent<ListTechicalImagesProps> = ({
  imajes,
  deleteMutation,
}) => {
  const { showToaster } = useToaster();
  const dispatch = useDrawerDispatch();
  const openDrawer = useCallback(
    ({ item }) => {
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'TECHICAL_IMAGES_PREVIEW',
        data: { url: item.attachment.url, fileType: item.attachment.fileType },
        widthComponent: '80%',
      });
    },
    [dispatch]
  );
  const [remove] = useMutation(deleteMutation);
  const handleRemove = (Id: string) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
      }).then(() => showToaster('deleted', 'positive'));
    }
  };
  const itemProps: BlockProps = {
    backgroundColor: '#ffffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <CardBody style={{ marginLeft: '8px', marginRight: '8px', marginBottom: '5px' }}>
      <FlexGrid
        flexGridColumnCount={[1, 4, 8]}
        flexGridColumnGap="scale800"
        flexGridRowGap="scale800"
      >
        {imajes &&
          imajes?.map((item: BillOfMaterialAttachment) => (
            <FlexGridItem key={item.id} {...itemProps} $style={{}}>
              {item.attachment.fileType === 'pdf' ? (
                <ButtonTopIcon onClick={() => openDrawer({ item })}>
                  <Button onClick={() => handleRemove(item.id)} color="transparent">
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </ButtonTopIcon>
              ) : (
                <ImgTopIcon
                  src={item.attachment.url}
                  alt={'technical'}
                  onClick={() => openDrawer({ item })}
                >
                  <Button onClick={() => handleRemove(item.id)} color="transparent">
                    <FontAwesomeIcon icon={faTimes} />
                  </Button>
                </ImgTopIcon>
              )}
            </FlexGridItem>
          ))}
      </FlexGrid>
    </CardBody>
  );
};
export default ListTechicalImages;
/*
 <DivTechnicalImages>
                <div
                  style={{
                    flexGrow: 1,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  {item.attachment.fileType === 'pdf' ? (
                    <div style={{ height: '85%' }}>
                      <Button
                        type="button"
                        onClick={() => openDrawer({ item })}
                        color={COLOR.transparent}
                        style={{ margin: 0, padding: 0 }}
                      >
                        <FontAwesomeIcon
                          icon={faFilePdf}
                          size="3x"
                          color={COLOR.grey}
                        />
                      </Button>
                    </div>
                  ) : (
                    <img
                      src={item.attachment.url}
                      onClick={() => openDrawer({ item })}
                      width="100%"
                      height="85%"
                      alt="technical"
                      style={{
                        cursor: 'pointer',
                        border: '1px solid rgba(215,218,215,1)',
                      }}
                    />
                  )}
                </div>
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                  <Button
                    type="button"
                    onClick={() => handleRemove(item.id)}
                    color={COLOR.transparent}
                    style={{ margin: 0, padding: 0 }}
                  >
                    <FontAwesomeIcon
                      icon={faTimesOctagon}
                      size="1x"
                      color={COLOR.grey}
                    />
                  </Button>
                </div>
              </DivTechnicalImages>
       
*/
