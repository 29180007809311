import React, { FunctionComponent, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';

interface ListRowProps {
  item: BillOfMaterialItem;
  handleItemDelete: Function;
  handleItemEdit: Function;
}
const ListItemBillOfMaterialItem: FunctionComponent<ListRowProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(item.id);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    }
  ];
  const _unit = useMemo(() => {
    const rawMaterial = item.inventory?.inventoryType?.id || item?.inventoryType?.id;
    return rawMaterial === "d5b8e917-9bc5-42e6-a23a-b721e72972a1" ?
        intl.formatMessage({ id: 'Gram' }) : intl.formatMessage({ id: 'Adet' }) || ''
}, [item])
  return (
    <RowCustom>
      <Col xs={12} md={7} lg={4}>
        <LabelCustom
          title={intl.formatMessage({ id: 'inventory.materialItemInventoryId' })}
        >
          {item.inventory?.name || item?.name || 'No Item'}
        </LabelCustom>
      </Col>
      <Col xs={12} md={5} lg={3}>
            <LabelCustom
                title={intl.formatMessage({ id: 'select.inventoryType' })}
            >
                {item.inventory?.inventoryType?.name || item?.inventoryType?.name || 'No Item'}
            </LabelCustom>
        </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'materialItem.partNumber' })}>
          {item?.partNumber || '-'}
        </LabelCustom>
      </Col>
      <Col sm={6} md={4} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'materialItem.quantity' })}>
        {(item?.quantity || '') + ' ' + _unit}
        </LabelCustom>
      </Col>
      {/* <Col sm={9} lg={3}>
        <LabelCustom title={intl.formatMessage({ id: 'company.vendor' })}>
          {item?.vendor?.companyShortName || '-'}
        </LabelCustom>
      </Col> */}
      <ColButton xs>
        <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
      </ColButton>
    </RowCustom>
  );
};
export default ListItemBillOfMaterialItem;

