import { Input, SIZE } from 'baseui/input';
import React, { Fragment, FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../components/FormWithElemets/FormControl';
import DateCustom from '../../DateCustom/DateCustom';
import { inputDecimal } from '../../Functions/Utilities';
import SelectBillOfMaterial from '../../SelectCustom/SelectBillOfMaterialCustom';
import SelectUnit from '../../SelectCustom/SelectUnit';

interface InputFormProps {
  errors: any;
  control: any;
  register: any;
  setValue: any;
  watch: any;
  update: boolean;
  handleResetForm: Function;
  handleAddOrUpdateItem: Function;
  setSubcontractSalesStatus: Function;
  estimatedDeliveryDate?: string;
}
const InputForm: FunctionComponent<InputFormProps> = ({
  errors,
  control,
  register,
  setValue,
  watch,
  update,
  handleAddOrUpdateItem,
  handleResetForm,
  setSubcontractSalesStatus,
  estimatedDeliveryDate,
}) => {
  const [cleanActive, setCleanActive] = useState(true);
  const [addActive, setAddActive] = useState(true);
  const [unit, setUnit] = useState(undefined);
  const { qty, billOfMaterialId, unitsId } = watch();
  useEffect(() => {
    setValue('unitsId', unit);
  }, [unit, setValue]);
  useEffect(() => {
    setCleanActive(() => !(qty !== '' || billOfMaterialId?.length > 0));
    setAddActive(
      () => !(qty !== '' && billOfMaterialId?.length > 0 && unitsId?.length > 0)
    );
    if (billOfMaterialId?.length === 0) {
      setUnit(undefined);
    }
  }, [setUnit, billOfMaterialId, qty, unitsId]);
  return (
    <RowCustom bottom="xs">
      <Col xs={4}>
        <SelectBillOfMaterial
          control={control}
          creatable={true}
          setSubcontractSalesStatus={setSubcontractSalesStatus}
          setUnit={setUnit}
        />
      </Col>
      <Col xs={2}>
        <DateCustom
          dateValue={update ? estimatedDeliveryDate || undefined : new Date().toString()}
          errors={errors}
          control={control}
          name="estimatedDeliveryDate"
          clearable={true}
        />
      </Col>
      <Col xs={2}>
        <FormControl label={<FormattedMessage id="offer.qty" />}>
          <Input
            type="text"
            name="qty"
            inputRef={register}
            size={SIZE.compact}
            onChange={(event) => {
              setValue('qty', inputDecimal({ event }));
            }}
          />
        </FormControl>
      </Col>
      <Col xs={2}>
        <SelectUnit control={control} disabled={!!unit} />
      </Col>
      <ColButton xs={2}>
        <Buttons
          cleanActive={cleanActive}
          addActive={addActive}
          handleAddOrUpdateItem={handleAddOrUpdateItem}
          handleResetForm={handleResetForm}
          update={update}
        />
      </ColButton>
    </RowCustom>
  );
};
export default InputForm;

interface ButtonsProps {
  cleanActive: boolean;
  addActive: boolean;
  handleResetForm: Function;
  update: boolean;
  handleAddOrUpdateItem: Function;
}
const Buttons: FunctionComponent<ButtonsProps> = ({
  cleanActive,
  addActive,
  handleResetForm,
  handleAddOrUpdateItem,
  update,
}) => {
  return (
    <Fragment>
      <Button
        type="button"
        disabled={cleanActive}
        onClick={() => {
          handleResetForm();
        }}
        style={{
          marginTop: '6px',
          marginBottom: '15px',
        }}
      >
        <FormattedMessage id={update ? 'button.close' : 'button.clean'} />
      </Button>
      <Button
        type={'button'}
        onClick={() => handleAddOrUpdateItem()}
        disabled={addActive}
        style={{
          marginTop: '6px',
          marginBottom: '15px',
        }}
      >
        <FormattedMessage id={'button.ok'} />
      </Button>
    </Fragment>
  );
};
