import React, { FunctionComponent } from 'react';
import { OperationSalesOffer } from '../../../../../types/OperationSalesOffer';
import { handleCeil, handleLocalDateString } from '../../../../Functions/Utilities';
import GridCol from '../../../../TailWinduiHTML/GridCol';

interface ListIteOperationSalesPreviewProps {
  item: OperationSalesOffer;
  transportUnitPrice: number;
}
const ListIteOperationSalesPreview: FunctionComponent<ListIteOperationSalesPreviewProps> = ({
  item,
  transportUnitPrice,
}) => {
  return (
    <div className="pt-3 grid print:grid-cols-12 grid-cols-12">
      <div className="lg:col-span-4  md:col-span-8 col-span-12  print:col-span-4">
        <GridCol title="offer.item">{item?.operation?.name || 'No Item...'}</GridCol>
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol title="offer.estimatedDeliveryDate">
          {handleLocalDateString(item?.estimatedDeliveryDate)}
        </GridCol>
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol title="offer.qty">{item?.qty}</GridCol>
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol title="offer.unitPrice">
          {handleCeil(item?.unitPrice + transportUnitPrice || 0) + ' ₺'}
        </GridCol>
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol title="offer.amount">
          {handleCeil((item?.unitPrice + transportUnitPrice) * item.qty) + ' ₺'}
        </GridCol>
      </div>
    </div>
  );
};
export default ListIteOperationSalesPreview;

/*
 <RowCustom>
            <Col xs={4}>
            <LabelCustom title={<FormattedMessage id="offer.item" />}>
              {item?.operation?.name || ''}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom
              title={<FormattedMessage id="offer.estimatedDeliveryDate" />}
            >
              {handleLocalDateString(item.estimatedDeliveryDate)}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.qty" />}>
              {item.qty} &nbsp; {item?.units?.name || 'No Unit'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
              {handleCeil((item?.unitPrice + transportUnitPrice || 0)) + ' ₺'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.amount" />}>
              {handleCeil((item?.unitPrice + transportUnitPrice) * item.qty) +
                ' ₺'}
            </LabelCustom>
          </Col>
        </RowCustom>
*/
