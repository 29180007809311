import React, { Fragment, FunctionComponent, memo } from 'react';
import { useIntl } from 'react-intl';
import {  DivButtonTitle } from '../../../../../components/FlexBox/FlexBox';
import { ColumnButtons } from '../../../../../components/TailWinduiTable/ColumnButtons';
import { AddColType } from './ListSubcontractSalesPreview';

interface SubcontractSalesHeadersProps {
  colOperationTitle: any;
  colMaterialTitle: any;
  setAddCol: Function;
}
const SubcontractSalesHeaders: FunctionComponent<SubcontractSalesHeadersProps> = ({
  colMaterialTitle,
  colOperationTitle,
  setAddCol,
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'colAdd.material' }),
      function: () => {
        setAddCol((prev: AddColType) => ({
          ...prev,
          addMaterialCol: true,
        }));
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'colAdd.operation' }),
      function: () => {
        setAddCol((prev: AddColType) => ({
          ...prev,
          addOperatinCol: true,
        }));
      },
      check_type: 'UPDATE',
    },
  ];
  const colLength =
    6 + (colOperationTitle?.length || 0) + (colMaterialTitle?.length || 0);

  return (
    <Fragment>
      <div className="text-sm print:text-sm font-medium divide-x   hidden print:grid print:grid-cols-12 lg:grid lg:grid-cols-12 border-b print:border-b">
        <div className="lg:col-span-1 print:col-span-1 h-full">
          <div className="grid print:grid print:grid-cols-3 lg:grid-cols-3 h-full divide-x">
            <div className="lg:col-span-1 print:col-span-1">{intl.formatMessage({ id: 'offer.no' })}</div>
            <div className="lg:col-span-2 print:col-span-2">
              {intl.formatMessage({ id: 'offer.itemCode' })}
            </div>
          </div>
        </div> 
        <div className="sm:col-span-12 md:col-span-6 lg:col-span-2 print:col-span-2">
          {intl.formatMessage({ id: 'offer.item' })}
        </div>
        <div className="sm:col-span-12 md:col-span-6 lg:col-span-9 print:col-span-9">
          <div
            className={`grid grid-cols-1 divide-x lg:grid-cols-${colLength} print:grid-cols-${colLength}`}
          >
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-1 print:col-span-1">
              {intl.formatMessage({ id: 'offer.revisionNo' })}
            </div>
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-1 print:col-span-1">
              {intl.formatMessage({ id: 'date.estimatedDeliveryDate' })}
            </div>
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-1 print:col-span-1">
              {intl.formatMessage({ id: 'offer.qty' })}
            </div>
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-1 print:col-span-1">
              {intl.formatMessage({ id: 'offer.materialAndOperation' })}
            </div>
            {colOperationTitle?.length > 0 && colOperationTitle.map((item: any) => item)}
            {colMaterialTitle?.length > 0 && colMaterialTitle.map((item: any) => item)}
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-1 print:col-span-1">
              {intl.formatMessage({ id: 'offer.unitPrice' })}
            </div>
            <div className="sm:col-span-6 md:col-span-3 lg:col-span-1">
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  margin: 0,
                  padding: 0,
                }}
              >
                <span style={{ display: 'inline-block' }}>
                  {intl.formatMessage({ id: 'offer.amount' })}
                </span>
                <span className="print:hidden">
                  <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DivButtonTitle>
        <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
      </DivButtonTitle>
    </Fragment>
  );
};
export default memo(SubcontractSalesHeaders);
