import React, { FunctionComponent, ReactNode } from 'react';

interface ButtonProps {
  type?: 'submit' | 'button';
  children: ReactNode;
  color?: BackgroundColor;
  opacity?: Opacity;
  className?: string;
}
const Button: FunctionComponent<
  ButtonProps &ButtonType
   
> = ({ type = 'button', children, color = 'gray', className, opacity, ...props }) => {
  const _disabled = props?.disabled
    ? 'cursor-not-allowed bg-gray-50'
    : 'bg-' + color + '-200';
  return (
    <button
      type={type}
      className={
        `${_disabled}
                 inline-flex items-center 
                 justify-center px-3 py-1.5 mx-0.5 my-1
                 border border-transparent 
                 font-medium rounded-md 
                 text-${color}-700 
                 hover:bg-${color}-300 
                 focus:outline-none 
                 focus:ring-2 
                 focus:ring-offset-2 
                 focus:ring-${color}-500 sm:text-sm ` + className
      }
      {...props}
    >
      {children}
    </button>
  );
};
export default Button;
export type BackgroundColor =
  | 'red'
  | 'yellow'
  | 'green'
  | 'blue'
  | 'purple'
  | 'indigo'
  | 'purple'
  | 'pink'
  | 'gray'
  | 'transparent';
type Opacity = 50 | 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900; 
export type ButtonType = React.DetailedHTMLProps<
React.ButtonHTMLAttributes<HTMLButtonElement>,
HTMLButtonElement
>