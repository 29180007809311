import React, { useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import { CardTitle, Card } from '../../components/FormWithElemets/Card.style';
import Button from '../../components/FormWithElemets/Button/Button';

import { loader } from 'graphql.macro';
import {
  handleAddInventoryType,
  handleUpdateInventoryType,
} from './GraphqlFunction';
const ADD_INVENTORY_TYPE = loader('../../graphql/inventory/ADD_INVENTORY_TYPE.graphql');

const UPDATE_INVENTORY_TYPE = loader(
  '../../graphql/inventory/UPDATE_INVENTORY_TYPE.graphql'
);
const InventoryTypeForm: React.FC = () => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name }) => {
    dataDispatch
      ? updateInventoryType({
          variables: {
            id: dataDispatch.id,
            name: name.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '),
            cons: name
              .replace(/^\s+|\s+$/g, '')
              .replace(/\s+/g, ' ')
              .toUpperCase()
              .split(' ')
              .join('_'),
          },
        })
      : addInventoryType({
          variables: {
            name: name.replace(/^\s+|\s+$/g, '').replace(/\s+/g, ' '),
            cons: name
              .replace(/^\s+|\s+$/g, '')
              .replace(/\s+/g, ' ')
              .toUpperCase()
              .split(' ')
              .join('_'),
            editable: true,
          },
        });
  };
  const [addInventoryType] = useMutation(ADD_INVENTORY_TYPE, {
    update(cache, { data: { createInventoryType } }) {
      handleAddInventoryType(cache, createInventoryType);
      closeDrawer();
    },
  });
  const [updateInventoryType] = useMutation(UPDATE_INVENTORY_TYPE, {
    update(cache, { data: { updateInventoryType } }) {
      handleUpdateInventoryType(cache, updateInventoryType);
      closeDrawer();
    },
  });
  return (
    <Card
      
    >
      <CardTitle title={
        <FormattedMessage
          id={dataDispatch ? 'inventory_type.update' : 'inventory_type.create'}
        />
      }></CardTitle>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="inventory_type.name" />}
          error={errors.name ? 'Please input a valid Name' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default InventoryTypeForm;
