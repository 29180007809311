import React, { FunctionComponent, useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { ColumnContents } from '../../components/Table/DataTableCustom';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import WarehouseRowForm from './WarehouseRowForm';
import {
  handleDeleteWareHouseRow, handleUpdateWareHouseRowOrder,
} from '../WareHouse/GraphqlFunction';
import DataTableCustomWithOrder from '../../components/Table/DataTableCustomWithOrder';
import { ListContextType, WarehouseContext } from '../WarehouseLevel/List';
import { WarehouseRow } from '../../types/WarehouseRow';
import WarehouseSheft from '../WarehouseShelf.tsx/WarehouseShelf';
const DELETE_WAREHOUSE_ROW = loader(
  '../../graphql/warehouseRow/DELETE_WAREHOUSE_ROW.graphql'
);
const UPDATE_WAREHOUSE_ROW_ORDER = loader(
  '../../graphql/warehouseRow/UPDATE_WAREHOUSE_ROW_ORDER.graphql'
);
const columnHeaders = [
  { name: 'warehouse_row.serial', col: { sm: 2 } },
  { name: 'warehouse_row.order', col: { sm: 2 } },
  { name: 'warehouse_row.name', col: { sm: 6 } },
  { name: 'transactions', col: { sm: 2 } },
];
interface ListProps {
  data: WarehouseRow[];
  warehouseId: string;
}
const List: FunctionComponent<ListProps> = ({ data, warehouseId }) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const [items, setItems] = useState(null);
  const { warehouse, setWarehouse } = useContext<ListContextType>(WarehouseContext);
  const columnContents: ColumnContents[] = [
    {
      name: 'serial',
      title: { name: 'warehouse_row.serial', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'order',
      title: { name: 'warehouse_row.order', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'name',
      title: { name: 'warehouse_row.name', custom: true },
      type: 'text',
      col: { sm: 6 },
    },
    {
      name: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleRemove(id);
          },
        },
        {
          label: intl.formatMessage({ id: 'button.warehouse_shelf' }),
          function: (id) => {
            setWarehouse({
              ...warehouse,
              warehouseRowId: id,
            });
          },
        },
      ],
      title: { name: 'transactions', custom: true },
      type: 'buttons',
      col: { sm: 2 },
    },
  ];
  const [remove] = useMutation(DELETE_WAREHOUSE_ROW);
  const handleRemove = (Id) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
        update(cache, { data: { deleteWarehouseRow } }) {
          handleDeleteWareHouseRow(cache, deleteWarehouseRow, warehouseId, warehouse);
        },
      }).then(() => showToaster('deleted', 'positive'));
    }
  };
  const handleOrder = (items) => {
    setItems(items.map((item, index) => {
      return {
        ...item,
        order: index,
      };
    }));
    const newItemsOrders = items.map((item, index) => {
      return {
        id: item.id,
        order: index,
      };
    });
    updateOrder({
      variables: {
        params: newItemsOrders,
      },
    }).then(() => showToaster('updated', 'positive'));
  };
  const [updateOrder] = useMutation(UPDATE_WAREHOUSE_ROW_ORDER, {
    update(cache, { data: { updateWarehouseRowOrder } }) {
      updateWarehouseRowOrder?.status &&
      handleUpdateWareHouseRowOrder(cache, items, warehouseId,warehouse);
    },
  });
  return (
    <CardBody style={{ padding: '5px' }}>
      {!warehouse.warehouseRowId ? (
        <DataTableCustomWithOrder
          threeDot
          data={data}
          columnHeaders={columnHeaders}
          columnContents={columnContents}
          updateOrder={(items) => {
            handleOrder(items);
          }}
          form={({ item, setItemId }) => (
            <WarehouseRowForm
              data={item}
              setItemId={setItemId}
              warehouseId={warehouseId}
            />
          )}
        />
      ) : (
        <WarehouseSheft
          data={data.find((item) => item.id === warehouse.warehouseRowId)?.warehouseShelf}
          warehouseId={warehouseId}
        />
      )}
    </CardBody>
  );
};
export default List;
