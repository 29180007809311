// @ts-nocheck
import React from 'react';
import { styled } from 'baseui';

//*****************************************/
export const Card = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
  marginBottom: '10px',
  padding: '30px',
  '@media all and (max-width: 1000px)': {
    paddingLeft: '10px',
    paddingRight: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
}));
export const Form = styled('form', ({ $theme }) => ({
  // minHeight: '100vh',
  backgroundColor: $theme.colors.backgroundF7,
  paddingBottom: '65px',
}));
export const Hr = styled('hr', ({ $theme }) => ({
  border: '0.6px solid #f1f1f1',
  margin: '20px',
  //borderRadius: '5px',
}));
export const ButtonGroup = styled('div', ({ $theme }) => ({
  padding: '10px 65px 20px 65px',
  display: 'flex',
  alignItems: 'center',
  position: 'fixed',
  bottom: '0',
  right: '0',
  width: '100%',
  backgroundColor: '#ffffff',
  boxShadow: '0 0 3px rgba(0, 0, 0, 0.1)',

  '@media only screen and (max-width: 767px)': {
    padding: '20px 30px',
  },
}));

export const Label = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'block',
  width: '100%',
  marginTop: '8px',
  marginBottom: '8px',
  padding: 0,
}));
/******************************/
export const RowTitle = styled('div', () => ({
  //Preview
  marginTop: '10px',
  marginRight: 0,
  marginLeft: 0,
  marginBottom: '3px',
  padding: 0,
  '@media all and (max-width: 768px)': {
    display: 'none',
  },
}));

//****************-Yeni-******************//
type LabelWithContentProps = {
  children: React.ReactNode;
  style?: any;
  title?: any;
};
const TitleC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  '@media all and (min-width: 769px)': {
    display: 'none !important',
  },
}));
const TContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  textAlign: 'right',
  margin: 0,
  padding: '1px',
  flex: '1',
  '@media all and (min-width: 769px)': {
    ...$theme.typography.font18,
    color: $theme.colors.textDark,
    margin: '0 !important',
    padding: '1px !important',
    textAlign: 'center !important',
    flex: '1',
  },
}));
export const TitleContent: React.FC<LabelWithContentProps> = ({ children, title }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <TitleC>{title}</TitleC>
      <TContent>{children}</TContent>
    </div>
  );
};

const LabelC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  '@media all and (max-width: 500px)': {
    minWidth: 'auto !important',
  },
}));

export const LContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  flex: '1',
}));
export const LabelContent: React.FC<LabelWithContentProps> = ({
  children,
  title,
  style,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '6px',
        marginTop: '6px',
      }}
    >
      <LabelC style={{ paddingTop: '5px', paddingRight: '10px', ...style }}>
        {title}
      </LabelC>
      <LContent style={{ flex: 1 }}>{children}</LContent>
    </div>
  );
};
