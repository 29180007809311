import { useQuery } from "@apollo/react-hooks";
import { TYPE } from "baseui/select";
import { loader } from "graphql.macro";
import React, { FunctionComponent } from "react";
import { FormattedMessage } from "react-intl";
import FormControl from "../../components/FormWithElemets/FormControl";
import { Select } from "../../components/FormWithElemets/Select/Select";
import { Materials } from "../../types/Material";
const GET_MATERIALS = loader('../../graphql/material/GET_MATERIALS.graphql');

interface RawMaterialSelectProps {
    errors?: any;
    control: any;
    required?:any;
    disabled?:boolean
  }
  const SelectRawMaterial: FunctionComponent<RawMaterialSelectProps> = ({
    errors,
    control,
    required=false,
    disabled=false
  }) => {
    const { data } = useQuery<Materials>(GET_MATERIALS);
    return (
      <FormControl
        label={<FormattedMessage id="product_material.raw_material" />}
        error={required && errors.rawMaterialId ? 'Please input a valid Raw Material' : null}
      >
        <Select
          data={data && data.materials}
          labelValue={'rawMaterial'}
          selectGrouped={true}
          controller={{
            name: 'rawMaterialId',
            type: TYPE.search,
            control,
            disabled:disabled,
            rules: { required: required },
            creatable: false,
            searchable: true,
            filterOutSelected: false,
            onChange: ([selected]) => {
              return selected.option ? selected.value : undefined;
            },
          }}
        />
      </FormControl>
    );
  };
  export default SelectRawMaterial;