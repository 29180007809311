import React, { FunctionComponent, useContext } from 'react';
import { handleDate } from '../../Functions/Utilities';
import InputForm from './InputForm';
import { Item, ItemsContext, UseFormContext } from './OperationSalesOfferAdd';

interface AddOperationSalesItemFormProps {
  item?: Item;
  itemIndex?: number;
  setItemIndex?: Function;
}
const AddOperationSalesItemForm: FunctionComponent<AddOperationSalesItemFormProps> = ({
  item,
  itemIndex = null,
  setItemIndex,
}) => {
  const { register, setValue, control, getValues, watch, errors } = useContext(
    UseFormContext
  );
  const { dispatchItems } = useContext(ItemsContext);
  const handleAddOrUpdateItem = () => {
    const { qty, operationId,unitsId, estimatedDeliveryDate, unitPrice } = getValues();
    if (qty !== '' && operationId) {
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            id: undefined,
            operationId: operationId,
            unitsId: unitsId,
            estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
            qty: qty,
            unitPrice: unitPrice,
          },
        });
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            id: item?.id,
            operationId: operationId,
            unitsId: unitsId,
            estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
            qty: qty,
            unitPrice: unitPrice,
          },
        });
      }
      handleResetForm();
    }
  };
  const handleResetForm = () => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('operationId', undefined);
      setValue('unitsId', undefined);
      setValue('estimatedDeliveryDate', [new Date()]);
      setValue('qty', '');
      setValue('unitPrice', '');
    }
  };
  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      item={item}
      handleResetForm={handleResetForm}
    />
  );
};
export default AddOperationSalesItemForm;
