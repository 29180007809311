import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import { useToaster } from '../../../../context/toaster-provider';
import { OperationSalesOffer } from '../../../../types/OperationSalesOffer';
import { handleDate } from '../../../Functions/Utilities';
import InputForm from './InputForm';
const UPDATE_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/UPDATE_OPERATION_SALES_OFFER.graphql'
);
interface ItemInputsProps {
  item: OperationSalesOffer;
  setClose?: Function;
}
const UpdateAddOperationSalesItems: FunctionComponent<ItemInputsProps> = ({
  item,
  setClose,
}) => {
  const { showToaster } = useToaster();
  const { register, setValue, control, handleSubmit, errors } = useForm({
    defaultValues: hadleDefaultValues(item)
  });
  const onSubmit = ({
    qty,
    operationId,
    estimatedDeliveryDate,
    unitPrice,
    unitsId,
  }) => {
    updateInlineOperation({
      variables: {
        id: item.id,
        operationSalesOffer: handleNewProductItem({
          qty,
          operationId,
          estimatedDeliveryDate,
          unitPrice: unitPrice || 0,
          unitsId,
        }),
      },
    }).then(() => {
      showToaster('updated', 'positive');
    });
    handleResetForm();
  };
  const handleResetForm = () => {
    if (item) {
      setClose(null);
    }
  };
  const [updateInlineOperation] = useMutation(UPDATE_OPERATION_SALES_OFFER);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputForm
        register={register}
        errors={errors}
        setValue={setValue}
        control={control}
        item={item}
        handleResetForm={handleResetForm}
      />
    </Form>
  );
};
export default UpdateAddOperationSalesItems;
const handleNewProductItem = ({
  qty,
  operationId,
  estimatedDeliveryDate,
  unitPrice,
  unitsId,
}) => {
  return {
    estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
    operationId: operationId[0].id,
    unitsId: unitsId[0].id,
    qty: +qty,
    unitPrice: +unitPrice,
  };
};
const hadleDefaultValues=(item:OperationSalesOffer)=>{
  return (item
  ? {
      operationId: item?.operation
        ? [{ id: item.operation.id, label: item.operation.name }]
        : undefined,
      qty:item?.qty | 0,
      estimatedDeliveryDate: [
        new Date(item.estimatedDeliveryDate || new Date()),
      ],
      unitPrice: item?.unitPrice || 0,
      unitsId: item?.units
        ? [{ id: item.units.id, label: item.units.name }]
        : undefined,
    }
  : {})
}