import React, { FunctionComponent } from 'react';
interface ListProps {
  innerShape?: string | undefined;
}
export const Hexagonal: FunctionComponent<ListProps> = ({
  innerShape = 'inner_full',
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="44.973"
      height="51.584"
      viewBox="0 0 88.973 102.584"
    >
      <g id="Group_18" data-name="Group 18" transform="translate(1.5 1.292)">
        <g id="Group_9" data-name="Group 9" transform="translate(-530.877 -100)">
          <g id="Group_1" data-name="Group 1">
            <line
              id="Line_7"
              data-name="Line 7"
              x1="42.986"
              y2="25.339"
              transform="translate(530.877 100)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_8"
              data-name="Line 8"
              x1="42.986"
              y1="25.339"
              transform="translate(573.864 100)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_9"
              data-name="Line 9"
              y2="49.321"
              transform="translate(530.877 125.339)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_10"
              data-name="Line 10"
              x1="42.986"
              y1="25.339"
              transform="translate(530.877 174.661)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_11"
              data-name="Line 11"
              x1="42.986"
              y2="25.339"
              transform="translate(573.864 174.661)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_12"
              data-name="Line 12"
              x1="0.452"
              y2="49.321"
              transform="translate(616.398 125.339)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
          </g>
          {innerShape === 'inner_hexagonal' && (
            <g id="Hexagonal_6" data-name="Hexagonal_6">
              <line
                id="Line_1"
                data-name="Line 1"
                x1="34.214"
                y2="20.168"
                transform="translate(540.877 110.407)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_2"
                data-name="Line 2"
                x1="34.214"
                y1="20.168"
                transform="translate(575.091 110.407)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_3"
                data-name="Line 3"
                y2="39.256"
                transform="translate(540.877 130.575)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_4"
                data-name="Line 4"
                x1="34.214"
                y1="20.168"
                transform="translate(540.877 169.832)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_5"
                data-name="Line 5"
                x1="34.214"
                y2="20.168"
                transform="translate(575.091 169.832)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
              <line
                id="Line_6"
                data-name="Line 6"
                x1="0.36"
                y2="39.256"
                transform="translate(608.945 130.575)"
                fill="none"
                stroke="#707070"
                strokeWidth="3"
              />
            </g>
          )}
        </g>
        {innerShape === 'inner_round' ? (
          <g id="Group_10" data-name="Group 10" transform="translate(-106 -100)">
            <g
              id="Ellipse_2"
              data-name="Ellipse 2"
              transform="translate(115 115)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <circle cx="34" cy="34" r="34" stroke="none" />
              <circle cx="34" cy="34" r="32.5" fill="none" />
            </g>
          </g>
        ) : innerShape === 'inner_square' ? (
          <g
            id="Square_9"
            data-name="Square 9"
            transform="translate(14 21)"
            fill="none"
            stroke="#707070"
            strokeWidth="3"
          >
            <rect width="58" height="58" stroke="none" />
            <rect x="1.5" y="1.5" width="55" height="55" fill="none" />
          </g>
        ) : (
          innerShape === 'inner_rectangle' && (
            <g
              id="Rectangle_10"
              data-name="Rectangle 10"
              transform="translate(10 28)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <rect width="68" height="42" stroke="none" />
              <rect x="1.5" y="1.5" width="65" height="39" fill="none" />
            </g>
          )
        )}
      </g>
    </svg>
  );
};
