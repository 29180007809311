import React, { useCallback, useEffect } from "react";
import { FormControl } from "baseui/form-control";
import { Input, SIZE } from "baseui/input";
import { useForm } from "react-hook-form";
import gql from "graphql-tag";
import { useDrawerState, useDrawerDispatch } from "../../context/DrawerContext";
import { useMutation } from "@apollo/react-hooks";
import { Form } from "../DrawerItems/DrawerItems.style";
import { Negative, Positive } from "../../components/General/NegativePositive";
import { Card, StyledBody } from "baseui/card";
import { FormattedMessage } from "react-intl";
import { Row, Col } from "../../components/FlexBox/FlexBox";
import Button from "../../components/FormWithElemets/Button/Button";

const ADD_STATUS = gql`
query query{
  statuses{
    id
    name
  }
}
`;

const GET_STATUSES = gql`
query query{
    statuses{
      id
      name
    }
  }
`;
const UPDATE_STATUS = gql`
query query{
  statuses{
    id
    name
  }
}
`;

export default function StatusForm(props) {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const dispatch = useDrawerDispatch();
  const data = useDrawerState("data");
  const closeDrawer = useCallback(() => dispatch({ type: "CLOSE_DRAWER" }), [
    dispatch,
  ]);
  useEffect(() => {
    if (data) {
      setValue("name", data.name);
    }
  }, [data, setValue]);
  const onSubmit = ({ name, operationType }) => {
    data
      ? updateStatus({
          variables: {
            id: data.id,
            name: name,
          },
        })
      : addStatus({
          variables: { name: name },
        });
  };
  const [addStatus] = useMutation(
    ADD_STATUS,
    {
        update(cache, { data: { insert_statuses: { returning } } }) {
            const { statuses } = cache.readQuery({ query: GET_STATUSES });
            cache.writeQuery({
                query: GET_STATUSES,
                data: { statuses: statuses.concat([returning[0]]) },
            });
            closeDrawer();
        }
    }
);
const [updateStatus] = useMutation(
    UPDATE_STATUS,
    {
        update(cache) {
            const {statuses} = cache.readQuery({query: GET_STATUSES});
            const newStatuses = statuses.map(status => {
                if (props.status.id === status.id) {
                    return {
                        ...status,
                        name: getValues().name
                    }
                } else {
                    return status;
                }
            });
            cache.writeQuery({
                query: GET_STATUSES,
                data: {statuses: newStatuses},
            });
            closeDrawer();
        }
    }
)
  return (
    <Card
      title={
        <FormattedMessage
          id={data ? "status.update" : "status.create"}
        />
      }
    >
      <StyledBody>
        <Form
          onSubmit={handleSubmit(onSubmit)}
          style={{
            height: "100%",
            position: "relative",
            backgroundColor: "transparent",
            margin: "50px",
          }}
        >
          <FormControl
            label={<FormattedMessage id="status.name" />}
            error={errors.name ? "Please input a valid Material Type" : null}
          >
            <Input
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              error={errors.name}
              overrides={
                errors.name ? { After: Negative } : { After: Positive }
              }
            />
          </FormControl>
          <Row
            end="md"
            style={{
              position: "absolute",
              bottom: "0px",
              width: "100%",
            }}
          >
            <Col md={3}>
              <Button
                onClick={closeDrawer}
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: "100%",
                    }),
                  },
                }}
              >
                <FormattedMessage id="button.close" />
              </Button>
            </Col>
            <Col md={3}>
              <Button
                type="submit"
                overrides={{
                  BaseButton: {
                    style: ({ $theme }) => ({
                      width: "100%",
                    }),
                  },
                }}
              >
                <FormattedMessage
                  id={data ? "button.update" : "button.create"}
                />
              </Button>
            </Col>
          </Row>
        </Form>
      </StyledBody>
    </Card>
  );
}
