import React, {
  Fragment,
  FunctionComponent,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../components/NoResult/NoResult';
import { ExecutionPlanType } from '../../../types/ExecutionPlan';
import ListStep from '../ExecutionPlanningStep/ListStep';
import ListInput from '../ExecutionPlanningStepInput/ListInput';
import ListOutput from '../ExecutionPlanningOutput/ListOutput';
import { PurchaseOffer } from '../../../types/Purchase';

const GET_EXECUTION_PLANNING = loader(
  '../../../graphql/executionPlanning/GET_EXECUTION_PLANNING.graphql'
);
const S_GET_EXECUTION_PLANNING = loader(
  '../../../graphql/executionPlanning/S_GET_EXECUTION_PLANNING.graphql'
);
const initialState = {
  component: 'STEP',
  data: null,
};
export const ComponentStatusContext = React.createContext(null);
type State = {
  component: 'STEP' | 'STEP_INPUT' | 'STEP_OUTPUT';
  data: any;
  executionPlanningStepId: string;
};
type Action =
  | { type: 'STEP'; data?: any }
  | {
    type: 'STEP_INPUT';
    data?: any;
    executionPlanningStepId: string;
  }
  | {
    type: 'STEP_OUTPUT';
    data?: any;
    executionPlanningStepId: string;
  };
const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'STEP':
      return {
        component: 'STEP',
        data: action.data,
      };
    case 'STEP_INPUT':
      return {
        component: 'STEP_INPUT',
        data: action.data,
        executionPlanningStepId: action.executionPlanningStepId,
      };
    case 'STEP_OUTPUT':
      return {
        component: 'STEP_OUTPUT',
        data: action.data,
        executionPlanningStepId: action.executionPlanningStepId,
      };
    default:
      return state;
  }
};
interface ExecutionPlanningProps {
  executionPlanningId: string;
  billOfMaterialId: string;
  setExecutionStatus?: Function;
  offerId?: string
  orderQty?: number
  purchaseOffer?:PurchaseOffer
}
const ExecutionPlanning: FunctionComponent<ExecutionPlanningProps> = ({
  executionPlanningId,
  billOfMaterialId,
  setExecutionStatus,
  offerId,
  orderQty,
  purchaseOffer
}) => {
  const [inputMaterialIds, setInputMaterialIds] = useState([]);
  const [componentStatus, dispatchComponentStatus] = useReducer(reducer, initialState);
  const { subscribeToMore, data, error, loading } = useQuery<ExecutionPlanType>(
    GET_EXECUTION_PLANNING,
    {
      variables: { id: executionPlanningId },
    }
  );
  useEffect(() => {
    if (data) {
      const inputMaterialIdsTemp = data.executionPlan.executionPlanStep
        .map((item) => {
          return item.executionPlanStepInput.map((itm) => itm.inventory?.id);
        })
        .flat(1);
      setInputMaterialIds(inputMaterialIdsTemp);
    }
  }, [data]);
  useEffect(() => {
    subscribeToMore({
      document: S_GET_EXECUTION_PLANNING,
      variables: {
        id: executionPlanningId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.executionPlan;
        return Object.assign({}, prev, {
          executionPlan: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, executionPlanningId]);
  useEffect(() => {
    const { component, executionPlanningStepId } = componentStatus;
    if (component === 'STEP') {
      dispatchComponentStatus({
        type: 'STEP',
        data: null,
      });
    } else if (component === 'STEP_INPUT') {
      dispatchComponentStatus({
        type: 'STEP_INPUT',
        data: data.executionPlan.executionPlanStep.find(
          (item) => item.id === executionPlanningStepId
        ).executionPlanStepInput,
        executionPlanningStepId: executionPlanningStepId || '2',
      });
    } else if (component === 'STEP_OUTPUT') {
      dispatchComponentStatus({
        type: 'STEP_OUTPUT',
        data: data.executionPlan.executionPlanStep.find(
          (item) => item.id === executionPlanningStepId
        ).executionPlanStepOutput,
        executionPlanningStepId: executionPlanningStepId || '2',
      });
    }
  }, [data]);
  useEffect(() => {
    setExecutionStatus && setExecutionStatus(componentStatus?.component || '');
  }, [componentStatus, setExecutionStatus]);
  return (
    //  <Can I="LIST" a="EXECUTION-PLANNINGS">
    <Fragment>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
            componentStatus.component === 'STEP' && (
              <ComponentStatusContext.Provider
                value={{
                  dispatchComponentStatus,
                  executionPlanningId,
                  billOfMaterialId,
                }}
              >
                <ListStep items={data.executionPlan} offerId={offerId}
                  purchaseOffer={purchaseOffer}
                  orderQty={orderQty} />
              </ComponentStatusContext.Provider>
            )
          )}
      {componentStatus.component === 'STEP_INPUT' && (
        <ComponentStatusContext.Provider
          value={{
            dispatchComponentStatus,
            componentStatus,
            billOfMaterialId,
            inputMaterialIds,
          }}
        >
          <ListInput />
        </ComponentStatusContext.Provider>
      )}
      {componentStatus.component === 'STEP_OUTPUT' && (
        <ComponentStatusContext.Provider
          value={{
            dispatchComponentStatus,
            componentStatus,
          }}
        >
          <ListOutput />
        </ComponentStatusContext.Provider>
      )}
    </Fragment>
    //  </Can>
  );
};

export default ExecutionPlanning;
