import React, { useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';

const ADD_MATERIAL_TYPE = loader('../../graphql/materialType/ADD_MATERIAL_TYPE.graphql');

const GET_MATERIAL_TYPES = loader(
  '../../graphql/materialType/GET_MATERIAL_TYPES.graphql'
);
const UPDATE_MATERIAL_TYPE = loader(
  '../../graphql/materialType/UPDATE_MATERIAL_TYPE.graphql'
);

export default function MaterialTypeForm(props) {
  const { register, handleSubmit, errors, setValue, getValues } = useForm();
  const dispatch = useDrawerDispatch();
  const data = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (data) {
      setValue('name', data.name);
    }
  }, [data, setValue]);
  const onSubmit = ({ name }) => {
    data
      ? updateMaterialTypes({
          variables: {
            id: data.id,
            name: name,
          },
        })
      : addMaterialTypes({
          variables: { name: name },
        });
  };
  const [addMaterialTypes] = useMutation(ADD_MATERIAL_TYPE, {
    update(cache, { data }) {
      const { materialTypes } = cache.readQuery({ query: GET_MATERIAL_TYPES });
      cache.writeQuery({
        query: GET_MATERIAL_TYPES,
        data: {
          materialTypes: materialTypes.concat([data.createMaterialType]),
        },
      });
      closeDrawer();
    },
  });
  const [updateMaterialTypes] = useMutation(UPDATE_MATERIAL_TYPE, {
    update(cache) {
      const { materialTypes } = cache.readQuery({ query: GET_MATERIAL_TYPES });
      const newMaterialTypes = materialTypes.map((materialType) => {
        if (data.id === materialType.id) {
          return {
            ...materialType,
            name: getValues().name,
          };
        } else {
          return materialType;
        }
      });
      cache.writeQuery({
        query: GET_MATERIAL_TYPES,
        data: { materialTypes: newMaterialTypes },
      });
      closeDrawer();
    },
  });
  return (
    <Card
      title={
        <FormattedMessage id={data ? 'material_type.update' : 'material_type.create'} />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="material_type.name" />}
          error={errors.name ? 'Please input a valid Material Type' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={data ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
