import React, { FunctionComponent } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { Form } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage } from 'react-intl';
import {  Col, RowCustom } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { Unit } from '../../types/Units';
import { useToaster } from '../../context/toaster-provider';

const ADD_UNIT = loader('../../graphql/unit/ADD_UNIT.graphql');
const GET_UNITS = loader('../../graphql/unit/GET_UNITS.graphql');
const UPDATE_UNIT = loader('../../graphql/unit/UPDATE_UNIT.graphql');
interface ListProps {
  data?: Unit;
  setItemId?: Function;
}
const UnitForm: FunctionComponent<ListProps> = ({ data, setItemId }) => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: data ? data.name : '',
      symbol: data ? data.symbol : '',
    },
  });
  const onSubmit = ({ name, symbol }) => {
    console.log('seded', symbol);
    data
      ? update({
          variables: {
            id: data.id,
            name: name,
            symbol: symbol,
          },
        }).then(() => {
          showToaster('updated', 'positive');
          setItemId(null);
        })
      : add({ variables: { name: name, symbol: symbol } }).then(() => {
          showToaster('created', 'positive');
          setValue('name', '');
        });
  };
  const [add] = useMutation(ADD_UNIT, {
    update(cache, { data: { createUnit } }) {
      const { units } = cache.readQuery({ query: GET_UNITS });
      cache.writeQuery({
        query: GET_UNITS,
        data: { units: units.concat(createUnit) },
      });
    },
  });
  const [update] = useMutation(UPDATE_UNIT, {
    update(cache, { data: { updateUnit } }) {
      const { units } = cache.readQuery({ query: GET_UNITS });
      const newUnitss = units.map((unit) => {
        if (updateUnit.id === unit.id) {
          return {
            ...updateUnit,
          };
        } else {
          return unit;
        }
      });
      cache.writeQuery({
        query: GET_UNITS,
        data: { units: newUnitss },
      });
    },
  });
  const handleReset = () => {
    setValue('name', '');
    setValue('symbol', '');
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <RowCustom style={{ paddingTop: '12px' }}>
        <Col md={6}>
          <FormControl error={errors?.name ? 'Please input a valid Name' : null}>
            <Input
              type="text"
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              error={!!errors.name}
              overrides={errors.name ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
        </Col>
        <Col md={4}>
          <FormControl>
            <Input type="text" size={SIZE.compact} name="symbol" inputRef={register} />
          </FormControl>
        </Col>
        <Col md={2}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <Button
              type="button"
              style={{ marginBottom: '16px' }}
              onClick={() => {
                data ? setItemId() : handleReset();
              }}
            >
              <FormattedMessage id={data ? 'button.close' : 'button.clean'} />
            </Button>
            <Button type="submit" style={{ marginBottom: '16px' }}>
              <FormattedMessage id="button.ok" />
            </Button>
          </div>
        </Col>
      </RowCustom>
    </Form>
  );
};
export default UnitForm;
