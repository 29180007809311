import { useQuery } from '@apollo/react-hooks';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import NoResult from '../../components/NoResult/NoResult';
import { SearchCompany } from '../../types/Company';
import { handleCompanyInformation } from '../Functions/Utilities';

const SEARCH_COMPANY = loader('../../graphql/company/SEARCH_COMPANY.graphql');
interface useCompanyInformationProps {
  companyId: string;
}
const useCompanyInformation = ({ companyId }: useCompanyInformationProps) => {
  const intl = useIntl();
  const [information, setInformation] = useState<Information>(null);
  const { data, loading, error } = useQuery<SearchCompany>(SEARCH_COMPANY, {
    variables: {
      full: false,
      name: null,
    },
  });
  useEffect(() => {
    if (data) {
      const _information = handleCompanyInformation(companyId, data.searchCompany);
      setInformation(_information);
    }
  }, [data, companyId]);

  const companyInformation = loading ? (
    <StyledSpinnerNext />
  ) : error ? (
    <NoResult />
  ) : data && information ? (
    <div className="grid print:grid-cols-1 grid-cols-1">
      <div className="col-span-1 mb-1">
        <div className="flex items-center space-x-3">
          <h3 className="text-gray-800 text-base font-medium truncate">
            {intl.formatMessage({ id: 'company.companyName' })}
          </h3>
          <span className="flex-1 text-right inline-block px-2 py-0.5">
            {information?.companyName}
          </span>
        </div>
      </div>
      <div className="col-span-1">
        <div className="text-right mb-0.5">
          {information?.address || '........................'}
        </div>
        <div className="text-right mb-0.5">
          {(information?.county || '..............') +
            ' / ' +
            (information?.city || '..............')}
        </div>
        <div className="text-right">
          {(information?.taxOffice || '..............') +
            ' - ' +
            (information?.taxNumber || '..............')}
        </div>
      </div>
    </div>
  ) : null;
  return {
    companyInformation,
    currency: information?.currency,
  };
};
export default useCompanyInformation;
type Information = {
  companyId: string;
  companyName: string;
  address: string;
  county: string;
  city: string;
  taxNumber: string;
  taxOffice: string;
  currency: string;
};
