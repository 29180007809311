import React, { useEffect, useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { faLanguage } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Popover, { PLACEMENT } from '../../../components/Popover/Popover';
import Notification from '../../../components/Notification/Notification';
import { AuthContext } from '../../../context/auth';
import { SETTINGS } from '../../../settings/constants';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import DropdownButton, {
  SIZE,
  KIND,
  SHAPE,
} from '../../../components/FormWithElemets/Button/DropdownButton';

import { ipcRenderer } from '../../../appRuntime';
import { NotificationIcon, AlertDotIcon } from '../../../components/AllSvgIcon';
import {
  TopbarWrapper,
  Logo,
  LogoImage,
  TopbarRightSide,
  ProfileImg,
  Image,
  AlertDot,
  NotificationIconWrapper,
  UserDropdownItem,
  NavLink,
  LogoutBtn,
  MenuWrapper,
} from './Topbar.style';
import logo_image from '../../../image/Prodobit.png';
import { useLocale } from '../../../context/language-provider';
import { Avatar } from 'baseui/avatar';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import {
  faChevronLeft,
  faChevronRight,
  faPrint,
  faSyncAlt,
} from '@fortawesome/pro-duotone-svg-icons';
import { Block } from 'baseui/block';
import Input from '../../../components/Input/Input';
import { useToaster } from '../../../context/toaster-provider';
import { loader } from 'graphql.macro';
import { useSubscription } from '@apollo/react-hooks';
import { version } from '../../../../package.json';

const S_GET_APP_COMMANDS = loader(
  '../../../graphql/appCommand/S_GET_APP_COMMANDS.graphql'
);
const MySwal = withReactContent(Swal);
const Topbar = ({ refs, handleToggleSidebar, profileData, toggled }) => {
  const intl = useIntl();
  const { data: dataAppCommand } = useSubscription(S_GET_APP_COMMANDS);
  const [latestVersion, setLatestVersion] = useState(version);
  const [changeVersion, setChangeVersion] = useState(false);
  useEffect(() => {
    if (dataAppCommand) {
      setChangeVersion(dataAppCommand.appCommands.message !== version ? true : false);
      setLatestVersion(dataAppCommand.appCommands.message);
    }
  }, [dataAppCommand]);
  const { showToaster } = useToaster();
  let history = useHistory();
  const { signout } = useContext(AuthContext);
  const [barcodeValue, setBarcodeValue] = useState('');
  const { changeLanguage } = useLocale();
  const languages = [
    { id: 'en', label: 'English', function: changeLanguage },
    { id: 'tr', label: 'Türkçe', function: changeLanguage },
  ];
  const updater = (event: any, data: string) => {
    showToaster(data, 'positive');
  };
  const updaterError = (event: any, data: string) => {
    showToaster(data, 'warning');
  };
  useEffect(() => {
    if (ipcRenderer) {
      ipcRenderer.on('updater', updater);
      return () => {
        ipcRenderer.removeListener('updater', updater);
      };
    }
  });
  useEffect(() => {
    if (ipcRenderer) {
      ipcRenderer.on('updater-error', updaterError);
      return () => {
        ipcRenderer.removeListener('updater-error', updaterError);
      };
    }
  });
  let userLogout = () => {
    console.log('logout bekle');
    signout({
      variables: {},
    })
      .then((result:any) => {
        console.log('set', result);
      })
      .catch((error:any) => {
        console.log('error', error); // <-- OK
      });
  };
  const handleBarcode = ({ close, value }) => {
    if (value.length > 1) {
      let barcodeValueTemp = value;
      while (barcodeValueTemp.indexOf('*') > -1) {
        barcodeValueTemp = barcodeValueTemp.replace('*', '-');
      }
      close();
      history.replace(`/product-record-barcode/${barcodeValueTemp}`);
    }
  };
  useEffect(() => {
    if (ipcRenderer) {
      ipcRenderer.on('user-logout', userLogout);
      return () => {
        ipcRenderer.removeListener('user-logout', userLogout);
      };
    }
  });

  useEffect(() => {
    if (changeVersion) {
      if (ipcRenderer) {
        ipcRenderer.send('change-version', latestVersion);
      } else {
        MySwal.fire({
          title: <p>{intl.formatMessage({ id: 'update.version' })}</p>,
          footer: <p>{latestVersion}</p>,
          showCancelButton: true,
          showLoaderOnConfirm: true,
          confirmButtonText: intl.formatMessage({ id: 'update.update' }),
          cancelButtonText: intl.formatMessage({ id: 'update.ignore' }),
          preConfirm: () => {
            window.location.reload(changeVersion);
          },
          onOpen: () => {},
        }).then(
          (result) =>
            result.isDismissed &&
            MySwal.fire({
              title: <p>{intl.formatMessage({ id: 'update.not_forget' })}</p>,
              showLoaderOnConfirm: true,
              confirmButtonText: intl.formatMessage({ id: 'button.ok' }),
            })
        );
      }
    }
  }, [changeVersion, intl, latestVersion]);
  return (
    <TopbarWrapper ref={refs}>
      <Logo>
        <Link to="/">
          <LogoImage src={logo_image} alt="prodobit" />
        </Link>
      </Logo>
      <MenuWrapper>
        <Button
          onClick={() => handleToggleSidebar(true)}
          color="#35C795"
          endEnhancer={() =>
            toggled ? (
              <FontAwesomeIcon icon={faChevronLeft} size="1x" />
            ) : (
              <FontAwesomeIcon icon={faChevronRight} size="1x" />
            )
          }
        >
          Menu
        </Button>
      </MenuWrapper>
      <TopbarRightSide>
        {changeVersion && (
          <Button onClick={() => window.location.reload(true)} color={COLOR.transparent}>
            <FontAwesomeIcon icon={faSyncAlt} color={COLOR.red} />
          </Button>
        )}
        <Popover
          focusLock
          content={({ close }) => (
            <Block $style={{display:'flex'}} padding={'5px'}>
              <Input
                size={SIZE.mini}
                name={'shoFloorId'}
                onChange={(e:any) => {
                  setBarcodeValue((e.target as HTMLTextAreaElement).value);
                  (e.target as HTMLTextAreaElement).value.length === 36 &&
                    handleBarcode({
                      close,
                      value: (e.target as HTMLTextAreaElement).value,
                    });
                }}
                value={barcodeValue}
                overrides={{
                  InputContainer: {
                    style: () => {
                      return { backgroundColor: '#ffff' };
                    },
                  },
                }}
              />
              <Button
                onClick={() =>
                  handleBarcode({
                    close,
                    value: barcodeValue,
                  })
                }
              >
                <FormattedMessage id="button.ok"/>
              </Button>
            </Block>
          )}
          onClick={() => setBarcodeValue('')}
          accessibilityType="tooltip"
          placement={PLACEMENT.bottomRight}
          overrides={{
            Body: {
              style: {
                width: '330px',
                zIndex: 2,
              },
            },
            Inner: {
              style: {
                backgroundColor: '#ffffff',
              },
            },
          }}
        >
          <NotificationIconWrapper>
            {' '}
            {/* <NotificationIcon /> */}
            <FontAwesomeIcon icon={faPrint} />
            <AlertDot>{/* <FontAwesomeIcon icon={faPrint} /> */}</AlertDot>
          </NotificationIconWrapper>
        </Popover>

        <Popover
          content={({ close }) => (
            <Notification
              data={profileData}
              onClear={close}
              changeVersion={changeVersion}
            />
          )}
          accessibilityType="tooltip"
          placement={PLACEMENT.bottomRight}
          overrides={{
            Body: {
              style: {
                width: '330px',
                zIndex: 2,
              },
            },
            Inner: {
              style: {
                backgroundColor: '#ffffff',
              },
            },
          }}
        >
          <NotificationIconWrapper>
            <NotificationIcon />
            <AlertDot>
              <AlertDotIcon />
            </AlertDot>
          </NotificationIconWrapper>
        </Popover>
        <DropdownButton
          items={languages}
          kind={KIND.minimal}
          size={SIZE.default}
          shape={SHAPE.pill}
        >
          <FontAwesomeIcon icon={faLanguage} size="2x" />
        </DropdownButton>
        <Popover
          content={({ close }) => (
            <UserDropdownItem>
              <NavLink to={SETTINGS} exact={false} onClick={close}>
                <FormattedMessage id="topbar.settings" />
              </NavLink>
              <LogoutBtn
                onClick={() => {
                  userLogout();
                  close();
                }}
              >
                <FormattedMessage id="topbar.logout" />
              </LogoutBtn>
            </UserDropdownItem>
          )}
          accessibilityType="tooltip"
          placement={PLACEMENT.bottomRight}
          overrides={{
            Body: {
              style: () => ({
                width: '220px',
                zIndex: 2,
              }),
            },
            Inner: {
              style: {
                backgroundColor: '#ffffff',
              },
            },
          }}
        >
          {profileData && profileData?.profilePhoto ? (
            <ProfileImg>
              <Image src={profileData && profileData?.profilePhoto} alt="user" />
            </ProfileImg>
          ) : (
            <ProfileImg>
              <Avatar name={profileData?.name + ' ' + profileData?.surname} />
            </ProfileImg>
          )}
        </Popover>
      </TopbarRightSide>
    </TopbarWrapper>
  );
};

export default Topbar;
