import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { Fragment, FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, Row, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../../components/Input/Input';
import { useToaster } from '../../../../context/toaster-provider';
import { PurchaseMaterialOffer } from '../../../../types/PurchaseMaterialOffer';
import DateCustom from '../../../DateCustom/DateCustom';
import { handleDate, inputDecimal } from '../../../Functions/Utilities';
import SelectInventoryType from '../../../SelectCustom/SelectInventoryType';
import SelectUnit from '../../../SelectCustom/SelectUnit';
const UPDATE_PURCHASE_MATERIAL_OFFER = loader(
  '../../../../graphql/purchaseOfferMaterial/UPDATE_PURCHASE_MATERIAL_OFFER.graphql'
);
interface UpdatePurchaseMaterialOfferFormProps {
  item?: PurchaseMaterialOffer;
  setItemAddShow?: Function;
  setItemId?: Function;
}
const UpdatePurchaseMaterialOfferForm: FunctionComponent<UpdatePurchaseMaterialOfferFormProps> = ({
  item,
  setItemId,
  setItemAddShow,
}) => {
  const { showToaster } = useToaster();
  const { control, errors, setValue, register, handleSubmit } = useForm({
    defaultValues: handleDefaultValues({ item }),
  });
  const [update] = useMutation(UPDATE_PURCHASE_MATERIAL_OFFER);
  const onSubmit = ({
    name,
    qty,
    inventoryTypeId,
    unitsId,
    estimatedDate,
  }) => {
      update({
        variables: {
          id: item.id,
          ...handleNameOrInventoryIdUpdate({
            item,
            inventoryTypeId,
            unitsId,
            name,
            rawMaterialId:item?.rawMaterial?.id || '',
            materialGeometryId:item?.materialGeometry?.id || '',
          }),
          estimatedDate: handleDate(estimatedDate[0]).toISOString(),
          qty: +qty,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
  };
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('inventoryId', undefined);
      setValue('inventoryTypeId', undefined);
      setValue('unitsId', undefined);
      setValue('estimatedDate', [new Date()]);
      setValue('qty', 0);
      setItemAddShow(false);
    }
  };
  return (
    <Fragment>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RowCustom bottom="xs">
          <Col xs={12} md={5} lg={5}>
              <FormControl label={<FormattedMessage id="inventory.inventoryId" />}>
                <Input
                  type="text"
                  size={SIZE.compact}
                  name="name"
                  inputRef={register({
                    required: false,
                  })}
                  disabled={item?.inventory}
                  clearOnEscape
                />
              </FormControl>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <DateCustom errors={errors} control={control} name="estimatedDate" />
          </Col>
          <Col xs={12} md={3} lg={3}>
            <Row style={{ margin: 0, padding: 0 }}>
              <Col style={{ margin: 0, padding: 0 }} xs={9}>
                <FormControl label={<FormattedMessage id="purchaseOffer.qty" />}>
                  <Input
                    type="text"
                    size={SIZE.compact}
                    name="qty"
                    inputRef={register({
                      required: false,
                    })}
                    onChange={(event: any) => {
                      setValue('qty', inputDecimal({ event }));
                    }}
                    clearOnEscape
                  />
                </FormControl>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={5} lg={4}>
            <SelectUnit control={control} disabled={!!item?.inventory} />
          </Col>
          <Col xs={12} md={5} lg={4}>
            <SelectInventoryType
              control={control}
              disabled={!!item?.inventory}
            />
          </Col>
          <ColButton xs>
            <Button
              style={{
                marginBottom: '16px',
              }}
              type="button"
              onClick={handleResetForm}
            >
              <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
            </Button>
            <Button
              style={{
                marginBottom: '16px',
              }}
              type="submit"
            >
              <FormattedMessage id={item ? 'button.update' : 'button.add'} />
            </Button>
          </ColButton>
        </RowCustom>
      </Form>
    </Fragment>
  );
};

export default UpdatePurchaseMaterialOfferForm;

const handleDefaultValues = ({ item }) => {
  console.log("------->item",item)
  return item
    ? {
        name: item.inventory?.name || item?.name,
        inventoryId: undefined,
        inventoryTypeId: [
          {
            id: item.inventory?.inventoryType?.id || item.inventoryType?.id,
            label: item.inventory?.inventoryType?.name || item?.inventoryType?.name,
          },
        ],
        unitsId: [
          {
            id: item.inventory?.units?.id || item.units.id,
            label: item.inventory?.units?.name || item.units.name,
          },
        ],
        qty: item.qty,
        estimatedDate: [new Date(item?.estimatedDate || new Date())],
      }
    : {
        name: '',
        inventoryId: undefined,
        inventoryTypeId: undefined,
        unitsId: undefined,
        qty: 0,
        estimatedDate: [new Date(new Date())],
      };
};
const handleNameOrInventoryIdUpdate = ({
  item,
  inventoryTypeId,
  unitsId,
  name,
  rawMaterialId,
  materialGeometryId,
}) => {
  const nameOrInventoryId = item?.inventory
    ? { inventoryId: item?.inventory?.id }
    : {
        name: name,
        inventoryTypeId: inventoryTypeId ? inventoryTypeId[0].id : null,
        unitsId: unitsId ? unitsId[0].id : null,
        rawMaterialId: rawMaterialId,
        materialGeometryId: materialGeometryId,
      };
  return nameOrInventoryId;
};

