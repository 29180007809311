// @ts-nocheck
import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Datepicker } from 'baseui/datepicker';
import tr from 'date-fns/locale/tr';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { RowTitle, TitleContent } from '../Invoice/Invoice.style';
import { Box, BoxContent } from './Shift.style';
import { Notification, KIND } from 'baseui/notification';
import { loader } from 'graphql.macro';

const ADD_SHIFT = loader('../../graphql/shift/ADD_SHIFT.graphql');
const UPDATE_SHIFT = loader('../../graphql/shift/UPDATE_SHIFT.graphql');
const GET_SHIFTS = loader('../../graphql/shift/GET_SHIFTS.graphql');
const GET_DAYS = loader('../../graphql/shift/GET_DAYS.graphql');
const GET_TIME_GROUPS = loader('../../graphql/shift/GET_TIME_GROUPS.graphql');

const ShiftForm = () => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
    clearError,
  } = useForm();
  const [timeList, setTimeList] = useState([]);
  const [timeIndex, setTimeIndex] = useState(null);
  const [timeListControl, setTimeListControl] = useState(false);
  const dispatch = useDrawerDispatch();
  const [defaultDate, setDefaultDate] = useState([new Date(), new Date()]);
  const { data: dataDay } = useQuery(GET_DAYS);
  const { data: dataGroup } = useQuery(GET_TIME_GROUPS);
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
      setValue('overtime', dataDispatch.overtime);
      setValue('description', dataDispatch.description);
      setValue('dateStartEnd', [
        new Date(dataDispatch.startDate),
        new Date(dataDispatch.endDate),
      ]);
      setDefaultDate([new Date(dataDispatch.startDate), new Date(dataDispatch.endDate)]);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name, overtime, description, dateStartEnd }) => {
    if (dataDispatch) {
      updateShift({
        variables: {
          id: dataDispatch.id,
          name: name,
          overtime: overtime,
          description: description,
          startDate: getValues().dateStartEndControl ? dateStartEnd[0][0] : null,
          endDate: getValues().dateStartEndControl ? dateStartEnd[0][1] : null,
        },
      }).then(() => console.log('Shift has been updated'));
    } else {
      if (timeList.length > 0) {
        timeList.map((item: { daysId: { id: any }[]; timeGroupId: { id: any }[] }) => {
          item.daysId = item.daysId && item.daysId[0].id;
          item.timeGroupId = item.timeGroupId && item.timeGroupId[0].id;
          return item;
        });
        addShift({
          variables: {
            name: name,
            overtime: overtime,
            description: description,
            startDate: getValues().dateStartEndControl ? dateStartEnd[0][0] : null,
            endDate: getValues().dateStartEndControl ? dateStartEnd[0][1] : null,
            time: timeList,
          },
        });
      } else {
        setTimeListControl(true);
      }
    }
  };
  const [addShift] = useMutation(ADD_SHIFT, {
    update(cache, { data: { createShiftDetailWithTime } }) {
      const { shifts } = cache.readQuery({
        query: GET_SHIFTS,
      });
      cache.writeQuery({
        query: GET_SHIFTS,
        data: {
          shifts: shifts.concat(createShiftDetailWithTime),
        },
      });
      closeDrawer();
    },
  });
  const [updateShift] = useMutation(UPDATE_SHIFT, {
    update(cache) {
      const { shifts } = cache.readQuery({
        query: GET_SHIFTS,
      });
      const newShits = shifts.map((item) => {
        if (dataDispatch.id === item.id) {
          return {
            ...item,
            name: getValues().name,
            overtime: getValues().overtime,
            description: getValues().description,
            startDate: getValues().dateStartEndControl
              ? getValues().dateStartEnd[0][0]
              : null,
            endDate: getValues().dateStartEndControl
              ? getValues().dateStartEnd[0][1]
              : null,
          };
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_SHIFTS,
        data: { shifts: newShits },
      });
      closeDrawer();
    },
  });

  const handleTimeAddOrUpdateItem = () => {
    const { daysId, timeGroupId } = getValues();
    if (daysId !== undefined && timeGroupId !== undefined) {
      setTimeListControl(false);
      if (timeIndex === null) {
        setTimeList([
          ...timeList,
          {
            daysId: daysId,
            timeGroupId: timeGroupId,
          },
        ]);
      } else {
        const updateItem = [...timeList];
        updateItem.map((item, index) => {
          if (index === timeIndex) {
            item.daysId = daysId;
            item.timeGroupId = timeGroupId;
            return item;
          } else {
            return item;
          }
        });
        setTimeList(updateItem);
        setTimeIndex(null);
      }
      resetTimeForm();
    }
  };
  const editTimeItem = ({ daysId, timeGroupId }, index) => {
    setValue('daysId', daysId);
    setValue('timeGroupId', timeGroupId);
    setTimeIndex(index);
    clearError();
  };
  const deleteTimeItem = (idx) => {
    const updateItem = timeList.filter((item, index) => index !== idx);
    setTimeList(updateItem);
  };
  const resetTimeForm = () => {
    setValue('daysId', undefined);
    setValue('timeGroupId', undefined);
  };
  return (
    <Card
      style={{ padding: 0 }}
      title={<FormattedMessage id={dataDispatch ? 'shift.update' : 'shift.create'} />}
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <Row bottom="xs" style={{ margin: 0, padding: 0 }}>
          <Col md={8}>
            <FormControl
              label={<FormattedMessage id="shift.name" />}
              error={errors.name ? 'Please input a valid Name' : null}
            >
              <Input
                size={SIZE.compact}
                name="name"
                inputRef={register({
                  required: true,
                })}
                error={errors.name}
                overrides={errors.name ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
          <Col md={4} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Controller
              as={
                <Checkbox
                  overrides={{
                    Label: {
                      style: () => {
                        return {
                          marginTop: '7px',
                          marginBottom: '16px',
                        };
                      },
                    },
                    Checkmark: {
                      style: () => {
                        return {
                          marginTop: '7px',
                          marginBottom: '16px',
                        };
                      },
                    },
                  }}
                >
                  <FormattedMessage id="shift.overtime" />
                </Checkbox>
              }
              name="overtime"
              type="checkbox"
              control={control}
              labelPlacement={LABEL_PLACEMENT.left}
              defaultValue={false}
            />
          </Col>
        </Row>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col xs={12}>
            <FormControl
              label={<FormattedMessage id="shift.description" />}
              error={errors.description ? 'Please input a valid Description' : null}
            >
              <Input
                size={SIZE.compact}
                name="description"
                inputRef={register({
                  required: true,
                })}
                error={errors.description}
                overrides={errors.description ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col md={5}>
            <Controller
              as={
                <Checkbox
                  overrides={{
                    Label: {
                      style: () => {
                        return {
                          marginTop: '7px',
                          marginBottom: '10px',
                        };
                      },
                    },
                    Checkmark: {
                      style: () => {
                        return {
                          marginTop: '7px',
                          marginBottom: '10px',
                        };
                      },
                    },
                  }}
                >
                  <FormattedMessage id="shift.dateStartEnd" />
                </Checkbox>
              }
              name="dateStartEndControl"
              type="checkbox"
              control={control}
              labelPlacement={LABEL_PLACEMENT.right}
              defaultValue={false}
            />
          </Col>
          {watch().dateStartEndControl && (
            <Col md={7}>
              <FormControl
                // label={<FormattedMessage id="shift.dateStartEnd" />}
                error={
                  errors.dateStartEnd ? 'Please select a valid Date Start and End' : null
                }
              >
                <Controller
                  as={
                    <Datepicker
                      size={SIZE.compact}
                      locale={tr}
                      mask={null}
                      value={defaultDate}
                      formatString="d/MM/yyyy"
                    />
                  }
                  name="dateStartEnd"
                  rules={{ required: true }}
                  control={control}
                  range
                  valueName="selected"
                  onChange={([selected]) => {
                    setDefaultDate(selected.date);
                    return selected ? [selected.date] : undefined;
                  }}
                  error={errors.dateStartEnd}
                  overrides={
                    errors.dateStartEnd ? { After: Negative } : { After: Positive }
                  }
                />
              </FormControl>
            </Col>
          )}
        </Row>
        {timeListControl && (
          <Notification
            overrides={{
              Body: { style: { width: 'auto' } },
            }}
            kind={KIND.negative}
          >
            {() => 'Please select a valid Day ve Time Group'}
          </Notification>
        )}
        <Box>
          <RowTitle style={{ margin: '7px 0' }}>
            <Row
              middle="xs"
              around="xs"
              style={{
                padding: 0,
                margin: 0,
                fontWeight: 'bold',
                color: '#454b68',
                textAlign: 'center',
              }}
            >
              <Col sm={12} md={5} lg={4}>
                <FormattedMessage id="shift.days" />
              </Col>
              <Col xs={8} sm={8} md={2} lg={4}>
                <FormattedMessage id="shift.timeGroup" />
              </Col>
              <Col xs={4} sm={4} md={3} lg={2}></Col>
            </Row>
          </RowTitle>
          {timeList &&
            timeList.map((item, index) => (
              <BoxContent key={index}>
                <Row
                  middle="xs"
                  around="xs"
                  style={{
                    margin: 0,
                    padding: 0,
                    width: '100%',
                  }}
                >
                  <Col sm={12} md={5} lg={4}>
                    <TitleContent title={<FormattedMessage id="shift.days" />}>
                      {item.daysId ? item.daysId[0]?.label : '...'}
                    </TitleContent>
                  </Col>
                  <Col xs={8} sm={8} md={2} lg={4}>
                    <TitleContent title={<FormattedMessage id="shift.timeGroup" />}>
                      {item.timeGroupId ? item.timeGroupId[0]?.label : '...'}
                    </TitleContent>
                  </Col>
                  <Col xs={4} sm={4} md={3} lg={3} style={{ textAlign: 'right' }}>
                    <Button
                      type="button"
                      style={{ paddingLeft: '15px', paddingRight: '15px' }}
                      onClick={() => {
                        deleteTimeItem(index);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrashAlt} size="1x" />
                    </Button>
                    <Button
                      type="button"
                      style={{ paddingLeft: '15px', paddingRight: '15px' }}
                      onClick={() => editTimeItem(item, index)}
                    >
                      <FontAwesomeIcon icon={faEdit} size="1x" />
                    </Button>
                  </Col>
                </Row>
              </BoxContent>
            ))}
          <Row bottom="xs" style={{ width: '100%' }}>
            <Col sm={12} md={5} lg={4}>
              <FormControl
                label={<FormattedMessage id="shift.days" />}
                overrides={{
                  Label: {
                    style: ({ $theme }) => {
                      return {
                        color: '#454b68',
                      };
                    },
                  },
                }}
              >
                <Select
                  data={dataDay && dataDay.days}
                  controller={{
                    name: 'daysId',
                    control: control,
                    rules: { required: false },
                    onChange: ([selected]) => {
                      return selected.option ? selected.value : undefined;
                    },
                  }}
                />
              </FormControl>
            </Col>
            <Col sm={12} md={5} lg={6}>
              <FormControl
                label={<FormattedMessage id="shift.timeGroup" />}
                overrides={{
                  Label: {
                    style: ({ $theme }) => {
                      return {
                        color: '#454b68',
                      };
                    },
                  },
                }}
              >
                <Select
                  data={dataGroup && dataGroup.timeGroups}
                  controller={{
                    name: 'timeGroupId',
                    control: control,
                    rules: { required: false },
                    onChange: ([selected]) => {
                      return selected.option ? selected.value : undefined;
                    },
                  }}
                />
              </FormControl>
            </Col>
            <Col
              sm={4}
              md={2}
              lg={2}
              style={{ textAlign: 'right', marginBottom: '12px' }}
            >
              <Button
                type="submit"
                disabled={getValues().daysId && getValues().timeGroupId ? false : true}
                onClick={() => {
                  handleTimeAddOrUpdateItem();
                }}
                style={{
                  width: '100%',
                  marginTop: '6px',
                  marginBottom: '4px',
                }}
              >
                <FormattedMessage
                  id={timeIndex === null ? 'button.add' : 'button.update'}
                />
              </Button>
            </Col>
          </Row>
        </Box>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ShiftForm;
