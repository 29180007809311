import React, { useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { handleAddInventoryCategory, handleUpdateInventoryCategory } from './GraphqlFunction';
import { CardTitle, Card } from '../../components/FormWithElemets/Card.style';


const ADD_INVENTORY_CATEGORY = loader(
  '../../graphql/inventory/ADD_INVENTORY_CATEGORY.graphql'
);

const UPDATE_INVENTORY_CATEGORY = loader(
  '../../graphql/inventory/UPDATE_INVENTORY_CATEGORY.graphql'
);

const InventoryCategoryForm: React.FC = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name }) => {
    dataDispatch
      ? updateInventoryCategory({
          variables: {
            id: dataDispatch.id,
            name: name,
          },
        })
      : addInventoryCategory({
          variables: { name: name },
        });
  };
  const [addInventoryCategory] = useMutation(ADD_INVENTORY_CATEGORY, {
    update(cache, { data: {createInventoryCategory} }) {
       handleAddInventoryCategory(cache,createInventoryCategory)
       closeDrawer();
    },
  });
  const [updateInventoryCategory] = useMutation(UPDATE_INVENTORY_CATEGORY, {
    update(cache, {data: {updateInventoryCategory}}) {
      handleUpdateInventoryCategory(cache,updateInventoryCategory)
      closeDrawer();
    },
  });
  return (
    <Card
     
    >
      <CardTitle  title={
        <FormattedMessage
          id={dataDispatch ? 'inventory_category.update' : 'inventory_category.create'}
        />
      } ></CardTitle>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="inventory_category.name" />}
          error={errors.name ? 'Please input a valid Inventory Category' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default InventoryCategoryForm;
