import { Input, SIZE } from 'baseui/input';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import FormControl from '../../../../../components/FormWithElemets/FormControl';
import { inputDecimal } from '../../../../Functions/Utilities';
import { Label } from '../../../../../components/FormWithElemets/Label.style';
import Button from '../../../../../components/FormWithElemets/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { useForm } from 'react-hook-form';
import { Form } from '../../../../../components/FormWithElemets/Form.style';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../../../context/toaster-provider';
import { useMutation } from '@apollo/react-hooks';
import { ContextSubcontractSalesOfferId } from '../ListItemSuncontractSales';
import { BillOfMaterialItem } from '../../../../../types/BillOfMaterial';
import { SubcontractSalesOfferMaterialPrice } from '../../../../../types/SubcontractSalesOfferMaterialPrice';

const UPDATE_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferMaterialPrice/UPDATE_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE.graphql'
);
const ADD_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferMaterialPrice/ADD_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE.graphql'
);

interface ItemDetailMaterialFormProps {
  itemAdd: {
    itemMaterial: BillOfMaterialItem;
    itemPrice: SubcontractSalesOfferMaterialPrice;
  };
  subcontractSalesOfferPriceId: string;
  setItemAdd: Function;
}
const ItemDetailMaterialForm: FunctionComponent<ItemDetailMaterialFormProps> = ({
  itemAdd,
  setItemAdd,
  subcontractSalesOfferPriceId,
}) => {
  const { itemMaterial, itemPrice } = itemAdd;
  const { showToaster } = useToaster();
  const { subcontractSalesOfferId } = useContext(
    ContextSubcontractSalesOfferId
  );
  const [profitAmount, setProfitAmount] = useState(0);
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues:
    {
      qty: itemPrice?.qty || 0,
      unitPrice: itemPrice?.unitPrice || 0,
      amount: itemPrice?.amount || 0,
      profitRate: itemPrice?.profitRate || 0,
    }
  });
  const { profitRate, amount } = watch();
  useEffect(() => {
    //const _amount = Math.ceil(100 * (+qty * +unitPrice)) / 100;
    //setValue('amount', _amount);
    const _profitAmount = Math.ceil((100 * (amount * profitRate)) / 100) / 100;
    setProfitAmount(_profitAmount);
  }, [setValue, profitRate]);
  const onSubmit = ({ qty, amount, unitPrice, profitRate }) => {
    itemPrice
      ? update({
        variables: {
          id: itemPrice?.id,
          amount: +amount,
          qty: +qty,
          unitPrice: +unitPrice,
          profitRate: +profitRate,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        setItemAdd(null);
      })
      : add({
        variables: {
          billOfMaterialItemId: itemMaterial.id,
          subcontractSalesOfferId: subcontractSalesOfferId,
          subcontractSalesOfferPriceId: subcontractSalesOfferPriceId,
          inventoryId: itemMaterial?.inventory?.id,
          amount: +amount || 0,
          qty: +qty,
          unitPrice: +unitPrice,
          profitRate: +profitRate,
        },
      }).then(() => {
        showToaster('created', 'positive');
        setItemAdd(null);
      });
  };
  const [add] = useMutation(ADD_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE);
  const [update] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom middle="xs">
        <Col sm={12} md={8} lg={4}>
          <Label
            styleTitle={{ width: '100%' }}
            title={<FormattedMessage id="materialPrice.materialName" />}
          >
            {itemMaterial?.inventory?.name ||
              itemMaterial?.name ||
              'No Inventory'}
          </Label>
        </Col>
        <Col sm={6} md={4} lg={2}>
          <FormControl label={<FormattedMessage id="materialPrice.qty" />}>
            <Input
              type="text"
              name="qty"
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              endEnhancer={itemMaterial?.inventory?.units?.name || 'No units'}
              onChange={(event) => {
                setValue('qty', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={6} md={4} lg={2}>
          <FormControl
            label={<FormattedMessage id="materialPrice.unitPrice" />}
          >
            <Input
              type="text"
              name="unitPrice"
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('unitPrice', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={6} md={4} lg={2}>
          <FormControl
            label={<FormattedMessage id="materialPrice.amountPrice" />}
          >
            <Input
              type="text"
              name="amount"
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('amount', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl
            label={<FormattedMessage id="operationPrice.profitRate" />}
          >
            <Input
              type="text"
              name={'profitRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('profitRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={(profitAmount || 0) + '₺'}
            />
          </FormControl>
        </Col>
        <ColButton xs>
          {/* <Button
            style={{ marginTop: '16px' }}
            type="button"
            onClick={() =>alert("Daha yapılmadı")}
          >
            <FontAwesomeIcon icon={faCalculator} />
          </Button> */}
          <Button
            style={{ marginTop: '16px' }}
            type="button"
            onClick={() => setItemAdd(null)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button style={{ marginTop: '16px' }} type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default ItemDetailMaterialForm;
