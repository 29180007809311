import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import { LabelCustom } from '../../../components/FormWithElemets/Label.style';
import { handleLocalDateString } from '../../Functions/Utilities';
import { Item as ItemType } from './AddSubContractSalesForm';

interface ItemProps {
  item: ItemType;
  index: number;
  handleItemDelete: Function;
  handleItemEdit: Function;
  setSubcontractSalesStatus: Function;
}
const ListItemSubcontractSalesItem: FunctionComponent<ItemProps> = memo(
  ({ item, index, handleItemDelete, handleItemEdit, setSubcontractSalesStatus }) => {
    return (
      <RowCustom>
        <Col xs={4}>
          <LabelCustom title={<FormattedMessage id="offer.item" />}>
            <Button
              type="button"
              color={COLOR.transparent}
              onClick={() => {
                setSubcontractSalesStatus({
                  status: 'UpdateBillOfMaterial',
                  billOfMaterialName: '',
                  billOfMaterialId: item.billOfMaterialId[0].id,
                  orderQty:item.qty
                });
              }}
            >
              <FontAwesomeIcon icon={faEdit} color={COLOR.grey} />
            </Button>
            {item.billOfMaterialId ? item.billOfMaterialId[0]?.label : ''}
          </LabelCustom>
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.estimatedDeliveryDate" />}>
            {item?.estimatedDeliveryDate ? handleLocalDateString(item?.estimatedDeliveryDate):""}
          </LabelCustom>
        </Col>
        <Col xs={4}>
          <LabelCustom title={<FormattedMessage id="offer.qty" />}>
            {item.qty} &nbsp; {item.unitsId ? item.unitsId[0]?.label : ''}
          </LabelCustom>
        </Col>
        <ColButton xs={2}>
          <Button
            type="button"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
            onClick={() => {
              handleItemDelete(index);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="1x" />
          </Button>
          <Button
            type="button"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
            onClick={() => handleItemEdit(index)}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </Button>
        </ColButton>
      </RowCustom>
    );
  }
);
export default ListItemSubcontractSalesItem;
