import React, { FunctionComponent } from 'react';
import { RowCustom } from '../../../../components/FlexBox/FlexBox';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';
import { PurchaseMaterialOffer } from '../../../../types/PurchaseMaterialOffer';
import ListItemBillOfMaterialItemCol from '../../BillOfMaterialItem/UpdateOrAdd/LisItemBillOfMaterialItemCol';
import BillOfMaterialItemPurchaseOffer from './BillOfMaterialItemListPurchaseOffer';

interface ListItemBillOfMaterialItemRowProps {
    item: BillOfMaterialItem;
    buttons: any,
    itemPurchaseMaterialOffer: PurchaseMaterialOffer[]
}
const ListItemBillOfMaterialItemRow: FunctionComponent<ListItemBillOfMaterialItemRowProps> = ({ item, buttons,itemPurchaseMaterialOffer }) => {
    return <RowCustom>
        <ListItemBillOfMaterialItemCol buttons={buttons} item={item} />
        <BillOfMaterialItemPurchaseOffer item={itemPurchaseMaterialOffer}/>
    </RowCustom>
}

export default ListItemBillOfMaterialItemRow;