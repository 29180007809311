import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useToaster } from '../../../../context/toaster-provider';
import { ProductSalesOfferIDs } from '../../../../types/ProductSalesOffer';
import ListItemProductSalesItem from '../../ProductSales/ListItemProductSalesItem';
import ItemHeaders from '../../ProductSales/ItemHeaders';
import { Item as ItemType } from '../../ProductSales/ProductSalesOfferAdd';
import UpdateAddProductSalesItem from './UpdateAddProductSalesItem';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
const DELETE_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/DELETE_PRODUCT_SALES_OFFER.graphql'
);
const GET_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/GET_PRODUCT_SALES_OFFER.graphql'
);
const S_GET_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/S_GET_PRODUCT_SALES_OFFER.graphql'
);
interface ListProductSalesItemProps {
  //itemTemps: ProductSalesOffer[];
  offerId: string;
}
const ListProductSalesItem: FunctionComponent<ListProductSalesItemProps> = ({
  offerId,
}) => {
  const { showToaster } = useToaster();
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<ProductSalesOfferIDs>(GET_PRODUCT_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_PRODUCT_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.productSalesOffer;
        return Object.assign({}, prev, {
          productSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const [itemIndex, setItemIndex] = useState(null);
  const newItemsLength = useMemo(() => {
    return data?.productSalesOffer?.productSalesOffer?.length || 0;
  }, [data]);
  const [itemAddShow, setItemAddShow] = useState(false);
  useEffect(()=>{
    setItemAddShow(()=>newItemsLength > 0 ? false : true)
  },[newItemsLength])
  const [items, setItems] = useState<ItemType[]>();
  useEffect(() => {
    data && setItems(() => itemsEdit(data?.productSalesOffer?.productSalesOffer));
  }, [data]);
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
  }, []);
  const [remove] = useMutation(DELETE_PRODUCT_SALES_OFFER);
  const handleItemDelete = useCallback(
    (index: number) => {
      if (window.confirm('Are you sure !')) {
        remove({
          variables: { id: items[index].id },
        }).then(() => {
          showToaster('deleted', 'positive');
        });
      }
    },
    [items, remove, showToaster]
  );
  return (
    loading ? <StyledSpinnerNext /> : error ? <NoResult /> : data &&
    <Fragment>
      <ItemHeaders
        itemsLenght={newItemsLength}
        setItemAddShow={setItemAddShow}
        itemAddShow={itemAddShow}
      />
      {itemAddShow  && (
        <UpdateAddProductSalesItem
          offerId={offerId}
          itemIndex={itemIndex}
          setItemIndex={setItemIndex}
        />
      )}
      {items &&
        items.map((item: ItemType, index: number) => {
          return index !== itemIndex ? (
            <ListItemProductSalesItem
              key={index}
              item={item}
              index={index}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
            />
          ) : (
            <UpdateAddProductSalesItem
              key={item.id}
              item={item}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
            />
          );
        })}
    </Fragment>
  );
};
const itemsEdit = (itemTemps: any) => {
  return itemTemps.map((item: any) => {
    return {
      id: item.id,
      productId: [{ id: item.product.id, label: item.product.name }],
      unitsId: [
        {
          id: item.product.inventory.units.id,
          label: item.product.inventory.units.name,
        },
      ],
      productRevisionId: item.productRevision?.id,
      estimatedDeliveryDate: item.estimatedDeliveryDate,
      qty: item.qty,
      unitPrice: item.unitPrice,
    };
  });
};
export default ListProductSalesItem;
