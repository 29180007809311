import { useMutation } from '@apollo/react-hooks';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Card, CardBody } from '../../../components/FormWithElemets/Card.style';
import { useDrawerDispatch } from '../../../context/DrawerContext';
import { useToaster } from '../../../context/toaster-provider';
const CHANGE_SHOP_FLOOR_ORDER_STATUS = loader(
  '../../../graphql/shopFloorOrderStatus/CHANGE_SHOP_FLOOR_ORDER_STATUS.graphql'
);
interface ListCompletedProps {
  shopFloorOrderId: string;
  shopFloorOrderStatusId: string;
  productRecordEmpty: boolean;
  partialComplete:boolean;
}
const CompleteForm: FunctionComponent<ListCompletedProps> = ({
  shopFloorOrderId,
  shopFloorOrderStatusId,
  productRecordEmpty,
  partialComplete
}) => {
  const { showToaster } = useToaster();
  const [checked, setChecked] = useState(false);
  const [update] = useMutation(CHANGE_SHOP_FLOOR_ORDER_STATUS);
  const dispatch = useDrawerDispatch();
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const handleChangeStatus = () => {
    update({
      variables: {
        id: shopFloorOrderId,
        shopFloorOrderStatusId: shopFloorOrderStatusId,
        shopFloorOrderStopReasonId: null,
      },
    }).then(
      ({
        data: {
          changeShopFloorOrderStatus: { shopFloorOrderStatus },
        },
      }) => {
        showToaster(shopFloorOrderStatus.name, 'positive', false);
        closeDrawer();
      }
    );
  };
  return (
    <Card
      style={{ display: 'flex', position: 'fixed', bottom: 0, right: 0, width: '100%' }}
    >
      <CardBody style={{ width: '100%' }}>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col md={12}>
            <Checkbox
              disabled={productRecordEmpty && !partialComplete}
              checked={checked}
              onChange={(e) => setChecked((e.target as HTMLInputElement).checked)}
              labelPlacement={LABEL_PLACEMENT.right}
            >
              <FormattedMessage id="checkbox.ok" />
            </Checkbox>
          </Col>
          <Col md={12}>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="button" disabled={!checked} onClick={handleChangeStatus}>
                <FormattedMessage id="button.complete" />
              </Button>
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default CompleteForm;
