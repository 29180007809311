import React, {
    Fragment,
    FunctionComponent,
    useCallback,
    useState,
} from 'react';
import { BillOfMaterialType } from '../../../types/BillOfMaterial';
import UpdateBillOfMaterialForm from '../UpdateBillOfMaterialForm';
import ListItemBillOfMaterialRow from '../ListItemBillOfMaterialRow';
import { useIntl } from 'react-intl';
import ListBillOfMaterialItem from '../BillOfMaterialItem/UpdateOrAdd/ListBillOfMaterialItem';
import ExecutionPlanning from '../../ExecutionPlanning/ExecutionPlanning/ExecutionPlanning';
import ListTechnicalImagesCustom from '../../TechicalImages/ListAndAddTechicalImagesCustom';
import { loader } from 'graphql.macro';
const DELETE_BILL_OF_MATERIAL_ATTACHMENT = loader('../../../graphql/uploadBillOfMaterial/DELETE_BILL_OF_MATERIAL_ATTACHMENT.graphql');
const UPLOAD_BILL_OF_MATERIAL_ATTACHMENT = loader(
    '../../../graphql/uploadBillOfMaterial/UPLOAD_BILL_OF_MATERIAL_ATTACHMENT.graphql'
);
interface ItemBillOfMaterialProps {
    item: BillOfMaterialType;
    setComponentStatus: Function;
    componentStatus: string;
} 
const ItemBillOfMaterial: FunctionComponent<ItemBillOfMaterialProps> = ({
    item,
    setComponentStatus,
    componentStatus,
}) => {
    const intl = useIntl();
    const [itemId, setItemId] = useState(null);
    const handleItemEdit = useCallback((id: string) => {
        setItemId(id);
    }, []);
    const buttons = [
        {
            label: intl.formatMessage({ id: 'button.update' }),
            function: () => {
                handleItemEdit(item.id);
            },
            check_type: 'UPDATE',
        },
        {
            label: intl.formatMessage({ id: 'billOfMaterial.item' }),
            function: () => setComponentStatus(() => "BillOfMaterialItem"),
            check_type: '',
        },
        // {
        //     label: intl.formatMessage({ id: 'billOfMaterial.executionPlanning' }),
        //     function: () => setComponentStatus(() => "ExecutionPlanning"),
        //     check_type: '',
        // },
        {
            label: intl.formatMessage({ id: 'billOfMaterial.images' }),
            function: () => setComponentStatus(() => "Images"),
            check_type: '',
        },
    ];
    return (
        <Fragment>
            {itemId !== item.id ?
                (<ListItemBillOfMaterialRow item={item} buttons={buttons} />

                ) : (
                    <UpdateBillOfMaterialForm item={item} setItemId={setItemId} />
                )
            }
            {componentStatus === "BillOfMaterialItem" ? (
                <ListBillOfMaterialItem
                    items={item?.billOfMaterialItem}
                    billOfMaterialId={item.id} />)
                : componentStatus === "ExecutionPlanning" ?
                    (<ExecutionPlanning //Teklik Oluşturma aşamasında gizlendi...
                        executionPlanningId={item?.executionPlan?.id}
                        billOfMaterialId={item.id}
                    />
                    ) : componentStatus === "Images" && (
                        <ListTechnicalImagesCustom
                            imajes={item.billOfMaterialAttachment}
                            parentVariables={{ billOfMaterialId: item?.id }}
                            addMutation={UPLOAD_BILL_OF_MATERIAL_ATTACHMENT}
                            deleteMutation={DELETE_BILL_OF_MATERIAL_ATTACHMENT}
                        />
                    )}
        </Fragment>
    );
};
export default ItemBillOfMaterial;
