import React from 'react';
import { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

interface OperationDetailProps {
  colName: string;
  operationDetail: {
    profitRate: number;
    profitAmount: number;
    discountRate: number;
    discountAmount: number;
    amount: number;
    operationName: string;
  }[];
}
const OperationDetail: FunctionComponent<OperationDetailProps> = ({
  operationDetail,
  colName,
}) => {
  const intl = useIntl();
  return (
    <div className="overflow-hidden">
      {operationDetail.map((item) => {
        return (
          <ul key={item?.operationName}>
            <li>
              <a href="#">
                <div className="pl-4 sm:px-6">
                  <div className="mt-1  grid grid-cols-1 sm:grid-cols-2  md:grid-cols-3 lg:grid-cols-5 print:grid-cols-5">
                    <div className="col-span-1  md:col-span-2 lg:col-span-2 print:col-span-2">
                      <ColContent colName={colName} contentName={item.operationName} />
                    </div>
                    <div className="col-span-1  print:col-span-1">
                      <ColContent
                        colName={intl.formatMessage({ id: 'preview.unitPrice' })}
                        contentName={(item?.amount || 0) + ' ₺'}
                      />
                    </div>
                    <div className="col-span-1  print:col-span-1">
                       <ColContent
                          colName={
                            intl.formatMessage({ id: 'preview.profitAmount' }) +
                            ' % ' +
                            (item?.profitRate || 0)
                          }
                          contentName={(item?.profitAmount || 0) + ' ₺ '}
                        />
                    </div>
                    <div className="col-span-1  print:col-span-1">
                      <ColContent
                        colName={
                          intl.formatMessage({ id: 'preview.discountAmount' }) +
                          ' % ' +
                          (item?.discountRate || 0)
                        }
                        contentName={(item?.discountAmount || 0) + ' ₺'}
                      />
                    </div>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        );
      })}
    </div>
  );
};
export default OperationDetail;
interface ColContentProps {
  colName: string;
  contentName: string;
}
const ColContent: FunctionComponent<ColContentProps> = ({ colName, contentName }) => {
  return (
    <p className="flex items-center text-sm text-gray-500 mt-0">
      <span className="pr-3 font-medium text-xs">{colName}</span>
      <span className="text-xs">{contentName}</span>
    </p>
  );
};
/*
 <Fragment>
      {operationDetail.map((item) => {
        return (
          <div className={'flex  text-xs'} style={{ margin: 0, padding: 0 }}>
            <div className={'font-weight'}>
              <div className="flex">
                <div className="text-sm font-medium text-gray-600 pr-2">{colName + ' : '}</div>
                <div className="pt-1">{'  ' + item?.operationName}</div>
              </div>
            </div>
            <div>
              <div className="flex">
                <div className="text-sm font-medium text-gray-600 pr-2">
                  {intl.formatMessage({ id: 'preview.unitPrice' })}
                </div>
                <div className="pt-1">{item.amount + ' ₺'}</div>
              </div>
            </div>
            <div>
              <div className="flex">
                <div className="text-sm font-medium text-gray-600 pr-2">
                  {intl.formatMessage({ id: 'preview.profitAmount' }) +
                    ' % ' +
                    item.profitRate}
                </div>
                <div className="pt-1"> {item.profitAmount + ' ₺ '}</div>
              </div>
            </div>
            <div>
              <div className="flex">
                <div className="text-sm font-medium text-gray-600 pr-2">
                  {intl.formatMessage({ id: 'preview.discountAmount' }) +
                    ' % ' +
                    item.discountRate}
                </div>
                <div className="pt-1"> {item.discountAmount + ' ₺'}</div>
              </div>
            </div>
          </div>
        );
      })}
    </Fragment>

*/
