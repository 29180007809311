import { Input, SIZE } from 'baseui/input';
import React from 'react';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import FormControl from '../../../components/FormWithElemets/FormControl';
import { Negative, Positive } from '../../../components/General/NegativePositive';

interface ListProps {
  errors:any;
  register:any;
}
const BooleanRule: FunctionComponent<ListProps> = ({ errors, register }) => {
  return (
    <Row style={{ margin: 0, padding: 0 }}>
      <Col md={6}>
        <FormControl
          label={<FormattedMessage id="measurement_rule.trueLabel" />}
          error={errors?.trueLabel ? 'Please input a valid True' : null}
        >
          <Input
            type="text"
            size={SIZE.compact}
            name="trueLabel"
            inputRef={register({
              required: true,
            })}
            error={errors.trueLabel}
            overrides={errors.trueLabel ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
      <Col md={6}>
        <FormControl
          label={<FormattedMessage id="measurement_rule.falseLabel" />}
          error={errors?.trueLabel ? 'Please input a valid False' : null}
        >
          <Input
            type="text"
            size={SIZE.compact}
            name="falseLabel"
            inputRef={register({
              required: true,
            })}
            error={errors.falseLabel}
            overrides={errors.falseLabel ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
      </Col>
    </Row>
  );
};
export default BooleanRule;
