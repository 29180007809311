import React, { FunctionComponent, memo } from 'react';
import { useIntl } from 'react-intl';

const HeadersPurchaseOffer: FunctionComponent = () => {
  const intl = useIntl();
  return (
    <div className="text-sm font-medium hidden print:grid print:grid-cols-12 lg:grid  lg:grid-cols-1 lg:grid-cols-12 border-b-2 print:ml-2">
      <div className="lg:col-span-4 print:col-span-4">
        {intl.formatMessage({ id: 'offer.item' })}
      </div>
      <div className="lg:col-span-2 print:col-span-2">
        {intl.formatMessage({ id: 'date.estimatedDate' })}
      </div>
      <div className="lg:col-span-2 print:col-span-2">
        {intl.formatMessage({ id: 'purchaseOffer.qty' })}
      </div>
      <div className="lg:col-span-2 print:col-span-2">
        {intl.formatMessage({ id: 'offer.unitPrice' })}
      </div>
      <div className="lg:col-span-2 print:col-span-2">
        {intl.formatMessage({ id: 'offer.amount' })}
      </div>
    </div>
  );
};
export default memo(HeadersPurchaseOffer);
