import React, { useCallback } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { useDrawerDispatch, useDrawerState } from '../../context/DrawerContext';
import { handleMachineryAndEquipmentOrderQty } from './GraphqlFuction';
import { Card } from '../../components/FormWithElemets/Card.style';
const UPDATE_SHOP_FLOOR_ORDER = loader(
  '../../graphql/shopFloorOrder/UPDATE_SHOP_FLOOR_ORDER.graphql'
);
const ShopFloorOrderQtyForm: React.FunctionComponent = () => {
  const { showToaster } = useToaster();
  const dispatch = useDrawerDispatch();
  const { machineryAndEquipmentId, id, qty, mimQty } = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      qty: qty || '',
    },
  });
  const onSubmit = ({ qty }) => {
    update({
      variables: {
        id: id,
        machineryAndEquipmentId: machineryAndEquipmentId,
        qty: Number(qty),
      },
    }).then(() => {
      showToaster('updated', 'positive');
      closeDrawer();
    });
  };
  const [update] = useMutation(UPDATE_SHOP_FLOOR_ORDER, {
    update(cache, { data: { updateShopFloorOrder } }) {
      handleMachineryAndEquipmentOrderQty(
        cache,
        machineryAndEquipmentId,
        updateShopFloorOrder
      );
    },
  });
  return (
    <Card>
     {/* // <CardTitle id=""></CardTitle> */}
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="shop_floor_summary.qty" />}
          error={errors.qty ? 'Please input a valid Qty' : null}
        >
          <Input
            type="number"
            min={mimQty}
            size={SIZE.compact}
            name="qty"
            inputRef={register({
              required: true,
            })}
            error={errors.qty}
            overrides={errors.qty ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <div
          style={{
            height: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
          }}
        >
          <Button onClick={closeDrawer} style={{ marginBottom: '16px' }}>
            <FormattedMessage id="button.close" />
          </Button>
          <Button type="submit" style={{ marginBottom: '16px' }}>
            <FormattedMessage id={'button.update'} />
          </Button>
        </div>
      </Form>
    </Card>
  );
};

export default ShopFloorOrderQtyForm;
