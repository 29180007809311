import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, RowCustom } from '../../../../components/FlexBox/FlexBox';
import { COLOR } from '../../../../components/FormWithElemets/Button/Button';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { PurchaseMaterialOffer } from '../../../../types/PurchaseMaterialOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';
interface PurchaseMaterialOfferPreviewProps {
  item: PurchaseMaterialOffer;
}
const PurchaseMaterialOfferPreview: FunctionComponent<PurchaseMaterialOfferPreviewProps> = ({
  item,
}) => {
  const intl = useIntl();

  return (
    <RowCustom>
      <Col xs={12} md={5} lg={4}>
        <LabelCustom
          title={intl.formatMessage({ id: 'inventory.inventoryId' })}
        >
          <span
            style={{
              fontSize: '20px',
              fontWeight: 'lighter',
              textDecoration: 'underline',
              color: COLOR.grey,
            }}
          >
            {item.inventory?.name || item?.name || 'No Item'}
          </span>
        </LabelCustom>
      </Col>
      <Col xs={6} md={3} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'select.inventoryType' })}>
          {item?.inventory?.inventoryType?.name || item?.inventoryType?.name || "No Inventory Type"}
        </LabelCustom>
      </Col>
      <Col xs={6} md={3} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'date.estimatedDate' })}>
          {handleLocalDateString(item.estimatedDate)}
        </LabelCustom>
      </Col>
      <Col xs={6} md={3} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'purchaseOffer.qty' })}>
          {item?.qty || 0}
        </LabelCustom>
      </Col>
    </RowCustom>
  );
};
export default PurchaseMaterialOfferPreview;
