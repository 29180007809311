export enum MaterialGeometry {
  BoxProfile,
  Sheet,
  Pipe,
  Round,
  Square,
  Hexagonal,
  OuterHexagonalInnerPipe,
  Rectangle
}

export type WallThickness = number;
export type Width = number;
export type Height = number;
export type Length = number;
export type Diameter = number;
export type InnerDiameter = number;
export type OuterDiameter = number;
export type RawMaterialId = string;
export type RawMaterialName = string;
export type ByPieces = boolean;
export type PieceLength = number;
export type UnmachinablePartLength = number;
export type CuttingToolDiam = number;
export type ProcessingLength = number;
export type Qty = number;
export type UnitPrice = number;

export type Mass = number;
export type MaterialWeight = number;
export type MaterialPrice = number;
export type MaterialLength = number;

export interface BoxProfile {
  wallThickness: WallThickness;
  width: Width;
  height: Height;
  length: Length;
}

export interface Sheet {
  thickness: Width;
  height: Length;
}

export interface Pipe {
  outerDiameter: OuterDiameter;
  wallThickness: WallThickness;
  length: Length;
}

export interface Round {
  diameter: Diameter;
}

export interface Square {
  width: Width;
}
export interface Rectangle {
  width: Width;
  height:Height
}

export interface Hexagonal {
  diameter: Diameter;
}

export interface OuterHexagonalInnerPipe {
  diameter: Diameter;
  innerDiameter: InnerDiameter;
}

export interface fromProduct {
  rawMaterialId: RawMaterialId;
  rawMaterialName: RawMaterialName;
  materialGeometry: MaterialGeometry;
  byPieces: ByPieces;
  pieceLength: PieceLength;
  unmachinablePartLength: UnmachinablePartLength;
  cuttingToolDiam: CuttingToolDiam;
  processingLength: ProcessingLength;
  length: Length;
}

export type ProductMaterialBoxProfile = fromProduct & BoxProfile;
export type ProductMaterialSheet = fromProduct & Sheet;
export type ProductMaterialPipe = fromProduct & Pipe;
export type ProductMaterialRound = fromProduct & Round;
export type ProductMaterialSquare = fromProduct & Square;
export type ProductMaterialHexagonal = fromProduct & Hexagonal;
export type ProductMaterialOuterHexagonalInnerPipe = fromProduct &
  OuterHexagonalInnerPipe;

export interface fromOffer {
  rawMaterialId: RawMaterialId;
  rawMaterialName: RawMaterialName;
  materialGeometry: MaterialGeometry;
  byPieces: ByPieces;
  qty: Qty;
  unitPrice: UnitPrice;
  pieceLength: PieceLength;
  unmachinablePartLength: UnmachinablePartLength;
  cuttingToolDiam: CuttingToolDiam;
  mass: Mass;
  materialWeight: MaterialWeight;
  materialPrice: MaterialPrice;
  materialLength: MaterialLength;
  processingLength: ProcessingLength;
  length: Length;
  innerShape:string;
}
export interface fromOfferResult {
  materialWeight: MaterialWeight;
  materialPrice: MaterialPrice;
  materialLength: MaterialLength;
  oneUnitWeight:number
}
export interface InnerOffer {
  innerDiameterOrWidth?:Diameter;
  innerHeight?:Height;
}
export type OfferMaterialBoxProfile = fromOffer & BoxProfile&InnerOffer;
export type OfferMaterialSheet = fromOffer & Sheet;
export type OfferMaterialPipe = fromOffer & Pipe;
export type OfferMaterialRound = fromOffer & Round&InnerOffer;
export type OfferMaterialSquare = fromOffer & Square&InnerOffer;
export type OfferMaterialHexagonal = fromOffer & Hexagonal&InnerOffer;
export type OfferMaterialRectangle = fromOffer & Rectangle&InnerOffer;
export type OfferMaterialOuterHexagonalInnerPipe = fromOffer & OuterHexagonalInnerPipe;
