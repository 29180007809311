import { useEffect, useState } from 'react';
import {
  OfferMaterialRound,
  Diameter,
  Length,
  RawMaterialId,
  RawMaterialName,
  ByPieces,
  PieceLength,
  UnmachinablePartLength,
  CuttingToolDiam,
  Qty,
  UnitPrice,
  Mass,
  ProcessingLength,
  fromOfferResult,
  MaterialLength,
  MaterialWeight,
  MaterialPrice,
} from '../../../types/Calc';
import {  cm3ToMm3, getMaterialLength, getMaterialPrice, getPerUnit, getUnitWeighRound,  mm2ToCm2 } from '../Common';

export const useCalcOfferMaterialRound = (initial: OfferMaterialRound) => {
  const [offerMaterialRound, setState] = useState<OfferMaterialRound>(initial);
  const [offerMaterialRoundResult, setStateResult] = useState<fromOfferResult>({
    materialWeight: 0,
    materialPrice: 0,
    materialLength: 0,
    oneUnitWeight:0
  });
  function setDiameter(diameter: Diameter) {
    setState((prev) => {
      return {
        ...prev,
        diameter: diameter,
      };
    });
    console.log('Wall Thickness Setted');
  }

  function setLength(length: Length) {
    setState((prev) => {
      return {
        ...prev,
        length: length,
      };
    });
    console.log('Wall Thickness Setted');
  }

  function setMaterial(rawMaterialId: RawMaterialId, rawMaterialName: RawMaterialName) {
    setState((prev) => {
      return {
        ...prev,
        rawMaterialId: rawMaterialId,
        rawMaterialName: rawMaterialName,
      };
    });
    console.log('Material Setted');
  }

  function setProductionStyle(byPieces: ByPieces) {
    setState((prev) => {
      return {
        ...prev,
        byPieces: byPieces,
      };
    });
    console.log('Production Style Setted');
  }
  function setPieceLength(pieceLength: PieceLength) {
    setState((prev) => {
      return {
        ...prev,
        pieceLength: pieceLength,
      };
    });
    console.log('Piece Length Setted');
  }
  function setUnmachinablePartLength(unmachinablePartLength: UnmachinablePartLength) {
    setState((prev) => {
      return {
        ...prev,
        unmachinablePartLength: unmachinablePartLength,
      };
    });
    console.log('Unmachinable Part Length Setted');
  }

  function setCuttingToolDiam(cuttingToolDiam: CuttingToolDiam) {
    setState((prev) => {
      return {
        ...prev,
        cuttingToolDiam: cuttingToolDiam,
      };
    });
    console.log('Cutting Tool Diam Setted');
  }

  function setQty(qty: Qty) {
    setState((prev) => {
      return {
        ...prev,
        qty: qty,
      };
    });
    console.log('Qty Setted');
  }

  function setUnitPrice(unitPrice: UnitPrice) {
    setState((prev) => {
      return {
        ...prev,
        unitPrice: unitPrice,
      };
    });
    console.log('Unit Price Setted');
  }

  function setMass(mass: Mass) {
    setState((prev) => {
      return {
        ...prev,
        mass: mass,
      };
    });
    console.log('Mass Setted');
  }

  function setProcessingLength(processingLength: ProcessingLength) {
    setState((prev) => {
      return {
        ...prev,
        processingLength: processingLength,
      };
    });
    console.log('Processing Length Setted');
  }
  useEffect(()=>{
    const perUnit: number = getPerUnit(
      offerMaterialRound.processingLength,
      offerMaterialRound.unmachinablePartLength,
      offerMaterialRound.pieceLength,
      offerMaterialRound.cuttingToolDiam
    );
    const materialLength: MaterialLength = getMaterialLength(
      offerMaterialRound.qty,
      perUnit,
      offerMaterialRound.processingLength
    );
    const unitWeight: number = getUnitWeighRound(
      offerMaterialRound.mass,
      offerMaterialRound.diameter,
      offerMaterialRound.innerShape,
      offerMaterialRound.innerDiameterOrWidth,
      offerMaterialRound.innerHeight,
      materialLength
    );
    const oneUnitWeight:number = getUnitWeighRound(
      offerMaterialRound.mass,
      offerMaterialRound.diameter,
      offerMaterialRound.innerShape,
      offerMaterialRound.innerDiameterOrWidth,
      offerMaterialRound.innerHeight,
      offerMaterialRound.pieceLength
    )
    const materialWeight: MaterialWeight = mm2ToCm2(unitWeight);

    const materialPrice: MaterialPrice = getMaterialPrice(
      materialWeight,
      offerMaterialRound.unitPrice
    );
    setStateResult(() => {
      return {
        materialWeight: +materialWeight.toFixed(3),
        materialPrice: +materialPrice.toFixed(3),
        materialLength: +materialLength.toFixed(3),
        oneUnitWeight: mm2ToCm2(oneUnitWeight)
      };
    });
  },[offerMaterialRound])
  return {
    offerMaterialRound,
    offerMaterialRoundResult,
    setDiameter,
    setLength,
    setMaterial,
    setProductionStyle,
    setPieceLength,
    setUnmachinablePartLength,
    setCuttingToolDiam,
    setQty,
    setUnitPrice,
    setMass,
    setProcessingLength,
    setState
};
};
