import React, { FunctionComponent } from 'react';
import { Company } from '../../types/Company';
import { User } from '../../types/User';
import { handleLocalDateString } from '../Functions/Utilities';

interface CompanyInformationProps {
  company: Company;
  assigned?: User;
  no?: number;
}
const CompanyInformation: FunctionComponent<CompanyInformationProps> = ({
  company,
  assigned,
  no,
}) => {
  return (
    <div>
      <Column
        title={'Tarih / Date'}
        content={handleLocalDateString(new Date().toString())}
      />
      <Column title={'Firma / Company'} content={company?.companyName || 'No Company'} />
      <Column
        title={'İlgili Kişi / Respective Person'}
        content={(assigned?.name || '') + ' ' + (assigned?.surname || '')}
      />
      <Column title={'Teklif Sıra No / Bid No'} content={'OF ' + no} />
      <Column
        title={'Telefon Num. / Rhone Num.'}
        content={
          company.companyTelephones
            ? company.companyTelephones[0]?.areaCode +
              ' ' +
              company.companyTelephones[0]?.telephone
            : '....'
        }
      />
      <Column title={'Faks Num. / Fax Num.'} content={'....'} />
    </div>
    // <div className="grid grid-cols-12 print:grid-cols-12 border-b border-gray-200">
    //   <div className="lg:col-span-3 md:col-span-5 sm:col-span-6 col-span-5 print:col-span-3 pl-1">
    //     <p className="text-sm font-medium">Tarih / Date</p>
    //     <p className="text-sm font-medium">Firma / Company</p>
    //     <p className="text-sm font-medium">İlgili Kişi / Respective Person</p>
    //     <p className="text-sm font-medium">Teklif Sıra No / Bid No</p>
    //     <p className="text-sm font-medium">Telefon Num. / Rhone Num.</p>
    //     <p className="text-sm font-medium">Faks Num. / Fax Num.</p>
    //   </div>
    //   <div className="lg:col-span-9 md:col-span-7 sm:col-span-6 col-span-7 print:col-span-7">
    //     <p className="text-sm  ml-2">{handleLocalDateString(new Date().toString())}</p>
    //     <p className="text-sm  ml-2">{company?.companyName || 'No Company'}</p>
    //     <p className="text-sm  ml-2">
    //       {(assigned?.name || '') + ' ' + (assigned?.surname || '')}
    //     </p>
    //     <p className="text-sm  ml-2">{'OF ' + no}</p>
    //     <p className="text-sm  ml-2">
    //       {' '}
    //       {company.companyTelephones
    //         ? company.companyTelephones[0]?.areaCode +
    //           ' ' +
    //           company.companyTelephones[0]?.telephone
    //         : '....'}
    //     </p>
    //     <p className="text-sm  ml-2">....</p>
    //   </div>
    // </div>
  );
};
export default CompanyInformation;
interface ColumnProps {
  title: string;
  content: string;
}
const Column: FunctionComponent<ColumnProps> = ({ content, title }) => {
  return (
    <div className="grid grid-cols-12 print:grid-cols-12 border-b border-gray-200">
      <div className="lg:col-span-3 md:col-span-5 sm:col-span-6 col-span-5 print:col-span-3 pl-1">
        <p className="text-sm font-medium">{title}</p>
      </div>
      <div className="lg:col-span-9 md:col-span-7 sm:col-span-6 col-span-7 print:col-span-7">
        <p className="text-sm  ml-2">{content}</p>
      </div>
    </div>
  );
};
