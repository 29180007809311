import React from 'react';
import { Button as BaseButton, SIZE, SHAPE, KIND } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import { getPaddingStyles } from './Button.style';

export const DropdownButtonNew = ({ children, overrides, data, ...props }: any) => {
  return (
    <StatefulPopover
      focusLock
      placement={PLACEMENT.left}
      content={({ close }) => ( 
        <StatefulMenu
          items={props.items}
          onItemSelect={(dataTemp) => {
            const temp = dataTemp.item.check_type === 'UPDATE' ?  data : dataTemp.item?.id
            dataTemp.item.function(temp);
            close();
          }}
        />
      )}
    >
      <BaseButton
        {...props}
        type="button"
        overrides={{
          BaseButton: {
            style: ({ $theme, $size }) => {
              return {
                ...getPaddingStyles({ $theme, $size }),
                marginTop: '1px',
                marginBottom: '1px',
                marginLeft: '3px',
                marginRight: '3px',
                ':hover': {
                  opacity: 0.9,
                },
              };
            },
          },
          ...overrides,
        }}
      >
        {children}
      </BaseButton>
    </StatefulPopover>
  );
};

export { SIZE, SHAPE, KIND };
export default DropdownButtonNew;
