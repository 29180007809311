import React, { Fragment, FunctionComponent } from 'react';
import { BillOfMaterialAttachment } from '../../types/BillOfMaterial';
import TechnicalImages from './TechicalImages';
import ListTechicalImages from './ListTechicalImages';

interface ListAndAddTechicalImagesCustomProps {
  imajes: BillOfMaterialAttachment[];
  addMutation: any;
  deleteMutation: any;
  parentVariables: any;
}

const ListAndAddTechicalImagesCustom: FunctionComponent<ListAndAddTechicalImagesCustomProps> = ({
  imajes,
  addMutation,
  deleteMutation,
  parentVariables,
}) => {
  return (
    <Fragment>
      <ListTechicalImages imajes={imajes} deleteMutation={deleteMutation}/>
       <TechnicalImages id={{ ...parentVariables }} mutation={addMutation} />
    </Fragment>
  );
};
export default ListAndAddTechicalImagesCustom;
