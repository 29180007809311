import React from 'react';
import { PLACEMENT, toaster, ToasterContainer } from 'baseui/toast';
import { FormattedMessage } from 'react-intl';

export const ToasterContext = React.createContext({} as any);
type Color = 'positive' | 'info' | 'warning' | 'negative';
export const ToasterProvider = ({ children }) => {
  const showToaster = ( text, color, intl=true ) => {
    if (color === 'positive') {
      toaster.positive(intl ? <FormattedMessage id={text} /> : text, {});
    } else if (color === 'info') {
      toaster.info(intl ? <FormattedMessage id={text} /> : text, {});
    } else if (color === 'warning') {
      toaster.warning(intl ? <FormattedMessage id={text} /> : text, {});
    } else if (color === 'negative') {
      toaster.negative(intl ? <FormattedMessage id={text} /> : text, {});
    }
  };

  return (
    <ToasterContext.Provider value={{ showToaster }}>
      <ToasterContainer autoHideDuration={3000} placement={PLACEMENT.topRight}>
        {children}
      </ToasterContainer>
    </ToasterContext.Provider>
  );
};

export const useToaster = () => React.useContext(ToasterContext);