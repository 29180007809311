import { useQuery } from '@apollo/react-hooks';
import { TYPE } from 'baseui/select';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col } from '../../components/FlexBox/FlexBox';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { MeasurementTypes } from '../../types/MeasurementType';
const GET_MEASUREMENT_TYPES = loader(
  '../../graphql/measurementType/GET_MEASUREMENT_TYPES.graphql'
);
interface ListProps {
  errors: any;
  control: any;
  setValue: Function;
}
const SelectMeasurementType: FunctionComponent<ListProps> = ({
  errors,
  control,
  setValue,
}) => {
  const [machineryAndEquipment, setMachineryAndEquipment] = useState([]);
  const [machineryAndEquipmentExist, setMachineryAndEquipmentExist] = useState(false);
  const { data } = useQuery<MeasurementTypes>(GET_MEASUREMENT_TYPES);
  useEffect(() => {
    if (machineryAndEquipment.length === 0) {
      setValue('machineryAndEquipmentId', undefined);
      setMachineryAndEquipmentExist(false);
    } else {
      setMachineryAndEquipmentExist(true);
    }
  }, [machineryAndEquipment, setValue]);
  return (
    <>
      <Col md={4}>
        <FormControl
          label={<FormattedMessage id="measurement_type.name" />}
          error={
            errors?.measurementTypeId ? 'Please input a valid Measurement Type' : null
          }
        >
          <Select
            data={data && data.measurementTypes}
            controller={{
              name: 'measurementTypeId',
              type: TYPE.search,
              control,
              rules: { required: true },
              creatable: false,
              searchable: true,
              filterOutSelected: false,
              error: errors.measurementTypeId,
              overrides: errors.measurementTypeId
                ? { After: Negative }
                : { After: Positive },
              onChange: ([selected]) => {
                selected?.option &&
                  selected.value[0] &&
                  setMachineryAndEquipment(
                    () =>
                      data.measurementTypes.find(
                        (item) => item.id === selected.value[0].id
                      ).machineryAndEquipment
                  );

                return selected?.option ? selected.value : undefined;
              },
            }}
          />
        </FormControl>
      </Col>
      <Col md={4}>
        <FormControl
          label={<FormattedMessage id="machinery_and_equipment.name" />}
          error={
            errors?.machineryAndEquipmentId
              ? 'Please input a valid  Machinery And Equipment'
              : null
          }
        >
          <Select
            data={machineryAndEquipment}
            controller={{
              name: 'machineryAndEquipmentId',
              type: TYPE.search,
              control,
              rules: { required: machineryAndEquipmentExist },
              creatable: false,
              searchable: true,
              disabled: !machineryAndEquipmentExist,
              filterOutSelected: false,
              error: errors.machineryAndEquipmentId,
              overrides: errors.machineryAndEquipmentId
                ? { After: Negative }
                : { After: Positive },
              onChange: ([selected]) => {
                return selected?.option ? selected.value : undefined;
              },
            }}
          />
        </FormControl>
      </Col>
    </>
  );
};
export default SelectMeasurementType;
