// @ts-nocheck
import { styled } from 'baseui';
import { Grid, Row, Col } from '../../components/FlexBox/FlexBox';

export const GridFull = styled(Grid, () => ({
  height: '100%',
  paddingBottom: '5%',
}));

export const RowFull = styled(Row, () => ({
  margin: '0 -15px 30px',
  height: '100%',

  ':last-child': {
    marginBottom: '0px',
  },
}));

export const ColFull = styled(Col, () => ({
  padding: '0 15px',
  height: '100%',
}));

export const MainCard = styled('div', () => ({
  borderRadius: '8px',
  backgroundColor: '#eeeeee',
  height: '99%',
  padding: '10px',
}));

export const Heading = styled('h2', ({ $theme }) => ({
  ...$theme.typography.fontBold18,
  color: $theme.colors.textDark,
  margin: 0,
}));
