import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../../context/toaster-provider';

const UPDATE_OFFER = loader('../../../../graphql/offer/UPDATE_OFFER.graphql');
const CHANGE_OFFER_STAGE_WITH_DEFAULT = loader(
  '../../../../graphql/offer/CHANGE_OFFER_STAGE_WITH_DEFAULT.graphql'
);
const useOfferStatusChange = () => {
  const { showToaster } = useToaster();
  const onSubmit = ({
    offerId,
    offerStageId,
    offerStatusId,
    canceledOrDeclinedReasonId,
    offerApproved,
  }) => {
    offerApproved
      ? changeOfferStageWithDefault({
          variables: {
            id: offerId,
            targetStage: offerStageId,
          },
        }).then(() => {
          showToaster('updated', 'positive');
        })
      : updateOffer({
          variables: {
            id: offerId,
            offerStageId: offerStageId,
            offerStatusId: offerStatusId,
            canceledOrDeclinedReasonId: canceledOrDeclinedReasonId,
          },
        }).then(() => {
          showToaster('updated', 'positive');
        });
  };
  const [updateOffer] = useMutation(UPDATE_OFFER);
  const [changeOfferStageWithDefault] = useMutation(CHANGE_OFFER_STAGE_WITH_DEFAULT);
  return {
    onSubmit,
  };
};
export default useOfferStatusChange;
