import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import {
  faArrowToBottom,
  faArrowToTop,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { useToaster } from '../../../../context/toaster-provider';
import Button, { COLOR } from '../../../../components/FormWithElemets/Button/Button';
import { PurchaseMaterialOffer } from '../../../../types/PurchaseMaterialOffer';
import UpdatePuchaseMaterialOffer from './UpdatePuchaseMaterialOffer';
import ListItemPurchaseMaterialOffer from './ListItemPurchaseMaterialOffer';
import { FormattedMessage } from 'react-intl';
import { Label } from '../../../../components/FormWithElemets/Label.style';

const DELETE_PURCHASE_MATERIAL_OFFER = loader(
  '../../../../graphql/purchaseOfferMaterial/DELETE_PURCHASE_MATERIAL_OFFER.graphql'
);
interface ListPurchaseOperationProps {
  items: PurchaseMaterialOffer[];
}
const ListPurchaseMaterialOffer: FunctionComponent<ListPurchaseOperationProps> = ({
  items,
}) => {
  const { showToaster } = useToaster();
  const [itemId, setItemId] = useState(null);
  const [detailShow, setDetailShow] = useState(true);
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
  }, []);
  const [remove] = useMutation(DELETE_PURCHASE_MATERIAL_OFFER);
  const handleItemDelete = useCallback((id: number) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: id },
      }).then(() => {
        showToaster('deleted', 'positive');
      });
    }
  }, [remove,showToaster]);
  return (
    <CardBody>
      <Label
        style={{ borderBottom: '1px solid ' + COLOR.grey }}
        title={<FormattedMessage id="purchaseOffer.materials" />}
      >
        <Button onClick={() => setDetailShow(!detailShow)}>
          <FontAwesomeIcon icon={detailShow ? faArrowToTop : faArrowToBottom} />
        </Button>
      </Label>
      <div style={detailShow ? {} : { display: 'none' }}>
        {items &&
          items.map((item: any) => {
            return item.id !== itemId ? (
              <ListItemPurchaseMaterialOffer
                key={item.id}
                item={item}
                handleItemDelete={handleItemDelete}
                handleItemEdit={handleItemEdit}
              />
            ) : (
              <UpdatePuchaseMaterialOffer
                key={item.id}
                item={item}
                setItemId={setItemId}
              />
            );
          })}
      </div>
    </CardBody>
  );
};
export default ListPurchaseMaterialOffer;
