import { useMutation } from '@apollo/react-hooks';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, SIZE } from 'baseui/input';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import Button from '../../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../../components/FormWithElemets/FormControl';
import { useToaster } from '../../../../../context/toaster-provider';
import { OperationSalesOffer } from '../../../../../types/OperationSalesOffer';
import { inputDecimal } from '../../../../Functions/Utilities';

const UPDATE_OPERATION_SALES_OFFER_PRICE = loader(
  '../../../../../graphql/operationSalesOfferPrice/UPDATE_OPERATION_SALES_OFFER_PRICE.graphql'
);
const ADD_OPERATION_SALES_OFFER_PRICE = loader(
  '../../../../../graphql/operationSalesOfferPrice/ADD_OPERATION_SALES_OFFER_PRICE.graphql'
);
interface OperationSalesPriceProps {
  item: OperationSalesOffer;
  setItemAdd: Function;
  transportUnitPrice: number;
}
const OperationSalesPriceForm: FunctionComponent<OperationSalesPriceProps> = ({
  item,
  setItemAdd,
  transportUnitPrice,
}) => {
  const { id, qty, unitPrice, operationSalesOfferPrice } = item;
  const { showToaster } = useToaster();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const [taxAmount, setTaxAmount] = useState(0);
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      amount: (qty * (unitPrice + transportUnitPrice)) | 0,
      discountRate: operationSalesOfferPrice?.discountRate | 0,
      profitRate: operationSalesOfferPrice?.profitRate | 0,
      taxRate: operationSalesOfferPrice?.taxRate | 0,
    },
  });
  const { amount, profitRate, discountRate, taxRate } = watch();
  useEffect(() => {
    setValue('amount', qty * (unitPrice + transportUnitPrice));
  }, [transportUnitPrice, qty, unitPrice]);
  useEffect(() => {
    const profitAmountTemp = Number(((amount * profitRate) / 100).toFixed(2));
    setProfitAmount(profitAmountTemp);
    const discountAmountTemp = Number(
      (((amount + profitAmountTemp) * discountRate) / 100).toFixed(2)
    );
    setDiscountAmount(discountAmountTemp);
    const taxAmount = Number(
      (
        ((amount + profitAmountTemp - discountAmountTemp) * discountRate) /
        100
      ).toFixed(2)
    );
    setTaxAmount(taxAmount);
  }, [amount, discountRate, profitRate, taxRate, transportUnitPrice]);
  const onSubmit = ({ amount, discountRate, profitRate, taxRate }) => {
    operationSalesOfferPrice?.id
      ? update({
          variables: {
            id: operationSalesOfferPrice?.id,
            qty: operationSalesOfferPrice?.qty || 0,
            unitPrice: operationSalesOfferPrice?.unitPrice || 0,
            amount: +amount || 0,
            discountRate: +discountRate || 0,
            profitRate: +profitRate || 0,
            taxRate: +taxRate || 0,
          },
        }).then(() => {
          showToaster('updated', 'positive');
          setItemAdd(null);
        })
      : add({
          variables: {
            operationSalesOfferId: id,
            amount: +(qty * (unitPrice + transportUnitPrice)).toFixed(2) || 0,
            discountRate: +discountRate || 0,
            profitRate: +profitRate || 0,
            taxRate: +taxRate || 0,
            qty: qty,
            unitPrice: unitPrice,
          },
        }).then(() => {
          showToaster('created', 'positive');
          setItemAdd(null);
        });
  };
  const [add] = useMutation(ADD_OPERATION_SALES_OFFER_PRICE);
  const [update] = useMutation(UPDATE_OPERATION_SALES_OFFER_PRICE);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom middle="xs">
        <Col sm={4} md={4} lg={2} style={{display:'none'}}>
          <FormControl label={<FormattedMessage id="offerPrice.amountPrice" />}>
            <Input
              type="text"
              name={'amount'}
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('amount', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl label={<FormattedMessage id="offerPrice.profitRate" />}>
            <Input
              type="text"
              name={'profitRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('profitRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={profitAmount + '₺'}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl
            label={<FormattedMessage id="offerPrice.discountRate" />}
          >
            <Input
              type="text"
              name={'discountRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('discountRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={discountAmount + '₺'}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={3}>
          <FormControl label={<FormattedMessage id="offerPrice.taxRate" />}>
            <Input
              type="text"
              name={'taxRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('taxRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={taxAmount + '₺'}
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button
            style={{ marginTop: '16px' }}
            type="button"
            onClick={() => setItemAdd(null)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button style={{ marginTop: '16px' }} type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default OperationSalesPriceForm;
