import React, { FunctionComponent } from 'react';
import { Col } from '../../../components/FlexBox/FlexBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faClock,
  faTimesCircle,
} from '@fortawesome/pro-regular-svg-icons';
import { difference, handleDate, sum } from '../../Functions/Utilities';
import { StatefulPopover, TRIGGER_TYPE } from 'baseui/popover';
import { Block } from 'baseui/block';
import { MachineMeasurementRecord } from '../../../types/MachineMeasurementRecord';
interface ListProps {
  data: any;
  rule: any;
  handleRemove: Function;
  openDrawerUpdate: Function;
}
export const LastTenValues: FunctionComponent<ListProps> = ({
  data,
  rule,
  handleRemove,
  openDrawerUpdate,
}) => {
  return data.map((record: MachineMeasurementRecord) => {
    return (
      <Col
        key={record.id}
        style={
          measurementTrueFalse({
            value: record.data.value,
            ruleValue: rule.data,
            measurementRuleType: rule.measurementRuleType,
          })
            ? {
                color: 'red',
                borderLeft: '1px solid #f3f3f3',
              }
            : {
                borderLeft: '1px solid #f3f3f3',
              }
        }
      >
        <div
          style={{
            marginBottom: '5px',
          }}
          // onClick={() => {
          //   handleRemove(record.id);
          // }}
        >
          <FontAwesomeIcon icon={faClock} size="1x" />
          {' ' + handleDate(new Date(record.updatedAt))?.toLocaleTimeString().slice(0, 5)}
        </div>
        <div
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
            textAlign: 'center',
            fontSize: '18px',
          }}
          onClick={() => {
            openDrawerUpdate({
              data: record.data,
              rule: rule.data,
              id: record.id,
              measurementRuleType: rule.measurementRuleType,
              measurementRuleUnit: rule.measurementRuleUnit,
            });
          }}
        >
          {record.data.value === true || record.data.value === false ? (
            <FontAwesomeIcon
              size="1x"
              color={record.data.value ? 'rgb(76, 175, 80)' : '#DB5461'}
              icon={record.data.value ? faCheckCircle : faTimesCircle}
            />
          ) : (
            <StatefulPopover
              content={() => (
                <Block padding={'8px'}>
                  {record.user?.name +
                    ' ' +
                    record.user?.surname }
                </Block>
              )}
              triggerType={TRIGGER_TYPE.hover}
              returnFocus
              autoFocus
              overrides={{
                Inner: {
                  style: () => {
                    return {
                      backgroundColor: '#dc143c',
                      color: '#ffffff',
                      borderRadius: '5px',
                    };
                  },
                },
              }}
            >
              <span> {record.data.value}</span>
            </StatefulPopover>
          )}
        </div>
      </Col>
    );
  });
};
export const measurementTrueFalse = ({ value, ruleValue, measurementRuleType }) => {
  let TrueFalse: boolean;
  if (measurementRuleType === 'BOOLEAN') {
    TrueFalse = value;
  } else if (measurementRuleType === 'RANGE') {
    TrueFalse =
      value >= ruleValue.minimumTolerance && value <= ruleValue.maximumTolerance;
  } else if (measurementRuleType === 'NUMERIC') {
    TrueFalse =
      (ruleValue.minimumToleranceSign[0].label === '+'
        ? value <= sum(ruleValue.minimumTolerance, ruleValue.tolerance)
        : value >= difference(ruleValue.tolerance, ruleValue.minimumTolerance)) &&
      (ruleValue.maximumToleranceSign[0].label === '+'
        ? value <= sum(ruleValue.maximumTolerance, ruleValue.tolerance)
        : value >= difference(ruleValue.tolerance, ruleValue.maximumTolerance));
  } else {
    TrueFalse = false;
  }
  return !TrueFalse;
};
