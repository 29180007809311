import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage, useIntl } from 'react-intl';
import { RadioGroup, Radio, ALIGN } from 'baseui/radio';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import Avatar from 'react-avatar-edit';
import { useAbility } from '@casl/react';
import { AbilityContext } from '../Layout/Layout';
import { loader } from 'graphql.macro';
import { User } from '../../types/User';

const ADD_USER = loader('../../graphql/user/ADD_USER.graphql');
const GET_USERS = loader('../../graphql/user/GET_USERS.graphql');
const UPDATE_USER = loader('../../graphql/user/UPDATE_USER.graphql');

export default function UserForm() {
  const { register, handleSubmit, errors, setValue,  control } = useForm();
  const ability = useAbility(AbilityContext);
  const access = ability.can('LIST', 'USERS');
  const [src] = useState('');
  const [preview, setPreview] = useState('');
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  const intl = useIntl();
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
      setValue('surname', dataDispatch.surname);
      setValue('isActive', dataDispatch.isActive);
      setValue('email', dataDispatch.email);
      setValue('password', dataDispatch.password);
      setValue('profilePhoto', dataDispatch.profilePhoto);
      setValue('language', dataDispatch.lang || 'tr');
      setPreview(dataDispatch.profilePhoto);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({
    name,
    isActive,
    email,
    surname,
    profilePhoto,
    password,
    language,
  }) => {
    dataDispatch
      ? updateUser({
          variables: {
            id: dataDispatch.id,
            name: name,
            isActive: access ? isActive : dataDispatch.isActive,
            profilePhoto: profilePhoto,
            surname: surname,
            lang: language,
          },
        })
      : addUser({
          variables: {
            name: name,
            isActive: isActive,
            email: email,
            profilePhoto: profilePhoto,
            surname: surname,
            password: password,
            lang: language,
          },
        });
  };
  const [addUser] = useMutation(ADD_USER, {
    update(cache, { data: { createUser } }) {
      const { users } = cache.readQuery({ query: GET_USERS });
      if (users) {
        cache.writeQuery({
          query: GET_USERS,
          data: { users: users.concat(createUser) },
        });
      }
      closeDrawer();
    },
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    update(cache, { data: { updateUser } }) {
      const { users } = cache.readQuery({ query: GET_USERS });
      const newUsers = users.map((item: User) => {
        if (dataDispatch.id === item.id) {
          return {
            ...updateUser,
          };
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_USERS,
        data: { users: newUsers },
      });
      closeDrawer();
    },
  });
  useEffect(() => {
    register({ name: 'profilePhoto' });
  }, [register]);
  function onClose() {
    setPreview(null);
  }
  function onCrop(preview: any) {
    setPreview(preview);
    setValue('profilePhoto', preview);
  }

  return (
    <Card title={<FormattedMessage id={dataDispatch ? 'user.update' : 'user.create'} />}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          backgroundColor: 'transparent',
        }}
      >
        <Row around="xs">
          <Col md={12} lg={6}>
            <FormControl
              label={<FormattedMessage id="user.name" />}
              error={errors.name ? 'Please input a valid Name' : null}
            >
              <Input
                size={SIZE.compact}
                name="name"
                inputRef={register({
                  required: true,
                })}
                error={errors.name}
                overrides={errors.name ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
            <FormControl
              label={<FormattedMessage id="user.surname" />}
              error={errors.surname ? 'Please input a valid Surname' : null}
            >
              <Input
                size={SIZE.compact}
                name="surname"
                inputRef={register({
                  required: true,
                })}
                error={errors.surname}
                overrides={errors.surname ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
            {!dataDispatch ? (
              <FormControl
                label={<FormattedMessage id="user.email" />}
                error={errors.email ? 'Please input a valid Email' : null}
              >
                <Input
                  disabled={!!dataDispatch}
                  size={SIZE.compact}
                  name="email"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.email}
                  overrides={errors.email ? { After: Negative } : { After: Positive }}
                />
              </FormControl>
            ) : null}

            {!dataDispatch && (
              <FormControl
                label={<FormattedMessage id="user.password" />}
                error={errors.password ? 'Please input a valid Password' : null}
              >
                <Input
                  size={SIZE.compact}
                  name="password"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.password}
                  overrides={errors.password ? { After: Negative } : { After: Positive }}
                />
              </FormControl>
            )}
            <Controller
              as={
                <RadioGroup align={ALIGN.horizontal}>
                  <Radio value="tr">TR</Radio>
                  <Radio value="en">EN</Radio>
                </RadioGroup>
              }
              defaultValue="TR"
              name="language"
              control={control}
            />
            <div
              style={
                access
                  ? { display: 'flex', justifyContent: 'flex-end' }
                  : { display: 'none' }
              }
            >
              <Controller
                as={
                  <Checkbox>
                    <FormattedMessage id="user.isActive" />
                  </Checkbox>
                }
                labelPlacement={LABEL_PLACEMENT.right}
                name="isActive"
                type="checkbox"
                control={control}
                defaultValue={false}
              />
            </div>
          </Col>
          <Col style={{ paddingTop: '15px', textAlign: 'center' }} md={12} lg={6}>
            <img style={{ height: '100%', width: 'auto' }} src={preview} alt="Preview" />
          </Col>
        </Row>
        <Row>
          <Col
            style={{ paddingTop: '15px', display: 'flex', justifyContent: 'center' }}
            md={12}
            lg={12}
          >
            <Avatar
              width={390}
              height={295}
              onCrop={onCrop}
              onClose={onClose}
              src={src}
              label={intl.formatMessage({ id: 'profile.select_photo' })}
            />
          </Col>
        </Row>
        <Row
          end="md"
          style={{
            marginTop: '55px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            width: '100%',
          }}
        >
          <Col md={6} style={{ textAlign: 'center' }}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
}
