import {
  faArrowAltFromTop,
  faArrowAltToBottom,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, useContext, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import { COLOR } from '../../../components/FormWithElemets/Button/Button';
import { Label } from '../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../components/TailWinduiTable/ColumnButtons';
import { ExecutionPlanStep } from '../../../types/ExecutionPlan';
import { PurchaseOffer } from '../../../types/Purchase';
import { PurchaseOperationOffer } from '../../../types/PurchaseOperationOffer';
import ContentInformation from '../../Functions/ContentInformation';
import { ComponentStatusContext } from '../ExecutionPlanning/ExecutionPlanning';
import PurchaseOperationOfferForm from '../PurchaseOfferOpration/PurchaseOperationOfferForm';

interface ListItemProps {
  item: ExecutionPlanStep;
  handleItemDelete: Function;
  handleItemEdit: Function;
  purchaseOffer: PurchaseOffer;
  offerId: string;
  orderQty: number;
}
const ListItem: FunctionComponent<ListItemProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
  purchaseOffer,
  offerId,
  orderQty,
}) => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const intl = useIntl();
  const [{ operation }, setPurhaseOfferAdd] = useState({
    operation: null,
  });
  const purchaseOperationOffer = useMemo(() => {
    return purchaseOffer?.purchaseOperationOffer?.filter(
      (itemOperation) => itemOperation.operation?.id === item?.operation?.id
    );
  }, [purchaseOffer]);
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(item.id);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    },
    {
      label: intl.formatMessage({ id: 'executionPlanning.step_inputs' }),
      function: () => {
        dispatchComponentStatus({
          type: 'STEP_INPUT',
          data: item.executionPlanStepInput,
          executionPlanningStepId: item.id,
        });
      },
      check_type: '',
    },
    {
      label: intl.formatMessage({ id: 'executionPlanning.step_outputs' }),
      function: () => {
        dispatchComponentStatus({
          type: 'STEP_OUTPUT',
          data: item.executionPlanStepOutput,
          executionPlanningStepId: item.id,
        });
      },
      check_type: '',
    },
    {
      label: intl.formatMessage({ id: 'purchaseOffer.operationAdd' }),
      function: () => {
        const _operation = item?.operation
          ? [{ id: item?.operation?.id, label: item?.operation.name }]
          : [{ id: item.name, label: item.name }];
        setPurhaseOfferAdd((prev) => ({
          operation: prev.operation === null ? _operation : null,
        }));
      },
      disabled: !purchaseOffer?.id || !item.external || (purchaseOperationOffer?.length > 0),
      check_type: '',
    },
  ];
  console.log("--->",item.operation.name,purchaseOperationOffer?.length > 0)
  return (
    <Fragment>
      <RowCustom middle="xs">
        <Col xs={12} md={6} lg={4}>
          <Label title={intl.formatMessage({ id: 'executionPlanning.stepName' })}>
            {item.name || 'No Item'}
          </Label>
        </Col>
        <Col xs={12} md={6} lg={3}>
          <Label title={intl.formatMessage({ id: 'executionPlanning.operation' })}>
            {item.operation?.name || 'No Item'}
          </Label>
        </Col>
        <Col xs={5} md={6} lg={2}>
          <Label title={intl.formatMessage({ id: 'executionPlanning.duration' })}>
            {item?.duration || '0'}
          </Label>
        </Col>
        <Col xs={5} md={6} lg={2}>
          <Label title={intl.formatMessage({ id: 'executionPlanning.external' })}>
            {item?.external ? ' ✓' : 'X'}
          </Label>
        </Col>
        <Col xs={5} md={6} lg={2}>
          <ContentInformation
            legnth={item.executionPlanStepInput.length}
            icon={<FontAwesomeIcon icon={faArrowAltToBottom} />}
          />
          <ContentInformation
            legnth={item.executionPlanStepOutput.length}
            icon={<FontAwesomeIcon icon={faArrowAltFromTop} />}
          />
        </Col>
        <ColButton xs>
          <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
        </ColButton>
        {purchaseOffer && (
          <ExecutionPlanningStep purchaseOperationOffer={purchaseOperationOffer} />
        )}
      </RowCustom>
      {operation !== null && (
        <PurchaseOperationOfferForm
          purchaseOfferId={purchaseOffer?.id}
          operation={operation}
          offerId={offerId}
          setClose={setPurhaseOfferAdd}
          orderQty={orderQty}
        />
      )}
    </Fragment>
  );
};
export default ListItem;
interface ExecutionPlanningStepProps {
  purchaseOperationOffer: PurchaseOperationOffer[];
}
const ExecutionPlanningStep: FunctionComponent<ExecutionPlanningStepProps> = ({
  purchaseOperationOffer,
}) => {
  const intl = useIntl();
  return (
    <Col xs={12} style={{ marginTop: '10px' }}>
      {purchaseOperationOffer?.map((itm) => {
        return (
          <span
            key={itm.id}
            style={{
              display: 'inline-block',
              borderRadius: '5px',
              backgroundColor: COLOR.yellow,
              paddingRight: '10px',
              paddingLeft: '10px',
              paddingTop: '3px',
              paddingBottom: '3px',
              marginRight: '5px',
            }}
          >
            {intl.formatMessage({ id: 'purhaseOffer.operation' }) +
              ' ' +
              itm?.operation?.name +
              ' ' +
              itm.qty +
              ' ' +
              intl.formatMessage({ id: 'Adet' })}
          </span>
        );
      })}
    </Col>
  );
};
