import React from 'react';
import { Button as BaseButton, SIZE, SHAPE, KIND } from 'baseui/button';
import { getPaddingStyles, getBorderRadiiStyles } from './Button.style';
import { styled } from 'baseui';

const Button = ({ children, overrides,color, ...props }: any) => {
  return (
    <BaseButton
      {...props}
      overrides={{
        BaseButton: {
          style: ({ $theme, $size, $shape }) => {
            return {
              ...getPaddingStyles({ $theme, $size }),
              ...getBorderRadiiStyles({ $theme, $size, $shape }),
              marginTop: '1px',
              marginBottom: '1px',
              marginLeft: '3px',
              marginRight: '3px',
              paddingTop:'5px',
              paddingBottom:'5px',
              paddingRight:'8px',
              paddingLeft:'8px',
              ':hover': {
                backgroundColor: color || '#696969',
                opacity: 0.9,
              },
              backgroundColor: color || '#696969',
            };
          },
        },
        ...overrides,
      }}
    >
      {children}
    </BaseButton>
  );
};

export { SIZE, SHAPE, KIND,COLOR };
const COLOR = {
  red:'#DB5461',
  orange:'#FC9D03',
  yellow:'#FFD357',
  green:'#35C795',
  purple:'#8B86C4',
  grey: '#696969',
  blue: '#0f2a99',
  white:'#ffffff',
  transparent: "transparent"
};
export default Button;

export const CreateButton = styled(Button, () => ({
  float: 'right',
}));