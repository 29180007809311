import React, {
  useState,
  FunctionComponent,
  useContext,
  Fragment,
  useCallback,
} from 'react';

import { Item as ItemType, ItemsContext } from './ProductSalesOfferAdd';
import ListItemProductSalesItem from './ListItemProductSalesItem';
import AddProductSalesItemForm from './AddProductSalesItemForm';
import ItemHeaders from './ItemHeaders';

const ListProductSalesItem: FunctionComponent = () => {
  const [itemIndex, setItemIndex] = useState(null);
  const { items, dispatchItems } = useContext(ItemsContext);
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
  }, []);
  const handleItemDelete = useCallback((index: number) => {
    if (window.confirm('Are you sure !')) {
      dispatchItems({
        type: 'DELETE',
        index: index,
      });
    }
  },[dispatchItems]);
  return (
    <Fragment>
      <ItemHeaders itemsLenght={items?.length} />
      {items &&
        items.map((item: ItemType, index: number) => {
          return index !== itemIndex ? (
            <ListItemProductSalesItem
              key={index}
              item={item}
              index={index}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
            />
          ) : (
            <AddProductSalesItemForm
              key={index}
              item={item}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
            />
          );
        })}
      {itemIndex === null && (
        <AddProductSalesItemForm itemIndex={itemIndex} setItemIndex={setItemIndex} />
      )}
    </Fragment>
  );
};
export default ListProductSalesItem;
