import { loader } from 'graphql.macro';

const GET_WAREHOUSES = loader('../../graphql/warehouse/GET_WAREHOUSES.graphql');

export const handleDeleteWarehouse = (cache, deleteWarehouse) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: warehouses.filter((item) => item.id !== deleteWarehouse.id) },
  });
};
export const handleDeleteWareHouseLevel = (cache, deleteWarehouseLevel, warehouseId) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.filter(
          (level) => level.id !== deleteWarehouseLevel.id
        ),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleAddWareHouseLevel = (cache, createWarehouseLevel, warehouseId) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.concat(createWarehouseLevel),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseLevel = (cache, updateWarehouseLevel, warehouseId) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === updateWarehouseLevel.id) {
            return {
              ...updateWarehouseLevel,
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseLevelOrder = (cache, items, warehouseId) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: items
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleDeleteWareHouseSection = (
  cache,
  deleteWarehouseSection,
  warehouseLevelId,
  warehouseId
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.filter(
                (section) => section.id !== deleteWarehouseSection.id
              ),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleAddWareHouseSection = (
  cache,
  createWarehouseSection,
  warehouseLevelId,
  warehouseId
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.concat(createWarehouseSection),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseSection = (
  cache,
  updateWarehouseSection,
  warehouseLevelId,
  warehouseId
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === updateWarehouseSection.id) {
                  return {
                    ...updateWarehouseSection,
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseSectionOrder = (
  cache,
  items,
  warehouseLevelId,
  warehouseId
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouseLevelId) {
            return {
              ...level,
              warehouseSection: items,
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleDeleteWareHouseRow = (
  cache,
  deleteWarehouseRow,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.filter(
                      (row) => row.id !== deleteWarehouseRow.id
                    ),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleAddWareHouseRow = (
  cache,
  createWarehouseRow,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.concat(createWarehouseRow),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseRow = (
  cache,
  updateWarehouseRow,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.map((row) => {
                      if (row.id === updateWarehouseRow.id) {
                        return {
                          ...updateWarehouseRow,
                        };
                      } else {
                        return row;
                      }
                    }),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseRowOrder = (
  cache,
  items,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: items,
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleDeleteWareHouseShelf = (
  cache,
  deleteWarehouseShelf,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.map((row) => {
                      if (row.id === warehouse.warehouseRowId) {
                        return {
                          ...row,
                          warehouseShelf: row.warehouseShelf.filter(
                            (shelf) => shelf.id !== deleteWarehouseShelf.id
                          ),
                        };
                      } else {
                        return row;
                      }
                    }),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleAddWareHouseShelf = (
  cache,
  createWarehouseshelf,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.map((row) => {
                      if (row.id === warehouse.warehouseRowId) {
                        return {
                          ...row,
                          warehouseShelf: row.warehouseShelf.concat(createWarehouseshelf),
                        };
                      } else {
                        return row;
                      }
                    }),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseShelf = (
  cache,
  updateWarehouseChelf,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.map((row) => {
                      if (row.id === warehouse.warehouseRowId) {
                        return {
                          ...row,
                          warehouseShelf: row.warehouseShelf.map((shelf) => {
                            if (shelf.id === updateWarehouseChelf.id) {
                              return {
                                ...updateWarehouseChelf,
                              };
                            } else {
                              return shelf;
                            }
                          }),
                        };
                      } else {
                        return row;
                      }
                    }),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};
export const handleUpdateWareHouseShelfOrder = (
  cache,
  items,
  warehouseId,
  warehouse
) => {
  const { warehouses } = cache.readQuery({ query: GET_WAREHOUSES });
  const newWarehouses = warehouses.map((item) => {
    if (item.id === warehouseId) {
      return {
        ...item,
        warehouseLevel: item.warehouseLevel.map((level) => {
          if (level.id === warehouse.warehouseLevelId) {
            return {
              ...level,
              warehouseSection: level.warehouseSection.map((section) => {
                if (section.id === warehouse.warehouseSectionId) {
                  return {
                    ...section,
                    warehouseRow: section.warehouseRow.map((row) => {
                      if (row.id === warehouse.warehouseRowId) {
                        return {
                          ...row,
                          warehouseShelf: items,
                        };
                      } else {
                        return row;
                      }
                    }),
                  };
                } else {
                  return section;
                }
              }),
            };
          } else {
            return level;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_WAREHOUSES,
    data: { warehouses: newWarehouses },
  });
};