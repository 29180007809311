import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, DivSubmit, Row } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { Form } from '../../components/FormWithElemets/Form.style';
import SelectInventory from '../SelectCustom/SelectInventory';
import BillOfMaterialItemForm, {
  MaterialItem,
} from './BillOfMaterialItem/ListBillOfMaterialItem';
import InputForm from './InputForm';
const ADD_BILL_OF_MATERIAL = loader(
  '../../graphql/billOfMaterial/ADD_BILL_OF_MATERIAL.graphql'
);

interface BillOfMaterialFormProps {
  handleSaveClose: Function;
  billOfMaterialName?: string;
}
const AddBillOfMaterialForm: FunctionComponent<BillOfMaterialFormProps> = ({
  handleSaveClose,
  billOfMaterialName = '',
}) => {
  const { control, errors, handleSubmit,register } = useForm({
    defaultValues: billOfMaterialName
      ? {
        inventory: [{ value: billOfMaterialName, label: billOfMaterialName }],
      }
      : {},
  });
  const [billOfMaterialItem, setBillOfMaterialItem] = useState<MaterialItem[]>([]);
  // const { select } = SelectInventory({
  //   name: 'billOfMaterialId',
  //   control,
  //   errors,
  //   required: true, 
  //   creatable: true,
  //   disabled: false,
  // });
  const [add] = useMutation(ADD_BILL_OF_MATERIAL);
  const onSubmit = ({ inventory,revisionNo,artNo }) => {
    console.log("--->",inventory)
    const nameOrInventoryId =
    inventory[0].value === inventory[0].label
        ? { name: inventory[0].value }
        : { inventoryId: inventory[0].value };

    add({
      variables: {
        ...nameOrInventoryId,
        revisionNo:revisionNo,
        artNo:artNo,
        draft: true,
        billOfMaterialItem: handleBillOfMaterialNew(billOfMaterialItem),
      },
    }).then(
      ({
        data: {
          createBillOfMaterial: { id, name, inventory },
        },
      }) => {
        handleSaveClose({
          billOfMaterialId: id,
          billOfMaterialName: inventory?.name || name,
        });
      }
    );
  };
  const handleNoSavedClose = () => {
    if (window.confirm('Kaydetmeden Çıkış Yapıyorsunuz..!')) {
      handleSaveClose({
        billOfMaterialId: '',
        billOfMaterialName: '',
      });
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputForm control={control} errors={errors} register={register}/>
      <hr />
      <BillOfMaterialItemForm setBillOfMaterialItem={setBillOfMaterialItem} />
      <DivSubmit style={{ marginTop: '30px' }}>
        {billOfMaterialName !== '' && (
          <Button type="button" onClick={handleNoSavedClose}>
            <FormattedMessage id="button.back" />
          </Button>
        )}
        <Button type="submit">
          <FormattedMessage id="button.save" />
        </Button>
      </DivSubmit>
    </Form>
  );
};
export const handleBillOfMaterialNew = (billOfMaterialItem: MaterialItem[]) => {
  return billOfMaterialItem.map((item) => {
    const materialItemNameOrInventoryId =
      item.materialItemInventoryId[0].id === item.materialItemInventoryId[0].label
        ? {
          name: item.materialItemInventoryId[0].id,
          inventoryTypeId: item?.inventoryTypeId[0]?.id
        }
        : { inventoryId: item.materialItemInventoryId[0].id };
    return {
      ...materialItemNameOrInventoryId,
      partNumber: item.partNumber,
      quantity: +item.quantity,
    };
  });
};
export default AddBillOfMaterialForm;
//vendorId: item?.companyName ? item.companyName[0].id : null,
