import React from 'react';
import { styled } from 'baseui';

const CardTitleTemp = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0px 0px 1px 1px rgba(230,223,230,.5)',
  margin: 0,
  padding: '10px',
}));
const CardBodyTemp = styled('div', () => ({
  backgroundColor: 'transparent',
  borderRadius: '3px',
  margin: '0',
  paddingTop: '10px',
  paddingBottom: '10px',
  paddingLeft: 0,
  paddingRight: 0,
}));
type ListProps = {
  children?: React.ReactNode;
  style?: any;
  title?: any;
};
export const CardTitle: React.FC<ListProps> = ({ children, title, style }) => {
  return (
    <CardTitleTemp
      style={{
        marginBottom: '1px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        ...style,
      }}
    >
      <div
        style={{
          color: 'rgb(69, 75, 104)',
          fontWeight: 700,
          fontSize: '16px',
          flexGrow: 1,
        }}
      >
        {title}
      </div>
      <div style={{ flexGrow: 2, display:'flex',justifyContent:'flex-end',alignContent:'center' }}>{children}</div>
    </CardTitleTemp>
  );
};
export const CardBody: React.FC<ListProps> = ({ children, style }) => {
  return <CardBodyTemp style={{ ...style }}>{children}</CardBodyTemp>;
};
export const Card: React.FC<ListProps> = ({ children, style }) => {
  return (
    <div
      style={{
        marginTop: '15px',
        width: '100%',
        boxShadow: '0px 2px 5px -0.5px rgba(158,158,158,0.5)',
        backgroundColor: '#ffffff',
        borderRadius: '3px',
        ...style,
      }}
    >
      {children}
    </div>
  );
};
