import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../components/TailWinduiTable/ColumnButtons';
import { BillOfMaterialType } from '../../types/BillOfMaterial';


interface ListItemBillOfMaterialRowProps {
    item: BillOfMaterialType
    buttons: any
}
const ListItemBillOfMaterialRow: FunctionComponent<ListItemBillOfMaterialRowProps> = ({ item, buttons }) => {
    const intl = useIntl();
    return <RowCustom>
        <Col sm={4}>
            <LabelCustom title={intl.formatMessage({ id: 'inventory.billOfMaterialId' })}>
                {item.inventory?.name || item?.name || 'No Item'}
            </LabelCustom>
        </Col>
        <Col sm={2}>
            <LabelCustom title={intl.formatMessage({ id: 'billOfMaterial.draft' })}>
                {item?.draft ? '✓' : 'X'}
            </LabelCustom>
        </Col>
        <Col sm={2}>
            <LabelCustom title={intl.formatMessage({ id: 'billOfMaterial.revisionNo' })}>
                {item?.revisionNo}
            </LabelCustom>
        </Col>
        <Col sm={2}>
            <LabelCustom title={intl.formatMessage({ id: 'billOfMaterial.artNo' })}>
                {item?.artNo}
            </LabelCustom>
        </Col>
        <ColButton xs>
            <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
        </ColButton>
    </RowCustom>
}

export default ListItemBillOfMaterialRow