import React, { FunctionComponent } from 'react';
import { useQuery } from '@apollo/react-hooks';
import NoResult from '../../components/NoResult/NoResult';
import List from './List';
import { Card } from '../../components/FormWithElemets/Card.style';
import { loader } from 'graphql.macro';
import { StyledSpinnerNext } from 'baseui/spinner';
import { QualityControlReasons } from '../../types/QualityControlReason';
import { Can } from '../Layout/Layout';

const GET_QUALITY_CONTROL_REASON = loader(
  '../../graphql/qualityControlReason/GET_QUALITY_CONTROL_REASON.graphql'
);

const QualityControlReason: FunctionComponent = () => {
  const { data, error, loading } = useQuery<QualityControlReasons>(GET_QUALITY_CONTROL_REASON);
  return (
    <Card> 
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
        data && <Can I="LIST" a="QUALITY-CONTROL-REASONS">
          <List data={data.qualityCheckReasons} />
        </Can>
      )}
    </Card>
  );
};

export default QualityControlReason;
