import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { reducerFunction } from '../../Functions/Utilities';
import ListItemBillOfMaterialItem from './ListItemBillOfMaterialItem';
import BillOfMaterialItemHeaders from './BillOfMaterialItemHeaders';
import BillOfMaterialItemForm from './BillOfMaterialItemForm';

interface BillOfMaterialItemFormProps {
  setBillOfMaterialItem: Function;
}
const ListBillOfMaterialItem: FunctionComponent<BillOfMaterialItemFormProps> = ({
  setBillOfMaterialItem,
}) => {
  const initialState = [];
  const [items, dispatchItems] = useReducer(reducer, initialState);
  const [itemAddShow, setItemAddShow] = useState(true);
  const [itemIndex, setItemIndex] = useState(null);
  useEffect(() => {
    items && setBillOfMaterialItem([...items]);
  }, [items,setBillOfMaterialItem]);
  
  const handleItemEdit = useCallback((index: number) => {
    setItemIndex(index);
    setItemAddShow(false);
  }, []);

  const handleItemDelete = useCallback((index: number) => {
    if (window.confirm('Are you sure !')) {
      dispatchItems({
        type: 'DELETE',
        index: index,
      });
    }
  }, []);
  return (
    <Fragment>
      <BillOfMaterialItemHeaders
        itemsLenght={items?.length}
        itemAddShow={itemAddShow}
        setItemAddShow={setItemAddShow}
      />
      {itemAddShow && (
        <BillOfMaterialItemForm
          itemIndex={itemIndex}
          dispatchItems={dispatchItems}
          setItemIndex={setItemIndex}
          setItemAddShow={setItemAddShow}
        />
      )}
      {items &&
        items.map((item: MaterialItem, index: number) => {
          return index !== itemIndex ? (
            <ListItemBillOfMaterialItem
              key={index}
              item={item}
              index={index}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
            />
          ) : (
            <BillOfMaterialItemForm
              key={index}
              item={item}
              dispatchItems={dispatchItems}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
            />
          );
        })}
    </Fragment>
  );
};

type MaterialItemInventory = {
  id: string;
  label: string;
};
type Company = {
  id: string;
  label: string;
};
type InventoryType = {
  id: string;
  label: string;
};
export type MaterialItem = {
  materialItemInventoryId: MaterialItemInventory[];
  inventoryTypeId:InventoryType[];
  partNumber: string;
  quantity: number;
  companyName: Company[];
};
type Action =
  | {
      type: 'ADD';
      item: MaterialItem;
    }
  | { type: 'DELETE'; index: number }
  | {
      type: 'UPDATE';
      item: MaterialItem;
      index: number;
    }
  | {
      type: 'RESET';
    };
const reducer = (state: MaterialItem[], action: Action) => {
  return reducerFunction(state, action);
};
export default ListBillOfMaterialItem;
