import React, { useCallback, useEffect, useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import gql from 'graphql-tag';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';

import { Select } from 'baseui/select';
import Button from '../../components/FormWithElemets/Button/Button';
import { Card, Form } from './Stock.style';

const ADD_STOCK = gql`
  mutation addStocks(
    $category: ID!
    $code: String!
    $criticalQuantity: Int!
    $name: String!
    $unitType: ID!
  ) {
    createStock(
      category: $category
      code: $code
      criticalQuantity: $criticalQuantity
      name: $name
      unitsId: $unitType
    ) {
      id
      category
      code
      criticalQuantity
      name
      units {
        id
        name
      }
    }
  }
`;
const GET_STOCKS = gql`
  query {
    stocks {
      id
      category
      code
      criticalQuantity
      name
      units {
        id
        name
      }
    }
  }
`;
const GET_UNITS = gql`
  query queryUnits {
    units {
      id
      name
    }
  }
`;
const GET_STOCK_CATEGORYS = gql`
  query queryStockCategory {
    stockCategories {
      id
      name
      parent
    }
  }
`;

export default function StockForm(props) {
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsUnit, setOptionsUnit] = useState([]);
  const dispatch = useDrawerDispatch();
  const dispatchData = useDrawerState('data');
  useEffect(() => {
    dispatchData && setValue('name', dispatchData);
  }, [dispatchData, setValue]);
  const closeDrawer = useCallback(
    (sendData) =>
      dispatch({
        type: 'CLOSE_DRAWER',
        data: sendData
          ? { data: sendData, component: 'StockForm' }
          : { data: undefined, component: 'StockForm' },
      }),
    [dispatch]
  );

  const { data: dataCategory } = useQuery(GET_STOCK_CATEGORYS);
  const { data: dataUnit } = useQuery(GET_UNITS);
  useEffect(() => {
    setOptionsCategory([]);
    dataCategory &&
      dataCategory.stockCategories.map(async (item) => {
        setOptionsCategory((optionsCategory) => [
          ...optionsCategory,
          { id: item.id.toString(), label: item.name },
        ]);
      });
  }, [dataCategory]);
  useEffect(() => {
    setOptionsUnit([]);
    dataUnit &&
      dataUnit.units.map(async (item) => {
        setOptionsUnit((optionsUnit) => [
          ...optionsUnit,
          { id: item.id.toString(), label: item.name },
        ]);
      });
  }, [dataUnit]);
  const [addStock] = useMutation(ADD_STOCK, {
    update(cache, { data: { createStock } }) {
      const { stocks } = cache.readQuery({ query: GET_STOCKS });
      cache.writeQuery({
        query: GET_STOCKS,
        data: { stocks: stocks.concat(createStock) },
      });
      closeDrawer([{ id: createStock.id, label: createStock.name }]);
      //props.handleClose({ value: createStock.id, label: createStock.name });
    },
  });
  const onSubmit = ({ code, category, criticalQuantity, name, unit }) => {
    addStock({
      variables: {
        category: category[0].id,
        code: code,
        criticalQuantity: +criticalQuantity,
        name: name,
        unitType: unit[0].id,
      },
    });
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Scrollbars
        autoHide
        renderView={(props) => (
          <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />
        )}
        renderTrackHorizontal={(props) => (
          <div
            {...props}
            style={{ display: 'none' }}
            className="track-horizontal"
          />
        )}
      >
        <Card>
          <FormControl
            label={<FormattedMessage id="stock.code" />}
            error={errors.code ? 'Please input a valid Code' : null}
          >
            <Input
              size={SIZE.compact}
              name="code"
              inputRef={register({
                required: true,
              })}
              error={errors.code}
              overrides={
                errors.code ? { After: Negative } : { After: Positive }
              }
            />
          </FormControl>
          <FormControl
            label={<FormattedMessage id="stock.name" />}
            error={errors.name ? 'Please input a valid  Name' : null}
          >
            <Input
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              error={errors.name}
              overrides={
                errors.name ? { After: Negative } : { After: Positive }
              }
            />
          </FormControl>
          <FormControl
            label={<FormattedMessage id="stock.category" />}
            error={errors.category ? 'Please select a valid Category' : null}
          >
            <Controller
              as={<Select size={SIZE.compact} />}
              inputRef={register({
                required: true,
              })}
              name="category"
              rules={{ required: true }}
              options={optionsCategory}
              control={control}
              error={errors.category}
              onChange={([selected]) => {
                return selected.option ? selected.value : undefined;
              }}
              overrides={
                errors.category ? { After: Negative } : { After: Positive }
              }
            />
          </FormControl>
          <Row style={{margin: 0, padding: 0}}> 
            <Col md={7}>
              <FormControl
                label={<FormattedMessage id="stock.unit" />}
                error={errors.unit ? 'Please select a valid Unit' : null}
              >
                <Controller
                  as={<Select size={SIZE.compact} />}
                  rules={{ required: true }}
                  name="unit"
                  options={optionsUnit}
                  control={control}
                  error={errors.unit}
                  onChange={([selected]) => {
                    return selected.option ? selected.value : undefined;
                  }}
                  overrides={
                    errors.unit ? { After: Negative } : { After: Positive }
                  }
                />
              </FormControl>
            </Col>
            <Col md={5}>
              <FormControl
                label={<FormattedMessage id="stock.critical_quantity" />}
                error={
                  errors.criticalQuantity
                    ? 'Please input a valid  Critical Quantity'
                    : null
                }
              >
                <Input
                  size={SIZE.compact}
                  name="criticalQuantity"
                  inputRef={register({
                    required: true,
                  })}
                  error={errors.criticalQuantity}
                  overrides={
                    errors.criticalQuantity
                      ? { After: Negative }
                      : { After: Positive }
                  }
                />
              </FormControl>
            </Col>
          </Row>
          <Row
            end="md"
            style={{
              width: '100%',
              marginTop: '50px',
              marginLeft: 0,
              marginRight: 0,
              marginBottom: 0,
              padding: 0,
            }}
          >
            <Col md={6}>
              <Button onClick={closeDrawer} style={{ width: '100%' }}>
                <FormattedMessage id="button.close" />
              </Button>
            </Col>
            <Col md={6}>
              <Button type="submit" style={{ width: '100%' }}>
                <FormattedMessage
                  id={true ? 'button.update' : 'button.create'}
                />
              </Button>
            </Col>
          </Row>
        </Card>
      </Scrollbars>
    </Form>
  );
}
