import React,{FunctionComponent} from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { Form } from '../../components/FormWithElemets/Form.style';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { CompanyTelephone } from '../../types/Company';
import { handleAddCompanyTelephone, handleUpdateCompanyTelephone } from './GraphqlFunction';


const ADD_COMPANY_TELEPHONE = loader(
  '../../graphql/company/ADD_COMPANY_TELEPHONE.graphql'
);
const UPDATE_COMPANY_TELEPHONE = loader(
  '../../graphql/company/UPDATE_COMPANY_TELEPHONE.graphql'
);
interface ListProps {
  data?: CompanyTelephone;
  setItemId?: Function;
  companyId: string;
}

const CompanyTelephoneForm: FunctionComponent<ListProps> = ({
  data,
  setItemId,
  companyId,
}) => {
  const {showToaster} = useToaster();
  const { register, handleSubmit, setValue } = useForm({
    defaultValues: {
      areaCode: data ? data?.areaCode : '',
      telephone: data ? data?.telephone : '',
    },
  });
  const [add] = useMutation(ADD_COMPANY_TELEPHONE, {
    update(cache, { data: { createCompanyTelephone } }) {
      handleAddCompanyTelephone(cache, createCompanyTelephone, companyId);
    },
  });
  const [update] = useMutation(UPDATE_COMPANY_TELEPHONE, {
    update(cache, { data: { updateCompanyTelephone } }) {
      handleUpdateCompanyTelephone(
        cache,
        updateCompanyTelephone,
        companyId
      );
    },
  });
  const onSubmit = ({ telephone, areaCode }) => {
    if (data) {
      update({
        variables: {
          id: data.id,
          telephone: telephone,
          areaCode: areaCode,
        },
      }).then(() => {
        setItemId(null);
        showToaster('updated', 'positive');
      });
    } else {
      add({
        variables: {
          companyId: companyId,
          telephone: telephone,
          areaCode: areaCode,
        },
      }).then(() => {
        handleFormReset()
        showToaster('created', 'positive');
      });
    }
  };
  const handleFormReset=()=>{
    setValue('telephone','');
    setValue('areaCode','');
  }
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        padding: 0,
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Row
        bottom="xs"
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <Col md={3}>
          <FormControl>
            <Input size={SIZE.compact} name={`areaCode`} inputRef={register} />
          </FormControl>
        </Col>
        <Col md={6}>
          <FormControl>
            <Input size={SIZE.compact} name={`telephone`} inputRef={register} />
          </FormControl>
        </Col>
        <Col md={3}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '16px',
            }}
          >
            <Button onClick={() => (data ? setItemId(null) : handleFormReset())}>
              <FormattedMessage id="button.close" />
            </Button>
            <Button type="submit">
              <FormattedMessage
                id={data ? 'button.update' : 'button.create'}
              />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyTelephoneForm;
