import { loader } from 'graphql.macro';
import { COLOR } from '../../components/FormWithElemets/Button/Button';
import { ShopFloorOrderStatus } from '../../types/ShopFloorOrderStatus';

const GET_SHOP_FLOORS = loader('../../graphql/shopFloorOrder/GET_SHOP_FLOORS.graphql');
export const handleAddProductRecord = (
  cache,
  shopFloorId,
  shopFloorOrderId,
  machineryAndEquipmentId,
  createProductRecord
) => {
  const { shopFloors } = cache.readQuery({
    query: GET_SHOP_FLOORS,
  });
  const newShopFloors = shopFloors.map((item) => {
    if (item.id === shopFloorId) {
      return {
        ...item,
        machineryAndEquipment: item.machineryAndEquipment.map((machineryAndEquipment) => {
          if (machineryAndEquipment.id === machineryAndEquipmentId) {
            return {
              ...machineryAndEquipment,
              shopFloorOrder: machineryAndEquipment.shopFloorOrder.map(
                (shopFloorOrder) => {
                  if (shopFloorOrder.id === shopFloorOrderId) {
                    return {
                      ...shopFloorOrder,
                      productRecords: shopFloorOrder.productRecords.concat(
                        createProductRecord
                      ),
                    };
                  } else {
                    return shopFloorOrder;
                  }
                }
              ),
            };
          } else {
            return machineryAndEquipment;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_SHOP_FLOORS,
    data: { shopFloors: newShopFloors },
  });
};
export const handleAddMachineMeasurementRecord = (
  cache,
  shopFloorId,
  machineryAndEquipmentId,
  shopFloorOrderId,
  createMachineMeasurementRecord
) => {
  const { shopFloors } = cache.readQuery({
    query: GET_SHOP_FLOORS,
  });
  const newShopFloors = shopFloors.map((item) => {
    if (item.id === shopFloorId) {
      return {
        ...item,
        machineryAndEquipment: item.machineryAndEquipment.map((machineryAndEquipment) => {
          if (machineryAndEquipment.id === machineryAndEquipmentId) {
            return {
              ...machineryAndEquipment,
              shopFloorOrder: machineryAndEquipment.shopFloorOrder.map(
                (shopFloorOrder) => {
                  if (shopFloorOrder.id === shopFloorOrderId) {
                    return {
                      ...shopFloorOrder,
                      machineMeasurementRecord: shopFloorOrder.machineMeasurementRecord.concat(
                        createMachineMeasurementRecord
                      ),
                    };
                  } else {
                    return shopFloorOrder;
                  }
                }
              ),
            };
          } else {
            return machineryAndEquipment;
          }
        }),
      };
    } else {
      return item;
    }
  });
  console.log("newShopFloors",newShopFloors)
  cache.writeQuery({
    query: GET_SHOP_FLOORS,
    data: { shopFloors: newShopFloors },
  });
};
export const handleUpdateMachineMeasurementRecord = (
  cache,
  shopFloorId,
  machineryAndEquipmentId,
  shopFloorOrderId,
  updateMachineMeasurementRecord
) => {
  const { shopFloors } = cache.readQuery({
    query: GET_SHOP_FLOORS,
  });
  const newShopFloors = shopFloors.map((item) => {
    if (item.id === shopFloorId) {
      return {
        ...item,
        machineryAndEquipment: item.machineryAndEquipment.map((machineryAndEquipment) => {
          if (machineryAndEquipment.id === machineryAndEquipmentId) {
            return {
              ...machineryAndEquipment,
              shopFloorOrder: machineryAndEquipment.shopFloorOrder.map(
                (shopFloorOrder) => {
                  if (shopFloorOrder.id === shopFloorOrderId) {
                    return {
                      ...shopFloorOrder,
                      machineMeasurementRecord: shopFloorOrder.machineMeasurementRecord.map(
                        (measurement) => {
                          if (measurement.id === updateMachineMeasurementRecord.id) {
                            return updateMachineMeasurementRecord;
                          } else {
                            return measurement;
                          }
                        }
                      ),
                    };
                  } else {
                    return shopFloorOrder;
                  }
                }
              ),
            };
          } else {
            return machineryAndEquipment;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_SHOP_FLOORS,
    data: { shopFloors: newShopFloors },
  });
};
export const handleDeleteMachineMeasurementRecord = (
  cache,
  shopFloorId,
  machineryAndEquipmentId,
  shopFloorOrderId,
  deleteMachineMeasurementRecord
) => {
  const { shopFloors } = cache.readQuery({
    query: GET_SHOP_FLOORS,
  });
  const newShopFloors = shopFloors.map((item) => {
    if (item.id === shopFloorId) {
      return {
        ...item,
        machineryAndEquipment: item.machineryAndEquipment.map((machineryAndEquipment) => {
          if (machineryAndEquipment.id === machineryAndEquipmentId) {
            return {
              ...machineryAndEquipment,
              shopFloorOrder: machineryAndEquipment.shopFloorOrder.map(
                (shopFloorOrder) => {
                  if (shopFloorOrder.id === shopFloorOrderId) {
                    return {
                      ...shopFloorOrder,
                      machineMeasurementRecord: shopFloorOrder.machineMeasurementRecord.filter(
                        (measurement) =>
                          measurement.id !== deleteMachineMeasurementRecord.id
                      ),
                    };
                  } else {
                    return shopFloorOrder;
                  }
                }
              ),
            };
          } else {
            return machineryAndEquipment;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_SHOP_FLOORS,
    data: { shopFloors: newShopFloors },
  });
};
export const handleUpdateShopFloorOrderStatus = (
  cache,
  shopFloorId,
  shopFloorOrderId,
  machineryAndEquipmentId,
  changeShopFloorOrderStatus
) => {
  const { shopFloors } = cache.readQuery({
    query: GET_SHOP_FLOORS,
  });
  const newShopFloors = shopFloors.map((item) => {
    if (item.id === shopFloorId) {
      return {
        ...item,
        machineryAndEquipment: item.machineryAndEquipment.map((machineryAndEquipment) => {
          if (machineryAndEquipment.id === machineryAndEquipmentId) {
            return {
              ...machineryAndEquipment,
              shopFloorOrder: machineryAndEquipment.shopFloorOrder.map(
                (shopFloorOrder) => {
                  if (shopFloorOrder.id === shopFloorOrderId) {
                    return {
                      ...shopFloorOrder,
                      shopFloorOrderStatus:
                        changeShopFloorOrderStatus?.shopFloorOrderStatus,
                    };
                  } else {
                    return shopFloorOrder;
                  }
                }
              ),
            };
          } else {
            return machineryAndEquipment;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_SHOP_FLOORS,
    data: { shopFloors: newShopFloors },
  });
};
export const machineStatusColor = (shopFloorOrderStatus: ShopFloorOrderStatus) =>
shopFloorOrderStatus.code === 'SHOP_FLOOR_ORDER_RUNNING'
  ? COLOR.green
  : shopFloorOrderStatus.code === 'SHOP_FLOOR_ORDER_CANCELED' || shopFloorOrderStatus.code ==='SHOP_FLOOR_ORDER_QUALITY_CONTROL_REQUIRED'
  ? COLOR.red
  : shopFloorOrderStatus.code === 'SHOP_FLOOR_ORDER_STOPPED'
  ? COLOR.orange
  : shopFloorOrderStatus.code === 'SHOP_FLOOR_ORDER_COMPLETED'
  ? COLOR.blue
  : COLOR.grey;
