import React, { useMemo, useState, FunctionComponent, useEffect } from 'react';
import { TableBuilder, TableBuilderColumn } from 'baseui/table-semantic';
import { Pagination } from 'baseui/pagination';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/FormWithElemets/Button/Button';
import DropdownButton, {
  SIZE,
  KIND,
} from '../../components/FormWithElemets/Button/DropdownButton';
import { faEllipsisHAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { Avatar } from 'baseui/avatar';
import { faTimesCircle, faCheckCircle } from '@fortawesome/pro-duotone-svg-icons';

export interface Buttons {
  label: string;
  function: any;
  close?: any;
  check_column?: string;
  check_type: string;
}
export type columnType =
  | 'img'
  | 'date'
  | 'float'
  | 'string'
  | 'text'
  | 'buttons'
  | 'boolean'
  | 'component'
  | 'array';

export interface ColumnArg {
  id: string;
  header: string | any;
  sortable: boolean;
  type: columnType;
  content?: string | React.FunctionComponentElement<any>;
  buttons?: Buttons[];
}

export interface DataTableProps {
  data: any;
  pageItem: number;
  columns: ColumnArg[];
  threeDot?: boolean;
  orderColumn?: string;
  sortOrder: 'ASC' | 'DESC';
}

export const getText = (type: columnType, data: any) => {
  if (type === 'date') {
    return data ? new Date(data)?.toLocaleDateString('tr-TR') : '------';
  } else if (type === 'boolean') {
    return (
      <FontAwesomeIcon
        size="2x"
        color={data ? '#35C795' : '#DB5461'}
        icon={data ? faCheckCircle : faTimesCircle}
      />
    );
  } else if (type === 'img') {
    return <Avatar size="scale1400" src={data} name={'profil picture'} />;
  } else {
    return data;
  }
};
function operation(threeDot, row, item) {
  if (item.type === 'component') {
    return item.content;
  }
  if (item.type !== 'buttons') {
    return getText(item.type, _.get(row, item.content));
  } else {
    if (threeDot) {
      let buttons = item.buttons;
      if (!!_.get(row, 'invoice') && item.type === 'buttons') {
        buttons = _.set(
          _.cloneDeep(item.buttons),
          _.findIndex(item.buttons, function (o: any) {
            return o.check_type === 'WAYBILL_TO_INVOICE';
          }) + '.disabled',
          !!_.get(row, 'invoice')
        );
      } else if (!!_.get(row, 'qualityCheck') && item.type === 'buttons') {
        buttons = _.set(
          _.cloneDeep(item.buttons),
          _.findIndex(item.buttons, function (o: any) {
            return o.check_type === 'CHECK';
          }) + '.disabled',
          !!_.get(row, 'qualityCheck')
        );
      } else if (!!_.get(row, 'offer') && item.type === 'buttons') {
        buttons = _.set(
          _.cloneDeep(item.buttons),
          _.findIndex(item.buttons, function (o: any) {
            return o.check_type === 'UPDATE';
          }) + '.disabled',
          !!_.get(row, 'offer')
        );
      }
      return (
        <DropdownButton
          items={buttons}
          kind={KIND.secondary}
          size={SIZE.mini}
          dataId={row.id}
        >
          <FontAwesomeIcon icon={faEllipsisHAlt} size="1x" />
        </DropdownButton>
      );
    } else {
      return item.buttons.map((button: Buttons, key) => {
        return (
          <Button
            key={key}
            onClick={() => {
              button.function(row.id);
              if (button.close) {
                button.close();
              }
            }}
          >
            {button.label}
          </Button>
        );
      });
    }
  }
}

export const DataTable: FunctionComponent<DataTableProps> = ({
  data,
  columns,
  pageItem,
  threeDot,
  sortOrder,
}) => {
  const [sortColumn, setSortColumn] = useState(null);
  const [sortAsc, setSortAsc] = useState(true);
  const [realData, setRealData] = useState(data);
  const [currentPage, setCurrentPage] = React.useState(1);
  useEffect(() => {
    setRealData(data);
  }, [data]);
  const intl = useIntl();

  function handleSort(id: string) {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc);
    } else {
      setSortColumn(id);
      setSortAsc(true);
    }
  }

  const sortedData = useMemo(() => {
    const page = currentPage * pageItem;
    return realData
      .slice()
      .sort((a: any, b: any) => {
        const left = sortAsc ? a : b;
        const right = sortAsc ? b : a;
        const leftValue = String(left[sortColumn]);
        const rightValue = String(right[sortColumn]);
        return leftValue.localeCompare(rightValue, 'en', {
          numeric: true,
          sensitivity: 'base',
        });
      })
      .slice(page - pageItem, page);
  }, [sortColumn, sortAsc, realData, currentPage, pageItem]);
  return (
    <>
      <TableBuilder
        data={sortedData}
        sortColumn={sortColumn}
        sortOrder={sortOrder}
        onSort={handleSort}
      >
        {columns.map((item: any) => {
          return (
            <TableBuilderColumn
              key={item.id}
              id={item.id}
              header={item?.header}
              sortable={item.sortable}
            >
              {(row) =>
                item.type === 'array'
                  ? _.get(row, item.content)?.map((itm: any) =>
                      itm.role ? (
                        <span key={itm.id}>
                          {itm?.role?.name ? ' ' + itm?.role?.name + ' ' : ''}
                        </span>
                      ) : (
                        itm?.product && (
                          <span key={itm.id} style={{display:"block"}}>
                            {itm?.product?.name ? ' ' + itm?.product?.name + ' ' : ''}
                          </span>
                        )
                      )
                    )
                  : operation(threeDot, row, item)
              }
            </TableBuilderColumn>
          );
        })}
      </TableBuilder>
      <Pagination
        numPages={Math.ceil(data.length / pageItem)}
        currentPage={currentPage}
        labels={{
          preposition: intl.formatMessage({ id: 'pagination.of' }),
        }}
        overrides={{
          PrevButton: {
            component: ({ onClick }: any) => (
              <Button disabled={currentPage === 1} onClick={onClick}>
                <FormattedMessage id="button.previous" />
              </Button>
            ),
          },
          NextButton: {
            component: ({ onClick }: any) => (
              <Button
                disabled={currentPage === Math.ceil(data.length / pageItem)}
                onClick={onClick}
              >
                <FormattedMessage id="button.next" />
              </Button>
            ),
          },
        }}
        onPageChange={({ nextPage }) => {
          setCurrentPage(Math.min(Math.max(nextPage, 1), 60));
        }}
      />
    </>
  );
};
