import React, { FunctionComponent, ReactNode } from "react";
import { useIntl } from "react-intl";

interface CardTitleProps{
    children?:ReactNode;
    title:string;
}
const CardTitle:FunctionComponent<CardTitleProps>=({children,title})=>{
  const intl=useIntl();
    return   <div className="bg-white rounded-t-md px-1 py-3 border-b border-gray-200 sm:px-6 print:hidden">
    <div className="flex items-center justify-between flex-wrap">
      <div className="sm:ml-3  ml-0  flex-shrink-0">
        <h3 className="text-lg  leading-6 font-medium text-gray-900">
          {intl.formatMessage({id:title})}
        </h3>
      </div>
      <div className="flex justify-end items-center flex-wrap">
        {children}
      </div>
    </div>
  </div>

}
export default CardTitle