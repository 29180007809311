import React, { useState, FunctionComponent, useEffect } from 'react';
import Pagination from './Pagination';
import { FormattedMessage, useIntl } from 'react-intl';
import { getButtons, getText } from './TableFunction';
import _ from 'lodash';
import { RowTitle, Col, RowCustom } from '../FlexBox/FlexBox';
import { Label, LabelCustom } from '../FormWithElemets/Label.style';
export type columnType = 'date' | 'text' | 'string' | 'buttons' | 'boolean';

export type ColumnContents = {
  name: any;
  title: { name: string; custom: boolean };
  type: columnType;
  col: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
  };
  unit?:string
};
interface ListProps {
  threeDot?: boolean | undefined;
  columnHeaders?: any;
  data: any;
  columnContents: ColumnContents[];
  formUpdate?: any;
  formAdd?: any;
  paginationInclude?: boolean | undefined;
}
const DataTableCustom: FunctionComponent<ListProps> = ({
  threeDot,
  columnHeaders,
  data,
  columnContents,
  formUpdate,
  formAdd,
  paginationInclude = true,
}) => {
  const intl = useIntl();
  const [realData, setRealData] = useState(() => data);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemId, setItemId] = useState(null);

  const edit: { label: string; function: Function } = {
    label: intl.formatMessage({ id: 'button.update' }),
    function: (id:string) => {
      setItemId(id);
    },
  };
  const [newColumnContents] = useState(() =>
    formUpdate
      ? columnContents.map((item) => {
          if (item.type === 'buttons' && formUpdate) {
            return {
              ...item,
              name: item.name.concat([edit]),
            };
          } else return item;
        })
      : columnContents
  );
  useEffect(() => {
    setRealData(data);
  }, [data]);
  useEffect(() => {
    if (paginationInclude) {
      const page = currentPage * 10;
      setRealData(data.slice(page - 10, page));
    }
  }, [paginationInclude, currentPage, data]);
  return (
    <>
      {columnHeaders && <RowTitle
        key={realData?.id}
        middle="xs"
        style={{ fontWeight: 'bold', padding: '5px', margin: 0 }}
      >
        {columnHeaders.map((item:any, index:number) => {
          return (
            <Col {...item.col} key={index}>
              <FormattedMessage id={item.name} />
            </Col>
          );
        })}
      </RowTitle>}
      {realData.map((item:any) =>
        item?.id !== itemId ? (
          <RowCustom
            middle="xs"
            key={item?.id}
            style={{
              width: '100%',
              marginTop: 0,
              marginBottom: '4px',
              marginLeft: 0,
              marginRight: 0,
            }}
          >
            {newColumnContents.map((content, index) => {
              return (
                <Col
                  {...content.col}
                  key={index}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    formUpdate && content.type !== 'buttons' && setItemId(item?.id);
                  }}
                >
                  {content.title.custom ? (
                    <LabelCustom
                      style={content.type === 'buttons' && { justifyContent: 'flex-end' }}
                      title={<FormattedMessage id={content.title.name} />}
                    >
                      {content.type === 'buttons'
                        ? getButtons(threeDot, item, content.name)
                        : content.type === 'text' || content.type === 'date'
                        ? getText(content.type, _.get(item, content.name))
                        : getText(content.type, _.get(item, content.name)) === 'true'
                        ? '✓'
                        : 'X'} {content?.unit || ""}
                    </LabelCustom>
                  ) : (
                    <Label
                      style={content.type === 'buttons' && { justifyContent: 'flex-end' }}
                      title={<FormattedMessage id={content.title.name} />}
                    >
                      {content.type === 'buttons'
                        ? getButtons(threeDot, item, content.name)
                        : content.type === 'text' || content.type === 'date'
                        ? getText(content.type, _.get(item, content.name))
                        : getText(content.type, _.get(item, content.name)) === 'true'
                        ? '✓'
                        : 'X'} {content?.unit || ""}
                    </Label>
                  )}
                </Col>
              );
            })}
          </RowCustom>
        ) : (
          <div key={item?.id} style={{ margin: 0, padding: 0 }}>
            {formUpdate && formUpdate({ item, setItemId })}
          </div>
        )
      )}
      {formAdd && itemId === null && formAdd({})}
      {paginationInclude && (
        <Pagination
          dataLength={data.length}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </>
  );
};
export default DataTableCustom;
