import React, { useState, createContext } from 'react';
import { loader } from 'graphql.macro';
import { useMutation } from '@apollo/react-hooks';
import { User } from '../types/User';

const LOGIN = loader('../graphql/auth/LOGIN.graphql');
const LOGIN_WITH_CARD = loader('../graphql/auth/LOGIN_WITH_CARD.graphql');
const LOGOUT = loader('../graphql/auth/LOGOUT.graphql');

type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  authenticateCard: Function;
  signout: Function;
  roles: any;
  setRoles: Function;
  profileData: any;
  permissions: any;
  user: User;
  setProfileData: Function;
};

export const AuthContext = createContext({} as AuthProps);

const isValidToken = () => {
  const token = localStorage.getItem('prodobit_token');
  return !!token;
};

const user_roles = () => {
  return localStorage.getItem('user_roles');
};

export const LOGIN_URL = 'http://reactor.prodobit.com/api/login';
export const LOGOUT_URL = 'http://reactor.prodobit.com/api/logout';
export const LOGIN_CARD_URL = 'http://reactor.prodobit.com/api/login-with-card';

const AuthProvider = (props: any) => {
  const [isAuthenticated, makeAuthenticated] = useState(isValidToken());
  const [roles, setRoles] = useState(user_roles());
  const [profileData, setProfileData] = useState(null);
  const [permissions, setPermissions] = useState([]);
  const [user, setUser] = useState(null);

  const [authenticate] = useMutation(LOGIN, {
    update(_, { data }) {
      const user = {
        name: data.login.user.name,
        surname: data.login.user.surname,
        profilePhoto: data.login.user.profilePhoto,
      };
      setUser(user);
      if (data.login.authToken) {
        const permission = [];
        data.login.user?.userRole?.map((v: any) => {
          if (v.role) {
            v.role.rolePermission?.map((rp: any) => {
              permission.push({
                action: rp.permission.code,
                subject: rp.permission.permissionGroup.code,
              });
            });
          }
        });

        setPermissions(permission);
        makeAuthenticated(true);
        localStorage.setItem('prodobit_token', `${data.login.authToken}`);
        localStorage.setItem('user_roles', JSON.stringify(permission));
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        makeAuthenticated(false);
      }
    },
  });

  const [authenticateCard] = useMutation(LOGIN_WITH_CARD, {
    update(_, { data }) {
      const user = {
        name: data.loginWithCard.user.name,
        surname: data.loginWithCard.user.surname,
        profilePhoto: data.loginWithCard.user.profilePhoto,
      };
      setUser(user);
      if (data.loginWithCard.authToken) {
        const permission = [];
        data.loginWithCard.user?.userRole?.map((v: any) => {
          if (v.role) {
            v.role.rolePermission?.map((rp: any) => {
              permission.push({
                action: rp.permission.code,
                subject: rp.permission.permissionGroup.code,
              });
            });
          }
        });

        setPermissions(permission);
        makeAuthenticated(true);
        localStorage.setItem('prodobit_token', `${data.loginWithCard.authToken}`);
        localStorage.setItem('user_roles', JSON.stringify(permission));
        localStorage.setItem('user', JSON.stringify(user));
      } else {
        makeAuthenticated(false);
      }
    },
  });

  const [signout] = useMutation(LOGOUT, {
    update(_, { data }) {
      makeAuthenticated(false);
      localStorage.removeItem('prodobit_token');
      localStorage.removeItem('user_roles');
      localStorage.removeItem('user');
      return true;
    },
  });
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        authenticateCard,
        signout,
        permissions,
        user,
        roles,
        setRoles,
        profileData,
        setProfileData,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
