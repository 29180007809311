import React, { FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { Textarea } from '../../components/Textarea/Textarea';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { Form } from '../../components/FormWithElemets/Form.style';
import { CompanyAddress } from '../../types/Company';
import { useToaster } from '../../context/toaster-provider';
import { handleAddCompanyAddress, handleUpdateCompanyAddress } from './GraphqlFunction';

const ADD_COMPANY_ADDRESS = loader('../../graphql/company/ADD_COMPANY_ADDRESS.graphql');
const UPDATE_COMPANY_ADDRESS = loader(
  '../../graphql/company/UPDATE_COMPANY_ADDRESS.graphql'
);
interface ListProps {
  data?: CompanyAddress;
  setItemId?: Function;
  companyId: string;
}
const CompanyAddressForm: FunctionComponent<ListProps> = ({
  data,
  setItemId,
  companyId,
}) => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      address: data ? data.address : '',
      country: data ? data.country : '',
      city: data ? data.city : '',
      county: data ? data.county : '',
    },
  });
  const [add] = useMutation(ADD_COMPANY_ADDRESS, {
    update(cache, { data: { createCompanyAddress } }) {
      handleAddCompanyAddress(cache, createCompanyAddress, companyId);
    },
  });
  const [update] = useMutation(UPDATE_COMPANY_ADDRESS, {
    update(cache, { data: { updateCompanyAddress } }) {
      handleUpdateCompanyAddress(cache, updateCompanyAddress, companyId);
    },
  });
  const onSubmit = ({ address, country, city, county }) => {
    if (data) {
      update({
        variables: {
          id: data.id,
          address: address,
          country: country,
          city: city,
          county: county,
        },
      }).then(() => {
        setItemId(null);
        showToaster('updated', 'positive');
      });
    } else {
      add({
        variables: {
          companyId: companyId,
          address: address,
          country: country,
          city: city,
          county: county,
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleFormReset();
      });
    }
  };
  const handleFormReset = () => {
    setValue('address', '');
    setValue('country', '');
    setValue('city', '');
    setValue('county', '');
  };
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        padding: 0,
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Row
        bottom="xs"
        style={{
          margin: 0,
          padding: 0,
        }}
      >
        <Col md={4}>
          <FormControl label={<FormattedMessage id="company.address.country" />}>
            <Input size={SIZE.compact} name={`country`} inputRef={register} />
          </FormControl>
        </Col>
        <Col md={4}>
          <FormControl label={<FormattedMessage id="company.address.city" />}>
            <Input size={SIZE.compact} name={`city`} inputRef={register} />
          </FormControl>
        </Col>
        <Col md={4}>
          <FormControl label={<FormattedMessage id="company.address.county" />}>
            <Input size={SIZE.compact} name={`county`} inputRef={register} />
          </FormControl>
        </Col>
        <Col md={8}>
          <Controller
            as={<Textarea size={SIZE.mini} />}
            name={`address`}
            control={control}
            inputRef={register}
            placeholder="Address...."
          />
        </Col>
        <Col md={4}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignContent: 'flex-end',
              marginBottom: '16px',
            }}
          >
            <Button onClick={() => (data ? setItemId(null) : handleFormReset())}>
              <FormattedMessage id="button.close" />
            </Button>
            <Button type="submit">
              <FormattedMessage id={data ? 'button.update' : 'button.create'} />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default CompanyAddressForm;
