import React, { FunctionComponent } from 'react';

interface Props {
  register?: any;
  className?: string;
}
const Textarea: FunctionComponent<Props & TextareaType> = ({
  register,
  className,
  ...props
}) => {
  return (
    <textarea
      className={
        ' shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border-gray-300 rounded-md ' +
        className
      }
      ref={register}
      {...props}
    ></textarea>
  );
};
export default Textarea;
export type TextareaType = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;
