import React, {
  useState,
  FunctionComponent,
  useContext,
  Fragment,
  useCallback,
} from 'react';
import { Item as ItemType, ItemsContext } from './AddSubContractSalesForm';
import ListItemSubcontractSalesItem from './ListItemSubcontractSalesItem';
import AddSubcontractSalesItemForm from './AddSubcontractSalesItemForm';
import ItemHeaders from './ItemHeaders';
import { ContextSubContractSalesStatus } from './SubContractSales';

const ListSubcontractSalesItem: FunctionComponent = () => {
  const { items, dispatchItems } = useContext(ItemsContext);
  const { setSubcontractSalesStatus } = useContext(ContextSubContractSalesStatus);
  const [itemAddShow, setItemAddShow] = useState(true);
  const [itemIndex, setItemIndex] = useState(null);
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
  }, []);
  const handleItemDelete = useCallback(
    (index: number) => {
      if (window.confirm('Are you sure !')) {
        dispatchItems({
          type: 'DELETE',
          index: index,
        });
      }
    },
    [dispatchItems]
  );
  return (
    <Fragment>
      <ItemHeaders
        itemsLenght={items?.length}
        setItemAddShow={setItemAddShow}
        itemAddShow={itemAddShow}
      />
      {itemAddShow  && (
        <AddSubcontractSalesItemForm
          itemIndex={itemIndex}
          setItemIndex={setItemIndex}
          setSubcontractSalesStatus={setSubcontractSalesStatus}
          setClose={setItemAddShow}
        />
      )}
      {items &&
        items.map((item: ItemType, index: number) => {
          return index !== itemIndex ? (
            <ListItemSubcontractSalesItem
              key={index}
              item={item}
              index={index}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
              setSubcontractSalesStatus={setSubcontractSalesStatus}
            />
          ) : (
            <AddSubcontractSalesItemForm
              key={index}
              item={item}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
              setSubcontractSalesStatus={setSubcontractSalesStatus}
            />
          );
        })}
    </Fragment>
  );
};
export default ListSubcontractSalesItem;
