import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useState } from 'react';
import { useToaster } from '../../../../../context/toaster-provider';
const UPDATE_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferOperationPrice/UPDATE_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE.graphql'
);
const ADD_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferOperationPrice/ADD_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE.graphql'
);
const useOperationPriceUpdate = () => {
  const { showToaster } = useToaster();
  const [result, setResult] = useState(false);
  const priceUpdate = ({
    executionPlanStepId,
    itemPrice,
    operationId,
    unitPrice,
    subcontractSalesOfferId,
    subcontractSalesOfferPriceId,
  }) => {
    const amount =Math.ceil(100 * 1 * unitPrice) / 100;
    itemPrice?.id
      ? update({
          variables: {
            id: itemPrice?.id,
            qty: 1,
            unitPrice: 1,
            duration: 1,
            amount: amount,
            discountRate: +itemPrice?.discountRate || 0,
            profitRate: +itemPrice?.profitRate || 0,
          },
        }).then(() => {
          showToaster('updated', 'positive');
        })
      : add({
          variables: {
            executionPlanStepId: executionPlanStepId,
            subcontractSalesOfferId: subcontractSalesOfferId,
            subcontractSalesOfferPriceId: subcontractSalesOfferPriceId,
            operationId: operationId,
            qty: 1,
            duration: 1,
            unitPrice: 1,
            amount: amount,
            discountRate: +itemPrice?.discountRate || 0,
            profitRate: +itemPrice?.profitRate || 0,
          },
        }).then(() => {
          showToaster('created', 'positive');
        });
    setResult(true);
  };
  const [update] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE);
  const [add] = useMutation(ADD_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE);

  return {
    priceUpdate,
    result,
  };
};
export default useOperationPriceUpdate;
// useEffect(() => {
//   offerMaterialPriceId
//     ? update({
//         variables: {
//           id: offerMaterialPriceId,
//           qty: qty,
//           unitPrice: unitPrice,
//           amount: +(qty * unitPrice).toFixed(2),
//         },
//       }).then(() => {
//         showToaster('updated', 'positive');
//       })
//     : add({
//         variables: {
//           billOfMaterialItemId: billOfMaterialItemId,
//           subcontractSalesOfferId: subcontractSalesOfferId,
//           subcontractSalesOfferPriceId: subcontractSalesOfferPriceId,
//           inventoryId: inventoryId,
//           qty: qty,
//           unitPrice: unitPrice,
//           amount: +(qty * unitPrice).toFixed(2),
//         },
//       }).then(() => {
//         showToaster('created', 'positive');
//       });
//   setResult(true);
// }, [
//   unitPrice,
//   offerMaterialPriceId,
//   qty,
//   billOfMaterialItemId,
//   subcontractSalesOfferId,
//   subcontractSalesOfferPriceId,
//   inventoryId,
// ]);
