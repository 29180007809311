import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import { Label } from '../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../components/TailWinduiTable/ColumnButtons';
import { ExecutionPlanStepInput } from '../../../types/ExecutionPlan';

interface ListItemProps {
  item: ExecutionPlanStepInput;
  handleItemDelete: Function;
  handleItemEdit: Function;
}
const ListItem: FunctionComponent<ListItemProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(item.id);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    }
  ];
  const lastIndex = item.inventory?.name.lastIndexOf('@@')
  return (
    <RowCustom>
      <Col xs={12} md={6} lg={4}>
        <Label title={intl.formatMessage({ id: 'executionPlanningStep.inputName' })}>
          {item.name || 'No Item'}
        </Label>
      </Col>
      <Col xs={12} md={6} lg={4}>
        <Label
          title={intl.formatMessage({ id: 'inventory.inventoryId' })}
        >
          {lastIndex ? item.inventory?.name.slice(0,lastIndex) : item.inventory?.name || 'No Item'}
        </Label>
      </Col>
      <ColButton xs>
        <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
      </ColButton>
    </RowCustom>
  );
};
export default ListItem;
