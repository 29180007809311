import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent,  useState } from 'react';
import { useForm } from 'react-hook-form';
import { useToaster } from '../../../../context/toaster-provider';
import { handleDate } from '../../../Functions/Utilities';
import InputForm from '../../ProductSales/InputForm';
import { Item } from '../../ProductSales/ProductSalesOfferAdd';
const ADD_INLINE_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/ADD_INLINE_PRODUCT_SALES_OFFER.graphql'
);
const UPDATE_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/UPDATE_PRODUCT_SALES_OFFER.graphql'
);
interface UpdateAddProductSalesItemProps {
  item?: Item;
  itemIndex?: number;
  setItemIndex?: Function;
  offerId?:string|undefined;
}
const UpdateAddProductSalesItem: FunctionComponent<UpdateAddProductSalesItemProps> = ({
  item,
  itemIndex = null,
  setItemIndex,
  offerId=undefined,
}) => {
  const { showToaster } = useToaster();
  const { register, setValue, control, getValues, watch, errors } = useForm();
  const [productRevision, setProductRevision] = useState('');
  const handleAddOrUpdateItem = () => {
    const { qty, productId, estimatedDeliveryDate, unitPrice } = getValues();
    if (qty !== '' && productId) {
      if (itemIndex === null) {
        addInlineProduct({
          variables: {
            id: offerId,
            productSalesOffer: handleNewProductItem({
              qty,
              productId,
              estimatedDeliveryDate,
              unitPrice : unitPrice || 0,
              productRevision,
            }),
          },
        }).then(() => {
          showToaster('created', 'positive');
        });;
      } else {
        updateInlineProduct({
          variables:{
            id: item.id,
            productSalesOffer: handleNewProductItem({
              qty,
              productId,
              estimatedDeliveryDate,
              unitPrice : unitPrice || 0,
              productRevision : productRevision || item.productRevisionId,
            }),
          }
        }).then(() => {
          showToaster('updated', 'positive');
        });
      }
      handleResetForm();
    }
  };
  const handleResetForm = () => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('productId', undefined);
      setValue('estimatedDeliveryDate', [new Date()]);
      setValue('qty', '');
      setValue('unitsId', undefined);
    }
  };
  const [addInlineProduct] = useMutation(ADD_INLINE_PRODUCT_SALES_OFFER);
  const [updateInlineProduct] = useMutation(UPDATE_PRODUCT_SALES_OFFER);
  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      setProductRevision={setProductRevision}
      item={item}
      handleResetForm={handleResetForm}
    />
  );
};
export default UpdateAddProductSalesItem;
const handleNewProductItem = ({
  qty,
  productId,
  estimatedDeliveryDate,
  unitPrice,
  productRevision,
}) => {
    return {
      estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
      productId: productId[0].id,
      productRevisionId: productRevision,
      qty: +qty,
      unitPrice: +unitPrice,
    };
};