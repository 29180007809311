// @ts-nocheck
import { styled } from 'baseui';

export const Card = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
  paddingTop: '15px',
  paddingBottom: '25px',
  paddingLeft: '15px',
  paddingRight: '15px',
  '@media all and (min-width: 768px)': {
    paddingTop: '20px',
    paddingBottom: '30px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
}));
export const Form = styled('form', ({ $theme }) => ({
  backgroundColor: $theme.colors.backgroundF7,
  paddingBottom: '65px',
}));
