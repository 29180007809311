import React, {  FunctionComponent } from 'react';
import { SIZE } from 'baseui/input';
import { Controller } from 'react-hook-form';
import { Select as SelectBaseUI } from 'baseui/select';

interface SelectProps {
  data: any;
  controller: any;
}
export const SelectBase: FunctionComponent<SelectProps> = ({
  data,
  controller,
}) => {
  return (
    <Controller
      as={<SelectBaseUI size={SIZE.compact} />}
      rules={{ required: true }}
      options={data}
      {...controller}
      overrides={{
        Root: {
          style: () => {
            return {
              position: 'relative',
            };
          },
        },
        Dropdown: {
          style: () => {
            return {
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              maxHeight: '200px',
            };
          },
        },
      }}
    />
  );
};
