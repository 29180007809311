import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import CSelectInventory from '../TailWinduiComponent/Select/CSelectInventory';
import Alert from '../TailWinduiHTML/Alerts/Alert';
import Label from '../TailWinduiHTML/Form/Input/Label';
import ErrorLabel from '../TailWinduiHTML/Form/Input/ErrorLabel';
import Input from '../TailWinduiHTML/Form/Input/Input';

interface InputFormProps {
  register: ReturnType<typeof useForm>['register'];
  control: ReturnType<typeof useForm>['control'];
  errors: ReturnType<typeof useForm>['errors'];
}
const InputForm: FunctionComponent<InputFormProps> = ({ control, register, errors }) => {
  const intl = useIntl();
  return (
    <div>
      <Alert>
        {intl.formatMessage({id:"required"})}
      </Alert>
      <div className="grid grid-cols-12 gap-2">
        <div className="col-span-12 md:col-span-6">
          <Label name="inventory" required={true}>
            {intl.formatMessage({ id: 'select.inventory' })}
          </Label>
          <CSelectInventory control={control} required={true} />
          <ErrorLabel>
            {errors?.inventory ? 'Please select a valid Inventory' : null}
          </ErrorLabel>
        </div>
        <div className="col-span-6 md:col-span-3">
          <Label name="artNo">{intl.formatMessage({ id: 'billOfMaterial.artNo' })}</Label>
          <Input name="artNo" register={register} />
        </div>
        <div className="col-span-6 md:col-span-3">
          <Label name="revisionNo">
            {intl.formatMessage({ id: 'billOfMaterial.revisionNo' })}
          </Label>
          <Input name="revisionNo" register={register} />
        </div>
      </div>
    </div>
  );
};
export default InputForm;
