import React, { Fragment, FunctionComponent, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';
import PurchaseMaterialOfferForm from './PurhaseMaterialOfferForm';
import ListItemBillOfMaterialItemRow from './ListItemBillOfMaterialItemRow';
import ListTechnicalImagesCustom from '../../../TechicalImages/ListAndAddTechicalImagesCustom';
import { loader } from 'graphql.macro';
import { PurchaseOffer } from '../../../../types/Purchase';
const DELETE_BILL_OF_MATERIAL_ITEM_ATTACHMENT = loader('../../../../graphql/uploadBillOfMaterialItem/DELETE_BILL_OF_MATERIAL_ITEM_ATTACHMENT.graphql');
const UPLOAD_BILL_OF_MATERIAL_ITEM_ATTACHMENT = loader(
  '../../../../graphql/uploadBillOfMaterialItem/UPLOAD_BILL_OF_MATERIAL_ITEM_ATTACHMENT.graphql'
);
interface ListRowProps {
  item: BillOfMaterialItem;
  handleItemDelete: Function;
  handleItemEdit: Function;
  offerId?: string
  orderQty?: number
  purchaseOffer: PurchaseOffer
}
const ListItemBillOfMaterialItem: FunctionComponent<ListRowProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
  offerId,
  orderQty = 0,
  purchaseOffer
}) => {
  const pageEndRef = useRef(null);
  const intl = useIntl();
  const [{ inventory }, setPurhaseOfferAdd] = useState({
    inventory: null,
  });
  const [showImages, setShowImages] = useState(false);
  useEffect(() => {
    if (pageEndRef.current) {
      pageEndRef?.current.scrollIntoView({ behavior: "smooth"})
    }
  },[pageEndRef,inventory])
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(item.id);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    },
    {
      label: intl.formatMessage({ id: 'purchaseOffer.materialAdd' }),
      function: () => {
        const _inventory = item?.inventory ? [{ id: item?.inventory?.id, label: item?.inventory.name }] : [{ id: item.name, label: item.name }];
        setPurhaseOfferAdd((prev) => ({
          inventory: prev.inventory === null ? _inventory : null
        }));
        setShowImages(false);
      },
      check_type: 'PURCHASEOFFERADD',
      disabled: offerId ? false : true
    },
    {
      label: intl.formatMessage({ id: 'billOfMaterial.images' }),
      function: () => {
        setShowImages((prev) => !prev);
        setPurhaseOfferAdd({
          inventory: null
        });
      },
      check_type: 'IMAGES',
    },
  ];
  const purchaseMaterialOffer = useMemo(() => {
    const billOfMaterialItemName = item?.inventory?.name || item?.name;
    return purchaseOffer?.purchaseMaterialOffer.filter(item => (item.inventory?.name === billOfMaterialItemName) || (item?.name === billOfMaterialItemName))
  }, [purchaseOffer])
  return (
    <Fragment >
      <ListItemBillOfMaterialItemRow buttons={buttons} item={item} itemPurchaseMaterialOffer={purchaseMaterialOffer}/>
      {inventory !== null && (
        <PurchaseMaterialOfferForm
          item={item}
          purchaseOfferId={purchaseOffer?.id}
          inventory={inventory}
          offerId={offerId}
          orderQty={orderQty}
          setClose={setPurhaseOfferAdd}
        />
      )}
      {showImages && (
        <ListTechnicalImagesCustom
          imajes={item.billOfMaterialItemAttachment}
          parentVariables={{ billOfMaterialItemId: item.id }}
          addMutation={UPLOAD_BILL_OF_MATERIAL_ITEM_ATTACHMENT}
          deleteMutation={DELETE_BILL_OF_MATERIAL_ITEM_ATTACHMENT}
        />
      )}
      <div ref={pageEndRef}/>
    </Fragment>
  );
};
export default ListItemBillOfMaterialItem;
