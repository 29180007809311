import React, { Fragment, FunctionComponent, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import {
  Card,
  CardBody,
  CardTitle,
} from '../../../components/FormWithElemets/Card.style';
import CompanyInformation from '../../Functions/CompanyInformation';
import AddPurchaseOfferForm from '../AddPurchaseOffer/AddPurchaseOfferForm';
import { ComponentStatusContext } from '../Offer';
import AssignOfferToPerson from './AssignOfferToPerson';
import ListOperationSalesItem from './OperationSales/ListOperationSalesItem';
import ListProductSalesItem from './ProductSales/ListProductSalesItem';
import ListSubcontractSalesItem from './SubContractSales/ListSubcontractSalesItem';
import UpdateOfferStatus from './UpdateOfferStatus';

const ToAssesment: FunctionComponent = () => {
  const { componentStatus, dispatchComponentStatus } = useContext(ComponentStatusContext);
  const {
    id,
    company,
    subcontractSalesOffer,
    productSalesOffer,
    operationSalesOffer,
    offerStatus,
    assigned, 
  } = componentStatus?.data;
  const currentSalesOffer = subcontractSalesOffer?.length
    ? 'update_subContract'
    : productSalesOffer?.length
      ? 'update_product'
      : operationSalesOffer?.length && 'update_operation';
  const [addPurchaseOffer, setAddPurchaseOffer] = useState(false);
  return (
    <Fragment>
      <Card style={addPurchaseOffer ? { display: 'none' } : {}}>
        <CardTitleComponent dispatchComponentStatus={dispatchComponentStatus} setAddPurchaseOffer={setAddPurchaseOffer} currentSalesOffer={currentSalesOffer} />
        <CardBody>
          <Row between="xs">
            <Col md={6} lg={6} xs={12}>
              <CompanyInformation
                companyId={company?.id}
                companyName={company?.companyName}
              />
            </Col>
            <Col md={6} lg={6} xs={12}>
              <AssignOfferToPerson item={assigned} offerId={id} />
              <UpdateOfferStatus offerId={id} offerStatus={offerStatus}/>
            </Col>
          </Row>
          <div style={{ marginTop: '50px' }} />
          {currentSalesOffer === 'update_subContract' && (
            <ListSubcontractSalesItem
              offerId={id}
            />
          )} 
          {currentSalesOffer === 'update_product' && (
            <ListProductSalesItem  offerId={id} />
          )}
          {currentSalesOffer === 'update_operation' && (
            <ListOperationSalesItem  offerId={id} />
          )}
        </CardBody>
      </Card>
      {addPurchaseOffer && (
        <AddPurchaseOfferForm setAddPurchaseOffer={setAddPurchaseOffer} />
      )}
    </Fragment>
  );
};
export default ToAssesment;






interface CardTitleComponentProps {
  currentSalesOffer: string;
  setAddPurchaseOffer: Function;
  dispatchComponentStatus: Function;
}
const CardTitleComponent: FunctionComponent<CardTitleComponentProps> = ({
  currentSalesOffer,
  setAddPurchaseOffer,
  dispatchComponentStatus
}) => {
  return (
    <CardTitle title={<FormattedMessage id={`offer.${currentSalesOffer}`} />}>
      <Button
        color={COLOR.green}
        onClick={() => {
          setAddPurchaseOffer(true);
        }}
      >
        <FormattedMessage id="purchaseOffer.add" />
      </Button>
      <Button
        onClick={() => {
          dispatchComponentStatus({ type: 'LIST' });
        }}
      >
        <FormattedMessage id="button.back" />
      </Button>
    </CardTitle>
  )
}