import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { handleAdd, handleUpdate } from './GraphqlFunction';
import { AqlSelect } from '../Product/ProductCategoryAndAqlSelect';
import { useToaster } from '../../context/toaster-provider';

const UPDATE_AQL_SAMPLE_RULE = loader(
  '../../graphql/aqlSampleRule/UPDATE_AQL_SAMPLE_RULE.graphql'
);
const ADD_AQL_SAMPLE_RULE = loader(
  '../../graphql/aqlSampleRule/ADD_AQL_SAMPLE_RULE.graphql'
);

const DayForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue, control } = useForm();
  const { showToaster } = useToaster();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('approvedMax', dataDispatch.approvedMax);
      setValue('aql', dataDispatch.aql?.id ?  [{ id: dataDispatch.aql.id, label: dataDispatch.aql.code }]:undefined);
      setValue('lotMaxSize', dataDispatch.lotMaxSize);
      setValue('lotMinSize', dataDispatch.lotMinSize);
      setValue('sampleSize', dataDispatch.sampleSize);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ approvedMax, aql, lotMaxSize, lotMinSize, sampleSize }) => {
    dataDispatch
      ? update({
          variables: {
            id: dataDispatch.id,
            approvedMax: Number(approvedMax),
            aqlId: aql[0].id,
            lotMaxSize: Number(lotMaxSize),
            lotMinSize: Number(lotMinSize),
            sampleSize: Number(sampleSize),
          },
        }).then(()=>showToaster('updated', 'positive'))
      : add({
          variables: {
            approvedMax: Number(approvedMax),
            aqlId: aql[0].id,
            lotMaxSize: Number(lotMaxSize),
            lotMinSize: Number(lotMinSize),
            sampleSize: Number(sampleSize),
          },
        }).then(()=>showToaster('created', 'positive'));
  };

  const [add] = useMutation(ADD_AQL_SAMPLE_RULE, {
    update(cache, { data: { createAqlSampleRule } }) {
      handleAdd(cache, createAqlSampleRule);
      closeDrawer();
    },
  });
  const [update] = useMutation(UPDATE_AQL_SAMPLE_RULE, {
    update(cache, { data: { updateAqlSampleRule } }) {
      handleUpdate(cache, updateAqlSampleRule);
      closeDrawer();
    },
  });
  return (
    <Card
      title={
        <FormattedMessage
          id={dataDispatch ? 'aql_sample_rule.update' : 'aql_sample_rule.add'}
        />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <AqlSelect control={control} errors={errors} />
        <FormControl
          label={<FormattedMessage id="aql_sample_rule.sampleSize" />}
          error={errors.sampleSize ? 'Please input a valid Sample Size' : null}
        >
          <Input
            size={SIZE.compact}
            name="sampleSize"
            inputRef={register({
              required: true,
            })}
            error={errors.sampleSize}
            overrides={errors.sampleSize ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <FormControl
          label={<FormattedMessage id="aql_sample_rule.lotMinSize" />}
          error={errors.lotMinSize ? 'Please input a valid Lot Min Size' : null}
        >
          <Input
            size={SIZE.compact}
            name="lotMinSize"
            inputRef={register({
              required: true,
            })}
            error={errors.lotMinSize}
            overrides={errors.lotMinSize ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <FormControl
          label={<FormattedMessage id="aql_sample_rule.lotMaxSize" />}
          error={errors.lotMaxSize ? 'Please input a valid Lot Max Size' : null}
        >
          <Input
            size={SIZE.compact}
            name="lotMaxSize"
            inputRef={register({
              required: true,
            })}
            error={errors.lotMaxSize}
            overrides={errors.lotMaxSize ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <FormControl
          label={<FormattedMessage id="aql_sample_rule.approvedMax" />}
          error={errors.approvedMax ? 'Please input a valid Approved Max' : null}
        >
          <Input
            size={SIZE.compact}
            name="approvedMax"
            inputRef={register({
              required: true,
            })}
            error={errors.approvedMax}
            overrides={errors.approvedMax ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default DayForm;
