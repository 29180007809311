import React, { FunctionComponent } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Form } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { CostCenter } from '../../types/CostCenter';
import { useToaster } from '../../context/toaster-provider';

const GET_COST_CENTERS = loader('../../graphql/costCenter/GET_COST_CENTERS.graphql');
const ADD_COST_CENTER = loader('../../graphql/costCenter/ADD_COST_CENTER.graphql');
const UPDATE_COST_CENTER = loader('../../graphql/costCenter/UPDATE_COST_CENTER.graphql');

interface ListCostCenterProps {
  data?: CostCenter;
  setItemId?: Function;
}
const CostCenterForm: FunctionComponent<ListCostCenterProps> = ({ data, setItemId }) => {
  const { showToaster } = useToaster();
  const { register, handleSubmit, errors, setValue } = useForm({
    defaultValues: {
      name: data ? data.name : '',
    },
  });

  const onSubmit = ({ name }) => {
    data
      ? update({
          variables: {
            id: data.id,
            name: name,
          },
        }).then(()=>{
          showToaster('updated', 'positive');
          setItemId(null)
        })
      : add({
          variables: { name: name },
        }).then(()=>{
          showToaster('created', 'positive');
          setValue('name','')
        });
  };
  const [add] = useMutation(ADD_COST_CENTER, {
    update(cache, { data: { createCostCenter } }) {
      const { costCenters } = cache.readQuery({ query: GET_COST_CENTERS });
      cache.writeQuery({
        query: GET_COST_CENTERS,
        data: { costCenters: costCenters.concat(createCostCenter) },
      });
    },
  });
  const [update] = useMutation(UPDATE_COST_CENTER, {
    update(cache, { data: { updateCostCenter } }) {
      const { costCenters } = cache.readQuery({ query: GET_COST_CENTERS });
      const newCostCenters = costCenters.map((item) => {
        if (updateCostCenter.id === item.id) {
          return {
            ...updateCostCenter,
          };
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_COST_CENTERS,
        data: { costCenters: newCostCenters },
      });
    },
  });
  return (
    <Form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        height: '100%',
        backgroundColor: 'transparent',
      }}
    >
      <Row style={{margin:0,padding:0}}>
        <Col md={9}>
          <FormControl
            error={errors.name ? 'Please input a valid Cost Center' : null}
          >
            <Input
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              autoComplete="off"
              error={errors.name ? true : false}
              overrides={errors.name ? { After: Negative } : { After: Positive }}
            />
          </FormControl>
        </Col>
        <Col md={3}>
          <div
            style={{
              height: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => (data ? setItemId(null) : setValue('name', ''))}
              style={{ marginBottom: '16px' }}
            >
              <FormattedMessage id="button.close" />
            </Button>
            <Button type="submit" style={{ marginBottom: '16px' }}>
              <FormattedMessage id={data ? 'button.update' : 'button.create'} />
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};
export default CostCenterForm;
