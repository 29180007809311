import {
    faArrowLeft,
  } from '@fortawesome/pro-regular-svg-icons';
  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import React, { useState } from 'react';
  import { FunctionComponent } from 'react';
  import { FormattedMessage } from 'react-intl';
  import Button, {
    COLOR,
  } from '../../../../../components/FormWithElemets/Button/Button';
  import { CardBody } from '../../../../../components/FormWithElemets/Card.style';
  import { Label } from '../../../../../components/FormWithElemets/Label.style';
import { ProductSalesOffer } from '../../../../../types/ProductSalesOffer';
import ProductSalesPriceForm from './ProductSalesPriceForm';
import ProductSalesOfferPriceItem from './ProductSalesPriceItem';
  
  interface OperationSalesPriceProps {
      item:ProductSalesOffer,
      transportUnitPrice:number
  }
  const ProductSalesPrice: FunctionComponent<OperationSalesPriceProps> = ({
      item,
      transportUnitPrice
  }) => {
    const [itemAdd, setItemAdd] = useState(null);
    return (
      <CardBody style={{ paddingLeft: '20px', backgroundColor:  "#e5e7eb"}}>
        <Label
          style={{ borderBottom: '1px solid ' + COLOR.grey }}
          title={<FormattedMessage id="offerPrice.operation" />}
        >
          <Button disabled={!itemAdd} onClick={() => setItemAdd(null)}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </Label> 
          {itemAdd !== null ? (
            <ProductSalesPriceForm item={itemAdd} setItemAdd={setItemAdd} transportUnitPrice={transportUnitPrice}/>
          ): itemAdd === null && (
             <ProductSalesOfferPriceItem item={item} setItemAdd={setItemAdd} transportUnitPrice={transportUnitPrice}/>
          )}
      </CardBody>
    );
  };
  export default ProductSalesPrice