import React, { FunctionComponent, useCallback } from 'react';
//import { useIntl } from 'react-intl';
import { Card, CardBody, CardTitle } from '../../components/FormWithElemets/Card.style';
import { useDrawerDispatch, useDrawerState } from '../../context/DrawerContext';
import Button from '../../components/FormWithElemets/Button/Button';
import { FormattedMessage } from 'react-intl';

const TechnicalImagesPreview: FunctionComponent = () => {
  const dispatch = useDrawerDispatch();
  const { url, fileType } = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  return (
    <Card style={{ height: '85%' }}>
      <CardTitle>
        <Button onClick={closeDrawer}>
          <FormattedMessage id="button.close" />
        </Button>
      </CardTitle>
      <CardBody style={{ padding: "5px", margin: 0, height: '100%',overflowX: "hidden" }}>
        {fileType === 'pdf' ? (
          <embed src={url} width="100%" height="85%" />
        ) : (
          <img src={url} width="100%" height="auto" alt="technicalImage" />
        )}
      </CardBody>
    </Card>
  );
};

export default TechnicalImagesPreview;
