import React, { FunctionComponent, useCallback, useContext } from 'react';
import {
  ProcessCard,
  Col1,
  BoxRightCol,
  ProcessLeft,
  RowSpecial,
  NewRow,
  NewCol,
} from './RequestBox.style';
import { Offer } from '../../types/Offer';
import { DraggableProvided, DraggableStateSnapshot } from 'react-beautiful-dnd';
import { KIND, Tag } from 'baseui/tag';
import { Col } from '../../components/FlexBox/FlexBox';
import OperationSales from './OperationSales';
import SubcontractSales from './SubcontractSales';
import ProductSales from './ProductSales';
import { ComponentStatusContext } from './Offer';
import { FormattedMessage } from 'react-intl';
import { LabelCol } from '../../components/FormWithElemets/Label.style';

interface RequestBoxProps {
  data: Offer;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
  columnId: string;
}

export const RequestBox: FunctionComponent<RequestBoxProps> = ({
  data,
  provided,
  snapshot,
  columnId,
}) => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const drawerMap: { [key: string]: string } = {
    '0': 'TO_ASSESSMENT',
    '1': 'IN_REVIEW',
    '2': 'IN_OFFER_STAGE',
    '3': 'DONE',
  };
  const openDrawer = useCallback(() => {
    if (drawerMap[columnId] === 'TO_ASSESSMENT') {
      dispatchComponentStatus({
        type: 'TO_ASSESSMENT',
        offerType: '',
        data: data,
      });
    } else if (drawerMap[columnId] === 'IN_REVIEW') {
      dispatchComponentStatus({
        type: 'IN_REVIEW',
        offerType: '',
        data: data,
      });
    } else if (drawerMap[columnId] === 'IN_OFFER_STAGE') {
      dispatchComponentStatus({
        type: 'IN_OFFER_STAGE',
        offerType: '',
        data: data,
      });
    } else if (drawerMap[columnId] === 'DONE') {
      dispatchComponentStatus({
        type: 'DONE',
        offerType: '',
        data: data,
      });
    }
  }, [drawerMap, columnId, data, dispatchComponentStatus]);
  return (
    <ProcessCard
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        backgroundColor: snapshot.isDragging ? '#efefef' : null,
        ...provided.draggableProps.style,
      }}
      onClick={openDrawer}
    >
      <NewRow>
        <Col1 md={1} lg={1} xl={1} {...provided.dragHandleProps}>
          <ProcessLeft>
            {data.company.companyShortName
              ? data.company.companyShortName.split(' ')[0]
              : 'No Header'}
          </ProcessLeft>
        </Col1>
        <BoxRightCol xs>
          {columnId === '3' && (
            <RowSpecial>
              <Col xs={12} style={{ textAlign: 'center' }}>
                <Tag
                  closeable={false}
                  color="#4327F1"
                  kind={KIND.custom}
                  onClick={() => {}}
                >
                  {data?.offerStatus.name}
                </Tag>
              </Col>
            </RowSpecial>
          )}
          <RowSpecial middle="xs" between="xs">
            <NewCol md={12} lg={12}>
              <LabelCol title={'Req No'}>OF{data.serial}</LabelCol>
            </NewCol>
          </RowSpecial>
          <RowSpecial>
            <NewCol md={12} lg={12}>
              <LabelCol title={<FormattedMessage id="select.assignOfferToPerson" />}>
                {data?.assigned?.name + data.assigned?.surname}
              </LabelCol>
            </NewCol>
          </RowSpecial>
          {(data?.offerStatus.code === 'OFFER_CANCELED' ||
            data?.offerStatus.code === 'OFFER_DECLINED') && (
            <RowSpecial>
              <NewCol md={12} lg={12}>
                <LabelCol
                  title={<FormattedMessage id="select.canceledordeclinedreason" />}
                >
                  {data?.canceledOrDeclinedReason?.name || 'No...'}
                </LabelCol>
              </NewCol>
            </RowSpecial>
          )}
          <OperationSales data={data.operationSalesOffer} />
          <SubcontractSales data={data.subcontractSalesOffer} />
          <ProductSales data={data.productSalesOffer} />
        </BoxRightCol>
      </NewRow>
    </ProcessCard>
  );
};
