import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { SubcontractSalesOffer } from '../../../../types/SubcontractSalesOffer';
import { SubcontractSalesOfferIDs } from '../../../../types/SubcontractSalesOfferID';
import { materialOperationPriceCalculate } from '../SubcontractSales/ItemDetailPrice/ItemDetail';
const UPDATE_SUBCONTRACT_SALES_OFFER_PRICE = loader(
  '../../../../graphql/subcontractSalesOfferPrice/UPDATE_SUBCONTRACT_SALES_OFFER_PRICE.graphql'
);
type useTranstIncludeType = {
  data: SubcontractSalesOfferIDs;
  transportUnitPrice: number;
};
const useTranstInclude = ({
  data,
  transportUnitPrice,
}: useTranstIncludeType) => {
  const [update] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER_PRICE);
  const handleTransportCalculate = () => {
    if (data) {
      const {
        subcontractSalesOffer: { subcontractSalesOffer },
      } = data;
      const subcontractSalesOfferUnitPrices = handleSubcontractSalesOfferUnitPrices(
        subcontractSalesOffer
      );
      transportCalculate({
        update,
        transportUnitPrice,
        subcontractSalesOfferUnitPrices,
      });
    }
  };
  return {
    handleTransportInclude: handleTransportCalculate,
  };
};
export default useTranstInclude;
const transportCalculate = ({
  update,
  transportUnitPrice,
  subcontractSalesOfferUnitPrices,
}) => {
  subcontractSalesOfferUnitPrices?.forEach((item: any) => {
    if (item?.id) {
      update({
        variables: {
          id: item.id,
          unitPrice:
            Math.ceil(100 * (item.unitPrice + transportUnitPrice)) / 100,
          amount:
            Math.ceil(100 * (item.unitPrice + transportUnitPrice) * item.qty) /
            100,
        },
      });
    }
  });
};
const handleSubcontractSalesOfferUnitPrices = (
  subcontractSalesOffer: SubcontractSalesOffer[]
) => {
  return subcontractSalesOffer.map((item: SubcontractSalesOffer) => {
    if (item?.subcontractSalesOfferPrice) {
      const {
        subcontractSalesOfferPrice: {
          id,
          qty,
          subcontractSalesOfferMaterialPrice,
          subcontractSalesOfferOperationPrice,
          discountRate,
          profitRate
        },
      } = item;
      return {
        id: id,
        qty: qty,
        unitPrice: materialOperationPriceCalculate(
          subcontractSalesOfferMaterialPrice,
          subcontractSalesOfferOperationPrice,
          discountRate, profitRate
        ),
      };
    } else {
      return null;
    }
  });
};
