import { Input, SIZE } from 'baseui/input';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  DivSubmit,
  Row,
} from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';
import { inputDecimal } from '../../../Functions/Utilities';
import SelectInventory from '../../../SelectCustom/SelectInventory';
import SelectInventoryType from '../../../SelectCustom/SelectInventoryType';

interface InputFormProps {
  item: BillOfMaterialItem;
  register: any;
  watch: any;
  errors: any;
  setValue?: any;
  control: any;
  handleSubmit?: Function | undefined;
  handleResetForm: Function;
  onSubmit: Function;
}
const InputForm: FunctionComponent<InputFormProps> = ({
  item,
  control,
  watch,
  errors,
  setValue,
  register,
  handleResetForm,
  handleSubmit,
  onSubmit,
}) => {
  const { materialItemInventoryId, inventoryTypeId } = watch();
  const { select, inventoryType } = SelectInventory({
    name: 'materialItemInventoryId',
    defaultValue:materialItemInventoryId,
    control,
    errors,
    required: true,
    creatable: true,
    disabled: false,
  });
  useEffect(() => {
    setValue("inventoryTypeId", inventoryType)
  }, [inventoryType]);
  const inventoryTypeDisabled = useMemo(() => {
    if (materialItemInventoryId && materialItemInventoryId[0]) {
      const result = materialItemInventoryId[0].id === materialItemInventoryId[0].label
      return !result
    } else {
      return false
    }
  }, [materialItemInventoryId]);
  const unitPriceDisabled = useMemo(() => {
    if (inventoryTypeId && inventoryTypeId[0]) {
      const result = "d5b8e917-9bc5-42e6-a23a-b721e72972a1" === inventoryTypeId[0].id ||
        "Hammadde" === inventoryTypeId[0].label
      return result
    } else {
      return false
    }
  }, [inventoryTypeId])
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row middle="xs">
        <Col xs={12} md={7} lg={4}>
          {select}
        </Col>
        <Col xs={12} md={5} lg={4}>
          <SelectInventoryType control={control} errors={errors} disabled={inventoryTypeDisabled} />
        </Col>
        <Col sm={6} md={4} lg={2}>
          <FormControl label={<FormattedMessage id="materialItem.partNumber" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="partNumber"
              inputRef={register({
                required: false,
              })}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <Col sm={6} md={4} lg={2}>
          <FormControl label={<FormattedMessage id="materialItem.quantity" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="quantity"
              disabled={unitPriceDisabled}
              inputRef={register({
                required: false,
              })}
              onChange={(event) => {
                setValue('quantity', inputDecimal({ event }));
              }}
              clearOnEscape
            />
          </FormControl>
        </Col>
        {/* <Col sm={9} md={5} lg={3}>
          <SelectCompany
            name={'vendor'}
            control={control}
            errors={errors}
            update={false}
            creatable={false}
          />
        </Col> */}
        <Col xs>
          <DivSubmit>
            <Button type="button" onClick={() => handleResetForm()}>
              <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
            </Button>
            <Button type="submit">
              <FormattedMessage id={item ? 'button.update' : 'button.add'} />
            </Button>
          </DivSubmit>
        </Col>
      </Row>
    </Form>
  );
};
export default InputForm;
