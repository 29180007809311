import React, { FunctionComponent } from 'react';
import { WarehouseShelf as WarehouseShelfType} from '../../types/WarehouseShelf';
import List from './List';

// const GET_WAREHOUSE_SECTIONS = loader(
//   '../../graphql/warehouseSection/GET_WAREHOUSE_SECTIONS.graphql'
// );
interface ListProps {
  data: WarehouseShelfType[];
  warehouseId:string;
}
const WarehouseSheft: FunctionComponent<ListProps> = ({ data,warehouseId }) => {
  // const { data, error, loading } = useQuery<WarehouseSections>(GET_WAREHOUSE_SECTIONS);
  return data && <List data={data}  warehouseId={warehouseId} />;
};

export default WarehouseSheft;
