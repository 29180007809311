import React, { FunctionComponent } from 'react';
import { Col } from 'react-flexbox-grid';
import { useIntl } from 'react-intl';
import { RowCustom } from '../../../../components/FlexBox/FlexBox';
import { COLOR } from '../../../../components/FormWithElemets/Button/Button';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { PurchaseOperationOffer } from '../../../../types/PurchaseOperationOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';
interface PurchaseOperationOfferPreviewProps {
  item: PurchaseOperationOffer;
}
const PurchaseOperationOfferPreview: FunctionComponent<PurchaseOperationOfferPreviewProps> = ({
  item,
}) => {
  const intl = useIntl();
  return (
    <RowCustom>
      <Col xs={12} md={5} lg={4}>
        <LabelCustom title={intl.formatMessage({ id: 'select.operation' })}>
          <span
            style={{
              fontSize: '20px',
              fontWeight: 'lighter',
              textDecoration: 'underline',
              color: COLOR.grey,
            }}
          >
            {item?.operation?.name || 'No Item'}
          </span>
        </LabelCustom>
      </Col>

      <Col xs={6} md={3} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'date.estimatedDate' })}>
          {handleLocalDateString(item.estimatedDate)}
        </LabelCustom>
      </Col>
      <Col xs={6} md={3} lg={2}>
        <LabelCustom title={intl.formatMessage({ id: 'purchaseOffer.qty' })}>
          {item?.qty || 0}
        </LabelCustom>
      </Col>
    </RowCustom>
  );
};
export default PurchaseOperationOfferPreview