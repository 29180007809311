// @ts-nocheck
import React from 'react';
import { styled } from 'baseui';
import { Col } from '../../components/FlexBox/FlexBox';

export const Form = styled('form', ({ $theme }) => ({
  paddingBottom: '20px',
  backgroundColor: 'transparent',
  height: '100%',
}));
export const RowTitle = styled('div', () => ({
  marginRight: 0,
  marginLeft: 0,
  marginBottom: '3px',
  padding: 0,
  '@media all and (max-width: 768px)': {
    display: 'none',
  },
}));
export const RightBox = styled(Col, () => ({
  '@media only screen and (max-width: 1700px)': {
    height: 'auto',
    width: 'auto',
    display: 'flex',
    flexDirection: 'column',
    webkitBoxAlign: 'center',
    alignItems: 'center',
    webkitBoxPack: 'center',
    justifyContent: 'center',
    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 21px 36px',
    cursor: 'pointer',
    position: 'fixed',
    right: '0px',
    top: '20%',
    marginTop: '0px',
    zIndex: '1',
    borderRadius: '6px 0px 0px 6px',
    borderWidth: '0px',
    borderStyle: 'initial',
    borderColor: 'initial',
    borderImage: 'initial',
    outline: '0px',
    padding: 0,
  },
}));

export const RightBoxItem = styled('div', () => ({
  //AddInvoice
  fontWeight: '700',
  padding: '5px',
  color: '#454b68',
  display: 'flex',
  webkitBoxAlign: 'center',
  alignItems: 'center',
  webkitBoxPack: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));
export const RightBoxRow = styled('div', () => ({
  width: '100%',
  height: 'auto',
  minWidth: '80px',
  display: 'block',
  webkitBoxAlign: 'center',
  alignItems: 'center',
  webkitBoxPack: 'center',
  justifyContent: 'center',
  backgroundColor: 'rgb(255, 255, 255)',
  fontSize: '13px',
  fontWeight: '700',
  color: '#454b68',
  overflow: 'hidden',
  borderRadius: '6px',
  marginTop: '5px',
  padding: '4px',
}));

export const Card = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
  marginBottom: '10px',
  padding: '30px',
  '@media all and (max-width: 800px)': {
    padding: '5px',
  },
}));

export const ColShowOrHide = styled(Col, () => ({
  '@media only screen and (max-width: 1700px)': {
    display: 'none',
  },
}));

type LabelWithContentProps = {
  children: React.ReactNode;
  style?: any;
  title?: any;
};
const TitleC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  '@media all and (min-width: 769px)': {
    display: 'none !important',
  },
}));
const TContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  textAlign: 'right',
  margin: 0,
  padding: '1px',
  flex: '1',
  '@media all and (min-width: 769px)': {
    ...$theme.typography.font18,
    color: $theme.colors.textDark,
    margin: '0 !important',
    padding: '1px !important',
    textAlign: 'center !important',
    flex: '1',
  },
}));
export const TitleContent: React.FC<LabelWithContentProps> = ({
  children,
  title,
  style,
}) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
      }}
    >
      <TitleC>{title}</TitleC>
      <TContent>{children}</TContent>
    </div>
  );
};
const TitleCC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  display: 'inline-block',
  margin: 0,
  width: '100%',
  padding: '1px',
  textAlign: 'center',
  '@media all and (min-width: 769px)': {
    display: 'none !important',
  },
}));

const TTContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  display: 'inline-block',
  width: '100%',
  margin: 0,
  padding: '1px',
  textAlign: 'center',
  '@media all and (min-width: 769px)': {
    ...$theme.typography.font18,
    color: $theme.colors.textDark,
    margin: '0 !important',
    padding: '1px !important',
    textAlign: 'left !important',
    flex: '1',
  },
}));
export const TitleCOntent: React.FC<LabelWithContentProps> = ({
  children,
  title,
  style,
}) => {
  return (
    <div style={{ width: '100%' }}>
      <TitleCC>{title}</TitleCC>
      <TTContent>{children}</TTContent>
    </div>
  );
};
const LabelC = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
}));

export const LContent = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  textAlign: 'right',
  margin: 0,
  padding: '1px',
  flex: '1',
}));
// export const LabelContent: React.FC<LabelWithContentProps> = ({
//   children,
//   title,
//   style,
// }) => {
//   return (
//     <div
//       style={{
//         width: '100%',
//         display: 'flex',
//         alignItems: 'center',
//         flexWrap: 'wrap',
//         justifyContent: 'center',
//         marginBottom: '6px',
//         marginTop: '6px',
//       }}
//     >
//       <LabelC>{title}</LabelC>
//       <LContent>{children}</LContent>
//     </div>
//   );
// };
export const LabelContent: React.FC<LabelWithContentProps> = ({ children, title }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'flex-start',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '6px',
        marginTop: '6px',
      }}
    >
      <LabelC style={{ flex: '0 0 130px', paddingTop: '5px' }}>{title}</LabelC>
      <LContent style={{ flex: 1 }}>{children}</LContent>
    </div>
  );
};
