import React, { FunctionComponent, useCallback, useContext, useState } from 'react';
import { handleDate } from '../../Functions/Utilities';
import InputForm from './InputForm';
import { Item, ItemsContext, UseFormContext } from './ProductSalesOfferAdd';

interface AddProductSalesItemFormProps {
  item?: Item;
  itemIndex?: number;
  setItemIndex?: Function;
}
const AddProductSalesItemForm: FunctionComponent<AddProductSalesItemFormProps> = ({
  item,
  itemIndex = null,
  setItemIndex,
}) => {
  const { register, setValue, control, getValues, watch, errors } = useContext(
    UseFormContext
  );
  const { dispatchItems } = useContext(ItemsContext);
  const [productRevision, setProductRevision] = useState('');
  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('productId', undefined);
      setValue('estimatedDeliveryDate', [new Date()]);
      setValue('qty', '');
      setValue('unitsId', undefined);
    }
  }, [setItemIndex,item,setValue]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { qty, productId, estimatedDeliveryDate, unitPrice, unitsId } = getValues();
    if (qty !== '' && productId) {
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            id: undefined,
            productId: productId,
            unitsId: unitsId,
            productRevisionId: productRevision,
            estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
            qty: qty,
            unitPrice: unitPrice || 0,
          },
        });
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            id: item?.id,
            productId: productId,
            unitsId: unitsId,
            productRevisionId: productRevision,
            estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
            qty: qty,
            unitPrice: unitPrice || 0,
          },
        });
      }
      handleResetForm();
    }
  }, [handleResetForm,dispatchItems,itemIndex,getValues,item,productRevision]);

  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      setProductRevision={setProductRevision}
      item={item}
      handleResetForm={handleResetForm}
    />
  );
};
export default AddProductSalesItemForm;
