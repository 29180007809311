import React, { FunctionComponent, useCallback, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../../components/Input/Input';
import { useToaster } from '../../../../context/toaster-provider';
import SelectBillOfMaterialInventories from '../../../SelectCustom/SelectBillOfMaterialInventories';
import { ComponentStatusContext } from '../../ExecutionPlanning/ExecutionPlanning';
import { InputType } from '../Step/Step';

interface ItemFormProps {
  item?: InputType;
  itemIndex?: number;
  setItemAddShow?: Function;
  setItemIndex?: Function;
  dispatchItems: Function;
}
const ItemFormInput: FunctionComponent<ItemFormProps> = ({
  item,
  setItemIndex,
  itemIndex = null,
  setItemAddShow,
  dispatchItems,
}) => {
  const { billOfMaterialId } = useContext(ComponentStatusContext);
  const { control, errors, setValue, register, getValues } = useForm({
    defaultValues: item
      ? {
          name: item.name,
          inventoryId: item.inventoryId,
        }
      : {},
  });
  const { showToaster } = useToaster();
  const { select, inventoryTypeId } = SelectBillOfMaterialInventories({
    billOfMaterialId: billOfMaterialId,
    name: 'inventoryId',
    control,
    errors,
    required: true,
    creatable: false,
    disabled: false,
  });
  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('name', '');
      setValue('inventoryId', undefined);
      setItemAddShow(false);
    }
  }, [item,setItemAddShow,setValue,setItemIndex]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { name, inventoryId } = getValues();
    if (true) {
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            name,
            inventoryId,
            inventoryTypeId,
          },
        });
        showToaster('created', 'positive');
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            name,
            inventoryId,
            inventoryTypeId,
          },
          index: itemIndex,
        });
        showToaster('updated', 'positive');
      }
      handleResetForm();
    }
  }, [
    dispatchItems,
    getValues,
    handleResetForm,
    itemIndex,
    inventoryTypeId,
    showToaster,
  ]);

  return (
    <RowCustom bottom="xs">
      <Col xs={12} md={6} lg={4}>
        <FormControl label={<FormattedMessage id="executionPlanningStep.inputName" />}>
          <Input
            type="text"
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            clearOnEscape
          />
        </FormControl>
      </Col>
      <Col xs={12} md={6} lg={4}>
        {select}
      </Col>
      <ColButton xs>
        <Button
          style={{
            marginBottom: '16px',
          }}
          type="button"
          onClick={handleResetForm}
        >
          <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
        </Button>
        <Button
          style={{
            marginBottom: '16px',
          }}
          onClick={handleAddOrUpdateItem}
          type="button"
        >
          <FormattedMessage id={item ? 'button.update' : 'button.add'} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};

export default ItemFormInput;
