import React, { useCallback, useEffect, FunctionComponent } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { useMutation } from '@apollo/react-hooks';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { handleAddTimegroup, handleUpdateTimegroup } from './GraphqlFuntion';

const ADD_TIME_GROUP = loader('../../graphql/shift/ADD_TIME_GROUP.graphql');
const UPDATE_TIME_GROUP = loader('../../graphql/shift/UPDATE_TIME_GROUP.graphql');

const TimeGroupForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name }) => {
    dataDispatch
      ? updateTimeGroup({
          variables: {
            id: dataDispatch.id,
            name: name,
          },
        })
      : addTimeGroup({
          variables: { name: name },
        });
  };
  const [addTimeGroup] = useMutation(ADD_TIME_GROUP, {
    update(cache, { data: { createTimeGroup } }) {
      handleAddTimegroup(cache,createTimeGroup)
      closeDrawer();
    },
  });
  const [updateTimeGroup] = useMutation(UPDATE_TIME_GROUP, {
    update(cache,{data:{updateTimeGroup}}) {
      handleUpdateTimegroup(cache,updateTimeGroup)
      closeDrawer();
    },
  });
  return (
    <Card
      title={
        <FormattedMessage id={dataDispatch ? 'time_group.update' : 'time_group.create'} />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="time_group.name" />}
          error={errors.name ? 'Please input a valid Name' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default TimeGroupForm;
