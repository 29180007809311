import { useQuery } from '@apollo/react-hooks';
import { faEye } from '@fortawesome/pro-duotone-svg-icons';
import { faEyeSlash, faPrint, faWindowClose } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useCallback, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Col, RowCustom } from '../../../../components/FlexBox/FlexBox';
import NoResult from '../../../../components/NoResult/NoResult';
import { useDrawerDispatch, useDrawerState } from '../../../../context/DrawerContext';
import { PurchaseOfferRecordForCompany } from '../../../../types/PurchaseOfferRecordForCompany';
import CompanyInformation from '../../../Functions/CompanyInformation';
import CardBody from '../../../TailWinduiHTML/Card/CardBody';
import CardTitle from '../../../TailWinduiHTML/Card/CardTitle';
import Button from '../../../TailWinduiHTML/Form/Button';
import HeadersPurchaseMaterialOffer from './HeadersPurchaseMaterialOffer';
import HeadersPurchaseOperationOffer from './HeadresPurchaseOperationOffer';
import ListPurchaseOfferClass from './Print/ListPurchaseOfferClass';
import PurchaseMaterialOfferPreview from './PurchaseMaterialOfferPreview';
import PurchaseOperationOfferPreview from './PurchaseOperationOfferPreview';
const GET_PURCHASE_OFFER_RECORD_FOR_COMPANY = loader(
  '../../../../graphql/purchaseOfferRecord/GET_PURCHASE_OFFER_RECORD_FOR_COMPANY.graphql'
);
type PurchaseOfferRecordForCompanyType = {
  purchaseOfferRecordForCompany: PurchaseOfferRecordForCompany[];
};
const PurchaseOfferPreview: FunctionComponent = () => {
  const dispatch = useDrawerDispatch();
  const { companyId, purchaseOfferId } = useDrawerState('data');
  const [printPreview, setPrintPreview] = useState(false);
  const [print, setPrint] = useState(false);
  const { data, loading, error } = useQuery<PurchaseOfferRecordForCompanyType>(
    GET_PURCHASE_OFFER_RECORD_FOR_COMPANY,
    {
      variables: {
        companyId: companyId,
        purchaseOfferId: purchaseOfferId,
      },
      fetchPolicy: 'network-only',
    }
  );
  const closeDrawer = useCallback(() => {
    dispatch({
      type: 'CLOSE_DRAWER',
    });
  }, [dispatch]);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return loading ? (
    <StyledSpinnerNext />
  ) : error ? (
    <NoResult />
  ) : (
    data && (
      <div>
        <CardTitle title={'purchaseOffer.preview'}>
          <Button onClick={() => setPrintPreview((prev) => !prev)}>
            <FontAwesomeIcon icon={printPreview ? faEyeSlash : faEye} />
          </Button>
          <Button disabled={!printPreview} onClick={() => handlePrint()}>
            <FontAwesomeIcon icon={faPrint} />
          </Button>
          <Button onClick={closeDrawer}>
            <FontAwesomeIcon icon={faWindowClose} />
          </Button>
        </CardTitle>

        {!printPreview ? (
          <CardBody>
            <RowCustom>
              <Col xs={12} sm={9} md={7} lg={6}>
                <CompanyInformation
                  companyId={data.purchaseOfferRecordForCompany[0]?.supplier?.id}
                  companyName={
                    data.purchaseOfferRecordForCompany[0]?.supplier?.companyName
                  }
                />
              </Col>
            </RowCustom>
            <div style={{ marginBottom: '50px' }} />
            <HeadersPurchaseMaterialOffer itemLength={1} />
            {data.purchaseOfferRecordForCompany?.map((item) => {
              return item.purchaseMaterialOffer?.map((material) => (
                <PurchaseMaterialOfferPreview item={material} />
              ));
            })}
            <HeadersPurchaseOperationOffer itemLength={1} />
            {data.purchaseOfferRecordForCompany?.map((item) => {
              return item.purchaseOperationOffer?.map((operation) => (
                <PurchaseOperationOfferPreview item={operation} />
              ));
            })}
          </CardBody>
        ) : (
          <ListPurchaseOfferClass
            company={data.purchaseOfferRecordForCompany[0]?.supplier}
            itemMaterials={data.purchaseOfferRecordForCompany
              .map((item) => item.purchaseMaterialOffer)
              .flat(1)}
            itemOperations={data.purchaseOfferRecordForCompany
              .map((item) => item.purchaseOperationOffer)
              .flat(1)}
            print={print}
            setPrint={setPrint}
            componentRef={componentRef}
          />
        )}
      </div>
    )
  );
};

export default PurchaseOfferPreview;
