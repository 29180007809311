import React, { Fragment } from 'react';
import { FunctionComponent } from 'react';
import { Col, RowCustom } from '../../components/FlexBox/FlexBox';
import { MaterialGeometry } from '../../types/MaterialGeometries';
import SelectionMaterialGeometryType from '../Offers/InReview/SubcontractSales/SelectionMaterialGeometryType';
import MaterialGeometries from '../Product/MaterialGeomatries';
import SelectRawMaterial from '../SelectCustom/SelectRawMaterial';
interface RawMaterialInventoryFormProps {
  errors: any;
  control: any;
  setValue: any;
  register: any;
  setMaterialGeometry: Function;
  materialGeometry: MaterialGeometry;
}
const RawMaterialInventoryForm: FunctionComponent<RawMaterialInventoryFormProps> = ({
  errors,
  control,
  setValue,
  register,
  setMaterialGeometry,
  materialGeometry,
}) => {
  return (
    <Fragment>
      <MaterialGeometries
        setMaterialGeometry={setMaterialGeometry}
        materialGeometry={materialGeometry}
      />
      <RowCustom>
        <Col lg={3}>
          <SelectRawMaterial errors={errors} control={control} />
        </Col>
        {materialGeometry && (
          <SelectionMaterialGeometryType
            materialGeometry={materialGeometry}
            setValue={setValue}
            errors={errors}
            register={register}
          />
        )}
      </RowCustom>
    </Fragment>
  );
};
export default RawMaterialInventoryForm;
