import React, { FunctionComponent } from 'react';
import { Controller, useForm } from 'react-hook-form';
import SelectDummy from 'react-select';
interface CreatableSelectProps {
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRtl?: boolean;
  isSearchable?: boolean;
  controller?: any;
  control?: ReturnType<typeof useForm>['control'];
  name: string;
  required:boolean;
  options: { value: string; label: string }[];
  handleChange?: (e: any) => void;
  handleInputChange?: (inputValue: any, actionMeta: any) => void;
  className?: string;
}
const Select: FunctionComponent<CreatableSelectProps> = ({
  isClearable = true,
  isDisabled = false,
  isLoading = false,
  isRtl = false,
  isSearchable = false,
  control,
  name,
  options = [],
  required=false,
  handleInputChange,
  handleChange,
  className = '',
}) => {
  return (
    <div className={`mt-1 sm:mt-0 sm:col-span-2` + className}>
      <div className="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
        <Controller
          as={<SelectDummy />}
          control={control}
          name={name}
          rules={{required:required}}
          options={options}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          onChange={handleChange}
          onInputChange={handleInputChange}
          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        />
      </div>
    </div>
  );
};
export default Select;
