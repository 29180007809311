import React, { useState, useEffect, useCallback } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useDrawerDispatch, useDrawerState } from '../../context/DrawerContext';
import FormControl from '../../components/FormWithElemets/FormControl';
import { FormattedMessage } from 'react-intl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { debounce, handleCompanyInformation } from './Utilities';
import { useToaster } from '../../context/toaster-provider';
import { Label } from '../../components/FormWithElemets/Label.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { SearchCompany } from '../../types/Company';

const SEARCH_COMPANY = loader('../../graphql/company/SEARCH_COMPANY.graphql');
interface ListProps {
  control: any;
  errors: any;
  setValue: any;
  dispatchItems: any;
  items: any;
  update?: boolean | undefined;
  creatable?: boolean | undefined;
  defaultValue?: [{ id: string, label: string }]
}
export const useCompanyAdd = ({
  dispatchItems,
  items,
  errors,
  control,
  setValue,
  defaultValue,
  update = false,
  creatable = false,
}: ListProps) => {
  const [loadOptionsEs, { data, loading }] = useLazyQuery<SearchCompany>(SEARCH_COMPANY);
  useEffect(() => {
    loadOptionsEs({
      variables: {
        full: false,
        name: null,
      },
    });
  }, [loadOptionsEs]);
  const handleInputChange = debounce(function (term: string) {
    loadOptionsEs({
      variables: {
        full: false,
        name: term ? term : null,
      },
    });
  });
  const [companyInformation, setCompanyInformation] = useState(null);
  const { showToaster } = useToaster();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const dispatch = useDrawerDispatch();
  const openDrawer = useCallback(
    (data) =>
      dispatch({
        type: 'OPEN_DRAWER',
        drawerComponent: 'COMPANY_FORM',
        data,
        widthComponent: '900px',
        transactionsReason: 'anotherComponent',
      }),
    [dispatch]
  );
  const handleChangeCompanyName = ({ value, option }) => {
    if (companyInformation?.companyId !== value[0]?.id) {
      if (companyInformation?.companyId) {
        dispatchItems({ type: 'RESET' });
      }
      if (option?.isCreatable) {
        setCompanyInformation(null);
        openDrawer({
          name: option?.label,
        });
      } else {
        data &&
          setCompanyInformation(
            handleCompanyInformation(value[0]?.id, data.searchCompany)
          );
      }
    }
  };
  useEffect(() => {
    console.log("---------->", defaultValue)
    if (defaultValue! != undefined && defaultValue?.length > 0 && data) {
      console.log("---------->", defaultValue)
      setCompanyInformation(handleCompanyInformation(defaultValue[0]?.id, data.searchCompany))
    }
  }, [defaultValue, data])
  const handleChangeCompanyNameControl = () => {
    items?.length > 0 &&
      companyInformation &&
      showToaster('companyChange_itemsDelete', 'info');
  };
  useEffect(() => {
    if (transactionsReasonDispatch === 'companyForm') {
      setValue('companyName', dataDispatch?.companyName || undefined);
    }
  }, [transactionsReasonDispatch, setValue, dataDispatch]);
  useEffect(() => {
    if (dataDispatch?.companyName) {
      setCompanyInformation(
        handleCompanyInformation(dataDispatch.companyName[0]?.id, data.searchCompany)
      );
      dispatch({
        type: 'DESTROY_DRAWER',
      });
    }
  }, [dispatch, dataDispatch, data]);
  return {
    selectCompany: (
      <>
        <FormControl
          label={<FormattedMessage id="company.companyName" />}
          error={errors.companyName ? 'Please select a valid Company  Name' : null}
        >
          <Select
            data={data && data.searchCompany}
            labelValue="companyName"
            controller={{
              disabled: update,
              name: 'companyName',
              control,
              isLoading: loading,
              creatable: creatable,
              error: errors.companyName,
              overrides: errors.companyName ? { After: Negative } : { After: Positive },
              onChange: ([selected]) => {
                handleChangeCompanyName(selected);
                return !selected.option?.isCreatable ? selected.value : undefined;
              },
              onInputChange: (event: React.SyntheticEvent) => {
                const target = event.target as HTMLInputElement;
                handleInputChange(target.value);
              },
              onOpen: () => {
                handleChangeCompanyNameControl();
              },
            }}
          />
        </FormControl>
        <Label style={{ justifyContent: 'flex-end' }}>
          {companyInformation?.address ||
            '.........................................................'}
        </Label>
        <Label style={{ justifyContent: 'flex-end' }}>
          {companyInformation?.county || '.......'}
          {' / '}
          {companyInformation?.city || '.......'}
        </Label>
        <Label style={{ justifyContent: 'flex-end' }}>
          {companyInformation?.taxOffice || '.......'}
          {' - '}
          {companyInformation?.taxNumber || '.......'}
        </Label>
      </>
    ),
    currency: companyInformation?.currency || '₺',
  };
};
