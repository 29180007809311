import React, { Fragment, useState } from 'react';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent } from 'react';
import ToggleWithIcon from '../../../../../TailWinduiHTML/Form/ToggleWithIcon';
import { MaterialIds } from '../AddCol/AddMaterialCol';
import SimpleModal from '../../../../../TailWinduiHTML/SimpleModal';

interface ColTitleProps {
  colName: string;
  index: number;
  setColMaterials: Function;
}
const ColTitle: FunctionComponent<ColTitleProps> = ({
  colName,
  index,
  setColMaterials,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const handleOnClick = (value: boolean) => {
    value &&
      setColMaterials((prev: MaterialIds) =>
        prev.filter((item: any, idx: number) => idx !== index)
      );
    setModalShow(false);
  };
  return (
    <Fragment>
      <div className="sm:col-span-6 md:col-span-3 lg:col-span-1 print:col-span-1">
        <div className="flex items-center space-x-3">
          <h3 className="truncate">{colName}</h3>
          <span className="flex-shrink-0 inline-block pl-0.5 py-0.5 print:hidden text-sm   font-medium  rounded-full">
            <ToggleWithIcon
              onClick={() => {
                setColMaterials((prev: MaterialIds) =>
                  prev.map((item, idx: number) =>
                    idx === index ? { ...item, detail: !item?.detail } : { ...item }
                  )
                );
              }}
            />
          </span>
          <span className="flex-shrink-0 inline-block pl-0.5 py-0.5  text-sm  print:hidden font-medium  rounded-full">
            <FontAwesomeIcon
              icon={faTimes}
              style={{ cursor: 'pointer' }}
              onClick={() => setModalShow(true)}
            />
          </span>
        </div>
      </div>
        <SimpleModal
          hidden={!modalShow}
          handleOnClick={(value: boolean) => {
            handleOnClick(value);
          }}
        >
          {'Are you sure ?'}
        </SimpleModal>
    </Fragment>
  );
};

export default ColTitle;
