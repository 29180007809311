import React, { FunctionComponent, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
//import { useIntl } from 'react-intl';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import { FileUploader } from 'baseui/file-uploader';



interface TechnicalImagesProps {
    id:any;
    mutation:any;
}

const TechnicalImages: FunctionComponent<TechnicalImagesProps> = ({ id,mutation }) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [uploadFileMutation] = useMutation(mutation);
    const handleUpload = async (uploadFile: any) => {
        const options = {
            variables: {
                ...id,
                image: uploadFile[0],
            },
        };
        try {
            const response = await uploadFileMutation(options);
            if (!(response && response.data)) {
                return;
            }
        } catch (err) {
            setErrorMessage(err);
        }
    };
    return (
        <CardBody style={{ padding: '10px' }}>
            <FileUploader
                errorMessage={errorMessage}
                onDrop={(acceptedFiles) => {
                    handleUpload(acceptedFiles).then(() => console.log('upload bitti'));
                }}
            />
        </CardBody>
    );
};
export default TechnicalImages;

