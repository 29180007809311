/* eslint-disable */

import { useCreateContext } from './create-context';
/* eslint-disable */

const initialState = {
  isOpen: false,
  drawerComponent: null,
  data: null,
  widthComponent: '70vw',
  transactionsReason: '',
};
type State = typeof initialState;
type Action = any;
function reducer(state: State, action: Action) {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        isOpen: true,
        drawerComponent: action.drawerComponent,
        data: action.data,
        widthComponent: action.widthComponent ? action.widthComponent : '70vw',
        transactionsReason: action.transactionsReason,
      };
    case 'CLOSE_DRAWER':
      return {
        ...state,
        isOpen: false,
        drawerComponent: action.drawerComponent,
        data: action.data,
        widthComponent: '',
        transactionsReason: action.transactionsReason,
      };
      case 'DESTROY_DRAWER':
        return {
          ...state,
        isOpen: false,
        drawerComponent: null,
        data: null,
        widthComponent: null,
        transactionsReason: null,
        }
    default:
      return state;
  }
}
const [useDrawerState, useDrawerDispatch, DrawerProvider] = useCreateContext(
  initialState,
  reducer
);

export { useDrawerState, useDrawerDispatch, DrawerProvider };
