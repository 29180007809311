import {
  Diameter,
  OuterDiameter,
  Mass,
  ProcessingLength,
  UnmachinablePartLength,
  PieceLength,
  CuttingToolDiam,
  Qty,
  MaterialWeight,
  UnitPrice,
  MaterialLength,
  Height,
  Width,
} from '../../types/Calc';

export function cm3ToMm3(val: number): number {
  return val / 1000;
}
export function mm2ToCm2(val: number): number {
  return val / 1000;
}
export function intersectionAreaHexagonal(diameter: Diameter): number {
  return (Math.pow(diameter, 2) * Math.sqrt(3)) / 2;
}
export function getUnitWeightHexagonal(
  mass: Mass,
  diameter: Diameter,
  innerShape: string,
  innerDiameterOrWeight: Diameter,
  innerHeight: Height,
  materialLength: MaterialLength
): number {
  return cm3ToMm3(
    mass *
      (intersectionAreaHexagonal(diameter) -
        handleInnerCal(innerShape, innerDiameterOrWeight, innerHeight)) *
      materialLength
  );
}
export function intersectionAreaRound(diameter: Diameter): number {
  return Math.PI * Math.pow(diameter / 2, 2);
}
export function getUnitWeighRound(
  mass: Mass,
  diameter: Diameter,
  innerShape: string,
  innerDiameterOrWeight: Diameter,
  innerHeight: Height,
  materialLength: MaterialLength
): number {
  return cm3ToMm3(
    mass *
      (intersectionAreaRound(diameter) -
        handleInnerCal(innerShape, innerDiameterOrWeight, innerHeight)) *
      materialLength
  );
}
export function intersectionAreaSquare(witdh: Width): number {
  return Math.pow(witdh, 2);
}
export function getUnitWeighSquare(
  mass: Mass,
  width: Width,
  innerShape: string,
  innerDiameterOrWeight: Diameter,
  innerHeight: Height,
  materialLength: MaterialLength
): number {
  return cm3ToMm3(
    mass *
      (intersectionAreaSquare(width) -
        handleInnerCal(innerShape, innerDiameterOrWeight, innerHeight)) *
      materialLength
  );
}
export function intersectionAreaRectangle(witdh: Width, height: Height): number {
  return witdh * height;
}
export function getUnitWeighRectangle(
  mass: Mass,
  width: Width,
  height:Height,
  innerShape: string,
  innerDiameterOrWeight: Diameter,
  innerHeight: Height,
  materialLength: MaterialLength
): number {
  return cm3ToMm3(
    mass *
      (intersectionAreaRectangle(width,height) -
        handleInnerCal(innerShape, innerDiameterOrWeight, innerHeight)) *
      materialLength
  );
}
export function weightPerCm(mass: Mass, diameter: Diameter): number {
  return mass * diameter;
}
export function getUnitWeighSheet(
  mass: Mass,
  thickness: Width,
  height:Height,
  materialLength: MaterialLength
): number {
  return cm3ToMm3(
    mass *
      (intersectionAreaSheet(thickness,height)) *
      materialLength
  );
}
export function intersectionAreaSheet(thickness: Width, height: Height): number {
  return thickness * height;
}
export function getPerUnit(
  processingLength: ProcessingLength,
  unmachinablePartLength: UnmachinablePartLength,
  pieceLength: PieceLength,
  cuttingToolDiam: CuttingToolDiam
): number {
  return Math.floor(
    (processingLength - unmachinablePartLength) / (pieceLength + cuttingToolDiam)
  );
}

export function getMaterialLength(
  qty: Qty,
  perUnit: number,
  processingLength: ProcessingLength
): MaterialLength {
  return Math.ceil(qty / perUnit) * processingLength;
}

export function getMaterialWeight(diameter: Diameter | OuterDiameter, qty: Qty): number {
  return (diameter / 1000) * qty;
}
export function getMaterialPrice(
  materialWeight: MaterialWeight,
  unitPrice: UnitPrice
): number {
  return materialWeight * unitPrice;
}
export function handleInnerCal(
  innerShape: string,
  innerDiameterOrWeight: Diameter,
  innerHeight: Height,
): number {
  return innerShape === 'inner_round'
    ? intersectionAreaRound(innerDiameterOrWeight)
    : innerShape === 'inner_square'
    ? intersectionAreaSquare(innerDiameterOrWeight)
    : innerShape === 'inner_rectangle'
    ? intersectionAreaRectangle(innerDiameterOrWeight, innerHeight)
    : innerShape === 'inner_hexagonal'
    ? intersectionAreaHexagonal(innerDiameterOrWeight)
    : (innerShape === 'inner_full' && 0) || 0;
}
