import React, { FunctionComponent, useState } from 'react';
import { Col, Row } from '../../../components/FlexBox/FlexBox';
import { useDrawerDispatch, useDrawerState } from '../../../context/DrawerContext';
import { AddImproperProductRecordForm } from '../../ProductRecord/ImproperProductRecordForm';
import { AddWipProductRecordForm } from '../../ProductRecord/WipProductRecordForm';
import CompleteForm from './CompleteForm';
import ProductRecordShopFloorOrderHistory from './ProductRecordShopFloorOrderHistory';

const ShopFloorOrderComleted: FunctionComponent = () => {
  const [productRecordEmpty, setProductRecordEmpty] = useState(true);
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  return (
    <>
      <Row
        style={{
          marginTop: 0,
          marginBottom: '100px',
          marginLeft: 0,
          marginRight: 0,
          padding: 0,
        }}
      >
        <Col md={12}>
          <ProductRecordShopFloorOrderHistory
            shopFloorOrderId={dataDispatch.shopFloorOrderId}
            setProductRecordEmpty={setProductRecordEmpty}
          />
        </Col>
        <Col md={6}>
          <AddWipProductRecordForm
            dispatch={dispatch}
            dataDispatch={dataDispatch}
            transactionsReasonDispatch={transactionsReasonDispatch}
          />
        </Col>
        <Col md={6}>
          <AddImproperProductRecordForm
            dispatch={dispatch}
            dataDispatch={dataDispatch}
            transactionsReasonDispatch={transactionsReasonDispatch}
          />
        </Col>
      </Row>
      <CompleteForm
        partialComplete={dataDispatch.shopFloorOrderId}
        shopFloorOrderId={dataDispatch.shopFloorOrderId}
        shopFloorOrderStatusId={dataDispatch.shopFloorOrderStatusId}
        productRecordEmpty={productRecordEmpty}
      />
    </>
  );
};
export default ShopFloorOrderComleted;
