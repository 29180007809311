import { Buttons } from './DataTable';
import DropdownButton, { KIND, SIZE } from '../FormWithElemets/Button/DropdownButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisHAlt } from '@fortawesome/pro-regular-svg-icons';
import Button from '../FormWithElemets/Button/Button';
import React from 'react';
import _ from 'lodash';
import { Col, RowCustom, RowTitle } from '../FlexBox/FlexBox';
import { FormattedMessage } from 'react-intl';
import { arrayMove, arrayRemove, List } from 'baseui/dnd-list';
import { LabelCustom } from '../FormWithElemets/Label.style';
export type columnType = 'date' | 'string' | 'text' | 'buttons' | 'boolean';
export const getText = (type: columnType, data: string) => {
  if (type === 'date') {
    return new Date(data)?.toLocaleDateString('tr-TR');
  } else if (type === 'boolean') {
    return data?.toString() || 'null';
  } else {
    return data;
  }
};
export const getButtons = (threeDot, item, row) => {
  if (threeDot) {
    const buttons = row.map((button: Buttons) => {
      return Object.assign(button, { id: item.id });
    });
    return (
      <DropdownButton
        items={buttons}
        kind={KIND.secondary}
        size={SIZE.mini}
        dataId={item.id}
      >
        <FontAwesomeIcon icon={faEllipsisHAlt} size="1x" />
      </DropdownButton>
    );
  } else {
    return row.map((button: Buttons, key) => {
      return (
        <Button
          type="button"
          key={key}
          onClick={() => {
            button.function(item?.id);
            if (button.close) {
              button.close();
            }
          }}
        >
          {button.label}
        </Button>
      );
    });
  }
};
export const childComponent = (
  childItem,
  newChildColumnContents,
  childForm,
  setChildItemId,
  threeDot
) => {
  return (
    <RowCustom
      middle="xs"
      key={childItem?.id}
      style={{ marginTop: 0, marginBottom: '4px', marginLeft: 0, marginRight: 0 }}
    >
      {newChildColumnContents.map((content, index) => (
        <Col
          key={index}
          {...content.col}
          style={{ cursor: 'pointer' }}
          onClick={() =>
            content.type !== 'buttons' && childForm && setChildItemId(childItem.id)
          }
        >
          <LabelCustom
            style={content.type === 'buttons' && { justifyContent: 'flex-end' }}
            title={<FormattedMessage id={content.title} />}
          >
            {content.type === 'buttons'
              ? getButtons(threeDot, childItem, content.name)
              : content.type === 'text' || content.type === 'date'
              ? getText(content.type, _.get(childItem, content.name))
              : getText(content.type, _.get(childItem, content.name)) === 'true'
              ? '✓'
              : 'X'}
          </LabelCustom>
        </Col>
      ))}
    </RowCustom>
  );
};
export const childHeadWithComponent = (
  threeDot,
  parentItem,
  childColumnHeaders,
  childItemId,
  childItems,
  childForm,
  setChildItemId,
  setChildItems,
  newChildColumnContents,
  childrenUpdateOrder
) => {
  return (
    <div key={childItemId}>
      <RowTitle
        middle="xs"
        key={parentItem?.id}
        style={{
          fontWeight: 'bold',
          padding: '5px',
          margin: '0',
        }}
      >
        {childColumnHeaders.map((item, index) => (
          <Col {...item.col} key={index}>
            <FormattedMessage id={item.name} />
          </Col>
        ))}
      </RowTitle>
      {!childrenUpdateOrder || childItemId ? (
        childItems.map((childItem) =>
          childItem?.id !== childItemId ? (
            <div
              key={childItem?.id}
              style={{
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
              }}
            >
              {childComponent(
                childItem,
                newChildColumnContents,
                childForm,
                setChildItemId,
                threeDot
              )}
            </div>
          ) : (
            childForm &&
            childForm({
              childItem,
              parentId: parentItem?.id,
              setChildItemId,
            })
          )
        )
      ) : (
        <List
          key={childItems[0]?.id}
          items={childItems.map((childItem) =>
            childComponent(
              childItem,
              newChildColumnContents,
              childForm,
              setChildItemId,
              threeDot
            )
          )}
          overrides={{
            List: {
              style: () => {
                return {
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  paddingRight: 0,
                  margin: 0,
                };
              },
            },
            Item: {
              style: () => {
                return {
                  margin: 0,
                  paddingTop: '3px !important',
                  paddingBottom: '3px !important',
                  paddingLeft: '0 !important',
                  paddingRight: '0 !important',
                };
              },
            },
            DragHandle: {
              style: () => {
                return {
                  display: 'none',
                };
              },
            },
          }}
          onChange={({ oldIndex, newIndex }) => {
            setChildItems((items) =>
              newIndex === -1
                ? arrayRemove(items, oldIndex)
                : arrayMove(items, oldIndex, newIndex)
            );
            childrenUpdateOrder(
              newIndex === -1
                ? arrayRemove(childItems, oldIndex)
                : arrayMove(childItems, oldIndex, newIndex),
              parentItem?.id
            );
          }}
        />
      )}
      {childForm && childItemId === null && childForm({ parentId: parentItem?.id })}
    </div>
  );
};
