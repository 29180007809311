import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useEffect,
} from 'react';
import { BillOfMaterialType } from '../../../../../types/BillOfMaterial';
import { PurchaseMaterialOffer } from '../../../../../types/PurchaseMaterialOffer';
import { PurchaseOperationOffer } from '../../../../../types/PurchaseOperationOffer';
import { SubcontractSalesOfferMaterialPrice } from '../../../../../types/SubcontractSalesOfferMaterialPrice';
import { SubcontractSalesOfferOperationPrice } from '../../../../../types/SubcontractSalesOfferOperationPrice';
import { SubcontractSalesOfferPrice } from '../../../../../types/SubcontractSalesOfferPrice';
import { handleCeil } from '../../../../Functions/Utilities';
import ItemDetailMaterial from './ItemDetailMaterial';
import ItemDetailOffer from './ItemDetailOffer';
import ItemDetailOperation from './ItemDetailOperation';
const UPDATE_SUBCONTRACT_SALES_OFFER_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferPrice/UPDATE_SUBCONTRACT_SALES_OFFER_PRICE.graphql'
);
export const ContextSubcontractSalesOfferUnitPrice = createContext(null);
interface ItemDetailProps {
  billOfMaterial: BillOfMaterialType;
  subcontractSalesOfferPrice: SubcontractSalesOfferPrice;
  transportUnitPrice: number;
  purchaseOperationOffer: PurchaseOperationOffer[];
  purchaseMaterialOffer: PurchaseMaterialOffer[];
  orderQty: number;
}
const ItemDetail: FunctionComponent<ItemDetailProps> = ({
  billOfMaterial,
  subcontractSalesOfferPrice,
  transportUnitPrice,
  purchaseMaterialOffer,
  purchaseOperationOffer,
  orderQty = 0,
}) => {
  const [updateSubcontractSalesOfferUnitPrice] = useMutation(
    UPDATE_SUBCONTRACT_SALES_OFFER_PRICE
  );
  useEffect(() => {
    if (subcontractSalesOfferPrice) {
      const {
        subcontractSalesOfferMaterialPrice,
        subcontractSalesOfferOperationPrice,
        id,
        qty,
        unitPrice,
        profitRate,
        discountRate
      } = subcontractSalesOfferPrice;
      const _unitPrice = materialOperationPriceCalculate(
        subcontractSalesOfferMaterialPrice,
        subcontractSalesOfferOperationPrice,
        profitRate,
        discountRate
      );
      if (
        unitPrice !==
        Math.ceil(100 * (_unitPrice + transportUnitPrice)) / 100
      ) {
        updateSubcontractSalesOfferUnitPrice({
          variables: {
            id: id,
            unitPrice: Math.ceil(100 * (_unitPrice + transportUnitPrice)) / 100,
            amount:
              Math.ceil(100 * (_unitPrice + transportUnitPrice) * qty) / 100,
          },
        });
      }
    }
  }, [
    subcontractSalesOfferPrice,
    updateSubcontractSalesOfferUnitPrice,
    transportUnitPrice,
  ]);
  return (
    <Fragment>
      <ItemDetailMaterial
        billOfMaterialItem={billOfMaterial.billOfMaterialItem}
        subcontractSalesOfferMaterialPrice={
          subcontractSalesOfferPrice?.subcontractSalesOfferMaterialPrice
        }
        subcontractSalesOfferPriceId={subcontractSalesOfferPrice?.id}
        purchaseMaterialOffer={purchaseMaterialOffer}
        orderQty={orderQty}
      />
      <ItemDetailOperation
        executionPlanStep={billOfMaterial.executionPlan.executionPlanStep}
        subcontractSalesOfferOperationPrice={
          subcontractSalesOfferPrice?.subcontractSalesOfferOperationPrice
        }
        subcontractSalesOfferPriceId={subcontractSalesOfferPrice?.id}
        purchaseOperationOffer={purchaseOperationOffer}
      />
      <ItemDetailOffer
        subcontractSalesOfferPrice={subcontractSalesOfferPrice}
      />
    </Fragment>
  );
};
export default ItemDetail;
export const materialOperationPriceCalculate = (
  subcontractSalesOfferMaterialPrice: SubcontractSalesOfferMaterialPrice[],
  subcontractSalesOfferOperationPrice: SubcontractSalesOfferOperationPrice[],
  _discountRate:number, _profitRate:number
) => {
  const unitPriceMaterial = subcontractSalesOfferMaterialPrice.reduce(
    (acc, item) => {
      const { amount, profitRate } = item;
      const profitAmount = amount + (amount * (profitRate || 0)) / 100;
      const _profitAmount = profitAmount + (profitAmount * _profitRate) / 100;
      const _discountAmount = _profitAmount + (_profitAmount * _discountRate) / 100;
      return acc + handleCeil(_discountAmount);
    },
    0
  );
  const unitPriceOperation = subcontractSalesOfferOperationPrice.reduce(
    (acc, item) => {
      const { amount, profitRate, discountRate } = item;
      const profitAmount = amount + (amount * profitRate) / 100;
      const discountAmount = profitAmount - (profitAmount * discountRate) / 100;
      const _profitAmount = discountAmount + (discountAmount * _profitRate) / 100;
      const _discountAmount = _profitAmount + (_profitAmount * _discountRate) / 100;
      return acc + handleCeil(_discountAmount);
    },
    0
  );
  return handleCeil(+(unitPriceMaterial + unitPriceOperation));
};
