import React, {
  useState,
  FunctionComponent,
  useContext,
  Fragment,
  useCallback,
} from 'react';
import { Item as ItemType, ItemsContext } from './OperationSalesOfferAdd';
import ListItemOperationSalesItem from './ListItemOperationSalesItem';
import AddOperationSalesItemForm from './AddOperationSalesItemForm';
import ItemHeaders from './ItemHeaders';

const ListOperationSalesItem: FunctionComponent = () => {
  const [itemIndex, setItemIndex] = useState(null);
  const { items, dispatchItems } = useContext(ItemsContext);
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
  }, []);
  const handleItemDelete = useCallback(
    (index: number) => {
      if (window.confirm('Are you sure !')) {
        dispatchItems({
          type: 'DELETE',
          index: index,
        });
      }
    },
    [dispatchItems]
  );
  return (
    <Fragment>
      <ItemHeaders itemsLenght={items?.length} />
      {items &&
        items.map((item: ItemType, index: number) => {
          return index !== itemIndex ? (
            <ListItemOperationSalesItem
              key={index}
              item={item}
              index={index}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
            />
          ) : (
            <AddOperationSalesItemForm
              key={index}
              item={item}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
            />
          );
        })}
      {itemIndex === null && (
        <AddOperationSalesItemForm itemIndex={itemIndex} setItemIndex={setItemIndex} />
      )}
    </Fragment>
  );
};
export default ListOperationSalesItem;
