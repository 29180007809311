import React, { FunctionComponent, useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { MaterialItem } from './ListBillOfMaterialItem';
import InputForm from './InputForm';

interface ItemInputsProps {
  item?: MaterialItem;
  itemIndex?: number;
  setItemIndex?: Function;
  dispatchItems: Function;
  setItemAddShow?: Function;
}
const BillOfMaterialItemForm: FunctionComponent<ItemInputsProps> = ({
  item,
  itemIndex,
  setItemIndex,
  dispatchItems,
  setItemAddShow,
}) => {
  const { register, setValue, control, errors, getValues, watch } = useForm({
    defaultValues: item
      ? {
          companyName: item.companyName,
          materialItemInventoryId: item.materialItemInventoryId,
          inventoryTypeId:item.inventoryTypeId,
          partNumber: item.partNumber,
          quantity: item.quantity,
        }
      : {},
  });

  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      //setValue('companyName', undefined);
      setValue('inventoryTypeId', undefined);
      setValue('materialItemInventoryId', undefined);
      setValue('partNumber', '');
      setValue('quantity', 0);
      setItemAddShow(false);
    }
  }, [item, setItemAddShow, setItemIndex, setValue]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { companyName, materialItemInventoryId, partNumber, quantity,inventoryTypeId } = getValues();
    const itemTemp = {
      companyName: companyName,
      materialItemInventoryId: materialItemInventoryId,
      inventoryTypeId:inventoryTypeId,
      partNumber: partNumber,
      quantity: +quantity,
    };
    if (itemIndex === null) {
      dispatchItems({
        type: 'ADD',
        item: {
          materialItemMaterial: [],
          materialItemOpreration: [],
          ...itemTemp,
        },
      });
    } else {
      dispatchItems({
        type: 'UPDATE',
        index: itemIndex,
        item: {
          ...item,
          ...itemTemp,
        },
      });
    }
    handleResetForm();
  }, [item,getValues, dispatchItems, handleResetForm, itemIndex]);
  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      item={item}
      handleResetForm={handleResetForm}
    />
  );
};
export default BillOfMaterialItemForm;
