import React from 'react';
import { IntlProvider } from 'react-intl';
import Cookie from 'js-cookie';
import { StyleSheetManager } from 'styled-components';

const LanguageContext = React.createContext({} as any);

export const LanguageProvider = ({ children, messages, initLocale }) => {
  const [locale, setLocale] = React.useState(initLocale ?? 'en');
  const changeLanguage = (newLocale): void => {
    setLocale(newLocale);
    document.documentElement.lang = newLocale;
    Cookie.set('locale', newLocale);
  };

  return (
    <LanguageContext.Provider value={{ locale, changeLanguage }}>
      <IntlProvider locale={locale} messages={messages[locale]}>
          <StyleSheetManager>
            {children}
          </StyleSheetManager>
      </IntlProvider>
    </LanguageContext.Provider>
  );
};

export const useLocale = () => React.useContext(LanguageContext);