import React, { FunctionComponent } from 'react';
import { COLOR } from '../../components/FormWithElemets/Button/Button';

interface TagCustomProps {
  legnth: number;
  icon: any;
}
const ContentInformation: FunctionComponent<TagCustomProps> = ({
  legnth,
  icon,
}) => {
  const styleSpan = {
    display: 'inline-block',
    marginRight: '5px',
    paddingLeft: '8px',
    paddingRight: '8px',
    paddingTop: '2px',
    paddingBottom: '2px',
    backgroundColor: COLOR.grey,
    color: '#ffffff',
    borderRadius: '3px',
  };
  return (
    <span
      style={
        legnth > 0
          ? {
              ...styleSpan,
            }
          : {
              ...styleSpan,
              opacity: 0.5,
            }
      }
    >
      {icon}
      <span style={{ paddingLeft: '8px' }}>{legnth}</span>
    </span>
  );
};
export default ContentInformation