import React, { FunctionComponent, useCallback, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { faMinus, faPlus } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { DivButton } from '../../../components/FlexBox/FlexBox';
import { CardBody } from '../../../components/FormWithElemets/Card.style';
import { useToaster } from '../../../context/toaster-provider';
import ItemForm from './ItemForm';
import ListItem from './ListItem';
import { ExecutionPlan } from '../../../types/ExecutionPlan';
import Button from '../../../components/FormWithElemets/Button/Button';
import Step from '../AddExecutionPlanning/Step/Step';
import { PurchaseOffer } from '../../../types/Purchase';

const DELETE_EXECUTION_PLANNING_STEP = loader(
  '../../../graphql/executionPlanningStep/DELETE_EXECUTION_PLANNING_STEP.graphql'
);

interface ListProps {
  items: ExecutionPlan;
  offerId:string;
  orderQty:number;
  purchaseOffer: PurchaseOffer;
}
const ListStep: FunctionComponent<ListProps> = ({ items,offerId,purchaseOffer,orderQty }) => {
  const { showToaster } = useToaster();
  const [itemId, setItemId] = useState(null);
  const [itemAddShow, setItemAddShow] = useState(
    items?.executionPlanStep?.length > 0 ? false : true
  );
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
    setItemAddShow(false);
  }, []);
  const [remove] = useMutation(DELETE_EXECUTION_PLANNING_STEP);
  const handleItemDelete = useCallback((id: number) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: id },
      }).then(() => {
        showToaster('deleted', 'positive');
      });
    }
  }, [showToaster,remove]);
  return (
    <CardBody>
      <DivButton>
        <Button
          type="button"
          onClick={() => {
            setItemAddShow(!itemAddShow);
          }}
        >
          <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
        </Button>
      </DivButton>
      {itemAddShow && <Step setItemAddShow={setItemAddShow} executionPlanId={items.id} />}
      {items &&
        items.executionPlanStep.map((item: any) => {
          return item.id !== itemId ? (
            <ListItem
              key={item.id}
              item={item}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
              offerId={offerId}
              orderQty={orderQty}
              purchaseOffer={purchaseOffer}
            />
          ) : (
            <ItemForm key={item.id} item={item} setItemId={setItemId} />
          );
        })}
    </CardBody>
  );
};
export default ListStep;
