import { loader } from 'graphql.macro';

const GET_AQL_SAMPLE_RULE = loader(
  '../../graphql/aqlSampleRule/GET_AQL_SAMPLE_RULE.graphql'
);
export const handleUpdate = (cache, updateAqlSampleRule) => {
  const { aqlSampleRules } = cache.readQuery({
    query: GET_AQL_SAMPLE_RULE,
  });
  const newAqlSampleRules = aqlSampleRules.map((item) => {
    if (updateAqlSampleRule.id === item.id) {
      return {
        ...updateAqlSampleRule,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_AQL_SAMPLE_RULE,
    data: { aqlSampleRules: newAqlSampleRules },
  });
};
export const handleAdd = (cache, createAqlSampleRule) => {
  const { aqlSampleRules } = cache.readQuery({
    query: GET_AQL_SAMPLE_RULE,
  });
  cache.writeQuery({
    query: GET_AQL_SAMPLE_RULE,
    data: { aqlSampleRules: aqlSampleRules.concat(createAqlSampleRule) },
  });
};
export const handleDelete = (cache, deleteAqlSampleRule) => {
    const { aqlSampleRules } = cache.readQuery({
      query: GET_AQL_SAMPLE_RULE,
    });
    cache.writeQuery({
      query: GET_AQL_SAMPLE_RULE,
      data: { aqlSampleRules: aqlSampleRules.filter((item) => item.id !== deleteAqlSampleRule.id) },
    });
  };
