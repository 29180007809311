import React, { FunctionComponent } from 'react';
interface ListProps {
  innerShape?: string | undefined;
}
export const Round: FunctionComponent<ListProps> = ({ innerShape = 'inner_full' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="50"
      viewBox="0 0 100 100"
    >
      <g id="Group_19" data-name="Group 19" transform="translate(-119)">
        <g
          id="Ellipse_5"
          data-name="Ellipse 5"
          transform="translate(119)"
          fill="none"
          stroke="#707070"
          strokeWidth="3"
        >
          <circle cx="50" cy="50" r="50" stroke="none" />
          <circle cx="50" cy="50" r="48.5" fill="none" />
        </g>
        {innerShape === 'inner_hexagonal' ? (
          <g id="Hexagonal_6" data-name="Hexagonal_6">
            <line
              id="Line_1"
              data-name="Line 1"
              x1="34.214"
              y2="20.168"
              transform="translate(540.877 110.407)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_2"
              data-name="Line 2"
              x1="34.214"
              y1="20.168"
              transform="translate(575.091 110.407)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_3"
              data-name="Line 3"
              y2="39.256"
              transform="translate(540.877 130.575)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_4"
              data-name="Line 4"
              x1="34.214"
              y1="20.168"
              transform="translate(540.877 169.832)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_5"
              data-name="Line 5"
              x1="34.214"
              y2="20.168"
              transform="translate(575.091 169.832)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
            <line
              id="Line_6"
              data-name="Line 6"
              x1="0.36"
              y2="39.256"
              transform="translate(608.945 130.575)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            />
          </g>
        ) : innerShape === 'inner_round' ? (
            <g id="Group_12" data-name="Group 12" transform="translate(18 -100)">
            <g id="Ellipse_2" data-name="Ellipse 2" transform="translate(110 110)" fill="none" stroke="#707070" strokeWidth="3">
              <circle cx="40" cy="40" r="40" stroke="none"/>
              <circle cx="40" cy="40" r="38.5" fill="none"/>
            </g>
          </g>
        ) : innerShape === 'inner_square' ? (
          <g
            id="Square_9"
            data-name="Square 9"
            transform="translate(14 21)"
            fill="none"
            stroke="#707070"
            strokeWidth="3"
          >
            <rect width="58" height="58" stroke="none" />
            <rect x="1.5" y="1.5" width="55" height="55" fill="none" />
          </g>
        ) : (
          innerShape === 'inner_rectangle' && (
            <g
              id="Rectangle_10"
              data-name="Rectangle 10"
              transform="translate(10 28)"
              fill="none"
              stroke="#707070"
              strokeWidth="3"
            >
              <rect width="68" height="42" stroke="none" />
              <rect x="1.5" y="1.5" width="65" height="39" fill="none" />
            </g>
          )
        )}
      </g>
    </svg>
  );
};
