import { loader } from 'graphql.macro';

const GET_OPERATIONS = loader('../../graphql/operation/GET_OPERATIONS.graphql');
//const DELETE_OPERATION = loader('../../graphql/operation/DELETE_OPERATION.graphql');

export const handleDeleteRemoteOperation = (Id: string, cache: any) => {
  const { operations } = cache.readQuery({
    query: GET_OPERATIONS,
  });
  const newOperations = operations.filter((item) => item.id !== Id);
  cache.writeQuery({
    query: GET_OPERATIONS,
    data: { operations: newOperations },
  });
};
export const handleAddRemoteOperationStateAdd = (
  cache: any,
  operationId: string,
  createOperationState: any
) => {
  const { operations } = cache.readQuery({
    query: GET_OPERATIONS,
  });
  const newOperations = operations.map((item) => {
    if (item.id === operationId) {
      return {
        ...item,
        operationState: item.operationState.concat(createOperationState),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_OPERATIONS,
    data: { operations: newOperations },
  });
};
export const handleAddRemoteOperationStateUpdate = (
  cache: any,
  operationId: string,
  updateOperationState: any
) => {
  const { operations } = cache.readQuery({
    query: GET_OPERATIONS,
  });
  const newOperations = operations.map((item: any) => {
    if (item.id === operationId) {
      return {
        ...item,
        operationState: item.operationState.map((state: any) => {
          if (state.id === updateOperationState.id) {
            return {
              ...updateOperationState,
            };
          } else {
            return state;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_OPERATIONS,
    data: { operations: newOperations },
  });
};
export const handleDeleteRemoteOperationState = (
  cache: any,
  operationId: string,
  operationStateId: string
) => {
  const { operations } = cache.readQuery({
    query: GET_OPERATIONS,
  });
  const newOperations = operations.map((item:any) => {
    if (item.id === operationId) {
      return {
        ...item,
        operationState: item.operationState.filter((state:any) => state.id !== operationStateId),
      };
    }else{
      return item
    }
  });
  cache.writeQuery({
    query: GET_OPERATIONS,
    data: { operations: newOperations },
  });
};
export const handleAddRemoteOperationAdd = (cache: any, createOperation: any) => {
  const { operations } = cache.readQuery({
    query: GET_OPERATIONS,
  });
  cache.writeQuery({
    query: GET_OPERATIONS,
    data: { operations: operations.concat(createOperation) },
  });
};
export const handleAddRemoteOperationUpdate = (cache: any, updateOperation: any) => {
  const { operations } = cache.readQuery({
    query: GET_OPERATIONS,
  });
  cache.writeQuery({
    query: GET_OPERATIONS,
    data: {
      operations: operations.map((item: any) => {
        if (item.id === updateOperation.id) {
          return {
            ...updateOperation,
          };
        } else {
          return item;
        }
      }),
    },
  });
};
