import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter } from 'react-router-dom';
import './index.css';
import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { theme } from './theme';
import Routes from './routes';
import { BaseProvider } from 'baseui';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloClient } from 'apollo-client';
import * as serviceWorker from './serviceWorker';
import { setContext } from 'apollo-link-context';
//@ts-ignore
import { LanguageProvider } from './context/language-provider';
import { ToasterProvider } from './context/toaster-provider';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createLink } from 'apollo-absinthe-upload-link';
import { getMainDefinition } from 'apollo-utilities';
import { split } from 'apollo-link';
import Cookie from 'js-cookie';
import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { Socket as PhoenixSocket } from 'phoenix';
import { version } from '../package.json';
import en from './translations/en.json';
import tr from './translations/tr.json';
import './theme/global.css';

declare global {
  interface Window {
    setLoadUpdate: any;
  }
}

const subdomain = window.location.host.split('.')[0];
const tenant = subdomain === 'app' || subdomain === 'localhost:3000' ? 'odin' : subdomain;
const httpLink = createLink({ uri: `http://${tenant}.reactor.prodobit.com/graphql` });
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('prodobit_token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});


const phoenixSocket = new PhoenixSocket(`ws://${tenant}.reactor.prodobit.com/graphql`, {
  params: () => {
    if (Cookie.get('token')) {
      return { token: Cookie.get('token') };
    } else {
      return {};
    }
  },
});

const absintheSocket = AbsintheSocket.create(phoenixSocket);
const websocketLink = createAbsintheSocketLink(absintheSocket);

const link = split(
  ({ query }) => {
    // @ts-ignore
    const { kind, operation } = getMainDefinition(query);
    return kind === 'OperationDefinition' && operation === 'subscription';
  },
  //@ts-ignore
  websocketLink,
  httpLink,
);

export const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache({
    addTypename: false,
  }),
});

const messages = {
  en: en,
  tr: tr,
};

const language = Cookie.get('locale')
  ? Cookie.get('locale')
  : navigator.language.split(/[-_]/)[0];

function App() {
  useEffect(() => {
    document.title = document.title + ' ' + version;
  }, []);

  const engine = new Styletron();
  return (
    <ApolloProvider client={client as any}>
      <StyletronProvider value={engine}>
        <BaseProvider theme={theme}>
          <HashRouter>
            <LanguageProvider messages={messages} initLocale={language}>
              <ToasterProvider>
                <Routes />
              </ToasterProvider>
            </LanguageProvider>
          </HashRouter>
        </BaseProvider>
      </StyletronProvider>
    </ApolloProvider>
  );
}

if (
  navigator.userAgent.toLowerCase().indexOf('chrome') > -1 ||
  navigator.userAgent.toLowerCase().indexOf('firefox') > -1
) {
  window.console.log.apply(console, [
    '%c Made by Prodobit %c %c🤘 %c\n',
    'color: #fff; background: #1BD92A; padding:5px 0;',
    'color: #fff; background: #242424; padding:5px 0 5px 5px;',
    'background: #242424; padding:5px 0',
    'background: #242424; padding:5px 5px 5px 0',
  ]);
} else {
  window.console && window.console.log('Made by Prodobit');
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.unregister();
