import { loader } from 'graphql.macro';

const GET_MACHINERY_AND_EQUIPMENT_ORDERS = loader(
  '../../graphql/machineryAndEquipment/GET_MACHINERY_AND_EQUIPMENT_ORDERS.graphql'
);

export const handleMachineryAndEquipmentOrderStatus = (
  cache,
  machineryAndEquipmentId,
  shopFloorOrderId,
  changeShopFloorOrderStatus
) => {
  const { machineryAndEquipmentOrders } = cache.readQuery({
    query: GET_MACHINERY_AND_EQUIPMENT_ORDERS,
  });
  const newMachineryAndEquipmentOrders = machineryAndEquipmentOrders.map((item) => {
    if (item.id === machineryAndEquipmentId) {
      return {
        ...item,
        shopFloorOrder: item.shopFloorOrder.map((shopFloorOrder) => {
          if (shopFloorOrder.id === shopFloorOrderId) {
            return {
              ...shopFloorOrder,
              shopFloorOrderStatus: changeShopFloorOrderStatus?.shopFloorOrderStatus,
            };
          } else {
            return shopFloorOrder;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_MACHINERY_AND_EQUIPMENT_ORDERS,
    data: { machineryAndEquipmentOrders: newMachineryAndEquipmentOrders },
  });
};

export const handleMachineryAndEquipmentOrderQty = (
  cache,
  machineryAndEquipmentId,
  updateShopFloorOrder
) => {
  const { machineryAndEquipmentOrders } = cache.readQuery({
    query: GET_MACHINERY_AND_EQUIPMENT_ORDERS,
  });
  const newMachineryAndEquipmentOrders = machineryAndEquipmentOrders.map((item) => {
    if (item.id === machineryAndEquipmentId) {
      return {
        ...item,
        shopFloorOrder: item.shopFloorOrder.map((shopFloorOrder) => {
          if (shopFloorOrder.id === updateShopFloorOrder.id) {
            return {
              ...shopFloorOrder,
              qty: updateShopFloorOrder?.qty,
            };
          } else {
            return shopFloorOrder;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_MACHINERY_AND_EQUIPMENT_ORDERS,
    data: { machineryAndEquipmentOrders: newMachineryAndEquipmentOrders },
  });
};
export const frappeGanttBarColor = (shopFloorOrderStatus: string) =>
  shopFloorOrderStatus === 'SHOP_FLOOR_ORDER_RUNNING'
    ? 'c-green'
    : shopFloorOrderStatus === 'SHOP_FLOOR_ORDER_CANCELED' || shopFloorOrderStatus === 'SHOP_FLOOR_ORDER_QUALITY_CONTROL_REQUIRED'
      ? 'c-red'
      : shopFloorOrderStatus === 'SHOP_FLOOR_ORDER_STOPPED'
        ? 'c-orange'
        : shopFloorOrderStatus === 'SHOP_FLOOR_ORDER_COMPLETED' || shopFloorOrderStatus === 'SHOP_FLOOR_ORDER_PARTIAL_COMPLETE'
          ? 'c-blue'
          : 'c-grey';