import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import ListItemProductSales from './ListItemProductSales';
import ItemHeaders from '../../SubContractSales/ItemHeaders';
import { ProductSalesOffer, ProductSalesOfferIDs } from '../../../../types/ProductSalesOffer';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import NoResult from '../../../../components/NoResult/NoResult';
import { StyledSpinnerNext } from 'baseui/spinner';
const GET_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/GET_PRODUCT_SALES_OFFER.graphql'
);
const S_GET_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/S_GET_PRODUCT_SALES_OFFER.graphql'
);
interface ListProductSalesItemProps {
  offerId: string;
}
const ListProductSalesItem: FunctionComponent<ListProductSalesItemProps> = ({
  offerId,
}) => {
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<ProductSalesOfferIDs>(GET_PRODUCT_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_PRODUCT_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.productSalesOffer;
        return Object.assign({}, prev, {
          productSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const newItemsLength = useMemo(() => {
    return data?.productSalesOffer?.productSalesOffer?.length || 0;
  }, [data]);
  return (
    loading ? <StyledSpinnerNext /> : error ? <NoResult /> : data &&
      <Fragment>
        <ItemHeaders itemsLenght={newItemsLength} />
        {
          data.productSalesOffer?.productSalesOffer?.map((item: ProductSalesOffer, index: number) => {
            return (
              (
                <ListItemProductSales key={index} item={item} />
              )
            );
          })}
      </Fragment>
  );
};

export default ListProductSalesItem;
