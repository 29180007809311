import React, { FunctionComponent, useContext, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useIntl } from 'react-intl';
import { loader } from 'graphql.macro';
import { useToaster } from '../../context/toaster-provider';
import { ColumnContents } from '../../components/Table/DataTableCustom';
import { CardBody } from '../../components/FormWithElemets/Card.style';
import WarehouseSectionForm from './WarehouseSectionForm';
import { WarehouseSection } from '../../types/WarehouseSection';
import {
  handleDeleteWareHouseSection,
  handleUpdateWareHouseSectionOrder,
} from '../WareHouse/GraphqlFunction';
import DataTableCustomWithOrder from '../../components/Table/DataTableCustomWithOrder';
import { WarehouseContext } from '../WarehouseLevel/List';
import WarehouseRow from '../WarehouseRow/WarehouseRow';

const DELETE_WAREHOUSE_SECTION = loader(
  '../../graphql/warehouseSection/DELETE_WAREHOUSE_SECTION.graphql'
);
const UPDATE_WAREHOUSE_SECTION_ORDER = loader(
  '../../graphql/warehouseSection/UPDATE_WAREHOUSE_SECTION_ORDER.graphql'
);
interface ListContextType {
  warehouse: any;
  setWarehouse: Function;
}
const columnHeaders = [
  { name: 'warehouse_section.serial', col: { sm: 2 } },
  { name: 'warehouse_section.order', col: { sm: 2 } },
  { name: 'warehouse_section.name', col: { sm: 6 } },
  { name: 'transactions', col: { sm: 2 } },
];
interface ListProps {
  data: WarehouseSection[];
  warehouseId: string;
}
const List: FunctionComponent<ListProps> = ({ data, warehouseId }) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const { warehouse, setWarehouse } = useContext<ListContextType>(WarehouseContext);
  const [items, setItems] = useState(null);
  const columnContents: ColumnContents[] = [
    {
      name: 'serial',
      title: { name: 'warehouse_section.serial', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'order',
      title: { name: 'warehouse_section.order', custom: true },
      type: 'text',
      col: { sm: 2 },
    },
    {
      name: 'name',
      title: { name: 'warehouse_section.name', custom: true },
      type: 'text',
      col: { sm: 6 },
    },

    {
      name: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleRemove(id);
          },
        },
        {
          label: intl.formatMessage({ id: 'button.warehouse_row' }),
          function: (id) => {
            setWarehouse({
              ...warehouse,
              warehouseSectionId: id,
            });
          },
        },
      ],
      title: { name: 'transactions', custom: true },
      type: 'buttons',
      col: { sm: 2 },
    },
  ];
  const [remove] = useMutation(DELETE_WAREHOUSE_SECTION);
  const handleRemove = (Id) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
        update(cache, { data: { deleteWarehouseSection } }) {
          handleDeleteWareHouseSection(
            cache,
            deleteWarehouseSection,
            warehouse.warehouseLevelId,
            warehouseId
          );
        },
      }).then(() => showToaster('deleted', 'positive'));
    }
  };
  const handleOrder = (items) => {
    setItems(
      items.map((item, index) => {
        return {
          ...item,
          order: index,
        };
      })
    );
    const newItemsOrders = items.map((item, index) => {
      return {
        id: item.id,
        order: index,
      };
    });
    updateOrder({
      variables: {
        params: newItemsOrders,
      },
    }).then(() => showToaster('updated', 'positive'));
  };
  const [updateOrder] = useMutation(UPDATE_WAREHOUSE_SECTION_ORDER, {
    update(cache, { data: { updateWarehouseSectionOrder } }) {
      updateWarehouseSectionOrder?.status &&
        handleUpdateWareHouseSectionOrder(cache, items, warehouse.warehouseLevelId, warehouseId);
    },
  });
  return (
    <CardBody style={{ padding: '5px' }}>
      {!warehouse.warehouseSectionId ? (
        <DataTableCustomWithOrder
          threeDot
          data={data}
          columnHeaders={columnHeaders}
          columnContents={columnContents}
          updateOrder={(items) => {
            handleOrder(items);
          }}
          form={({ item, setItemId }) => (
            <WarehouseSectionForm
              data={item}
              setItemId={setItemId}
              warehouseId={warehouseId}
            />
          )}
        />
      ) : (
        <WarehouseRow
          data={
            data.find((item) => item.id === warehouse.warehouseSectionId).warehouseRow
          }
          warehouseId={warehouseId}
        />
      )}
    </CardBody>
  );
};
export default List;
