import React, { FunctionComponent, useContext, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { Col, RowCustom } from "../../../components/FlexBox/FlexBox";
import Button from "../../../components/FormWithElemets/Button/Button";
import { Card, CardBody, CardTitle } from "../../../components/FormWithElemets/Card.style";
import CompanyInformation from "../../Functions/CompanyInformation";
import { ComponentStatusContext } from "../Offer";
import OperationSales from "./OperationSales";
import ProductSales from "./ProductSales";
import SubcontractSales from "./SubcontractSales";

const Done: FunctionComponent = () => {
  const { componentStatus: { data }, dispatchComponentStatus } = useContext(
    ComponentStatusContext
  );
  const {
    id,
    company,
    subcontractSalesOffer,
    productSalesOffer,
    operationSalesOffer,
  } = data;
  const currentSalesOffer = useMemo(() => subcontractSalesOffer?.length
    ? 'update_subContract'
    : productSalesOffer?.length
      ? 'update_product'
      : operationSalesOffer?.length && 'update_operation',
    [subcontractSalesOffer, productSalesOffer, operationSalesOffer])
  return (
  <Card>
    <CardTitle title={<FormattedMessage id={`offer.${currentSalesOffer}`} />}>
      <Button
        onClick={() => {
          dispatchComponentStatus({ type: 'LIST' });
        }}
      >
        <FormattedMessage id="button.back" />
      </Button>
    </CardTitle>
    <CardBody>
      <RowCustom>
        <Col xs={12} md={7} lg={6}>
          <CompanyInformation
            companyId={company?.id}
            companyName={company?.companyName}
          />
        </Col>
      </RowCustom>
    </CardBody>
    <CardBody>
      <div style={{ marginTop: '50px' }} />
      {currentSalesOffer === 'update_subContract' && (
        <SubcontractSales
          offerId={id}
        />
      )}
      {currentSalesOffer === 'update_product' && (
        <ProductSales
          offerId={id}
        />
      )}
      {currentSalesOffer === 'update_operation' && (
        <OperationSales
          offerId={id}
        />
      )}
    </CardBody>
  </Card>)
}
export default Done;