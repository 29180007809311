import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { handleUpdateDay } from './GraphqlFuntion';

const UPDATE_DAY = loader('../../graphql/shift/UPDATE_DAY.graphql');

const DayForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue,  control } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
      setValue('workingDay', dataDispatch.workingDay);
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ name, workingDay }) => {
    updateDay({
      variables: {
        id: dataDispatch.id,
        name: name,
        workingDay: workingDay,
      },
    });
  };

  const [updateDay] = useMutation(UPDATE_DAY, {
    update(cache,{data:{updateDay}}) {
       handleUpdateDay(cache,updateDay)
      closeDrawer();
    },
  });
  return (
    <Card title={<FormattedMessage id={'day.update'} />}>
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="day.name" />}
          error={errors.name ? 'Please input a valid Name' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <Controller
          as={
            <Checkbox
              overrides={{
                Label: {
                  style: ({ $theme }) => {
                    return {
                      marginTop: '7px',
                      marginBottom: '10px',
                    };
                  },
                },
                Checkmark: {
                  style: ({ $theme }) => {
                    return {
                      marginTop: '7px',
                      marginBottom: '10px',
                    };
                  },
                },
              }}
            >
              <FormattedMessage id="day.workingDay" />
            </Checkbox>
          }
          name="workingDay"
          type="checkbox"
          control={control}
          labelPlacement={LABEL_PLACEMENT.right}
          defaultValue={false}
        />

        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={'button.update'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default DayForm;
