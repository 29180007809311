import React, {FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery} from '@apollo/react-hooks';
import { FormattedMessage } from 'react-intl';
import { loader } from 'graphql.macro';
import { TYPE } from 'baseui/select';
import { ProductRecordsReasons } from '../../types/ProductRecordReason';


const GET_PRODUCT_RECORD_REASONS = loader(
  '../../graphql/productRecordReason/GET_PRODUCT_RECORD_REASONS.graphql'
);
interface ListProps {
    errors: any;
    control: any;
  }
export const SelectProductRecordReason: FunctionComponent<ListProps> = ({
  errors,
  control,
}) => {
  const { data } = useQuery<ProductRecordsReasons>(GET_PRODUCT_RECORD_REASONS);

  return (
    <FormControl
      label={<FormattedMessage id="product_record.reason" />}
      error={
        errors.productRecordReason ? 'Please input a valid Product Record Reason' : null
      }
    >
      <Select
        data={data && data.productRecordsReasons}
        controller={{
          name: 'productRecordReason',
          type: TYPE.search,
          control,
          multi: true,
          rules: { required: errors ? true : false },
          searchable: true,
          filterOutSelected: false,
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
export default SelectProductRecordReason