import { loader } from 'graphql.macro';
const SEARCH_PRODUCTS= loader('../../graphql/product/SEARCH_PRODUCTS.graphql');
const GET_PRODUCT_CATEGORIES = loader(
  '../../graphql/product/GET_PRODUCT_CATEGORIES.graphql'
);
export const handleAddProduct = (cache, createProduct, createType, cacheSearch) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full: true,
      name: null,
    },
  });
  const { productCategories } = cache.readQuery({
    query: GET_PRODUCT_CATEGORIES,
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full: true,
      name: null,
    },
    data: { searchProduct: searchProduct.concat(createProduct) },
  });
  createType &&
    cache.writeQuery({
      query: GET_PRODUCT_CATEGORIES,
      data: {
        productCategories: productCategories.concat(createProduct.productCategory),
      },
    });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: searchProduct.concat(createProduct) },
    });
  }
};
export const handleUpdateProduct = (cache, updateProduct, cacheSearch) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (updateProduct.id === item.id) {
      return updateProduct;
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (updateProduct.id === item.id) {
        return updateProduct;
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleDeleteProduct = (cache, deleteProduct, cacheSearch) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: searchProduct.filter((item) => item.id !== deleteProduct.id) },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: {
        searchProduct: searchProduct.filter((item) => item.id !== deleteProduct.id),
      },
    });
  }
};
export const handleUpdateProductCategory = (cache, updateProductCategory) => {
  const { productCategories } = cache.readQuery({
    query: GET_PRODUCT_CATEGORIES,
  });
  const newproductCategories = productCategories.map((item) => {
    if (updateProductCategory.id === item.id) {
      return {
        ...item,
        name: updateProductCategory.name,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_PRODUCT_CATEGORIES,
    data: { productCategories: newproductCategories },
  });
};
export const handleAddProductCategory = (cache, createProductCategory) => {
  const { productCategories } = cache.readQuery({
    query: GET_PRODUCT_CATEGORIES,
  });
  cache.writeQuery({
    query: GET_PRODUCT_CATEGORIES,
    data: {
      productCategories: productCategories.concat(createProductCategory),
    },
  });
};
export const handleAddOProductOperation = (
  cache,
  productId,
  createProductOperation,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: item.productOperation.concat([
          { ...createProductOperation, measurementRule: [] },
        ]),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: item.productOperation.concat([
            { ...createProductOperation, measurementRule: [] },
          ]),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleUpdateProductOperation = (
  cache,
  productId,
  updateProductOperation,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: item.productOperation.map((operation) => {
          return operation.id === updateProductOperation.id
            ? updateProductOperation
            : operation;
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: item.productOperation.map((operation) => {
            return operation.id === updateProductOperation.id
              ? updateProductOperation
              : operation;
          }),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleDeleteProductOperation = (
  cache,
  productId,
  deleteProductOperation,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: item.productOperation.filter(
          (operation) => operation.id !== deleteProductOperation.id
        ),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: item.productOperation.filter(
            (operation) => operation.id !== deleteProductOperation.id
          ),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleUpdateProductOperationOrder = (
  cache,
  productId,
  items,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: items,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: items,
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleAddOProductRawMaterial = (
  cache,
  productId,
  createProductRawMaterial,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productRawMaterial: item.productRawMaterial.concat(createProductRawMaterial),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productRawMaterial: item.productRawMaterial.concat(createProductRawMaterial),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleUpdateProductRawMaterial = (
  cache,
  productId,
  updateProductRawMaterial,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productRawMaterial: item.productRawMaterial.map((operation) => {
          return operation.id === updateProductRawMaterial.id
            ? updateProductRawMaterial
            : operation;
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productRawMaterial: item.productRawMaterial.map((operation) => {
            return operation.id === updateProductRawMaterial.id
              ? updateProductRawMaterial
              : operation;
          }),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleDeleteProductMaterial = (
  cache,
  productId,
  deleteProductMaterial,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productMaterial: item.productMaterial.filter(
          (operation) => operation.id !== deleteProductMaterial.id
        ),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productMaterial: item.productMaterial.filter(
            (operation) => operation.id !== deleteProductMaterial.id
          ),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleAddOProductMaterial = (
  cache,
  productId,
  createProductMaterial,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productMaterial: item.productMaterial.concat(createProductMaterial),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productMaterial: item.productMaterial.concat(createProductMaterial),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleUpdateProductMaterial = (
  cache,
  productId,
  updateProductMaterial,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productMaterial: item.productMaterial.map((operation) => {
          return operation.id === updateProductMaterial.id
            ? updateProductMaterial
            : operation;
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productMaterial: item.productMaterial.map((operation) => {
            return operation.id === updateProductMaterial.id
              ? updateProductMaterial
              : operation;
          }),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleDeleteProductRawMaterial = (
  cache,
  productId,
  deleteProductRawMaterial,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productRawMaterial: item.productRawMaterial.filter(
          (operation) => operation.id !== deleteProductRawMaterial.id
        ),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productRawMaterial: item.productRawMaterial.filter(
            (operation) => operation.id !== deleteProductRawMaterial.id
          ),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleAddProductRevision = (
  cache,
  productId,
  createProductRevision,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productRevision:item.productRevision.concat(createProductRevision)
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productRevision:item.productRevision.concat(createProductRevision)
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleAddMeasurementRule = (
  cache,
  productId,
  productOperationId,
  createMeasurementRule,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: item.productOperation.map((operation) => {
          if (operation.id === productOperationId) {
            return {
              ...operation,
              measurementRule: operation.measurementRule.concat(createMeasurementRule),
            };
          } else {
            return operation;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: item.productOperation.map((operation) => {
            if (operation.id === productOperationId) {
              return {
                ...operation,
                measurementRule: operation.measurementRule.concat(createMeasurementRule),
              };
            } else {
              return operation;
            }
          }),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
export const handleDeleteMeasurementRule = (
  cache,
  productId,
  productOperationId,
  deleteMeasurementRule,
  cacheSearch
) => {
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: item.productOperation.map((operation) => {
            if (operation.id === productOperationId) {
              return {
                ...operation,
                measurementRule: operation.measurementRule.filter(
                  (measurementRule) => measurementRule.id !== deleteMeasurementRule.id
                ),
              };
            } else {
              return operation;
            }
          }),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: item.productOperation.map((operation) => {
          if (operation.id === productOperationId) {
            return {
              ...operation,
              measurementRule: operation.measurementRule.filter(
                (measurementRule) => measurementRule.id !== deleteMeasurementRule.id
              ),
            };
          } else {
            return operation;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
};

export const handleUpdateMeasurementRule = (
  cache,
  productId,
  productOperationId,
  updateMeasurementRule,
  cacheSearch
) => {
  const { searchProduct } = cache.readQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
  });
  const newSearchProduct = searchProduct.map((item) => {
    if (item.id === productId) {
      return {
        ...item,
        productOperation: item.productOperation.map((operation) => {
          if (operation.id === productOperationId) {
            return {
              ...operation,
              measurementRule: operation.measurementRule.map((measurementRule) => {
                if (measurementRule.id === updateMeasurementRule.id) {
                  return updateMeasurementRule;
                } else {
                  return measurementRule;
                }
              }),
            };
          } else {
            return operation;
          }
        }),
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: SEARCH_PRODUCTS,
    variables: {
      full:true,
      name: null,
    },
    data: { searchProduct: newSearchProduct },
  });
  if (cacheSearch?.name !== null) {
    const { searchProduct } = cache.readQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
    });
    const newSearchProduct = searchProduct.map((item) => {
      if (item.id === productId) {
        return {
          ...item,
          productOperation: item.productOperation.map((operation) => {
            if (operation.id === productOperationId) {
              return {
                ...operation,
                measurementRule: operation.measurementRule.map((measurementRule) => {
                  if (measurementRule.id === updateMeasurementRule.id) {
                    return updateMeasurementRule;
                  } else {
                    return measurementRule;
                  }
                }),
              };
            } else {
              return operation;
            }
          }),
        };
      } else {
        return item;
      }
    });
    cache.writeQuery({
      query: SEARCH_PRODUCTS,
      variables: {
        full:true,
        name: cacheSearch?.name !== null ? cacheSearch?.name : null,
      },
      data: { searchProduct: newSearchProduct },
    });
  }
};
