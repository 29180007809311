import React, { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { faCheck, faEdit, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useToaster } from '../../../context/toaster-provider';
import CardBody from '../../TailWinduiHTML/Card/CardBody';
import Button from '../../TailWinduiHTML/Form/Button';
import Form from '../../TailWinduiHTML/Form/Form';
import Textarea from '../../TailWinduiHTML/Form/Textarea';
import GridCol from '../../TailWinduiHTML/GridCol';
import Label from '../../TailWinduiHTML/Form/Input/Label';
import ErrorLabel from '../../TailWinduiHTML/Form/Input/ErrorLabel';
const UPDATE_OFFER = loader('../../../graphql/offer/UPDATE_OFFER.graphql');

interface ListOfferNoteProps {
  offerId: string;
  note: string;
}
const ListOfferNote: FunctionComponent<ListOfferNoteProps> = ({ offerId, note }) => {
  const [itemId, setItemId] = useState(null);
  return (
    <CardBody>
      {itemId === null || itemId !== offerId ? (
        <ListOfferItemNote offerId={offerId} note={note} setItemId={setItemId} />
      ) : (
        <UpdateOfferNote offerId={offerId} note={note} setClose={setItemId} />
      )}
    </CardBody>
  );
};
export default ListOfferNote;
///******************************************
interface ListOfferItemNoteProps {
  offerId: string;
  note: string;
  setItemId: Dispatch<SetStateAction<string>>;
}
const ListOfferItemNote: FunctionComponent<ListOfferItemNoteProps> = ({
  offerId,
  note,
  setItemId,
}) => {
  return (
    <div className="grid grid-cols-12 print:grid-cols-12 px-4 place-content-center border-b">
      <div className="col-span-1 print:col-span-1 flex content-center">
        <GridCol  title="offer.order">
          {'1'}
        </GridCol>
      </div>
      <div className="col-span-11 sm:col-span-10 print:col-span-11 flex  content-center">
        <GridCol fontSize="xs" title="offer.note">
          {note || 'No...'}
        </GridCol>
      </div>
      <div className="col-span-12 sm:col-span-1  flex justify-end print:hidden">
        <Button onClick={() => setItemId(offerId)}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </div>
    </div>
  );
};

interface UpdateOfferNoteProps {
  offerId: string;
  note: string;
  setClose: Dispatch<SetStateAction<null>>;
}
const UpdateOfferNote: FunctionComponent<UpdateOfferNoteProps> = ({
  offerId,
  note,
  setClose,
}) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      note: note || '',
    },
  });
  const intl = useIntl();
  const { showToaster } = useToaster();
  const onSubmit = ({ note }) => {
    updateOffer({
      variables: {
        id: offerId,
        note: note,
      },
    }).then(() => {
      setClose(null)
      showToaster('updated', 'positive');
    });
  };
  const [updateOffer] = useMutation(UPDATE_OFFER);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <div className="grid grid-cols-7">
        <div className="col-span-7 sm:col-span-6">
          <Label name="note">{intl.formatMessage({ id: 'offer.note' })}</Label>
          <Textarea register={register({ required: true })} rows={4} name="note" />
          <ErrorLabel>{errors?.note ? 'Please input a valid note' : null}</ErrorLabel>
        </div>
        <div className="col-span-7 sm:col-span-1 place-self-end pb-1">
          <Button onClick={() => setClose(null)}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </div>
      </div>
    </Form>
  );
};
