import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm, Controller } from 'react-hook-form';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { TimePicker } from 'baseui/timepicker';
import { loader } from 'graphql.macro';
import { Checkbox, LABEL_PLACEMENT } from 'baseui/checkbox';
import { handleUpdateTimeRange, handleAddTimeRange } from './GraphqlFuntion';
const ADD_TIME_RANGE = loader('../../graphql/shift/ADD_TIME_RANGE.graphql');
const UPDATE_TIME_RANGE = loader('../../graphql/shift/UPDATE_TIME_RANGE.graphql');
const GET_TIME_GROUPS = loader('../../graphql/shift/GET_TIME_GROUPS.graphql');

const TimeRangeForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue,  control } = useForm();
  const dispatch = useDrawerDispatch();
  const [defaultTimeStart, setDefaultTimeStart] = useState(new Date());
  const [defaultTimeEnd, setDefaultTimeEnd] = useState(new Date());
  const { data: dataTimeGroup } = useQuery(GET_TIME_GROUPS);
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch) {
      setValue('name', dataDispatch.name);
      setValue('timeGroup', [
        { id: dataDispatch.timeGroup.id, label: dataDispatch.timeGroup.name },
      ]);
      setValue('end', new Date(Date.parse(`2019-01-01T${dataDispatch.end}`)));
      setValue('start', new Date(Date.parse(`2019-01-01T${dataDispatch.start}`)));
      setDefaultTimeStart(
        new Date(new Date(Date.parse(`2019-01-01T${dataDispatch.start}`)))
      );
      setDefaultTimeEnd(new Date(new Date(Date.parse(`2019-01-01T${dataDispatch.end}`))));
    } else {
      setValue('end', new Date());
      setValue('start', new Date());
    }
  }, [dataDispatch, setValue]);
  const onSubmit = ({ end, start, timeGroup, name, overtime }) => {
    dataDispatch
      ? updateTimeRange({
          variables: {
            id: dataDispatch.id,
            name: name,
            overtime: overtime,
            timeGroupId: timeGroup[0].id,
            start: new Date(`${start}`).toLocaleTimeString(),
            end: new Date(`${end}`).toLocaleTimeString(),
          },
        })
      : addTimeRange({
          variables: {
            name: name,
            overtime: overtime,
            timeGroupId: timeGroup[0].id,
            start: new Date(`${start}`).toLocaleTimeString(),
            end: new Date(`${end}`).toLocaleTimeString(),
          },
        });
  };
  const [addTimeRange] = useMutation(ADD_TIME_RANGE, {
    update(cache, { data: { createTimeRange } }) {
      handleAddTimeRange(cache,createTimeRange)
      closeDrawer();
    },
  });
  const [updateTimeRange] = useMutation(UPDATE_TIME_RANGE, {
    update(cache,{data:{updateTimeRange}}) {
      handleUpdateTimeRange(cache,updateTimeRange)
      closeDrawer();
    },
  });
  return (
    <Card
      title={
        <FormattedMessage id={dataDispatch ? 'time_range.update' : 'time_range.create'} />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="time_range.name" />}
          error={errors.name ? 'Please input a valid Name' : null}
        >
          <Input
            size={SIZE.compact}
            name="name"
            inputRef={register({
              required: true,
            })}
            error={errors.name}
            overrides={errors.name ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <FormControl
          label={<FormattedMessage id="time_range.time_group" />}
          error={errors.timeGroup ? 'Please input a valid Time Group' : null}
        >
          <Select
            data={dataTimeGroup && dataTimeGroup.timeGroups}
            controller={{
              name: 'timeGroup',
              control: control,
              creatable: true,
              error: errors.timeGroup,
              overrides: errors.timeGroup ? { After: Negative } : { After: Positive },
              onChange: ([selected]) => {
                return selected.option ? selected.value : undefined;
              },
            }}
          />
        </FormControl>

        <FormControl
          label={<FormattedMessage id="time_range.start" />}
          error={errors.start ? 'Please select a valid Date Start Time' : null}
          overrides={{
            Label: {
              style: () => {
                return {
                  color: '#454b68',
                };
              },
            },
          }}
        >
          <Controller
            as={<TimePicker value={defaultTimeStart} format="24" step={1800} />}
            name="start"
            rules={{ required: true }}
            control={control}
            valueName="selected"
            onChange={([selected]) => {
              setDefaultTimeStart(selected);
              return selected ? selected : undefined;
            }}
            error={errors.start}
            overrides={errors.start ? { After: Negative } : { After: Positive }}
          />
        </FormControl>
        <FormControl
          label={<FormattedMessage id="time_range.end" />}
          error={errors.end ? 'Please select a valid Time  End' : null}
          overrides={{
            Label: {
              style: () => {
                return {
                  color: '#454b68',
                };
              },
            },
          }}
        >
          <Controller
            as={<TimePicker value={defaultTimeEnd} format="24" step={1800} />}
            name="end"
            rules={{ required: true }}
            control={control}
            valueName="selected"
            onChange={([selected]) => {
              setDefaultTimeEnd(selected);
              return selected ? selected : undefined;
            }}
            error={errors.end}
            overrides={errors.end ? { After: Negative } : { After: Positive }}
          />
        </FormControl>

        <FormControl
          error={errors.end ? 'Please select a valid Time  End' : null}
          overrides={{
            Label: {
              style: () => {
                return {
                  color: '#454b68',
                };
              },
            },
          }}
        >
          <Controller
            as={
              <Checkbox
                overrides={{
                  Label: {
                    style: ({ $theme }) => {
                      return {
                        marginTop: '7px',
                        marginBottom: '16px',
                      };
                    },
                  },
                  Checkmark: {
                    style: ({ $theme }) => {
                      return {
                        marginTop: '7px',
                        marginBottom: '16px',
                      };
                    },
                  },
                }}
              >
                <FormattedMessage id="shift.overtime" />
              </Checkbox>
            }
            name="overtime"
            type="checkbox"
            control={control}
            labelPlacement={LABEL_PLACEMENT.left}
            defaultValue={false}
          />
        </FormControl>

        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage id={dataDispatch ? 'button.update' : 'button.create'} />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default TimeRangeForm;
