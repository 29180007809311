import React, { Fragment, FunctionComponent, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col,  RowCustom } from '../../../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { ProductSalesOffer } from '../../../../types/ProductSalesOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';

interface ListItemProductSalesProps {
  item: ProductSalesOffer;
}
const ListItemProductSales: FunctionComponent<ListItemProductSalesProps> = memo(({ item}) => {
  return (
    <Fragment>
      <RowCustom>
        <Col xs={4}>
          <LabelCustom title={<FormattedMessage id="offer.item" />}>
            {item?.product?.name || 'No Item...'}
          </LabelCustom>
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.estimatedDeliveryDate" />}>
            {handleLocalDateString(item.estimatedDeliveryDate)}
          </LabelCustom>
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.qty" />}>
            {item?.qty} &nbsp;{' '}
            {item.product?.inventory?.units?.name || 'No Units'}
          </LabelCustom>
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
            {item?.unitPrice}
          </LabelCustom>
        </Col>
        {/* <ColButton xs={2}>
          <Button
            type="button"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
            onClick={() => {
              setItemId(item.id);
            }}
          >
            <FontAwesomeIcon icon={faExpand} size="1x" />
          </Button>
        </ColButton> */}
      </RowCustom>
    </Fragment>
  );
});
export default ListItemProductSales;
