import React, { FunctionComponent, useContext, useEffect, useReducer } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Col, DivSubmit, Row } from '../../../components/FlexBox/FlexBox';
import { reducerFunction } from '../../Functions/Utilities';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../context/toaster-provider';
import { useCompanyAdd } from '../../Functions/useCompanyAdd';
import { Form } from '../../../components/FormWithElemets/Form.style';
import ListSubcontractSalesItem from './ListSubcontractSalesItem';
import { ComponentStatusContext } from '../Offer';
import { ContextSubContractSalesStatus, Status } from './SubContractSales';
import SelectUser from '../../SelectCustom/SelectUser';
const ADD_SUBCONTRACT_SALES_OFFER = loader(
  '../../../graphql/offer/subcontractSales/ADD_SUBCONTRACT_SALES_OFFER.graphql'
);
export const UseFormContext = React.createContext(null);
export const ItemsContext = React.createContext(null);
export const BillOfMaterialCreateContext = React.createContext(null);
type BillOfMaterialType = {
  id: string;
  label: string;
};
type Unit = {
  id: string;
  label: string;
};
export type Item = {
  id: string | undefined;
  billOfMaterialId: BillOfMaterialType[];
  unitsId: Unit[] | undefined;
  estimatedDeliveryDate: string;
  qty: number;
};
type Action =
  | {
      type: 'ADD';
      item: Item;
    }
  | { type: 'DELETE'; index: number }
  | {
      type: 'UPDATE';
      item: Item;
      index: number;
    }
  | {
      type: 'RESET';
    };
const reducer = (state: Item[], action: Action) => {
  return reducerFunction(state, action);
};
interface AddSubconractSalesOfferFormProps {
  subcontractSalesStatus: Status;
}
const AddSubconractSalesOfferForm: FunctionComponent<AddSubconractSalesOfferFormProps> = ({
  subcontractSalesStatus,
}) => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const { setSubcontractSalesStatus } = useContext(ContextSubContractSalesStatus);
  const { showToaster } = useToaster();
  const [items, dispatchItems] = useReducer(reducer, []);
  const {
    register,
    setValue,
    control,
    errors,
    getValues,
    clearError,
    watch,
    handleSubmit,
  } = useForm();
  useEffect(() => {
    const { status, billOfMaterialId, billOfMaterialName } = subcontractSalesStatus;
    if (status === 'AddSubcontractSalesOffer') {
      const itemIndex = items.findIndex(
        (item) => item.billOfMaterialId[0].id === billOfMaterialId
      );
      if (itemIndex !== -1) {
        const item = items[itemIndex];
        dispatchItems({
          type: 'UPDATE',
          item: {
            ...item,
            billOfMaterialId: [{ id: billOfMaterialId, label: billOfMaterialName }],
          },
          index: itemIndex,
        });
        setSubcontractSalesStatus({
          status: 'AddSubcontractSalesOffer',
          billOfMaterialName: '',
          billOfMaterialId: '',
        });
      } else {
        billOfMaterialId !== '' && billOfMaterialName !== ''
          ? setValue('billOfMaterialId', [
              { id: billOfMaterialId, label: billOfMaterialName },
            ])
          : setValue('billOfMaterialId', undefined);
      }
    }
  }, [items, setValue, subcontractSalesStatus, setSubcontractSalesStatus]);
  const { selectCompany } = useCompanyAdd({
    control,
    errors,
    setValue,
    dispatchItems,
    items,
    update: false,
    creatable: true,
  });
  const [addSubContract] = useMutation(ADD_SUBCONTRACT_SALES_OFFER);
  const onSubmit = ({ companyName, productId, qty, userId }) => {
    if (items.length > 0) {
      if (productId === undefined && (qty === '' || qty === undefined)) {
        addSubContract({
          variables: {
            companyId: companyName[0].id,
            assignedTo: userId ? userId[0]?.id : null,
            approved: false,
            transportIncluded: false,
            subcontractSalesOffer: handleNewSubcontractItems(items),
          },
        }).then(() => {
          showToaster('created', 'positive');
          dispatchComponentStatus({ type: 'LIST' });
        });
      } else {
        showToaster('item_did_not_add_or_remove', 'warning');
      }
    } else {
      showToaster('items_is_empty', 'warning');
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <UseFormContext.Provider
        value={{ register, setValue, control, errors, getValues, clearError, watch }}
      >
        <Row style={{ marginBottom: '45px' }}>
          <Col md={7} lg={6} xs={12}>
            <ItemsContext.Provider value={{ items, dispatchItems }}>
              {selectCompany}
            </ItemsContext.Provider>
          </Col>
          <Col md={5} lg={5} xs={12}>
            <SelectUser errors={errors} control={control} name="assignOfferToPerson" />
          </Col>
        </Row>
        <ItemsContext.Provider value={{ items, dispatchItems }}>
          <ListSubcontractSalesItem />
        </ItemsContext.Provider>
      </UseFormContext.Provider>
      <DivSubmit style={{ margin: '40px' }}>
        <Button type="button"  onClick={() => {
            dispatchComponentStatus({ type: 'LIST' });
          }}>
          <FormattedMessage id="button.close" />
        </Button>
        <Button type="submit">
          <FormattedMessage id={'button.create'} />
        </Button>
      </DivSubmit>
    </Form>
  );
};
export default AddSubconractSalesOfferForm;

const handleNewSubcontractItems = (items: Item[]) => {
  return items.map((item) => {
    if (item.billOfMaterialId[0].id === item.billOfMaterialId[0].label) {
      return {
        estimatedDeliveryDate: item?.estimatedDeliveryDate || "",
        name: item.billOfMaterialId[0].id,
        unitsId: item.unitsId[0].id,
        qty: +item.qty,
      };
    } else {
      return {
        estimatedDeliveryDate: item?.estimatedDeliveryDate || "",
        billOfMaterialId: item.billOfMaterialId[0].id,
        unitsId: item.unitsId[0].id,
        qty: +item.qty,
      };
    }
  });
};
