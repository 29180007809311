import React, { FunctionComponent } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { useToaster } from '../../../../context/toaster-provider';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';
import InputForm from './InputForm';
const UPDATE_BILL_OF_MATERIAL_ITEM = loader(
  '../../../../graphql/billOfMaterialItem/UPDATE_BILL_OF_MATERIAL_ITEM.graphql'
);
const ADD_BILL_OF_MATERIAL_ITEM = loader(
  '../../../../graphql/billOfMaterialItem/ADD_BILL_OF_MATERIAL_ITEM.graphql'
);
interface ItemInputsUpdateProps {
  item?: BillOfMaterialItem;
  setItemAddShow?: Function;
  setItemId?: Function;
  billOfMaterialId?: string;
}
const BillOfMaterialItemForm: FunctionComponent<ItemInputsUpdateProps> = ({
  item,
  setItemId,
  billOfMaterialId,
  setItemAddShow,
}) => {
  const { control, errors, handleSubmit, register, setValue, watch } = useForm({
    defaultValues: handleDefaultValues({ item }),
  });
  const { showToaster } = useToaster();

  const [update] = useMutation(UPDATE_BILL_OF_MATERIAL_ITEM);
  const [add] = useMutation(ADD_BILL_OF_MATERIAL_ITEM);
  const onSubmit = ({ inventoryTypeId, materialItemInventoryId, partNumber, quantity }) => {
    const newItem = handleNewItem({
      materialItemInventoryId,
      inventoryTypeId,
      partNumber,
      quantity,
    });
    if (item) {
      update({
        variables: {
          id: item.id,
          ...newItem,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
    } else {
      add({
        variables: {
          billOfMaterialId: billOfMaterialId,
          ...newItem,
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleResetForm();
      });
    }
  };
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('companyName', undefined);
      setValue('materialItemInventoryId', undefined);
      setValue('partNumber', '');
      setValue('quantity', 0);
      setItemAddShow(false);
    }
  };
  return (
    <InputForm
      watch={watch}
      item={item}
      control={control}
      errors={errors}
      setValue={setValue}
      register={register}
      handleResetForm={handleResetForm}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};
const handleNewItem = ({
  materialItemInventoryId,
  inventoryTypeId,
  partNumber,
  quantity,
}) => {
  const nameOrInventoryId =
    materialItemInventoryId[0].id === materialItemInventoryId[0].label
      ? {
        name: materialItemInventoryId[0]?.id,
        inventoryTypeId: inventoryTypeId[0]?.id
      }
      : { inventoryId: materialItemInventoryId[0].id };
  const newItem = {
    ...nameOrInventoryId,
    //vendorId: companyName ? companyName[0]?.id : null,
    partNumber: partNumber,
    quantity: +quantity,
  };
  return newItem;
};
const handleDefaultValues = ({ item }) => {
  const defaultValue = item
    ? {
      materialItemInventoryId: [
        item?.inventory?.name
          ? { id: item.inventory.id, label: item.inventory.name }
          : { id: item.name, label: item.name },
      ],
      // companyName: item?.vendor
      //   ? [{ id: item.vendor.id, label: item.vendor.companyShortName }]
      //   : undefined,
      inventoryTypeId: [{
        id: item?.inventory?.inventoryType?.id || item?.inventoryType?.id,
        label: item?.inventory?.inventoryType?.name || item?.inventoryType?.name,
      }],
      partNumber: item?.partNumber || '',
      quantity: item?.quantity || 0,
    }
    : {};
  return defaultValue;
};
export default BillOfMaterialItemForm;
