// @ts-nocheck
import { styled } from 'baseui';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import { Row as DefaultRow, Col as DefaultCol } from 'react-flexbox-grid';

export const NewRow = styled(DefaultRow, () => ({
  marginLeft: '0',
  marginRight: '0',

  ':last-child': {
    marginBottom: '0px',
  },
}));

export const NewCol = styled(DefaultCol, () => ({
  padding: '0 10px',
}));
export const RowFull = styled(Row, () => ({
  margin: '0 -15px 30px',
  height: '100%',

  ':last-child': {
    marginBottom: '0px',
  },
}));

export const RowSpecial = styled(Row, () => ({
  marginBottom: '0px',

  ':last-child': {
    marginBottom: '10px',
  },
}));

export const ColFull = styled(Col, () => ({
  padding: '0 15px',
  height: '100%',
}));

export const Col1 = styled(Col, () => ({
  height: 'auto',
  backgroundColor: '#E05548',
  borderRadius: '8px 0px 0px 8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  ':active': {
    cursor: 'all-scroll !important',
  },
}));

export const BoxRightCol = styled(Col, () => ({
  paddingLeft: '10px',
  paddingRight: '10px',
}));

export const MainCard = styled('div', () => ({
  borderRadius: '8px',
  backgroundColor: '#eaf4ff',
  height: '99%',
  padding: '10px',
}));

export const ProcessCard = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
  marginBottom: '10px',
}));

export const ProcessLeft = styled('div', () => ({
  color: '#ffffff',
  transform: 'rotate(-90deg)',
  fontWeight: '500',
}));

export const ProductHeadLineForCard = styled('div', () => ({
  borderRadius: '8px',
  backgroundColor: '#fdf2f1',
  padding: '5px',
  marginTop: '5px',
  textAlign: 'center',
  lineHeight: '1.21',
}));
export const ProductHeadLineText = styled('span', () => ({
  fontSize: '0.74em',
  fontWeight: '500',
  fontStretch: '500',
  fontStyle: '500',
  letterSpacing: '0.31px',
  color: '#e05548',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

export const DateWithIcon = styled('div', () => ({
  marginTop: '5px',
  textAlign: 'right',
  marginRight: '10px',
}));
export const DateIcon = styled('span', () => ({
  color: '#65708b',
}));
export const DateWithIconText = styled('span', () => ({
  marginLeft: '10px',
  fontSize: '14px',
  fontWeight: '500',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '2',
  letterSpacing: '0.31px',
  textAlign: 'left',
  color: '#65708b',
}));
export const Text = styled('span', () => ({
  display: 'inline-block',
  marginTop: '5px',
  marginLeft: '10px',
  fontSize: '14px',
  fontWeight: '500',
  fontStretch: 'normal',
  fontStyle: 'normal',
  lineHeight: '2',
  letterSpacing: '0.31px',
  textAlign: 'left',
  color: '#65708b',
}));

export const Heading = styled('h2', ({ $theme }) => ({
  ...$theme.typography.fontBold18,
  color: $theme.colors.textDark,
  margin: 0,
}));
