import React, { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { handleDate } from '../../Functions/Utilities';
import { Item, ItemsContext, UseFormContext } from './AddSubContractSalesForm';
import InputForm from './InputForm';

interface AddSubcontractSalesItemFormProps {
  item?: Item;
  itemIndex?: number;
  setItemIndex?: Function;
  setSubcontractSalesStatus: Function;
  setClose?: Function;
}
const AddSubcontractSalesItemForm: FunctionComponent<AddSubcontractSalesItemFormProps> = ({
  item,
  itemIndex = null,
  setItemIndex,
  setSubcontractSalesStatus,
  setClose,
}) => {
  const { register, setValue, control, getValues, watch, errors } = useContext(
    UseFormContext
  );
  const { dispatchItems } = useContext(ItemsContext);
  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('billOfMaterialId', undefined);
      setValue('estimatedDeliveryDate', [new Date()]);
      setValue('qty', '');
      setValue('unitsId', undefined);
      setClose(false);
    }
  }, [setValue, item, setItemIndex]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { qty, billOfMaterialId, estimatedDeliveryDate, unitsId } = getValues();
    console.log('---->', estimatedDeliveryDate);
    if (qty !== '' && billOfMaterialId) {
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            id: undefined,
            billOfMaterialId: billOfMaterialId,
            unitsId: unitsId,
            estimatedDeliveryDate: estimatedDeliveryDate
              ? handleDate(estimatedDeliveryDate[0])?.toISOString()
              : '',
            qty: qty,
          },
        });
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            id: item?.id,
            billOfMaterialId: billOfMaterialId,
            unitsId: unitsId,
            estimatedDeliveryDate: estimatedDeliveryDate
              ? handleDate(estimatedDeliveryDate[0])?.toISOString()
              : '',
            qty: qty,
          },
          index: itemIndex,
        });
      }
      handleResetForm();
    }
  }, [handleResetForm, dispatchItems, getValues, item, itemIndex]);

  useEffect(() => {
    setValue('billOfMaterialId', item?.billOfMaterialId || undefined);
    setValue('unitsId', item?.unitsId || undefined);
    setValue('qty', item?.qty || '');
    setValue(
      'estimatedDeliveryDate',
      item?.estimatedDeliveryDate
        ? [new Date(item?.estimatedDeliveryDate)] || undefined
        : [new Date()]
    );
  }, [setValue, item]);
  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      estimatedDeliveryDate={item?.estimatedDeliveryDate}
      update={!!item}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      handleResetForm={handleResetForm}
      setSubcontractSalesStatus={setSubcontractSalesStatus}
    />
  );
};
export default AddSubcontractSalesItemForm;
