import React, { FunctionComponent, useState } from 'react';

interface ToggleWithIconProps {
  status?: boolean;
  onClick:Function
  props?:any;
}
const ToggleWithIcon: FunctionComponent<ToggleWithIconProps> = ({
  status: _status = false,
  onClick,
  ...props
}) => {
  const [{ bgColor, translate, opacityClose, opacityOpen }, setState] = useState(
    _status ? on : off
  );
  const handleOnClick = () => {
    setState((prev) => (prev.status === true ? { ...off } : { ...on }));
    onClick()
  };
  return (
    <button
      {...props}
      type="button"
      aria-pressed="false"
      onClick={handleOnClick}
      className={`${bgColor} relative inline-flex flex-shrink-0 h-5 w-8 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none  focus:ring-gray-500`}
    >
      <span className="sr-only">Use setting</span>
      <span
        className={`pointer-events-none ${translate} relative inline-block 
                       h-4 w-5 rounded-full bg-white shadow transform ring-0 
                       transition ease-in-out duration-200`}
      >
        <span
          className={`${opacityClose} absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
          aria-hidden="true"
        >
          <svg className="bg-white h-3 w-3 text-gray-400" fill="none" viewBox="0 0 12 12">
            <path
              d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span
          className={`${opacityOpen} absolute inset-0 h-full w-full flex items-center justify-center transition-opacity`}
          aria-hidden="true"
        >
          <svg
            className="bg-white h-3 w-3 text-indigo-600"
            fill="currentColor"
            viewBox="0 0 12 12"
          >
            <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
          </svg>
        </span>
      </span>
    </button>
  );
};
export default ToggleWithIcon;
const on = {
  bgColor: 'bg-indigo-600',
  translate: 'translate-x-2',
  opacityClose: 'opacity-0 ease-out duration-100',
  opacityOpen: 'opacity-100 ease-in duration-200',
  status: true,
};
const off = {
  bgColor: 'bg-gray-200',
  translate: 'translate-x-0',
  opacityClose: 'opacity-100 ease-in duration-200',
  opacityOpen: 'opacity-0 ease-out duration-100',
  status: false,
};
