import React, { FunctionComponent, useEffect, useState } from 'react';
import { CardBody } from '../../../../../components/FormWithElemets/Card.style';
import {
  Label,
} from '../../../../../components/FormWithElemets/Label.style';
import { FormattedMessage } from 'react-intl';
import Button, {
  COLOR,
} from '../../../../../components/FormWithElemets/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowToBottom,
  faArrowToTop,
  faEdit,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { SubcontractSalesOfferOperationPrice as SubcontractSalesOfferOperationPriceType } from '../../../../../types/SubcontractSalesOfferOperationPrice';
import { ExecutionPlanStep } from '../../../../../types/ExecutionPlan';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import ItemDetailOperationForm from './ItemDetailOperationForm';
import useOperationPriceUpdate from './useOperationPriceUpdate';
import { PurchaseOperationOffer } from '../../../../../types/PurchaseOperationOffer';
import ItemDateilOperationPrice from './ItemDetailOperationPrice';
import { handleCurrency } from '../../../../Functions/Utilities';

interface ItemDetailMaterialProps {
  executionPlanStep: ExecutionPlanStep[];
  subcontractSalesOfferOperationPrice: SubcontractSalesOfferOperationPriceType[];
  subcontractSalesOfferPriceId: string;
  purchaseOperationOffer: PurchaseOperationOffer[];
}
const ItemDetailOperation: FunctionComponent<ItemDetailMaterialProps> = ({
  executionPlanStep,
  subcontractSalesOfferOperationPrice,
  subcontractSalesOfferPriceId,
  purchaseOperationOffer,
}) => {
  const { priceUpdate } = useOperationPriceUpdate();
  const [detailShow, setDetailShow] = useState(true);
  const [itemAdd, setItemAdd] = useState(null);
  useEffect(() => {
    priceUpdate;
  }, [purchaseOperationOffer]);
  return (
    <CardBody style={{ paddingLeft: '20px' }}>
      <Label
        style={{ borderBottom: '1px solid ' + COLOR.grey }}
        title={<FormattedMessage id="operationPrice.operations" />}
      >
        <Button disabled={!itemAdd} onClick={() => setItemAdd(null)}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <Button onClick={() => setDetailShow(!detailShow)}>
          <FontAwesomeIcon icon={detailShow ? faArrowToTop : faArrowToBottom} />
        </Button>
      </Label>
      <div style={detailShow ? {} : { display: 'none' }}>
        {itemAdd !== null && (
          <ItemDetailOperationForm
            itemAdd={itemAdd}
            setItemAdd={setItemAdd}
            subcontractSalesOfferPriceId={subcontractSalesOfferPriceId}
          />
        )}
        {itemAdd === null &&
          executionPlanStep.map((item) => {
            const itemPrice = subcontractSalesOfferOperationPrice?.find(
              (price) => price.executionPlanStepId === item.id
            );
            return (
              <RowCustom key={item?.id}>
                <Col sm={12} md={8} lg={4}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      height: '100%',
                      textDecoration: 'underline',
                    }}
                  >
                    {item?.operation?.name || item?.name || 'No Inventory'}
                  </div>
                </Col>
                <ItemDateilOperationPrice
                  itemPrice={itemPrice}
                  duration={item?.duration}
                  external={!!item?.external}
                  executionPlanStepId={item.id}
                  subcontractSalesOfferPriceId={subcontractSalesOfferPriceId}
                  operationId={item.operation?.id}
                  priceUpdate={priceUpdate}
                  purchaseOfferRecord={handleChangeCheckPriceExternal(
                    item?.operation?.id,
                    purchaseOperationOffer
                  )}
                />
                <ColButton xs>
                  <Button
                    onClick={() =>
                      setItemAdd({
                        itemExecutionStep: item,
                        itemPrice,
                      })
                    }
                    color={COLOR.green}
                  >
                    <FontAwesomeIcon icon={itemPrice ? faEdit : faPlus} />
                  </Button>
                </ColButton>
              </RowCustom>
            );
          })}
      </div>
    </CardBody>
  );
};
export default ItemDetailOperation;

const handleChangeCheckPriceExternal = (
  operationId: string,
  purchaseOperationOffer: PurchaseOperationOffer[]
) => {
  const currency =
    purchaseOperationOffer
      ?.find((item) => item.operation?.id === operationId)
      ?.purchaseOfferRecord.find((record) => record.accepted == true)
      ?.currency || '₺';

  const totalQty = purchaseOperationOffer?.reduce((acc, item) => {
    return item.operation?.id === operationId
      ? acc +
      item.purchaseOfferRecord?.reduce(
        (accc, record) =>
          record.accepted === true ? accc + record.qty : accc + 0,
        0
      )
      : acc + 0;
  }, 0);
  const unitPrice = totalQty
    ? purchaseOperationOffer?.reduce((acc, item) => {
      return item.operation.id === operationId
        ? acc +
        item.purchaseOfferRecord?.reduce(
          (accc, record) =>
            record.accepted === true
              ? accc + (record.qty * record.unitPrice)
              : accc + 0,
          0
        )
        : acc + 0;
    }, 0)
    : 0;
  return {
    qty: Math.ceil(100 * totalQty) / 100,
    unitPrice: (Math.ceil(100 * unitPrice / totalQty) / 100) || 0,
    currency: handleCurrency(currency),
  };
};
