import React, { Fragment, FunctionComponent, useEffect, useMemo, useState } from 'react';
import ListItemOperationSalesItem from './ListItemOperationSales';
import ItemHeaders from '../../OperationSales/ItemHeaders';
import { OperationSalesOffer, OperationSalesOfferIDs } from '../../../../types/OperationSalesOffer';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
const GET_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/GET_OPERATION_SALES_OFFER.graphql'
);
const S_GET_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/S_GET_OPERATION_SALES_OFFER.graphql'
);
interface ListOperationSalesProps {
  offerId: string;
}
const ListOperationSales: FunctionComponent<ListOperationSalesProps> = ({
  offerId,
}) => {
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<OperationSalesOfferIDs>(GET_OPERATION_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_OPERATION_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.operationSalesOffer;
        return Object.assign({}, prev, {
          operationSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const newItemsLength = useMemo(() => {
    return data?.operationSalesOffer?.operationSalesOffer?.length || 0;
  }, [data]);
  return (
    loading ? <StyledSpinnerNext /> : error ? <NoResult /> : data &&
      <Fragment>
        <ItemHeaders itemsLenght={newItemsLength} />
        {
          data.operationSalesOffer?.operationSalesOffer?.map((item: OperationSalesOffer, index: number) => {
            return (
              (
                <ListItemOperationSalesItem key={index} item={item}
                />
              )
            );
          })}
      </Fragment>
  );
};

export default ListOperationSales;
