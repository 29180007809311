import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import ItemHeaders from '../../SubContractSales/ItemHeaders';
import { useToaster } from '../../../../context/toaster-provider';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import Transport from '../Transport/Transport';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import {
  OperationSalesOffer,
  OperationSalesOfferIDs,
} from '../../../../types/OperationSalesOffer';
import ListItemOperationSales from './ListItemOperationSales';
import UpdateOperationSalesForm from './UpdateOperationSalesForm';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
import OperationSalesResult from './OperationSalesResult';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import ListOperationSalesPreview from './Preview/ListOperationSalesPreview';
const DELETE_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/DELETE_OPERATION_SALES_OFFER.graphql'
);
const GET_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/GET_OPERATION_SALES_OFFER.graphql'
);
const S_GET_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/S_GET_OPERATION_SALES_OFFER.graphql'
);

interface ListOperationSalesProps {
  offerId: string;
  offerTransport: OfferTransport[];
  transportIncluded: boolean;
  preview: boolean
}
const ListOperationSales: FunctionComponent<ListOperationSalesProps> = ({
  offerId,
  offerTransport,
  transportIncluded,
  preview
}) => {
  const { showToaster } = useToaster();
  const {
    subscribeToMore,
    data,
    loading,
    error,
  } = useQuery<OperationSalesOfferIDs>(GET_OPERATION_SALES_OFFER, {
    variables: {
      id: offerId,
    },
  });
  useEffect(() => {
    subscribeToMore({
      document: S_GET_OPERATION_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.operationSalesOffer;
        return Object.assign({}, prev, {
          operationSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const [itemId, setItemId] = useState(null);
  const [transportUnitPrice, setTransportUnitPrice] = useState(0);
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
  }, []);
  const [remove] = useMutation(DELETE_OPERATION_SALES_OFFER);
  const handleItemDelete = useCallback(
    (id: string) => {
      if (window.confirm('Are you sure !')) {
        remove({
          variables: { id: id },
        }).then(() => {
          showToaster('deleted', 'positive');
        });
      }
    },
    [showToaster, remove]
  );
  useEffect(() => {
    if (data) {
      const {
        operationSalesOffer: { operationSalesOffer },
      } = data;
      const _transportUnitPrice = handleTransportUnitPrice(
        transportIncluded,
        offerTransport,
        operationSalesOffer
      );
      setTransportUnitPrice(_transportUnitPrice);
    }
  }, [offerTransport, data, transportIncluded]);
  return (
    <Fragment>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
            data && (
              <Fragment>
                <CardBody style={preview ? { display: 'none' } : {}}>
                  <ItemHeaders
                    itemsLenght={1}
                  />
                  {data.operationSalesOffer?.operationSalesOffer?.map(
                    (item: OperationSalesOffer, index: number) => {
                      return itemId === null && item.id !== itemId ? (
                        <ListItemOperationSales
                          key={index}
                          item={item}
                          transportUnitPrice={transportUnitPrice}
                          handleItemEdit={handleItemEdit}
                          handleItemDelete={handleItemDelete}
                        />
                      ) : (
                          <UpdateOperationSalesForm
                            key={item.id}
                            item={item}
                            setClose={setItemId}
                          />
                        );
                    }
                  )}
                  <Transport
                    offerId={offerId}
                    offerTransport={offerTransport}
                    transportIncluded={transportIncluded}
                  />
                </CardBody>
                <CardBody style={preview ? {} : { display: 'none' }}>
                  <ListOperationSalesPreview items={data?.operationSalesOffer?.operationSalesOffer} transportUnitPrice={transportUnitPrice} />
                </CardBody>
                <OperationSalesResult
                  preview={preview}
                  offerTransport={offerTransport}
                  items={data?.operationSalesOffer?.operationSalesOffer}
                  transportIncluded={transportIncluded}
                />
              </Fragment>
            )
          )}
    </Fragment>
  );
};

export default ListOperationSales;
const handleTransportUnitPrice = (
  transportIncluded: boolean,
  offerTransport: OfferTransport[],
  items: OperationSalesOffer[]
) => {
  const offerTransportTotalPrice = transportIncluded
    ? offerTransport.reduce(
      (acc: number, item: OfferTransport) =>
        item.approved === true ? acc + item.price : 0,
      0
    )
    : 0;
  const orderQty =
    items.reduce(
      (acc: number, item: OperationSalesOffer) => acc + (item?.qty || 0),
      0
    ) || 1;
  const transportUnitPrice =
    Math.ceil(100 * (offerTransportTotalPrice / orderQty)) / 100;
  return transportUnitPrice;
};
