import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useState,
} from 'react';
import { BillOfMaterialType } from '../../../../types/BillOfMaterial';
import UpdateBillOfMaterialForm from '../../UpdateBillOfMaterialForm';
import ListItemBillOfMaterialRow from '../../ListItemBillOfMaterialRow';
import { useIntl } from 'react-intl';
import ListBillOfMaterialItem from './ListBillOfMaterialItem';
import { OfferPurchaseOffer } from '../../../../types/Offer';
import ListPurchaseOffer from '../ListPurhaseOffer';
import ListTechnicalImagesCustom from '../../../TechicalImages/ListAndAddTechicalImagesCustom';
import { loader } from 'graphql.macro';
const DELETE_BILL_OF_MATERIAL_ATTACHMENT = loader('../../../../graphql/uploadBillOfMaterial/DELETE_BILL_OF_MATERIAL_ATTACHMENT.graphql');
const UPLOAD_BILL_OF_MATERIAL_ATTACHMENT = loader(
  '../../../../graphql/uploadBillOfMaterial/UPLOAD_BILL_OF_MATERIAL_ATTACHMENT.graphql'
);
interface ListProps {
  item: BillOfMaterialType;
  offerPurchaseOffer: OfferPurchaseOffer;
  offerId: string;
  orderQty: number;
  componentStatus: string;
  setComponentStatus: Function
}
const ItemBillOfMaterial: FunctionComponent<ListProps> = ({
  item,
  offerPurchaseOffer,
  offerId,
  orderQty,
  componentStatus,
  setComponentStatus
}) => {
  const intl = useIntl();
  const [itemId, setItemId] = useState(null);
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
  }, []);
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    },
    {
      label: intl.formatMessage({ id: 'billOfMaterial.purhaseOffer' }),
      function: () => {
        setComponentStatus("purhaseOffer");
      },
      check_type: '', 
    },
    {
      label: intl.formatMessage({ id: 'billOfMaterial.item' }),
      function: () => {
        setComponentStatus("billOfMaterialItem");
      },
      check_type: '',
    },
    {

      label: intl.formatMessage({ id: 'billOfMaterial.images' }),
      function: () => {
        setComponentStatus("images");
      },
      check_type: 'IMAGES',
    },
  ];
  return (
    <Fragment>
      {itemId !== item.id ?
        (<ListItemBillOfMaterialRow item={item} buttons={buttons} />

        ) : (
          <UpdateBillOfMaterialForm item={item} setItemId={setItemId} />
        )
      }
      {
        componentStatus === 'images' ?
          <ListTechnicalImagesCustom
            imajes={item?.billOfMaterialAttachment}
            parentVariables={{ billOfMaterialId: item.id }}// örenek { billOfMaterialId:"kefnklmsmfs de"}
            addMutation={UPLOAD_BILL_OF_MATERIAL_ATTACHMENT}
            deleteMutation={DELETE_BILL_OF_MATERIAL_ATTACHMENT}
          /> :
          componentStatus === 'billOfMaterialItem' ?
            <ListBillOfMaterialItem
              items={item?.billOfMaterialItem}
              billOfMaterialId={item.id}
              purchaseOffer={offerPurchaseOffer?.purchaseOffer}
              offerId={offerId}
              orderQty={orderQty} /> :
              componentStatus === 'purhaseOffer' &&
            <ListPurchaseOffer items={offerPurchaseOffer?.purchaseOffer} />
      }
    </Fragment>
  );
};
export default ItemBillOfMaterial;
