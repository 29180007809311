import React, { FunctionComponent, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import FormControl from '../../components/FormWithElemets/FormControl';
import { FormattedMessage } from 'react-intl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { SearchCompany } from '../../types/Company';
import { debounce } from '../Functions/Utilities';

const SEARCH_COMPANY = loader('../../graphql/company/SEARCH_COMPANY.graphql');
interface ListProps {
  name?: string;
  control: any;
  errors: any;
  update?: boolean | undefined;
  creatable?: boolean | undefined;
  required?:boolean
}
const SelectCompany: FunctionComponent<ListProps> = ({
  name,
  errors,
  control,
  update = false,
  creatable = false,
  required=false
}) => {
  const [loadOptionsEs, { data, loading }] = useLazyQuery<SearchCompany>(SEARCH_COMPANY);
  useEffect(() => {
    loadOptionsEs({
      variables: {
        full: false,
        name: null,
      },
    });
  }, [loadOptionsEs]);
  const handleInputChange = debounce(function (term: string) {
    loadOptionsEs({
      variables: {
        full: false,
        name: term ? term : null,
      },
    });
  });
  return ( 
    <FormControl
      label={<FormattedMessage id={name ? "company."+name :"company.companyShortName"} />}
      error={errors.companyName ? 'Please select a valid Company' : null}
    >
      <Select
        data={data && data.searchCompany}
        labelValue="companyShortName"
        controller={{
          disabled: update,
          name: 'companyName',
          isLoading: loading,
          rules: { required: required },
          control,
          creatable: creatable, 
          error: errors.companyName,
          overrides: errors.companyName ? { After: Negative } : { After: Positive },
          onChange: ([selected]) => {
            return selected?.option ? selected.value : undefined;
          },
          onInputChange: (event: React.SyntheticEvent) => {
            const target = event.target as HTMLInputElement;
            handleInputChange(target.value);
          },
        }}
      />
    </FormControl>
  );
};
export default SelectCompany;
