import React, { Fragment, FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { SubcontractSalesOffer } from '../../types/SubcontractSalesOffer';
import {
  NewCol,
  ProductHeadLineForCard,
  ProductHeadLineText,
  RowSpecial,
  Text,
} from './RequestBox.style';

interface SubcontractSalesOfferProps {
  data: SubcontractSalesOffer[];
}
const SubcontractSales: FunctionComponent<SubcontractSalesOfferProps> = ({ data }) => {
  return (
    <Fragment>
      {data.map((item, index) => {
        return (
          <RowSpecial middle="xs" between="xs" key={index}>
            <NewCol md={7} lg={7}>
              <ProductHeadLineForCard>
                <ProductHeadLineText>
                  {item?.billOfMaterial?.inventory?.name || item?.billOfMaterial?.name}
                </ProductHeadLineText>
              </ProductHeadLineForCard>
            </NewCol>
            <NewCol xs>
              <Text>
                <b>
                  <FormattedMessage id="offer.qty" />:
                </b>{' '}
                {item.qty} {item.billOfMaterial?.inventory?.units?.name || item?.units?.name}
              </Text>
            </NewCol>
          </RowSpecial>
        );
      })}
    </Fragment>
  );
};

export default SubcontractSales;
