import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { useToaster } from '../../../../context/toaster-provider';
import { handleDate } from '../../../Functions/Utilities';
import InputForm from '../../OperationSales/InputForm';
import { Item } from '../../OperationSales/OperationSalesOfferAdd';
const ADD_INLINE_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/ADD_INLINE_OPERATION_SALES_OFFER.graphql'
);
const UPDATE_OPERATION_SALES_OFFER = loader(
  '../../../../graphql/offer/operationSales/UPDATE_OPERATION_SALES_OFFER.graphql'
);
interface ItemInputsProps {
  item?: Item;
  itemIndex?: number;
  setClose?: Function;
  parentId?: string | undefined;
}
const UpdateAddOperationSalesItems: FunctionComponent<ItemInputsProps> = ({
  item,
  itemIndex = null,
  setClose,
  parentId = undefined,
}) => {
  const { showToaster } = useToaster();
  const { register, setValue, control, getValues, watch, errors } = useForm();
  const handleAddOrUpdateItem = () => {
    const { qty, operationId, estimatedDeliveryDate, unitPrice, unitsId } = getValues();
    if (qty !== '' && operationId) {
      if (itemIndex === null) {
        addInlineOperation({
          variables: {
            id: parentId,
            operationSalesOffer: handleNewProductItem({
              qty,
              operationId,
              estimatedDeliveryDate,
              unitPrice: unitPrice || 0,
              unitsId,
            }),
          },
        }).then(() => {
          showToaster('created', 'positive');
          setClose(null);
        });
      } else {
        updateInlineOperation({
          variables: {
            id: item.id,
            operationSalesOffer: handleNewProductItem({
              qty,
              operationId,
              estimatedDeliveryDate,
              unitPrice: unitPrice || 0,
              unitsId,
            }),
          },
        }).then(() => {
          showToaster('updated', 'positive');
        });
      }
      handleResetForm();
    }
  };
  const handleResetForm = () => {
    if (item) {
      setClose(null);
    } else {
      setValue('operationId', undefined);
      setValue('estimatedDeliveryDate', [new Date()]);
      setValue('qty', '');
      setValue('unitsId', undefined);
    }
  };
  const [addInlineOperation] = useMutation(ADD_INLINE_OPERATION_SALES_OFFER);
  const [updateInlineOperation] = useMutation(UPDATE_OPERATION_SALES_OFFER);
  return (
    <InputForm
      register={register}
      errors={errors}
      setValue={setValue}
      control={control}
      watch={watch}
      handleAddOrUpdateItem={handleAddOrUpdateItem}
      item={item}
      handleResetForm={handleResetForm}
    />
  );
};
export default UpdateAddOperationSalesItems;
const handleNewProductItem = ({
  qty,
  operationId,
  estimatedDeliveryDate,
  unitPrice,
  unitsId,
}) => {
  return {
    estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
    operationId: operationId[0].id,
    unitsId: unitsId[0].id,
    qty: +qty,
    unitPrice: +unitPrice,
  };
};

