import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

interface GridColProps {
  title: string;
  fontSize?: FontSize;
  hidden?:boolean;
}
const GridCol: FunctionComponent<GridColProps> = ({ hidden=true,title, children,fontSize="sm" }) => {
  const intl = useIntl();
  const _hidden = hidden ? "lg:hidden":"";
  const _titleFontSize = titleFontSize(fontSize)
  return (
    <div className="flex flex-wrap items-center lg:space-x-1 print:space-x-1 space-x-3">
      <h3 className={`text-gray-900 text-${_titleFontSize} print:text-${_titleFontSize} font-medium print:hidden ${_hidden}`}>
        {intl.formatMessage({ id: title })}
      </h3>
      <span className={`lg:flex lg:flex-shrink-1  lg:px-0 px-2 py-0.5  text-${fontSize} print:text-${fontSize}   rounded-full`}>
        {children}
      </span>
    </div>
  );
};
export default GridCol;
type FontSize =
  | 'xs'
  | 'sm'
  | 'base'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | '4xl'
  | '5xl'
  | '6xl'
  | '7xl'
  | '8xl'
  | '9xl';
const titleFontSize = (fontSize: FontSize) => {
  const fontSizes = [
    'xs',
    'sm',
    'base',
    'lg',
    'xl',
    '2xl',
    '3xl',
    '4xl',
    '4xl',
    '5xl',
    '6xl',
    '7xl',
    '8xl',
    '9xl',
  ];
  const fonstSizeIndex = fontSizes.indexOf(fontSize);
  return fontSizes[fonstSizeIndex + 1] || fontSizes[fonstSizeIndex];
};
