import { Checkbox, LABEL_PLACEMENT } from "baseui/checkbox";
import React from "react";
import { FunctionComponent } from "react";
import { Controller } from "react-hook-form";
import { FormattedMessage } from "react-intl";

interface CheckboxCustomProps {
    control: any;
    name: string;
    style?:any;
}
const CheckboxCustom: FunctionComponent<CheckboxCustomProps> = ({ control, name,style }) => {
    return (
        <div
            style={{ display: 'flex', marginTop: '20px', alignItems: 'center', height: '100%',...style }}
        >
            <Controller
                as={
                    <Checkbox>
                        <FormattedMessage id={"checkbox." + name} />
                    </Checkbox>
                }
                name={name}
                type="checkbox"
                control={control}
                labelPlacement={LABEL_PLACEMENT.right}
                defaultValue={false}
            />
        </div>
    );
};
export default CheckboxCustom