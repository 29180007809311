import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import { OperationSalesOffer } from '../../../../types/OperationSalesOffer';
import { LiComponent, LiComponentKdv } from '../SubcontractSales/SubcontractSalesResult';
interface SubcontractSalesResultProps {
  offerTransport: OfferTransport[];
  items: OperationSalesOffer[];
  transportIncluded: boolean;
  preview?: boolean;
}
const OperationSalesResult: FunctionComponent<SubcontractSalesResultProps> = ({
  offerTransport,
  items,
  transportIncluded,
  preview = true,
}) => {
  const [transportTotalPrice, setTransportTotalPrice] = useState(0);
  useEffect(() => {
    setTransportTotalPrice(handleTransportTotal(offerTransport));
  }, [offerTransport]);
  const [
    { subTotal, taxTotal, grandTotal, profitTotal, discountTotal },
    setTotal,
  ] = useState({
    subTotal: 0,
    taxTotal: [],
    grandTotal: 0,
    profitTotal: 0,
    discountTotal: 0,
  });
  useEffect(() => {
    const {
      taxTotal,
      subTotal,
      grandTotal,
      discountTotal,
      profitTotal,
    } = handleCalculate(items);
    const newGrandTotal = transportIncluded
      ? grandTotal
      : grandTotal + transportTotalPrice;
    setTotal(() => ({
      subTotal: subTotal,
      taxTotal: taxTotal,
      grandTotal: newGrandTotal,
      discountTotal: discountTotal,
      profitTotal: profitTotal,
    }));
  }, [items, transportTotalPrice, transportIncluded]);
  return (
    <div className="grid grid-cols-6 print:grid-cols-6 place-items-end justify-items-end mt-20">
      <div className="sm:col-span-2 md:col-span-3 lg:col-span-4 print:col-span-4" />
      <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2 print:col-span-2 w-full">
        <ul className="divide-y divide-gray-200 w-full">
          <LiComponent price={subTotal} title={'subTotal'} />
          <LiComponent price={profitTotal} title={'profitTotal'} />
          <LiComponent price={discountTotal} title={'discountTotal'} />
          {taxTotal.map((item) => (
            <LiComponentKdv key={item?.kdv} price={item?.taxAmount | 0} title={'kdv'} kdv={item?.kdv} />
          ))}
          {!transportIncluded && (
            <LiComponent price={transportTotalPrice} title={'transportTotal'} />
          )}
          <LiComponent price={grandTotal} title={'grandTotal'} />
        </ul>
      </div>
    </div>
  );
};
export default OperationSalesResult;
const handleTransportTotal = (offerTransport: OfferTransport[]) =>
  offerTransport.reduce(
    (acc: number, item: OfferTransport) =>
      item.approved === true ? acc + item.price : 0,
    0
  );
const handleCalculate = (items: OperationSalesOffer[]) => {
  const kdvList = items
  .map((item) => {
    return item.operationSalesOfferPrice?.taxRate || 0;
  })
  .filter((value, index, self) => self.indexOf(value) === index);
  const subTotal = items.reduce((acc: number, item: OperationSalesOffer) => {
    const { qty, unitPrice } = item;
    return acc + qty * unitPrice;
  }, 0);
  const profitTotal = items.reduce((acc: number, item: OperationSalesOffer) => {
    const { qty, unitPrice, operationSalesOfferPrice } = item;
    const profitAmount =
      (qty * unitPrice * (operationSalesOfferPrice?.profitRate || 1)) / 100;
    return acc + profitAmount;
  }, 0);
  const discountTotal = items.reduce((acc: number, item: OperationSalesOffer) => {
    const { qty, unitPrice, operationSalesOfferPrice } = item;
    const profitAmount =
      (qty * unitPrice * (operationSalesOfferPrice?.profitRate || 1)) / 100;
    const discountAmount =
      ((qty * unitPrice + profitAmount) * (operationSalesOfferPrice?.discountRate || 1)) /
      100;
    return acc + discountAmount;
  }, 0);
  const taxTotal = kdvList.map((kdv)=>{
    const taxAmount = items.reduce((acc: number, item: OperationSalesOffer) => {
      if(item?.operationSalesOfferPrice?.taxRate === kdv){
        const { qty, unitPrice, operationSalesOfferPrice } = item;
        const profitAmount = qty * unitPrice * (operationSalesOfferPrice?.profitRate || 1) / 100;
        const discountAmount = ((qty * unitPrice) + profitAmount) * (operationSalesOfferPrice?.discountRate || 1) / 100;
        const taxeAmount = ((qty * unitPrice) + profitAmount - discountAmount) * (operationSalesOfferPrice?.taxRate || 1) / 100;
        return acc + taxeAmount;
      } else{
        return acc + 0;
      }
    }, 0);
    return {
      kdv,
      taxAmount: taxAmount,
    };
  })
  const grandTotal = subTotal + taxTotal.reduce((acc, i) => acc + i.taxAmount, 0);
  return {
    subTotal: subTotal,
    taxTotal: taxTotal,
    grandTotal: grandTotal,
    profitTotal: profitTotal,
    discountTotal: discountTotal,
  };
};
