import React, { FunctionComponent, useReducer, useState } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Col,
  ColButton,
  DivSubmit,
  RowCustom,
} from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import SelectOperation from '../../../SelectCustom/SelectOperation';
import ListOutput from '../InOutput/ListOutput';
import ListInput from '../InOutput/ListInput';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';
import { Card } from '../../../../components/FormWithElemets/Card.style';
import { inputDecimal } from '../../../Functions/Utilities';
import CheckboxCustom from '../../../CheckboxCustom/CheckboxCustom';
const ADD_EXECUTION_PLANNING_STEP = loader(
  '../../../../graphql/executionPlanningStep/ADD_EXECUTION_PLANNING_STEP.graphql'
);
export type InputType = {
  inventoryId: [{ id: string; label: string }];
  inventoryTypeId: string;
  name: string;
};
export type OutputType = {
  inventoryTypeId: [{ id: string; label: string }];
  name: string;
  unitsId: [{ id: string; label: string }];
};
export type ExecutionPlannigStep = {
  executionPlanStepInput: InputType[];
  executionPlanStepOutput: OutputType[];
};
type Action =
  | { type: 'INPUT'; item: InputType[] }
  | { type: 'OUTPUT'; item: OutputType[] };
const reducer = (state: ExecutionPlannigStep, action: Action) => {
  switch (action.type) {
    case 'INPUT':
      return {
        ...state,
        executionPlanStepInput: action.item,
      };
    case 'OUTPUT':
      return {
        ...state,
        executionPlanStepOutput: action.item,
      };
    default:
      return state;
  }
};
interface StepProps {
  setItemAddShow?: Function;
  executionPlanId: string;
}
const Step: FunctionComponent<StepProps> = ({ setItemAddShow, executionPlanId }) => {
  const intl = useIntl();
  const [items, dispatch] = useReducer(reducer, {
    executionPlanStepInput: [],
    executionPlanStepOutput: [],
  });
  const [addShow, setAddShow] = useState({
    input: false,
    output: false,
  });
  const { control, errors, handleSubmit, register, setValue, watch } = useForm();
  const { external } = watch();
  const [add] = useMutation(ADD_EXECUTION_PLANNING_STEP);
  const onSubmit = ({ name, operationId, duration, external }) => {
    add({
      variables: {
        executionPlanId: executionPlanId,
        name: name,
        duration: duration ? +duration : 0,
        operationId: operationId[0].id,
        external: external,
        executionPlanStepInput: handleDataInputNew(items.executionPlanStepInput),
        executionPlanStepOutput: handleDataOutputNew(items.executionPlanStepOutput),
      },
    }).then(() => {
      setItemAddShow(false);
    });
  };
  const buttons = [
    {
      label: intl.formatMessage({ id: 'executionPlanning.step_inputs' }),
      function: () => {
        setAddShow({
          output: false,
          input: true,
        });
      },
      check_type: '',
    },
    {
      label: intl.formatMessage({ id: 'executionPlanning.step_outputs' }),
      function: () => {
        setAddShow({
          input: false,
          output: true,
        });
      },
      check_type: '',
    },
  ];
  return (
    <Card>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <RowCustom>
          <Col xs={12} md={6} lg={4}>
            <FormControl label={<FormattedMessage id="executionPlanning.stepName" />}>
              <Input
                type="text"
                size={SIZE.compact}
                name="name"
                inputRef={register({
                  required: true,
                })}
                clearOnEscape
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <SelectOperation errors={errors} control={control} />
          </Col>
          <Col xs={8} md={6} lg={2} style={{ marginBottom: '20px' }}>
            <CheckboxCustom control={control} name="external" />
          </Col>
          {!external && (
            <Col xs={8} md={6} lg={2}>
              <FormControl label={<FormattedMessage id="executionPlanning.duration" />}>
                <Input
                  type="text"
                  size={SIZE.compact}
                  name="duration"
                  inputRef={register({
                    required: true,
                  })}
                  onChange={(event: any) => {
                    setValue('duration', inputDecimal({ event }));
                  }}
                  clearOnEscape
                />
              </FormControl>
            </Col>
          )}
          <ColButton xs>
            <div style={{ marginTop: '18px' }}>
              <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
            </div>
          </ColButton>
        </RowCustom>
        {addShow.input && (
          <ListInput
            dispatchExecutionPlannigStep={dispatch}
            initialState={items.executionPlanStepInput}
            setAddShow={setAddShow}
          />
        )}
        {addShow.output && (
          <ListOutput
            dispatchExecutionPlannigStep={dispatch}
            initialState={items.executionPlanStepOutput}
            setAddShow={setAddShow}
          />
        )}
        {!addShow.input && !addShow.output && (
          <DivSubmit style={{ marginTop: '30px' }}>
            <Button type="submit">
              <FormattedMessage id="button.save" />
            </Button>
          </DivSubmit>
        )}
      </Form>
    </Card>
  );
};
export default Step;
const handleDataInputNew = (items: InputType[]) => {
  return items.map((item) => {
    return {
      name: item.name,
      inventoryId: item.inventoryId[0].id,
      inventoryTypeId: item.inventoryTypeId,
    };
  });
};
const handleDataOutputNew = (items: OutputType[]) => {
  return items.map((item) => {
    return {
      name: item.name,
      inventoryTypeId: item.inventoryTypeId[0].id,
      unitsId: item.unitsId[0].id,
    };
  });
};
