import { useMutation, useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useToaster } from '../../../../context/toaster-provider';
import ListItemSubcontractSalesItem from '../../SubContractSales/ListItemSubcontractSalesItem';
import ItemHeaders from '../../SubContractSales/ItemHeaders';
import { Item as ItemType } from '../../SubContractSales/AddSubContractSalesForm';
import UpdateAddSubcontractSalesItem from '../../ToAssesment/SubContractSales/UpdateAddSubcontractSalesItem';
import AddBillOfMaterialForm from '../../../BillOfMaterial/AddBillOfMaterialForm';
import BillOfMaterial from '../../../BillOfMaterial/PurchaseOffer/PurchaseOperationOffer/BillOfMaterial';
import { SubcontractSalesOfferIDs } from '../../../../types/SubcontractSalesOfferID';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
import Button from '../../../TailWinduiHTML/Form/Button';
const DELETE_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/DELETE_SUBCONTRACT_SALES_OFFER.graphql'
);
const GET_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/GET_SUBCONTRACT_SALES_OFFER.graphql'
);
const S_GET_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/S_GET_SUBCONTRACT_SALES_OFFER.graphql'
);
export type Status = {
  status: 'AddSubcontractSalesOffer' | 'AddBillOfMaterial' | 'UpdateBillOfMaterial';
  billOfMaterialName: '';
  billOfMaterialId: '';
  orderQty?: 0;
};

interface ListSubcontractSalesProps {
  offerId: string;
}
const ListSubcontractSales: FunctionComponent<ListSubcontractSalesProps> = ({
  offerId,
}) => {
  const { showToaster } = useToaster();
  const { subscribeToMore, data, loading, error } = useQuery<SubcontractSalesOfferIDs>(
    GET_SUBCONTRACT_SALES_OFFER,
    {
      variables: {
        id: offerId,
      },
    }
  );
  useEffect(() => {
    subscribeToMore({
      document: S_GET_SUBCONTRACT_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.subcontractSalesOffer;
        return Object.assign({}, prev, {
          subcontractSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);
  const [itemIndex, setItemIndex] = useState(null);
  const [newItems, setNewItems] = useState<ItemType[]>([]);
  const [itemAddShow, setItemAddShow] = useState(false);
  const [subcontractSalesStatus, setSubcontractSalesStatus] = useState<Status>({
    status: 'AddSubcontractSalesOffer',
    billOfMaterialName: '',
    billOfMaterialId: '',
    orderQty: 0,
  });
  const newItemsLength = useMemo(() => {
    return data?.subcontractSalesOffer?.subcontractSalesOffer?.length || 0;
  }, [data]);
  useEffect(() => {
    if (data) {
      setNewItems(() => itemsEdit(data.subcontractSalesOffer.subcontractSalesOffer));
      setItemAddShow(() => (newItemsLength > 0 ? false : true));
    }
  }, [data]);
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
  }, []);
  const [remove] = useMutation(DELETE_SUBCONTRACT_SALES_OFFER);
  const handleItemDelete = useCallback(
    (index: number) => {
      if (window.confirm('Are you sure !')) {
        remove({
          variables: { id: newItems[index].id },
        }).then(() => {
          showToaster('deleted', 'positive');
        });
      }
    },
    [newItems, remove, showToaster]
  );
  const handleCompleted = ({ billOfMaterialId, billOfMaterialName }) => {
    setSubcontractSalesStatus({
      status: 'AddSubcontractSalesOffer',
      billOfMaterialName: billOfMaterialName,
      billOfMaterialId: billOfMaterialId,
    });
  };
  const pageEndRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (pageEndRef?.current) {
      setTimeout(() => {
        pageEndRef?.current.scrollIntoView({
          behavior: "smooth",
        });
      },500);
    }
  }, [pageEndRef, subcontractSalesStatus]);
  return loading ? (
    <StyledSpinnerNext />
  ) : error ? (
    <NoResult />
  ) : (
    data && (
      <Fragment>
        <div
          style={
            subcontractSalesStatus.status !== 'AddSubcontractSalesOffer'
              ? { display: 'none' }
              : {}
          }
        > 
          <ItemHeaders
            itemsLenght={newItemsLength}
            setItemAddShow={setItemAddShow}
            itemAddShow={itemAddShow}
          />
          {itemAddShow && (
            <UpdateAddSubcontractSalesItem
              offerId={offerId}
              itemIndex={itemIndex}
              setItemIndex={setItemIndex}
              setClose={setItemAddShow}
              setSubcontractSalesStatus={setSubcontractSalesStatus}
              subcontractSalesStatus={subcontractSalesStatus}
            />
          )}
          {newItems &&
            newItems.map((item: ItemType, index: number) => {
              return index !== itemIndex ? (
                <ListItemSubcontractSalesItem
                  key={index}
                  item={item}
                  index={index}
                  handleItemDelete={handleItemDelete}
                  handleItemEdit={handleItemEdit}
                  setSubcontractSalesStatus={setSubcontractSalesStatus}
                />
              ) : (
                <UpdateAddSubcontractSalesItem
                  key={index}
                  item={item}
                  itemIndex={itemIndex}
                  setItemIndex={setItemIndex}
                  setSubcontractSalesStatus={setSubcontractSalesStatus}
                  subcontractSalesStatus={subcontractSalesStatus}
                />
              );
            })}
        </div>
        {subcontractSalesStatus.status === 'AddBillOfMaterial' && (
          <AddBillOfMaterialForm
            billOfMaterialName={subcontractSalesStatus.billOfMaterialName}
            handleSaveClose={handleCompleted}
          />
        )}
        {subcontractSalesStatus.status === 'UpdateBillOfMaterial' && (
          <BillOfMaterial
            billOfMaterialId={subcontractSalesStatus.billOfMaterialId}
            handleCompleted={handleCompleted}
            offerId={offerId}
            orderQty={subcontractSalesStatus.orderQty}
            offerPurchaseOffer={data?.subcontractSalesOffer?.offerPurchaseOffer}
          />
        )}
        <div ref={pageEndRef} />
      </Fragment>
    )
  );
};
export default ListSubcontractSales;

const itemsEdit = (itemTemps: any[]) => {
  return itemTemps.map((item: any) => {
    if (item?.billOfMaterial?.inventory) {
      return {
        id: item.id,
        billOfMaterialId: [
          {
            id: item.billOfMaterial.id,
            label: item.billOfMaterial.inventory.name,
          },
        ],
        unitsId: item.billOfMaterial.inventory.units
          ? [
              {
                id: item.billOfMaterial.inventory.units.id,
                label: item.billOfMaterial.inventory.units.name,
              },
            ]
          : undefined,
        estimatedDeliveryDate: item.estimatedDeliveryDate,
        qty: item.qty,
      };
    } else {
      return {
        id: item.id,
        billOfMaterialId: [
          { id: item.billOfMaterial.id, label: item.billOfMaterial.name },
        ],
        unitsId: [{ id: item.units.id, label: item.units.name }],
        estimatedDeliveryDate: item.estimatedDeliveryDate,
        qty: item.qty,
      };
    }
  });
};
