import { faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FunctionComponent, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import { LabelCustom } from '../../../components/FormWithElemets/Label.style';
import {  handleLocalDateString } from '../../Functions/Utilities';
import { Item as ItemType } from './OperationSalesOfferAdd';

interface ListItemOperationSalesItemProps {
  item: ItemType;
  index: number;
  handleItemDelete: Function;
  handleItemEdit: Function;
}
const ListItemOperationSalesItem: FunctionComponent<ListItemOperationSalesItemProps> = memo(
  ({ item, index, handleItemDelete, handleItemEdit }) => {
    return (
      <RowCustom>
        <Col xs={4}>
          <LabelCustom title={<FormattedMessage id="offer.item" />}>
            {item.operationId ? item.operationId[0]?.label : ''}
          </LabelCustom>
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.estimatedDeliveryDate" />}>
            {handleLocalDateString(item?.estimatedDeliveryDate)}
          </LabelCustom>
        </Col>
        <Col xs={3}>
          <LabelCustom title={<FormattedMessage id="offer.qty" />}>
            {item.qty} &nbsp; {item.unitsId[0].label}
          </LabelCustom>
        </Col>
        {/* <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
            {item.unitPrice}
          </LabelCustom>
        </Col> */}
        <ColButton xs={2}>
          <Button
            type="button"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
            onClick={() => {
              handleItemDelete(index);
            }}
          >
            <FontAwesomeIcon icon={faTrashAlt} size="1x" />
          </Button>
          <Button
            type="button"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
            onClick={() => handleItemEdit(index)}
          >
            <FontAwesomeIcon icon={faEdit} size="1x" />
          </Button>
        </ColButton>
      </RowCustom>
    );
  }
);
export default ListItemOperationSalesItem;
