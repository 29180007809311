import React, {
    FunctionComponent,
    useEffect,
    useState,
} from 'react';
import { useQuery } from '@apollo/react-hooks';
import NoResult from '../../../../components/NoResult/NoResult';
import { loader } from 'graphql.macro';
import { Can } from '../../../Layout/Layout';
import { StyledSpinnerNext } from 'baseui/spinner';
import { Card, CardBody, CardTitle } from '../../../../components/FormWithElemets/Card.style';
import { BillOfMaterial as BillOfMaterialType } from '../../../../types/BillOfMaterial';
import Button, { COLOR } from '../../../../components/FormWithElemets/Button/Button';
import { FormattedMessage } from 'react-intl';
import { OfferPurchaseOffer } from '../../../../types/Offer';
import ItemBillOfMaterial from './ItemBillOfMaterial';
import BreadcrumbsCustom from './BreadcrumbsCustom';

const GET_BILL_OF_MATERIAL = loader(
    '../../../../graphql/billOfMaterial/GET_BILL_OF_MATERIAL.graphql'
);
const S_GET_BILL_OF_MATERIAL = loader(
    '../../../../graphql/billOfMaterial/S_GET_BILL_OF_MATERIAL.graphql'
);

interface BillOfMaterialProps {
    billOfMaterialId: string;
    handleCompleted: Function;
    offerPurchaseOffer: OfferPurchaseOffer;
    offerId: string
    orderQty?: number
}
const BillOfMaterial: FunctionComponent<BillOfMaterialProps> = ({
    billOfMaterialId,
    handleCompleted,
    offerPurchaseOffer,
    offerId,
    orderQty,
}) => {
    const { subscribeToMore, data, error, loading } = useQuery<BillOfMaterialType>(
        GET_BILL_OF_MATERIAL,
        {
            variables: {
                id: billOfMaterialId,
            },
        }
    );
    const [componentStatus, setComponentStatus] = useState("billOfMaterialItem");
    useEffect(() => {
        subscribeToMore({
            document: S_GET_BILL_OF_MATERIAL,
            variables: {
                id: billOfMaterialId,
            },
            updateQuery: (prev, { subscriptionData }) => {
                if (!subscriptionData.data) return prev;
                const newFeedItem = subscriptionData.data.billOfMaterial;
                return Object.assign({}, prev, {
                    billOfMaterial: newFeedItem,
                });
            },
        });
    }, [subscribeToMore, billOfMaterialId]);

    return (
        <Can I="LIST" a="BILL-OF-MATERIALS">
            <Card>
                <CardTitle
                    title={<BreadcrumbsCustom componentStatus={componentStatus} />}
                >
                    <Button
                        onClick={() =>
                            handleCompleted({
                                billOfMaterialId: data.billOfMaterial.id,
                                billOfMaterialName:
                                    data.billOfMaterial?.inventory?.name || data.billOfMaterial?.name,
                            })
                        }
                        color={COLOR.green}
                    >
                        <FormattedMessage id={'button.completed'} />
                    </Button>
                </CardTitle>
                <CardBody
                    style={{ margin: '5px', padding: 0 }}
                >
                    {loading ? (
                        <StyledSpinnerNext />
                    ) : error ? (
                        <NoResult />
                    ) : (
                                <ItemBillOfMaterial
                                    item={data?.billOfMaterial}
                                    offerId={offerId}
                                    offerPurchaseOffer={offerPurchaseOffer}
                                    orderQty={orderQty}
                                    componentStatus={componentStatus}
                                    setComponentStatus={setComponentStatus}
                                />
                            )
                    }
                </CardBody>
            </Card>
        </Can>
    );
};

export default BillOfMaterial;

