import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import { BillOfMaterialItem } from '../../../../types/BillOfMaterial';
import { useToaster } from '../../../../context/toaster-provider';
import BillOfMaterialItemForm from '../../BillOfMaterialItem/UpdateOrAdd/BillOfMaterialItemForm';
import ListItemBillOfMaterialItem from './ListItemBillOfMaterialItem';
import BillOfMaterialItemHeaders from '../../BillOfMaterialItem/BillOfMaterialItemHeaders';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { PurchaseOffer } from '../../../../types/Purchase';
const DELETE_BILL_OF_MATERIAL_ITEM = loader(
    '../../../../graphql/billOfMaterialItem/DELETE_BILL_OF_MATERIAL_ITEM.graphql'
);
interface ListBillOfMaterialItemProps {
    items: BillOfMaterialItem[];
    billOfMaterialId: string;
    offerId: string;
    orderQty: number;
    purchaseOffer:PurchaseOffer
}
const ListBillOfMaterialItem: FunctionComponent<ListBillOfMaterialItemProps> = ({
    items,
    billOfMaterialId,
    offerId,
    orderQty,
    purchaseOffer
}) => {
    const { showToaster } = useToaster();
    const [itemId, setItemId] = useState(null);
    const [itemAddShow, setItemAddShow] = useState(() => (items.length > 0 ? false : true));
    const handleItemEdit = useCallback((id: string) => {
        setItemId(id);
        setItemAddShow(false);
    }, []);
    const [remove] = useMutation(DELETE_BILL_OF_MATERIAL_ITEM);
    const handleItemDelete = useCallback((id: number) => {
        if (window.confirm('Are you sure !')) {
            remove({
                variables: { id: id },
            }).then(() => {
                showToaster('deleted', 'positive');
            });
        }
    }, [remove, showToaster]);
    return (
        <CardBody style={{ marginLeft: '20px' }}>
            <BillOfMaterialItemHeaders
                itemsLenght={items?.length}
                itemAddShow={itemAddShow}
                setItemAddShow={setItemAddShow}
            />
            {itemAddShow && (
                <BillOfMaterialItemForm
                    billOfMaterialId={billOfMaterialId}
                    setItemAddShow={setItemAddShow}
                />
            )}
            {items &&
                items.map((item: BillOfMaterialItem) => {
                    return item.id !== itemId ? (
                        <ListItemBillOfMaterialItem
                            key={item.id}
                            item={item}
                            handleItemDelete={handleItemDelete}
                            handleItemEdit={handleItemEdit}
                            offerId={offerId}
                            orderQty={orderQty}
                            purchaseOffer={purchaseOffer}
                        />
                    ) : (
                            <BillOfMaterialItemForm key={item.id} item={item} setItemId={setItemId} />
                        );
                })}
        </CardBody>
    );
};
export default ListBillOfMaterialItem;
