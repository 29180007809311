import _ from 'lodash';
import { Company } from '../../types/Company';

export const handleDate = (date: Date) => {
  //Saati 3 saat ileri alıyor
  const dateTemp = new Date(`${date.toString().split('GMT')[0]} UTC`);
  return dateTemp;
};
export const handleDateLocal = (date: string) => {
  const localTime = handleDate(new Date(date)).toLocaleTimeString('tr-TR', {
    hour: '2-digit',
    minute: '2-digit',
  })
  return (
    handleDate(new Date(date)).toLocaleDateString('tr-TR') +
    ' ' +
    (localTime[3] === ':' ? ("0" + localTime) : localTime )
  );
};
export const handleCompanyInformation = (Id: string, data: any) => {
  let state = {
    companyId: '',
    companyName:'',
    address: '',
    county: '',
    city: '',
    taxNumber: '',
    taxOffice: '',
    currency: '',
  };
  data.forEach((item: Company) => {
    const { id, companyAddress, taxNumber, currency, taxOffice,companyName } = item;
    if (id.toString() === (Id || '0')) {
      let currencyTemp = handleCurrency(currency);
      state = {
        companyId: Id,
        companyName:companyName,
        address: companyAddress[0] ? companyAddress[0].address : '',
        county: companyAddress[0] ? companyAddress[0].county : '',
        city: companyAddress[0] ? companyAddress[0].city : '',
        taxNumber: taxNumber,
        taxOffice: taxOffice,
        currency: currencyTemp || '₺',
      };
    }
  });
  return state;
};
//*****************************************************************//
type TaxRate = {
  id: string;
  label: string;
};
type Inventory = {
  id: string;
  label: string;
};
interface InvoiceDetail {
  id: string;
  description: string;
  qty: number;
  inventoryId: string | Inventory[];
  amount: number;
  discountRate: number;
  taxRate: number | TaxRate[];
  unitPrice: number;
}
export const handleInvoiceCalculation = (data: InvoiceDetail[]) => {
  let subtotal: number = 0;
  let discountTotal: number = 0;
  let taxAmountEight: number = 0;
  let taxAmountOne: number = 0;
  let taxAmountOneTotal: number = 0;
  let taxAmountEightTotal: number = 0;
  let taxAmountTen: number = 0;
  let taxAmountTenTotal: number = 0;
  let taxAmountEighteen: number = 0;
  let taxAmountEighteenTotal: number = 0;
  let total: number = 0;
  data.forEach((item) => {
    const { amount, discountRate, taxRate, qty, unitPrice } = item;
    let taxRateTemp = taxRate[0] ? taxRate[0].id : taxRate;
    let amountTemp = qty * unitPrice;
    subtotal += amount;
    discountTotal += Number(((discountRate * amountTemp) / 100).toFixed(2));
    if (taxRateTemp.toString() === '18') {
      taxAmountEighteen += Number(((taxRateTemp * amount) / 100).toFixed(2));
      taxAmountEighteen = Number(taxAmountEighteen.toFixed(2));
      taxAmountEighteenTotal = Number((taxAmountEighteenTotal + unitPrice).toFixed(2));
    } else if (taxRateTemp.toString() === '10') {
      taxAmountTen += Number(((taxRateTemp * amount) / 100).toFixed(2));
      taxAmountTen = Number(taxAmountTen.toFixed(2));
      taxAmountTenTotal = Number((taxAmountTenTotal + unitPrice).toFixed(2));
    } else if (taxRateTemp.toString() === '8') {
      taxAmountEight += Number(((taxRateTemp * amount) / 100).toFixed(2));
      taxAmountEight = Number(taxAmountEight.toFixed(2));
      taxAmountEightTotal = Number((taxAmountEightTotal + unitPrice).toFixed(2));
    } else if (taxRateTemp.toString() === '1') {
      taxAmountOne += Number(((taxRateTemp * amount) / 100).toFixed(2));
      taxAmountOne = Number(taxAmountOne.toFixed(2));
      taxAmountOneTotal = Number((taxAmountOneTotal + unitPrice).toFixed(2));
    }
    total = Number((amount + Number((taxRateTemp * amount) / 100) + total).toFixed(2));
  });
  return {
    subtotal,
    discountTotal,
    taxAmountEight,
    taxAmountTen,
    taxAmountEighteen,
    taxAmountOne,
    taxAmountOneTotal,
    taxAmountEightTotal,
    taxAmountTenTotal,
    taxAmountEighteenTotal,
    total,
  };
};

export const reducerFunction = (state: any[], action: any) => {
  switch (action.type) {
    case 'ADD':
      return [...state, action.item];
    case 'UPDATE':
      return state.map((item, index) => {
        if (index === action.index) {
          return {
            ...action.item,
          };
        }
        return item;
      });
    case 'DELETE':
      return state.filter((item, index) => index !== action.index);
    case 'RESET':
      return [];
    default:
      return state;
  }
};
export const inputDecimal = ({ event }: any): number => {
  if (
    event.target.value.replace(
      /[^a-zA-ZIİçÇşŞüÜöÖğĞ!@#$%^&*()_\-\+\=|\\\?/\[\]{}:;\"\'\~\`<>]/g, //eslint-disable-line
      ''
    ).length === 0 &&
    event.target.value.replace(/[^\,\.]/g, '').length < 2 //eslint-disable-line
  ) {
    const value = event.target.value.replace(',', '.');
    if (value.slice(-1) !== '.' && value.slice(-2) !== '.' && value.slice(-1) !== '0') {
      return Number(value);
    } else {
      return value;
    }
  } else {
    return event.target.value.slice(0, -1);
  }
};
export function difference(number1: number, number2: number) {
  return parseFloat((number1 - number2).toFixed(10));
}
export function sum(number1: number, number2: number) {
  return parseFloat((number1 + number2).toFixed(10));
}
export const Collection = ({ array, itemName }) =>
  array?.reduce((acc: number, itm: any) => acc + _.get(itm, itemName), 0);
export const debounce = (fn: any, delay: number = 500) => {
  let timeoutId: any;
  function debounced(...args: any) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      fn(...args);
    }, delay);
  }
  return debounced;
};

///*****************************************//
export const handleLocalDateString = (date: string) => {
  return new Date(date).toLocaleDateString('tr');
};
export const handleCurrency = (currency:string) => {
  return currency == 'TRY' ? '₺' : currency === 'EUR' ? '€' : currency === 'USD' ? '$' : '₺'; 
}
export const handleCeil = (value: number) => {
  return Math.ceil(100 * value) / 100
}
export const handleDateBigger = ({ data, name }) => {
  console.log("--->,data",data)
  const dataBigger =data ?  new Date(
    Math.max(...data.map((item:any) => new Date(_.get(item, name))))
  ).toString() : new Date().toString();
  return handleLocalDateString(dataBigger)
};