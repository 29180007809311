import React from 'react';
import { styled } from 'baseui';

export const Content = styled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '3px',
  boxShadow: '0px 0px 0.3px 0.3px rgba(204,206,204,1)',
  overflow: 'hidden',
  padding: '3px 0px',
  ':hover': {
    backgroundColor: '#fbfbfb',
    transform: 'translateY(2px)',
  },
}));
export const Div = styled('div', () => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  borderRadius: '3px',
  boxShadow: '0px 0px 0.3px 0.3px rgba(215,218,215,1)',
  overflow: 'hidden',
  padding: '3px',
}));
type BoxProps = {
  children: React.ReactNode;
  style?: any;
  click?: any;
};

export const Box: React.FC<BoxProps> = ({ children, style }: any) => {
  return <Div style={style}>{children}</Div>;
};
export const BoxContent: React.FC<BoxProps> = ({ children, style }) => {
  return <Content style={style}>{children}</Content>;
};
