import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import FormControl from '../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../components/Input/Input';
import { useToaster } from '../../../context/toaster-provider';
import { ExecutionPlanStep } from '../../../types/ExecutionPlan';
import CheckboxCustom from '../../CheckboxCustom/CheckboxCustom';
import { inputDecimal } from '../../Functions/Utilities';
import SelectOperation from '../../SelectCustom/SelectOperation';
import { ComponentStatusContext } from '../ExecutionPlanning/ExecutionPlanning';
const UPDATE_EXECUTION_PLANNING_STEP = loader(
  '../../../graphql/executionPlanningStep/UPDATE_EXECUTION_PLANNING_STEP.graphql'
);
const ADD_EXECUTION_PLANNING_STEP = loader(
  '../../../graphql/executionPlanningStep/ADD_EXECUTION_PLANNING_STEP.graphql'
);
interface ItemFormProps {
  item?: ExecutionPlanStep;
  setItemAddShow?: Function;
  setItemId?: Function;
}
const ItemForm: FunctionComponent<ItemFormProps> = ({
  item,
  setItemId,
  setItemAddShow,
}) => {
  const { executionPlanningId } = useContext(ComponentStatusContext);
  const { control, errors, handleSubmit, setValue, register } = useForm({
    defaultValues: item
      ? {
        name: item.name,
        operationId: [{ id: item.operation.id, label: item.operation.name }],
        duration: item?.duration || 0,
        external: item?.external || false
      }
      : {},
  });
  const { showToaster } = useToaster();
  const [update] = useMutation(UPDATE_EXECUTION_PLANNING_STEP);
  const [add] = useMutation(ADD_EXECUTION_PLANNING_STEP);
  const onSubmit = ({ name, operationId, duration, external }) => {
    if (item) {
      update({
        variables: {
          id: item.id,
          name: name,
          operationId: operationId[0].id,
          duration: +duration,
          external: external
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
    } else {
      add({
        variables: {
          executionPlanningId: executionPlanningId,
          name: name,
          operationId: operationId[0].id,
          duration: +duration,
          external: external
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleResetForm();
      });
    }
  };
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('name', '');
      setValue('duration', 0);
      setValue('operationId', undefined);
      setValue('external', false);
      setItemAddShow(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom>
        <Col xs={12} md={6} lg={4}>
          <FormControl label={<FormattedMessage id="executionPlanning.stepName" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <Col xs={12} md={6} lg={4}>
          <SelectOperation errors={errors} control={control} />
        </Col>
        <Col xs={8} md={6} lg={2}>
          <FormControl label={<FormattedMessage id="executionPlanning.duration" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="duration"
              inputRef={register({
                required: true,
              })}
              onChange={(event: any) => {
                setValue('duration', inputDecimal({ event }));
              }}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <Col xs={8} md={6} lg={2} style={{marginBottom:'20px'}}>
          <CheckboxCustom control={control} name="external" />
        </Col>
        <ColButton xs>
          <Button
            style={{
              marginTop: '30px',
            }}
            type="button"
            onClick={() => handleResetForm()}
          >
            <FormattedMessage id="button.close" />
          </Button>
          <Button
            style={{
              marginTop: '30px',
            }}
            type="submit"
          >
            <FormattedMessage id={item ? 'button.update' : 'button.add'} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};

export default ItemForm;
