import { faArrowAltDown, faArrowAltUp, faEdit, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, FunctionComponent, memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { ProductSalesOffer } from '../../../../types/ProductSalesOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';
import ProductSalesPrice from './ProductSalesPrice/ProductSalesPrice';

interface ItemProps {
  item: ProductSalesOffer;
  handleItemDelete: Function;
  handleItemEdit: Function;
  transportUnitPrice: number;
}
const ListItemProductSales: FunctionComponent<ItemProps> = memo(
  ({ item, handleItemDelete, handleItemEdit, transportUnitPrice }) => {
    const [productSalesOfferId,setProductSalesOfferId] =  useState(null);
    return (
      <Fragment>
        <RowCustom>
          <Col xs={4}>
            <LabelCustom title={<FormattedMessage id="offer.item" />}>
              {item?.product?.name || 'No Item...'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom
              title={<FormattedMessage id="offer.estimatedDeliveryDate" />}
            >
              {handleLocalDateString(item.estimatedDeliveryDate)}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.qty" />}>
              {item?.qty} &nbsp;{' '}
              {item.product?.inventory?.units?.name || 'No Units'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
              {(item?.unitPrice + transportUnitPrice || 0).toFixed(2) + ' ₺'}
            </LabelCustom>
          </Col>
          <Col xs={2}>
            <LabelCustom title={<FormattedMessage id="offer.amount" />}>
              {((item?.unitPrice + transportUnitPrice) * item.qty).toFixed(2) +
                ' ₺'}
            </LabelCustom>
          </Col>
          <ColButton xs>
            <Button
              type="button"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
              onClick={() => {
                handleItemDelete(item.id);
              }}
            >
              <FontAwesomeIcon icon={faTrashAlt} size="1x" />
            </Button>
            <Button
              type="button"
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
              onClick={() => handleItemEdit(item.id)}
            >
              <FontAwesomeIcon icon={faEdit} size="1x" />
            </Button>
            <Button
              type="button"
              onClick={()=>setProductSalesOfferId((prev:any) => prev === null ? item.id : null)}
              style={{ paddingLeft: '15px', paddingRight: '15px' }}
            >
              <FontAwesomeIcon
                icon={productSalesOfferId ? faArrowAltUp : faArrowAltDown}
                size="1x"
              />
            </Button>
          </ColButton>
        </RowCustom>
        <CardBody style={item.id === productSalesOfferId ? {} : { display: 'none' }}>
          <ProductSalesPrice  item={item} transportUnitPrice={transportUnitPrice}/>
        </CardBody>
      </Fragment>
    );
  }
);
export default memo(ListItemProductSales);
