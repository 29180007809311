import { Input, SIZE } from 'baseui/input';
import React, { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import { OperationSalesOffer } from '../../../../types/OperationSalesOffer';
import DateCustom from '../../../DateCustom/DateCustom';
import { inputDecimal } from '../../../Functions/Utilities';
import SelectOperation from '../../../SelectCustom/SelectOperation';
import SelectUnit from '../../../SelectCustom/SelectUnit';

interface InputFormProps {
  item: OperationSalesOffer;
  register: any;
  errors: any;
  setValue?: any;
  control: any;
  handleResetForm: Function;
}
const InputForm: FunctionComponent<InputFormProps> = ({
  item,
  control,
  errors,
  setValue,
  register,
  handleResetForm,
}) => {
  return (
    <RowCustom bottom="xs">
      <Col xs={4}>
        <SelectOperation control={control} disabled={true}/>
      </Col>
      <Col xs={2}>
        <DateCustom
          errors={errors}
          control={control}
          name="estimatedDeliveryDate"
          dateValue={item?.estimatedDeliveryDate}
        />
      </Col>
      <Col xs={2}>
        <FormControl label={<FormattedMessage id="offer.qty" />}>
          <Input
            type="text"
            name="qty"
            inputRef={register}
            size={SIZE.compact}
            onChange={(event) => {
              setValue('qty', inputDecimal({ event }));
            }}
          />
        </FormControl>
      </Col>
      <Col xs={2}>
        <SelectUnit control={control} disabled={true}/>
      </Col>
      <Col xs={2}>
        <FormControl label={<FormattedMessage id="offer.unitPrice" />}>
          <Input
            type="text"
            name="unitPrice"
            size={SIZE.compact}
            inputRef={register}
            onChange={(event) => {
              setValue('unitPrice', inputDecimal({ event }));
            }}
          />
        </FormControl>
      </Col>
      <ColButton xs={2}>
        <Button
          type="button"
          onClick={() => {
            handleResetForm();
          }}
          style={{
            marginTop: '6px',
            marginBottom: '15px',
          }}
        >
          <FormattedMessage id={'button.close'} />
        </Button>
        <Button
          type={'submit'}
          style={{
            marginTop: '6px',
            marginBottom: '15px',
          }}
        >
          <FormattedMessage id={'button.ok'} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};
export default InputForm;
