// @ts-nocheck
import React, { useCallback } from 'react';
import { styled } from 'baseui';
import Drawer from '../../components/Drawer/Drawer';
import { CloseIcon } from '../../components/AllSvgIcon';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';

import Sidebar from '../Layout/Sidebar/Sidebar';
//import AddOfferRequest from '../Offers/AddOffer/AddOfferRequest';
//import OfferRequestDetail from '../Offers/ToAssesment/OfferRequestDetail';
//import OfferRequestReview from '../Offers/InReview/SuncontractSales/OfferRequestReview';
//import OfferRequestInOfferStage from '../Offers/InOfferStage/OfferRequestInOfferStage';
//import OfferRequestDone from '../Offers/Done/OfferRequestDone';
import BankForm from '../Bank/BankForm';
import ExternalOperationTypeForm from '../ExternalOperationType/ExternalOperationTypeForm';
import InternalOperationTypeForm from '../InternalOperationType/InternalOperationTypeForm';
import MaterialTypeForm from '../MaterialType/MaterialTypeForm';
import StatusForm from '../Status/StatusForm';
import UnitForm from '../Unit/UnitForm';
import UserForm from '../User/UserForm';
import CompanyForm from '../Company/CompanyForm';
import CompanyTelephoneForm from '../Company/CompanyTelephoneForm';
import CompanyAddressForm from '../Company/CompanyAddressForm';
import StockForm from '../Stock/StockForm';
import WareHouseForm from '../WareHouse/WareHouseForm';
import WareHouseSectionForm from '../WarehouseSection/WarehouseSectionForm';
import WareHouseLevelForm from '../WarehouseLevel/WarehouseLevelForm';
import CostCenterForm from '../CostCenter/CostCenterForm';
import InventoryCategoryForm from '../Inventory/InventoryCategoryForm';
import InventoryForm from '../Inventory/InventoryForm';
import CashBookForm from '../CashBook/CashBookForm';
import ProductForm from '../Product/ProductForm';
import ProductCategoryForm from '../Product/ProductCategoryForm';
import ProductPriceForm from '../PriceLists/PriceForm';
import ProductRecordForm from '../ProductRecord/ProductRecordForm';
import ProductPriceCompanySpecificForm from '../PriceLists/PriceCompanySpecificForm';
import ShiftForm from '../Shift/ShiftForm';
import DayForm from '../Shift/DayForm';
import TimeGroupForm from '../Shift/TimeGroupForm';
import TimeRangeForm from '../Shift/TimeRangeForm';
import ShiftDetailForm from '../Shift/ShiftDetailForm';
import InventoryType from '../Inventory/InventoryTypeForm';
import OperationForm from '../Operation/AddOperationForm';
import MeasurementRuleForm from '../Product/MeasurementRule/MeasurementRuleForm';
import ShopFloorOrderForm from '../Order/ShopFloorOrder/ShopFloorOrderForm';
import MachineMeasurementForm from '../ShopFloor/MachineMeasurement/MachineMeasurementForm';
import AqlSampleRuleForm from '../AqlSampleRule/AqlSampleRuleForm';
import QualityControlReasonForm from '../QualityControlReason/QualityControlReasonFom';
import ShopFloorOrderQtyForm from '../Planing/ShopFloorOrderQtyForm';
import ShopFloorHistory from '../Planning/ShopFloorOrderStatusHistory';
import ShopFloorOrderHistory from '../ShopFloor/ShopFloorOrder/ShopFloorOrderHistory';
import TechnicalImagesPreview from '../Product/TechnicalImagesPreview';
import ImproperProductRecordForm  from '../ProductRecord/ImproperProductRecordForm';
import WipProductRecordForm  from '../ProductRecord/WipProductRecordForm';
import UpdateProductRecordForm  from '../ProductRecord/UpdateProductRecordForm';
import ShopFloorOrderCompleted  from '../ShopFloor/Complete/ShopFloorOrderComplete';
import ProductRecordHistory  from '../Planning/ProductRecordHistory';
import NewInventoryForm from '../Inventory/InventoryForm/NewInventoryForm';
import PurchaseOfferPreview from '../Purchase/ToAssesment/Preview/PurchaseOfferPreview';


const DRAWER_COMPONENTS = {
  BANK_FORM: BankForm,
  EXTERNAL_OPERATION_TYPE_FORM: ExternalOperationTypeForm,
  INTERNAL_OPERATION_TYPE_FORM: InternalOperationTypeForm,
  MATERIAL_TYPE_FORM: MaterialTypeForm,
  STATUS_FORM: StatusForm,
  UNIT_FORM: UnitForm,
  USER_FORM: UserForm,
  COMPANY_FORM: CompanyForm,
  COMPANY_TELEPHONE_FORM: CompanyTelephoneForm,
  COMPANY_ADDRESS_FORM: CompanyAddressForm,
  STOCK_FORM: StockForm,
  WAREHOUSE_FORM: WareHouseForm,
  WAREHOUSE_SECTION_FORM: WareHouseSectionForm,
  WAREHOUSE_LEVEL_FORM: WareHouseLevelForm,
  COST_CENTER_FORM: CostCenterForm,
  INVENTORY_CATEGORIES_FORM: InventoryCategoryForm,
  INVENTORY_FORM: InventoryForm,
  CASH_BOOK_FORM: CashBookForm,
  PRODUCT_FORM: ProductForm,
  PRODUCT_CATEGORY_FORM: ProductCategoryForm,
  PRODUCT_PRICE_FORM: ProductPriceForm,
  PRODUCT_PRICE_COMPANY_SPECIFIC_FORM: ProductPriceCompanySpecificForm,
  PRODUCT_RECORD_FORM: ProductRecordForm,
  SHIFT_FORM: ShiftForm,
  SHIFT_DETAIL_FORM: ShiftDetailForm,
  DAY_FORM: DayForm,
  TIME_GROUP_FORM: TimeGroupForm,
  TIME_RANGE_FORM: TimeRangeForm,
  INVENTORY_TYPE_FORM: InventoryType,
  //ADD_OFFER_REQUEST: AddOfferRequest,
  //OFFER_REQUEST_DETAIL: OfferRequestDetail,
  //OFFER_REQUEST_REVIEW: OfferRequestReview,
  //OFFER_REQUEST_IN_OFFER_STAGE: OfferRequestInOfferStage,
  //OFFER_REQUEST_DONE: OfferRequestDone,
  SIDEBAR: Sidebar,
  OPERATION_FORM: OperationForm,
  MEASUREMENT_RULE_FORM: MeasurementRuleForm,
  SHOP_FLOOR_ORDER_FORM: ShopFloorOrderForm,
  MACHINE_MEASUREMENT_FORM: MachineMeasurementForm,
  AQL_SAMPLE_RULE_FORM: AqlSampleRuleForm,
  QUALITY_CONTROL_REASON_FORM: QualityControlReasonForm,
  SHOP_FLOOR_ORDER_QTY_FORM: ShopFloorOrderQtyForm,
  SHOP_FLOOR_ORDER_HISTORY: ShopFloorOrderHistory,
  SHOP_FLOOR_HISTORY: ShopFloorHistory,
  TECHICAL_IMAGES_PREVIEW: TechnicalImagesPreview,
  IMPROPER_PRODUCT_RECORD_FORM:ImproperProductRecordForm,
  WIP_PRODUCT_RECORD_FORM:WipProductRecordForm,
  UPDATE_PRODUCT_RECORD_FORM:UpdateProductRecordForm,
  SHOP_FLOOR_ORDER_COMPLETED:ShopFloorOrderCompleted,
  PRODUCT_RECORD_HISTORY:ProductRecordHistory,
  NEW_INVENTORY_FORM:NewInventoryForm,
  PURCHASE_OFFER_PREVIEW:PurchaseOfferPreview
};

const CloseButton = styled('button', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textNormal,
  lineHeight: 1.2,
  outline: '0',
  border: 'none',
  padding: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  top: '10px',
  left: '-30px',
  right: 'auto',
  cursor: 'pointer',
  backgroundColor: '#ffffff',
  width: '20px',
  height: '20px',
  borderRadius: '50%',

  '@media only screen and (max-width: 767px)': {
    left: 'auto',
    right: '30px',
    top: '29px',
  },
}));

export default function DrawerItems() {
  const widthComponent = useDrawerState('widthComponent');
  const drawerComponent = useDrawerState('drawerComponent');
  const data = useDrawerState('data');
  const isOpen = useDrawerState('isOpen');
  const dispatch = useDrawerDispatch();
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  if (!drawerComponent) {
    return null;
  }
  const SpecificContent = DRAWER_COMPONENTS[drawerComponent];

  return (
    <Drawer
      isOpen={isOpen}
      onClose={closeDrawer}
      overrides={{
        DrawerBody: {
          style: {
            marginTop: '30px',
            marginLeft: '20px',
            marginRight: '20px',
            marginBottom: '5px',
            '@media only screen and (max-width: 767px)': {
              marginTop: '80px',
              marginLeft: '30px',
              marginRight: '30px',
              marginBottom: '30px',
            },
          },
        },
        DrawerContainer: {
          style: {
            width: `${widthComponent}`,
            backgroundColor: '#f7f7f7',
            '@media only screen and (max-width: 767px)': {
              width: '100%',
            },
          },
        },
        Close: {
          component: () => (
            <CloseButton onClick={closeDrawer}>
              <CloseIcon width="6px" height="6px" />
            </CloseButton>
          ),
        },
      }}
    >
      <SpecificContent onClose={closeDrawer} data={data} />
    </Drawer>
  );
}
