import React, { useCallback, useState, useEffect, FunctionComponent } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '../../components/FormWithElemets/Button/Button';
import { useDrawerDispatch } from '../../context/DrawerContext';
import { loader } from 'graphql.macro';
import { ColumnArg, DataTable } from '../../components/Table/DataTable';
import { CardTitle, CardBody } from '../../components/FormWithElemets/Card.style';
import { useToaster } from '../../context/toaster-provider';
import { handleDelete } from './GraphqlFunction';
import { QualityControlReason } from '../../types/QualityControlReason';

const DELETE_QUALITY_CONTROL_REASON = loader(
  '../../graphql/qualityControlReason/DELETE_QUALITY_CONTROL_REASON.graphql'
);
interface ListProps {
  data: QualityControlReason[];
}
const List: FunctionComponent<ListProps> = ({ data }) => {
  const intl = useIntl();
  const { showToaster } = useToaster();
  const [qualityControlReasonId, setQualityControlReasonId] = useState(null);
  const dispatch = useDrawerDispatch();
  const openDrawer = useCallback(
    (data?) => {
      data
        ? dispatch({
            type: 'OPEN_DRAWER',
            drawerComponent: 'QUALITY_CONTROL_REASON_FORM',
            data: data,
            transactionsReason: 'QualityControlReasonUpdate',
            widthComponent: '550px',
          })
        : dispatch({
            type: 'OPEN_DRAWER',
            drawerComponent: 'QUALITY_CONTROL_REASON_FORM',
            widthComponent: '550px',
          });
      setQualityControlReasonId(null);
    },
    [dispatch]
  );
  const [remove] = useMutation(DELETE_QUALITY_CONTROL_REASON);
  const handleRemove = (Id) => {
    if (window.confirm('Are you sure !')) {
      remove({
        variables: { id: Id },
        update(cache, { data: { deleteQualityCheckReason } }) {
          handleDelete(cache, deleteQualityCheckReason);
        },
      }).then(() => showToaster('removed', 'warning'));
    }
  };
  const columns: ColumnArg[] = [
    {
      id: 'name',
      header: intl.formatMessage({ id: 'quality_control_reason.name' }),
      sortable: true,
      content: 'name',
      buttons: null,
      type: 'string',
    },
    {
      id: 'id',
      header: '',
      sortable: false,
      content: null,
      buttons: [
        {
          label: intl.formatMessage({ id: 'button.delete' }),
          function: (id) => {
            handleRemove(id);
          },
          check_type: 'DELETE',
        },
        {
          label: intl.formatMessage({ id: 'button.update' }),
          function: (id) => {
            setQualityControlReasonId(id);
          },
          check_type: 'UPDATE',
        },
      ],
      type: 'buttons',
    },
  ];
  useEffect(() => {
    qualityControlReasonId &&
      openDrawer(data.find((item) => item.id === qualityControlReasonId));
  }, [qualityControlReasonId, data, openDrawer]);
  return (
    <>
      <CardTitle>
        <Button
          onClick={() => {
            openDrawer();
          }}
        >
          <FormattedMessage id="button.create" />
        </Button>
      </CardTitle>
      <CardBody style={{ paddingTop: 0 }}>
        <DataTable
          pageItem={6}
          columns={columns}
          data={data}
          orderColumn="name"
          sortOrder="DESC"
          threeDot
        />
      </CardBody>
    </>
  );
};
export default List;
