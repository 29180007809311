import { useQuery } from '@apollo/react-hooks';
import { StyledSpinnerNext } from 'baseui/spinner';
import { loader } from 'graphql.macro';
import React, {
    Fragment,
    FunctionComponent,
    memo,
    useEffect,
  } from 'react';
import { CardBody } from '../../../components/FormWithElemets/Card.style';
import NoResult from '../../../components/NoResult/NoResult';
import { SubcontractSalesOfferIDs } from '../../../types/SubcontractSalesOfferID';
import ListSubcontractSalesPreview from '../InOfferStage/SubcontractSales/Preview/ListSubcontractSalesPreview';
import SubcontractSalesResult from '../InOfferStage/SubcontractSales/SubcontractSalesResult';
  const GET_SUBCONTRACT_SALES_OFFER = loader(
    '../../../graphql/offer/subcontractSales/GET_SUBCONTRACT_SALES_OFFER.graphql'
  );
  const S_GET_SUBCONTRACT_SALES_OFFER = loader(
    '../../../graphql/offer/subcontractSales/S_GET_SUBCONTRACT_SALES_OFFER.graphql'
  );
  
  interface SuncontractSalesProps {
    offerId: string;
  }
  const SuncontractSales: FunctionComponent<SuncontractSalesProps> = ({
    offerId,
  }) => {
    const {
      subscribeToMore,
      data,
      loading,
      error,
    } = useQuery<SubcontractSalesOfferIDs>(GET_SUBCONTRACT_SALES_OFFER, {
      variables: {
        id: offerId,
      },
    });
    useEffect(() => {
      subscribeToMore({
        document: S_GET_SUBCONTRACT_SALES_OFFER,
        variables: {
          id: offerId,
        },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.subcontractSalesOffer;
          return Object.assign({}, prev, {
            subcontractSalesOffer: newFeedItem,
          });
        },
      });
    }, [subscribeToMore, offerId]);
  
    return (
      <Fragment>
        {loading ? (
          <StyledSpinnerNext />
        ) : error ? (
          <NoResult />
        ) : (
              data && (
                <Fragment>
                  <CardBody>
                    <ListSubcontractSalesPreview items={data?.subcontractSalesOffer?.subcontractSalesOffer} />
                  </CardBody>
                  <SubcontractSalesResult
                    transportIncluded={data.subcontractSalesOffer?.transportIncluded}
                    offerTransport={data.subcontractSalesOffer?.offerTransport}
                    items={data.subcontractSalesOffer.subcontractSalesOffer}
                  />
                </Fragment>
              )
            )}
      </Fragment>
    );
  };  
export default memo(SuncontractSales);
  