import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../components/FlexBox/FlexBox';
import {
  Label,
  LabelCol,
} from '../../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../../components/TailWinduiTable/ColumnButtons';

interface ListItemTransportOfferProps {
  item: OfferTransport;
  handleItemDelete: Function;
  handleItemEdit: Function;
}
const ListItemTransportOffer: FunctionComponent<ListItemTransportOfferProps> = ({
  item,
  handleItemEdit,
  handleItemDelete,
}) => {
  const intl = useIntl();
  const buttons = [
    {
      label: intl.formatMessage({ id: 'button.delete' }),
      function: () => {
        handleItemDelete(item.id);
      },
      check_type: 'DELETE',
    },
    {
      label: intl.formatMessage({ id: 'button.update' }),
      function: () => {
        handleItemEdit(item.id);
      },
      check_type: 'UPDATE',
    },
  ];
  return (
    <RowCustom>
      <Col xs={12} md={8} lg={4}>
        <LabelCol
          title={intl.formatMessage({ id: 'offer_item_transport.company' })}
        >
          {item?.company?.companyShortName || 'No Company'}
        </LabelCol>
      </Col>
      <Col xs={5} md={4} lg={2}>
        <LabelCol
          title={intl.formatMessage({ id: 'offer_item_transport.price' })}
        >
          {item?.price || '0'}
        </LabelCol>
      </Col>
      <Col xs={5} md={6} lg={2}>
        <LabelCol
          title={intl.formatMessage({ id: 'offer_item_transport.approved' })}
        >
          {item?.approved ? '✓' : 'X'}
        </LabelCol>
      </Col>
      <ColButton xs>
        <ColumnButtons threeDot={true} item={[{ id: '1' }]} buttons={buttons} />
      </ColButton>
      {item?.description && (
        <Col xs={12}>
          <LabelCol
            title={intl.formatMessage({
              id: 'offer_item_transport.description',
            })}
          >
            {item?.description}
          </LabelCol>
        </Col>
      )}
    </RowCustom>
  );
};
export default ListItemTransportOffer;
