import React, { FunctionComponent } from 'react';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SubMenu,
  SidebarContent,
  SidebarFooter,
} from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
// import './custom.scss';
import './style.css';
// import './node_modules/react-pro-sidebar/dist/css/style.css';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faBuilding,
  faUsers,
  faSlidersVSquare,
  faBadgeDollar,
  faFileInvoiceDollar,
  faMoneyCheckEditAlt,
  faIndustryAlt,
  faMindShare,
  faClipboardCheck,
  faFileChartPie,
  faChevronLeft,
  faChevronRight,
  faInventory,
  faWarehouseAlt,
  faCartArrowDown,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  DASHBOARD,
  OFFERS,
  COMPANY,
  INVOICE_SALES,
  INVOICE_PURCHASE,
  BANKS,
  MACHINERY_AND_EQUIPMENT,
  MATERIAL_TYPE,
  UNITS,
  USERS,
  WAYBILL_SALES,
  WAYBILL_PURCHASE,
  WAREHOUSE,
  COST_CENTER,
  INVENTORY,
  SHIFT,
  PRODUCT,
  PRODUCT_PRICE,
  PRODUCT_RECORD,
  MBOM,
  ORDER,
  OPERATION,
  PLANNING,
  QUALITY_CONTROL,
  SHOP_FLOOR,
  AQL_SAMPLE_RULE,
  QUALITY_CONTROL_REASON,
  PERMISSION,
  SHOP_FLOOR_ORDER,
  MEASUREMENT_RULE_UNIT,
  SHOP_FLOOR_ORDER_STOP_REASON,
  SELECT_PRINTER,
  CANCELED_OR_DECLINED_REASON,
  MATERIAL,
  PRODUCT_RECORD_REASON,
  SHOP_FLOOR_ORDER_CANCEL_REASON,
  OFFER_TYPE,
  BILL_OF_MATERIAL,
  PURCHASE_OFFER,
  CALCULATOR,
  MANUFACTURER,
  MACHINERY_AND_EQUIPMENT_STATUS,
  DEPARTMENT,
  QUALITY_DOCUMENT_TYPE,
  QUALITY_ACTION,
  QUALITY_IMPROVEMENT_ACTION_PLAN,
  QUALITY_OBJECTIVE,
  QUALITY_OBJECTIVE_RESPONSIBLE,
  QUALITY_DOCUMENT,
  QUALITY_OBJECTIVE_RESOURCE,
  QUALITY_DOCUMENT_MASTER
} from '../../../settings/constants';
import { Button, KIND } from 'baseui/button';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { BlockProps } from 'baseui/block';
import { Can } from '../Layout';

const itemProps: BlockProps = {
  height: 'scale1000',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

type NewSidebarProps = {
  collapsed: boolean;
  toggled: boolean;
  handleToggleSidebar: any;
  handleCollapsedChange: any;
};

export const NewSidebar: FunctionComponent<NewSidebarProps> = ({
  collapsed,
  toggled,
  handleToggleSidebar,
  handleCollapsedChange,
}) => {
  return (
    <ProSidebar
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
      {/*<SidebarHeader></SidebarHeader>*/}
      <SidebarContent>
        <Menu iconShape="circle">
          <MenuItem icon={<FontAwesomeIcon size="2x" icon={faTachometerAlt} />}>
            <FormattedMessage id="menu.dashboard" />
            <NavLink to={DASHBOARD} />
          </MenuItem>
          <Can I="COMPANIES" a="MENUS">
            <MenuItem icon={<FontAwesomeIcon size="2x" icon={faBuilding} />}>
              <FormattedMessage id="menu.companies" />
              <NavLink to={COMPANY} />
            </MenuItem>
          </Can>
          <Can I="PRODUCTION" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.production" />}
              icon={<FontAwesomeIcon size="2x" icon={faIndustryAlt} />}
            >
              <Can I="LIST" a="MBOM">
                <MenuItem>
                  <FormattedMessage id="menu.MBOM" />
                  <NavLink to={MBOM} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="OPERATIONS">
                <MenuItem>
                  <FormattedMessage id="menu.operations" />
                  <NavLink to={OPERATION} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SHOP-FLOOR-ORDERS">
                <MenuItem>
                  <FormattedMessage id="menu.shop_management" />
                  <NavLink to={SHOP_FLOOR_ORDER} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="PLANING" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.planing" />}
              icon={<FontAwesomeIcon size="2x" icon={faMindShare} />}
            >
              <Can I="SHOP-FLOOR-SUMMARY" a="PLANING">
                <MenuItem>
                  <FormattedMessage id="menu.planing_management" />
                  <NavLink to={PLANNING} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="QUALITY-CONTROL" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.quality_control" />}
              icon={<FontAwesomeIcon size="2x" icon={faClipboardCheck} />}
            >
              <Can I="CONTROL" a="QUALITY-CONTROL">
                <MenuItem>
                  <FormattedMessage id="menu.quality_control" />
                  <NavLink to={QUALITY_CONTROL} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="AQL-SAMPLE-RULES">
                <MenuItem>
                  <FormattedMessage id="menu.aql_sample_rule" />
                  <NavLink to={AQL_SAMPLE_RULE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY-CONTROL-REASONS">
                <MenuItem>
                  <FormattedMessage id="menu.quality_control_reason" />
                  <NavLink to={QUALITY_CONTROL_REASON} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PRODUCT-RECORD-REASONS">
                <MenuItem>
                  <FormattedMessage id="menu.product_record_reason" />
                  <NavLink to={PRODUCT_RECORD_REASON} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="REPORTS" a="MENUS">
            <MenuItem icon={<FontAwesomeIcon size="2x" icon={faFileChartPie} />}>
              <FormattedMessage id="menu.reports" />
            </MenuItem>
          </Can>
          <Can I="ORDERS" a="MENUS">
            <MenuItem icon={<FontAwesomeIcon size="2x" icon={faCartArrowDown} />}>
              <FormattedMessage id="menu.order" />
              <NavLink to={ORDER} />
            </MenuItem>
          </Can>
          <Can I="SALES" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.sales" />}
              icon={<FontAwesomeIcon size="2x" icon={faBadgeDollar} />}
            >
              <Can I="LIST" a="OFFERS">
                <MenuItem>
                  <FormattedMessage id="menu.offers" />
                  <NavLink to={OFFERS} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SALE-INVOICE">
                <MenuItem>
                  <FormattedMessage
                    id="menu.invoices_with_type"
                    values={{ type: <FormattedMessage id="menu.sales" /> }}
                  />
                  <NavLink to={INVOICE_SALES} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SALE-WAYBILL">
                <MenuItem>
                  <FormattedMessage
                    id="menu.waybills_with_type"
                    values={{ type: <FormattedMessage id="menu.sales" /> }}
                  />
                  <NavLink to={WAYBILL_SALES} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="PURCHASES" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.purchases" />}
              icon={<FontAwesomeIcon size="2x" icon={faMoneyCheckEditAlt} />}
            >
              <Can I="LIST" a="PURCHASE-INVOICE">
                <MenuItem>
                  <FormattedMessage
                    id="menu.invoices_with_type"
                    values={{ type: <FormattedMessage id="menu.purchase" /> }}
                  />
                  <NavLink to={INVOICE_PURCHASE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PURCHASE-WAYBILL">
                <MenuItem>
                  <FormattedMessage
                    id="menu.waybills_with_type"
                    values={{ type: <FormattedMessage id="menu.purchase" /> }}
                  />
                  <NavLink to={WAYBILL_PURCHASE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PURCHASE-OFFERS">
                <MenuItem>
                  <FormattedMessage
                    id="menu.purchaseOffers"
                    values={{ type: <FormattedMessage id="menu.purchase" /> }}
                  />
                  <NavLink to={PURCHASE_OFFER} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="ACCOUNTING" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.accounting" />}
              icon={<FontAwesomeIcon size="2x" icon={faFileInvoiceDollar} />}
            >
              <Can I="LIST" a="BANKS">
                <MenuItem>
                  <FormattedMessage id="menu.banks" />
                  <NavLink to={BANKS} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="COST-CENTERS">
                <MenuItem>
                  <FormattedMessage id="menu.cost_centers" />
                  <NavLink to={COST_CENTER} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="INVENTORIES-AND-PRODUCTS" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.inventories_and_products" />}
              icon={<FontAwesomeIcon size="2x" icon={faInventory} />}
            >
              <Can I="LIST" a="INVENTORIES">
                <MenuItem>
                  <FormattedMessage id="menu.inventories" />
                  <NavLink to={INVENTORY} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PRODUCTS">
                <MenuItem>
                  <FormattedMessage id="menu.products" />
                  <NavLink to={PRODUCT} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PRODUCT-PRICES">
                <MenuItem>
                  <FormattedMessage id="menu.product_prices" />
                  <NavLink to={PRODUCT_PRICE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PRODUCT-RECORDS">
                <MenuItem>
                  <FormattedMessage id="menu.product_records" />
                  <NavLink to={PRODUCT_RECORD} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="MATERIALS">
                <MenuItem>
                  <FormattedMessage id="menu.materials" />
                  <NavLink to={MATERIAL} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="BILL-OF-MATERIALS">
                <MenuItem>
                  <FormattedMessage id="menu.billOfMaterials" />
                  <NavLink to={BILL_OF_MATERIAL} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="WAREHOUSES-AND-LOGISTICS" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.warehouses_and_logistics" />}
              icon={<FontAwesomeIcon size="2x" icon={faWarehouseAlt} />}
            >
              <Can I="LIST" a="WAREHOUSES">
                <MenuItem>
                  <FormattedMessage id="menu.warehouses" />
                  <NavLink to={WAREHOUSE} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
          <Can I="SETTINGS-AND-DEFINITIONS" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.settings_definitions" />}
              icon={<FontAwesomeIcon size="2x" icon={faSlidersVSquare} />}
            >
              <Can I="LIST" a="DEPARTMENTS">
                <MenuItem>
                  <FormattedMessage id="menu.departments" />
                  <NavLink to={DEPARTMENT} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_ACTIONS">
                <MenuItem>
                  <FormattedMessage id="menu.qualityActions" />
                  <NavLink to={QUALITY_ACTION} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_OBJECTIVES">
                <MenuItem>
                  <FormattedMessage id="menu.qualityObjectives" />
                  <NavLink to={QUALITY_OBJECTIVE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_OBJECTIVE_RESPONSIBLES">
                <MenuItem>
                  <FormattedMessage id="menu.qualityObjectiveResponsibles" />
                  <NavLink to={QUALITY_OBJECTIVE_RESPONSIBLE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_OBJECTIVE_RESOURCES">
                <MenuItem>
                  <FormattedMessage id="menu.qualityObjectiveResources" />
                  <NavLink to={QUALITY_OBJECTIVE_RESOURCE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_IMPROVEMENT_ACTION_PLANS">
                <MenuItem>
                  <FormattedMessage id="menu.qualityImprovementActionPlans" />
                  <NavLink to={QUALITY_IMPROVEMENT_ACTION_PLAN} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_DOCUMENT_TYPES">
                <MenuItem>
                  <FormattedMessage id="menu.qualityDocumentTypes" />
                  <NavLink to={QUALITY_DOCUMENT_TYPE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_DOCUMENTS">
                <MenuItem>
                  <FormattedMessage id="menu.qualityDocuments" />
                  <NavLink to={QUALITY_DOCUMENT} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="QUALITY_DOCUMENT_MASTERS">
                <MenuItem>
                  <FormattedMessage id="menu.qualityDocumentMasters" />
                  <NavLink to={QUALITY_DOCUMENT_MASTER} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SHIFTS">
                <MenuItem>
                  <FormattedMessage id="menu.shifts" />
                  <NavLink to={SHIFT} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="MACHINERY-AND-EQUIPMENTS">
                <MenuItem>
                  <FormattedMessage id="menu.machinery_and_equipments" />
                  <NavLink to={MACHINERY_AND_EQUIPMENT} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="MATERIAL-TYPES">
                <MenuItem>
                  <FormattedMessage id="menu.material_types" />
                  <NavLink to={MATERIAL_TYPE} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="MACHINERY_AND_EQUIPMENT_STATUSES">
                <MenuItem>
                  <FormattedMessage id="menu.machineryAndEquipmentStatuses" />
                  <NavLink to={MACHINERY_AND_EQUIPMENT_STATUS} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="MANUFACTURERS">
                <MenuItem>
                  <FormattedMessage id="menu.manufacturers" />
                  <NavLink to={MANUFACTURER} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="UNITS">
                <MenuItem>
                  <FormattedMessage id="menu.units" />
                  <NavLink to={UNITS} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="MEASUREMENT-RULE-UNITS">
                <MenuItem>
                  <FormattedMessage id="menu.measurement_rule_unit" />
                  <NavLink to={MEASUREMENT_RULE_UNIT} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SHOP-FLOORS">
                <MenuItem>
                  <FormattedMessage id="menu.shop_floors" />
                  <NavLink to={SHOP_FLOOR} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SHOP-FLOOR-ORDER-STOP-REASONS">
                <MenuItem>
                  <FormattedMessage id="menu.shop_floor_order_stop_reason" />
                  <NavLink to={SHOP_FLOOR_ORDER_STOP_REASON} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SHOP-FLOOR-ORDER-CANCEL-REASONS">
                <MenuItem>
                  <FormattedMessage id="menu.shop_floor_order_cancel_reason" />
                  <NavLink to={SHOP_FLOOR_ORDER_CANCEL_REASON} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="SELECT-PRINTER">
                <MenuItem>
                  <FormattedMessage id="menu.printer" />
                  <NavLink to={SELECT_PRINTER} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="CANCELED-OR-DECLINED-REASON">
                <MenuItem>
                  <FormattedMessage id="menu.canceled_or_declined_reason" />
                  <NavLink to={CANCELED_OR_DECLINED_REASON} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="OFFER-TYPES">
                <MenuItem>
                  <FormattedMessage id="menu.offerTypes" />
                  <NavLink to={OFFER_TYPE} />
                </MenuItem>
              </Can>
              {/* <Can I="LIST" a="SHIFTS"> */}
                <MenuItem>
                  <FormattedMessage id="menu.calculator" />
                  <NavLink to={CALCULATOR} />
                </MenuItem>
              {/* </Can> */}
            </SubMenu>
          </Can>
          <Can I="USERS-AND-PERMISSIONS" a="MENUS">
            <SubMenu
              title={<FormattedMessage id="menu.users_and_permissions" />}
              icon={<FontAwesomeIcon size="2x" icon={faUsers} />}
            >
              <Can I="LIST" a="USERS">
                <MenuItem>
                  <FormattedMessage id="menu.users" />
                  <NavLink to={USERS} />
                </MenuItem>
              </Can>
              <Can I="LIST" a="PERMISSIONS">
                <MenuItem>
                  <FormattedMessage id="menu.permission" />
                  <NavLink to={PERMISSION} />
                </MenuItem>
              </Can>
            </SubMenu>
          </Can>
        </Menu>
      </SidebarContent>
      <SidebarFooter>
        <FlexGrid flexGridColumnCount={1}>
          <FlexGridItem {...itemProps}>
            <Button kind={KIND.minimal} onClick={() => handleCollapsedChange(!collapsed)}>
              <FontAwesomeIcon icon={collapsed ? faChevronRight : faChevronLeft} />
            </Button>
          </FlexGridItem>
        </FlexGrid>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default NewSidebar;
