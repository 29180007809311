import React, { FunctionComponent, useEffect, useState } from 'react';
import { ceren, ms } from '../../../TailWinduiComponent/Headers';
import CardFooter from '../../../TailWinduiHTML/Card/CardFooter';
import { useIntl } from 'react-intl';
import ListOfferNote from '../ListOfferNote';

interface Props {
  offerId?: string;
  note?: string;
}
const CardFooterCustom: FunctionComponent<Props> = ({ offerId, note }) => {
  const intl = useIntl();
  const subdomain = window.location.host.split('.')[0];
  const [companyName, setCompanyName] = useState('');
  useEffect(() => {
    const _companyName =
      subdomain === 'app' || subdomain === 'localhost:3000'
        ? ms
        : subdomain
        ? ceren
        : 'No Company';
    setCompanyName(_companyName);
  }, [subdomain]);
  return (
    <CardFooter>
      {offerId && <p className="text-center">{intl.formatMessage({ id: 'descriptions' })}</p>}
      {offerId && <ListOfferNote offerId={offerId} note={note} />}
      <div className="flex  px-2 text-center text-xs font-semibold h-24">
        <div className="flex-1">
          <p>MÜŞTERİ ONAYI</p>
          <p>CUSTOMER CONFIRMATION</p>
        </div>
        <div className="flex-1">
          <p>{companyName + ' '}ONAYI</p>
          <p>{companyName + ' '}CONFIRMATION</p>
        </div>
      </div>
    </CardFooter> 
  );
};
export default CardFooterCustom;
