import { useQuery } from '@apollo/react-hooks';
import { TYPE } from 'baseui/select';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { Negative, Positive } from '../../components/General/NegativePositive';

const GET_QUALITY_CONTROL_REASON = loader(
  '../../graphql/qualityControlReason/GET_QUALITY_CONTROL_REASON.graphql'
);
export const handleUpdate = (cache, updateQualityCheckReason) => {
  const { qualityCheckReasons } = cache.readQuery({
    query: GET_QUALITY_CONTROL_REASON,
  });
  const newQualityCheckReasons = qualityCheckReasons.map((item) => {
    if (updateQualityCheckReason.id === item.id) {
      return { ...updateQualityCheckReason };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_QUALITY_CONTROL_REASON,
    data: { qualityCheckReasons: newQualityCheckReasons },
  });
};
export const handleAdd = (cache, createQualityCheckReason) => {
  const { qualityCheckReasons } = cache.readQuery({
    query: GET_QUALITY_CONTROL_REASON,
  });
  cache.writeQuery({
    query: GET_QUALITY_CONTROL_REASON,
    data: {
      qualityCheckReasons: qualityCheckReasons.concat(createQualityCheckReason),
    },
  });
};
export const handleDelete = (cache, deleteQualityCheckReason) => {
  const { qualityCheckReasons } = cache.readQuery({
    query: GET_QUALITY_CONTROL_REASON,
  });
  cache.writeQuery({
    query: GET_QUALITY_CONTROL_REASON,
    data: {
      qualityCheckReasons: qualityCheckReasons.filter(
        (item) => item.id !== deleteQualityCheckReason.id
      ),
    },
  });
};
interface ListQualityControlReasonSelectProps {
  errors: any;
  control: any;
}
export const QualityControlReasonSelect: FunctionComponent<ListQualityControlReasonSelectProps> = ({
  errors,
  control,
}) => {
  const { data } = useQuery(GET_QUALITY_CONTROL_REASON);
  return (
    <FormControl
      //label={<FormattedMessage id="product.aql" />}
      error={errors.QualityControlReasonId ? 'Please input a valid Quality Control Reason' : null}
    >
      <Select
        data={data && data.qualityCheckReasons}
        controller={{
          name: 'QualityControlReasonId',
          type: TYPE.search,
          control,
          rules: { required: true },
          creatable: false,
          searchable: true,
          filterOutSelected: false,
          placeholder: 'Quality Control Reason',
          error: errors.QualityControlReasonId,
          overrides: errors.QualityControlReasonId ? { After: Negative } : { After: Positive },
          onChange: ([selected]) => {
            return selected.option ? selected.value : undefined;
          },
        }}
      />
    </FormControl>
  );
};
