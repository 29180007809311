import { loader } from "graphql.macro";

const GET_SHIFTS = loader('../../graphql/shift/GET_SHIFTS.graphql');
const GET_DAYS = loader('../../graphql/shift/GET_DAYS.graphql');
const GET_TIME_GROUPS = loader('../../graphql/shift/GET_TIME_GROUPS.graphql');
const GET_TIME_RANGES = loader('../../graphql/shift/GET_TIME_RANGES.graphql');
export const handleAddShiftDetail=(cache,shiftId,createShiftDetail)=>{
    const { shifts } = cache.readQuery({
        query: GET_SHIFTS,
      });
      const newShifts=shifts.map((item) => {
        if (shiftId === item.id) {
          return{
              ...item,
              shiftDetail :item.shiftDetail.concat([createShiftDetail])
          
          }
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_SHIFTS,
        data: {
          shifts: newShifts,
        },
      });

}

export const handleUpdateShiftDetail=(cache,shiftId,updateShiftDetail)=>{
    const { shifts } = cache.readQuery({ query: GET_SHIFTS });
    const newShifts=shifts.map((item) => {
        if (shiftId === item.id) {
          return{
              ...item,
              shiftDetail :item.shiftDetail.map(detail=>{
                if(detail.id===updateShiftDetail.id){
                    return updateShiftDetail
                }else{
                    return detail
                }
              })
          }
        } else {
          return item;
        }
      });
      console.log(newShifts)
    cache.writeQuery({
      query: GET_SHIFTS,
      data: { shifts: newShifts },
    });
}
export const handleDeleteShiftDetail=(cache,shiftId,deleteShiftDetail)=>{
    const { shifts } = cache.readQuery({
        query: GET_SHIFTS,
      });
      const newShifts=shifts.map((item) => {
        if (shiftId === item.id) {
          return{
              ...item,
              shiftDetail :item.shiftDetail.filter(detail=>detail.id!==deleteShiftDetail.id)
              
          }
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: GET_SHIFTS,
        data: { shifts: newShifts },
      });
}
export const handleUpdateDay=(cache,updateDay)=>{
  const { days } = cache.readQuery({
    query: GET_DAYS,
  });
  const newdays = days.map((item) => {
    if (updateDay.id === item.id) {
      return updateDay;
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_DAYS,
    data: { days: newdays },
  });
}
export const handleDeleteTimeGroup=(cache,deleteTimeGroup)=>{
  const { timeGroups } = cache.readQuery({
    query: GET_TIME_GROUPS,
  });
  cache.writeQuery({
    query: GET_TIME_GROUPS,
    data: { timeGroups: timeGroups.filter((item) => item.id !== deleteTimeGroup.id) },
  });
}
export const handleAddTimegroup=(cache,createTimeGroup)=>{
  const { timeGroups } = cache.readQuery({ query: GET_TIME_GROUPS });
      cache.writeQuery({
        query: GET_TIME_GROUPS,
        data: { timeGroups: timeGroups.concat(createTimeGroup) },
      });
}
export const handleUpdateTimegroup=(cache,updateTimeGroup)=>{
  const { timeGroups } = cache.readQuery({ query: GET_TIME_GROUPS });
  console.log(timeGroups)
      const newTimeGroups = timeGroups.map((item) => {
        if (updateTimeGroup.id === item.id) {
          return{
            ...updateTimeGroup
          }
        } else {
          return item;
        }
      });
      console.log(newTimeGroups)
      cache.writeQuery({
        query: GET_TIME_GROUPS,
        data: { timeGroups: newTimeGroups },
      });
}

export const handleDeleteTimeRange=(cache,deleteTimeRange)=>{
  const { timeRanges } = cache.readQuery({
    query: GET_TIME_RANGES,
  });
  cache.writeQuery({
    query: GET_TIME_RANGES,
    data: { timeRanges: timeRanges.filter((item) => item.id !== deleteTimeRange.id) },
  });
}
export const handleAddTimeRange=(cache,createTimeRange)=>{
  const { timeRanges } = cache.readQuery({
    query: GET_TIME_RANGES,
  });
  cache.writeQuery({
    query: GET_TIME_RANGES,
    data: {
      timeRanges: timeRanges.concat([createTimeRange]),
    },
  });
}
export const handleUpdateTimeRange=(cache,updateTimeRange)=>{
  const { timeRanges } = cache.readQuery({
    query: GET_TIME_RANGES,
  });
  const newtimeRanges = timeRanges.map((item) => {
    if (updateTimeRange.id === item.id) {
      return {
        ...updateTimeRange
      }
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_TIME_RANGES,
    data: { timeRanges: newtimeRanges },
  });
}