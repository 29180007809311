import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import {
  Card,
  CardBody,
  CardTitle,
} from '../../../components/FormWithElemets/Card.style';
import { Form } from '../../../components/FormWithElemets/Form.style';
import {
  useDrawerDispatch, 
  useDrawerState,
} from '../../../context/DrawerContext';
import { MaterialGeometry } from '../../../types/MaterialGeometries';
import { handleAddInventory, handleUpdateInventory } from '../GraphqlFunction';
import RawMaterialInventoryForm from '../RawMaterialInventoryForm';
import CommonForm from './CommonForm';
const ADD_INVENTORY = loader('../../../graphql/inventory/ADD_INVENTORY.graphql');
const ADD_RAW_MATERIAL_INVENTORY = loader(
  '../../../graphql/inventory/ADD_RAW_MATERIAL_INVENTORY.graphql'
);
const UPDATE_RAW_MATERIAL_INVENTORY_WITH_INVENTORY = loader(
  '../../../graphql/inventory/UPDATE_RAW_MATERIAL_INVENTORY_WITH_INVENTORY.graphql'
);
const UPDATE_INVENTORY = loader(
  '../../../graphql/inventory/UPDATE_INVENTORY.graphql'
);
const NewInventoryForm: FunctionComponent = () => {
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const [rawMaterialInventory, setRawMaterialInventory] = useState(false);
  const [materialGeometry, setMaterialGeometry] = useState<MaterialGeometry>(
    () => dataDispatch?.rawMaterialInventory?.materialGeometry || null
  );
  const { register, handleSubmit, errors, setValue, control, watch } = useForm({
    defaultValues: handleDefaultValues(dataDispatch),
  });
  const { inventoryTypeId } = watch();
  const closeDrawer = useCallback(
    (sendData?) => {
      sendData
        ? dispatch({
          type: 'CLOSE_DRAWER',
          data: sendData || undefined,
          transactionsReason: 'InventoryForm',
        })
        : dispatch({
          type: 'CLOSE_DRAWER',
          transactionsReason: 'InventoryForm',
        });
    },
    [dispatch]
  );
  console.log("------>", dataDispatch)
  useEffect(() => {
    if (inventoryTypeId) {
      const _rawMaterialInventory =
        inventoryTypeId[0].id === 'd5b8e917-9bc5-42e6-a23a-b721e72972a1';
      setRawMaterialInventory(_rawMaterialInventory);
    }
  }, [inventoryTypeId]);
  const onSubmit = ({
    name,
    unitsId,
    inventoryCategoryId,
    costCenterId,
    inventoryTypeId,
    aql,
    innerMeasurementA,
    innerMeasurementB,
    outerMeasurementA,
    outerMeasurementB,
    rawMaterialId,
  }) => {
    transactionsReasonDispatch === 'update'
      ? rawMaterialInventory ?
        updateRawMaterialInventory({
          variables: {
            inventory: {
              name: name,
              unitsId: unitsId[0]?.id || null,
              inventoryCategoryId: inventoryCategoryId[0]?.id || null,
              costCenterId: costCenterId[0]?.id || null,
              inventoryTypeId: inventoryTypeId[0]?.id || null,
            },
            inventoryId: dataDispatch.id,
            rawMaterialInventory: {
              innerMeasurementA: +innerMeasurementA || 0,
              innerMeasurementB: +innerMeasurementB || 0,
              materialGeometryId: materialGeometry ? materialGeometry?.id : null,
              outerMeasurementA: +outerMeasurementA || 0,
              outerMeasurementB: +outerMeasurementB || 0,
              rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
            },
            rawMaterialInventoryId: dataDispatch?.rawMaterialInventory?.id,
          }
        })
        : updateInventory({
          variables: {
            id: dataDispatch.id,
            name: name,
            unitsId: unitsId[0].id,
            inventoryCategoryId: inventoryCategoryId[0].id,
            costCenterId: costCenterId[0].id,
            inventoryTypeId: inventoryTypeId[0].id,
          },
        })
      : rawMaterialInventory
        ? addRawMaterialInventory({
          variables: {
            innerMeasurementA: +innerMeasurementA || 0,
            innerMeasurementB: +innerMeasurementB || 0,
            materialGeometryId: materialGeometry ? materialGeometry?.id : null,
            outerMeasurementA: +outerMeasurementA || 0,
            outerMeasurementB: +outerMeasurementB || 0,
            rawMaterialId: rawMaterialId ? rawMaterialId[0]?.id : null,
            inventory: {
              name: name,
              unitsId: unitsId[0]?.id || null,
              inventoryCategoryId: inventoryCategoryId[0]?.id || null,
              costCenterId: costCenterId[0]?.id || null,
              inventoryTypeId: inventoryTypeId[0]?.id || null,
            },
          },
        })
        : addInventory({
          variables: {
            name: name,
            aqlId: aql ? aql[0]?.id : null,
            unitsId: unitsId[0].id,
            inventoryCategoryId: inventoryCategoryId[0].id,
            costCenterId: costCenterId[0].id,
            inventoryTypeId: inventoryTypeId[0].id,
          },
        });
  };
  const [addInventory] = useMutation(ADD_INVENTORY, {
    update(cache, { data: { createInventory } }) {
      handleAddInventory(cache, createInventory, undefined);
      transactionsReasonDispatch === 'material'
        ? closeDrawer({
          inventoryId: [
            { id: createInventory.id, label: createInventory.name },
          ],
          unit: createInventory.units.name,
        })
        : closeDrawer();
    },
  });
  const [addRawMaterialInventory] = useMutation(ADD_RAW_MATERIAL_INVENTORY, {
    update(cache, { data: { createRawMaterialInventory } }) {
      handleAddInventory(cache, createRawMaterialInventory, undefined);
      transactionsReasonDispatch === 'material'
        ? closeDrawer({
          inventoryId: [
            {
              id: createRawMaterialInventory.id,
              label: createRawMaterialInventory.name,
            },
          ],
          unit: createRawMaterialInventory.units.name,
        })
        : closeDrawer();
    },
  });
  const [updateInventory] = useMutation(UPDATE_INVENTORY, {
    update(cache, { data: { updateInventory } }) {
      handleUpdateInventory(cache, updateInventory);
      closeDrawer();
    },
  });
  const [updateRawMaterialInventory] = useMutation(UPDATE_RAW_MATERIAL_INVENTORY_WITH_INVENTORY, {
    update(cache, { data: { updateRawMaterialInventoryWithInventory } }) {
      handleUpdateInventory(cache, updateRawMaterialInventoryWithInventory);
      closeDrawer();
    },
  });
  return (
    <Card>
      <CardTitle
        title={
          <FormattedMessage
            id={
              transactionsReasonDispatch === 'update'
                ? 'inventory.update'
                : 'inventory.create'
            }
          />
        }
      />
      <CardBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <CommonForm errors={errors} control={control} register={register} />
          {rawMaterialInventory && (
            <RawMaterialInventoryForm
              errors={errors}
              control={control}
              setValue={setValue}
              register={register}
              setMaterialGeometry={setMaterialGeometry}
              materialGeometry={materialGeometry}
            />
          )}
          <RowCustom>
            <ColButton xs>
              <Button onClick={() => closeDrawer(null)}>
                <FormattedMessage id="button.close" />
              </Button>
              <Button type="submit">
                <FormattedMessage
                  id={
                    transactionsReasonDispatch !== 'update'
                      ? 'button.create'
                      : 'button.update'
                  }
                />
              </Button>
            </ColButton>
          </RowCustom>
        </Form>
      </CardBody>
    </Card>
  );
};
const handleDefaultValues = (item: any) => {
  const _rawMaterialId = item?.rawMaterialInventory?.rawMaterial ?
    [{
      id: item?.rawMaterialInventory?.rawMaterial?.id,
      label: item.rawMaterialInventory?.rawMaterial?.label
    }]
    : undefined
  const _aql = item?.aql
    ? [{ id: item.aql.id, label: item.aql.name }]
    : undefined
  const _unitsId = item?.units
    ? [
      {
        id: item.units.id,
        label: item.units.name,
      },
    ]
    : undefined
  const _inventoryCategoryId = item?.inventoryCategory
  ? [
    {
      id: item.inventoryCategory.id,
      label: item.inventoryCategory.name,
    },
  ]
  : undefined;
  const _costCenterId = item?.costCenter
  ? [
    {
      id: item.costCenter.id,
      label: item.inventoryCategory.name,
    },
  ]
  : undefined
  const _inventoryTypeId = item?.inventoryType
  ? [
    {
      id: item.inventoryType.id,
      label: item.inventoryType.name,
    },
  ]
  : undefined
  return item
    ? {
      name: item?.name,
      aql: _aql,
      unitsId:_unitsId,
      inventoryCategoryId: _inventoryCategoryId,
      costCenterId: _costCenterId,
      inventoryTypeId: _inventoryTypeId,
      rawMaterialId: _rawMaterialId,
      outerMeasurementA: item?.rawMaterialInventory?.outerMeasurementA || 0,
      outerMeasurementB: item?.rawMaterialInventory?.outerMeasurementB || 0,
      innerMeasurementA: item?.rawMaterialInventory?.innerMeasurementA || 0,
      innerMeasurementB: item?.rawMaterialInventory?.innerMeasurementB || 0
    }
    : {};
};
export default NewInventoryForm;
