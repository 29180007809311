import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useReducer,
  useState,
} from 'react';
import { faMinus, faPlus, faTimes } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DivButton } from '../../../../components/FlexBox/FlexBox';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { useToaster } from '../../../../context/toaster-provider';
import ItemFormInput from './ItemFormInput';
import ListItem from './ListInputItem';
import { InputType } from '../Step/Step';
import { reducerFunction } from '../../../Functions/Utilities';
import Button from '../../../../components/FormWithElemets/Button/Button';
import { Label } from '../../../../components/FormWithElemets/Label.style';
import { FormattedMessage } from 'react-intl';

type Action =
  | {
      type: 'ADD';
      item: InputType;
    }
  | { type: 'DELETE'; index: number }
  | {
      type: 'UPDATE';
      item: InputType;
      index: number;
    }
  | {
      type: 'RESET';
    };
const reducer = (state: InputType[], action: Action) => {
  return reducerFunction(state, action);
};

interface ListProps {
  initialState: InputType[];
  dispatchExecutionPlannigStep: Function;
  setAddShow: Function;
}
const ListInOutput: FunctionComponent<ListProps> = ({
  dispatchExecutionPlannigStep,
  initialState,
  setAddShow,
}) => {
  const [items, dispatchItems] = useReducer(reducer, initialState);
  const { showToaster } = useToaster();
  const [itemIndex, setItemIndex] = useState(null);
  const [itemAddShow, setItemAddShow] = useState(() =>
    initialState.length === 0 ? true : false
  );
  const handleItemEdit = useCallback((index: string) => {
    setItemIndex(index);
    setItemAddShow(false);
  }, []);
  const handleItemDelete = useCallback((index: number) => {
    if (window.confirm('Are you sure !')) {
      dispatchItems({
        type: 'DELETE',
        index: index,
      });
      showToaster('deleted', 'positive');
    }
  }, [showToaster]);
  useEffect(() => {
    dispatchExecutionPlannigStep({
      type: 'INPUT',
      item: items,
    });
  }, [items,dispatchExecutionPlannigStep]);
  return (
    <CardBody>
      <DivButton>
        <Label title={<FormattedMessage id={'executionPlanningSteps.INPUT'} />}>
          <Button
            type="button"
            onClick={() => {
              setAddShow('');
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button
            type="button"
            onClick={() => {
              setItemAddShow(!itemAddShow);
            }}
          >
            <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
          </Button>
        </Label>
      </DivButton>
      {itemAddShow && (
        <ItemFormInput setItemAddShow={setItemAddShow} dispatchItems={dispatchItems} />
      )}
      {items &&
        items.map((item: any, index: number) => {
          return index !== itemIndex ? (
            <ListItem
              key={item.id}
              item={item}
              index={index}
              handleItemDelete={handleItemDelete}
              handleItemEdit={handleItemEdit}
            />
          ) : (
            <ItemFormInput
              key={item.id}
              item={item}
              setItemIndex={setItemIndex}
              itemIndex={index}
              dispatchItems={dispatchItems}
            />
          );
        })}
    </CardBody>
  );
};
export default ListInOutput;
