import React, {
  Fragment,
  FunctionComponent,
  memo,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { SubcontractSalesOffer } from '../../../../types/SubcontractSalesOffer';
import ListItemSuncontractSalesItem from './ListItemSuncontractSales';
import ItemHeaders from '../../SubContractSales/ItemHeaders';
import { useQuery } from '@apollo/react-hooks';
import { SubcontractSalesOfferIDs } from '../../../../types/SubcontractSalesOfferID';
import { loader } from 'graphql.macro';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../../components/NoResult/NoResult';
import Transport from '../Transport/Transport';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import useTranstInclude from '../Transport/useTransportInclude';
import SubcontractSalesResult from './SubcontractSalesResult';
import ListSubcontractSalesPreview from './Preview/ListSubcontractSalesPreview';
import ListOfferNote from '../ListOfferNote';
const GET_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/GET_SUBCONTRACT_SALES_OFFER.graphql'
);
const S_GET_SUBCONTRACT_SALES_OFFER = loader(
  '../../../../graphql/offer/subcontractSales/S_GET_SUBCONTRACT_SALES_OFFER.graphql'
);

interface ListSuncontractSalesProps {
  offerId: string;
  offerTransport: OfferTransport[];
  transportIncluded: boolean;
  preview: boolean;
}
const ListSuncontractSales: FunctionComponent<ListSuncontractSalesProps> = ({
  offerId,
  offerTransport,
  transportIncluded,
  preview,
}) => {
  const { subscribeToMore, data, loading, error } = useQuery<SubcontractSalesOfferIDs>(
    GET_SUBCONTRACT_SALES_OFFER,
    {
      variables: {
        id: offerId,
      },
    }
  );
  const [itemId, setItemId] = useState(null);
  useEffect(() => {
    subscribeToMore({
      document: S_GET_SUBCONTRACT_SALES_OFFER,
      variables: {
        id: offerId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        const newFeedItem = subscriptionData.data.subcontractSalesOffer;
        return Object.assign({}, prev, {
          subcontractSalesOffer: newFeedItem,
        });
      },
    });
  }, [subscribeToMore, offerId]);

  const transportUnitPrice = useMemo(() => {
    return handleTransportUnitPrice(
      transportIncluded,
      offerTransport,
      data?.subcontractSalesOffer?.subcontractSalesOffer
    );
  }, [transportIncluded, offerTransport, data]);
  console.log('----->transportIncluded', transportIncluded);
  const { handleTransportInclude } = useTranstInclude({
    data,
    transportUnitPrice: transportUnitPrice,
  });
  console.log('---->ListSuncontractSales', transportUnitPrice);
  return (
    <div>
      {loading ? (
        <StyledSpinnerNext />
      ) : error ? (
        <NoResult />
      ) : (
        data && (
          <Fragment>
            <div style={preview ? { display: 'none' } : {}}>
              <ItemHeaders itemsLenght={1} />
              {data.subcontractSalesOffer.subcontractSalesOffer.map(
                (item: SubcontractSalesOffer, index: number) => {
                  return (
                    (itemId == null || item.id === itemId) && (
                      <ListItemSuncontractSalesItem
                        key={index}
                        item={item}
                        itemId={itemId}
                        setItemId={setItemId}
                        transportUnitPrice={transportUnitPrice}
                        purchaseMaterialOffer={
                          data.subcontractSalesOffer.offerPurchaseOffer?.purchaseOffer
                            ?.purchaseMaterialOffer
                        }
                        purchaseOperationOffer={
                          data.subcontractSalesOffer.offerPurchaseOffer?.purchaseOffer
                            ?.purchaseOperationOffer
                        }
                      />
                    )
                  );
                }
              )}
              <Transport
                handleTransportInclude={handleTransportInclude}
                offerId={offerId}
                offerTransport={offerTransport}
                transportIncluded={transportIncluded}
              />
            </div>
            <div style={preview ? {} : { display: 'none' }}>
              <ListSubcontractSalesPreview
                items={data?.subcontractSalesOffer?.subcontractSalesOffer}
              />
            </div>
            <SubcontractSalesResult
              preview={preview}
              transportIncluded={transportIncluded}
              offerTransport={offerTransport}
              items={data.subcontractSalesOffer.subcontractSalesOffer}
            />
          </Fragment>
        )
      )}
    </div>
  );
};

export default memo(ListSuncontractSales);
const handleTransportUnitPrice = (
  transportIncluded: boolean,
  offerTransport: OfferTransport[],
  subcontractSalesOffer: SubcontractSalesOffer[]
) => {
  if (transportIncluded) {
    const _totalOrderQty = subcontractSalesOffer?.reduce(
      (acc: number, item: SubcontractSalesOffer) => acc + (item?.qty || 0),
      0
    );
    const _totalTransportPriceConfirmed = offerTransport?.reduce(
      (acc: number, item: OfferTransport) =>
        item.approved === true ? acc + item.price : 0,
      0
    );
    return Math.ceil((100 * _totalTransportPriceConfirmed) / _totalOrderQty) / 100;
  } else {
    return 0;
  }
};
