import { useMutation } from '@apollo/react-hooks';
import {
  faArrowToBottom,
  faArrowToTop,
  faMinus,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useCallback, useState } from 'react';
import Button, {
  COLOR,
} from '../../../../components/FormWithElemets/Button/Button';
import { CardBody } from '../../../../components/FormWithElemets/Card.style';
import { Label } from '../../../../components/FormWithElemets/Label.style';
import { useToaster } from '../../../../context/toaster-provider';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import IncludeTransportOffer from './IncludeTransportOffer';
import ListItemTransportOffer from './ListItemTransportOffer';
import TransportForm from './TransportForm';
const DELETE_OFFER_TRANSPORT = loader(
  '../../../../graphql/offer/transport/DELETE_OFFER_TRANSPORT.graphql'
);
interface TransportProps {
  offerTransport: OfferTransport[];
  offerId: string;
  transportIncluded:boolean;
  handleTransportInclude?:Function
} 
const Transport: FunctionComponent<TransportProps> = ({
  offerId,
  offerTransport,
  transportIncluded,
  handleTransportInclude
}) => {
  const { showToaster } = useToaster();
  const [transportShow, setTransportShow] = useState(false);
  const [itemId, setItemId] = useState(null);
  const [itemAddShow, setItemAddShow] = useState(() =>
    offerTransport?.length > 0 ? false : true
  );
  const handleItemEdit = useCallback((id: string) => {
    setItemId(id);
    setItemAddShow(false);
  }, []);
  const [remove] = useMutation(DELETE_OFFER_TRANSPORT);
  const handleItemDelete = useCallback(
    (id: number) => {
      if (window.confirm('Are you sure !')) {
        remove({
          variables: { id: id },
        }).then(() => {
          showToaster('deleted', 'positive');
        });
      }
    },
    [remove, showToaster]
  );
  return (
    <CardBody style={{marginLeft:'25px'}}>
      <Label
        style={{ borderBottom: '1px solid' + COLOR.grey }}
        title={
          <IncludeTransportOffer handleTransportInclude={handleTransportInclude} offerId={offerId} transportIncluded={transportIncluded}/>
        }
      >
        <Button
          type="button"
          onClick={() => {
            setTransportShow((prev) => !prev);
          }}
        >
          <FontAwesomeIcon
            icon={transportShow ? faArrowToBottom : faArrowToTop}
          />
        </Button>
        <Button
          type="button"
          onClick={() => {
            setItemAddShow(!itemAddShow);
          }}
        >
          <FontAwesomeIcon icon={itemAddShow ? faMinus : faPlus} />
        </Button>
      </Label>
      <div style={transportShow ? {} : { display: 'none' }}>
        {itemAddShow && (
          <TransportForm offerId={offerId} setClose={setItemAddShow} />
        )}
        {offerTransport &&
          offerTransport.map((item: any) => {
            return item.id !== itemId ? (
              <ListItemTransportOffer
                key={item.id}
                item={item}
                handleItemDelete={handleItemDelete}
                handleItemEdit={handleItemEdit}
              />
            ) : (
              <TransportForm
                key={item.id}
                offerId={offerId}
                item={item}
                setItemId={setItemId}
              />
            );
          })}
      </div>
    </CardBody>
  );
};
export default Transport;
