import React, { FunctionComponent } from "react"
import { useIntl } from "react-intl"
import { Col } from "../../../../components/FlexBox/FlexBox"
import { COLOR } from "../../../../components/FormWithElemets/Button/Button"
import { PurchaseMaterialOffer } from "../../../../types/PurchaseMaterialOffer"
interface ListProps {
    item: PurchaseMaterialOffer[]
}
const BillOfMaterialItemPurchaseOffer: FunctionComponent<ListProps> = ({ item }) => {
    const intl = useIntl();
    return <Col xs={12}>
        {
            item?.map(itm => {
                return <span key={itm.id} style={{ display: 'inline-block', borderRadius: '5px', backgroundColor: COLOR.yellow, paddingRight: '10px', paddingLeft: '10px', paddingTop: '3px', paddingBottom: '3px', marginRight: '5px' }}>
                    {
                        intl.formatMessage({ id: 'purhaseOffer.material' }) + ' '
                         +(itm.inventory?.name || itm?.name) + ' ' + itm.qty + ' '
                         + (itm.inventory?.units?.name || itm.units?.name)
                    }
                </span>
            })
        }
    </Col>
}
export default BillOfMaterialItemPurchaseOffer