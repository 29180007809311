import React, { useEffect, useState } from 'react';
import { FunctionComponent } from 'react';
import {  useIntl } from 'react-intl';
import { OfferTransport } from '../../../../types/OfferAndDetailsWithOperation';
import { SubcontractSalesOffer } from '../../../../types/SubcontractSalesOffer';
import { handleCeil } from '../../../Functions/Utilities';
interface SubcontractSalesResultProps {
  offerTransport: OfferTransport[];
  items: SubcontractSalesOffer[];
  transportIncluded: boolean;
  preview?: boolean;
}
const SubcontractSalesResult: FunctionComponent<SubcontractSalesResultProps> = ({
  offerTransport,
  items,
  transportIncluded,
  preview = true,
}) => {
  const [transportTotalPrice, setTransportTotalPrice] = useState(0);
  useEffect(() => {
    setTransportTotalPrice(handleTransportTotal(offerTransport));
  }, [offerTransport]);
  const [
    {
      operationTotal,
      grandTotal,
      materialTotal,
      subTotal,
      profitTotal,
      discountTotal,
      taxTotal,
    },
    setTotal,
  ] = useState({
    materialTotal: 0,
    operationTotal: 0,
    subTotal: 0,
    profitTotal: 0,
    discountTotal: 0,
    taxTotal: [],
    grandTotal: 0,
  });
  useEffect(() => {
    const {
      materialTotal,
      taxTotal,
      subTotal,
      profitTotal,
      discountTotal,
      grandTotal,
      operationTotal,
    } = handleCalculate(items);
    const newGrandTotal = transportIncluded
      ? grandTotal
      : grandTotal + transportTotalPrice;
    setTotal(() => ({
      operationTotal: operationTotal,
      materialTotal: materialTotal,
      subTotal: subTotal,
      profitTotal: profitTotal,
      discountTotal: discountTotal,
      taxTotal: taxTotal,
      grandTotal: newGrandTotal,
    }));
  }, [items, transportTotalPrice, transportIncluded]);
  return (
    <div>
      <div className="grid grid-cols-6 print:grid-cols-6 place-items-end justify-items-end mt-20">
        <div className="sm:col-span-2 md:col-span-3 lg:col-span-4 print:col-span-4" />
        <div className="col-span-6 sm:col-span-4 md:col-span-3 lg:col-span-2 print:col-span-2 w-full">
          <ul className="divide-y divide-gray-200 w-full">
            {false && <LiComponent price={materialTotal} title={'materialTotal'} />}
            {false && <LiComponent price={operationTotal} title={'operationTotal'} />}
            <LiComponent price={subTotal} title={'subTotal'} />
            <LiComponent price={profitTotal} title={'profitTotal'} />
            <LiComponent price={discountTotal} title={'discountTotal'} />
            {taxTotal.map((item) => (
              <LiComponentKdv key={item?.kdv} price={item?.taxAmount} title={'kdv'} kdv={item?.kdv} />
            ))}
            {!transportIncluded && (
              <LiComponent price={transportTotalPrice} title={'transportTotal'} />
            )}
            <LiComponent price={grandTotal} title={'grandTotal'} />
          </ul>
        </div>
      </div>
    </div>
  );
};
export default SubcontractSalesResult;

interface LiComponentProps {
  price: number;
  title: string;
  kdv?: number;
}
export const LiComponent: FunctionComponent<LiComponentProps> = ({ price, title }) => {
  const intl = useIntl();
  return (
    price !== 0 && (
      <li className="py-2 px-2">
        <div className="flex space-x-3">
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">
                {intl.formatMessage({ id: 'subcontractSalesOffer.' + title })}
              </h3>
              <p className="text-sm">{handleCeil(price) + ' ₺'}</p>
            </div>
            <p className="text-sm text-gray-500 hidden">h1</p>
          </div>
        </div>
      </li>
    )
  );
};
export const LiComponentKdv: FunctionComponent<LiComponentProps> = ({ price, title, kdv }) => {
  const intl = useIntl();
  console.log("----->sws",price)
  return (
    price !== 0 && (
      <li className="py-2 px-2">
        <div className="flex space-x-3">
          <div className="flex-1 space-y-1">
            <div className="flex items-center justify-between">
              <h3 className="text-sm font-medium">
                {intl.formatMessage({ id: 'subcontractSalesOffer.' + title }) +
                  ' %' +
                  kdv}
              </h3>
              <p className="text-sm">{(price ? handleCeil(price) : 0) + ' ₺'}</p>
            </div>
            <p className="text-sm text-gray-500 hidden">h1</p>
          </div>
        </div>
      </li>
    )
  );
};
const handleTransportTotal = (offerTransport: OfferTransport[]) =>
  offerTransport.reduce(
    (acc: number, item: OfferTransport) =>
      item.approved === true ? acc + item.price : 0,
    0
  );
const handleCalculate = (items: SubcontractSalesOffer[]) => {
  const kdvList = items
    .map((item) => {
      return item.subcontractSalesOfferPrice?.taxRate || 0;
    })
    .filter((value, index, self) => self.indexOf(value) === index);
  console.log("---->kdvList",kdvList)
  const materialTotal = items.reduce((acc: number, item: SubcontractSalesOffer) => {
    const { subcontractSalesOfferPrice } = item;
    const amountTemp =
      subcontractSalesOfferPrice?.subcontractSalesOfferMaterialPrice?.reduce(
        (accc, itemm) => {
          const { amount } = itemm;
          return accc + amount;
        },
        0
      ) || 0;
    return acc + amountTemp;
  }, 0);
  const operationTotal = items.reduce((acc: number, item: SubcontractSalesOffer) => {
    const { subcontractSalesOfferPrice } = item;
    const amountTemp =
      subcontractSalesOfferPrice?.subcontractSalesOfferOperationPrice?.reduce(
        (accc, itemm) => {
          const { amount, profitRate, discountRate } = itemm;
          const profitAmount = amount + (amount * profitRate) / 100;
          const discountAmount = profitAmount - (profitAmount * discountRate) / 100;
          return accc + discountAmount;
        },
        0
      ) || 0;
    return acc + amountTemp;
  }, 0);
  const subTotal = items.reduce((acc, item) => {
    const { subcontractSalesOfferPrice } = item;
    return acc + (subcontractSalesOfferPrice?.amount || 0);
  }, 0);
  const profitTotal = items.reduce((acc, item) => {
    const { subcontractSalesOfferPrice } = item;
    const amount = subcontractSalesOfferPrice?.amount || 0;
    const profitRate = subcontractSalesOfferPrice?.profitRate || 0;
    const profitAmount = (amount * profitRate) / 100;
    return acc + profitAmount;
  }, 0);
  const discountTotal = items.reduce((acc, item) => {
    const { subcontractSalesOfferPrice } = item;
    const amount = subcontractSalesOfferPrice?.amount || 0;
    const profitRate = subcontractSalesOfferPrice?.profitRate || 0;
    const discountRate = subcontractSalesOfferPrice?.discountRate || 0;
    const profitAmount = amount + (amount * profitRate) / 100;
    const discountAmount = (profitAmount * discountRate) / 100;
    return acc + discountAmount;
  }, 0);
  const taxTotal = kdvList.map((kdv) => {
    console.log("---->kdv",kdv)
    const taxAmount = items.reduce((acc, item) => {
      console.log("------>",item)
      if (item?.subcontractSalesOfferPrice?.taxRate === kdv) {
        const { subcontractSalesOfferPrice } = item;
        const amount = subcontractSalesOfferPrice?.amount || 0;
        const profitRate = subcontractSalesOfferPrice?.profitRate || 0;
        const discountRate = subcontractSalesOfferPrice?.discountRate || 0;
        const taxRate = subcontractSalesOfferPrice?.taxRate || 0;
        const profitAmount = amount + (amount * profitRate) / 100;
        const discountAmount = profitAmount - (profitAmount * discountRate) / 100;
        const taxeAmount = (discountAmount * taxRate) / 100;
        return acc + taxeAmount;
      } else {
        return acc + 0;
      }
    }, 0);
    return {
      kdv,
      taxAmount: taxAmount,
    };
  });
  console.log("--->taxTotal",taxTotal)
  const grandTotal =
    subTotal +
    profitTotal -
    discountTotal +
    taxTotal.reduce((acc, i) => acc + i.taxAmount, 0);
  return {
    materialTotal: materialTotal,
    operationTotal: operationTotal,
    subTotal: subTotal,
    discountTotal: discountTotal,
    profitTotal: profitTotal,
    taxTotal: taxTotal,
    grandTotal: grandTotal,
  };
};
/*
     <Row style={{ margin: 0, padding: 0 }}>
        <Col xs={12} sm={4} md={7} lg={8} style={{ margin: 0, padding: 0 }} />
        <Col xs={12} sm={8} md={5} lg={4} style={{ margin: 0, padding: 0 }}>
          <RowCustom style={{ marginTop: '40px' }}>
            <Col xs={12} style={preview ? { display: 'none' } : {}}>
              <Label
                title={<FormattedMessage id="subcontractSalesOffer.materialTotal" />}
              >
                {handleCeil(materialTotal) + ' ₺'}
              </Label>
            </Col>
            <Col xs={12} style={preview ? { display: 'none' } : {}}>
              <Label
                title={<FormattedMessage id="subcontractSalesOffer.operationTotal" />}
              >
                {handleCeil(operationTotal) + ' ₺'}
              </Label>
            </Col>
            <Col xs={12}>
              <Label title={<FormattedMessage id="subcontractSalesOffer.subTotal" />}>
                {handleCeil(subTotal) + ' ₺'}
              </Label>
            </Col>
            <Col xs={12} style={preview ? { display: 'none' } : {}}>
              <Label title={<FormattedMessage id="subcontractSalesOffer.profitTotal" />}>
                {handleCeil(profitTotal) + ' ₺'}
              </Label>
            </Col>
            <Col xs={12} style={preview ? { display: 'none' } : {}}>
              <Label
                title={<FormattedMessage id="subcontractSalesOffer.discountTotal" />}
              >
                {handleCeil(discountTotal) + ' ₺'}
              </Label>
            </Col>
            {taxTotal?.map((item) => (
              <Col xs={12}>
                <Label title={intl.formatMessage({ id: 'offer.kdv' }) + ' %' + item.kdv}>
                  {handleCeil(item.taxAmount) + ' ₺'}
                </Label>
              </Col>
            ))}
            {!transportIncluded && (
              <Col xs={12}>
                <Label
                  style={{ marginBottom: 0, paddingBottom: 0 }}
                  title={<FormattedMessage id="subcontractSalesOffer.transportTotal" />}
                >
                  {handleCeil(transportTotalPrice) + ' ₺'}
                </Label>
              </Col>
            )}
            <Col xs={12}>
              <Label title={<FormattedMessage id="subcontractSalesOffer.grandTotal" />}>
                {handleCeil(grandTotal) + ' ₺'}
              </Label>
            </Col>
          </RowCustom>
        </Col>
      </Row>
 
*/
