// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/';
export const OFFER_REQUEST = '/offer-request';
export const OFFERS = '/offers';
export const COMPANY = '/company';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const ORDERS = '/orders';
export const CUSTOMERS = '/customers';
export const COUPONS = '/coupons';
export const SETTINGS = '/settings';
export const STUFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const INVOICE_SALES = '/sales-invoices';
export const INVOICE_PURCHASE = '/purchase-invoices';
export const WAYBILL_SALES = '/sales-waybills';
export const WAYBILL_PURCHASE = '/purchase-waybills';
export const PLANNING = '/planning';
export const INVOICE_SALES_WITH_ID = '/sales-invoice/:id';
export const INVOICE_PURCHASE_WITH_ID = '/purchase-invoice/:id';
export const WAYBILL_SALES_WITH_ID = '/sales-waybill/:id';
export const WAYBILL_PURCHASE_WITH_ID = '/purchase-waybill/:id';
export const BANKS = '/banks';
export const EXTERNAL_OPERATION_TYPES = '/external-operation-type';
export const INTERNAL_OPERATION_TYPES = '/internal-operation-type';
export const MACHINERY_AND_EQUIPMENT = '/machinery-equipment';
export const MATERIAL_TYPE = '/material-type';
export const STATUS = '/status';
export const UNITS = '/unit';
export const USERS = '/user';
export const WAREHOUSE = '/warehouse';
export const WAREHOUSE_SECTION = '/warehouse-section';
export const WAREHOUSE_LEVEL = '/warehouse-level';
export const COST_CENTER = '/cost-center';
export const INVENTORY_CATEGORY = '/inventory-category';
export const INVENTORY = '/inventory';
export const CASH_BOOK = '/cash-book';
export const SHIFT = '/shift';
export const PRODUCT = '/product';
export const PRODUCT_PRICE = '/product-price';
export const PRODUCT_RECORD = '/product-record';
export const ORDER = '/order';
export const OPERATION = '/operation';
export const EBOM = '/ebom';
export const MBOM = '/mbom';
export const QUALITY_CONTROL = '/quality-control';
export const SHOP_FLOOR = '/shop-floor';
export const SHOP_FLOOR_ORDER = '/shop-floor-orders';
export const PRODUCT_RECORD_ID = '/product-record-barcode/:id';
export const AQL_SAMPLE_RULE = '/aql-sample-rule';
export const QUALITY_CONTROL_REASON = '/quality-control-reason'
export const PERMISSION = '/permission';
export const MEASUREMENT_RULE_UNIT = '/measurement-rule-unit';
export const SHOP_FLOOR_ORDER_STOP_REASON = '/shop-floor-order-stop-reason';
export const SHOP_FLOOR_ORDER_CANCEL_REASON = '/shop-floor-order-cancel-reason';
export const SELECT_PRINTER = '/select-printer';
export const CANCELED_OR_DECLINED_REASON = '/canceled-or-declined-reason';
export const MATERIAL = '/material';
export const PRODUCT_RECORD_REASON = '/product-record-reason';
export const OFFER_TYPE ="/offer-type";
export const BILL_OF_MATERIAL ="/bill-of-material";
export const PURCHASE_OFFER ="/purchase-offer";
export const CALCULATOR ="/calculator";
export const MANUFACTURER ="/manufacturer";
export const MACHINERY_AND_EQUIPMENT_STATUS ="/machinery-equipment-status";
export const DEPARTMENT ="/departments";
export const QUALITY_DOCUMENT_TYPE ="/quality-document-types";
export const QUALITY_ACTION ="/quality-actions";
export const QUALITY_IMPROVEMENT_ACTION_PLAN ="/quality-improvement-action-plans";
export const QUALITY_OBJECTIVE ="/quality-objectives";
export const QUALITY_OBJECTIVE_RESPONSIBLE ="/quality-objective-responsibles";
export const QUALITY_DOCUMENT ="/quality-documents";
export const QUALITY_OBJECTIVE_RESOURCE ="/quality-objective-resources";
export const QUALITY_DOCUMENT_MASTER ="/quality-document-masters";

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
