import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from '../../../../components/FormWithElemets/Form.style';
import { useToaster } from '../../../../context/toaster-provider';
import { ProductSalesOffer } from '../../../../types/ProductSalesOffer';
import { handleDate } from '../../../Functions/Utilities';
import InputForm from './InputForm';
const UPDATE_PRODUCT_SALES_OFFER = loader(
  '../../../../graphql/offer/productSales/UPDATE_PRODUCT_SALES_OFFER.graphql'
);
interface UpdateAddProductSalesItemProps {
  item: ProductSalesOffer;
  setClose:Function
}
const UpdateProductSalesForm: FunctionComponent<UpdateAddProductSalesItemProps> = ({
  item,
  setClose
}) => {
  const { showToaster } = useToaster();
  const { register, setValue, control,  errors,handleSubmit  } = useForm({
    defaultValues:item ? {
      productId: item?.product ? [{id:item?.product?.id,label:item?.product.name}] :undefined,
      estimatedDeliveryDate: [new Date(item?.estimatedDeliveryDate || new Date())],
      qty:item?.qty || 0,
      unitPrice: item?.unitPrice || 0,
      unitsId:item?.product?.inventory?.units ? [{id:item?.product?.inventory?.units.id,label:item?.product.inventory.units.name}] : undefined
    }:{}
  });
  const onSubmit = ({qty, productId, estimatedDeliveryDate, unitPrice}) => {
        updateInlineProduct({
          variables: {
            id: item.id,
            productSalesOffer: handleNewProductItem({
              qty,
              productId,
              estimatedDeliveryDate,
              unitPrice: unitPrice || 0,
              productRevision: item?.productRevision,
            }),
          },
        }).then(() => {
          showToaster('updated', 'positive');
        });
      handleResetForm();
  };
  const handleResetForm = () => {
    if (item) {
      setClose(null);
    }
  };
  const [updateInlineProduct] = useMutation(UPDATE_PRODUCT_SALES_OFFER);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <InputForm
        register={register}
        errors={errors}
        setValue={setValue}
        control={control}
        item={item}
        handleResetForm={handleResetForm}
      />
    </Form>
  );
};
export default UpdateProductSalesForm;
const handleNewProductItem = ({
  qty,
  productId,
  estimatedDeliveryDate,
  unitPrice,
  productRevision,
}) => {
  return {
    estimatedDeliveryDate: handleDate(estimatedDeliveryDate[0]).toISOString(),
    productId: productId[0].id,
    productRevisionId: productRevision?.id,
    qty: +qty,
    unitPrice: +unitPrice,
  };
};
