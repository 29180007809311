import React, {
  createContext,
  Fragment,
  FunctionComponent,
  useContext,
  useState,
} from 'react';
import { CardTitle, CardBody } from '../../../components/FormWithElemets/Card.style';
import { FormattedMessage, useIntl } from 'react-intl';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import { ComponentStatusContext } from '../Offer';
import BillOfMaterial from '../../BillOfMaterial/BillOfMaterial/BillOfMaterial';
import AddBillOfMaterialForm from '../../BillOfMaterial/AddBillOfMaterialForm';
import AddSubconractSalesOfferForm from './AddSubContractSalesForm';
import Breadcrumb from '../../TailWinduiHTML/Form/Breadcrumb/Breadcrumb';
import Breadcrumbs from '../../TailWinduiHTML/Form/Breadcrumb/Breadcrumbs';
export const ContextSubContractSalesStatus = createContext(null);
export type Status = {
  status:
    | 'AddSubcontractSalesOffer'
    | 'AddBillOfMaterial'
    | 'UpdateBillOfMaterial'
    | 'AddExecutingPlan';
  billOfMaterialName: '';
  billOfMaterialId: '';
};
const SubContractSalesOffer: FunctionComponent = () => {
  const { dispatchComponentStatus } = useContext(ComponentStatusContext);
  const [subcontractSalesStatus, setSubcontractSalesStatus] = useState<Status>({
    status: 'AddSubcontractSalesOffer',
    billOfMaterialName: '',
    billOfMaterialId: '',
  });
  const handleBack = () => {
    if (window.confirm('Kaydetmeden Çıkış Yapıyorsunuz..!')) {
      const { status } = subcontractSalesStatus;
      status === 'AddSubcontractSalesOffer'
        ? dispatchComponentStatus({ type: 'LIST' })
        : setSubcontractSalesStatus({
            status: 'AddSubcontractSalesOffer',
            billOfMaterialName: '',
            billOfMaterialId: '',
          });
    }
  };
  const handleCompleted = ({ billOfMaterialId, billOfMaterialName }) => {
    setSubcontractSalesStatus({
      status: 'AddSubcontractSalesOffer',
      billOfMaterialName: billOfMaterialName,
      billOfMaterialId: billOfMaterialId,
    });
  };
  return (
    <Fragment>
      <CardTitleComponent
        status={subcontractSalesStatus.status}
        handleBack={handleBack}
      />
      <CardBody
        style={
          subcontractSalesStatus.status !== 'AddSubcontractSalesOffer'
            ? { display: 'none' }
            : {}
        }
      >
        <ContextSubContractSalesStatus.Provider value={{ setSubcontractSalesStatus }}>
          <AddSubconractSalesOfferForm subcontractSalesStatus={subcontractSalesStatus} />
        </ContextSubContractSalesStatus.Provider>
      </CardBody>
      <AddBillOfMaterialFormComponent
        subcontractSalesStatus={subcontractSalesStatus}
        handleSaveClose={handleCompleted}
      />
      <BillOfMaterialComponent
        subcontractSalesStatus={subcontractSalesStatus}
        handleCompleted={handleCompleted}
      />
    </Fragment>
  );
};
export default SubContractSalesOffer;

interface CardTitleComponentProps {
  status: string;
  handleBack: Function;
}
const CardTitleComponent: FunctionComponent<CardTitleComponentProps> = ({
  status,
  handleBack,
}) => {
  const intl = useIntl();
  const handleTitle = () => {
    return (
      <Breadcrumbs onClick={()=>handleBack()} title={intl.formatMessage({ id: 'subcontractSalesOffer.add' })} >
        {status === 'AddBillOfMaterial' && (
          <Breadcrumb>{intl.formatMessage({ id: 'billOfMaterial.add' })}</Breadcrumb>
        )}
        {status === 'UpdateBillOfMaterial' && (
          <Breadcrumb>{intl.formatMessage({ id: 'menu.billOfMaterial' })}</Breadcrumb>
        )}
      </Breadcrumbs>
    );
  };
  return (
    <CardTitle title={handleTitle()}>
      {status !== 'UpdateBillOfMaterial' && (
        <Button onClick={handleBack} color={COLOR.grey}>
          <FormattedMessage id={'button.back'} />
        </Button>
      )}
    </CardTitle>
  );
};

interface BillOfMaterialComponentProps {
  subcontractSalesStatus: Status;
  handleCompleted: Function;
}
const BillOfMaterialComponent: FunctionComponent<BillOfMaterialComponentProps> = ({
  subcontractSalesStatus,
  handleCompleted,
}) => {
  const { status, billOfMaterialId } = subcontractSalesStatus;
  return status === 'UpdateBillOfMaterial' ? (
    <BillOfMaterial
      billOfMaterialId={billOfMaterialId}
      handleCompleted={handleCompleted}
    />
  ) : null;
};
interface AddBillOfMaterialFormComponentProps {
  subcontractSalesStatus: Status;
  handleSaveClose: Function;
}
const AddBillOfMaterialFormComponent: FunctionComponent<AddBillOfMaterialFormComponentProps> = ({
  subcontractSalesStatus,
  handleSaveClose,
}) => {
  const { status, billOfMaterialName } = subcontractSalesStatus;
  return status === 'AddBillOfMaterial' ? (
    <AddBillOfMaterialForm
      billOfMaterialName={billOfMaterialName}
      handleSaveClose={handleSaveClose}
    />
  ) : null;
};
