import React, { useEffect } from 'react';
import { withRouter, useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import _ from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTachometerAlt,
  faBuilding,
  faUsers,
  faSlidersVSquare,
  faWarehouseAlt,
  faBadgeDollar,
  faFileInvoiceDollar,
  faBoxFull,
  faMoneyCheckEditAlt,
  faIndustryAlt,
  faMindShare,
  faClipboardCheck,
  faFileChartPie,
  faSortDown,
} from '@fortawesome/pro-regular-svg-icons';
import { Navigation } from 'baseui/side-navigation';
import { SidebarWrapper, SideBarItemArrow } from './Sidebar.style';
import {
  DASHBOARD,
  OFFERS,
  COMPANY,
  INVOICE_SALES,
  INVOICE_PURCHASE,
  BANKS,
  EXTERNAL_OPERATION_TYPES,
  INTERNAL_OPERATION_TYPES,
  MACHINERY_AND_EQUIPMENT,
  MATERIAL_TYPE,
  UNITS,
  USERS,
  WAYBILL_SALES,
  WAYBILL_PURCHASE,
  WAREHOUSE,
  COST_CENTER,
  INVENTORY,
  SHIFT,
  PRODUCT,
  PRODUCT_PRICE,
  PRODUCT_RECORD,
  MBOM,
  ORDER,
  OPERATION,
  QUALITY_CONTROL,
  SHOP_FLOOR,
  AQL_SAMPLE_RULE,
  QUALITY_CONTROL_REASON,
  PERMISSION,
  PLANNING
} from '../../../settings/constants';

// @ts-ignore
export default withRouter(function Sidebar({ refs, style }) {
  const historyNew = useHistory();
  const [activeItemId, setActiveItemId] = React.useState<string>('/');
  const location = useLocation();
  useEffect(() => {
    setActiveItemId(_.keys(queryString.parse(location.pathname))[0]);
  }, [location]);
  return (
    <SidebarWrapper ref={refs} style={style}>
      <Navigation
        overrides={{
          Root: {
            style: () => {
              return {
                overflowY: 'auto',
              };
            },
          },
          NavItem: {
            style: ({ $level }) => {
              return $level === 1
                ? { paddingLeft: 'calc(12px)' }
                : { paddingLeft: 'calc(32px)' };
            },
          },
        }}
        items={[
          {
            title: (
              <>
                <FontAwesomeIcon icon={faTachometerAlt} />{' '}
                <FormattedMessage id="menu.dashboard" />{' '}
              </>
            ),
            itemId: DASHBOARD,
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faBuilding} />{' '}
                <FormattedMessage id="menu.companies" />{' '}
              </>
            ),
            itemId: COMPANY,
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faUsers} /> <FormattedMessage id="menu.users" />{' '}
              </>
            ),
            itemId: USERS,
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faIndustryAlt} />{' '}
                <FormattedMessage id="menu.production" />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.MBOM" />,
                itemId: MBOM,
              },
              {
                title: <FormattedMessage id="menu.operations" />,
                itemId: OPERATION,
              },
              {
                title: <FormattedMessage id="menu.shop_floor" />,
                itemId: SHOP_FLOOR,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faMindShare} />{' '}
                <FormattedMessage id="menu.planing" />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.planing_management" />,
                itemId: PLANNING,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faClipboardCheck} />{' '}
                <FormattedMessage id="menu.quality_control" />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.quality_control" />,
                itemId: QUALITY_CONTROL,
              },
              {
                title: <FormattedMessage id="menu.aql_sample_rule" />,
                itemId: AQL_SAMPLE_RULE,
              },
              {
                title: <FormattedMessage id="menu.quality_control_reason" />,
                itemId: QUALITY_CONTROL_REASON,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faFileChartPie} />{' '}
                <FormattedMessage id="menu.reports" />{' '}
              </>
            ),
            disabled: true,
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faClipboardCheck} />{' '}
                <FormattedMessage id="menu.order" />{' '}
              </>
            ),
            itemId: ORDER,
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faBadgeDollar} />{' '}
                <FormattedMessage id="menu.sales" />{' '}
                <SideBarItemArrow icon={faSortDown} />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.offers" />,
                itemId: OFFERS,
              },
              {
                title: (
                  <FormattedMessage
                    id="menu.invoices_with_type"
                    values={{ type: <FormattedMessage id="menu.sales" /> }}
                  />
                ),
                itemId: INVOICE_SALES,
              },
              {
                title: (
                  <FormattedMessage
                    id="menu.waybills_with_type"
                    values={{ type: <FormattedMessage id="menu.sales" /> }}
                  />
                ),
                itemId: WAYBILL_SALES,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faMoneyCheckEditAlt} />{' '}
                <FormattedMessage id="menu.purchases" />{' '}
                <SideBarItemArrow icon={faSortDown} />{' '}
              </>
            ),
            subNav: [
              {
                title: (
                  <FormattedMessage
                    id="menu.invoices_with_type"
                    values={{ type: <FormattedMessage id="menu.purchase" /> }}
                  />
                ),
                itemId: INVOICE_PURCHASE,
              },
              {
                title: (
                  <FormattedMessage
                    id="menu.waybills_with_type"
                    values={{ type: <FormattedMessage id="menu.purchase" /> }}
                  />
                ),
                itemId: WAYBILL_PURCHASE,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faFileInvoiceDollar} />{' '}
                <FormattedMessage id="menu.accounting" />{' '}
                <SideBarItemArrow icon={faSortDown} />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.banks" />,
                itemId: BANKS,
              },
              {
                title: <FormattedMessage id="menu.cost_centers" />,
                itemId: COST_CENTER,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faBoxFull} />{' '}
                <FormattedMessage id="menu.inventories_and_products" />{' '}
                <SideBarItemArrow icon={faSortDown} />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.inventories" />,
                itemId: INVENTORY,
              },
              {
                title: <FormattedMessage id="menu.products" />,
                itemId: PRODUCT,
              },
              {
                title: <FormattedMessage id="menu.product_prices" />,
                itemId: PRODUCT_PRICE,
              },
              {
                title: <FormattedMessage id="menu.product_records" />,
                itemId: PRODUCT_RECORD,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faWarehouseAlt} />{' '}
                <FormattedMessage id="menu.warehouses_and_logistics" />{' '}
                <SideBarItemArrow icon={faSortDown} />{' '}
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.warehouses" />,
                itemId: WAREHOUSE,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faSlidersVSquare} />{' '}
                <FormattedMessage id="menu.settings_definitions" />{' '}
                <SideBarItemArrow icon={faSortDown} />
              </>
            ),
            subNav: [
              {
                title: <FormattedMessage id="menu.external_operation_types" />,
                itemId: EXTERNAL_OPERATION_TYPES,
              },
              {
                title: <FormattedMessage id="menu.internal_operation_types" />,
                itemId: INTERNAL_OPERATION_TYPES,
              },
              {
                title: <FormattedMessage id="menu.shifts" />,
                itemId: SHIFT,
              },
              {
                title: <FormattedMessage id="menu.machinery_and_equipments" />,
                itemId: MACHINERY_AND_EQUIPMENT,
              },
              {
                title: <FormattedMessage id="menu.material_types" />,
                itemId: MATERIAL_TYPE,
              },
              {
                title: <FormattedMessage id="menu.units" />,
                itemId: UNITS,
              },
            ],
          },
          {
            title: (
              <>
                <FontAwesomeIcon icon={faClipboardCheck} />{' '}
                <FormattedMessage id="menu.permission" />{' '}
              </>
            ),
            itemId: PERMISSION,
          },
        ]}
        activeItemId={activeItemId}
        onChange={({ event, item }) => {
          event.preventDefault();
          if (item.itemId) {
            setActiveItemId(item.itemId);
            historyNew.push(item.itemId);
          }
        }}
      />
    </SidebarWrapper>
  );
});
