import React, {  FunctionComponent, memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col,  RowCustom } from '../../../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../../../components/FormWithElemets/Label.style';
import { OperationSalesOffer } from '../../../../types/OperationSalesOffer';
import { handleLocalDateString } from '../../../Functions/Utilities';

interface ListItemOperationSalesProps {
    item: OperationSalesOffer;
}
const ListItemOperationSales: FunctionComponent<ListItemOperationSalesProps> = memo(
  ({ item }) => {
    return (
      <RowCustom>
        <Col xs={4}>
          <LabelCustom title={<FormattedMessage id="offer.item" />}>
            {item?.operation?.name || 'No Item'}
          </LabelCustom> 
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.estimatedDeliveryDate" />}>
            {handleLocalDateString(item?.estimatedDeliveryDate)}
          </LabelCustom>
        </Col>
        <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.qty" />}>
            {item?.qty} &nbsp; {item?.units?.name || 'No Unit'}
          </LabelCustom>
        </Col>
        {/* <Col xs={2}>
          <LabelCustom title={<FormattedMessage id="offer.unitPrice" />}>
            {item.unitPrice}
          </LabelCustom>
        </Col> */}
        {/* <ColButton xs={2}>
          <Button
            type="button"
            style={{ paddingLeft: '15px', paddingRight: '15px' }}
            onClick={() => {
              setItemId(item.id);
            }}
          >
            <FontAwesomeIcon icon={faExpand} size="1x" />
          </Button>
        </ColButton> */}
      </RowCustom>
    );
  }
);
export default ListItemOperationSales;
