import React, { useEffect, useState } from 'react';
import { loader } from 'graphql.macro';
import { useQuery } from '@apollo/react-hooks';
import FormControl from '../../components/FormWithElemets/FormControl';
import { FormattedMessage } from 'react-intl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { TYPE } from 'baseui/select';
import _ from 'lodash';
import { BillOfMaterialInventories } from '../../types/BillOfMaterialInventories';

const GET_BILL_OF_MATERIAL_INVENTORIES = loader(
  '../../graphql/executionPlanning/GET_BILL_OF_MATERIAL_INVENTORIES.graphql'
);
interface ListProps {
  billOfMaterialId: string;
  name: string;
  control: any;
  errors: any;
  required: boolean;
  creatable?: boolean;
  disabled?: boolean | undefined;
  inputMaterialIds?: string[];
}
const SelectBillOfMaterialInventories = ({
  billOfMaterialId,
  name,
  control,
  errors,
  required,
  creatable = false,
  disabled,
  inputMaterialIds,
}: ListProps) => {
  const [newData, setNewData] = useState([]);
  const { data, loading } = useQuery<BillOfMaterialInventories>(
    GET_BILL_OF_MATERIAL_INVENTORIES,
    {
      variables: {
        id: billOfMaterialId,
      },
      fetchPolicy: 'network-only',
    }
  );
  useEffect(() => {
    if (data) {
      const newDataTemp = data.billOfMaterialInventories
        .filter((x) => !inputMaterialIds?.includes(x.id))
        .map((item) => {
          const index = item?.name?.lastIndexOf('@@');
          const nameTemp = item?.name;
          const newName = index !== -1 ? nameTemp.slice(0, index) : nameTemp;
          return {
            ...item,
            name: newName,
          };
        });
      setNewData(() => newDataTemp);
    }
  }, [data]);
  const [unit, setUnit] = useState<{ id: string; label: string }[]>(undefined);
  const [inventoryType, setInventoryType] = useState<string>('');
  const handleChange = ({ value }) => {
    if (data) {
      const inventoryItem = data.billOfMaterialInventories.find(
        (item) => item.id === value[0]?.id
      );
      if (inventoryItem) {
        setUnit([
          {
            id: inventoryItem?.units?.id,
            label: inventoryItem?.units?.name,
          },
        ]);
        setInventoryType(inventoryItem.inventoryType?.id || '');
      }
    }
  };
  return {
    select: (
      <FormControl
        label={<FormattedMessage id={'inventory.' + name} />}
        error={_.get(errors, name) ? 'Please input a valid Inventory' : null}
      >
        <Select
          data={newData}
          controller={{
            name: name,
            isLoading: loading,
            type: TYPE.search,
            control,
            rules: { required: required },
            creatable: creatable,
            searchable: true,
            filterOutSelected: false,
            disabled: disabled,
            onChange: ([selected]) => {
              handleChange(selected);
              return selected.option ? selected.value : undefined;
            },
          }}
        />
      </FormControl>
    ),
    unit: unit,
    setInventoryUnit: setUnit,
    inventoryTypeId: inventoryType,
  };
};

export default SelectBillOfMaterialInventories;
