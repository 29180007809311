import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { FormControl } from 'baseui/form-control';
import { Input, SIZE } from 'baseui/input';
import { useForm } from 'react-hook-form';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
const ADD_PRODUCT_PRICE = loader('../../graphql/price/ADD_PRODUCT_PRICE.graphql');
const UPDATE_PRODUCT_PRICE = loader('../../graphql/price/UPDATE_PRODUCT_PRICE.graphql');
const SEARCH_PRODUCT_PRICES = loader('../../graphql/price/SEARCH_PRODUCT_PRICES.graphql');

const GET_PRODUCTS = loader('../../graphql/product/GET_PRODUCTS.graphql');
const PriceForm: FunctionComponent = () => {
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    getValues,
    control,
    watch,
  } = useForm();
  const { data: dataProduct } = useQuery(GET_PRODUCTS);
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (dataDispatch?.priceItem) {
      setValue('productId', [{ id: dataDispatch.id, label: dataDispatch.name }]);
      setValue('from', dataDispatch?.priceItem ? dataDispatch?.priceItem?.from : 0);
      setValue('price', dataDispatch?.priceItem?.price);
    } else if (dataDispatch) {
      setValue('productId', [{ id: dataDispatch.id, label: dataDispatch.name }]);
    }
  }, [dataDispatch, setValue]);

  const rangeFromToRangeDisplay = watch().rangeFromToRangeDisplay;
  useEffect(() => {
    if (rangeFromToRangeDisplay) {
      setValue('from', dataDispatch?.priceItem ? dataDispatch?.priceItem?.from : 0);
    }
  }, [rangeFromToRangeDisplay, dataDispatch, setValue]);

  const onSubmit = ({ from, price, productId }) => {
    dataDispatch?.priceItem
      ? updateProduct({
          variables: {
            id: dataDispatch.priceItem.id,
            from: Number(from) === 0 ? 0 : Number(from) || null,
            price: Number(price),
          },
        })
      : addProduct({
          variables: {
            from: Number(from) === 0 ? 0 : Number(from) || null,
            price: Number(price),
            productId: dataDispatch?.id || productId[0].id,
          },
        });
  };
  const [addProduct] = useMutation(ADD_PRODUCT_PRICE, {
    update(cache, { data: { createPriceList } }) {
      const { searchProductWithPrice } = cache.readQuery({
        query: SEARCH_PRODUCT_PRICES,
        variables: { name: null },
      });
      const newSearchProductWithPrice = searchProductWithPrice.map((item) => {
        if (getValues().productId[0].id === item.id) {
          item.priceList = item.priceList.concat([createPriceList]);
          return item;
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: SEARCH_PRODUCT_PRICES,
        variables: { name: null },
        data: {
          searchProductWithPrice: newSearchProductWithPrice,
        },
      });
      closeDrawer();
    },
  });
  const [updateProduct] = useMutation(UPDATE_PRODUCT_PRICE, {
    update(cache) {
      const { searchProductWithPrice } = cache.readQuery({
        query: SEARCH_PRODUCT_PRICES,
        variables: { name: null },
      });
      const newSearchProductWithPrice = searchProductWithPrice.map((item) => {
        if (getValues().productId[0].id === item.id) {
          item.priceList = item.priceList.map((itm) => {
            if (dataDispatch.priceItem.id === item.id) {
              return {
                ...itm,
                from: getValues().from,
                price: getValues().price,
              };
            } else {
              return itm;
            }
          });
          return item;
        } else {
          return item;
        }
      });
      cache.writeQuery({
        query: SEARCH_PRODUCT_PRICES,
        variables: { name: null },
        data: { searchProductWithPrice: newSearchProductWithPrice },
      });
      closeDrawer();
    },
  });
  return (
    <Card
      title={
        <FormattedMessage
          id={dataDispatch?.priceItem ? 'product_price.update' : 'product_price.create'}
        />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <FormControl
          label={<FormattedMessage id="product_price.product_name" />}
          error={errors.productId ? 'Please select a valid Product Name' : null}
          overrides={{
            Label: {
              style: () => {
                return {
                  color: '#454b68',
                };
              },
            },
          }}
        >
          <Select
            data={dataProduct && dataProduct.products}
            controller={{
              name: 'productId',
              control: control,
              disabled: !!dataDispatch,
              error: errors.productId,
              overrides: errors.productId ? { After: Negative } : { After: Positive },
              onChange: ([selected]) => {
                return selected.option ? selected.value : undefined;
              },
            }}
          />
        </FormControl>
        <Row style={{ margin: 0, padding: 0 }}>
          <Col sm={6}>
            <FormControl
              label={<FormattedMessage id="product_price.from_range" />}
              error={errors.from ? 'Please input a valid From Range' : null}
            >
              <Input
                type="number"
                size={SIZE.compact}
                name="from"
                inputRef={register({
                  required: true,
                })}
                error={errors.from}
                overrides={errors.from ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
          <Col sm={6}>
            <FormControl
              label={<FormattedMessage id="product_price.price" />}
              error={
                errors?.price?.type === 'required'
                  ? 'Please input a valid Price'
                  : errors?.price?.type === 'pattern'
                  ? 'Please input a Number Only'
                  : null
              }
            >
              <Input
                type="text"
                size={SIZE.compact}
                name="price"
                inputRef={register({
                  required: true,
                  pattern: /^[0-9]*\.?[0-9]*$/,
                })}
                error={errors.price}
                overrides={errors.price ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
        </Row>
        <Row
          end="md"
          style={{
            width: '100%',
            marginTop: '50px',
            marginLeft: 0,
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
          }}
        >
          <Col md={6}>
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>
          </Col>
          <Col md={6}>
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage
                id={dataDispatch?.priceItem ? 'button.update' : 'button.create'}
              />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default PriceForm;
