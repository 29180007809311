import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { useToaster } from '../../../../../context/toaster-provider';
const UPDATE_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferMaterialPrice/UPDATE_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE.graphql'
);
const ADD_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferMaterialPrice/ADD_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE.graphql'
);
const useMaterialPriceUpdate = () => {
  const { showToaster } = useToaster();
  //const [result, setResult] = useState(false);
  const priceUpdate = ({
    itemPrice,
    inventoryId,
    qty,
    unitPrice,
    billOfMaterialItemId,
    subcontractSalesOfferId,
    subcontractSalesOfferPriceId,
  }) => {
    const amount = Math.ceil(100 * qty * unitPrice) / 100;
    itemPrice?.id
      ? update({
          variables: {
            id: itemPrice?.id,
            qty: +qty,
            unitPrice: +unitPrice,
            amount: amount,
            profitRate: +itemPrice?.profitRate || 0,
          },
        }).then(() => {
          showToaster('updated', 'positive');
        })
      : add({
          variables: {
            billOfMaterialItemId: billOfMaterialItemId,
            subcontractSalesOfferId: subcontractSalesOfferId,
            subcontractSalesOfferPriceId: subcontractSalesOfferPriceId,
            inventoryId: inventoryId,
            qty: +qty,
            unitPrice: +unitPrice,
            amount: +amount,
            profitRate: +itemPrice?.profitRate || 0,
          },
        }).then(() => {
          showToaster('created', 'positive');
        });
    //setResult(true);
  };
  const [update] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE);
  const [add] = useMutation(ADD_SUBCONTRACT_SALES_OFFER_MATERIAL_PRICE);

  return {
    priceUpdate,
  };
};
export default useMaterialPriceUpdate;
// useEffect(() => {
//   offerMaterialPriceId
//     ? update({
//         variables: {
//           id: offerMaterialPriceId,
//           qty: qty,
//           unitPrice: unitPrice,
//           amount: +(qty * unitPrice).toFixed(2),
//         },
//       }).then(() => {
//         showToaster('updated', 'positive');
//       })
//     : add({
//         variables: {
//           billOfMaterialItemId: billOfMaterialItemId,
//           subcontractSalesOfferId: subcontractSalesOfferId,
//           subcontractSalesOfferPriceId: subcontractSalesOfferPriceId,
//           inventoryId: inventoryId,
//           qty: qty,
//           unitPrice: unitPrice,
//           amount: +(qty * unitPrice).toFixed(2),
//         },
//       }).then(() => {
//         showToaster('created', 'positive');
//       });
//   setResult(true);
// }, [
//   unitPrice,
//   offerMaterialPriceId,
//   qty,
//   billOfMaterialItemId,
//   subcontractSalesOfferId,
//   subcontractSalesOfferPriceId,
//   inventoryId,
// ]);
