import { styled } from 'baseui';
import { Grid as Grids, Row as Rows, Col as Cols } from 'react-flexbox-grid';
import { COLOR } from '../FormWithElemets/Button/Button';

export const Grid = styled(Grids, () => ({}));

export const GridFull = styled(Grids, () => ({
  height: '99%',
}));

export const Row = styled(Rows, () => ({
  margin: 0,
  padding: 0,
  ':last-child': {
    marginBottom: '0px',
  },
}));
export const DivSubmit = styled('div', () => ({
  margin: 0,
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
  ':last-child': {
    marginBottom: '0px',
  },
}));
export const ColButton = styled(Cols, () => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignContent: 'center',
  flexWrap: 'wrap',
}));

export const RowFull = styled(Rows, () => ({
  margin: '0 -15px 30px',
  height: '99%',

  ':last-child': {
    marginBottom: '0px',
  },
}));

export const Col = styled(Cols, () => ({
  padding: '0 8px',
}));

export const ColFull = styled(Cols, () => ({
  padding: '0 15px',
  height: '99%',
}));

export const RowTitle = styled(Rows, ({ $theme }) => ({
  ...$theme.typography.font350,
  margin: '0 0 10px 0',
  padding: 0,
  borderBottom: '1px solid #d2d6dc',
  color: COLOR.grey,
  '@media all and (max-width: 1000px)': {
    display: 'none',
  },
}));
export const DivButton = styled('div', () => ({
  margin: '0 0 10px 0',
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  borderBottom: '1px solid #d2d6dc',
}));
export const DivButtonTitle = styled('div', () => ({
  margin: '0 0 10px 0',
  padding: 0,
  display: 'flex',
  justifyContent: 'flex-end',
  borderBottom: '1px solid #d2d6dc',
  '@media all and (min-width: 1000px)': {
    display: 'none',
  },
}));
export const RowCustom = styled(Rows, () => ({
  margin: 0,
  padding: '5px 0 5px 0',
  borderBottom: '1px solid #d2d6dc',
  ':last-child': {
    marginBottom: '0px',
    borderBottom: '0px',
  },
  // ':first-child': {
  //   borderBottom: '0px',
  // },
  ':hover': {
    boxShadow: '0px 0px 0px 1px rgba(209,205,209,1)',
    transform: 'translateY(.5px)',
    backgroundColor: '#d2d6dc',
  },
}));
