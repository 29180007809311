import React, { FunctionComponent, LegacyRef, memo, useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { Company } from '../../../../../types/Company';
import { PurchaseMaterialOffer } from '../../../../../types/PurchaseMaterialOffer';
import { PurchaseOperationOffer } from '../../../../../types/PurchaseOperationOffer';
import Card from '../../../../TailWinduiHTML/Card/Card';
import CardBody from '../../../../TailWinduiHTML/Card/CardBody';
import CardFooter from '../../../../Offers/InOfferStage/PreviewCommon/CardFooterCustom';
import CompanyInformation from '../../../../TailWinduiComponent/CompanyInformation';
import Headers from '../../../../TailWinduiComponent/Headers';
import HeadersPurchaseOffer from './HeadersPurchaseOffer';
import ListItePurhaseOffer from './ListItemPurhaseOffer';

interface ListPurhaseOfferProps {
  itemMaterials: PurchaseMaterialOffer[];
  itemOperations: PurchaseOperationOffer[];
  company: Company;
  print: boolean;
  setPrint: Function;
  componentRef:any
}
const ListPurhaseOffer: FunctionComponent<ListPurhaseOfferProps> = ({
  itemMaterials,
  itemOperations,
  company,
  componentRef
}) => {
  return (
    <Card ref={componentRef}>
      <Headers formNameEN={'BID FORM'} formNameTR={'TEKLİF FORMU'} />
      <CompanyInformation company={company} />
      <div className="my-20" />
      <HeadersPurchaseOffer />
      <CardBody>
        {itemMaterials.map((item: PurchaseMaterialOffer) => {
          return item ? <ListItePurhaseOffer key={item?.id} itemMaterial={item} /> : null;
        })}
        {itemOperations.map((item: PurchaseOperationOffer) => {
          return item ? (
            <ListItePurhaseOffer key={item?.id} itemOperation={item} />
          ) : null;
        })}
      </CardBody>
      <div className="my-20" />
      <CardFooter />
    </Card>
  );
};
export default memo(ListPurhaseOffer);
