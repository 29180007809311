import React, { useCallback, useEffect, FunctionComponent } from 'react';
import FormControl from '../../components/FormWithElemets/FormControl';
import { Input, SIZE } from 'baseui/input';
import {  useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import { useDrawerState, useDrawerDispatch } from '../../context/DrawerContext';
import { Form, Card } from '../../components/FormWithElemets/Form.style';
import { Negative, Positive } from '../../components/General/NegativePositive';
import { FormattedMessage } from 'react-intl';
import { Row, Col } from '../../components/FlexBox/FlexBox';
import Button from '../../components/FormWithElemets/Button/Button';
import { loader } from 'graphql.macro';
import { handleAdd, handleUpdate } from './GraphqlFunction';


const ADD_QUALITY_CONTROL_REASON = loader(
  '../../graphql/qualityControlReason/ADD_QUALITY_CONTROL_REASON.graphql'
);
const UPDATE_QUALITY_CONTROL_REASON = loader(
  '../../graphql/qualityControlReason/UPDATE_QUALITY_CONTROL_REASON.graphql'
);

const QualityControlReasonForm: FunctionComponent = () => {
  const { register, handleSubmit, errors, setValue } = useForm();
  const dispatch = useDrawerDispatch();
  const dataDispatch = useDrawerState('data');
  const transactionsReasonDispatch = useDrawerState('transactionsReason');
  const closeDrawer = useCallback(() => dispatch({ type: 'CLOSE_DRAWER' }), [dispatch]);
  useEffect(() => {
    if (transactionsReasonDispatch === 'QualityControlReasonUpdate') {
      setValue('name', dataDispatch.name);
    }
  }, [dataDispatch, setValue,transactionsReasonDispatch]);
  const onSubmit = ({ name }) => {
    transactionsReasonDispatch === 'QualityControlReasonUpdate'
      ? update({
          variables: {
            id: dataDispatch.id,
            name: name,
          },
        })
      : add({
          variables: {
            name: name,
          },
        });
  };
  const [add] = useMutation(ADD_QUALITY_CONTROL_REASON, {
    update(cache, { data: { createQualityCheckReason } }) {
      handleAdd(cache, createQualityCheckReason);
      closeDrawer();
    },
  });
  const [update] = useMutation(UPDATE_QUALITY_CONTROL_REASON, {
    update(cache, { data: { updateQualityCheckReason } }) {
      handleUpdate(cache, updateQualityCheckReason);
      closeDrawer();
    },
  });
  return (
    <Card
      style={{
        padding: '30px 5px',
      }}
      title={
        <FormattedMessage
          id={
            transactionsReasonDispatch === 'QualityControlReasonUpdate'
              ? 'quality_control_reason.update'
              : 'quality_control_reason.create'
          }
        />
      }
    >
      <Form
        onSubmit={handleSubmit(onSubmit)}
        style={{
          height: '100%',
          backgroundColor: 'transparent',
        }}
      >
        <Row>
          <Col sm={12}>
            <FormControl
              label={<FormattedMessage id="quality_control_reason.name" />}
              error={errors.name ? 'Please input a valid Wip' : null}
            >
              <Input
                size={SIZE.compact}
                name="name"
                inputRef={register({
                  required: true,
                })}
                error={errors.name}
                overrides={errors.name ? { After: Negative } : { After: Positive }}
              />
            </FormControl>
          </Col>
          {/* <Col sm={6}>
            <Controller
              as={
                <Checkbox
                  overrides={{
                    Label: {
                      style: () => {
                        return {
                          marginTop: '40px',
                          marginBottom: '5px',
                          color: '#454b68',
                        };
                      },
                    },
                    Checkmark: {
                      style: () => {
                        return {
                          marginTop: '40px',
                          marginBottom: '5px',
                        };
                      },
                    },
                  }}
                >
                  <FormattedMessage id="quality_control_reason.improper" />
                </Checkbox>
              }
              name="improper"
              type="checkbox"
              control={control}
              labelPlacement={LABEL_PLACEMENT.right}
              defaultValue={false}
            />
          </Col> */}
        </Row>
        <Row end="xs" style={{ margin: 0, padding: 0 }}>
          <Col
            md={4}
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'flex-end',
            }}
          >
            <Button onClick={closeDrawer} style={{ width: '100%' }}>
              <FormattedMessage id="button.close" />
            </Button>{' '}
            <Button type="submit" style={{ width: '100%' }}>
              <FormattedMessage
                id={
                  transactionsReasonDispatch === 'QualityControlReasonUpdate'
                    ? 'button.update'
                    : 'button.create'
                }
              />
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
export default QualityControlReasonForm;
