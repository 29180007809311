import { faCalculator } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  Fragment,
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, Row, RowCustom } from '../../../../components/FlexBox/FlexBox';
import Button from '../../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../../components/Input/Input';
import { useToaster } from '../../../../context/toaster-provider';
import DateCustom from '../../../DateCustom/DateCustom';
import { handleDate, inputDecimal } from '../../../Functions/Utilities';
import SelectInventory from '../../../SelectCustom/SelectInventory';
import SelectInventoryType from '../../../SelectCustom/SelectInventoryType';
import SelectUnit from '../../../SelectCustom/SelectUnit';
import { ItemMaterial } from '../AddPurchaseOfferForm';
import RawMaterialCalculation from './RawMaterialCalculate';

interface ItemFormProps {
  item?: ItemMaterial;
  itemIndex?: number;
  setItemAddShow?: Function;
  setItemIndex?: Function;
  dispatchItems: Function;
}
const PurchaseMaterialForm: FunctionComponent<ItemFormProps> = ({
  item,
  setItemIndex,
  itemIndex = null,
  setItemAddShow,
  dispatchItems,
}) => {
  const [qtyCalculate, setQtyCalculate] = useState(false);
  const [rawMaterialId, setRawMaterialId] = useState(() => item?.rawMaterialId || null);
  const [materialGeometryId, setMaterialGeometryId] = useState(
    () => item?.materialGeometryId || null
  );
  const { control, errors, setValue, register, getValues } = useForm({
    defaultValues: item
      ? {
          estimatedDate: [new Date(item?.estimatedDate || new Date())],
          inventoryId: item.inventoryId,
          inventoryTypeId: item.inventoryTypeId,
          unitsId: item.unitsId,
          qty: item.qty,
        }
      : {
          estimatedDate: [new Date(new Date())],
          inventoryId: undefined,
          inventoryTypeId: undefined,
          unitsId: undefined,
          qty: 0,
        },
  });
  const { showToaster } = useToaster();
  const { select, unit, rawMaterialGeometry } = SelectInventory({
    name: 'inventoryId',
    control,
    errors,
    required: true,
    creatable: true,
    disabled: false,
  });
  useEffect(() => {
    setValue('unitsId', unit);
  }, [unit, setValue]);
  const handleResetForm = useCallback(() => {
    if (item) {
      setItemIndex(null);
    } else {
      setValue('inventoryId', undefined);
      setValue('inventoryTypeId', undefined);
      setValue('unitsId', undefined);
      setValue('estimatedDate', [new Date()]);
      setValue('qty', 0);
      setItemAddShow(false);
    }
  }, [item,setItemAddShow,setValue,setItemIndex]);
  const handleAddOrUpdateItem = useCallback(() => {
    const { estimatedDate, inventoryId, inventoryTypeId, unitsId, qty } = getValues();
    if (true) {
      if (itemIndex === null) {
        dispatchItems({
          type: 'ADD',
          item: {
            estimatedDate: handleDate(estimatedDate[0]).toISOString(),
            inventoryId,
            inventoryTypeId,
            unitsId,
            rawMaterialId: rawMaterialId,
            materialGeometryId: materialGeometryId,
            qty,
          },
        });
        showToaster('created', 'positive');
      } else {
        dispatchItems({
          type: 'UPDATE',
          item: {
            estimatedDate: handleDate(estimatedDate[0]).toISOString(),
            inventoryId,
            inventoryTypeId,
            unitsId,
            rawMaterialId: rawMaterialId,
            materialGeometryId: materialGeometryId,
            qty,
          },
          index: itemIndex,
        });
        showToaster('updated', 'positive');
      }
      handleResetForm();
    }
  }, [
    materialGeometryId,
    rawMaterialId,
    dispatchItems,
    handleResetForm,
    getValues,
    itemIndex,
    showToaster,
  ]);
  const handleCalculateResult = ({ rawMaterialId, materialGeometryId, result }) => {
    console.log(rawMaterialId, materialGeometryId);
    setValue('qty', result);
    setRawMaterialId(rawMaterialId);
    setMaterialGeometryId(materialGeometryId);
  };
  return (
    <Fragment>
      <RowCustom bottom="xs">
        <Col xs={12} md={5} lg={5}>
          {select}
        </Col>
        <Col xs={12} md={5} lg={4}>
          <DateCustom errors={errors} control={control} name="estimatedDate" />
        </Col>
        <Col xs={12} md={3} lg={3}>
          <Row style={{ margin: 0, padding: 0 }}>
            <Col style={{ margin: 0, padding: 0 }} xs={9}>
              <FormControl label={<FormattedMessage id="purchaseOffer.qty" />}>
                <Input
                  type="text"
                  size={SIZE.compact}
                  name="qty"
                  inputRef={register({
                    required: false,
                  })}
                  onChange={(event: any) => {
                    setValue('qty', inputDecimal({ event }));
                  }}
                  clearOnEscape
                />
              </FormControl>
            </Col>
            <Col style={{ margin: 0, padding: 0 }} xs={3}>
              <Button
                style={{ marginTop: '8px' }}
                type="button"
                onClick={() => setQtyCalculate(true)}
              >
                <FontAwesomeIcon icon={faCalculator} />
              </Button>
            </Col>
          </Row>
        </Col>
        <Col xs={12} md={5} lg={4}>
          <SelectUnit control={control} disabled={!!unit} />
        </Col>
        {!unit && (
          <Col xs={12} md={5} lg={4}>
            <SelectInventoryType control={control} />
          </Col>
        )}
        <ColButton xs>
          <Button
            style={{
              marginBottom: '16px',
            }}
            type="button"
            onClick={handleResetForm}
          >
            <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
          </Button>
          <Button
            style={{
              marginBottom: '16px',
            }}
            onClick={handleAddOrUpdateItem}
            type="button"
          >
            <FormattedMessage id={item ? 'button.update' : 'button.add'} />
          </Button>
        </ColButton>
      </RowCustom>
      {qtyCalculate && (
        <RawMaterialCalculation
          setQtyCalculate={setQtyCalculate}
          handleResult={({ rawMaterialId, materialGeometryId, result }) =>
            handleCalculateResult({ rawMaterialId, materialGeometryId, result })
          }
          rawMaterialGeometry={rawMaterialGeometry}
          orderQty={10}
        />
      )}
    </Fragment>
  );
};

export default PurchaseMaterialForm;
