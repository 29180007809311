import React, { Fragment, FunctionComponent } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGenderless } from '@fortawesome/pro-regular-svg-icons';
import { useDrawerState } from '../../../context/DrawerContext';
import {
  Card,
  CardBody,
  CardTitle,
} from '../../../components/FormWithElemets/Card.style';
import { COLOR } from '../../../components/FormWithElemets/Button/Button';
interface ListType {
  shopFloorOrders: {
    product: string;
    qty: number;
    unit: string;
  }[];
  machineryAndEquipment: string;
}
const ShopFloorOrderHistory: FunctionComponent = () => {
  const { shopFloorOrders, machineryAndEquipment }: ListType = useDrawerState('data');
  return (
    <Card>
      <CardTitle title={machineryAndEquipment}></CardTitle>
      <CardBody style={{ margin: '20px' }}>
        <div className="timeline timeline-6 mt-3">
          {shopFloorOrders.map((item, index) => {
            return (
              <Fragment key={index}>
                <div className="timeline-item align-items-start">
                  <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg">
                    <span style={{marginLeft:'30px'}}>{index + 2}</span>
                  </div>
                  <div className="timeline-badge">
                    <FontAwesomeIcon icon={faGenderless} color={COLOR.grey} size="2x" />
                  </div>
                  <div
                    className="font-weight-mormal font-size-lg timeline-content text-muted pl-3"
                    style={{ marginLeft: '15px' }}
                  >
                    {item.product}
                    <span
                      style={{
                        marginLeft: '20px',
                        marginRight: '5px',
                      }}
                    >
                      {item.qty} {' ' + item.unit}
                    </span>
                  </div>
                </div>
              </Fragment>
            );
          })}
        </div>
      </CardBody>
    </Card>
  );
};
export default ShopFloorOrderHistory;
