import React, { FunctionComponent, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import { Card, CardBody } from '../../../components/FormWithElemets/Card.style';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { BlockProps } from 'baseui/block';
import { StyledSpinnerNext } from 'baseui/spinner';
import NoResult from '../../../components/NoResult/NoResult';
import { ProductImage, ProductImages } from '../../../types/ProductImage';
import { DivTechnicalImages } from '../../Product/TechnicalImages';
import Button, { COLOR } from '../../../components/FormWithElemets/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/pro-regular-svg-icons';

const S_PRODUCT_IMAGES = loader(
  '../../../graphql/product/upload/S_PRODUCT_IMAGES.graphql'
);
const PRODUCT_IMAGES = loader('../../../graphql/product/upload/PRODUCT_IMAGES.graphql');

interface ListProps {
  productId: string;
  setTechnicalImage: Function;
}

const TechnicalImagesList: FunctionComponent<ListProps> = ({
  productId,
  setTechnicalImage,
}) => {
  const { subscribeToMore, data, loading, error } = useQuery<ProductImages>(
    PRODUCT_IMAGES,
    {
      variables: {
        id: productId,
      },
    }
  );
  const [dataTechnicalImages, setDataTechnicalImages] = useState(null);
  useEffect(() => {
    data && setDataTechnicalImages(data);
  }, [data]);
  useEffect(() => {
    subscribeToMore({
      document: S_PRODUCT_IMAGES,
      variables: {
        id: productId,
      },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        setDataTechnicalImages(subscriptionData.data);
      },
    });
  }, [subscribeToMore, productId]);
  const itemProps: BlockProps = {
    backgroundColor: '#fffff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  };
  return (
    <Card>
      <CardBody style={{ padding: '10px' }}>
        {loading ? (
          <StyledSpinnerNext />
        ) : error ? (
          <NoResult />
        ) : (
          <FlexGrid
            flexGridColumnCount={[1, 4, 8]}
            flexGridColumnGap="scale800" 
            flexGridRowGap="scale800"
          >
            {dataTechnicalImages &&
              dataTechnicalImages?.productImages.map((item: ProductImage) => (
                <FlexGridItem key={item.id} {...itemProps}>
                  <DivTechnicalImages>
                    {item.fileType === 'pdf' ? (
                      <Button
                        type="button"
                        onClick={() => setTechnicalImage({...item})}
                        color={COLOR.transparent}
                        style={{ margin: "auto", padding: 0 }}
                      >
                        <FontAwesomeIcon icon={faFilePdf} size="3x" color={COLOR.grey} />
                      </Button>
                    ) : (
                      <img
                        src={item.url}
                        onClick={() => setTechnicalImage({...item})}
                        width="100%"
                        height="100%"
                        alt={item?.product?.name || '...'}
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    )}
                  </DivTechnicalImages>
                </FlexGridItem>
              ))}
          </FlexGrid>
        )}
      </CardBody>
    </Card>
  ); 
};
export default TechnicalImagesList;
