import { Input, SIZE } from 'baseui/input';
import React, { FunctionComponent, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import FormControl from '../../../components/FormWithElemets/FormControl';
import DateCustom from '../../DateCustom/DateCustom';
import { inputDecimal } from '../../Functions/Utilities';
import SelectProduct from '../../SelectCustom/SelectProduct';
import SelectUnit from '../../SelectCustom/SelectUnit';
import { ComponentStatusContext } from '../Offer';
import { Item } from './ProductSalesOfferAdd';

interface InputFormProps {
  item: Item;
  register: any;
  errors: any;
  setValue?: any;
  control: any;
  watch: any;
  handleAddOrUpdateItem?: Function | undefined;
  handleResetForm: Function;
  setProductRevision: Function;
}
const InputForm: FunctionComponent<InputFormProps> = ({
  item,
  control,
  errors,
  setValue,
  register,
  watch,
  handleResetForm,
  handleAddOrUpdateItem,
  setProductRevision,
}) => {
  const [cleanButtonActive, setCleanButtonActive] = useState(false);
  const [addButtonActive, setAddButtonActive] = useState(false);
  const { qty, productId, unitsId } = watch();
  const { componentStatus } = useContext(ComponentStatusContext);
  const { select, productRevison, unit } = SelectProduct({
    control,
    errors,
    required: false,
    creatable: !!(componentStatus.offerType === 'Subcontract'),
    disabled: false,
  });
  useEffect(() => {
    setValue('unitsId', unit);
  }, [unit, setValue]);
  useEffect(() => {
    setProductRevision && setProductRevision(productRevison);
  }, [productRevison, setProductRevision]);
  useEffect(() => {
    setValue('productId', item?.productId || undefined);
    setValue('unitsId', item?.unitsId || undefined);
    setValue('qty', item?.qty || '');
    setValue('estimatedDeliveryDate', [
      new Date(item?.estimatedDeliveryDate || new Date()),
    ]);
  }, [setValue, item]);
  useEffect(() => {
    setCleanButtonActive(!(qty !== '' || productId?.length > 0));
    setAddButtonActive(!(qty !== '' && productId?.length > 0 && unitsId?.length > 0));
  }, [qty, productId, unitsId]);
  return (
    <RowCustom bottom="xs">
      <Col xs={4}>{select}</Col>
      <Col xs={2}>
        <DateCustom errors={errors} control={control} name="estimatedDeliveryDate" />
      </Col>
      <Col xs={2}>
        <FormControl label={<FormattedMessage id="offer.qty" />}>
          <Input
            type="text"
            name="qty"
            inputRef={register}
            size={SIZE.compact}
            //  endEnhancer={unit[0].label}
            onChange={(event) => {
              setValue('qty', inputDecimal({ event }));
            }}
          />
        </FormControl>
      </Col>
      <Col xs={2}>
        <SelectUnit control={control} disabled={true} />
      </Col>
      {/* <Col xs={2}>
      <FormControl label={<FormattedMessage id="offer.unitPrice" />}>
        <Input
          type="text"
          name="unitPrice"
          size={SIZE.compact}
          inputRef={register}
          onChange={(event) => {
            setValue('unitPrice', inputDecimal({ event }));
          }}
        />
      </FormControl>
    </Col> */}
      <ColButton xs>
        <Button
          type="button"
          disabled={cleanButtonActive}
          onClick={() => {
            handleResetForm();
          }}
          style={{
            marginTop: '6px',
            marginBottom: '15px',
          }}
        >
          <FormattedMessage id={item ? 'button.close' : 'button.clean'} />
        </Button>
        <Button
          type={'button'}
          onClick={() => handleAddOrUpdateItem()}
          disabled={addButtonActive}
          style={{
            marginTop: '6px',
            marginBottom: '15px',
          }}
        >
          <FormattedMessage id={'button.ok'} />
        </Button>
      </ColButton>
    </RowCustom>
  );
};
export default InputForm;
