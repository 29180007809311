import React, { FunctionComponent, useState } from 'react';
import { PurchaseMaterialOffer } from '../../../../../types/PurchaseMaterialOffer';
import { PurchaseOperationOffer } from '../../../../../types/PurchaseOperationOffer';
import { handleLocalDateString } from '../../../../Functions/Utilities';
import GridCol from '../../../../TailWinduiHTML/GridCol';

interface ListItePurhaseOfferProps {
  itemMaterial?: PurchaseMaterialOffer;
  itemOperation?: PurchaseOperationOffer;
}
const ListItePurhaseOffer: FunctionComponent<ListItePurhaseOfferProps> = ({
  itemMaterial,
  itemOperation,
}) => {
  const [item] = useState(handleNewItem(itemMaterial, itemOperation));
  return (
    <div className="pt-3 grid print:grid-cols-12 grid-cols-12 border-b hover:bg-gray-100 cursor-pointer">
      <div className="lg:col-span-4  md:col-span-8 col-span-12  print:col-span-4">
        <GridCol title="offer.item">{item?.name || 'No Item...'}</GridCol>
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol title="date.estimatedDate">
          {handleLocalDateString(item?.estimatedDate)}
        </GridCol>
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol title="purchaseOffer.qty">{item?.qty}</GridCol>
      </div>
      {/* <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol
          title="offer.unitPrice"
          content={""}
        />
      </div>
      <div className="lg:col-span-2 md:col-span-4 col-span-12 sm:col-span-6 print:col-span-2">
        <GridCol
          title="offer.amount"
          content={""}
        />
      </div> */}
    </div>
  );
};
export default ListItePurhaseOffer;
const handleNewItem = (
  itemMaterial: PurchaseMaterialOffer,
  itemOperation: PurchaseOperationOffer
) => {
  const newItem = itemMaterial
    ? {
        name: itemMaterial.inventory?.name || itemMaterial?.name,
        estimatedDate: itemMaterial?.estimatedDate || '',
        qty: itemMaterial?.qty || 0,
      }
    : itemOperation && {
        name: itemOperation?.operation?.name,
        estimatedDate: itemOperation?.estimatedDate || '',
        qty: itemOperation?.qty || 0,
      };
  return newItem;
};
