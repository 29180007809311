import React, { FunctionComponent } from 'react';
export const Sheet: FunctionComponent = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="50" viewBox="0 0 18 100">
    <g id="Rectangle_3" data-name="Rectangle 3" fill="none" stroke="#707070" strokeWidth="3">
      <rect width="18" height="100" stroke="none"/>
      <rect x="1.5" y="1.5" width="15" height="97" fill="none"/>
    </g>
  </svg>
   );
};
