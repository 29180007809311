import React, { useState, useEffect } from 'react';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import FormControl from '../../components/FormWithElemets/FormControl';
import { FormattedMessage } from 'react-intl';
import { Select } from '../../components/FormWithElemets/Select/Select';
import { TYPE } from 'baseui/select';
import { RawMaterialInventory, SearchInventory } from '../../types/Inventory';
import { debounce } from '../Functions/Utilities';
import _ from 'lodash';

const SEARCH_INVENTORIES = loader('../../graphql/inventory/SEARCH_INVENTORIES.graphql');
interface ListProps {
  name: string;
  defaultValue?:[{id:string,label:string}];
  control: any;
  errors: any;
  required: boolean;
  creatable?: boolean;
  disabled?: boolean | undefined;
}
const SelectInventory = ({
  name,
  defaultValue,
  control,
  errors,
  required,
  creatable = false,
  disabled,
}: ListProps) => {
  const [loadOptionsEs, { data: dataInventory, loading }] = useLazyQuery<SearchInventory>(
    SEARCH_INVENTORIES
  );
  useEffect(() => {
    loadOptionsEs({
      variables: {
        full: true,
        name: null,
      },
    });
  }, [loadOptionsEs]);
  const [unit, setUnit] = useState<{ id: string; label: string }[]>(undefined);
  const [inventoryType, setInventoryType] = useState<{ id: string; label: string }[]>(undefined);
  const [rawMaterialGeometry, setRawMaterialGeometry] = useState<RawMaterialInventory>(undefined);
  const handleChangeInventory = (value:[{id:string,label:string}]) => {
    if (dataInventory && value) {
      const inventoryItem = dataInventory.searchInventory.find(
        (item) => item.id === (value && value[0]?.id)
      );
      if (inventoryItem) {
        setUnit([
          {
            id: inventoryItem?.units?.id,
            label: inventoryItem?.units?.name,
          },
        ]);
        setInventoryType([{
          id: inventoryItem?.inventoryType?.id,
          label: inventoryItem?.inventoryType?.name,
        }]);
        inventoryItem?.rawMaterialInventory ?
          setRawMaterialGeometry(inventoryItem?.rawMaterialInventory)
        : setRawMaterialGeometry(null)
      }else{
        setUnit(undefined);
        setInventoryType(undefined)
      }
    }
  };
  useEffect(()=>{
      handleChangeInventory(defaultValue)
  },[dataInventory,defaultValue])
  const handleInputChange = debounce(function (term: string) {
    loadOptionsEs({
      variables: {
        full: true,
        name: term ? term : null,
      },
    });
  });
  return {
    select: (
      <FormControl
        label={<FormattedMessage id={'inventory.' + name} />}
        error={_.get(errors, name) ? 'Please input a valid Inventory' : null}
      >
        <Select
          data={dataInventory && dataInventory.searchInventory}
          controller={{
            name: name,
            isLoading: loading,
            type: TYPE.search,
            control,
            rules: { required: required },
            creatable: creatable,
            searchable: true,
            filterOutSelected: false,
            disabled: disabled,
            onChange: ([selected]) => {
              handleChangeInventory(selected?.value);
              return selected.option ? selected.value : undefined;
            },
            onInputChange: (event: React.SyntheticEvent) => {
              const target = event.target as HTMLInputElement;
              handleInputChange(target.value);
            },
          }}
        />
      </FormControl>
    ),
    unit: unit,
    setInventoryUnit: setUnit,
    rawMaterialGeometry:rawMaterialGeometry,
    inventoryType:inventoryType
  };
};

export default SelectInventory;
