// @ts-nocheck
import React from 'react';
import { styled } from 'baseui';
export const Form = styled('form', () => ({
  padding: '10px',
  height: '100%',
  backgroundColor: 'transparent',
}));
export const Img = styled('img', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  height: '100%',
  borderRadius: '10px',
  boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
  ':hover': {
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.25), 0 3px 10px 0 rgba(0, 0, 0, 0.25)',
  },
}));
export const ImgBase = styled('img', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  height: '100%',
  borderRadius: '10px',
}));
const CardBody = styled('div', () => ({
  backgroundColor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 5px 6px 0 rgba(0, 0, 0, 0.16)',
  marginBottom: '10px',
  padding: '30px',
}));
const Label = styled('span', ({ $theme }) => ({
  ...$theme.typography.fontBold14,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  flex: '1',
}));

const Content = styled('span', ({ $theme }) => ({
  ...$theme.typography.font18,
  color: $theme.colors.textDark,
  margin: 0,
  padding: '1px',
  flex: '1.9',
}));
type ListProps = {
  children: React.ReactNode;
  style?: any;
  title?: any;
};
export const Card: React.FC<ListProps> = ({ children, title, style }) => {
  return (
    <div style={{ width: '100%', margin: 0, padding: 0 }}>
      <h2 style={{ marginTop: '10px', marginBottom: 0, textAlign: 'center' }}>{title}</h2>
      <CardBody style={{ ...style }}>{children}</CardBody>
    </div>
  );
};
export const LabelContent: React.FC<ListProps> = ({ children, title, style }) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
        marginBottom: '6px',
        marginTop: '6px',
      }}
    >
      <Label>{title}</Label>
      <Content>{children}</Content>
    </div>
  );
};
//gray
