import { loader } from 'graphql.macro';
const GET_OFFER_AND_DETAILS_WITH_OPERATIONS = loader(
  '../../graphql/offer/GET_OFFER_AND_DETAILS_WITH_OPERATIONS.graphql'
);
const GET_OFFER_WITH_STAGES = loader('../../graphql/offer/GET_OFFER_WITH_STAGES.graphql');
export const handleUpdateOffer = (cache: any, updateOffer: any) => {
  cache.writeQuery({
    query: GET_OFFER_WITH_STAGES,
    data: { offerWithStages: updateOffer },
  });
};
export const handleAddOfferItem = (
  cache: any,
  offerId: string | null,
  createOfferDetail: any
) => {
  const newCreateOfferWithStagesItem = {
    id: createOfferDetail.id,
    inventory: createOfferDetail.inventory,
    qty: createOfferDetail.qty,
    //unitPrice: createOfferDetail.unitPrice,
    estimatedDeliveryDate: createOfferDetail.estimatedDeliveryDate,
    __typename: createOfferDetail.__typename,
  };
  if (offerId) {
    const { offerAndDetailsWithOperations } = cache.readQuery({
      query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
      variables: {
        id: offerId,
      },
    });
    const newCreateOfferAndDetailsWithOperationsItem = {
      ...newCreateOfferWithStagesItem,
      offerDetailOperation: [],
      offerDetailMaterial: [],
    };
    const newOfferAndDetailsWithOperations = {
      ...offerAndDetailsWithOperations,
      offerDetail: offerAndDetailsWithOperations.offerDetail.concat([
        newCreateOfferAndDetailsWithOperationsItem,
      ]),
    };
    cache.writeQuery({
      query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
      variables: {
        id: offerId,
      },
      data: {
        offerAndDetailsWithOperations: newOfferAndDetailsWithOperations,
      },
    });
  }
  const { offerWithStages } = cache.readQuery({
    query: GET_OFFER_WITH_STAGES,
  });
  const newOfferWithStages = offerWithStages.map((item) => {
    if (item.id === createOfferDetail?.offer?.offerStageId) {
      const itemOfferTemp = item?.offer.map((offerItem) => {
        if (offerItem.id === createOfferDetail?.offer?.id) {
          offerItem.offerDetail = offerItem.offerDetail.concat([
            newCreateOfferWithStagesItem,
          ]);
          return offerItem;
        } else {
          return offerItem;
        }
      });
      return {
        ...item,
        offer: itemOfferTemp,
      };
    } else {
      return item;
    }
  });

  cache.writeQuery({
    query: GET_OFFER_WITH_STAGES,
    data: { offerWithStages: newOfferWithStages },
  });
};
export const handleUpdateOfferItem = (
  cache: any,
  offerId: string | null,
  updateOfferDetail: any
) => {
  if (offerId) {
    const { offerAndDetailsWithOperations } = cache.readQuery({
      query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
      variables: {
        id: offerId,
      },
    });
    const newOfferAndDetailsWithOperations = {
      ...offerAndDetailsWithOperations,
      offerDetail: offerAndDetailsWithOperations.offerDetail.map((item) => {
        if (item?.id === updateOfferDetail.id) {
          return {
            ...item,
            inventory: updateOfferDetail.inventory,
            qty: updateOfferDetail.qty,
            //unitPrice: updateOfferDetail.unitPrice,
            estimatedDeliveryDate: updateOfferDetail.estimatedDeliveryDate,
            offerDetailOperation: [],
            offerDetailMaterial: [],
          };
        } else {
          return item;
        }
      }),
    };
    offerId &&
      cache.writeQuery({
        query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
        variables: {
          id: offerId,
        },
        data: {
          offerAndDetailsWithOperations: newOfferAndDetailsWithOperations,
        },
      });
  }
  const { offerWithStages } = cache.readQuery({
    query: GET_OFFER_WITH_STAGES,
  });
  const newOfferWithStages = offerWithStages.map((item) => {
    if (item.id === updateOfferDetail?.offer?.offerStageId) {
      const newOffer = item?.offer.map((offer) => {
        if (offer.id === updateOfferDetail?.offer?.id) {
          return {
            ...offer,
            offerDetail: offer.offerDetail.map((offerItem) => {
              if (offerItem.id === updateOfferDetail?.id) {
                return {
                  ...offerItem,
                  inventory: updateOfferDetail.inventory,
                  qty: updateOfferDetail.qty,
                  //unitPrice: updateOfferDetail.unitPrice,
                  estimatedDeliveryDate: updateOfferDetail.estimatedDeliveryDate,
                };
              } else {
                return offerItem;
              }
            }),
          };
        } else {
          return offer;
        }
      });
      return {
        ...item,
        offer: newOffer,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_OFFER_WITH_STAGES,
    data: { offerWithStages: newOfferWithStages },
  });
};
export const handleDeleteOfferItem = (cache: any, deleteOfferDetail: any) => {
  const { offerWithStages } = cache.readQuery({
    query: GET_OFFER_WITH_STAGES,
  });
  const newOfferWithStages = offerWithStages.map((item) => {
    if (item.id === deleteOfferDetail?.offer?.offerStageId) {
      const newOffer = item?.offer.map((offer) => {
        if (offer.id === deleteOfferDetail?.offer?.id) {
          return {
            ...offer,
            offerDetail: offer.offerDetail.filter(
              (detail) => detail.id !== deleteOfferDetail.id
            ),
          };
        } else {
          return offer;
        }
      });
      return {
        ...item,
        offer: newOffer,
      };
    } else {
      return item;
    }
  });
  cache.writeQuery({
    query: GET_OFFER_WITH_STAGES,
    data: { offerWithStages: newOfferWithStages },
  });
};
export const handleAddOfferItemOperation = (
  cache: any,
  offerId: string,
  offerItemId: string,
  createOfferDetailOperation: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerItemId) {
      item.offerDetailOperation = item.offerDetailOperation.concat([
        createOfferDetailOperation,
      ]);
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleUpdateOfferItemOperation = (
  cache: any,
  offerId: string,
  offerItemId: string,
  updateOfferDetailOperation: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerItemId) {
      const newOfferDetailOperation = item.offerDetailOperation.map((item) => {
        if (updateOfferDetailOperation?.id === item.id) {
          return updateOfferDetailOperation;
        } else {
          return item;
        }
      });
      item.offerDetailOperation = newOfferDetailOperation;
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleDeleteOfferItemOperation = (
  cache: any,
  offerId: string,
  offerItemId: string,
  operationId: string
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerItemId) {
      item.offerDetailOperation = item.offerDetailOperation.filter(
        (itm) => itm.id !== operationId
      );
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};

export const handleUpdateOfferDetailOperationOrder = (
  cache,
  items,
  offerId,
  parentId
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === parentId) {
      item.offerDetailOperation = items;
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleDeleteOfferItemMaterial = (
  cache: any,
  offerId: string,
  offerItemId: string,
  detailMaterialId: string
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerItemId) {
      item.offerDetailMaterial = item.offerDetailMaterial.filter(
        (itm) => itm.id !== detailMaterialId
      );
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleAddOfferItemMaterial = (
  cache: any,
  offerId: string,
  offerItemId: string,
  createOfferDetailMaterial: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerItemId) {
      item.offerDetailMaterial = item.offerDetailMaterial.concat([
        createOfferDetailMaterial,
      ]);
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleUpdateOfferItemMaterial = (
  cache: any,
  offerId: string,
  offerItemId: string,
  updateOfferDetailMaterial: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerItemId) {
      const newOfferDetailMaterial = item.offerDetailMaterial.map((item) => {
        if (updateOfferDetailMaterial?.id === item.id) {
          return updateOfferDetailMaterial;
        } else {
          return item;
        }
      });
      item.offerDetailMaterial = newOfferDetailMaterial;
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleAddOfferTransport = (
  cache: any,
  offerId: string,
  createOfferTransport: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  const newOfferAndDetailsWithOperations = {
    ...offerAndDetailsWithOperations,
    offerTransport: offerAndDetailsWithOperations.offerTransport.concat([
      createOfferTransport,
    ]),
  };
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: {
      offerAndDetailsWithOperations: newOfferAndDetailsWithOperations,
    },
  });
};
export const handleUpdateOfferTransport = (
  cache: any,
  offerId: string,
  updateOfferTransport: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  const newOfferAndDetailsWithOperations = {
    ...offerAndDetailsWithOperations,
    offerTransport: offerAndDetailsWithOperations.offerTransport.map((item) => {
      if (updateOfferTransport?.id === item.id) {
        return updateOfferTransport;
      } else {
        return item;
      }
    }),
  };
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: newOfferAndDetailsWithOperations },
  });
};
export const handleDeleteOfferTransport = (
  cache: any,
  offerId: string,
  offerTransportId: string
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  const newOfferAndDetailsWithOperations = {
    ...offerAndDetailsWithOperations,
    offerTransport: offerAndDetailsWithOperations.offerTransport.filter(
      (item) => offerTransportId !== item.id
    ),
  };
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: newOfferAndDetailsWithOperations },
  });
};
export const handleAddOfferDetailOperationLaborCost = (
  cache: any,
  offerId: string,
  offerDetailId: string,
  offerDetailOperationId: string,
  createOfferDetailOperationLaborCost: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerDetailId) {
      item.offerDetailOperation.forEach((operation) => {
        if (operation.id === offerDetailOperationId) {
          operation.offerDetailOperationLaborCost = operation.offerDetailOperationLaborCost.concat(
            [createOfferDetailOperationLaborCost]
          );
        }
      });
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: {
      offerAndDetailsWithOperations: offerAndDetailsWithOperations,
    },
  });
};
export const handleUpdateOfferDetailOperationLaborCost = (
  cache: any,
  offerId: string,
  offerDetailId: string,
  offerDetailOperationId: string,
  updateOfferDetailOperationLaborCost: any
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerDetailId) {
      item.offerDetailOperation.forEach((operation) => {
        if (operation.id === offerDetailOperationId) {
          operation.offerDetailOperationLaborCost = operation.offerDetailOperationLaborCost.map(
            (laborCost) => {
              if (updateOfferDetailOperationLaborCost?.id === laborCost.id) {
                return updateOfferDetailOperationLaborCost;
              } else {
                return laborCost;
              }
            }
          );
        }
      });
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleDeleteOfferDetailOperationLaborCost = (
  cache: any,
  offerId: string,
  offerDetailId: string,
  offerAndDetailsWithOperationId: string
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  offerAndDetailsWithOperations.offerDetail.forEach((item) => {
    if (item.id === offerDetailId) {
      item.offerDetailOperation.forEach((operation) => {
        operation.offerDetailOperationLaborCost = operation.offerDetailOperationLaborCost.filter(
          (laborCost) => laborCost?.id !== offerAndDetailsWithOperationId
        );
      });
    }
  });
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: offerAndDetailsWithOperations },
  });
};
export const handleUpdateOfferTransportIncluded = (
  cache: any,
  offerId: string,
  offertransportInculuded: boolean
) => {
  const { offerAndDetailsWithOperations } = cache.readQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
  });
  const newOfferAndDetailsWithOperations = {
    ...offerAndDetailsWithOperations,
    transportIncluded: !offerAndDetailsWithOperations?.transportIncluded,
  };
  cache.writeQuery({
    query: GET_OFFER_AND_DETAILS_WITH_OPERATIONS,
    variables: {
      id: offerId,
    },
    data: { offerAndDetailsWithOperations: newOfferAndDetailsWithOperations },
  });
};
type Item = {
  productId: { id: string }[];
  estimatedDeliveryDateItem: string;
  qty: number;
  id: string | undefined;
};
type NewItem = {
  id: string | undefined;
  productId: string;
  estimatedDeliveryDate: string;
  qty: number;
};
export const editItems = (items: Item[]) => {
  const newItems: NewItem[] = items.map((item) => {
    let productIdTemp = item.productId && item.productId[0]?.id;
    return {
      id: item.id,
      productId: productIdTemp,
      qty: Number(item.qty),
      estimatedDeliveryDate: item.estimatedDeliveryDateItem,
    };
  });
  return newItems;
};
