import { useMutation } from '@apollo/react-hooks';
import { loader } from 'graphql.macro';
import React, { FunctionComponent, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import Button from '../../../components/FormWithElemets/Button/Button';
import { Form } from '../../../components/FormWithElemets/Form.style';
import FormControl from '../../../components/FormWithElemets/FormControl';
import Input, { SIZE } from '../../../components/Input/Input';
import { useToaster } from '../../../context/toaster-provider';
import SelectInventoryType from '../../SelectCustom/SelectInventoryType';
import SelectUnit from '../../SelectCustom/SelectUnit';
import { ComponentStatusContext } from '../ExecutionPlanning/ExecutionPlanning';
const UPDATE_EXECUTION_PLANNING_STEP_OUTPUT = loader(
  '../../../graphql/executionPlanningStepOutput/UPDATE_EXECUTION_PLANNING_STEP_OUTPUT.graphql'
);
const ADD_EXECUTION_PLANNING_STEP_OUTPUT = loader(
  '../../../graphql/executionPlanningStepOutput/ADD_EXECUTION_PLANNING_STEP_OUTPUT.graphql'
);
interface ItemFormProps {
  item?: any;
  setItemAddShow?: Function;
  setItemId?: Function;
}
const ItemForm: FunctionComponent<ItemFormProps> = ({
  item,
  setItemId,
  setItemAddShow,
}) => {
  const { componentStatus } = useContext(ComponentStatusContext);
  const { control, errors, handleSubmit, setValue, register } = useForm({
    defaultValues: item
      ? {
          name: item.name,
          inventoryTypeId: [
            { id: item.inventoryType.id, label: item.inventoryType.name },
          ],
        }
      : {},
  });
  const { showToaster } = useToaster();
  const [update] = useMutation(UPDATE_EXECUTION_PLANNING_STEP_OUTPUT);
  const [add] = useMutation(ADD_EXECUTION_PLANNING_STEP_OUTPUT);
  const onSubmit = ({ name, inventoryTypeId, unitsId }) => {
    if (item) {
      update({
        variables: {
          id: item.id,
          name: name,
          inventoryTypeId: inventoryTypeId ?  inventoryTypeId[0]?.id : null,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        handleResetForm();
      });
    } else {
      add({
        variables: {
          executionPlanStepId: componentStatus?.executionPlanningStepId,
          name: name,
          unitsId: unitsId ?  unitsId[0]?.id : null,
          inventoryTypeId: inventoryTypeId ? inventoryTypeId[0]?.id : null,
        },
      }).then(() => {
        showToaster('created', 'positive');
        handleResetForm();
      });
    }
  };
  const handleResetForm = () => {
    if (item) {
      setItemId(null);
    } else {
      setValue('name', ''); 
      setValue('inventoryTypeId', undefined);
      setValue('unitsId', undefined);
      setItemAddShow(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom>
        <Col xs={12} md={6} lg={4}>
          <FormControl label={<FormattedMessage id="executionPlanningStep.inputName" />}>
            <Input
              type="text"
              size={SIZE.compact}
              name="name"
              inputRef={register({
                required: true,
              })}
              clearOnEscape
            />
          </FormControl>
        </Col>
        <Col xs={5} md={6} lg={3}>
          <SelectInventoryType errors={errors} control={control} />
        </Col>
        {!item && (
          <Col xs={5} md={6} lg={3}>
            <SelectUnit errors={errors} control={control} />
          </Col>
        )}
        <ColButton xs>
          <Button
            style={{
              marginTop: '30px',
            }}
            type="button"
            onClick={() => handleResetForm()}
          >
            <FormattedMessage id="button.close" />
          </Button>
          <Button
            style={{
              marginTop: '30px',
            }}
            type="submit"
          >
            <FormattedMessage id={item ? 'button.update' : 'button.add'} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};

export default ItemForm;
