import React, { FunctionComponent, memo, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, ColButton, RowCustom } from '../../../components/FlexBox/FlexBox';
import { LabelCustom } from '../../../components/FormWithElemets/Label.style';
import { ColumnButtons } from '../../../components/TailWinduiTable/ColumnButtons';
import { MaterialItem } from './ListBillOfMaterialItem';

interface ItemProps {
  item: MaterialItem;
  index: number;
  handleItemDelete: Function;
  handleItemEdit?: Function;
}
const ListItemBillOfMaterialItem: FunctionComponent<ItemProps> = memo(
  ({
    item,
    index,
    handleItemDelete,
    handleItemEdit,
  }) => {
    const intl = useIntl();
    const buttons = [
      {
        label: intl.formatMessage({ id: 'button.delete' }),
        function: () => {
          handleItemDelete(index);
        },
        check_type: 'DELETE',
      },
      {
        label: intl.formatMessage({ id: 'button.update' }),
        function: () => {
          handleItemEdit(index);
        },
        check_type: 'UPDATE',
      }
    ];
    const _unit = useMemo(() => {
      const rawMaterial = item?.inventoryTypeId && item?.inventoryTypeId[0]?.id;
      return rawMaterial === "d5b8e917-9bc5-42e6-a23a-b721e72972a1" ?
        intl.formatMessage({ id: 'Gram' }) : intl.formatMessage({ id: 'Adet' }) || ''
    }, [item])
    return (
      <RowCustom>
        <Col xs={12} md={7} lg={4}>
          <LabelCustom title={<FormattedMessage id="inventory.materialItemInventoryId" />}>
            {(item?.materialItemInventoryId && item?.materialItemInventoryId[0]?.label) ||
              ''}
          </LabelCustom>
        </Col>
        <Col xs={12} md={5} lg={3}>
          <LabelCustom title={<FormattedMessage id="select.inventoryType" />}>
            {item?.inventoryTypeId && item?.inventoryTypeId[0]?.label || ''}
          </LabelCustom>
        </Col>
        <Col sm={6} md={4} lg={2}>
          <LabelCustom title={<FormattedMessage id="materialItem.partNumber" />}>
            {item?.partNumber || ''}
          </LabelCustom>
        </Col>
        <Col sm={6} md={6} lg={2}>
          <LabelCustom title={<FormattedMessage id="materialItem.quantity" />}>
            {(item?.quantity || '') + ' ' + _unit}
          </LabelCustom>
        </Col>
        {/* <Col sm={9} lg={3}>
          <LabelCustom title={<FormattedMessage id="company.vendor" />}>
            {(item?.companyName && item?.companyName[0]?.label) || ''}
          </LabelCustom>
        </Col> */}
        <ColButton xs>
          <ColumnButtons threeDot={true} item={[{ id: index }]} buttons={buttons} />
        </ColButton>
      </RowCustom>
    );
  }
);
export default ListItemBillOfMaterialItem;
