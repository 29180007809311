import { Input, SIZE } from 'baseui/input';
import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Col,
  ColButton,
  RowCustom,
} from '../../../../../components/FlexBox/FlexBox';
import FormControl from '../../../../../components/FormWithElemets/FormControl';
import { handleCeil, inputDecimal } from '../../../../Functions/Utilities';
import { Label } from '../../../../../components/FormWithElemets/Label.style';
import { loader } from 'graphql.macro';
import { SubcontractSalesOfferPrice } from '../../../../../types/SubcontractSalesOfferPrice';
import { ExecutionPlanStep } from '../../../../../types/ExecutionPlan';
import { useToaster } from '../../../../../context/toaster-provider';
import { useForm } from 'react-hook-form';
import { Form } from '../../../../../components/FormWithElemets/Form.style';
import { ContextSubcontractSalesOfferId } from '../ListItemSuncontractSales';
import { useMutation } from '@apollo/react-hooks';
import Button from '../../../../../components/FormWithElemets/Button/Button';
import { faTimes, faCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const UPDATE_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferOperationPrice/UPDATE_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE.graphql'
);
const ADD_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE = loader(
  '../../../../../graphql/subcontractSalesOfferOperationPrice/ADD_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE.graphql'
);
interface ItemDetailOperationFormProps {
  itemAdd: {
    itemExecutionStep: ExecutionPlanStep;
    itemPrice: SubcontractSalesOfferPrice;
  };
  setItemAdd: Function;
  subcontractSalesOfferPriceId: string;
}
const ItemDetailOperationForm: FunctionComponent<ItemDetailOperationFormProps> = ({
  itemAdd: { itemExecutionStep, itemPrice },
  setItemAdd,
  subcontractSalesOfferPriceId,
}) => {
  const { subcontractSalesOfferId } = useContext(
    ContextSubcontractSalesOfferId
  );
  const { showToaster } = useToaster();
  const [discountAmount, setDiscountAmount] = useState(0);
  const [profitAmount, setProfitAmount] = useState(0);
  const { register, setValue, watch, handleSubmit } = useForm({
    defaultValues: {
      qty: itemPrice?.qty || 1,
      duration: itemPrice?.duration || (itemExecutionStep?.external ? 1 : itemExecutionStep?.duration) || 1,
      unitPrice: itemPrice?.unitPrice || 0,
      amount: itemPrice?.amount || 0,
      discountRate: itemPrice?.discountRate || 0,
      profitRate: itemPrice?.profitRate || 0,
    },
  });
  const { qty, unitPrice, profitRate, discountRate, duration, amount } = watch();

  useEffect(() => {
    if (!itemExecutionStep?.external) {
      const _amount = handleCeil((+qty * +unitPrice * +duration));
      setValue('amount', _amount);
      const profitAmount = (_amount * profitRate) / 100;
      setProfitAmount(profitAmount);
      const discountAmount = ((_amount + profitAmount) * discountRate) / 100;
      setDiscountAmount(discountAmount);
    }
  }, [setValue, discountRate, profitRate, qty, unitPrice, duration]);
  useEffect(() => {
    if (itemExecutionStep?.external) {
      const profitAmount = (amount * profitRate) / 100;
      setProfitAmount(profitAmount);
      const discountAmount = ((amount + profitAmount) * discountRate) / 100;
      setDiscountAmount(discountAmount);
    }
  }, [amount, discountRate, profitRate,])
  const onSubmit = ({
    qty,
    amount,
    duration,
    unitPrice,
    discountRate,
    profitRate,
  }) => {
    itemPrice
      ? update({
        variables: {
          id: itemPrice?.id,
          qty: +qty || 0,
          unitPrice: +unitPrice || 0,
          duration: +duration || 0,
          amount: +amount || 0,
          discountRate: +discountRate || 0,
          profitRate: +profitRate || 0,
        },
      }).then(() => {
        showToaster('updated', 'positive');
        setItemAdd(null);
      })
      : add({
        variables: {
          executionPlanStepId: itemExecutionStep.id,
          subcontractSalesOfferId: subcontractSalesOfferId,
          subcontractSalesOfferPriceId: subcontractSalesOfferPriceId,
          operationId: itemExecutionStep?.operation?.id,
          qty: +qty || 0,
          duration: +duration || 0,
          unitPrice: +unitPrice || 0,
          amount: +amount || 0,
          discountRate: +discountRate || 0,
          profitRate: +profitRate || 0,
        },
      }).then(() => {
        showToaster('created', 'positive');
        setItemAdd(null);
      });
  };
  const [add] = useMutation(ADD_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE);
  const [update] = useMutation(UPDATE_SUBCONTRACT_SALES_OFFER_OPERATION_PRICE);
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <RowCustom middle="xs">
        <Col sm={12} md={8} lg={6}>
          <Label
            styleTitle={{ width: '100%' }}
            title={<FormattedMessage id="operationPrice.operationName" />}
          >
            {itemExecutionStep?.operation?.name || itemExecutionStep?.name}
          </Label>
        </Col>
        <Col sm={4} md={4} lg={2} style={{ display: 'none' }}>
          <FormControl label={<FormattedMessage id="operationPrice.qty" />}>
            <Input
              type="text"
              name="qty"
              inputRef={register}
              disabled={true}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('qty', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={2}>
          <FormControl
            label={<FormattedMessage id="operationPrice.duration" />}
          >
            <Input
              type="text"
              name="duration"
              inputRef={register}
              size={SIZE.compact}
              disabled={true}
              onChange={(event) => {
                setValue('duration', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={2}>
          <FormControl
            label={<FormattedMessage id="operationPrice.unitPrice" />}
          >
            <Input
              type="text"
              name="unitPrice"
              disabled={itemExecutionStep?.external}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('unitPrice', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={2}>
          <FormControl
            label={<FormattedMessage id="operationPrice.amountPrice" />}
          >
            <Input
              type="text"
              name={'amount'}
              disabled={true}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('amount', inputDecimal({ event }));
              }}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={4}>
          <FormControl
            label={<FormattedMessage id="operationPrice.profitRate" />}
          >
            <Input
              type="text"
              name={'profitRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('profitRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={(profitAmount || 0) + '₺'}
            />
          </FormControl>
        </Col>
        <Col sm={4} md={4} lg={4}>
          <FormControl
            label={<FormattedMessage id="operationPrice.discountRate" />}
          >
            <Input
              type="text"
              name={'discountRate'}
              inputRef={register}
              size={SIZE.compact}
              onChange={(event) => {
                setValue('discountRate', inputDecimal({ event }));
              }}
              startEnhancer={'%'}
              endEnhancer={(discountAmount || 0) + '₺'}
            />
          </FormControl>
        </Col>
        <ColButton xs>
          <Button
            style={{ marginTop: '16px' }}
            type="button"
            onClick={() => setItemAdd(null)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </Button>
          <Button style={{ marginTop: '16px' }} type="submit">
            <FontAwesomeIcon icon={faCheck} />
          </Button>
        </ColButton>
      </RowCustom>
    </Form>
  );
};
export default ItemDetailOperationForm;
